import { connect } from 'react-redux'

import BannerPicker from './BannerPicker'
import { getBannersPaginatedResult, getIsLoading } from '../../store/promotions/reducers'
import { fetchBanners } from '../../store/promotions/actions'

const mapStateToProps = (state, { match }) => {
    return {
        bannersPaginatedResult: getBannersPaginatedResult(state),
        isLoading: getIsLoading(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchBanner: (pageSelected, search, type) => dispatch(fetchBanners(pageSelected, search, type))
    }
}

const BannerPickerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(BannerPicker)

export default BannerPickerContainer
