import React from 'react'
import { injectIntl } from 'react-intl'
import ProductLandingOne from './ProductLandingOne'

class LandingOne extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const bannerSrc = this.props.headLandingBannerData.url ? this.props.headLandingBannerData.url : this.props.srcBanner

        return (
            <div className="container my-4">
                <section id="section-landing-promotions">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <h2 className="title" dangerouslySetInnerHTML={{ __html: this.props.promotionTitle }} />
                                <div dangerouslySetInnerHTML={{ __html: this.props.promotionDescription }} />
                            </div>
                            <div style={{ position: 'relative', marginBottom: '20px' }}>
                                <div className="promotion-home-banner">
                                    <div className="promotion-home-banner-text" style={{backgroundImage: 'url(' + bannerSrc + ')'}}>
                                        <div dangerouslySetInnerHTML={{ __html: this.props.promotionBannerTitle }} />
                                        <div dangerouslySetInnerHTML={{ __html: this.props.promotionBannerDescription }} />
                                    </div>
                                    <div className="promotion-home-banner-image">
                                        <img src={bannerSrc} alt="Promo Banner" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="promotions-materials">
                                <div className="row">
                                    {this.props.products &&
                                        this.props.products.map((item, key) => (
                                            <div className="col-12 col-sm-6 my-3 mx-auto ">
                                                <div className="row">
                                                    <div className="col-12 col-md-10 offset-md-1">
                                                        <ProductLandingOne
                                                            key={key + item.product.productId}
                                                            product={item.product}
                                                            promotionId={this.props.promotionId}
                                                            isSellingOutlet={item.isSellingOutlet}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default injectIntl(LandingOne)
