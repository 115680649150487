import React from 'react'
import logo from '../../assets/img/e.cosentino.svg'

class InfoModal extends React.Component {
    render() {
        return (
            <div>
                <div className="full-overlay" style={{ display: this.props.show ? 'block' : 'none' }} />
                <div className="info-modal" style={{ display: this.props.show ? 'block' : 'none' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-5 logo-container d-none d-lg-block">
                                <img alt="" id="logo-img" className="profile-img-card" src={logo} />
                            </div>
                            <div className="col-12 col-lg-7 container">
                                <header className="container-fluid">{this.props.header}</header>
                                <div className="row">
                                    <div className="col-12">{this.props.content}</div>
                                </div>
                                <footer>{this.props.footer}</footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default InfoModal
