import { connect } from 'react-redux'

import { getIsLogged, getToken, getSalesOrg, getUserType, getImpersonatedBy, getIsAdmin } from '../../store/login/reducers'
import { getSelectedCenter } from '../../store/centers/reducers'
import LandingCreation from './LandingCreation'
import {
    fetchCountries,
    fetchCenter,
    fetchLangs,
    fetchLanding,
    fetchLandinById,
    getLandingSlug,
    createLanding,
    updateLanding,
    deleteLanding,
    deleteLandingImage,
    resetCountriesCenters,
    createLandingTranslated,
    updateLandingTranslated,
    setPortfolioMode
} from '../../store/landing/actions'
import { loginRequired } from '../../store/login/actions'
import { push } from 'react-router-redux'
import { showToastNotification } from '../../store/toast-notifications/actions'
import { addLoader } from '../../store/loader/actions'
import { removeLoader } from '../../store/loader/actions'
import { dispatchPushURL } from '../../store/ui/actions'

const mapStateToProps = (state, { match }) => {
    return {
        isLogged: getIsLogged(state),
        salesOrg: getSalesOrg(state),
        token: getToken(state),
        center: getSelectedCenter(state) ? getSelectedCenter(state) : 'near',
        outlet: match.params.outlet,
        landingId: match.params.id,
        currentIso: match.params.iso,
        landing: state.landing.landing,
        langs: state.landing.langs,
        countries: state.landing.countries,
        centers: state.landing.centers,
        slug: state.landing.slug,
        error: state.landing.error,
        userType: getUserType(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToastNotification: (text = '', type = 'none', duration = 10000) => dispatch(showToastNotification(text, type, duration)),
        fetchCountriesDescriptions: salesOrg => dispatch(fetchCountries(salesOrg)),
        fetchCentersDescriptions: salesOrg => dispatch(fetchCenter(salesOrg)),
        resetCountriesCenters: () => dispatch(resetCountriesCenters()),
        fetchLanding: () => dispatch(fetchLanding()),
        fetchLandingbyId: (id, iso) => dispatch(fetchLandinById(id, iso)),
        fetchLangs: () => dispatch(fetchLangs()),
        getLandingSlug: text => dispatch(getLandingSlug(text)),
        createLanding: landing => dispatch(createLanding(landing)),
        updateLanding: landing => dispatch(updateLanding(landing)),
        createLandingTranslated: landing => dispatch(createLandingTranslated(landing)),
        updateLandingTranslated: landing => dispatch(updateLandingTranslated(landing)),
        deleteLanding: (id, iso) => dispatch(deleteLanding(id, iso)),
        deleteLandingImage: (id, type) => dispatch(deleteLandingImage(id, type)),
        loginRequired: () => {
            dispatch(loginRequired())
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        storePortfolioMode: portfolioMode => dispatch(setPortfolioMode(portfolioMode)),
        addLoader: (id) => dispatch(addLoader(id)),
        removeLoader: (id) => dispatch(removeLoader(id))
    }
}

const LandingCreationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingCreation)

export default LandingCreationContainer
