import { connect } from 'react-redux'
import { getIsLogged } from '../../store/login/reducers'
import Translation from "../global/Translation";
import React from "react";
import ProductsSlider from "./ProductsSlider";
import {canViewMktools} from "../../store/centers/reducers";
import {fetchCrossSellingCheckOut} from "../../store/crossSelling/actions";
import {getCurrentCrossSellingCheckout} from "../../store/crossSelling/reducers";

const mapStateToProps = state => {
    return {
        products: getCurrentCrossSellingCheckout(state),
        hide: !getIsLogged(state) || canViewMktools(state),
        title: <Translation id="products_for_you" defaultMessage="Products for you" />,
        analyticsTag: 'discover_products_cross',
        gtmLabel: 'ec_carrusel_products_for_you'
    }
}

const mapDispatchToProps = (dispatch, props) => {

    const productIds = props.lines.map(l => l.productId).join(',')

    return {
        fetch: () => {
            return dispatch(fetchCrossSellingCheckOut(productIds))
        }
    }
}

const DiscoverProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider)


export default DiscoverProductsContainer
