import React from 'react'
import Button from '../../global/Button'
import { Input } from '@progress/kendo-react-inputs'
import { getFileData, isImageValid } from '../utils';
import cameraOff from '../../../assets/img/icons/camera-off.svg'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator';
import ProductDisplayName from '../../global/ProductDisplayName';
import IntegerInputNumber from "../../input-number-other/IntegerInputNumber";
import Translation from "../../global/Translation";

const SortableItemUI = props => {
    const { style, attributes, dataItem, forwardRef, onDelete, onDeleteImage, onEdit, landingId, onShowImage, intl, onFileError, editable, showLotsModal, showAdditionalInfoModal, commingFromMall } = props

    let outletQuality = false;
    let stockOutletAvailable = false;
    let thumbnail = dataItem.tumbnail_image_url || dataItem.thumbnail_image_uri;

    if (dataItem.data && typeof dataItem.data !== 'string') {
        outletQuality = dataItem.data.outletQuality
        stockOutletAvailable = dataItem.data.stock_outlet > 0 && dataItem.data.isStockOutlet
        thumbnail = dataItem.data.original_thumbnail || thumbnail;
    } else {
        try {
            const data = JSON.parse(dataItem.data)

            thumbnail = data.original_thumbnail || thumbnail
        } catch (e) { }
    }

    const isGroup = dataItem.data && dataItem.data.isGroup
    // if (isGroup) style.backgroundColor = 'antiquewhite'

    return (
        <div className="landings__products__item" ref={forwardRef} style={{ ...style }} {...attributes}>
            <div className="row">
                <div className="col-lg-3 col-md-6 col-6 landings__products__item__info">
                    <div className="landings__products__item__info--img-wrapper">
                        <img className={"landings__products__item__info--img" + (!thumbnail || thumbnail === 'null' ? '--placeholder' : '')} src={thumbnail && thumbnail !== 'null' ? thumbnail : cameraOff} />
                        {((!commingFromMall && outletQuality) || (commingFromMall && stockOutletAvailable)) && <DisplayOutletIndicator outletQuality={outletQuality}/>}
                    </div>
                    <div className="landings__products__item__info--text">
                        <p className="landings__products__item__info__brand">{dataItem.brand}</p>
                        <p className="landings__products__item__info__name">
                            <ProductDisplayName product={{ ...dataItem, displayName: dataItem.name ? dataItem.name.toUpperCase() : '', translations: dataItem.data.translations ? dataItem.data.translations : {} }} />
                        </p>
                        <p className="landings__products__item__info__data">
                            {dataItem.thickness && (
                                <span>
                                    <i className="icon-thickness"></i>
                                    {dataItem.thickness} cm
                                </span>
                            )}
                            <span>
                                <i className="icon-finish"></i>
                                {dataItem.finish}
                            </span>
                        </p>
                    </div>
                </div>


                {editable && (
                    <React.Fragment>

                        <div className="col-lg-2 col-md-3 col-6 d-flex flex-column" style={{rowGap: '12px'}}>
                            {isGroup && (
                                <Button size="small"
                                        onClick={() => showLotsModal(dataItem)}
                                        icon="edit"
                                        backgroundColor="#3333FF"
                                        color="white"
                                >
                                    <Translation id="lots_group" defaultMessage="Lots group" />
                                </Button>
                            )}
                            <Button size="small"
                                    onClick={() => showAdditionalInfoModal(dataItem.data)}
                                    icon="edit"
                                    backgroundColor="#0A0A0A"
                                    color="white"
                            >
                                <Translation id="additional_info" defaultMessage="Additional info" />
                            </Button>
                        </div>

                        <div className="col-lg-1 col-md-3 col-6 p-lg-0">
                            <div style={{display:'flex', flexDirection:'column', alignItems: 'center', fontSize:'14px'}}>
                                <label htmlFor={dataItem.id}>
                                    <Translation id="minimun_order" defaultMessage="Minimun order"/>
                                </label>
                                <IntegerInputNumber id={dataItem.id}
                                                    min={1}
                                                    valueDefault={Number.parseInt(dataItem.minimum_order)}
                                                    onChangeValue={v => dataItem.minimum_order = v}
                                />
                            </div>

                            {/*<Input*/}
                            {/*    type="number"*/}
                            {/*    label={intl.formatMessage({ id: 'minimum_order' })}*/}
                            {/*    value={dataItem.minimum_order}*/}
                            {/*    onChange={e => {*/}
                            {/*        dataItem.minimum_order = e.target.value > 0 ? e.target.value : 0*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>

                        <div className="col-lg-2 col-md-4 col-6 landings__products__item__file">
                            <label>
                                <Translation id="thumbnail" defaultMessage="Thumbnail" />
                                <br />
                                <small>(250 &times; 150px)</small>
                            </label>
                            {!dataItem.thumbnail_image_filename && (
                                <Input
                                    type="file"
                                    accept="image/jpg,image/jpeg,image/png"
                                    data-text={intl.formatMessage({ id: 'upload_image' })}
                                    onChange={e => {
                                        if (isImageValid(e.target.element.files[0])) {
                                            const pData = getFileData(dataItem.data)
                                            pData.original_thumbnail = pData.original_thumbnail || dataItem.tumbnail_image_url || dataItem.thumbnail_image_uri
                                            onEdit({
                                                ...dataItem,
                                                thumbnail_image: e.target.element.files[0],
                                                thumbnail_image_uri: URL.createObjectURL(e.target.element.files[0]),
                                                thumbnail_image_filename: e.target.element.files[0].name,
                                                thumbnail_image_preview: true,
                                                data: pData
                                            })
                                        } else {
                                            if (typeof onFileError === 'function') {
                                                onFileError()
                                            }
                                        }
                                    }}
                                />
                            )}
                            {dataItem.thumbnail_image_filename && (
                                <div className="landings__products__item__file__buttons">
                                    <Button
                                        inverted
                                        icon="times"
                                        far
                                        className="landings__products__item__file__buttons__clear"
                                        onClick={e => {
                                            onDeleteImage(landingId, 'THUMBNAIL', () =>
                                                onEdit({
                                                    ...dataItem,
                                                    thumbnail_image: null,
                                                    thumbnail_image_uri: null,
                                                    thumbnail_image_filename: null
                                                })
                                            )
                                        }}
                                    >
                                        {intl.formatMessage({ id: 'delete' })}
                                    </Button>
                                    {dataItem.thumbnail_image_uri && !dataItem.thumbnail_image_preview && (
                                        <Button
                                            inverted
                                            icon="image"
                                            className="landings__products__item__file__buttons__image"
                                            onClick={() => onShowImage(dataItem.thumbnail_image_uri)}
                                        ></Button>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="col-lg-2 col-md-4 col-6 landings__products__item__file">
                            <label><Translation id="details" defaultMessage="Details" /><br /><small>(1000 &times; 667px)</small></label>
                            {!dataItem.detail_image_filename && (
                                <Input type="file" accept="image/jpg,image/jpeg,image/png" data-text={intl.formatMessage({ id: 'upload_image' })}
                                    onChange={e => {
                                        if (isImageValid(e.target.element.files[0])) {
                                            onEdit({
                                                ...dataItem,
                                                detail_image: e.target.element.files[0],
                                                detail_image_uri: URL.createObjectURL(e.target.element.files[0]),
                                                detail_image_filename: e.target.element.files[0].name,
                                                detail_image_preview: true
                                            })
                                        } else {
                                            if (typeof onFileError === 'function') {
                                                onFileError()
                                            }
                                        }
                                    }}
                                />
                            )}
                            {dataItem.detail_image_filename && (
                                <div className="landings__products__item__file__buttons">
                                    <Button
                                        inverted
                                        icon="times"
                                        far
                                        className="landings__products__item__file__buttons__clear"
                                        onClick={e => {
                                            onDeleteImage(landingId, 'DETAIL', () =>
                                                onEdit({
                                                    ...dataItem,
                                                    detail_image: null,
                                                    detail_image_uri: null,
                                                    detail_image_filename: null
                                                })
                                            )
                                        }}
                                    >
                                        {intl.formatMessage({ id: 'delete' })}
                                    </Button>
                                    {dataItem.detail_image_uri && !dataItem.detail_image_preview && (
                                        <Button
                                            inverted
                                            icon="image"
                                            className="landings__products__item__file__buttons__image"
                                            onClick={() => onShowImage(dataItem.detail_image_uri)}
                                        ></Button>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="col-lg-2 col-md-4 col-6 landings__products__item__file">
                            <label>                              
                                <Translation id="background" defaultMessage="Background" />
                                <br />
                                <small>(1920 &times; 600px)</small>
                            </label>
                            {!dataItem.background_image_filename && (
                                <Input
                                    type="file"
                                    accept="image/jpg,image/jpeg,image/png"
                                    data-text={intl.formatMessage({ id: 'upload_image' })}
                                    onChange={e => {
                                        if (isImageValid(e.target.element.files[0])) {
                                            onEdit({
                                                ...dataItem,
                                                background_image: e.target.element.files[0],
                                                background_image_uri: URL.createObjectURL(e.target.element.files[0]),
                                                background_image_filename: e.target.element.files[0].name,
                                                background_image_preview: true
                                            })
                                        } else {
                                            if (typeof onFileError === 'function') {
                                                onFileError()
                                            }
                                        }
                                    }}
                                />
                            )}
                            {dataItem.background_image_filename && (
                                <div className="landings__products__item__file__buttons">
                                    <Button
                                        inverted
                                        icon="times"
                                        far
                                        className="landings__products__item__file__buttons__clear"
                                        onClick={e => {
                                            onDeleteImage(landingId, 'BACKGROUND', () =>
                                                onEdit({
                                                    ...dataItem,
                                                    background_image: null,
                                                    background_image_uri: null,
                                                    background_image_filename: null
                                                })
                                            )
                                        }}
                                    >
                                        {intl.formatMessage({ id: 'delete' })}
                                    </Button>
                                    {dataItem.background_image_uri && !dataItem.background_image_preview && (
                                        <Button
                                            inverted
                                            icon="image"
                                            className="landings__products__item__file__buttons__image"
                                            onClick={() => onShowImage(dataItem.background_image_uri)}
                                        ></Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>

            {editable && <Button icon="times" size="medium" danger className="landings__products__item__remove" onClick={e => onDelete(dataItem)}></Button>}

        </div>
    )
}

export default SortableItemUI
