import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { getSelectedBusinesSegment, getCurrentSegmentation, getSelectedChannel, getChannelOptions } from '../../store/segmentation/reducers'
import { toggleSegmentation, selectChannel } from '../../store/segmentation/actions'
const mapStateToProps = state => {
    const businessSegment = getSelectedBusinesSegment(state)
    return {
        show: getCurrentSegmentation(state) === 'CHANNEL',
        selected: getSelectedChannel(state),
        options: getChannelOptions(state, businessSegment)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('CHANNEL'))
        },
        onSelect: (value, name) => {
            dispatch(selectChannel(value, name))
        }
    }
}

//DEPRECATED
const Channel = ({ show, selected, onToggle, onSelect, options }) => {
    if (options === undefined) {
        return ''
    }
    if (Object.keys(options).length === 1 && selected === null) {
        Object.entries(options).map(option => onSelect(option[0], option[1].name))
    }
    const selectedValue = selected ? (
        <span className="selected">
            {['A0','B0','C0','D0','E0'].includes(selected) && <img src={require(`../../assets/img/icons/${selected}.png`)} alt="" className="segmentation-icon" />}
            {options[selected].name}
        </span>
    ) : null
    return (
        <div>
            <div onClick={() => onToggle()} className="option">
                <Translation id="channel" defaultMessage="Channel" /> : {selectedValue}
                <i className={show ? 'fa fa-caret-down' : 'fa fa-caret-right'} aria-hidden="true" />
                <br />
            </div>

            <div style={{ display: show ? 'block' : 'none' }}>
                <div className="row segment-selections">
                    {Object.entries(options).map(option => (
                        <span
                            key={option[0]}
                            onClick={() => {
                                onSelect(option[0], option[1].name)
                            }}
                        >
                            {['A0','B0','C0','D0','E0'].includes(option[0]) && <img src={require(`../../assets/img/icons/${option[0]}.png`)} alt="" className="segmentation-icon" />}
                            {/* <img src={require(`../../assets/img/icons/${option[0]}.png`)} alt="" className="segmentation-icon" /> */}
                            <span>{option[1].name}</span>
                        </span>
                    ))}
                </div>
            </div>
            <hr />
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Channel)
