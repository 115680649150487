import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from './Translation'
import Button from './Button'
import { resubscribeNewsletter, unsubscribeNewsletter } from '../../store/profile-configuration/actions'
import { getIsLoading, getIsSubscribed } from '../../store/profile-configuration/reducers'
import Spinner from './Spinner'

class Unsubscribe extends React.Component {
    constructor(props) {
        super(props)
        props.unsubscribe(props.tokenOnlyLogin)
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 offset-md-3 col-md-6">
                        <div className="fill-height-template">
                            <div style={{ minHeight: '200px' }} className="text-center my-4">
                                <h2>
                                    <Translation id="newsletter" defaultMessage="Newsletter" />
                                </h2>
                                {this.props.isLoading ? (
                                    <div className="my-4">
                                        <Spinner isLoading={this.props.isLoading} relative />
                                    </div>
                                ) : this.props.isSubscribe ? (
                                    <div>
                                        <Translation
                                            id="you_have_been_successfully_subscribed_from_email_communications"
                                            defaultMessage="You have been successfully subscribed from email communications."
                                        />
                                        <Translation
                                            id="if_you_did_this_in_error_you_may_unsubscribe_by_clicking_the_button_below"
                                            defaultMessage="If you did this in error, you may unsubscribe by clicking the button below."
                                        />
                                        <div>
                                            <Button
                                                className="mt-4 d-inline-block px-5"
                                                onClick={() => this.props.unsubscribe(this.props.tokenOnlyLogin)}
                                                disabled={this.props.isLoading}
                                            >
                                                <Translation id="unsubscribe" defaultMessage="unsubscribe" />
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Translation
                                            id="you_have_been_successfully_unsubscribed_from_email_communications"
                                            defaultMessage="You have been successfully unsubscribed from email communications."
                                        />
                                        <Translation
                                            id="if_you_did_this_in_error_you_may_re_subscribe_by_clicking_the_button_below"
                                            defaultMessage="If you did this in error, you may re-subscribe by clicking the button below."
                                        />
                                        <div>
                                            <Button
                                                className="mt-4 d-inline-block px-5"
                                                onClick={() => this.props.resubscribe(this.props.tokenOnlyLogin)}
                                                disabled={this.props.isLoading}
                                            >
                                                <Translation id="re_subscribe" defaultMessage="re-subscribe" />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, { match }) => {
    return {
        tokenOnlyLogin: match.params.tokenOnlyLogin,
        isSubscribe: getIsSubscribed(state),
        isLoading: getIsLoading(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        unsubscribe: tokenOnlyLogin => dispatch(unsubscribeNewsletter(tokenOnlyLogin)),
        resubscribe: tokenOnlyLogin => dispatch(resubscribeNewsletter(tokenOnlyLogin))
    }
}

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(Unsubscribe)
    )
)
