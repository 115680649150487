import React from "react"
import Select from 'react-select'
import { injectIntl } from "react-intl"
import { capitalizeFirstLetter } from "../../../helpers"

const CatalogFilter = props => {
    const { intl, configurableModels, uniqueColors } = props

    const typeOptions = [
        { label: intl.formatMessage({ id: 'subtype_washbasin' }), value: 'washbasins' },
        { label: intl.formatMessage({ id: 'subtype_shower_tray' }), value: 'showertrays' }
    ]

    const modelOptions = configurableModels && configurableModels.map(item => {
        return { value: item.model, label: capitalizeFirstLetter(item.model) }
    })

    const brandOptions = [
        { label: 'Silestone', value: 'SILESTONE' },
        { label: 'Dekton', value: 'DEKTON' }
    ]

    const colorOptions = uniqueColors && uniqueColors.map(color => {
        return { value: color, label: capitalizeFirstLetter(color) }
    })

    return (
        <div className="config__bath__filter">
            <div className="config__bath__filter--select">
                <div className="flex-block">
                    <Select value={props.selectedOptionType} 
                            onChange={props.handleChangeType}
                            options={typeOptions} 
                            placeholder={intl.formatMessage({ id: 'type' })} 
                            className={'custom__select'} 
                    />
                    <Select value={props.selectedOptionModel} 
                            onChange={props.handleChangeModel} 
                            options={modelOptions} 
                            placeholder={intl.formatMessage({ id: 'Modelo' })} 
                            className={'custom__select'} 
                    />
                    <Select value={props.selectedOptionBrand} 
                            onChange={props.handleChangeBrand} 
                            options={brandOptions} 
                            placeholder={intl.formatMessage({ id: 'brand' })} 
                            className={'custom__select'} 
                    />
                    <Select value={props.selectedOptionColor} 
                            onChange={props.handleChangeColor} 
                            options={colorOptions} 
                            placeholder={intl.formatMessage({ id: 'color' })} 
                            className={'custom__select'} 
                    />
                </div>
            </div>
        </div>
    )
}

export default injectIntl(CatalogFilter)