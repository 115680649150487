import React from 'react'

import { typeMap } from './TypeMaps'
import { injectIntl } from 'react-intl'

class MultiProductDisplayName extends React.Component {
    render() {
        const { product, specialNames } = this.props
        let LANG_TO_INDEX = {
            da: 'EN',
            de: 'DE',
            'en-US': 'Z2',
            en: 'EN',
            es: 'ES',
            fi: 'EN',
            'fr-CA': 'Z2',
            'en-CA': 'Z2',
            fr: 'FR',
            it: 'IT',
            nl: 'NL',
            no: 'EN',
            pt: 'PT',
            pl: 'EN',
            sv: 'SV',
            tr: 'TR' 
        }
        let locale = LANG_TO_INDEX[this.props.intl.locale]

        const typeName = typeMap[product.type]
        let sufix = '';        
        
        if (product.colorFormulations && product.colorFormulations[0]) {
            sufix += ' ' + product.colorFormulations[0].brand.toLowerCase()
        }

        if (product.translations && product.translations[locale]) {
            sufix += ' ' + product.translations[locale].colorName.toLowerCase()
        } 

        
        return (
            <span className="product-title" data-cy="global__product_display_name">
                {typeName}
                <span>{ sufix }</span>
            </span>
        )
    }
}

export default injectIntl(MultiProductDisplayName)
