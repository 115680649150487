import React from 'react'
import { connect } from 'react-redux'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { getIsIkeaCenter } from '../../store/centers/reducers'
import Modal from './Modal'
import Button from './Button'
import Translation from './Translation'

const mapStateToProps = state => {
    return { userPreferences: getProfileConfiguration(state), isIkeaCenter: getIsIkeaCenter(state) }
}
class FormatCSVModal extends React.Component {
    state = { selectedCSVFormat: 'xls' }
    render() {
        return (
            <Modal
                customClass="showOverflow"
                title={<Translation id="please_specify_format" defaultMessage="Please specify format" />}
                footer={
                    <Button
                        onClick={() => {
                            this.props.onClose(this.state.selectedCSVFormat)
                        }}
                        inverted
                        size="medium"
                    >
                        <Translation id="download" defaultMessage="download" />
                    </Button>
                }
                onClose={() => this.props.onClose()}
            >
                <div className="container">
                    <Translation id="please_specify_the_desired_format" defaultMessage="Please specify the desired format" />

                    <div className="col-12" style={{ marginLeft: '10px', marginTop: '10px' }}>
                        <span>
                            <form>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        value="xls"
                                        checked={this.state.selectedCSVFormat === 'xls'}
                                        onChange={e =>
                                            this.setState({
                                                selectedCSVFormat: e.target.value
                                            })
                                        }
                                    />
                                    <label class="form-check-label">
                                        <Translation id="csv_optimized_for_excel" defaultMessage="csv optimized for excel"></Translation>
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        value="csv"
                                        checked={this.state.selectedCSVFormat === 'csv'}
                                        onChange={e =>
                                            this.setState({
                                                selectedCSVFormat: e.target.value
                                            })
                                        }
                                    />
                                    <label class="form-check-label">csv</label>
                                </div>
                            </form>
                        </span>
                    </div>
                </div>
            </Modal>
        )
    }
}
export default connect(
    mapStateToProps,
    null
)(FormatCSVModal)
