import React from 'react'
import ReactTable from 'react-table'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import Modal from '../global/Modal'
import Spinner from '../global/Spinner'
import LocalizedLink from '../global/LocalizedLink'

class PackingListView extends React.Component {
    render() {
        let columns = [
            { Header: <FormattedMessage id="productId" defaultMessage="Product ID" />, accessor: 'productId', className: 'text-center' },
            { Header: <FormattedMessage id="product" defaultMessage="Product" />, accessor: 'productName', className: 'text-center' },
            { Header: <FormattedMessage id="lot_id" defaultMessage="Lot ID" />, accessor: 'lotId', className: 'text-center' }
        ]
        if (this.props.isOffer) {
            columns = [
                ...columns,
                { Header: <FormattedMessage id="center_id" defaultMessage="Center ID" />, accessor: 'centerId', className: 'text-center' },
                { Header: <FormattedMessage id="qty" defaultMessage="Quantity" />, accessor: 'quantity', className: 'text-center' }
            ]
        } else {
            columns = [
                ...columns,
                {
                    Header: <FormattedMessage id="invoice_num" defaultMessage="Invoice Num." />,
                    Cell: ({ original }) => {
                        return (
                            <span
                                onClick={() => {
                                    this.props.getFileByUrl(this.props.getOrderInvoiceDownloadUrl(original.invoiceNum))
                                }}
                                style={{ color: 'blue', cursor: 'pointer' }}
                            >
                                {original.invoiceNum}
                            </span>
                        )
                    },
                    className: 'text-center'
                },
                { Header: <FormattedMessage id="invoice_date" defaultMessage="Invoice Date" />, accessor: 'invoiceDate', className: 'text-center' }
            ]
        }

        return (
            <Modal
                title={
                    <span>
                        {this.props.isOffer ? (
                            <FormattedMessage id="quote_overview" defaultMessage="Quote overview" />
                        ) : (
                            <FormattedMessage id="packing_list" defaultMessage="Packing list" />
                        )}{' '}
                        <small>
                            <FormattedMessage id="reservation_id" defaultMessage="Reservation ID" />: {this.props.order}
                        </small>
                    </span>
                }
                footer={''}
                onClose={this.props.onClose}
                size="large"
            >
                <div className="row">
                    <div className="col-12 text-right mb-3">
                        {this.props.canEditOrders && (
                            <LocalizedLink
                                routeId="ROUTE_PROFILE"
                                params={{ filter: 'edit-order', open: this.props.orderId }}
                                onClick={e => {
                                    if (!this.props.orderId) e.preventDefault()
                                }}
                            >
                                <Button inline size="tiny" icon="edit" disabled={!this.props.orderId}>
                                    <FormattedMessage id="edit_order" defaultMessage="Edit order" />
                                </Button>
                            </LocalizedLink>
                        )}
                        {!this.props.isOffer ? (
                            <span style={{ margin: '0 5px' }}>
                                <Button inline size="tiny" icon="download" onClick={() => this.props.getFileByUrl(this.props.getOrderImagesDownloadUrl())}>
                                    <FormattedMessage id="download_images" defaultMessage="Download images" />
                                </Button>
                            </span>
                        ) : null}
                    </div>
                </div>
                <ReactTable
                    data={this.props.packingList}
                    loading={this.props.isLoading}
                    loadingText={<Spinner isLoading={this.props.isLoading} />}
                    columns={columns}
                    defaultPageSize={4}
                    pageSizeOptions={[8, 16, 32, 64, 128]}
                    previousText={<FormattedMessage id="previous" defaultMessage="Previous" />}
                    nextText={<FormattedMessage id="next" defaultMessage="Next" />}
                    noDataText={<FormattedMessage id="no_data" defaultMessage="No data available" />}
                    pageText={<FormattedMessage id="page" defaultMessage="Page" />}
                    ofText={<FormattedMessage id="of" defaultMessage="Of" />}
                />
            </Modal>
        )
    }
}
export default injectIntl(PackingListView)
