import React from "react";
import ReactTable from "react-table";
import Translation from "../../global/Translation";
import DisplayOutletIndicator from "../../catalog/rectangleResult/displays/DisplayOutletIndicator";
import ProductDisplayName from "../../global/ProductDisplayName";
import DisplayBrand from "../../catalog/rectangleResult/displays/DisplayBrand";
import DisplayThickness from "../../catalog/rectangleResult/displays/DisplayThickness";
import DisplayDetail from "../../catalog/rectangleResult/displays/DisplayDetail";
import {typeMap} from "../../global/TypeMaps";
import {getLanguage} from "../../../store/ui/reducers";
import {connect} from "react-redux";
import Button from "../../global/Button";
import ReactTablePaginationCount from './ReactTablePaginationCount';
import { fetchDiscountMaterialsData } from "../../../store/promotions/actions";
import { getToken } from "../../../store/login/reducers";
import axios from "axios";
import FormatCSVModal from "../../global/FormatCSVModal";

class MaterialsTable extends React.Component {

    state = { showCSVFormat: false }

    componentDidMount() {
        this.props.fetchDiscountMaterialsData(this.props.campaign.id)
    }

    closeFormatCSVModal(format) {
        this.setState({ showCSVFormat: false })

        const FileDownload = require('js-file-download');
        axios.get(process.env.REACT_APP_API_HOST + `/promotions/get-discount-materials-data/${this.props.campaign.id}/csv?format=${format}`,
            {headers: 
                {Authorization: `Bearer ${this.props.token}`},
                'Content-Type': 'text/plain',
                responseType: 'blob'
            })
        .then((response) => {
            FileDownload(response.data, `materials_discount.${format}`);
        });
    } 

    render() {
        return (
            <React.Fragment>
                <div className="d-flex align-items-center justify-content-end">
                    <Button inline size="small" icon="download" onClick={() => this.setState({ showCSVFormat: true })}>
                        <Translation id="download_excel" defaultMessage="Download Excel" />
                    </Button>
                </div>
                {this.state.showCSVFormat && (<FormatCSVModal onClose={(format) => this.closeFormatCSVModal(format)} token={() => this.props.token} />)}
                <ReactTable
                    className={'light list-result discount-materials'}
                    data={this.props.products}
                    columns={[
                        {
                            id: 'image',
                            Header: <Translation id="image" defaultMessage="Image" />,
                            Cell: ({ original }) => {
                                const image = original.thumb
                                return (
                                    <div>
                                        <DisplayOutletIndicator outletQuality={original.isSellingOutlet && original.outletQuality} style={{ top: 'initial' }} />
                                        <div className="product-image-container" style={{width:'64px', margin:'auto'}}>
                                            {
                                                image ?
                                                    (<img style={{ maxWidth: '100%'}} src={image}/>)
                                                    :
                                                    (<img style={{ maxWidth: '100%', height: '64px'}}/>)
                                            }
                                        </div>
                                    </div>
                                )
                            },
                            filterable: false
                        },
                        {
                            id: 'name',
                            Header: <Translation id="name" defaultMessage="Name" />,
                            Cell: ({ original }) => {
                                return (
                                    <div>
                                        <ProductDisplayName product={original} />
                                        <DisplayBrand brand={original.brand} />
                                        <span style={{ fontSize: '0.8em' }}># {original.productId}</span>
                                    </div>
                                )
                            }
                        },
                        {
                            id: 'features',
                            Header: <Translation id="features" defaultMessage="Features" />,
                            Cell: ({ original }) => {
                                return (
                                    <div style={{fontSize: '12px'}}>
                                        <DisplayThickness thickness={original.thickness} />
                                        <DisplayDetail class="finish" value={original.finish} />
                                        {typeMap[original.type] ? typeMap[original.type] : <DisplayDetail class="finish" value={original.type} />}
                                        <DisplayDetail class="finish" value={original.subtypeName} />
                                    </div>
                                )
                            }
                        },
                        {
                            id: 'rotation',
                            Header: <Translation id="rotation" defaultMessage="Rotation" />,
                            Cell: ({ original }) => {
                                let label = ''

                                if (original.indRotation) {
                                    let lang = this.props.language.toUpperCase()
                                    if (!(lang in original.translations)) lang = 'Z2'
                                    if (lang in original.translations && original.translations[lang].indRotation) {
                                        label = `(${original.indRotation}) ${original.translations[lang].indRotation}`
                                    }
                                }

                                return (<DisplayDetail class="finish" value={label} />)
                            }
                        },
                        // {
                        //     id: 'promotion_id',
                        //     Header: <Translation id="promotion_id" defaultMessage="Promotion ID" />,
                        //     Cell: ({ index }) => {
                        //         return this.getPromotionIdInput(index)
                        //         // return  this.state.promotionIds[index] ? <input type="text"
                        //         //              ref={this.state.promotionIds[index].input}
                        //         //              // value={this.state.promotionIds[index].promotionId}
                        //         //              onChange={e => this.setPromotionId(index, e.target.value)}
                        //         //              maxLength="12"
                        //         //              style={{textAlign:"end", fontSize:"1.2em", width: "9em", padding:"2px 6px 1px"}}
                        //         //              id={`input_${++this.counter}`}
                        //         // /> : null
                        //     }
                        // },
                        // {
                        //     id: 'auth_promotion',
                        //     Header: <Translation id="auth_promotion" defaultMessage="Auth promotion" />,
                        //     Cell: ({ original }) => {
                        //         return original.hasPromotionDiscount ? (
                        //                 <i className="fas fa-check fa-2x" style={{color:'green'}}/>
                        //             ) : (
                        //                 <i className="fas fa-times fa-2x" style={{color:'red'}}/>
                        //             )
                        //     }
                        // }
                    ]}
                    defaultPageSize={4}
                    pageSizeOptions={[4, 8, 10, 25, 50, 100]}
                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                    nextText={<Translation id="next" defaultMessage="Next" />}
                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                    pageText={<Translation id="page" defaultMessage="Page" />}
                    ofText={<Translation id="of" defaultMessage="Of" />}
                    PaginationComponent={ReactTablePaginationCount}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: getLanguage(state),
        token: getToken(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDiscountMaterialsData: (promotionId) => dispatch(fetchDiscountMaterialsData(promotionId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaterialsTable)
