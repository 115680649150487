import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import mallDiscount from '../../assets/img/logos/mall-discount.svg'

defineMessages({
    ORDER_EDITED: {
        id: 'ORDER_EDITED',
        description: 'ORDER_EDITED',
        defaultMessage: 'Order edited successfully'
    },
    ORDER_REJECTED: {
        id: 'ORDER_REJECTED',
        description: 'ORDER_REJECTED',
        defaultMessage: 'Order rejected successfully'
    },
    ORDER_REJECTED_SENT: {
        id: 'ORDER_REJECTED_SENT',
        description: 'ORDER_REJECTED_SENT',
        defaultMessage: 'Order cancellation request sent'
    },
    ORDER_BEING_MODIFIED: {
        id: 'ORDER_BEING_MODIFIED',
        description: 'ORDER_BEING_MODIFIED',
        defaultMessage: 'Order is currently being modified'
    },
    ORDER_REJECT_FAILURE: {
        id: 'ORDER_REJECT_FAILURE',
        description: 'ORDER_REJECT_FAILURE',
        defaultMessage: 'Order reject failures'
    },
    ORDER_NOT_CANCELLABLE_PROCESS_STARTED: {
        id: 'ORDER_NOT_CANCELLABLE_PROCESS_STARTED',
        description: 'ORDER_NOT_CANCELLABLE_PROCESS_STARTED',
        defaultMessage: 'Order not cancellable, process already started'
    },
    ORDER_NOT_CANCELLABLE_PURCHASE_ORDER_NOT_CANCELLABLE: {
        id: 'ORDER_NOT_CANCELLABLE_PURCHASE_ORDER_NOT_CANCELLABLE',
        description: 'ORDER_NOT_CANCELLABLE_PURCHASE_ORDER_NOT_CANCELLABLE',
        defaultMessage: 'Order not cancellable, because could not reject the associated purchase order'
    },
    ORDER_NOT_CANCELLABLE_PURCHASE_ORDER_NOT_FOUND: {
        id: 'ORDER_NOT_CANCELLABLE_PURCHASE_ORDER_NOT_FOUND',
        description: 'ORDER_NOT_CANCELLABLE_PURCHASE_ORDER_NOT_FOUND',
        defaultMessage: 'Order not cancellable, because cant found the associated purchase order'
    },

    DOCUMENTS_NOT_FOUND: {
        id: 'DOCUMENTS_NOT_FOUND',
        description: 'DOCUMENTS_NOT_FOUND',
        defaultMessage: 'Order not cancellable because documents not found'
    },
    MAX_MONTH_QTY_PRODUCT_MKTOOLS_REACHED: {
        id: 'MAX_MONTH_QTY_PRODUCT_MKTOOLS_REACHED',
        description: 'You have reached monthly limit ( {limitMonth} ) of qty for this product, you have already bought {monthQty} units this month and you want add: {toAdd}',
        defaultMessage: 'You have reached monthly limit ( {limitMonth} ) of qty for this product, you have already bought {monthQty} units this month and you want add: {toAdd}'
    },
    MAX_QTY_PRODUCT_MKTOOLS_REACHED: {
        id: 'MAX_QTY_PRODUCT_MKTOOLS_REACHED',
        description: 'You have reached limit ( {limitMax} ) of qty for this product, you have already into cart {alreadyIntoCart} and you want add: {toAdd}',
        defaultMessage: 'You have reached limit ( {limitMax} ) of qty for this product, you have already into cart {alreadyIntoCart} and you want add: {toAdd}'
    },
    THE_PRODUCTS_HAVE_BEEN_SAVED_SUCCESSFULLY: {
        id: 'THE_PRODUCTS_HAVE_BEEN_SAVED_SUCCESSFULLY',
        description: 'The products have been saved successfully: {listOfProducts}',
        defaultMessage: 'The products have been saved successfully: {listOfProducts}'
    },
    SOMETHING_WENT_WRONG: {
        id: 'SOMETHING_WENT_WRONG',
        description: 'Something went wrong',
        defaultMessage: 'Something went wrong'
    },
    PRICES_EXPERIMENTAL: {
        id: 'PRICES_EXPERIMENTAL',
        description: 'PRICES_EXPERIMENTAL',
        defaultMessage: 'The prices shown here are not definitive and are subject to confirmation by COSENTINO S.A. staff. Any applicable taxes are not included.'
    },
    PRICES_EXPERIMENTAL_NOT_MENTION_TAXES: {
        id: 'PRICES_EXPERIMENTAL_NOT_MENTION_TAXES',
        description: 'PRICES_EXPERIMENTAL_NOT_MENTION_TAXES',
        defaultMessage: 'The prices shown here are not definitive and are subject to confirmation by COSENTINO S.A. staff.'
    },
    PROMOTION_NEEDS_MATERIALS: {
        id: 'PROMOTION_NEEDS_MATERIALS',
        description: 'PROMOTION_NEEDS_MATERIALS',
        defaultMessage: 'Promotion needs materials to be activated'
    },
    PROMOTION_NEEDS_CUSTOMERS: {
        id: 'PROMOTION_NEEDS_CUSTOMERS',
        description: 'PROMOTION_NEEDS_CUSTOMERS',
        defaultMessage: 'Promotion needs customers to be activated'
    },
    NO_DATA_HAS_BEEN_SENT: {
        id: 'NO_DATA_HAS_BEEN_SENT',
        description: 'NO_DATA_HAS_BEEN_SENT',
        defaultMessage: 'No data has been sent'
    },
    UNDEFINED_ERROR: {
        id: 'UNDEFINED_ERROR',
        description: 'UNDEFINED_ERROR',
        defaultMessage: 'Undefined error. Please contact the support team.'
    },
    THE_PROMOTION_HAVE_BEEN_CLONED_SUCCESSFULLY: {
        id: 'THE_PROMOTION_HAVE_BEEN_CLONED_SUCCESSFULLY',
        description: 'THE_PROMOTION_HAVE_BEEN_CLONED_SUCCESSFULLY',
        defaultMessage: 'The promotion has been successfully cloned'
    },
    AN_ERROR_HAS_OCCURRED_CLONING_THIS_CAMPAIGN: {
        id: 'AN_ERROR_HAS_OCCURRED_CLONING_THIS_CAMPAIGN',
        description: 'AN_ERROR_HAS_OCCURRED_CLONING_THIS_CAMPAIGN',
        defaultMessage: 'An error has occurred in the cloning of this campaign'
    }
})
class FloatingAlert extends Component {
    componentWillUnmount() {
        this.props.onClose && this.props.onClose()
    }
    render() {
        const { children, onClose, sucessMessage, failureMessage, warningMessage, values, className, pricing, hidden, mallContainerDiscount } = this.props

        const style = failureMessage ? 'alert__failure fadeinup' : warningMessage ? 'alert__warning fadeinup' : 'alert__success fadeinup'
        let classes = className + ' ' + style
        if (!children && !failureMessage && !sucessMessage && !warningMessage) return null
        if (!pricing) {
            setTimeout(onClose, 8000)
        }
        if (hidden) {
            classes += ' d-none'
        }
        return (
            <div className={classes} style={{ animationDelay: '0.1s' }}>
                {onClose && (
                    <span className="alert__close" onClick={onClose}>
                        &times;
                    </span>
                )}
                {mallContainerDiscount && <img alt="" src={mallDiscount} className="mall-discount-image" />}
                <span className="alert__title">{this.props.intl.formatMessage({ id: children || failureMessage || sucessMessage || warningMessage }, values ? values : {})}</span>
                <p className="alert__message" />
            </div>
        )
    }
}
export default injectIntl(FloatingAlert)
