export const initState = []

const loader = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_LOADER': {
            return [ ...state, action.loader ]
        }
        case 'REMOVE_LOADER': {
            const i = state.findIndex(t => t === action.loader);

            if (i === -1) {
                return state;
            }

            return [
                ...state.slice(0, i),
                ...state.slice(i + 1)
            ]
        }
        default: {
            return state;
        }
    }
}

export default loader;