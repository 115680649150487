import { LOCATION_CHANGE } from 'react-router-redux'
import { getUserType, getUserName, getImpersonatedBy } from '../login/reducers'
import { getLowesCenterId, getSelectedCenter } from '../centers/reducers'

const initState = {
    showModal: false,
    fetchingUsers: false,
    users: [],
    fetchingCenterUsers: false,
    centerUsers: []
}
const impersonate = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, { showModal: false })
        case 'FETCH_USERS_TO_IMPERSONATE_REQUEST':
            return Object.assign({}, state, { fetchingUsers: true })
        case 'FETCH_USERS_TO_IMPERSONATE_BY_CENTER_REQUEST':
                return Object.assign({}, state, { fetchingCenterUsers: true })
        case 'FETCH_USERS_TO_IMPERSONATE_SUCCESS':
            return Object.assign({}, state, { users: action.users, fetchingUsers: false })
        case 'FETCH_USERS_TO_IMPERSONATE_BY_CENTER_SUCCESS':
                return Object.assign({}, state, { centerUsers: action.users, fetchingCenterUsers: false })
        case 'SHOW_IMPERSONATE_MODAL':
            return Object.assign({}, state, { showModal: true })
        case 'CLOSE_IMPERSONATE_MODAL':
            return Object.assign({}, state, { showModal: false })
        case 'RESET_UI':
            return Object.assign({}, state, { showModal: false })

        default:
            return state
    }
}
export const showModalSelector = state => {
    return state.impersonate.showModal
}
export const usersSelector = state => {
    return state.impersonate.users
}
export const centerUsers = state => {
    return state.impersonate.centerUsers
}
export const getFetchingUsers = state => {
    return state.impersonate.fetchingUsers
}
export const getFetchingCenterUsers = state => {
    return state.impersonate.fetchingCenterUsers
}

export const canImpersonate = state => {
    if (getUserName(state) === 'COMERCIAL') return false
    if (getSelectedCenter(state) === getLowesCenterId()) return false
    if (getUserType(state) === 'ADMIN') return true
    if (getImpersonatedBy(state)) return true
    return false
}
export default impersonate
