import React from 'react'
import ProductImage from '../../../catalog/ProductImage'
import ProductDisplayName from '../../../global/ProductDisplayName'
import { addForwardSlash } from '../Utils'
import Translation from '../../../global/Translation'
import { capitalizeFirstLetter } from '../../../../helpers'
import { getConfigLineName } from '../Utils'
import ConfigBathIndicator from '../indicators/ConfigBathIndicator'

class NewShippingCardItem extends React.Component {
    blockQuantity = orderLine => {
        return (
            <span className="qty">
                <Translation id="quantity" defaultMessage="Quantity" />
                :&nbsp;
                {orderLine.delivery_qty == 0 ? (
                    <Translation id="product_canceled_by_center" defaultMessage="Product cancelled by center" />
                ) : (
                    <React.Fragment>{parseInt(orderLine.delivery_qty)}</React.Fragment>
                )}
            </span>
        )
    }
    render() {
        const { orderLine, adaptedFormat, intl, locale, replaceDotWithComma } = this.props

        const thicknessFormatIntl = orderLine => {
            if (!orderLine.product.thickness) return null
            return replaceDotWithComma(orderLine.product.thickness, locale) + ' cm'
        }

        const finishFormatIntl = (orderLine, intl) => {
            if (!orderLine.product.finish) return null
            return intl.formatMessage({ id: orderLine.product.finish })
        }
        const productFormatIntl = orderLine => {
            if (!orderLine.product.format) return null
            return adaptedFormat(orderLine.product.format, { upper: true, showUnits: true })
        }

        const descriptionBlock = () => {
            return (
                <div className="order__card__item">
                    <div className="order__card__item--shipping-content">
                        <div>
                            <div className="card__item__productinfo">
                                <div className="card__item__productinfo--img">
                                    <ProductImage product={orderLine.product} />
                                </div>
                                <div className="card__item__productinfo--text">
                                    <div>
                                        <span className="brand">{capitalizeFirstLetter(orderLine.product.brand)} &reg;</span>
                                    </div>
                                    <div>
                                        <span className="product">
                                            <ProductDisplayName product={orderLine.product} />
                                        </span>
                                    </div>
                                    <div>
                                        <span className="technical">
                                            {addForwardSlash(
                                                orderLine.product.productId,
                                                productFormatIntl(orderLine),
                                                finishFormatIntl(orderLine, intl),
                                                thicknessFormatIntl(orderLine)
                                            )}
                                        </span>
                                    </div>
                                    {/* Mobile screen */}
                                    <div className="mobile-screen">{this.blockQuantity(orderLine)}</div>
                                </div>
                            </div>
                        </div>
                        {/* Desktop screen */}
                        <div className="desktop-screen">{this.blockQuantity(orderLine)}</div>
                    </div>
                </div>
            )
        }

        const descriptionBlockConfigBath = () => {
            const cts = orderLine.cut_to_size
            return (
                <div className="order__card__item">
                    <div className="order__card__item--shipping-content">
                        <div>
                            <div className="card__item__productinfo">
                                <div className="card__item__productinfo--img">
                                    <img src={cts.thumb} alt={getConfigLineName(cts.configurable)} className="is-img-configurable"/>
                                    <ConfigBathIndicator />
                                </div>
                                <div className="card__item__productinfo--text">
                                    <div>
                                        <span className="brand">{capitalizeFirstLetter(cts.configurable.material.brand)} &reg;</span>
                                    </div>
                                    <div>
                                        <span className="product">
                                            <span className="product-title">
                                                {getConfigLineName(cts.configurable)}
                                            </span>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="technical">
                                            <Translation id="customized" defaultMessage="Custom" />
                                        </span>
                                    </div>
                                    {/* Mobile screen */}
                                    <div className="mobile-screen">{this.blockQuantity(orderLine)}</div>
                                </div>
                            </div>
                        </div>
                        {/* Desktop screen */}
                        <div className="desktop-screen">{this.blockQuantity(orderLine)}</div>
                    </div>
                </div>
            )
        }

        const descriptionBlockException = () => {
            return (
                <div className="order__card__item">
                    <div className="order__card__item--shipping-content">
                        <div className="card__item__productinfo">
                            <div className="card__item__productinfo--img">
                                <ProductImage product={null} />
                            </div>
                            <div className="card__item__productinfo--text">
                                {orderLine.short_text && (
                                    <div>
                                        <span className="product">
                                            <span>{capitalizeFirstLetter(orderLine.short_text)}</span>
                                        </span>
                                    </div>
                                )}
                                {orderLine.material_id && (
                                    <div>
                                        <span className="technical">{orderLine.material_id}</span>
                                    </div>
                                )}
                                {orderLine.lot_id && (
                                    <div className="lot-id">
                                        <span>
                                            <Translation id="lot_id" defaultMessage="Lot ID" />
                                            :&nbsp;
                                        </span>
                                        <span>{orderLine.lot_id}</span>
                                    </div>
                                )}
                                {/* Mobile screen */}
                                <div className="mobile-screen">{this.blockQuantity(orderLine)}</div>
                            </div>
                        </div>
                        {/* Desktop screen */}
                        <div className="desktop-screen">{this.blockQuantity(orderLine)}</div>
                    </div>
                </div>
            )
        }

        const renderContent = () => {
            if (orderLine.product) {
                return descriptionBlock()
            }
        
            if (orderLine.cut_to_size && orderLine.cut_to_size.configurable) {
                return descriptionBlockConfigBath()
            }
        
            return descriptionBlockException()
        }

        return (
            <React.Fragment>
                <div>
                    <div className="order-divider-x"></div>
                    {renderContent()}
                </div>
            </React.Fragment>
        )
    }
}

export default NewShippingCardItem
