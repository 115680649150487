import React from "react"

const SearchInput = ({inputType, inputName, inputValue, inputRef, placeholder, inputOnChange, className, gtmLabel, dataQa, dataQas}) => {
    return (
        <input
            type={inputType}
            name={inputName}
            value={inputValue}
            ref={inputRef}
            onChange={inputOnChange}
            className={`form-control search__input__bar ` + className}
            placeholder={placeholder}
            gtm-label={gtmLabel}
            data-qa={dataQa}
            data-qas={dataQas}
        />
    )
}

export default SearchInput