import React from 'react'
import ReactTable from 'react-table'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { fetchOrderDetails, getFileByUrl, fetchPackingList } from '../../store/orders/actions'
import { getPackingList, getPackingListLoading } from '../../store/orders/reducers'
import { getToken } from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import Button from '../global/Button'
import Modal from '../global/Modal'
import Spinner from '../global/Spinner'
import LocalizedLink from '../global/LocalizedLink'

const mapStateToProps = (state, ownProps) => {
    return {
        packingList: ownProps.packingList ? ownProps.packingList : getPackingList(state),
        shouldFetchPackingList: !ownProps.packingList,
        isLoading: getPackingListLoading(state),
        token: getToken(state),
        lang: getLanguage(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchPackingList: reservationId => {
            dispatch(fetchPackingList(reservationId))
        },
        getFileByUrl: url => dispatch(getFileByUrl(url))
    }
}

class PackingList extends React.Component {
    constructor(props) {
        super(props)

        this.getOrderInvoiceDownloadUrl = this.getOrderInvoiceDownloadUrl.bind(this)
        this.getOrderImagesDownloadUrl = this.getOrderImagesDownloadUrl.bind(this)
    }

    componentDidMount() {
        //cargamos packing list para vistas de pedido antiguas al nuevo seguimiento
        if (this.props.shouldFetchPackingList && !this.props.isLoading) this.props.fetchPackingList(this.props.reservationId)
    }

    getOrderInvoiceDownloadUrl(invoiceNum) {
        return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum
        // return process.env.REACT_APP_API_HOST + '/users/order-invoice/' + invoiceNum + '/?authToken=' + this.props.token
    }

    getOrderImagesDownloadUrl() {
        return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang
        // return process.env.REACT_APP_API_HOST + '/users/order-images?reservationId=' + this.props.reservationId + '&lang=' + this.props.lang + '&authToken=' + this.props.token
    }

    render() {
        return (
            <Modal
                title={
                    <span>
                        <FormattedMessage id="packing_list" defaultMessage="Packing list" />{' '}
                        <small>
                            <FormattedMessage id="reservation_id" defaultMessage="Reservation ID" />: {this.props.reservationId}
                        </small>
                    </span>
                }
                footer={''}
                onClose={this.props.onClose}
                size="large"
            >
                <Spinner isLoading={this.props.isLoading} relative={true} />
                {this.props.packingList && this.props.packingList.length ? (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-12 text-right mb-3">
                                {this.props.canEditOrders && (
                                    <LocalizedLink
                                        routeId="ROUTE_PROFILE"
                                        params={{ filter: 'edit-order', open: this.props.orderId }}
                                        onClick={e => {
                                            if (!this.props.orderId) e.preventDefault()
                                        }}
                                    >
                                        <Button inline size="tiny" icon="edit" disabled={!this.props.orderId}>
                                            <FormattedMessage id="edit_order" defaultMessage="Edit order" />
                                        </Button>
                                    </LocalizedLink>
                                )}

                                <span style={{ margin: '0 5px' }}>
                                    <Button inline size="tiny" icon="download" onClick={() => this.props.getFileByUrl(this.getOrderImagesDownloadUrl())}>
                                        <FormattedMessage id="download_images" defaultMessage="Download images" />
                                    </Button>
                                </span>
                            </div>
                        </div>
                        <ReactTable
                            data={this.props.packingList}
                            loading={this.props.isLoading}
                            loadingText={<Spinner isLoading={this.props.isLoading} />}
                            columns={[
                                { Header: <FormattedMessage id="productId" defaultMessage="Product ID" />, accessor: 'productId' },
                                { Header: <FormattedMessage id="product" defaultMessage="Product" />, accessor: 'productName' },
                                { Header: <FormattedMessage id="lot_id" defaultMessage="Lot ID" />, accessor: 'lotId' },
                                {
                                    Header: <FormattedMessage id="invoice_num" defaultMessage="Invoice Num." />,
                                    Cell: ({ original }) => {
                                        return (
                                            <span
                                                onClick={() => {
                                                    this.props.getFileByUrl(this.getOrderInvoiceDownloadUrl(original.invoiceNum))
                                                }}
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                            >
                                                {original.invoiceNum}
                                            </span>
                                        )
                                    }
                                },
                                { Header: <FormattedMessage id="invoice_date" defaultMessage="Invoice Date" />, accessor: 'invoiceDate' }
                            ]}
                            defaultPageSize={4}
                            pageSizeOptions={[8, 16, 32, 64, 128]}
                            previousText={<FormattedMessage id="previous" defaultMessage="Previous" />}
                            nextText={<FormattedMessage id="next" defaultMessage="Next" />}
                            noDataText={<FormattedMessage id="no_data" defaultMessage="No data available" />}
                            pageText={<FormattedMessage id="page" defaultMessage="Page" />}
                            ofText={<FormattedMessage id="of" defaultMessage="Of" />}
                        />
                    </React.Fragment>
                ) : (
                    !this.props.isLoading && (
                        <div className="row">
                            <div className="col-12 text-center my-3">
                                <FormattedMessage id="try_it_again_later" defaultMessage="Try it again later" />
                            </div>
                        </div>
                    )
                )}
            </Modal>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PackingList)
)
