import React from 'react'

const SimpleModal = props => {
    const { children, onClose, size, customClass, showCloseButton = true } = props

    let dialogClasses = ''

    if (size === 'small') {
        dialogClasses += ' modal-sm'
    } else if (size === 'large') {
        dialogClasses += ' modal-lg'
    } else if (size === 'xl') {
        dialogClasses += ' modal-xl'
    }
    if (customClass) {
        dialogClasses += ' ' + customClass
    }

    return (
        <div>
            <div className="full-overlay" onClick={onClose}>
                <div className="modal fade show" tabIndex="-1" role="dialog" data-backdrop="static" style={{ display: 'block' }}>
                    <div className={'modal-dialog' + dialogClasses} data-cy="global__modal_dialog" role="document" onClick={e => e.stopPropagation()}>
                        <div className="modal-content">
                            {showCloseButton && (
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={onClose}
                                    style={{ cursor: 'pointer', position: 'absolute', fontSize: '1.5em', top: 0, right: 0, width: '32px', height: '32px', zIndex: '100' }}
                                >
                                    <i className="fal fa-times" />
                                </button>
                            )}
                            <div className="modal-body">{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SimpleModal
