import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '../global/Button'
import confirm from '../global/confirm'
import DatePicker from 'react-datepicker'
import LocalizedLink from '../global/LocalizedLink'
import Modal from '../global/Modal'
import moment from 'moment'
import OrderStatus from './OrderStatus'
import ReactTable from 'react-table'
import Spinner from '../global/Spinner'
import Translation from '../global/Translation'
import OfferOverview from './OfferOverview'
import {align} from "@progress/kendo-drawing";



const confirmRejectOrder = (reservationId, orderId, onRejectOrder, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_REJECT_ORDER' }), {intl: intl}).then(
        confirm => {
            return onRejectOrder(reservationId, orderId)
        },
        cancel => {
            return Promise.reject()
        }
    )
}

const confirmOptions = (intl) => {
    return {
        textCancel : intl.formatMessage({ id: 'no' }),
        textOk : intl.formatMessage({ id: 'yes' }),
    }
}

const confirmRejectQuote = (offer, onRejectQuote, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_REJECT_QUOTE' }, {intl: intl}), confirmOptions(intl))
        .then(
        confirm => {
            return onRejectQuote(offer)
        },
        cancel => {
            return Promise.reject()
        }
    )
}

const confirmAcceptQuote = (offer, onAcceptQuote, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_ACCEPT_QUOTE' }, {intl: intl}), confirmOptions(intl))
        .then(
        confirm => {
            return onAcceptQuote(offer)
        },
        cancel => {
            return Promise.reject()
        }
    )
}

class OfferStatusHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOrder: null,
            selectedOrderId: null,
            showOrderDetails: false,
            startDate: moment().add(-15, 'days'),
            endDate: moment(),
            installationData: null,
            oldInstallationData: null
        }
    }

    componentDidMount() {
        this.props.fetchOrders('offers', this.state.startDate, this.state.endDate)
    }

    haveInstallationData = original => {
        return original.notas
    }
    showOrderInstallationData = original => {
        let installationDataSplited = original.notas.split(' // ')
        if (installationDataSplited.length >= 6) {
            let name = installationDataSplited[0].replace('Name: ', '')
            let address = installationDataSplited[1].replace('Address: ', '')
            let town = installationDataSplited[2].replace('Town: ', '')
            let province = installationDataSplited[3].replace('Pronvince: ', '')
            let zip = installationDataSplited[4].replace('Zip Code: ', '')
            let phone = installationDataSplited[5].replace('Phone: ', '')
            let comments = installationDataSplited[6].replace('Comments: ', '')

            this.setState({ installationData: { name, address, town, province, zip, phone, comments } })
        } else if (original.notas) {
            this.setState({ oldInstallationData: original.notas })
        }
    }
    showOrderDetails = (reservationId, orderId) => {
        this.setState({ selectedOrder: reservationId, selectedOrderId: orderId, showOrderDetails: true })
    }

    acceptQuote = (original) => {
        confirmAcceptQuote(original, this.props.acceptQuote, this.props.intl)
    }

    rejectQuote = (original) => {
        confirmRejectQuote(original, this.props.rejectQuote, this.props.intl)
    }

    rejectOrder = (reservationId, orderId) => {
        confirmRejectOrder(reservationId, orderId, this.props.rejectOrder, this.props.intl)
    }
    closeOrderDetails = () => {
        this.setState({ selectedOrder: null, selectedOrderId: null, showOrderDetails: false })
    }
    search = e => {
        this.setState({ search: e.target.value })
    }
    changeStartDate = date => {
        this.setState({ startDate: date })
    }

    changeEndDate = date => {
        this.setState({ endDate: date }, () => this.props.fetchOrders('offers', this.state.startDate, this.state.endDate))
    }
    canDoActions = original => {
        if (original.statusAtRescab === 'RR') return true
        return !['06', '07', '08', '09'].includes(original.status)
    }
    render() {
        const { intl } = this.props
        let orders = this.props.type ? this.props.openOrders : this.props.orders
        let ordersFiltered = null

        if (this.props.isIkeaCenter)
            orders = orders.filter(row => {
                return row.shippingAddressId.includes(this.props.selectedShippingAddressForIkea)
            })

        if (this.state.search) {
            ordersFiltered = orders
            ordersFiltered = ordersFiltered.filter(row => {
                return (
                    row.reservationId.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    row.orderId.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    row.createdDate.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    row.customerOrder.toLowerCase().includes(this.state.search.toLowerCase())
                )
            })
        }

        const dataOrders = ordersFiltered !== null ? ordersFiltered : orders
        const columns = [
            {
                Header: <FormattedMessage id="actions" defaultMessage="Acciones" />,
                id: 'assign',
                Cell: ({ original }) => {
                    //if (original.statusText === 'CANCELLED') original.statusText = 'QUOTE_REJECTED'
                    //if (original.statusText === 'EXPIRED') original.statusText = 'QUOTE_EXPIRED'
                    return (
                        <React.Fragment>
                            <div className="container">
                                <div className="row">
                                    <Button className="col m-1" offers size="medium" fal icon="eye" onClick={() => this.showOrderDetails(original.reservationId, original.orderId)}>
                                        <FormattedMessage id="quote_overview" defaultMessage="Quote overview" />
                                    </Button>
                                </div>

                                {original.statusText === 'OFFER_CREATED' && (
                                    <div className="row">
                                        <Button className="col m-1" offers fal redIcon size="medium" icon="times" onClick={() => this.rejectQuote(original)}>
                                            <FormattedMessage id="reject" defaultMessage="Reject" />
                                        </Button>
                                        <Button className="col m-1" offers fal greenIcon size="medium" icon="check" onClick={() => this.acceptQuote(original)}>
                                            <FormattedMessage id="accept" defaultMessage="Accept" />
                                        </Button>

                                    </div>
                                )}
                            </div>
                            {' '}
                            {this.props.isIkeaCenter && this.haveInstallationData(original) && (
                                <React.Fragment>
                                    <div style={{ marginTop: '5px' }} />
                                    <Button inline size="small" icon="clipboard-list" far onClick={() => this.showOrderInstallationData(original)}>
                                        <FormattedMessage id="installation_data" defaultMessage="Installation Data" />
                                    </Button>
                                </React.Fragment>
                            )}
                            {original.rejected ? null : (
                                <React.Fragment>
                                    {' '}
                                    {this.props.canEditOrders && this.canDoActions(original) && (
                                        <LocalizedLink
                                            routeId="ROUTE_PROFILE"
                                            params={{ filter: 'edit-order', open: original.orderId }}
                                            onClick={e => {
                                                if (!original.orderId) e.preventDefault()
                                            }}
                                        >
                                            <Button inline size="small" icon="edit" disabled={!original.orderId}>
                                                <FormattedMessage id="edit_order" defaultMessage="Edit" />
                                            </Button>
                                        </LocalizedLink>
                                    )}{' '}
                                    {this.props.canDeleteOrders && this.canDoActions(original) && (
                                        <Button
                                            inline
                                            size="small"
                                            icon="trash"
                                            onClick={() => this.rejectOrder(original.reservationId, original.orderId)}
                                            disabled={!original.orderId || !original.reservationId}
                                        >
                                            <FormattedMessage id="delete" defaultMessage="delete" />
                                        </Button>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                },
                className: 'text-center',
                headerClassName: 'text-center',
                minWidth: 280
            },

            { Header: <FormattedMessage id="reservation_id" defaultMessage="Reservation ID" />, accessor: 'reservationId' },
            { Header: <FormattedMessage id="order_id" defaultMessage="Order ID" />, accessor: 'orderId' },
            { Header: <Translation id="quote" defaultMessage="Quote" />, accessor: 'offer' },
            {
                Header: <FormattedMessage id="customer_id" defaultMessage="Customer id" />,
                accessor: 'customerId',
                show: this.props.userType !== 'CUSTOMER'
            },
            { Header: <FormattedMessage id="created_date" defaultMessage="Created Date" />, accessor: 'createdDate' },
            { Header: <FormattedMessage id="customer" defaultMessage="Customer" />, accessor: 'customerOrder' },
            {
                Header: <FormattedMessage id="status" defaultMessage="Status" />,
                id: 'status',
                Cell: ({ original }) => {
                    return <OrderStatus original={original} rejected={original.rejected} />
                },
                className: 'text-center',
                headerClassName: 'text-center',
                minWidth: 250
            },
            { Header: <FormattedMessage id="container" defaultMessage="Container" />, accessor: 'container' },
            {
                Header: <FormattedMessage id="eta" defaultMessage="ETA" />,
                Cell: ({ original }) => {
                    return <span>{original.eta !== '0000-00-00' && original.eta}</span>
                },
                accessor: 'eta',
                minWidth: 100
            }
        ]
        const columnsOfIkea = [
            { Header: <FormattedMessage id="id_employee" defaultMessage="Id employee" />, accessor: 'employeeId' },
            { Header: <FormattedMessage id="addressee" defaultMessage="Addressee" />, accessor: 'customerName' }
        ]
        if (this.props.isIkeaCenter) columns.splice(7, 1, ...columnsOfIkea)
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="toolbar">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 order_header">
                                        <div className="group_title">
                                            <h4>
                                                {!this.props.type && <FormattedMessage id="quotes_history" defaultMessage="Quotes history" />}
                                                {this.props.type === 'open' && <FormattedMessage id="open_orders" defaultMessage="Open orders" />}
                                                {this.props.type === 'transfer' && <FormattedMessage id="transfer_orders" defaultMessage="Transfer orders" />}
                                            </h4>
                                        </div>
                                        <div className="group_date_picker">
                                            {this.props.type !== 'transfer' && (
                                                <React.Fragment>
                                                    <div className="group_date_picker__item">
                                                        <div className="name">
                                                            <FormattedMessage id="from" defaultMessage="From" /> <i className="fal fa-chevron-down" />
                                                        </div>
                                                        <DatePicker
                                                            selected={this.state.startDate}
                                                            selectsStart={true}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            maxDate={moment()}
                                                            onChange={selectedOption => this.changeStartDate(selectedOption, 'startDate')}
                                                            onFocus={() => this.setState({ endDate: null })}
                                                            autoComplete="off"
                                                            onKeyDown={e => e.preventDefault()}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            className="form-control form-control-sm date"
                                                        />
                                                    </div>
                                                    <div className="group_date_picker__item">
                                                        <div className="name">
                                                            <FormattedMessage id="to" defaultMessage="To" /> <i className="fal fa-chevron-down" />
                                                        </div>
                                                        <DatePicker
                                                            selected={this.state.endDate}
                                                            selectsEnd
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            minDate={this.state.startDate}
                                                            maxDate={
                                                                moment() < moment(this.state.startDate).add(3, 'month') ? moment() : moment(this.state.startDate).add(3, 'month')
                                                            }
                                                            onChange={selectedOption => this.changeEndDate(selectedOption, 'endDate')}
                                                            autoComplete="off"
                                                            onKeyDown={e => e.preventDefault()}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            className="form-control form-control-sm date"
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className="location search-bar search-bar--thin">
                                            <input
                                                type="text"
                                                data-qa="query"
                                                className="search-box"
                                                placeholder={intl.formatMessage({
                                                    id: 'search'
                                                })}
                                                value={this.state.search}
                                                onChange={e => this.setState({ search: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ReactTable
                            data={dataOrders}
                            loading={this.props.isLoading}
                            loadingText={<Spinner isLoading={this.props.isLoading} />}
                            className="-highlight"
                            columns={columns}
                            defaultSorted={[{ id: 'reservationId', desc: true }]}
                            defaultPageSize={8}
                            pageSizeOptions={[8, 16, 32, 64, 128]}
                            previousText={<FormattedMessage id="previous" defaultMessage="Previous" />}
                            nextText={<FormattedMessage id="next" defaultMessage="Next" />}
                            noDataText={<FormattedMessage id="no_data" defaultMessage="No data available" />}
                            pageText={<FormattedMessage id="page" defaultMessage="Page" />}
                            ofText={<FormattedMessage id="of" defaultMessage="Of" />}
                            getTrProps={(state, rowInfo) => {
                                let cursor = rowInfo ? 'pointer' : 'default'
                                let clases = ''
                                if (rowInfo) {
                                    if (rowInfo.original.rejected) clases += 'reject '
                                }
                                return {
                                    // onClick: e => {
                                    //     if (rowInfo.original.reservationId) {
                                    //         this.showOrderDetails(rowInfo.original.reservationId, rowInfo.original.orderId)
                                    //     }
                                    // },
                                    style: { cursor: cursor },
                                    className: clases
                                }
                            }}
                        />
                        {this.state.showOrderDetails && (
                            <OfferOverview
                                order={this.state.selectedOrder}
                                orderId={this.state.selectedOrderId}
                                onClose={() => this.closeOrderDetails()}
                                canEditOrders={this.props.canEditOrders}
                                isOffer={true}
                            />
                        )}
                        {(this.state.installationData || this.state.oldInstallationData) && (
                            <Modal
                                title={<FormattedMessage id="installation_data" defaultMessage="Installation Data" />}
                                onClose={() => this.setState({ installationData: null })}
                                size="large"
                                footer={
                                    <div className="modal-footer__buttons">
                                        <Button onClick={() => this.setState({ installationData: null, oldInstallationData: null })} inverted size="medium">
                                            <FormattedMessage id="ok" defaultMessage="Ok" />
                                        </Button>
                                    </div>
                                }
                            >
                                {this.state.installationData && (
                                    <React.Fragment>
                                        <FormattedMessage id="name" defaultMessage="Name" />: <span>{this.state.installationData.name}</span> <br />
                                        <FormattedMessage id="address" defaultMessage="Address" />: <span>{this.state.installationData.address}</span> <br />
                                        <FormattedMessage id="town" defaultMessage="Town" />: <span>{this.state.installationData.town}</span> <br />
                                        <FormattedMessage id="province" defaultMessage="Province" />: <span>{this.state.installationData.province}</span> <br />
                                        <FormattedMessage id="zip" defaultMessage="Zip Code" />: <span>{this.state.installationData.zip}</span> <br />
                                        <FormattedMessage id="phone" defaultMessage="Phone" />: <span>{this.state.installationData.phone}</span> <br />
                                        <FormattedMessage id="comments" defaultMessage="Comments" />: <span>{this.state.installationData.comments}</span> <br />
                                    </React.Fragment>
                                )}
                                {this.state.oldInstallationData && <span>{this.state.oldInstallationData}</span>}
                            </Modal>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(OfferStatusHistory)
