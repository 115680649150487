import { connect } from 'react-redux'
import NewOrderDetails from './NewOrderDetails'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import {
    getOrder,
    getInvoices,
    getOrderDetailsLoading,
    getOrderStatusMap,
    getOrderToEdit,
    getOrderInEdition,
} from '../../../../store/orders/reducers'
import { fetchOrderDetails, getFileByUrl, rejectOrder, fetchOrderToEdit, saveOrderToEdit, canEditOrderMinMax, changeOrderInEdition } from '../../../../store/orders/actions'
import { getToken, isEmserUser, getImpersonatedBy } from '../../../../store/login/reducers'
import { getShippingAddresses } from '../../../../store/shipping-addresses/reducers'
import { getAdaptedFormat, getCtsMeasures, getReplaceDotWithComma } from '../../../../helpers'
import { dispatchPushURL, setIsOrderHistoryScreen } from '../../../../store/ui/actions'
import { getLanguage } from '../../../../store/ui/reducers'

const mapStateToProps = (state, { match }) => {
    return {
        order: getOrder(state),
        isLoading: getOrderDetailsLoading(state),
        reservationId: match.params.reservationId,
        shippingAddressesByUser: getShippingAddresses(state),
        orderStatusMap: getOrderStatusMap(state),
        isEmserUser: isEmserUser(state),
        invoices: getInvoices(state),
        token: getToken(state),
        orderToEdit: getOrderToEdit(state),
        orderInEdition: getOrderInEdition(state),
        locale: getLanguage(state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        getMeasure: (cutToSize) => getCtsMeasures(cutToSize, state),
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state),
        impersonatedBy: getImpersonatedBy(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrderDetails: reservationId => {
            return dispatch(fetchOrderDetails(reservationId))
        },
        fetchOrderToEdit: orderId => dispatch(fetchOrderToEdit(orderId)),
        saveOrderToEdit: (order, orderId, reservationId) => dispatch(saveOrderToEdit(order, orderId, reservationId)),
        canEditOrderMinMax: () => dispatch(canEditOrderMinMax()),
        changeOrderInEdition: orderInEdition => dispatch(changeOrderInEdition(orderInEdition)),
        rejectOrder: (reservationId, orderId) => dispatch(rejectOrder(reservationId, orderId)),
        getFileByUrl: url => dispatch(getFileByUrl(url)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        setIsOrderHistoryScreen: isOrderHistoryScreen => {
            dispatch(setIsOrderHistoryScreen(isOrderHistoryScreen))
        }
    }
}

const NewOrderDetailsContainer = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewOrderDetails)))

export default NewOrderDetailsContainer
