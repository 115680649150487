import React from 'react'
import Translation from '../global/Translation'

import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import iconBars from '../../assets/img/icons/filter.svg'
import iconClose from '../../assets/img/icons/close-white.svg'
import { injectIntl } from 'react-intl'

import { fetchKpis, setKpisFilters } from '../../store/kpis/actions'
import { getSummary, getFilters, getFiltersOpen } from '../../store/kpis/reducers'
import { connect } from 'react-redux'
import Button from '../global/Button'
import { toggleKpisFilters } from '../../store/ui/actions'

const mapStateToProps = state => {
    return {
        summary: getSummary(state),
        filters: getFilters(state),
        filtersOpen: getFiltersOpen(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchKpis: () => {
            dispatch(fetchKpis())
        },
        setKpisFilters: (startDate, endDate, centers, customers, topColors) => {
            dispatch(setKpisFilters(startDate, endDate, centers, customers, topColors))
        },
        toggleKpisFilters: showFilters => {
            dispatch(toggleKpisFilters(showFilters))
        }
    }
}
class KpisFilters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: this.props.filtersOpen,
            centers: this.props.filters.centers,
            customers: this.props.filters.customers,
            topColors: this.props.filters.topColors,
            startDate: moment().startOf('year'),
            endDate: moment()
        }
    }
    toggleKpisFilters = showFilters => {
        this.setState({ isOpen: showFilters }, () => {
            this.props.toggleKpisFilters(showFilters)
        })
    }
    setKpisFilters = () => {
        this.props.setKpisFilters(this.state.startDate, this.state.endDate, this.state.centers, this.state.customers, this.state.topColors)
    }
    changeStartDate = date => {
        this.setState(
            {
                startDate: date
            },
            function() {
                this.setKpisFilters()
            }
        )
    }

    changeEndDate = date => {
        this.setState(
            {
                endDate: date
            },
            function() {
                this.setKpisFilters()
            }
        )
    }
    handleFilterChange = (selectedOption, filter) => {
        if (selectedOption !== null) {
            let selected = selectedOption

            this.setState({ [filter]: selected }, function() {
                this.setKpisFilters()
            })
        } else {
            this.setState({ [filter]: selectedOption }, function() {
                this.setKpisFilters()
            })
        }
    }
    selectColor = colorId => {
        if (this.state.topColors.includes(colorId)) {
            this.setState(
                {
                    topColors: this.state.topColors.filter(color => color !== colorId)
                },
                function() {
                    this.setKpisFilters()
                }
            )
        } else {
            this.setState(
                {
                    topColors: this.state.topColors.concat(colorId)
                },
                function() {
                    this.setKpisFilters()
                }
            )
        }
    }
    flushFilters = () => {
        this.setState(
            {
                centers: [],
                customers: [],
                topColors: [],
                startDate: moment().startOf('year'),
                endDate: moment()
            },
            function() {
                this.setKpisFilters()
            }
        )
    }
    render() {
        const { isOpen } = this.state
        return (
            <div className="row">
                <div className="col-8 offset-2">
                    <div className="row">
                        <div className="col-12">
                            <h1>
                                <Translation id="activity" defaultMessage="Activity" />
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="filter-bar">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="date-filter col-12 col-sm-6">
                                                <div className="start-date">
                                                    <div className="name">
                                                        <Translation id="from" defaultMessage="From" /> <i className="fal fa-chevron-down" />
                                                    </div>
                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        selectsStart
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        maxDate={this.state.endDate}
                                                        onChange={selectedOption => this.changeStartDate(selectedOption, 'startDate')}
                                                        autoComplete="off"
                                                        onKeyDown={e => e.preventDefault()}
                                                        className="form-control form-control-sm date"
                                                    />
                                                </div>
                                            </div>
                                            <div className="date-filter col-12 col-sm-6">
                                                <div className="end-date">
                                                    <div className="name">
                                                        <Translation id="to" defaultMessage="To" /> <i className="fal fa-chevron-down" />
                                                    </div>
                                                    <DatePicker
                                                        selected={this.state.endDate}
                                                        selectsEnd
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        minDate={this.state.startDate}
                                                        onChange={selectedOption => this.changeEndDate(selectedOption, 'endDate')}
                                                        autoComplete="off"
                                                        onKeyDown={e => e.preventDefault()}
                                                        className="form-control form-control-sm date"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-1">
                    <div className="more-filters">
                        <div className="bt-open-more-filters" onClick={() => this.toggleKpisFilters(!this.state.isOpen)}>
                            <img alt="" src={isOpen ? iconClose : iconBars} />
                        </div>

                        <div className="more-filters-container" style={{ visibility: isOpen ? 'visible' : 'hidden' }}>
                            <div className="filters">
                                <div className="filter">
                                    <div className="label">
                                        <Translation id="centers" defaultMessage="Centers" />
                                    </div>
                                    <div className="options">
                                        <Select
                                            placeholder={this.props.intl.formatMessage({ id: 'select' })}
                                            multi={true}
                                            value={this.props.filters.centers}
                                            onChange={selectedOption => this.handleFilterChange(selectedOption, 'centers')}
                                            options={
                                                this.props.summary &&
                                                this.props.summary.centers.map(option => ({
                                                    label: option.name + ` (${option.count})`,
                                                    value: option.id
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="label">
                                        <Translation id="client" defaultMessage="Client" />
                                    </div>
                                    <div className="options">
                                        <Select
                                            placeholder={this.props.intl.formatMessage({ id: 'select' })}
                                            multi={true}
                                            value={this.props.filters.customers}
                                            onChange={selectedOption => this.handleFilterChange(selectedOption, 'customers')}
                                            options={
                                                this.props.summary &&
                                                this.props.summary.customers.map(option => ({
                                                    label: option.name + ` (${option.count})`,
                                                    value: option.id
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="label">
                                        <Translation id="top_color" defaultMessage="Top Color" />
                                    </div>
                                    <div className="options">
                                        {this.props.summary &&
                                            this.props.summary.topColors.map(option => (
                                                <div className="option">
                                                    <input
                                                        id={option.id}
                                                        type="checkbox"
                                                        onClick={() => this.selectColor(option.id)}
                                                        key={option.id}
                                                        checked={this.props.filters.topColors.includes(option.id)}
                                                    />
                                                    <label for={option.id} className="text">
                                                        {option.name} ({option.count})
                                                    </label>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="refresh row">
                                        <div className="col-12">
                                            <Button size="small" onClick={() => this.flushFilters()}>
                                                <i className="fa fa-sync" /> <Translation id="clear_all" defaultMessage="Clear all" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
KpisFilters = connect(
    mapStateToProps,
    mapDispatchToProps
)(KpisFilters)

export default injectIntl(KpisFilters)
