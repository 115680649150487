import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import LocalizedLink from '../../global/LocalizedLink'
import { Document, Page, pdfjs } from 'react-pdf'
import confirm from '../../global/extendedConfirm'
import Translation from '../../global/Translation'
import { handlePlanPdfDownload, getFormatConfigData, getCheckoutLineId, getConfigurablePlanPdfUrl } from '../utils'
import Spinner from '../../global/Spinner'

class ConfigCutToSizePlanAcceptance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            numPages: null,
            isPlanAcceptanceChecked: false,
            isLeavingIntentionally: false,
            configurableDrawingIsLoading: true
        }

        // Store intervalId to clear it later
        this.intervalId = null
    }

    componentDidMount() {
        this.props.setIsConfigurablePlanAcceptanceScreen(true)

        // Set the worker from CDN. DO NOT DELETE THIS. Is required for react-pdf
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

        window.addEventListener('beforeunload', this.handleBeforeUnload)
        this.unblock = this.props.history.block((location, action) => {
            if (!this.state.isLeavingIntentionally && !this.state.isPlanAcceptanceChecked) {
                this.handleNavigationAttempt(() => {
                    this.setState({ isLeavingIntentionally: true }, () => {
                        this.unblock() // Allow the navigation after confirmation
                        this.props.history.push(location.pathname) // Proceed to the intended location
                    })
                })
                return false // Prevent navigation until confirmed
            }
            return true // Allow navigation if already leaving intentionally or plan is checked
        })

        if (this.props.refcode) {
            this.pollForDrawing(this.props.refcode)
        }
    }

    pollForDrawing(refcode) {
        this.setState({ configurableDrawingIsLoading: true })
        
        const maxAttempts = 5
        let attemptCount = 0
    
        const intervalId = setInterval(() => {
            attemptCount++
    
            this.props.fetchConfigurableDrawing(refcode)
                .then((response) => {
                    if (response.status === 200) {
                        const { exists, documentUrl } = response.data
    
                        if (exists && documentUrl) {
                            this.setState({ configurableDrawingIsLoading: false })
                            clearInterval(intervalId)
                        } else if (attemptCount >= maxAttempts) {
                            // Stop polling after maxAttempts if no documentUrl is generated
                            console.error('pollForDrawing -> Failed to generate URL after maximum attempts.')
                            this.setState({ configurableDrawingIsLoading: false })
                            clearInterval(intervalId)
                        }
                    } else {
                        // If the response status is not 200, stop polling
                        console.error('pollForDrawing -> Backend service is not available, stopping polling.');
                        this.setState({ configurableDrawingIsLoading: false })
                        clearInterval(intervalId)
                    }
                })
                .catch((error) => {
                    console.error('pollForDrawing -> Error fetching drawing:', error)
                    this.setState({ configurableDrawingIsLoading: false })
                    clearInterval(intervalId)
                })
    
        }, 4000) // Poll every 4 seconds
    }
    
    componentWillUnmount() {
        this.props.setIsConfigurablePlanAcceptanceScreen(false)
        
        window.removeEventListener('beforeunload', this.handleBeforeUnload)

        if (this.unblock) this.unblock()

        // Clear the interval if it's still running
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
    }

    changeCartLineCutToSize = (config) => {
        this.props.changeCartLineCutToSize(this.getCheckoutLineIdForCurrentConfig(), this.getFormattedData(config))
    }

    getCheckoutLineIdForCurrentConfig = () => {
        const { checkoutLines, refcode } = this.props

        return getCheckoutLineId(checkoutLines, refcode)
    }

    handleBeforeUnload = (event) => {
        const { isPlanAcceptanceChecked, isLeavingIntentionally } = this.state
        
        // If the user is not intentionally leaving and the plan is not accepted, show confirmation
        if (!isLeavingIntentionally && !isPlanAcceptanceChecked) {
            event.preventDefault()
            event.returnValue = ''
        }
    }

    handleNavigationAttempt = (onConfirm) => {
        confirm(this.props.intl.formatMessage({ id: 'leave_page_plan_acceptance_confirmation_message' }), {
            isV2: true,
            advise: this.props.intl.formatMessage({ id: 'leave_page_plan_acceptance_advise_message' }),
            textCancel: this.props.intl.formatMessage({ id: 'cancel' }),
            textOk: this.props.intl.formatMessage({ id: 'leave_page_plan_acceptance_textok_message' })
        }).then(
            () => {
                onConfirm()
            },
            () => {
                return Promise.reject()
            }
        )
    }

    handleBackToEditClick = (event) => {
        event.preventDefault()
        const { location } = this.props

        // Extract the parts of the current location's pathname
        const currentPath = location.pathname
        const updatedPath = currentPath.replace("/configurable-plan-acceptance", "/configurables")

        this.setState({ isLeavingIntentionally: true }, () => {
            this.props.history.push(updatedPath)
        })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages })
    }

    handlePlanAcceptanceCheckboxChange = () => {
        this.setState(prevState => ({ isPlanAcceptanceChecked: !prevState.isPlanAcceptanceChecked }))
    }

    getFormattedData = config => {
        const { isPlanAcceptanceChecked } = this.state
        const { intl, units, refcode } = this.props

        return getFormatConfigData(config, isPlanAcceptanceChecked, intl.locale, units, this.getPlanPdfUrl(), refcode)
    }

    handlePlanAcceptance = () => {
        const { history, configurableInProgress, refcode, addCartLine, changeCartLineCutToSize, addConfigurablePlanAcceptanceChecked, localizedUrl, configurableModifiedList } = this.props
        const { isPlanAcceptanceChecked } = this.state
    
        if (isPlanAcceptanceChecked) {
            const currentConfig = configurableInProgress[refcode]
            const isModified = configurableModifiedList[refcode] ? configurableModifiedList[refcode].isConfigurableModified : null

            if (currentConfig) {
                addConfigurablePlanAcceptanceChecked(refcode, true).then(() => {
                    if (isModified === true) {
                        // Update the existing cart line
                        changeCartLineCutToSize(this.getCheckoutLineIdForCurrentConfig(), this.getFormattedData(currentConfig))
                    } else {
                        // Add new item to the cart
                        addCartLine(currentConfig.quantity, this.getFormattedData(currentConfig))
                    }

                    const routePath = localizedUrl('ROUTE_CHECKOUT')
                    history.push(routePath)

                }).catch(error => {
                    console.error('handlePlanAcceptance -> Failed to update the state', error)
                })
            }
        }
    }

    getPlanPdfUrl = () => {
        const { configurableDrawingList, refcode } = this.props
        return getConfigurablePlanPdfUrl(configurableDrawingList, refcode)
    }

    onDownloadClick = () => {
        const { model, refcode } = this.props
        const url = this.getPreparedUrlForDownload()

        handlePlanPdfDownload(url, model, refcode)
    }

    getPreparedUrlForDownload = () => {
        if (this.getPlanPdfUrl()) {
            return process.env.REACT_APP_API_HOST + '/helper/view-pdf-url/?url=' + encodeURIComponent(this.getPlanPdfUrl())
        }

        return null
    }

    render() {
        const { model, refcode, configurableDrawingList} = this.props
        const { isPlanAcceptanceChecked, numPages, configurableDrawingIsLoading } = this.state

        const checkbokAndButtonBlock = (
            <div className="accept-plan-checkbox">
                <div className="checkbox-block">
                    <div>
                        <input type="checkbox" id="accept-checkbox" checked={isPlanAcceptanceChecked} onChange={this.handlePlanAcceptanceCheckboxChange} />
                    </div>
                    <div>
                        <label htmlFor="accept-checkbox">
                            <Translation id="config_plan_acceptance_agreement_with_drawing" defaultMessage="I agree with the drawing and the information included in it for ordering the product." />
                        </label>
                    </div>
                </div>
                <div className="button-block">
                    <button onClick={this.handlePlanAcceptance} disabled={!isPlanAcceptanceChecked} className="accept-plan-button">
                        <Translation id="continue" defaultMessage="Continue" />
                    </button>
                </div>
            </div>
        )

        return (
            <div className="container config__plan__acceptance">
                <div className="config__plan__acceptance--back-btn">
                    <LocalizedLink
                        routeId="ROUTE_CONFIG_CUT_TO_SIZE" 
                        params={{ model: model, refcode: refcode }} 
                        onClick={(event) => this.handleBackToEditClick(event)}
                    >
                        <button>
                            <i className="fas fa-chevron-left"></i>
                            <Translation id="back_to_edit" defaultMessage="Back to edit" />
                        </button>
                    </LocalizedLink>
                </div>
                <section className="config__plan__acceptance--content-container">
                    <div className="main-heading">
                        <h1><Translation id="config_plan_acceptance_review_and_accept_the_plan" defaultMessage="Review and accept the plan" /></h1>
                    </div>
                    <div className="pdf-text-content">
                        <div className="pdf-viewer">
                            {configurableDrawingIsLoading ? (
                                <Spinner isLoading={true} innerCenter />
                            ) : (
                                <Document 
                                    file={this.getPreparedUrlForDownload()}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    className="config-plan-acceptance-document"
                                    loading={<Translation id="config_plan_acceptance_plan_display_is_being_generated" defaultMessage="The plan display is being generated, this may take a few seconds." />}
                                >
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                    ))}
                                </Document>
                            )}
                        </div>
                        <div className="text-checkbox">
                            <div>
                                <p className="main-paragraph">
                                    <Translation id="config_plan_acceptance_review_the_document_containing_the_drawing" defaultMessage="Please review the document containing the drawing and all the technical information of the product you are going to buy." />
                                </p>

                                <p className="second-paragraph">
                                    <Translation id="config_plan_acceptance_remember_the_acceptance_will_be_used_for_order" defaultMessage="Remember that the acceptance of this plan implies the acceptance of the document and all the information contained in it and that it will be used for the realization of the order of the requested product." />
                                </p>

                                <p className="normal-paragraph">
                                    <Translation id="config_plan_acceptance_review_the_document_prior" defaultMessage="It is important that you review the document prior to acceptance." />
                                </p>
                                {getConfigurablePlanPdfUrl(configurableDrawingList, refcode) && (
                                    <div className="downlow-plan-acceptance">
                                        <button onClick={this.onDownloadClick}>
                                            <i className="fas fa-download" />
                                            <Translation id="download_map" defaultMessage="Download map" />
                                        </button>
                                    </div>
                                )}
                                {checkbokAndButtonBlock}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default withRouter(ConfigCutToSizePlanAcceptance)
