import ReactTooltip from 'react-tooltip'

import React from 'react'

import { injectIntl } from 'react-intl'
import Translation from '../../../global/Translation'

class DisplayStock extends React.Component {
    render() {
        let noStockTooltip = (
            <React.Fragment>
                <i
                    className="fa fa-info-circle"
                    style={{ margin: '5px', flex: '0 0 auto', color: '' }}
                    data-tip={this.props.intl.formatMessage({
                        id: 'theres_no_stock_available_now',
                        defaultMessage: 'You can buy this product even if it is out of stock. We will delivery it to you from our supply chain. Delivery time may increase slightly'
                    })}
                    data-for={this.props.product.productId}
                />
                <ReactTooltip id={this.props.product.productId} effect="solid" place="top" />
            </React.Fragment>
        )
        let stock
        if (this.props.product.fromMall) {
            if(this.props.product.realStock){
                stock = this.props.product.realStock
            } else {
                stock = this.props.value
            }
        } else if (this.props.watchingOutlet) {
            stock = this.props.product.stock_outlet
        } else {
            if (this.props.product.threshold && this.props.product.threshold >= this.props.product.stock) {
                stock = ' < ' + this.props.product.threshold
            } else if (this.props.canViewThresholdMax && this.props.product.thresholdMax && this.props.product.thresholdMax <= this.props.product.stock) {
                stock = ' > ' + this.props.product.thresholdMax
            } else {
                stock = this.props.product.stock
            }
        }
        return (
            <div className={`product_detail stock`} style={{ color: !this.props.value ? '#232323' : '#36A596' }} key={this.props.product.productId}>
                {(this.props.icon || this.props.faIconClass) && (
                    <div className="icon">
                        {this.props.icon && <img alt="" src={this.props.icon} />}
                        {this.props.faIconClass && <i className={this.props.faIconClass} />}
                    </div>
                )}

                {this.props.product.type === 'TABLA' ? (
                    <React.Fragment>
                        <div className="text">
                            {this.props.value >= 1 && (
                                <React.Fragment>
                                    {stock} <Translation id="slabs_availables_on_stock" defaultMessage="Slabs availables on stock" />
                                </React.Fragment>
                            )}
                            {(this.props.value < 1 || !this.props.value) && !this.props.product.fromMall && (
                                <React.Fragment>
                                    {0} <Translation id="slabs_availables_on_stock" defaultMessage="Slabs availables on stock" />
                                    {noStockTooltip}
                                </React.Fragment>
                            )}
                            {(this.props.value < 1 || !this.props.value) && this.props.product.fromMall && (
                                <div className="price-landing-mall">
                                    {0} <Translation id="slabs_availables_on_stock" defaultMessage="Slabs availables on stock" />
                                </div>
                            )}
                        </div>
                        {this.props.value >= 1 && !this.props.hideSurface && (
                            <div className="length">
                                <Translation id="that_would_cover" defaultMessage="That would cover" /> {this.props.surface}
                                {this.props.measure}
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <div className="text">
                        {this.props.value} <Translation id="units_availables_on_stock" defaultMessage="Units availables on stock" /> {this.props.value < 1 && noStockTooltip}
                    </div>
                )}
            </div>
        )
    }
}
export default injectIntl(DisplayStock)
