import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { getLocaleWithft2 } from '../../store/products/reducers'
import { withRouter } from 'react-router-dom'
import { canViewThresholdMax } from '../../store/ui/reducers'
import { getReplaceDotWithComma } from '../../helpers'

const mapStateToProps = (state, ownProps) => {
    return {
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state),
        userPreferences: getProfileConfiguration(state),
        localeWithft2: getLocaleWithft2(),
        outlet: ownProps.isSellingOutlet === 'X' ? true : ownProps.match.params.outlet,
        canViewThresholdMax: canViewThresholdMax(state)

    }
}
const LengthMeasure = ({ qty, userPreferences, intl, product, localeWithft2, outlet, canViewThresholdMax, replaceDotWithComma }) => {
    const defaultStockM2 = outlet ? product.stock_outlet_m2 : product.stock_m2
    const defaultStockFt2 = outlet ? product.stock_outlet_ft2 : product.stock_ft2

    const stockM2 = defaultStockM2 ? replaceDotWithComma(defaultStockM2, intl.locale) : defaultStockM2
    const stockFt2 = defaultStockFt2 ? replaceDotWithComma(defaultStockFt2, intl.locale) : defaultStockFt2

    const userLengthPrefer = userPreferences.lengthUnit === 'M2' ? stockM2 : stockFt2
    const lengthDefault = localeWithft2.includes(intl.locale) ? stockFt2 : stockM2
    const lengthToShow = userPreferences.lengthUnit ? userLengthPrefer : lengthDefault
    let result
    if (outlet) {
        result = lengthToShow
    } else {
        if (product.threshold && product.threshold >= product.stock) {
            // medida calculada
            if (product.stock + product.stock_outlet > 0 && lengthToShow) {
                // parseInt returns NaN if the first character cannot be converted to a number
                let numericLengthToShow = parseInt(lengthToShow)
                result = ' < ' + parseInt((numericLengthToShow / (product.stock + product.stock_outlet)) * product.threshold)
            } 
            // 5 : 54 (medida aproximada de una tabla)
            else if (userPreferences.lengthUnit) result = userPreferences.lengthUnit === 'M2' ? ' < ' + 5 * product.threshold : ' < ' + 54 * product.threshold
            // 54 : 5 (medida aproximada de una tabla)
            else result = localeWithft2.includes(intl.locale) ? ' < ' + 54 * product.threshold : ' < ' + 5 * product.threshold
        } else if (canViewThresholdMax && product.thresholdMax && product.thresholdMax <= product.stock) {
            // medida calculada
            if (product.stock + product.stock_outlet > 0 && lengthToShow) {
                // parseInt returns NaN if the first character cannot be converted to a number
                let numericLengthToShow = parseInt(lengthToShow)
                result = ' > ' + parseInt((numericLengthToShow / (product.stock + product.stock_outlet)) * product.thresholdMax)
            } 
            // 5 : 54 (medida aproximada de una tabla)
            else if (userPreferences.lengthUnit) result = userPreferences.lengthUnit === 'M2' ? ' > ' + 5 * product.thresholdMax : ' > ' + 54 * product.thresholdMax
            // 54 : 5 (medida aproximada de una tabla)
            else result = localeWithft2.includes(intl.locale) ? ' > ' + 54 * product.thresholdMax : ' > ' + 5 * product.thresholdMax
        } else {
            result = lengthToShow
        }
    }
    return result
}

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            null
        )(LengthMeasure)
    )
)
