import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import '@progress/kendo-theme-default/dist/all.css'
import Button from '../global/Button'
import LocalizedLink from '../global/LocalizedLink'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { process } from '@progress/kendo-data-query'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { Input } from '@progress/kendo-react-inputs'
import confirm from '../global/confirm'
import SimpleModal from '../global/SimpleModal'
import { getMarkets, getCenters, getCountries, getFilteredLandings, getStatuses, advParseLang } from './utils'
import { defineMessages } from 'react-intl'
import { ActionsCell } from './components/ActionsCell'

defineMessages({
    ROUTE_LANDING_TEMPLATE: {
        id: 'ROUTE_LANDING_TEMPLATE',
        description: 'ROUTE_LANDING_TEMPLATE',
        defaultMessage: '/en/landing-templates/:slug?/:iso?/:preview?'
    },
    countries: {
        id: 'countries',
        description: 'countries',
        defaultMessage: 'Countries'
    },
    country: {
        id: 'country',
        description: 'country',
        defaultMessage: 'Country'
    },
    country_AE: {
        id: 'country_AE',
        description: 'country_AE',
        defaultMessage: 'United Arab Emirates'
    },
    country_AT: {
        id: 'country_AT',
        description: 'country_AT',
        defaultMessage: 'Austria'
    },
    country_AU: {
        id: 'country_AU',
        description: 'country_AU',
        defaultMessage: 'Australia'
    },
    country_BE: {
        id: 'country_BE',
        description: 'country_BE',
        defaultMessage: 'Belgium'
    },
    country_BR: {
        id: 'country_BR',
        description: 'country_BR',
        defaultMessage: 'Brazil'
    },
    country_CA: {
        id: 'country_CA',
        description: 'country_CA',
        defaultMessage: 'Canada'
    },
    country_CH: {
        id: 'country_CH',
        description: 'country_CH',
        defaultMessage: 'Switzerland'
    },
    country_DE: {
        id: 'country_DE',
        description: 'country_DE',
        defaultMessage: 'Germany'
    },
    country_DK: {
        id: 'country_DK',
        description: 'country_DK',
        defaultMessage: 'Denmark'
    },
    country_DU: {
        id: 'country_DU',
        description: 'country_DU',
        defaultMessage: 'Dubai'
    },
    country_ES: {
        id: 'country_ES',
        description: 'country_ES',
        defaultMessage: 'Spain'
    },
    country_FI: {
        id: 'country_FI',
        description: 'country_FI',
        defaultMessage: 'Finland'
    },
    country_FR: {
        id: 'country_FR',
        description: 'country_FR',
        defaultMessage: 'France'
    },
    country_GB: {
        id: 'country_GB',
        description: 'country_GB',
        defaultMessage: 'United Kingdom'
    },
    country_IE: {
        id: 'country_IE',
        description: 'country_IE',
        defaultMessage: 'Ireland'
    },
    country_IL: {
        id: 'country_IL',
        description: 'country_IL',
        defaultMessage: 'Israel'
    },
    country_IT: {
        id: 'country_IT',
        description: 'country_IT',
        defaultMessage: 'Italy'
    },
    country_JP: {
        id: 'country_JP',
        description: 'country_JP',
        defaultMessage: 'Japan'
    },
    country_MX: {
        id: 'country_MX',
        description: 'country_MX',
        defaultMessage: 'Mexico'
    },
    country_MY: {
        id: 'country_MY',
        description: 'country_MY',
        defaultMessage: 'Malaysia'
    },
    country_NL: {
        id: 'country_NL',
        description: 'country_NL',
        defaultMessage: 'Netherlands'
    },
    country_NO: {
        id: 'country_NO',
        description: 'country_NO',
        defaultMessage: 'Norway'
    },
    country_NZ: {
        id: 'country_NZ',
        description: 'country_NZ',
        defaultMessage: 'New Zealand'
    },
    country_PL: {
        id: 'country_PL',
        description: 'country_PL',
        defaultMessage: 'Poland'
    },
    country_PR: {
        id: 'country_PR',
        description: 'country_PR',
        defaultMessage: 'Puerto Rico'
    },
    country_PT: {
        id: 'country_PT',
        description: 'country_PT',
        defaultMessage: 'Portugal'
    },
    country_SE: {
        id: 'country_SE',
        description: 'country_SE',
        defaultMessage: 'Sweden'
    },
    country_SG: {
        id: 'country_SG',
        description: 'country_SG',
        defaultMessage: 'Singapore'
    },
    country_TR: {
        id: 'country_TR',
        description: 'country_TR',
        defaultMessage: 'Turkey'
    },
    country_US: {
        id: 'country_US',
        description: 'country_US',
        defaultMessage: 'United States'
    },
    country_ZA: {
        id: 'country_ZA',
        description: 'country_ZA',
        defaultMessage: 'South Africa'
    },
    country_AF: {
        id: 'country_AF',
        description: 'country_AF',
        defaultMessage: 'Afghanistan'
    },
    country_AO: {
        id: 'country_AO',
        description: 'country_AO',
        defaultMessage: 'Angola'
    },
    country_CN: {
        id: 'country_CN',
        description: 'country_CN',
        defaultMessage: 'China'
    },
    country_EG: {
        id: 'country_EG',
        description: 'country_EG',
        defaultMessage: 'Egypt'
    },
    country_GR: {
        id: 'country_GR',
        description: 'country_GR',
        defaultMessage: 'Greece'
    },
    country_IN: {
        id: 'country_IN',
        description: 'country_IN',
        defaultMessage: 'India'
    },
    country_IR: {
        id: 'country_IR',
        description: 'country_IR',
        defaultMessage: 'Iran'
    },
    country_MU: {
        id: 'country_MU',
        description: 'country_MU',
        defaultMessage: 'Republic of Mauritius'
    },
    country_MZ: {
        id: 'country_MZ',
        description: 'country_MZ',
        defaultMessage: 'Mozambique'
    },
    country_PK: {
        id: 'country_PK',
        description: 'country_PK',
        defaultMessage: 'Pakistan'
    },
    country_TN: {
        id: 'country_TN',
        description: 'country_TN',
        defaultMessage: 'Tunisia'
    },
    country_VN: {
        id: 'country_VN',
        description: 'country_VN',
        defaultMessage: 'Vietnam'
    },
    country_ZW: {
        id: 'country_ZW',
        description: 'country_ZW',
        defaultMessage: 'Zimbabwe'
    },
    trick: {
        id: 'trick',
        description: 'trick',
        defaultMessage: 'trick'
    },
    ending_date: {
        id: 'ending_date',
        description: 'ending_date',
        defaultMessage: 'End date'
    },
    markets: {
        id: 'markets',
        description: 'markets',
        defaultMessage: 'Markets'
    },
    order_by: {
        id: 'order_by',
        description: 'order_by',
        defaultMessage: 'Order by'
    },
    remove_success: {
        id: 'remove_success',
        description: 'remove_success',
        defaultMessage: 'Remove success'
    },
    start_date: {
        id: 'start_date',
        description: 'start_date',
        defaultMessage: 'Start date'
    },
    status_ALL: {
        id: 'status_ALL',
        description: 'status_ALL',
        defaultMessage: 'All'
    },
    status_DRAFT: {
        id: 'status_DRAFT',
        description: 'status_DRAFT',
        defaultMessage: 'Draft'
    },
    status_EXPIRED: {
        id: 'status_EXPIRED',
        description: 'status_EXPIRED',
        defaultMessage: 'Expired'
    },
    status_PUBLISHED: {
        id: 'status_PUBLISHED',
        description: 'status_PUBLISHED',
        defaultMessage: 'Published'
    },
    status_SCHEDULE: {
        id: 'status_SCHEDULE',
        description: 'status_SCHEDULE',
        defaultMessage: 'Schedule'
    },
    translations: {
        id: 'translations',
        description: 'translations',
        defaultMessage: 'Translations'
    },
    upload_image: {
        id: 'upload_image',
        description: 'upload_image',
        defaultMessage: 'Upload image'
    }
})

class LandingManager extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            filtersObject: {
                selectedMarket: null,
                selectedCountry: null,
                selectedCenter: null,
                statusSelected: { value: 'ALL', label: this.props.intl.formatMessage({ id: 'status_ALL' }) },
                searchString: '',
                dates: { start: null, end: null }
            },
            gridDataState: {
                sort: [{ field: 'created_at', dir: 'desc' }],
                skip: 0,
                take: 10
            },
            showLangsModal: false,
            modalDataLang: [],
            modalCommonID: ''
        }
    }

    componentDidMount() {
        const timeout = setTimeout(() => {
            this.props.removeLoader(timeout)
        }, 20000)
        this.props.addLoader(timeout)
        this.props.fetchLandings().then(() => this.props.removeLoader(timeout))
    }

    ActionsCell = props => (
        <ActionsCell
            {...props}
            intl={this.props.intl}
            onDelete={(id, iso) => {
                confirm(this.props.intl.formatMessage({ id: 'CONFIRM_DELETE_LANDING' }), {
                    textOk: this.props.intl.formatMessage({ id: 'ok' }),
                    textCancel: this.props.intl.formatMessage({ id: 'cancel' }),
                    intl: this.props.intl
                }).then(
                    conf => {
                        const timeout = setTimeout(() => {
                            this.props.removeLoader(timeout)
                        }, 15000)
                        this.props.addLoader(timeout)
                        return this.props.deleteLanding(id, iso).then(() => {
                            this.props.fetchLandings().then(() => this.props.removeLoader(timeout))
                            this.props.showToastNotification(this.props.intl.formatMessage({ id: 'remove_success' }), 'success')
                        })
                    },
                    cancel => {
                        return Promise.reject()
                    }
                )
            }}
        />
    )

    selectedSalesOrgs(countries = this.state.filtersObject.selectedCountry) {
        const salesOrg = []

        countries.forEach(c => {
            c.salesOrg.forEach(s => salesOrg.push(s))
        })

        return salesOrg
    }

    isLandingTranslated = dataLang => {
        let t = true

        if (this.props.landings.langs) {
            this.props.landings.langs.map((lang, i) => {
                const found = dataLang && Array.isArray(dataLang) ? (dataLang || []).find(a => a === lang.id) : false
                if (!found) {
                    t = false
                }
            })
        }

        return t
    }

    render() {
        const { landings, centers, fetchCountriesDescriptions, fetchCentersDescriptions, countries, intl } = this.props
        const { filtersObject, gridDataState } = this.state
        const filteredLandings = getFilteredLandings({ ...filtersObject, statusSelected: filtersObject.statusSelected.value }, (landings || {}).landings, this.props.intl)

        return (
            <section id="section-landings">
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2><Translation id="lndmngr" defaultMessage="Landing Manager" /></h2>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="landings">
                    <div className="container">
                        <div className="row row-reverse">
                            <div className="col-md-3 landings__button">
                                <LocalizedLink
                                    className="bt noselect bt-gentle bt-size-medium"
                                    routeId="ROUTE_LANDING_MANAGER_DETAIL"
                                    params={{ id: 'new', outlet: '', iso: landings && landings['baseLang']['id'] }}
                                >
                                    <Translation id="create" defaultMessage="Create" />
                                </LocalizedLink>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 landings__filter">
                                <DropDownList
                                    label={intl.formatMessage({ id: 'markets' })}
                                    dataItemKey="value"
                                    textField="label"
                                    data={[{ value: 'Todos', label: 'Todos' }, ...getMarkets(landings ? landings.markets : [])]}
                                    value={filtersObject.selectedMarket}
                                    onChange={e => {
                                        const object = {
                                            ...filtersObject,
                                            selectedMarket: e.target.value,
                                            selectedCountry: null,
                                            selectedCenter: null
                                        }
                                        this.setState({ filtersObject: object })
                                        fetchCountriesDescriptions(e.target.value.salesOrg).then(() =>
                                            this.setState({
                                                filtersObject: {
                                                    ...this.state.filtersObject,
                                                    selectedCountry: null,
                                                    selectedCenter: null
                                                }
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div className="col-md-4 landings__filter">
                                <MultiSelect
                                    label={intl.formatMessage({ id: 'countries' })}
                                    dataItemKey="id"
                                    textField="label"
                                    data={getCountries(countries ? countries : [], intl && intl)}
                                    value={filtersObject.selectedCountry}
                                    onChange={e => {
                                        const object = {
                                            ...filtersObject,
                                            selectedCountry: e.target.value
                                        }
                                        this.setState({ filtersObject: object })
                                        fetchCentersDescriptions(this.selectedSalesOrgs(e.target.value)).then(data =>
                                            this.setState({
                                                filtersObject: {
                                                    ...this.state.filtersObject,
                                                    selectedCenter: null
                                                }
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div className="col-md-4 landings__filter">
                                <MultiSelect
                                    label={intl.formatMessage({ id: 'centers' })}
                                    dataItemKey="value"
                                    textField="label"
                                    data={getCenters(centers ? centers : [])}
                                    value={filtersObject.selectedCenter}
                                    onChange={e => {
                                        const object = {
                                            ...filtersObject,
                                            selectedCenter: e.target.value
                                        }
                                        this.setState({ filtersObject: object })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 landings__filter">
                                {landings && landings.statuses ? (
                                    <DropDownList
                                        label={intl.formatMessage({ id: 'status' })}
                                        textField="label"
                                        data={getStatuses([...landings.statuses, 'ALL'], intl)}
                                        onChange={e => {
                                            const object = {
                                                ...filtersObject,
                                                statusSelected: e.target.value
                                            }
                                            this.setState({ filtersObject: object })
                                        }}
                                        value={filtersObject.statusSelected}
                                    ></DropDownList>
                                ) : (
                                    <DropDownList label={intl.formatMessage({ id: 'status' })}></DropDownList>
                                )}
                            </div>
                            <div className="col-md-4">{/* spacer block */}</div>
                            <div className="col-md-4 landings__filter landings__filter--date">
                                <label>
                                    <Translation id="dates" defaultMessage="Dates" />
                                </label>
                                <DateRangePicker
                                    format="dd/MM/yyyy"
                                    width="100%"
                                    className=""
                                    value={filtersObject.dates}
                                    onChange={e => {
                                        const object = {
                                            ...filtersObject,
                                            dates: e.target.value
                                        }
                                        this.setState({ filtersObject: object })
                                    }}
                                />
                                {(filtersObject.dates.start || filtersObject.dates.end) && (
                                    <a
                                        className="landings__filter--clear"
                                        onClick={() => {
                                            const object = {
                                                ...filtersObject,
                                                dates: { start: null, end: null }
                                            }
                                            this.setState({ filtersObject: object })
                                        }}
                                    >
                                        <Translation id="clear_dates" defaultMessage="Clear dates" />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 landings__filter landings__filter--search">
                                <Input
                                    className="searchInput"
                                    type="search"
                                    name="search"
                                    label={intl.formatMessage({ id: 'search' }) + '...'}
                                    onChange={e => {
                                        const object = {
                                            ...filtersObject,
                                            searchString: e.target.value
                                        }
                                        this.setState({ filtersObject: object })
                                    }}
                                    value={filtersObject.searchString}
                                />
                                {filtersObject.searchString == '' ? <span className="k-icon k-i-search"></span> : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 landings__data">
                                <Grid
                                    data={process(filteredLandings || [], gridDataState)}
                                    pageable={true}
                                    sortable={true}
                                    {...this.state.gridDataState}
                                    onDataStateChange={e => this.setState({ gridDataState: e.dataState })}
                                >
                                    <GridColumn title={intl.formatMessage({ id: 'title' })} field="title" />
                                    <GridColumn title={intl.formatMessage({ id: 'status' })} field="status" />
                                    <GridColumn title={intl.formatMessage({ id: 'start_date' })} field="start_at" filter="date" format="{0:dd/MM/yyyy HH:mm:ss}" />
                                    <GridColumn title={intl.formatMessage({ id: 'ending_date' })} field="end_at" filter="date" format="{0:dd/MM/yyyy HH:mm:ss}" />
                                    <GridColumn title={intl.formatMessage({ id: 'created_date' })} field="created_at" filter="date" format="{0:dd/MM/yyyy HH:mm:ss}" />
                                    <GridColumn
                                        sortable={false}
                                        title={intl.formatMessage({ id: 'translations' })}
                                        field="translations"
                                        cell={data => (
                                            <td style={{ textAlign: 'center' }}>
                                                <Button
                                                    className={'tablecustomButtonRound ' + (this.isLandingTranslated(data.dataItem.data_lang) ? 'valid' : 'invalid')}
                                                    onClick={() =>
                                                        this.setState({ showLangsModal: true, modalDataLang: data.dataItem.data_lang, modalCommonID: data.dataItem.common_id })
                                                    }
                                                >
                                                    <span className="k-icon k-i-spell-checker"></span>
                                                </Button>
                                            </td>
                                        )}
                                    />
                                    <GridColumn sortable={false} title={intl.formatMessage({ id: 'actions' })} field="actions" cell={this.ActionsCell} />
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showLangsModal && (
                    <SimpleModal customClass="langs-modal" size="large" onClose={() => this.setState({ showLangsModal: false })}>
                        {this.props.landings.langs ? (
                            this.props.landings.langs.map((lang, i) => {
                                var found = this.state.modalDataLang && Array.isArray(this.state.modalDataLang) ? (this.state.modalDataLang || []).find(a => a === lang.id) : false
                                return (
                                    <div key={i} className="translation-item">
                                        <div className="translation-label-row">
                                            <span className={`flag-icon flag-icon-${advParseLang(lang, true)} mx-1`}></span>
                                            <span>{lang.name}</span>
                                        </div>
                                        <LocalizedLink
                                            routeId="ROUTE_LANDING_MANAGER_DETAIL"
                                            params={{ id: this.state.modalCommonID, iso: lang.id, outlet: '' }}
                                            queryString={found ? '/edit' : ''}
                                            className={'bt noselect bt-gentle bt-size-medium ' + (found ? 'valid' : 'invalid')}
                                        >
                                            {found ? <Translation id="Edit" defaultMessage="Edit" /> : <Translation id="translate" defaultMessage="Translate" />}
                                        </LocalizedLink>
                                    </div>
                                )
                            })
                        ) : (
                            <div></div>
                        )}
                    </SimpleModal>
                )}
            </section>
        )
    }
}
export default injectIntl(LandingManager)
