import { connect } from 'react-redux'

import {
    getImpersonatedBy,
    getIsCampaignsPricingUser,
    getSalesOrg,
    getUserName,
    getUserType
} from '../../store/login/reducers'
import { getAvailableLands } from '../../store/ui/reducers'
import PromotionAdmin from './PromotionAdmin'
import * as reducer from '../../store/promotions/reducers'
import { getSelectedCenter } from '../../store/centers/reducers'
import * as action from '../../store/promotions/actions'
import {
    getBannerDescription,
    getBannerTitle,
    getCommercialName,
    getCurrentPromotionId,
    getMaterialsSelected,
    getMaterialsSelectedData,
    getCustomersSelected,
    getDescription,
    getHeadEmailBannerData,
    getHeadLandingBannerData,
    getHomeBannerData,
    getLandingTemplateId,
    getLandingTemplates,
    getLateralBanner,
    getMailTemplateId,
    getNewsLetterTemplates,
    getPromotionCenters,
    getPromotionCountries,
    getPromotionEndDate,
    getPromotionMarket,
    getPromotionName,
    getPromotionStartDate,
    getAvailableSteps,
    canViewDiscountStep,
    getIsAmortized,
    getDiscountStatus,
    getUnsavedChangesStepOne,
    getUnsavedChangesStepTwo,
    getUnsavedChangesStepThree,
    getUnsavedChangesStepFour,
    getUnsavedChangesStepFive,
    getUnsavedChangesStepSix,
    getUnsavedChangesStepSeven
} from '../../store/promotions/reducers'
import { push } from 'react-router-redux'
import { dispatchPushURL } from '../../store/ui/actions'

const mapStateToProps = (state, { match }) => {
    return {
        currentPromotionId: getCurrentPromotionId(state),
        availableSteps: getAvailableSteps(state),
        salesOrg: getSalesOrg(state),
        isSending: reducer.getIsSending(state),
        isLoading: reducer.getIsLoading(state),
        isAmortized: reducer.getIsAmortized(state),
        alert: reducer.getAlert(state),
        status: reducer.getStatus(state),
        step: reducer.getStep(state),
        userName: getUserName(state),
        userType: getUserType(state),
        impersonatedBy: getImpersonatedBy(state),
        centerId: getSelectedCenter(state),
        translations: reducer.getTranslations(state),
        markets: getAvailableLands(state),
        landingTemplates: getLandingTemplates(),
        newsLetterTemplates: getNewsLetterTemplates(),
        fetchedMarkets: reducer.getFetchedMarkets(state),
        fetchedCountries: reducer.getFetchedCountries(state),
        fetchedCenters: reducer.getFetchedCenters(state),
        languages: reducer.getAvailableLanguages(state),
        customersOfArea: reducer.getCustomersOfArea(state),
        promotionName: getPromotionName(state),
        startDate: getPromotionStartDate(state),
        endDate: getPromotionEndDate(state),
        commercial_name: getCommercialName(state),
        description: getDescription(state),
        market: getPromotionMarket(state),
        countries: getPromotionCountries(state),
        centers: getPromotionCenters(state),
        landingTemplateId: getLandingTemplateId(state),
        mailTemplateId: getMailTemplateId(state),

        headLandingBannerData: getHeadLandingBannerData(state),
        homeBannerData: getHomeBannerData(state),
        headEmailBannerData: getHeadEmailBannerData(state),
        lateralBanner: getLateralBanner(state),

        customersSelected: getCustomersSelected(state),
        materialsSelected: getMaterialsSelected(state),
        materialsSelectedData: getMaterialsSelectedData(state),
        canViewDiscountStep: canViewDiscountStep(state),
        discountStatus:getDiscountStatus(state),
        //Unsaved changes
        hasUnsavedChangesStepOne: getUnsavedChangesStepOne(state),
        hasUnsavedChangesStepTwo: getUnsavedChangesStepTwo(state),
        hasUnsavedChangesStepThree: getUnsavedChangesStepThree(state),
        hasUnsavedChangesStepFour: getUnsavedChangesStepFour(state),
        hasUnsavedChangesStepFive: getUnsavedChangesStepFive(state),
        hasUnsavedChangesStepSix: getUnsavedChangesStepSix(state),
        hasUnsavedChangesStepSeven: getUnsavedChangesStepSeven(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendPromotionHeader: (name, startDate, endDate, commercial_name, description) => {
            let data = { name, startDate, endDate, commercial_name, description }
            return dispatch(action.sendPromotionHeader(data))
        },
        sendPromotionTemplateSelected: (landingTemplateId, mailTemplateId) => {
            return dispatch(action.sendTemplatesSelected({ landingTemplateId, mailTemplateId }))
        },
        saveTranslationObject: (object, updatedTrans) => {
            return dispatch(action.updateTranslationObject(object, updatedTrans))
        },
        getMarkets: () => {
            return dispatch(action.getMarkets())
        },
        getCountries: salesOrg => {
            let data = { salesOrg }
            return dispatch(action.getCountries(data))
        },
        getCenters: salesOrg => {
            let data = { salesOrg }
            return dispatch(action.getCenters(data))
        },
        sendPromotionArea: (market, countries, centers) => {
            let data = { market, countries, centers }
            return dispatch(action.sendPromotionArea(data))
        },
        fetchCustomersOfArea: (market, countries, centers) => {
            return dispatch(action.fetchCustomersOfArea(market, countries, centers))
        },
        sendCustomersSelected: customers => {
            return dispatch(action.sendCustomersSelected(customers))
        },
        sendMaterialsSelected: (materials, materialsSelectedData) => {
            return dispatch(action.sendMaterialsSelected(materials, materialsSelectedData))
        },
        changeStep: step => dispatch(action.changeStep(step)),
        stepBack: step => dispatch(action.changeStep(step)),
        setAvailableLanguages: languages => {
            return dispatch({ type: 'PROMOTIONS_SET_AVAILABLE_LANGUAGES', languages })
        },
        sendBanners: (bannerLanding, bannerHome, bannerNewsLetter, sideBannerNewsLetter, banner_title, banner_description) =>
            dispatch(action.sendBanners(bannerLanding, bannerHome, bannerNewsLetter, sideBannerNewsLetter, banner_title, banner_description)),
        uploadPromotionFile: formData => dispatch(action.uploadPromotionFile(formData)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        goAdminList: (intl) => {
            dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_PROMOTIONS_LIST' })))
        },
        setUnsavedChangesStepOne: (changes) => {
            return dispatch({ type: 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_ONE', changes })
        },
        setUnsavedChangesStepTwo: (changes) => {
            return dispatch({ type: 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_TWO', changes })
        },
        setUnsavedChangesStepThree: (changes) => {
            return dispatch({ type: 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_THREE', changes })
        },
        setUnsavedChangesStepFour: (changes) => {
            return dispatch({ type: 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_FOUR', changes })
        },
        setUnsavedChangesStepFive: (changes) => {
            return dispatch({ type: 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_FIVE', changes })
        },
        setUnsavedChangesStepSix: (changes) => {
            return dispatch({ type: 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_SIX', changes })
        },
        setUnsavedChangesStepSeven: (changes) => {
            return dispatch({ type: 'PROMOTIONS_SET_UNSAVED_CHANGES_STEP_SEVEN', changes })
        }
    }
}

const PromotionAdminContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PromotionAdmin)

export default PromotionAdminContainer
