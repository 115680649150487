import React from 'react'
import Translation from '../global/Translation'

import ProfileContainer from '../profile/ProfileContainer'
import SegmentationContainer from './SegmentationContainer'
import { injectIntl } from 'react-intl'

import CentersContainer from '../centers/CentersContainer'

class ProfileSegmentation extends React.Component {
    render() {
        return (
            <div className="box-profile row">
                <div className="col-12 col-md-4 col-lg-3">
                    <ProfileContainer />
                </div>
                <div className="col-12 col-xl-9">
                    <div className="profile-form">
                        <div id="segmentation-container" className="segmentation" style={{ display: 'block' }}>
                            <div className="toolbar">
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', columnGap: '24px'}}>
                                    <h4 style={{fontSize: '20px'}}>
                                        <Translation id="saved_segmentations" defaultMessage="Saved segmentations" />
                                    </h4>
                                    <div style={{textAlign: 'end'}}>
                                        <CentersContainer keepHere simple/>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <SegmentationContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(ProfileSegmentation)
