import React from 'react'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {getUnit} from "../../store/profile-configuration/reducers";
import LotTableRow from "./LotTableRow";
import LotGridCell from "./LotGridCell";
import {canViewLotId} from "../../store/products/reducers";
import {v4 as uuidv4} from 'uuid'

const mapStateToProps = state => {
    return {
        unit: getUnit(state),
        canViewLotId: canViewLotId(state)
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         buyLot: (productId, lotId, centerId, extra = null, supplierCenterId = null) => {
//             dispatch(addCartLineLot(productId, lotId, centerId, extra, supplierCenterId))
//         },
//         buyBundle: (productId, bundleId, lotId, qty, centerId, extra = null, supplierCenterId = null) => {
//             dispatch(addCartLineBundle(productId, bundleId, lotId, qty, centerId, extra, supplierCenterId))
//         },
//         selectLot: (selectedLot, uuid) => {
//             dispatch({type:'ADD_LOT_TO_BUY', selectedLot, uuid})
//         }
//     }
// }

class LotComponent extends React.Component {

    componentDidMount() {
        this.setState({
            quantity: this.getMinimumValue(),
            uuid: uuidv4()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.lot && this.props.lot) {
            this.setState({
                quantity: this.getMinimumValue()
            })
        }
    }

    getLUnit = () => {
        return this.props.unit === 'ft' ? 'in' : 'cm'
    }

    getMeasure = (field, isSurface) => {
        let localeField = field + '_' + this.props.unit
        if (isSurface) localeField += '2'
        return this.props.lot[localeField]
    }

    setQuantity = (quantity) => {
        this.setState({quantity: quantity}, () => {
            if (this.props.minimumOrder) {
                const selectedLot = quantity > 0 ? this.getSelectedLot() : null
                this.props.selectLot(selectedLot, this.state.uuid)
            }
        })
    }

    addToCart = () => {
        const selectedLot = this.getSelectedLot()

        if (selectedLot) {
            if (selectedLot.bundleId) this.props.buyBundle(selectedLot.productId, selectedLot.bundleId, selectedLot.visibleLotId, selectedLot.quantity, selectedLot.centerId, selectedLot.extra, selectedLot.supplierCenterId)
            else if (selectedLot.lotId) this.props.buyLot(selectedLot.productId, selectedLot.lotId, selectedLot.centerId, selectedLot.extra, selectedLot.supplierCenterId)
        }
    }

    getSelectedLot = () => {
        const {product, center, lot, canViewLotId, selectedProduct, fromMall} = this.props

        const isBundle = lot && lot.bundleId && true
        let extra = {}
        let supplierCenterId = null
        if (selectedProduct){
            extra = {...extra, ...selectedProduct.extra}
            supplierCenterId = selectedProduct.centerId
        }
        if (fromMall) extra.mall = true
        const selectedLot = {}

        if (isBundle) {
            selectedLot.bundleId = lot.bundleId
            selectedLot.visibleLotId = canViewLotId ? lot.lotId : null
        }
        selectedLot.quantity = isBundle ? this.state.quantity : 1
        selectedLot.productId = product.productId
        selectedLot.lotId = lot.lotId
        selectedLot.centerId = center
        selectedLot.extra = extra
        selectedLot.supplierCenterId = supplierCenterId

        return selectedLot
    }

    getMinimumValue = () => this.props.isLatinaVitoria ? this.props.lot.count : (this.props.minimumOrder ? 0 : 1)


    render() {
        const {lotsImages, lot, isGrid} = this.props
        const isLot = !lot.bundleId ||!(lot.lots && lot.lots.length > 0)
        let lotImage;

        if (lotsImages) {
            if(!isLot){
                const foundLot = lot.lots && lot.lots.find((lotInfo) => {
                    return lotsImages[lotInfo.lotId] && !!lotsImages[lotInfo.lotId].image
                });
                lotImage = foundLot && (isGrid ? lotsImages[foundLot.lotId].image : lotsImages[foundLot.lotId].thumb);
            } else {
                lotImage = lotsImages[lot.lotId] && (isGrid ? lotsImages[lot.lotId].image : lotsImages[lot.lotId].thumb)
            }
        }

        const width =this.getMeasure('width')
        const length = this.getMeasure('length')
        const surface = this.getMeasure('qty', true)


        const maxQuantity = lot.lots ? lot.lots.length : 1

        const props = {...this.props, setQuantity: this.setQuantity, lunit: this.getLUnit, addToCart: this.addToCart,
            width, length, surface, maxQuantity, lotImage, isLot, getMinimumValue: this.getMinimumValue
        }
        return isGrid ? (<LotGridCell {...props}/>) : (<LotTableRow {...props}/>)
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(LotComponent)
)
