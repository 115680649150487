import React, { Component } from 'react'
import { getChangelogItemsNews } from '../../store/changelog/reducers'
import { fetchChangelogByProject } from '../../store/changelog/actions'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { getIsLogged } from '../../store/login/reducers'
import Translation from '../global/Translation'

const mapStateToProps = state => {
    return {
        changelogItems: getChangelogItemsNews(state),
        isLogged: getIsLogged(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchChangelogByProject: onlyNews => dispatch(fetchChangelogByProject(onlyNews))
    }
}

class ChangeLog extends Component {
    state = { showHelp: !this.props.lastView }
    componentDidMount() {
        if (!this.props.changelogItems) this.props.fetchChangelogByProject(true)
    }
    render() {
        let { changelogItems, isLogged } = this.props
        if (isLogged && changelogItems && Object.keys(changelogItems).length > 0) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div class="">
                                <h3 className="mb-4 display-4" style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                    <Translation defaultMessage="What´s new" id="what_new" />!
                                </h3>

                                {changelogItems &&
                                    Object.keys(changelogItems).map(date => {
                                        if (
                                            //check if some functionality have items
                                            Object.entries(changelogItems[date])
                                                .map(([key, value]) => ({ key, value }))
                                                .some(item => Object.entries(item.value).length > 0)
                                        ) {
                                            return (
                                                <React.Fragment>
                                                    {Object.keys(changelogItems[date]).map(project => {
                                                        if (Object.entries(changelogItems[date][project]).length > 0) {
                                                            return (
                                                                <div>
                                                                    <h5>{this.props.intl.formatMessage({ id: project })}</h5>
                                                                    <ul className="changelog-items" style={{ listStyle: 'none' }}>
                                                                        {Object.keys(changelogItems[date][project]).map(functionality => {
                                                                            if (Object.keys(changelogItems[date][project][functionality]).length > 0) {
                                                                                return (
                                                                                    <li>
                                                                                        <h6>{this.props.intl.formatMessage({ id: functionality })}</h6>
                                                                                        <ul className="changelog-items" style={{ listStyle: 'none' }}>
                                                                                            {Object.keys(changelogItems[date][project][functionality]).map(change => {
                                                                                                return (
                                                                                                    <div>
                                                                                                        <h6>
                                                                                                            <span className="badge badge-primary mr-1">
                                                                                                                {this.props.intl.formatMessage({ id: change })}
                                                                                                            </span>
                                                                                                        </h6>
                                                                                                        <ul className="changelog-items" style={{ listStyle: 'none' }}>
                                                                                                            {changelogItems[date][project][functionality][change].map(
                                                                                                                (item, index) => {
                                                                                                                    return (
                                                                                                                        <li className="mb-4">
                                                                                                                            <div
                                                                                                                                className="changelog–items__title collapsed"
                                                                                                                                data-toggle="collapse"
                                                                                                                                data-target={
                                                                                                                                    '#' +
                                                                                                                                    date +
                                                                                                                                    project +
                                                                                                                                    functionality +
                                                                                                                                    change +
                                                                                                                                    index
                                                                                                                                }
                                                                                                                                aria-controls={
                                                                                                                                    date + project + functionality + change + index
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <span>{item.title}</span>
                                                                                                                                {item.description && (
                                                                                                                                    <div
                                                                                                                                        className="button_info button_info--changelog"
                                                                                                                                        style={{ left: 'initial' }}
                                                                                                                                    >
                                                                                                                                        <i
                                                                                                                                            className="fal fa-info-circle"
                                                                                                                                            style={{ margin: '5px' }}
                                                                                                                                        />
                                                                                                                                        <Translation
                                                                                                                                            id="plus_info"
                                                                                                                                            defaultMessage="+ Info"
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                            {item.description && (
                                                                                                                                <div
                                                                                                                                    id={
                                                                                                                                        date +
                                                                                                                                        project +
                                                                                                                                        functionality +
                                                                                                                                        change +
                                                                                                                                        index
                                                                                                                                    }
                                                                                                                                    className="collapse"
                                                                                                                                >
                                                                                                                                    <small>{item.description}</small>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </li>
                                                                                                                    )
                                                                                                                }
                                                                                                            )}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </ul>
                                                                                    </li>
                                                                                )
                                                                            } else return null
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            )
                                                        } else return null
                                                    })}
                                                </React.Fragment>
                                            )
                                        } else return null
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else return null
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChangeLog)
)
