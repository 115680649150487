import React from 'react'

import PropTypes from 'prop-types'
class DisplayPriceMktools extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        dataCY: PropTypes.string,
        group: PropTypes.string,
        prices: PropTypes.object.isRequired,
        showCoin: PropTypes.bool
    }
    render() {
        const { prices, group, className = '', dataCY = 'display_price', showCoin = true } = this.props
        if (!prices) return null
        let groupPrice = prices[group] ? prices[group] : prices.default
        if (!groupPrice) return null
        return (
            <span className={className} data-cy={dataCY}>
                {groupPrice.price} {showCoin && groupPrice.coin}
            </span>
        )
    }
}
export default DisplayPriceMktools
