import React from 'react'

import LotsFilter from './LotsFilter'
import LotsList from './LotsList'
import LotsHeader from './LotsHeader'
import Spinner from '../global/Spinner'
import Translation from "../global/Translation";
import _ from 'lodash';

class Lots extends React.Component {
    constructor(props) {
        super(props)
        this.state = { initialValuesSet: false, quarryBlock: null, shade: null, quality: null, location: null, search: '', modalImage: null, lotsFiltered: null }
    }

    componentDidMount() {
        let shouldFetchOutlet = (this.props.original && (!this.props.original.outlet && this.props.original.trullyOutlet)) || this.props.outlet ? true : false
        const fl = this.props.fetchLots(this.props.productId, null, null, null, null, shouldFetchOutlet, this.filter)
        this.props.fetchProduct(this.props.productId)
        // if (!this.props.fromMall) this.props.fetchProduct(this.props.productId)
        // this.props.onLoadLots(this.props.outlet, this.props.productId)
    }

    componentDidUpdate(prevProps) {
        const prevLotsSize = this.getTotalLotsSize(prevProps.lots)
        const currentLotsSize = this.getTotalLotsSize(this.props.lots)
        if (currentLotsSize !== prevLotsSize || this.state.lotsFiltered === null) {
            this.filter()
        }
    }

    getTotalLotsSize =(lots) => {
        if (!lots) return 0
        let count = 0
        lots.forEach(l => {
            if (l.lots) l.lots.forEach(() => ++count)
            else ++count
        })
        return count
    }

    handleFilterChange = (selectedOption, filter) => {
        let selected = selectedOption !== null ? selectedOption.value : null
        // this.setState({ [filter]: selected }, function() {
        //     // this.props.fetchLots(this.props.productId, this.state.quarryBlock, this.state.shade, this.state.quality, this.state.location, this.props.outlet)
        // })
        this.setState({ [filter]: selected }, this.filter)
    }

    clearFilters = () => {
        this.setState({ quarryBlock: null, shade: null, quality: null, location: null, search: '' },  this.filter)
    }

    flush = () => {
        this.setState({ search: '' }, () => {
            this.props.fetchLots(this.props.productId, this.state.quarryBlock, this.state.shade, this.state.quality, this.state.location, this.props.outlet, this.filter)
        })
    }

    search = e => {
        this.setState({ search: e.target.value }, this.filter)
    }

    filter = () => {

        if (!this.props.lots) return

        let lotsFiltered = _.cloneDeep(this.props.lots)

        if (this.props.fromMall) {
            // if (!!this.props.mallProductLots && this.props.selectedMallProductId && !!this.props.mallProductLots[this.props.selectedMallProductId]) {
            if (!!this.props.selectedProduct.productLots) {
                const mallLots = {}
                // this.props.mallProductLots[this.props.selectedMallProductId].lots.forEach(l => mallLots[l] = true)
                this.props.selectedProduct.productLots.lots.forEach(l => mallLots[l] = true)
                lotsFiltered = lotsFiltered.filter(l => {
                    if (mallLots[l.lotId]) return true
                    // if (l.lotId in mallLots) return true
                    if (l.lots) {
                        const bundle = l.lots.filter(innerLot => {
                            return mallLots[innerLot.lotId]
                        })
                        if (bundle.length >= 1) {
                            l.lots = bundle
                            return true
                        }
                    }
                    return false
                })
            } else {
                lotsFiltered = lotsFiltered.filter(l => {
                    if (l.mall === 'MA') return true
                    if (l.lots) {
                        const bundle = l.lots.filter(il => il.mall === 'MA')
                        if (bundle.length > 1) {
                            l.lots = bundle
                            return true
                        }
                    }
                    return false
                })
            }
        } else {
            // lotsFiltered = lotsFiltered.filter(l => !l.mall)
        }


        
        if (!(this.props.isAdmin || this.props.impersonatedBy)) {
            lotsFiltered = lotsFiltered.filter(row => {
                return row.blocked !== 'Y'
            })
        }

        if (this.state.search) {
            lotsFiltered = lotsFiltered.filter(row => {
                const hasLotId = !!row.lotId && row.lotId.includes(this.state.search)
                const hasBundleId = !!row.bundleId && row.bundleId.includes(this.state.search)
                const hasInnerLotId = !!row.lots && row.lots.some(lot => lot.lotId.includes(this.state.search))
                return hasLotId || hasBundleId || hasInnerLotId
                // if (row.lotId) return row.lotId.includes(this.state.search)
                // else if (row.bundleId) return row.bundleId.includes(this.state.search) || row.lots.some(lot => lot.lotId.includes(this.state.search))
                // else return row
            })
        }

        if (this.state.quarryBlock) {
            lotsFiltered = lotsFiltered.filter(row => {
                if (row.quarryBlock) return row.quarryBlock.includes(this.state.quarryBlock)
                if (row.bundleId) return row.lots.some(lot => lot.quarryBlock && lot.quarryBlock.includes(this.state.quarryBlock))
                return false
            })
        }
        if (this.state.shade) {
            lotsFiltered = lotsFiltered.filter(row => {
                if (row.shade) return row.shade.includes(this.state.shade)
                if (row.bundleId) return row.lots.some(lot => lot.shade && lot.shade.includes(this.state.shade))
                else return false
            })
        }
        if (this.state.quality) {
            lotsFiltered = lotsFiltered.filter(row => {
                if (row.quality) return row.quality.includes(this.state.quality)
                if (row.bundleId) return row.lots.some(lot => lot.quality && lot.quality.includes(this.state.quality))
                else return false
            })
        }
        if (this.state.location) {
            lotsFiltered = lotsFiltered.filter(row => {
                if (row.location) return row.location.includes(this.state.location)
                if (row.bundleId) return row.lots.some(lot => lot.location && lot.location.includes(this.state.location))
                else return false
            })
        }

        if (lotsFiltered) this.calcLotsDimensions(lotsFiltered)
        this.storeCurrentProductLots(lotsFiltered)
        this.setState({lotsFiltered})
    }

    calcLotsDimensions(lots) {
        if (lots) {
            lots.map(lot => {
                if (lot.lots && lot.lots.length > 0) {
                    const size = lot.lots.length

                    lot.length = (lot.lots.reduce((val, child) => {
                        return val + parseFloat(child.length)
                    }, 0)/size).toFixed(2)

                    lot.width =(lot.lots.reduce((val, child) => {
                        return val + parseFloat(child.width)
                    }, 0) / size).toFixed(2)

                    lot.length_ft = (lot.lots.reduce((val, child) => {
                        return val + parseFloat(child.length_ft)
                    }, 0) / size).toFixed(2)

                    lot.length_m = (lot.lots.reduce((val, child) => {
                        return val + parseFloat(child.length_m)
                    }, 0) / size).toFixed(1)

                    lot.width_ft = (lot.lots.reduce((val, child) => {
                        return val + parseFloat(child.width_ft)
                    }, 0) / size).toFixed(2)

                    lot.width_m = (lot.lots.reduce((val, child) => {
                        return val + parseFloat(child.width_m)
                    }, 0) / size).toFixed(1)

                    lot.qty = (lot.lots.reduce((val, child) => {
                        return val + parseFloat(child.qty)
                    }, 0) / size).toFixed(2)

                    lot.qty_ft2 = (lot.lots.reduce((val, child) => {
                            return val + parseFloat(child.qty_ft2)
                        }, 0) / size).toFixed(2)

                    lot.qty_m2 = (lot.lots.reduce((val, child) => {
                        return val + parseFloat(child.qty_m2)
                    }, 0) / size).toFixed(2)

                    lot.quarryBlock = lot.lots[0].quarryBlock
                    lot.quality = lot.lots[0].quality
                    lot.location = lot.lots[0].location
                    lot.qualityOutlet = lot.lots[0].qualityOutlet
                    lot.shade = lot.lots[0].shade
                    lot.lotId = lot.lots[0].lotId
                    lot.count = size
                } else if(lot.length_ft && lot.length_ft.toFixed) {
                    // lot.length = lot.length.toFixed(2)
                    // lot.width = lot.width.toFixed(2)
                    lot.length_ft = lot.length_ft.toFixed(2)
                    lot.length_m = lot.length_m.toFixed(0)
                    lot.width_ft = lot.width_ft.toFixed(2)
                    lot.width_m = lot.width_m.toFixed(0)
                    // lot.qty = lot.qty.toFixed(2)
                    lot.qty_ft2 = lot.qty_ft2.toFixed(2)
                    lot.qty_m2 = lot.qty_m2.toFixed(2)
                    lot.count = 1
                }

                return lot
            })
        }
    }

    storeCurrentProductLots(lots) {
        const productLots = []
        const addLot = (lot) => {
            const foundLot = lot.lots && lot.lots.find((lotInfo) => {
                return this.props.lotsImages[lotInfo.lotId] && !!this.props.lotsImages[lotInfo.lotId].image
            });
            const lotImgData = foundLot ? {...this.props.lotsImages[foundLot.lotId]} : {...this.props.lotsImages[lot.lotId]}
            if (lotImgData && lotImgData.image) {
                lotImgData.bundleId = lot.bundleId
                lotImgData.quarryBlock = lot.quarryBlock
                lotImgData.quality = lot.quality
                lotImgData.qualityOutlet = lot.qualityOutlet
                lotImgData.shade = lot.shade
                lotImgData.location = lot.location
                lotImgData.width_m = lot.width_m
                lotImgData.width_ft = lot.width_ft
                lotImgData.length_m = lot.length_m
                lotImgData.length_ft = lot.length_ft
                lotImgData.qty_m2 = lot.qty_m2
                lotImgData.qty_ft2 = lot.qty_ft2
                lotImgData.count = lot.count
                lotImgData.rejectionCode = lot.rejectionCode
                productLots.push(lotImgData)
            }
        }

        if (lots && this.props.lotsImages) {
            lots.forEach(lot => {
                addLot(lot)
                // if (lot.lots && lots.length) {
                //     lot.lots.forEach(innerLot => addLot(innerLot))
                // }
                // else addLot(lot)
            })
        }

        this.props.setCurrentProductLots(productLots)
    }

    render() {
        if (this.props.original && this.props.original.lotViewAvailable !== undefined && !this.props.original.lotViewAvailable) {
            this.props.redirect(
                this.props.intl
                    .formatMessage({ id: 'ROUTE_PRODUCT_DETAIL' })
                    .replace(':id', this.props.original.productId)
                    .replace('/:outlet?', this.props.outlet ? '/outlet' : '')
            )
        }
        if (this.props.lots === undefined || this.props.original === undefined || this.props.isLoading) {
            return (
                <section id="section-lots">
                    <Spinner isLoading={this.props.isLoading} />
                </section>
            )
        }

        let lots = this.state.lotsFiltered
        let onlyLots = []

        if (lots) {
            // Only lots
            lots.forEach(lot => {
                if (lot.lots) {
                    lot.lots.forEach(childLot => onlyLots.push(childLot))
                } else {
                    onlyLots.push(lot)
                }
            })
        }

        return (
            <section id="section-lots">
                <div className="lots container">
                    <div className="row">
                        <div className="lots-container col-12">
                            <LotsHeader {...this.props} flush={this.flush} onlyLots={onlyLots} />
                            <div className="filters-container">
                                <LotsFilter {...this.props}
                                            search={e => this.search}
                                            flush={this.flush}
                                            handleFilterChange={this.handleFilterChange}
                                            clearFilters={this.clearFilters}
                                            onlyLots={onlyLots}
                                />
                            </div>
                            <div className="table">
                                {
                                    (lots && lots.length > 0) ? (
                                        <LotsList {...this.props} lots={lots}/>
                                    ) : (
                                        <h1 className="text-center my-4"><Translation id="no_results" defaultMessage="No results"/></h1>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Lots
