import * as hardcode from '../hardCode'
import { getIsIkeaCenter, getIsLowesCenter, getIsMkToolsCenter, getSalesOrg, getSelectedCenter } from '../centers/reducers'
import { getCurrentUrl, getLanguageToFetchZ2, isComingFromMall } from '../ui/reducers'
import { getImpersonatedBy, getUserName, getIsLogged, getUserType, getUserPriceList, isEmserUser } from '../login/reducers'
import { getSalesOrg as getUserSalesOrg } from '../login/reducers'

export const initState = {
    fetchedProducts: [],
    lastVisitedProducts: null,
    lastBoughtProducts: null,
    productsForYou: null,
    // lastBoughtProductsId: null,
    // lastBoughtProductsState: 'NOT_FETCHED',
    // productsForYouId: null,
    // productsForYouState: 'NOT_FETCHED',
    productsSimilarColors: null,
    error: null,
    colorsCutToSize: {
        productsId: [],
        isLoading: false,
        products: null
    },
    samplesCutToSize: {
        samples: null,
        isLoading: false
    },
    furnituresCutToSize: {
        furnitures: null,
        isLoading: false
    },
    productsFavourite: {
        productsId: [],
        isAdding: false,
        isLoading: false,
        products: []
    },
    productsMktToMax: [],
    // productId: 'fixedgallery',
    gallery: [
        {
            url:
                'https://assetstools.cosentino.com/api/v1/bynder/image/2E40493B-D183-4D59-81ABA96F1AF2105F/dekton-outdoor-kitchen-eu-nilium-orix-zenith.jpg?auto=compress,format&w=1200&h=500&fit=crop',
            urlPreview:
                'https://assetstools.cosentino.com/api/v1/bynder/image/2E40493B-D183-4D59-81ABA96F1AF2105F/dekton-outdoor-kitchen-eu-nilium-orix-zenith.jpg?auto=compress,format&w=30&h=25&fit=crop'
        },
        {
            url:
                'https://assetstools.cosentino.com/api/v1/bynder/image/61C96A3C-E07C-407E-A77C5A3C393159A9/dekton-living-room-nilium.jpg?auto=compress,format&w=1200&h=500&fit=crop',
            urlPreview:
                'https://assetstools.cosentino.com/api/v1/bynder/image/61C96A3C-E07C-407E-A77C5A3C393159A9/dekton-living-room-nilium.jpg?auto=compress,format&w=30&h=25&fit=crop'
        },
        {
            url: 'https://assetstools.cosentino.com/api/v1/bynder/image/D363FE91-8596-4C72-862F550DA0C567A3/dekton-nilium-2.jpg?auto=compress,format&w=1200&h=500&fit=crop',
            urlPreview: 'https://assetstools.cosentino.com/api/v1/bynder/image/D363FE91-8596-4C72-862F550DA0C567A3/dekton-nilium-2.jpg?auto=compress,format&w=30&h=25&fit=crop'
        },
        {
            url:
                'https://assetstools.cosentino.com/api/v1/bynder/image/873474B0-C613-4D6B-8EA682BFFC43F175/dekton-fachada-concepto-radium-nilium.jpg?auto=compress,format&w=1200&h=500&fit=crop',
            urlPreview:
                'https://assetstools.cosentino.com/api/v1/bynder/image/873474B0-C613-4D6B-8EA682BFFC43F175/dekton-fachada-concepto-radium-nilium.jpg?auto=compress,format&w=30&h=25&fit=crop'
        },
        {
            url: 'https://assetstools.cosentino.com/api/v1/bynder/image/69A0985C-715E-4D20-84E121D8BE1975ED/dekton-kitchen-nilium.jpg?auto=compress,format&w=1200&h=500&fit=crop',
            urlPreview:
                'https://assetstools.cosentino.com/api/v1/bynder/image/69A0985C-715E-4D20-84E121D8BE1975ED/dekton-kitchen-nilium.jpg?auto=compress,format&w=30&h=25&fit=crop'
        }
    ],
    colorsMktoolsAttr: {},
    colorsMktoolsAttrToAdd: {},
    colorsMktoolsAttrToDel: {},
    deviatedAdmin: {
        isLoading: false,
        products: []
    },
    deviatedDescriptionsIsLoading: false,
    deviatedDescriptions: []
}

const products = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCT_REQUEST':
            return { ...state, error: null }
        case 'FETCH_PRODUCT_SUCCESS':
        case 'ADD_SELECTED_PRODUCT_TO_FETCHED':
            return { ...state, fetchedProducts: [{ product: action.product, centerId: action.centerId, locale: action.locale }].concat(...state.fetchedProducts) }
        case 'FETCH_PRODUCT_FAILURE':
            return { ...state, error: action.response }
        case 'FETCH_PRODUCTS_MKT_TO_MAX_REQUEST':
            return { ...state, isLoading: true }
        case 'FETCH_PRODUCTS_MKT_TO_MAX_SUCCESS':
            return { ...state, productsMktToMax: action.productsMktToMax, isLoading: false }
        case 'FETCH_PRODUCTS_MKT_TO_MAX_FAILURE':
            return { ...state, isLoading: false }
        case 'SAVE_PRODUCTS_MKT_TO_MAX_REQUEST':
            return { ...state, isLoading: true }
        case 'SAVE_PRODUCTS_MKT_TO_MAX_SUCCESS':
            let newProducts = state.productsMktToMax.map(item => {
                if (action.productsMktToMax.filter(newItem => item.productId === newItem.productId && item.center === newItem.center).length > 0)
                    return action.productsMktToMax.filter(newItem => item.productId === newItem.productId && item.center === newItem.center)[0]
                else return item
            })
            return { ...state, productsMktToMax: newProducts, isLoading: false }
        case 'SAVE_PRODUCTS_MKT_TO_MAX_FAILURE':
            return { ...state, isLoading: false }
        case 'FETCH_SIMILAR_PRODUCT_SUCCESS':
            return {
                ...state,
                fetchedProducts: state.fetchedProducts.map(item => {
                    if (item.product.productId === action.productId && item.centerId === action.centerId && item.locale === action.locale) {
                        item = { ...item, product: { ...item.product, similarProducts: action.products } }
                    }
                    return item
                })
            }
        case 'LAST_PRODUCTS_BOUGHT_INIT':
            return Object.assign({}, state, { lastBoughtProductsState: 'FETCHING', lastBoughtProductsId: null })
        case 'LAST_PRODUCTS_BOUGHT_FINISH':
            return Object.assign({}, state, { lastBoughtProductsState: 'DONE' })
        case 'LAST_PRODUCTS_BOUGHT_SUCCESS':
            // return Object.assign({}, state, { lastBoughtProductsId: action.products })
            return Object.assign({}, state, { lastBoughtProducts: action.products })
        case 'PRODUCTS_FOR_YOU_INIT':
            return Object.assign({}, state, { productsForYouState: 'FETCHING', productsForYouId: null })
        case 'PRODUCTS_FOR_YOU_FINISH':
            return Object.assign({}, state, { productsForYouState: 'DONE' })
        case 'PRODUCTS_FOR_YOU_SUCCESS':
            return Object.assign({}, state, { productsForYou: action.products })
        // return Object.assign({}, state, { productsForYouId: action.products })
        case 'PRODUCTS_SIMILAR_COLORS_INIT':
            return Object.assign({}, state, { productsSimilarColorsState: 'FETCHING', productsSimilarColorsId: null })
        case 'PRODUCTS_SIMILAR_COLORS_FINISH':
            return Object.assign({}, state, { productsSimilarColorsState: 'DONE' })
        case 'PRODUCTS_SIMILAR_COLORS_SUCCESS':
            return Object.assign({}, state, { productsSimilarColors: action.products })
        case 'FETCH_COLORS_CUT_TO_SIZE_REQUEST':
            return Object.assign({}, state, { colorsCutToSize: Object.assign({}, state.colorsCutToSize, { isLoading: true }) })
        case 'FETCH_COLORS_CUT_TO_SIZE_SUCCESS':
            return Object.assign({}, state, {
                colorsCutToSize: Object.assign({}, state.colorsCutToSize, { productsId: action.productsId, isLoading: false, products: action.products })
            })
        case 'FETCH_COLORS_CUT_TO_SIZE_FAILURE':
            return Object.assign({}, state, { colorsCutToSize: Object.assign({}, state.colorsCutToSize, { isLoading: false }) })
        case 'FETCH_SAMPLES_CUT_TO_SIZE_REQUEST':
            return Object.assign({}, state, { samplesCutToSize: Object.assign({}, state.samplesCutToSize, { isLoading: true }) })
        case 'FETCH_SAMPLES_CUT_TO_SIZE_SUCCESS':
            return Object.assign({}, state, {
                samplesCutToSize: Object.assign({}, state.samplesCutToSize, { samples: action.samples, isLoading: false })
            })
        case 'FETCH_SAMPLES_CUT_TO_SIZE_FAILURE':
            return Object.assign({}, state, { samplesCutToSize: Object.assign({}, state.samplesCutToSize, { isLoading: false }) })
        case 'FETCH_FURNITURES_CUT_TO_SIZE_REQUEST':
            return Object.assign({}, state, { furnituresCutToSize: Object.assign({}, state.furnituresCutToSize, { isLoading: true }) })
        case 'FETCH_FURNITURES_CUT_TO_SIZE_SUCCESS':
            return Object.assign({}, state, {
                furnituresCutToSize: Object.assign({}, state.furnituresCutToSize, { furnitures: action.furnitures, isLoading: false })
            })
        case 'FETCH_FURNITURES_CUT_TO_SIZE_FAILURE':
            return Object.assign({}, state, { furnituresCutToSize: Object.assign({}, state.furnituresCutToSize, { isLoading: false }) })
        case 'EMPTY_PRODUCTS_FAVOURITE':
            return Object.assign({}, state, { productsFavourite: Object.assign({}, state.productsFavourite, { productsId: [] }) })
        case 'FETCH_PRODUCTS_FAVOURITE_REQUEST':
            return Object.assign({}, state, { productsFavourite: Object.assign({}, state.productsFavourite, { isLoading: true }) })
        case 'FETCH_PRODUCTS_FAVOURITE_SUCCESS':
            return Object.assign({}, state, {
                productsFavourite: Object.assign({}, state.productsFavourite, { productsId: action.productsId, isLoading: false, products: action.products })
            })
        case 'FETCH_PRODUCTS_FAVOURITE_FAILURE':
            return Object.assign({}, state, { productsFavourite: Object.assign({}, state.productsFavourite, { isLoading: false }) })
        case 'ADD_PRODUCT_FAVOURITE_REQUEST':
            return Object.assign({}, state, { productsFavourite: Object.assign({}, state.productsFavourite, { isLoading: true }) })
        case 'ADD_PRODUCT_FAVOURITE_SUCCESS':
            return Object.assign({}, state, {
                productsFavourite: Object.assign({}, state.productsFavourite, {
                    productsId: [action.productId].concat(...state.productsFavourite.productsId),
                    products: [action.product].concat(...state.productsFavourite.products),
                    isLoading: false
                })
            })
        case 'ADD_PRODUCT_FAVOURITE_FAILURE':
            return Object.assign({}, state, { productsFavourite: Object.assign({}, state.productsFavourite, { isLoading: false }) })
        case 'REMOVE_PRODUCT_FAVOURITE_REQUEST':
            return Object.assign({}, state, { productsFavourite: Object.assign({}, state.productsFavourite, { isLoading: true }) })
        case 'REMOVE_PRODUCT_FAVOURITE_SUCCESS':
            return Object.assign({}, state, {
                productsFavourite: Object.assign({}, state.productsFavourite, {
                    productsId: state.productsFavourite.productsId.filter(id => {
                        return id !== action.productId
                    }),
                    products:
                        state.productsFavourite.products &&
                        state.productsFavourite.products.filter(product => {
                            return product.productId !== action.productId
                        }),
                    isLoading: false
                })
            })
        case 'REMOVE_PRODUCT_FAVOURITE_FAILURE':
            return Object.assign({}, state, { productsFavourite: Object.assign({}, state.productsFavourite, { isLoading: false }) })
        case 'FETCH_COLORS_MKTOOLS_ATTR_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_COLORS_MKTOOLS_ATTR_SUCCESS':
        case 'SAVE_COLORS_MKTOOLS_ATTR_SUCCESS':
            return Object.assign({}, state, { colorsMktoolsAttr: action.colors, colorsMktoolsAttrToDel: {}, colorsMktoolsAttrToAdd: {}, isLoading: false })
        case 'ADD_COLOR_MKTOOLS_ATTR_SUCCESS':
            let newColorsToSave = state.colorsMktoolsAttr[action.brand]
            let colorsToDelByBrand = state.colorsMktoolsAttrToDel[action.brand]
            let colorsToAddByBrand = state.colorsMktoolsAttrToAdd[action.brand]

            if (!colorsToDelByBrand) colorsToDelByBrand = []
            let indexToDel = colorsToDelByBrand.findIndex(name => name === action.color)

            if (indexToDel !== -1) {
                colorsToDelByBrand.splice(indexToDel, 1)
                colorsToDelByBrand.sort()
            } else {
                if (!colorsToAddByBrand) colorsToAddByBrand = []
                colorsToAddByBrand.push(action.color)
                colorsToAddByBrand.sort()
            }
            if (!newColorsToSave) newColorsToSave = []
            newColorsToSave.push(action.color)
            newColorsToSave.sort()

            return Object.assign({}, state, {
                colorsMktoolsAttr: { ...state.colorsMktoolsAttr, [action.brand]: newColorsToSave },
                colorsMktoolsAttrToAdd: { ...state.colorsMktoolsAttrToAdd, [action.brand]: colorsToAddByBrand },
                colorsMktoolsAttrToDel: { ...state.colorsMktoolsAttrToDel, [action.brand]: colorsToDelByBrand },
                isLoading: false
            })
        case 'REMOVE_COLOR_MKTOOLS_ATTR_SUCCESS':
            let colorsToAddForRemoveAction = state.colorsMktoolsAttr[action.brand]
            let colorsToDelByBrandForRemoveAction = state.colorsMktoolsAttrToDel[action.brand]
            let colorsToAddByBrandForRemoveAction = state.colorsMktoolsAttrToAdd[action.brand]

            if (!colorsToAddByBrandForRemoveAction) colorsToAddByBrandForRemoveAction = []
            let indexToDelForRemoveAction = colorsToAddByBrandForRemoveAction.findIndex(name => name === colorsToAddForRemoveAction[action.index])

            if (indexToDelForRemoveAction !== -1) {
                colorsToAddByBrandForRemoveAction.splice(indexToDelForRemoveAction, 1)
                colorsToAddByBrandForRemoveAction.sort()
            } else {
                if (!colorsToDelByBrandForRemoveAction) colorsToDelByBrandForRemoveAction = []
                colorsToDelByBrandForRemoveAction.push(colorsToAddForRemoveAction[action.index])
                colorsToDelByBrandForRemoveAction.sort()
            }

            colorsToAddForRemoveAction.splice(action.index, 1)

            return Object.assign({}, state, {
                colorsMktoolsAttr: { ...state.colorsMktoolsAttr, [action.brand]: colorsToAddForRemoveAction },
                colorsMktoolsAttrToAdd: { ...state.colorsMktoolsAttrToAdd, [action.brand]: colorsToAddByBrandForRemoveAction },
                colorsMktoolsAttrToDel: { ...state.colorsMktoolsAttrToDel, [action.brand]: colorsToDelByBrandForRemoveAction },
                isLoading: false
            })
        case 'FETCH_CCOLORS_MKTOOLS_ATTR_FAILURE':
            return Object.assign({}, state, { isLoading: false })

        case 'FETCH_PRODUCTS_DEVIATED_ADMIN_REQUEST':
            return { ...state, deviatedAdmin: { ...state.deviatedAdmin, isLoading: true } }
        case 'FETCH_PRODUCTS_DEVIATED_ADMIN_SUCCESS':
            return { ...state, deviatedAdmin: { ...state.deviatedAdmin, isLoading: false, products: action.productsDeviatedAdmin } }
        case 'FETCH_PRODUCTS_DEVIATED_ADMIN_FAILURE':
            return { ...state, deviatedAdmin: { ...state.deviatedAdmin, isLoading: false } }

        case 'SAVE_PRODUCTS_DEVIATED_ADMIN_REQUEST':
            return { ...state, deviatedAdmin: { ...state.deviatedAdmin, isLoading: true } }
        case 'SAVE_PRODUCTS_DEVIATED_ADMIN_SUCCESS':
            let newProductsDeviated = state.deviatedAdmin.products.map(item => {
                if (action.products.filter(newItem => item.productId === newItem.productId && item.centerId === newItem.centerId).length > 0)
                    return action.products.filter(newItem => item.productId === newItem.productId && item.centerId === newItem.centerId)[0]
                else return item
            })
            return { ...state, deviatedAdmin: { ...state.deviatedAdmin, isLoading: false, products: newProductsDeviated } }
        case 'SAVE_PRODUCTS_DEVIATED_ADMIN_FAILURE':
            return { ...state, deviatedAdmin: { ...state.deviatedAdmin, isLoading: false } }

        case 'FETCH_DEVIATED_DESCRIPTIONS_REQUEST':
            return { ...state, deviatedDescriptionsIsLoading: true }
        case 'FETCH_DEVIATED_DESCRIPTIONS_SUCCESS':
            return { ...state, deviatedDescriptionsIsLoading: false, deviatedDescriptions: action.descriptions }
        case 'FETCH_DEVIATED_DESCRIPTIONS_FAILURE':
            return { ...state, deviatedDescriptionsIsLoading: false }
        case 'FETCH_LAST_VISITED_PRODUCTS_REQUEST_SUCCESS':
            return { ...state, lastVisitedProducts: action.products }
        default:
            return state
    }
}
export const getProductsForYouId = state => {
    return state.products.productsForYouId
}
export const getProductsForYouState = state => {
    return state.products.productsForYouState
}
export const getProductsForYou = state => {
    return state.products.productsForYou
    // if (getProductsForYouId(state) === null) return []
    // const productsForYou = []
    // getProductsForYouId(state).forEach(product => productsForYou.push(byProductId(state, product)))
    // return productsForYou
}
export const getProductsSimilarColorsId = state => {
    return state.products.productsSimilarColorsId
}
export const getProductsSimilarColorsState = state => {
    return state.products.productsSimilarColorsState
}
export const getProductsSimilarColors = state => {
    return state.products.productsSimilarColors
}
export const getLastProductBoughtsId = state => {
    return state.products.lastBoughtProductsId
}
export const getError = state => {
    return state.products.error
}
export const getLastBoughtProductsState = state => {
    return state.products.lastBoughtProductsState
}
export const getLastProductBoughts = state => {
    return state.products.lastBoughtProducts
    // if (getLastProductBoughtsId(state) === null) return []
    // const lastBoughtProducts = []
    // getLastProductBoughtsId(state).forEach(product => lastBoughtProducts.push(byProductId(state, product)))
    // return lastBoughtProducts
}
export const byProductId = (state, productId) => {
    let centerId = getSelectedCenter(state) ? getSelectedCenter(state) : 'near'
    let locale = getLanguageToFetchZ2(state)

    let found =
        state.products &&
        state.products.fetchedProducts.find(item => {
            if (item.control) return false
            const currentUrl = getCurrentUrl(state)
            if (isComingFromMall(state) || (currentUrl && (currentUrl.pathname.includes('landing-template') || currentUrl.pathname.includes('mall')))) {
                return item.product.productId === productId
            } else {
                return item.product.productId === productId && item.centerId === centerId && item.locale === locale
            }
        })
    return found ? found.product : found
}

export const discountsByProductId = (state, productId, outlet) => {
    if (!productId || !state.login.allProductsDiscounts) return null
    let centerId = getSelectedCenter(state)
    let found = state.login.allProductsDiscounts.filter(item => {
        return item.productId == productId && item.center == centerId && item.outlet == (outlet ? true : false)
    })
    return found ? found : []
}
export const getProductsIdFavourite = state => {
    return state.products.productsFavourite.productsId
}
export const isFavouriteById = (state, productId) => {
    return !!(state.products.productsFavourite.productsId && state.products.productsFavourite.productsId.find(id => id == productId))
}
export const getShowModal = state => {
    return state.products.productsFavourite.showModal
}
export const getIsAdding = state => {
    return state.products.productsFavourite.isAdding
}
export const getIsLoading = state => {
    return state.products.productsFavourite.isLoading
}
export const getGallery = state => {
    return state.products.gallery
}
export const getFetchedProducts = state => {
    return state.products.fetchedProducts
}
export const getLastVisitedProduct = state => {
    return state.products.lastVisitedProducts
}
export const getColorsCutToSizeIsLoading = state => {
    return state.products.colorsCutToSize.isLoading
}
export const getColorsCutToSizeProducts = state => {
    return state.products.colorsCutToSize.products
}
export const getSamplesCutToSize = state => {
    return state.products.samplesCutToSize.samples
}
export const getFurnituresCutToSize = state => {
    return state.products.furnituresCutToSize.furnitures
}
export const getProductsFavouritesByIds = state => {
    return state.products.productsFavourite.products
}
export const similarProductByProductId = (state, productId) => {
    let centerId = getSelectedCenter(state)
    let locale = getLanguageToFetchZ2(state)
    let found = state.products.fetchedProducts.find(item => {
        return item.centerId === centerId && item.product.productId === productId && item.product.similarProducts.length > 0 && item.locale === locale
    })
    return found ? found.product : found
}
export const getProductsMktToMax = state => {
    return state.products.productsMktToMax
}
export const getBrandsMktoolsAttr = state => {
    //HARDCODE: list of brands
    return hardcode.listBrandsMktoolsAttr
}
export const getColorsMktoolsAttr = state => {
    return state.products.colorsMktoolsAttr
}
export const getColorsMktoolsAttrToDel = state => {
    return state.products.colorsMktoolsAttrToDel
}
export const getColorsMktoolsAttrToAdd = state => {
    return state.products.colorsMktoolsAttrToAdd
}
export const getLocaleWithft2 = () => {
    return ['en-US', 'fr-CA', 'en-CA']
}
export const canViewPrices = state => {
    if (!getIsLogged(state)) return false
    if (isEmserUser(state)) return false
    return !(getUserType(state) === 'SHOP' || getIsIkeaCenter(state) || getIsLowesCenter(state))

    // let userNames = [
    //     '0000301893',
    //     '0000305017',
    //     '0000303184',
    //     '0000110610',
    //     '0000110472',
    //     '0000103619',
    //     '0000103617',
    //     '0000103598',
    //     '0000425597',
    //     '0000411822',
    //     '0000412465',
    //     '0000102163',
    //     '0000102173',
    //     '0000110652',
    //     '0000102013',
    //     '0000408750',
    //     '0000411529'
    // ]
    // let centerNames = ['PRICING', 'TOULOUSE', 'HOLLAND', 'MADRID', 'CATARINA', 'PRICINGUSA', 'ORLANDO', 'ANAHEIM']
    // if (!getIsLogged(state)) return false

    // if (centerNames.includes(getImpersonatedBy(state))) return true
    // if (userNames.includes(getUserName(state))) return true
    // if (centerNames.includes(getUserName(state))) return true
    // return false
}
export const canViewPricesPhase2 = state => {
    if (!getIsLogged(state)) return false
    if (getIsMkToolsCenter(state)) return false
    if (isEmserUser(state)) return false
    return !(getUserType(state) === 'SHOP' || getIsIkeaCenter(state) || getIsLowesCenter(state))

    // let userNames = [
    //     '0000301893',
    //     '0000305017',
    //     '0000303184',
    //     '0000110610',
    //     '0000110472',
    //     '0000103619',
    //     '0000103617',
    //     '0000103598',
    //     '0000102163',
    //     '0000102173',
    //     '0000110652',
    //     '0000102013',
    //     '0000408750',
    //     '0000411529',
    //     '0000425597',
    //     '0000411822',
    //     '0000412465'
    // ]

    // let centerNames = ['PRICING', 'TOULOUSE', 'HOLLAND', 'MADRID', 'CATARINA', 'PRICINGUSA', 'ORLANDO', 'ANAHEIM']
    // if (!getIsLogged(state)) return false
    // if (centerNames.includes(getImpersonatedBy(state))) return true
    // if (userNames.includes(getUserName(state))) return true
    // return false
}
export const canViewPricesPhase3 = state => {
    let centerNames = ['PRICING', 'MEXICO', 'SAOPAULO', 'BRISBANE', 'ORLANDO', 'ANAHEIM', 'HAIFA', 'SINGAPORE', 'MILANO']
    if (!getIsLogged(state)) return false
    if (isEmserUser(state)) return false
    if (getUserType(state) === 'SHOP' || getIsIkeaCenter(state) || getIsLowesCenter(state)) return false

    if (centerNames.includes(getImpersonatedBy(state))) return true
    return true
    // let userNames = [
    //     '0000301893',
    //     '0000305017',
    //     '0000303184',
    //     '0000110610',
    //     '0000110472',
    //     '0000103619',
    //     '0000103617',
    //     '0000103598',
    //     '0000102163',
    //     '0000102173',
    //     '0000110652',
    //     '0000102013',
    //     '0000408750',
    //     '0000411529',
    //     '0000425597',
    //     '0000411822',
    //     '0000412465'
    // ]

    // let centerNames = ['PRICING', 'TOULOUSE', 'HOLLAND', 'MADRID', 'CATARINA', 'PRICINGUSA', 'ORLANDO', 'ANAHEIM']
    // if (!getIsLogged(state)) return false
    // if (centerNames.includes(getImpersonatedBy(state))) return true
    // if (userNames.includes(getUserName(state))) return true
    // return false
}
export const canViewLotId = state => {
    let salesOrg = ['2011', '2012', '2032', '2036']
    if (getUserType(state) === 'ADMIN') return true
    if (getImpersonatedBy(state)) return true
    if (salesOrg.includes(getSalesOrg(state))) return true
    return false
}
export const getProductsDeviatedAdmin = state => state.products.deviatedAdmin.products
export const getProductsDeviatedAdminIsLoading = state => state.products.deviatedAdmin.isLoading
export const getDeviatedDescriptions = state => state.products.deviatedDescriptions

export const getPromotionsProductIds = (state, outlet) => {
    //TODO: Fix get products IDS to Filter Catalog
    if (state.login.allProductsDiscounts) {
        return state.login.allProductsDiscounts.filter(discount => discount.outlet == (outlet ? true : false)).map(discount => discount['productId'])
    }
    return []
}

/**
 * Determines the product is Home Depot
 * @param {object} state
 * @param {string} colorId
 * @param {boolean} outlet
 * @returns {boolean}
 */
export const isExclusiveHomeDepot = (state, colorId) => {
    const homeDepotColorId = [
        'NL2',
        'HDA',
        'LC1',
        'LG1',
        'G1S',
        'LC2',
        'LG3',
        'LG2'
    ]

    const availableSalesOrg = ['2011', '2037']

    if (homeDepotColorId.includes(colorId) && availableSalesOrg.includes(getUserSalesOrg(state))) {
        return true
    }

    return false
}

export default products
