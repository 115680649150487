import React from 'react'
import Spinner from '../../../global/Spinner'
import Translation from '../../../global/Translation'
import ProfileContainer from '../../../profile/ProfileContainer'
import NavigationTabs from '../NavigationTabs'
import NewShippingCard from './NewShippingCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import Button from '../../../global/Button'
import GoBackButton from '../GoBackButton'
import ShippingHistoryFilter from "./ShippingHistoryFilter";
import ShippingWalkthrough from '../../../walkthroughs/shipping/ShippingWalkthrough'

class NewShippingHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filters: null,
            shouldRenderWalkthrough: false
        }
    }
    componentDidMount() {
        this.props.setIsShippingHistoryScreen(true)
    }

    componentWillUnmount() {
        this.props.setIsShippingHistoryScreen(false)
    }

    fetchOrderDeliveries(paginate=false) {
        this.props.fetchOrderDeliveriesPaginate(...this.mapFilters(this.state.filters), paginate ? (this.props.currentPage + 1) : 1).then(() => this.setState({ shouldRenderWalkthrough: true }))
    }

    mapFilters = (filters) => {
        if (filters && (filters.selections || filters.search)) {
            return [
                filters.selections && filters.selections.status_type_filter && filters.selections.status_type_filter.value,
                filters.selections && filters.selections.by_date_filter && filters.selections.by_date_filter.value.startDate,
                filters.selections && filters.selections.by_date_filter && filters.selections.by_date_filter.value.endDate,
                filters.search,
                filters.selections && filters.selections.shipping_type_filter && filters.selections.shipping_type_filter.value,
            ]
        }

        return [null, null, null, null, null]
    }

    updateFilters = filters => {
        const prevFilters = this.mapFilters(this.state.filters)
        const currentFilters = this.mapFilters(filters)
        let update = false

        currentFilters.every((v, index) => {
            if (prevFilters[index] !== v) {
                update = true
                return false
            }
            return true
        })

        if (update) this.fetchOrderDeliveries()
    }

    render() {
        const { deliveries, isLoading, currentPage, lastPage, redirect, isIkeaCenter, deliveryStatusMap, adaptedFormat, locale, replaceDotWithComma } = this.props

        return (
            <React.Fragment>
                <GoBackButton />
                <div className="box-profile row">
                    {/* Sidebar */}
                    <div className="profile-container-box col-12 col-md-4 col-lg-3">
                        <ProfileContainer />
                    </div>

                    <div className="col-12 col-xl-9 p-0">
                        {isLoading && <Spinner isLoading={isLoading} />}
                        <div className="order-histoy-container">
                            <div className="order__history__header">
                                <div className="header__title">
                                    <h1>
                                        <Translation id="shipping_history" defaultMessage="Shipping history" />
                                    </h1>
                                </div>
                                <div className="header__subtitle">
                                    <h4>
                                        <Translation id="show" defaultMessage="Show" />
                                    </h4>
                                </div>
                                <NavigationTabs />

                                <ShippingHistoryFilter onChange={filters => this.setState({filters: filters}, this.fetchOrderDeliveries)}/>
                            </div>

                            {/* <InfiniteScroll dataLength={deliveries.length} next={() => currentPage < lastPage && this.fetchOrderDeliveries(true)} hasMore={true}> */}
                                {deliveries.length > 0 ? (
                                    deliveries.map((delivery, idx) => (
                                        <NewShippingCard 
                                            key={idx}
                                            delivery={delivery}
                                            redirect={redirect}
                                            isIkeaCenter={isIkeaCenter}
                                            adaptedFormat={adaptedFormat}
                                            deliveryStatusMap={deliveryStatusMap}
                                            locale={locale}
                                            replaceDotWithComma={replaceDotWithComma}
                                        />
                                    ))
                                ) : null}

                                {/* Button load more */}
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <Button onClick={() => this.fetchOrderDeliveries(true)} size="medium" inline disabled={currentPage >= lastPage || isLoading}>
                                        {currentPage >= lastPage ? (
                                            <Translation id="no_results" defaultMessage="No results" />
                                        ) : (
                                            <Translation id="load_more" defaultMessage="Load more" />
                                        )}
                                    </Button>
                                </div>
                            {/*  </InfiniteScroll> */}
                        </div>
                    </div>
                </div>
                {this.state.shouldRenderWalkthrough && <ShippingWalkthrough />}
            </React.Fragment>
        )
    }
}

export default NewShippingHistory
