import React from 'react'
import Translation from '../../../global/Translation'
import { injectIntl } from 'react-intl'

const DisplayNaturalStoneOrigin = ({ product, isLotGridView, isLotTableView, intl }) => {
    /**
     * Get the country code of the product
     * @returns {(string|null)} countryCode translated
     */
    const getCountryCode = () => {
        if (product && product.countryCode) {
            /** @type {object} */
            const countryCodeTranslations = {
                AE: 'country_AE',
                AT: 'country_AT',
                AU: 'country_AU',
                BE: 'country_BE',
                BR: 'country_BR',
                CA: 'country_CA',
                CH: 'country_CH',
                DE: 'country_DE',
                DK: 'country_DK',
                DU: 'country_DU',
                ES: 'country_ES',
                FI: 'country_FI',
                FR: 'country_FR',
                GB: 'country_GB',
                IE: 'country_IE',
                IL: 'country_IL',
                IT: 'country_IT',
                JP: 'country_JP',
                LT: 'country_LT',
                MX: 'country_MX',
                MY: 'country_MY',
                NL: 'country_NL',
                NO: 'country_NO',
                NZ: 'country_NZ',
                PL: 'country_PL',
                PR: 'country_PR',
                PT: 'country_PT',
                SE: 'country_SE',
                SG: 'country_SG',
                TR: 'country_TR',
                US: 'country_US',
                ZA: 'country_ZA',
                AF: 'country_AF',
                AO: 'country_AO',
                CN: 'country_CN',
                EG: 'country_EG',
                GR: 'country_GR',
                IN: 'country_IN',
                IR: 'country_IR',
                MU: 'country_MU',
                MZ: 'country_MZ',
                PK: 'country_PK',
                TN: 'country_TN',
                VN: 'country_VN',
                ZW: 'country_ZW'
            }

            /** @type {string} */
            const countryCode = product.countryCode
            return intl.formatMessage({ id: countryCodeTranslations[countryCode] })
        }
        return null
    }

    /**
     * Determines if the product is natural stone
     * @returns {boolean}
     */
    const isNaturalStone = () => {
        if (product && product.brand) {
            const naturalStone = ['scalea', 'sensa', 'prexury']
            if (naturalStone.includes(product.brand.toLowerCase())) return true
            return false
        }
        return false
    }

    const originString = <Translation id="origin" defaultMessage="Origin" />

    return isNaturalStone() && getCountryCode() ? (
        isLotGridView ? (
            <div className="row__lot__info">
                <div className="row__lot__info--key">
                    <span>
                        {originString}
                    </span>
                    :
                </div>
                <div className="row__lot__info--value">
                    <span>{getCountryCode()}</span>
                </div>
            </div>
        ) : isLotTableView ? (
            <td style={{textAlign:'end', position:'relative'}}>
                {getCountryCode()}
            </td>
        ) : (
            <div className="product-info-details">
                <div className="product_detail pn-origin">
                    <span className="text">
                        {originString}
                        :&nbsp;
                        {getCountryCode()}
                    </span>
                </div>
            </div>
        )
    ) : null
}

export default injectIntl(DisplayNaturalStoneOrigin)
