import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { getSelectedSubchannel, getSubchannelOptions, getCurrentSegmentation, getSelectedBusinesSegment, getSelectedChannel } from '../../store/segmentation/reducers'
import { toggleSegmentation, selectSubChannel } from '../../store/segmentation/actions'
const mapStateToProps = state => {
    const businessSegment = getSelectedBusinesSegment(state)
    const channel = getSelectedChannel(state)

    return {
        show: getCurrentSegmentation(state) === 'SUBCHANNEL',
        selected: getSelectedSubchannel(state),
        options: getSubchannelOptions(state, businessSegment, channel)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('SUBCHANNEL'))
        },
        onSelect: (value, name) => {
            dispatch(selectSubChannel(value, name))
        }
    }
}
const SubChannel = ({ show, selected, onToggle, onSelect, options }) => {
    if (options === undefined) {
        return ''
    }
    if (Object.keys(options).length === 1 && selected === null) {
        Object.entries(options).map(option => onSelect(option[0]))
    }
    const selectedValue = selected ? <span>{options[selected].name}</span> : null
    return (
        <div>
            <div onClick={onToggle} className="option">
                <Translation id="subchannel" defaultMessage="Subchannel" /> : {selectedValue}
                <i className={show ? 'fa fa-caret-down' : 'fa fa-caret-right'} aria-hidden="true" />
                <br />
            </div>

            <div style={{ display: show ? 'block' : 'none' }}>
                <div className="row segment-selections">
                    {Object.entries(options).map(option => (
                        <span
                            key={option[0]}
                            onClick={() => {
                                onSelect(option[0], option[0].name)
                            }}
                        >
                            {option[1].name}
                        </span>
                    ))}
                </div>
            </div>
            <hr />
        </div>
    )
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubChannel)
