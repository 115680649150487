import { connect } from 'react-redux'
import NewShippingHistory from './NewShippingHistory'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getDeliveries, getDeliveriesCurrentPage, getDeliveriesLastPage, getDeliveriesLoading } from '../../../../store/deliveries/reducers'
import { fetchOrderDeliveriesPaginate } from '../../../../store/deliveries/actions'
import { getIsIkeaCenter } from '../../../../store/centers/reducers'
import { dispatchPushURL, setIsShippingHistoryScreen } from '../../../../store/ui/actions'
import { getAdaptedFormat, getReplaceDotWithComma } from '../../../../helpers'
import { getDeliveryStatusMap } from "../../../../store/orders/reducers";
import { getLanguage } from '../../../../store/ui/reducers'

const mapStateToProps = (state) => {
    return {
        deliveries: getDeliveries(state),
        currentPage: getDeliveriesCurrentPage(state),
        lastPage: getDeliveriesLastPage(state),
        isLoading: getDeliveriesLoading(state),
        isIkeaCenter: getIsIkeaCenter(state),
        deliveryStatusMap: getDeliveryStatusMap(state),
        locale: getLanguage(state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrderDeliveriesPaginate: (status, startDate, endDate, search, type, page) => {
            return dispatch(fetchOrderDeliveriesPaginate(status, startDate, endDate, search, type, page))
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        setIsShippingHistoryScreen: isShippingHistoryScreen => {
            dispatch(setIsShippingHistoryScreen(isShippingHistoryScreen))
        }
    }
}

const NewShippingHistoryContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(NewShippingHistory)
    )
)

export default NewShippingHistoryContainer
