import moment from 'moment'

//REVIEW:: PV_REPLACE_CENTERS
// export const CENTERS = ['1000', '1070']
export const CODI_CENTER = 1100;
export const PORTFOLIO_MODE_SHOW_ONLY_CODI = 0;
export const PORTFOLIO_MODE_SHOW_ONLY_USER_CENTER = 1;
export const PORTFOLIO_MODE_SHOW_BOTH_CENTERS = 2;
export const CENTERS = ['1100','7120','7126']
export const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const LANG_TO_INDEX = {
    da: 'EN',
    de: 'DE',
    'en-US': 'Z2',
    en: 'EN',
    es: 'ES',
    fi: 'EN',
    'fr-CA': 'Z2',
    'en-CA': 'Z2',
    fr: 'FR',
    it: 'IT',
    nl: 'NL',
    no: 'EN',
    pt: 'PT',
    pl: 'EN',
    sv: 'SV',
    tr: 'TR'
}

export const CREATION_MODE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    NEW_LANGUAGE: 'NEW_LANGUAGE',
    EDIT_LANGUAGE: 'EDIT_LANGUAGE'
}

export const isImageValid = image => {
    if (!image || !image.type || (image.type !== 'image/png' && image.type !== 'image/jpeg' && image.type !== 'image/jpg') || !image.size || image.size > 10000000) {
        return false
    }

    return true
}

export const advParseLang = (l, forIcon = false) => {
    switch (l.iso_639_2) {
        case 'en': {
            switch (l.ietf) {
                case 'en-GB': {
                    return forIcon ? 'gb' : 'en'
                }
                case 'en-US': {
                    return forIcon ? 'us' : 'en-US'
                }
                case 'en-CA': {
                    return forIcon ? 'ca' : 'en-CA'
                }
                default: {
                    return l.ietf
                }
            }
        }
        case 'fr': {
            switch (l.ietf) {
                case 'fr-FR': {
                    return 'fr'
                }
                case 'fr-CA': {
                    return forIcon ? 'ca' : 'fr-CA'
                }
                default: {
                    return l.ietf
                }
            }
        }
        case 'ja': {
            return forIcon ? 'jp' : 'ja'
        }
        case 'he': {
            return forIcon ? 'il' : 'he'
        }
        case 'nn': {
            return 'no'
        }
        case 'da': {
            return forIcon ? 'dk' : 'da'
        }
        default: {
            switch (l.ietf) {
                case 'en-GB': {
                    return forIcon ? 'gb' : 'en'
                }
                case 'en-US': {
                    return forIcon ? 'us' : 'en-US'
                }
                case 'en-CA': {
                    return forIcon ? 'ca' : 'en-CA'
                }
                case 'fr-FR': {
                    return 'fr'
                }
                case 'fr-CA': {
                    return forIcon ? 'ca' : 'fr-CA'
                }
                default: {
                    return l.iso_639_2
                }
            }
        }
    }
}

export const getFileData = data => {
    if (data && typeof data !== 'string') {
        return data
    } else {
        try {
            const pData = JSON.parse(data)

            if (pData) {
                return pData
            }
        } catch (e) {}
    }

    return data
}

export const getMarkets = markets => {
    if (markets.length > 0) {
        return markets
            .map(market => {
                return { value: market.code, label: market.name, salesOrg: market.salesOrg }
            })
            .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    }
    return []
}

export const getCountries = (countries, intl) => {
    if (countries.length > 0) {
        return countries
            .map(country => {
                return { id: country.code, value: country, label: intl.formatMessage({ id: 'country_' + country.code }), salesOrg: country.salesOrg }
            })
            .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    }
    return []
}

export const getStatuses = (statuses, intl) => {
    if (statuses.length > 0) {
        return statuses.map(status => {
            return { value: status, label: intl.formatMessage({ id: 'status_' + status }) }
        })
    }
    return []
}

export const getCenters = centers => {
    if (centers.length > 0) {
        return centers
            .map(center => {
                return { value: center.id, label: center.id + ' ' + center.name, salesOrg: center.salesOrg }
            })
            .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    }
    return []
}

export const getFilteredByMarket = (filtersParameters = {}, landings) => {
    const marketParam = filtersParameters && filtersParameters.value !== 'Todos' ? filtersParameters.value : null
    return marketParam ? landings.filter(landing => landing.market === marketParam) : landings
}

export const getFilteredByCountry = (filtersParameters = {}, landings) => {
    const countryParam = filtersParameters ? filtersParameters.id : null
    return countryParam ? landings.filter(landing => landing.countries.indexOf(countryParam) > -1) : landings
}

export const getFilteredByCenter = (filtersParameters = {}, landings) => {
    const centerParam = filtersParameters ? filtersParameters.value : null
    return centerParam ? landings.filter(landing => landing.centers.indexOf(centerParam) > -1) : landings
}

export const getFilteredByCountries = (countries = [], landings = []) => {
    return countries.reduce((filteredLandings, country) => getFilteredByCountry(country, filteredLandings), landings)
}

export const getFilteredByCenters = (centers = [], landings = []) => {
    return centers.reduce((filteredLandings, center) => getFilteredByCenter(center, filteredLandings), landings)
}

export const getFilteredByStatus = (statusSelected = 'ALL', landings = []) => {
    return statusSelected !== 'ALL' ? landings.filter(landing => landing.status === statusSelected) : landings
}

export const getFilteredByTitle = (searchString = '', landings = []) => {
    return searchString ? landings.filter(landing => landing.title.toUpperCase().indexOf(searchString.toUpperCase()) > -1) : landings
}

export const getFilteredByDates = (dates, landings = []) => {
    if (dates && (dates.start || dates.end)) {
        return landings.filter(
            landing =>
                landing.start_at &&
                landing.end_at &&
                (moment(landing.start_at).isBetween(dates.start, dates.end) ||
                    moment(landing.start_at).isSame(dates.start, 'day') ||
                    ((dates.end && moment(landing.end_at).isBetween(dates.start, dates.end)) ||
                        moment(landing.start_at).isSame(dates.end, 'day') ||
                        moment(landing.end_at).isSame(dates.end, 'day') ||
                        (!dates.end && moment(landing.start_at).isAfter(dates.start))))
        )
    }

    return landings
}

export const getFilteredLandings = (filtersParameters = {}, landings = [], intl) => {
    const { selectedMarket = {}, selectedCountry = [], selectedCenter = [], statusSelected = 'ALL', searchString = '', dates = { start: null, end: null } } = filtersParameters

    const landingsFilteredByMarket = getFilteredByMarket(selectedMarket, landings)
    const landingsFilteredByCountries = getFilteredByCountries(selectedCountry || [], landingsFilteredByMarket)
    const landingsFilteredByCenters = getFilteredByCenters(selectedCenter || [], landingsFilteredByCountries)
    const landingsFilteredByStatus = getFilteredByStatus(statusSelected || '', landingsFilteredByCenters)
    const landingsFilteredByTitle = getFilteredByTitle(searchString || '', landingsFilteredByStatus)
    const landingsFilteredByDates = getFilteredByDates(dates || {}, landingsFilteredByTitle)

    return landingsFilteredByDates.map(l => {
        return { ...l, status: intl.formatMessage({ id: 'status_' + l.status }) }
    })
}

export const arrayToFormData = (array = [], fd, field) => {
    ;(array || []).map((item, index) => {
        if (field === 'countries') {
            fd.append(`countries[${index}]`, item)
        }
        if (field === 'centers') {
            fd.append(`centers[${index}]`, item)
        }
    })
}

export const parseLandingStatus = landing => {
    if (landing.status !== 'SCHEDULE') {
        return landing.status;
    } else {
        const endDay = landing && landing.end_at  
        const isNotExpired = !endDay || moment().isBefore(endDay)
        return isNotExpired ?  'PUBLISHED' : 'EXPIRED'
    }
}