import React from 'react'
import Translation from '../../global/Translation'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getShowHealthConditions } from '../../../store/ui/reducers'
import { toggleHealthConditionsCheck } from '../../../store/checkout/actions'
import { getHealthConditionsCheck } from '../../../store/checkout/reducers'

const mapStateToProps = state => {
    return {
      showHealthConditions: getShowHealthConditions(state),
      hasHealthConditionsCheck: getHealthConditionsCheck(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleHealthConditionsCheck: checked => {
          dispatch(toggleHealthConditionsCheck(checked))
        }
    }
}

class CheckboxLinkHealthConditions extends React.Component {
  render() {
      if (!this.props.showHealthConditions) return null
        return(
            <label className="CheckboxLinkSaleConditions" data-qas="checkout_steptwo-checkbox-health-conditions-checkbox">
              <input
                name="health_conditions_check"
                type="checkbox"
                onClick={input => this.props.toggleHealthConditionsCheck(input.target.checked)}/>
                {' '}
                <span className='checkmark' />
              <Translation id="through" defaultMessage="Through" />
              {' '}
              <a href="https://osh.cosentino.com/health-safety-documentation/safety-data-sheets-sds/" target="_blank" rel="nofollow noopener noreferrer">
                <Translation id="this_link" defaultMessage="this link" />
              </a>
              {' '}
              <Translation id="received_read_safety_data_sheets_products_purchased" defaultMessage="I have received and read the safety data sheets of the products purchased, and I commit to conveying this information to the worker who will process the products" />
            </label>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CheckboxLinkHealthConditions))
