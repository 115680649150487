import moment from 'moment'

export const initState = {
    lastView: null,
    lastRelease: null,
    loading: false,
    items: null,
    itemsNews: null
}

const changelog = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_CHANGELOG_BY_PROJECT_REQUEST':
            return { ...state, loading: true }
        case 'FETCH_CHANGELOG_BY_PROJECT_SUCCESS':
            return { ...state, loading: false, items: action.items, lastRelease: moment(Object.keys(action.items)[0]).valueOf() }
        case 'FETCH_CHANGELOG_BY_PROJECT_NEWS_SUCCESS':
            return { ...state, loading: false, itemsNews: action.items }
        case 'FETCH_CHANGELOG_BY_PROJECT_FAILURE':
            return { ...state, loading: false, items: action.items }
        case 'SET_LAST_VIEW':
            return Object.assign({}, state, { lastView: action.date })

        case 'SIMPLE_IMPERSONATE_SUCCESS':
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}
export const getChangesRead = state => state.changelog.lastView > state.changelog.lastRelease

export const getChangelogLastView = state => state.changelog.lastView

export const getChangelogLastUpdate = state => state.changelog.lastRelease

export const getChangelogItems = state => state.changelog.items

export const getChangelogItemsNews = state => state.changelog.itemsNews

export const getIsLoading = state => state.changelog.loading

export default changelog
