import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { canViewLotId } from '../../../../store/products/reducers'
import Translation from '../../../global/Translation'

const mapStateToProps = state => {
    return {
        canViewLotId: canViewLotId(state)
    }
}
class DisplayLotId extends React.Component {
    render() {
        if (this.props.canViewLotId) {
            return (
                <React.Fragment>
                    {this.props.withDescription && <Translation id="lot_id" defaultMessage="Lot ID" />}
                    {this.props.lotId}
                </React.Fragment>
            )
        } else {
            return ''
        }
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(DisplayLotId)
)
