import React from 'react'

import { injectIntl, defineMessages } from 'react-intl'
import ReactTable from 'react-table'
import Spinner from '../global/Spinner'
import OrderTransfersStatus from './OrderTransfersStatus'

import Translation from '../global/Translation'
defineMessages({
    RP: {
        id: 'RP',
        description: 'RP',
        defaultMessage: 'Reserve pending confirmation'
    },
    RC: {
        id: 'RC',
        description: 'RC',
        defaultMessage: 'Confirmed reservation'
    },
    PP: {
        id: 'PP',
        description: 'PP',
        defaultMessage: 'Pending order to create'
    },
    PC: {
        id: 'PC',
        description: 'PC',
        defaultMessage: 'Order created'
    },
    CE: {
        id: 'CE',
        description: 'CE',
        defaultMessage: 'External customer'
    },
    ER: {
        id: 'ER',
        description: 'ER',
        defaultMessage: 'Error creating order'
    },
    RR: {
        id: 'RR',
        description: 'RR',
        defaultMessage: 'Reserve pending to cancel'
    },
    RE: {
        id: 'RE',
        description: 'RE',
        defaultMessage: 'Cancelled reservation'
    },
    PM: {
        id: 'PM',
        description: 'PM',
        defaultMessage: 'Order modified'
    },
    WL: {
        id: 'WL',
        description: 'WL',
        defaultMessage: 'Reserve Wilsam'
    },
    SH: {
        id: 'SH',
        description: 'SH',
        defaultMessage: 'Reservations Shops USA'
    },
    CP: {
        id: 'CP',
        description: 'CP',
        defaultMessage: 'Pending changes'
    }
})
class Transfers extends React.Component {
    state = { search: null }
    componentDidMount() {
        this.props.fetchOrders('transfer', this.state.startDate, this.state.endDate)
    }

    search = e => {
        this.setState({ search: e.target.value })
    }

    render() {
        const { intl } = this.props
        const orders = this.props.transferOrders
        if (!orders) return null
        let ordersFiltered = null

        if (this.state.search) {
            ordersFiltered = orders
            ordersFiltered = ordersFiltered.filter(row => {
                return row.reservationId.toLowerCase().includes(this.state.search.toLowerCase()) || row.orderId.toLowerCase().includes(this.state.search.toLowerCase())
            })
        }
        const dataTransferOrders = ordersFiltered !== null ? ordersFiltered : orders
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="toolbar">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 order_header">
                                        <div className="group_title">
                                            <h4>
                                                <Translation id="transfer_orders" defaultMessage="Transfer orders" />
                                            </h4>
                                        </div>
                                        <div className="group_date_picker" />
                                        <div className="location search-bar search-bar--thin">
                                            <input
                                                type="text"
                                                data-qa="query"
                                                className="search-box"
                                                placeholder={intl.formatMessage({ id: 'search' })}
                                                value={this.state.search}
                                                onChange={e =>
                                                    this.setState({
                                                        search: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ReactTable
                            data={dataTransferOrders}
                            loading={this.props.isLoading}
                            loadingText={<Spinner isLoading={this.props.isLoading} />}
                            className="-highlight"
                            columns={[
                                { Header: <Translation id="reservation_id" defaultMessage="Reservation ID" />, accessor: 'reservationId' },
                                { Header: <Translation id="order_id" defaultMessage="Order ID" />, accessor: 'orderId' },
                                {
                                    Header: <Translation id="status" defaultMessage="Status" />,
                                    id: 'status',
                                    Cell: ({ original }) => {
                                        return <OrderTransfersStatus status={original.orderStatus} />
                                        // return original.reservationStatus
                                    },
                                    minWidth: 250
                                },
                                { Header: <Translation id="shippingMethod" defaultMessage="Shipping method" />, accessor: 'shippingType' },
                                { Header: <Translation id="shipping_date" defaultMessage="Shipping date" />, accessor: 'preferredShippingAddress' },
                                { Header: <Translation id="last_update" defaultMessage="Last update" />, accessor: 'updatedAt' }
                            ]}
                            defaultSorted={[
                                {
                                    id: 'reservationId',
                                    desc: true
                                }
                            ]}
                            defaultPageSize={8}
                            pageSizeOptions={[8, 16, 32, 64, 128]}
                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                            nextText={<Translation id="next" defaultMessage="Next" />}
                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                            pageText={<Translation id="page" defaultMessage="Page" />}
                            ofText={<Translation id="of" defaultMessage="Of" />}
                            getTrProps={(state, rowInfo) => {
                                let cursor = rowInfo ? 'pointer' : 'default'

                                return {
                                    // onClick: e => {
                                    //     if (rowInfo.original.reservationId) {
                                    //         this.showOrderDetails(rowInfo.original.reservationId)
                                    //     }
                                    // },
                                    style: { cursor: cursor }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(Transfers)
