import { defineMessages } from 'react-intl'
import flooring from '../../assets/img/cut-to-size/flooring.svg'
import skirting from '../../assets/img/cut-to-size/skirting.svg'
import justCut from '../../assets/img/cut-to-size/just-cut.svg'
import furnitures from '../../assets/img/cut-to-size/furnitures.svg'
import flooring_bmp from '../../assets/img/cut-to-size/Soleria-v2.png'
import skirting_bmp from '../../assets/img/cut-to-size/Rodapies-v2.png'
import justCut_bmp from '../../assets/img/cut-to-size/solo-corte-v2.png'
import furnitures_bmp from '../../assets/img/cut-to-size/Mobiliario-v2.png'
import budget_bmp from '../../assets/img/cut-to-size/presupuesto_det.png'
import washbasinBath from '../../assets/img/cut-to-size/washbasin-bath.png'


// defineMessages(
//     SOLERIA: ElaborationOptionsMap.SOLERIA,
//     RODAPIES: ElaborationOptionsMap.RODAPIES,
//     SOLO_CORTE: ElaborationOptionsMap.SOLO_CORTE,
//     MOBILIARIO: ElaborationOptionsMap.MOBILIARIO
// })

// if (typeof require.context === 'undefined') {
//     const fs = require('fs');
//     const path = require('path');
  
//     require.context = (base = '.', scanSubDirectories = false, regularExpression = /\.js$/) => {
//         const files = {};

//         function readDirectory(directory) {
//             fs.readdirSync(directory).forEach((file) => {
//                 const fullPath = path.resolve(directory, file);
//                 if (fs.statSync(fullPath).isDirectory()) {
//                     if (scanSubDirectories) readDirectory(fullPath);
//                     return;
//                 }

//                 if (!regularExpression.test(fullPath)) return;
//                 files[fullPath] = true;
//             });
//         }

//         readDirectory(path.resolve(__dirname, base));
//         function Module(file) {
//             return require(file);
//         }

//         Module.keys = () => Object.keys(files);
//         return Module;
//     };
// }

// const imgOutlineContext = require.context('../../assets/img/cut-to-size/models/outline', false, /\.(png|jpe?g|svg)$/)
// const imgDetailsContext = require.context('../../assets/img/cut-to-size/models/detail', false, /\.(png|jpe?g|svg)$/)
// const imgExtensionsContext = require.context('../../assets/img/cut-to-size/models/ext', false, /\.(png|jpe?g|svg)$/)
// export const ModelsOutlines = imgOutlineContext && imgOutlineContext.keys().map(key =>({[key] : imgOutlineContext(key)}))
// export const ModelsDetails = imgDetailsContext && imgDetailsContext.keys().map(key =>({[key] : imgDetailsContext(key)}))
// export const ModelsExtensions = imgExtensionsContext && imgExtensionsContext.keys().map(key =>({[key] : imgExtensionsContext(key)}))

export const ElaborationOptionsMap = {
    SOLERIA : {
        name: {
            id: 'soleria',
            defaultMessage: 'Flooring',
            description: 'cut to size soleria',
        },
        icon : flooring,
        image: flooring_bmp,
        text: {
            id: 'flooring_description',
            defaultMessage: 'Choose flooring in the format, color, finish, and thickness you prefer.',
            description: 'Choose flooring in the format, color, finish, and thickness you prefer.'
        },
        gtmLabel: 'ec_cam_soleria'
    },
    RODAPIES : {
        name: {
            id: 'rodapies',
            defaultMessage: 'Skirting',
            description: 'cut to size rodapies',
        },
        icon : skirting,
        image: skirting_bmp,
        text: {
            id: 'skirting_description',
            defaultMessage: 'Choose the baseboard with the length and width measurements you prefer.',
            description: 'Choose the baseboard with the length and width measurements you prefer.'
        },
        gtmLabel: 'ec_cam_rodapies'
    },
    SOLO_CORTE : {
        name: {
            id: 'solo_corte',
            defaultMessage: 'Cut to size',
            description: 'cut to size solo corte',
        },
        icon : justCut,
        image: justCut_bmp,
        text: {
            id: 'just_cut_description',
            defaultMessage: 'Choose a board from our wide range and we will cut it to your preferred measurements.',
            description: 'Choose a board from our wide range and we will cut it to your preferred measurements.'
        },
        gtmLabel: 'ec_cam_solo_corte'
    },
    CUSTOM_MOBILIARIO: {
        name: {
            id: 'mobiliario',
            defaultMessage: 'Mobiliario',
            description: 'cut to size mobiliario'
        },
        icon : furnitures,
        image: furnitures_bmp,
        text: {
            id: 'furnitures_description',
            defaultMessage: 'Configure your furniture piece. You can add extensions and choose the type of packaging.',
            description: 'Configure your furniture piece. You can add extensions and choose the type of packaging.'
        },
        gtmLabel: 'ec_cam_mobiliario'
    },
    MOBILIARIO : {
        name: {
            id: 'mobiliario',
            defaultMessage: 'Mobiliario',
            description: 'cut to size mobiliario'
        },
        icon : furnitures,
        image: furnitures_bmp,
        text: {
            id: 'furnitures_description',
            defaultMessage: 'Configure your furniture piece. You can add extensions and choose the type of packaging.',
            description: 'Configure your furniture piece. You can add extensions and choose the type of packaging.'
        },
        gtmLabel: 'ec_cam_mobiliario'
    },
    BUDGET : {
        name: {
            id: 'budget',
            defaultMessage: 'Presupuesto de elaborado',
            description: 'cut to size Presupuesto de elaborado'
        },
        icon : null,
        image: budget_bmp,
        text: {
            id: 'budget',
            defaultMessage: 'Quotation cut to size',
            description: 'Quotation cut to size'
        },
        gtmLabel: ''
    },
    CONFIGURABLE_BATH : {
        name: {
            id: 'washbasing_and_shower_trays',
            defaultMessage: 'Washbasins and shower trays',
            description: 'Washbasins and shower trays'
        },
        icon : null,
        image: washbasinBath,
        text: {
            id: 'configurable_bath_description',
            defaultMessage: 'Customize your sink or shower tray using the 3D configurator.',
            description: 'Customize your sink or shower tray using the 3D configurator.'
        },
        gtmLabel: ''
    }
}

export const BrandOptionsMap = {
    SILESTONE: { name: 'Silestone', icon: 'SILESTONE' , position: 1 },
    DEKTON: { name: 'Dekton', icon: 'DEKTON', position: 2 },
    SENSA: { name: 'Sensa', icon: 'SENSA', position: 3 },
    SCALEA: { name: 'Scalea', icon: 'SCALEA', position: 4 },
    PREXURY: { name: 'Prexury', icon: 'PREXURY', position: 5 },
}

export const Messages = defineMessages({
    SET_DEADLINE : {
        id: 'set_deadline_date',
        defaultMessage: 'Set preferred delivery date',
        description: 'cut to size Set deadline date',
    },
    CHOOSE_MODEL : {
        id: 'choose_model',
        defaultMessage: 'Choose model',
        description: 'cut to size Choose model',
    },
    CHOOSE_SHAPE : {
        id: 'choose_shape',
        defaultMessage: 'Choose shape',
        description: 'cut to size Choose shape',
    },
    CHOOSE_PROJECT_TYPE : {
        id: 'choose_project_type',
        defaultMessage: 'Choose a project type',
        description: 'cut to size Choose project type',
    },
    CHOOSE_FILE_TYPE : {
        id: 'choose_file_type',
        defaultMessage: 'Choose a file type',
        description: 'cut to size Choose file type',
    },
    CHOOSE_BRAND : {
        id: 'choose_brand',
        defaultMessage: 'Choose brand',
        description: 'cut to size Choose brand',
    },
    CHOOSE_PACKAGING : {
        id: 'choose_packaging',
        defaultMessage: 'Choose packaging',
        description: 'cut to size Choose packaging',
    },
    CHOOSE_COLOR : {
        id: 'choose_color',
        defaultMessage: 'Choose color',
        description: 'cut to size Choose color',
    },
    CHOOSE_FINISH : {
        id: 'choose_finish',
        defaultMessage: 'Choose finish',
        description: 'cut to size Choose finish',
    },
    CHOOSE_ADDON : {
        id: 'choose_addon',
        defaultMessage: 'Choose addon',
        description: 'cut to size Choose addon',
    },
    CHOOSE_THICKNESS : {
        id: 'choose_thickness',
        defaultMessage: 'Choose thickness',
        description: 'cut to size Choose thickness',
    },
    CHOOSE_FORMAT : {
        id: 'choose_format',
        defaultMessage: 'Choose format',
        description: 'cut to size Choose format',
    },
    CHOOSE_EDGE : {
        id: 'choose_edge',
        defaultMessage: 'Choose edge',
        description: 'cut to size Choose edge',
    },
    CHOOSE_BEVEL : {
        id: 'choose_bevel',
        defaultMessage: 'Choose bevel',
        description: 'cut to size Choose bevel',
    },
    CHOOSE_HEIGHT : {
        id: 'choose_height',
        defaultMessage: 'Choose height',
        description: 'cut to size Choose height',
    },
    CHOOSE_WIDTH : {
        id: 'choose_width',
        defaultMessage: 'Choose width',
        description: 'cut to size Choose width',
    },
    CHOOSE_OPTION : {
        id: 'choose_option',
        defaultMessage: 'Choose option',
        description: 'cut to size Choose option',
    },
    BACK_SELECT_ELABORATED_TYPE : {
        id: 'back_select_elaborated_type',
        defaultMessage: 'Back to elaborated type selection',
        description: 'cut to size Back to elaborated type selection',
    },
    MIN_LENGTH : {
        id: 'min_length',        
        defaultMessage: 'Minimum length',
        description: 'cut to size Minimum length',
    },
    MAX_LENGTH : {
        id: 'max_length',        
        defaultMessage: 'Maximum length',
        description: 'cut to size Maximum length',
    },
    MIN_DIMENSIONS : {
        id: 'min_dimensions',        
        defaultMessage: 'Minimum dimensions',
        description: 'cut to size Minimum dimensions',
    },
    MAX_DIMENSIONS : {
        id: 'max_dimensions',        
        defaultMessage: 'Maximum dimensions',
        description: 'cut to size Maximum dimensions',
    },
    MEASURE_A : {
        id: 'measure_a',        
        defaultMessage: 'Measure A',
        description: 'cut to size Measure A',
    },
    MEASURE_B : {
        id: 'measure_b',        
        defaultMessage: 'Measure B',
        description: 'cut to size Measure B',
    },
    MEASURE_C : {
        id: 'measure_c',        
        defaultMessage: 'Measure C',
        description: 'cut to size Measure C',
    },
    MEASURE_D : {
        id: 'measure_d',        
        defaultMessage: 'Measure D',
        description: 'cut to size Measure D',
    },
    SUCCESS_REQUEST_QUOTE: {
        id: 'success_request_quote',
        defaultMessage: 'The budget request has been sent. You\'ll recieve a response soon. Thanks.',
        description: 'message showed when an user request a Cut To Size quote'
    },
    SOLERIA: {
        id: 'soleria',
        defaultMessage: 'Flooring',
        description: 'cut to size soleria',
    },
    RODAPIES: {
        id: 'rodapies',
        defaultMessage: 'Skirting',
        description: 'cut to size rodapies',
    },
    SOLO_CORTE: {
        id: 'solo_corte',
        defaultMessage: 'Cut to size',
        description: 'cut to size solo corte',
    },
    CUSTOM_MOBILIARIO: {
        id: 'mobiliario',
        defaultMessage: 'Mobiliario',
        description: 'cut to size mobiliario'
    },
    MOBILIARIO: {
        id: 'mobiliario',
        defaultMessage: 'Mobiliario',
        description: 'cut to size mobiliario'
    },
    BUDGET: {
        id: 'budget',
        defaultMessage: 'Presupuesto de elaborado',
        description: 'cut to size Presupuesto de elaborado'
    },
    PACKAGING_10_5 : {
        id: 'packaging_10_5',
        defaultMessage: 'Packaging (10,5)',
        description: 'cut to size Packaging (10,5) label'
    },
    SIDE: {
        id: 'side',
        defaultMessage: 'Side',
        description: 'side',
    },
    DIAMETER: {
        id: 'diameter',
        defaultMessage: 'Diameter',
        description: 'diameter',
    },
    SQUARED: {
        id: 'squared',
        defaultMessage: 'Squared',
        description: 'Squared',
    },
    RECTANGULAR: {
        id: 'rectangular',
        defaultMessage: 'Rectangular',
        description: 'Rectangular',
    },
    RECTANGULAR_CURVE_RADIUS: {
        id: 'rectangular_curve_radius',
        defaultMessage: 'Rectangular curve radius',
        description: 'Rectangular curve radius',
    },
    ELLIPSE: {
        id: 'ellipse',
        defaultMessage: 'Ellipse',
        description: 'Ellipse',
    },
    ROUNDED: {
        id: 'rounded',
        defaultMessage: 'Rounded',
        description: 'Rounded',
    },
    SIMPLE_PACKAGING : {
        id: 'simple_packaging',
        defaultMessage: 'Simple packaging',
        description: 'Simple packaging'
    },
    PACKAGING_A : {
        id: 'packaging_a',
        defaultMessage: 'Packaging A',
        description: 'Packaging A'
    },
    PACKAGING_B : {
        id: 'packaging_b',
        defaultMessage: 'Packaging B',
        description: 'Packaging B'
    },
    PACKAGING_C : {
        id: 'packaging_c',
        defaultMessage: 'Packaging C',
        description: 'Packaging C'
    },
    MITER : {
        id: 'miter',
        defaultMessage: 'Miter',
        description: 'Miter'
    },
    POLISHED_MITER : {
        id: 'polished_miter',
        defaultMessage: 'Polished miter',
        description: 'Polished miter'
    },
    BULLNOSE : {
        id: 'bullnose',
        defaultMessage: 'Bullnose',
        description: 'Bullnose'
    },
    DEMI_BULLNOSE : {
        id: 'demi_bullnose',
        defaultMessage: 'Demi bullnose',
        description: 'Demi bullnose'
    },
    PLATNER : {
        id: 'platner',
        defaultMessage: 'Platner',
        description: 'Platner'
    },
    OGEE : {
        id: 'ogee',
        defaultMessage: 'Ogee',
        description: 'Ogee'
    },
    POLISHED_STRAIGHT : {
        id: 'polished_straight',
        defaultMessage: 'Polished straight',
        description: 'Polished straight'
    },
    ROUNDED_BEVEL : {
        id: 'rounded_bevel',
        defaultMessage: 'Rounded bevel',
        description: 'Rounded bevel'
    },
    JUST_BEVEL : {
        id: 'just_bevel',
        defaultMessage: 'Just bevel',
        description: 'Just bevel'
    },
    B2 : {
        id: 'B2',
        defaultMessage: "[2-3] mm",
        description: "CTS BEVEL [2-3] mm"
    },
    B3 : {
        id: 'B3',
        defaultMessage: "[4-5] mm",
        description: "CTS BEVEL [4-5] mm",
    },
    B4 : {
        id: 'B4',
        defaultMessage: "[6-10] mm",
        description: "CTS BEVEL [6-10] mm"
    },
    FURNITURE_DESCRIPTION: {
        id: 'furnitures_description',
        defaultMessage: 'Configure your furniture piece. You can add extensions and choose the type of packaging.',
        description: 'Configure your furniture piece. You can add extensions and choose the type of packaging.'
    },
    SKIRTING_DESCRIPTION: {
        id: 'skirting_description',
        defaultMessage: 'Choose the baseboard with the length and width measurements you prefer.',
        description: 'Choose the baseboard with the length and width measurements you prefer.'
    },
    FLOORING_DESCRIPTION: {
        id: 'flooring_description',
        defaultMessage: 'Choose flooring in the format, color, finish, and thickness you prefer.',
        description: 'Choose flooring in the format, color, finish, and thickness you prefer.'
    },
    JUST_CUT_DESCRIPTION: {
        id: 'just_cut_description',
        defaultMessage: 'Choose a board from our wide range and we will cut it to your preferred measurements.',
        description: 'Choose a board from our wide range and we will cut it to your preferred measurements.'
    },
    CONFIGURABLE_BATH_DESCRIPTION: {
        id: 'configurable_bath_description',
        defaultMessage: 'Customize your sink or shower tray using the 3D configurator.',
        description: 'Customize your sink or shower tray using the 3D configurator.'
    },
    with_mesh: {
        id: 'with_mesh',
        defaultMessage: 'With mesh',
        description: 'With mesh'
    },
    without_mesh: {
        id: 'without_mesh',
        defaultMessage: 'Without mesh',
        description: 'Without mesh'
    }
})

export const BudgetMessages = defineMessages({
    FURNITURE: {
        id: 'furniture',
        defaultMessage: 'Furniture',
        description: 'cut to size budget Furniture'
    },
    BATH:  {
        id: 'bath',
        defaultMessage: 'Bath',
        description: 'cut to size budget Bath'
    },
    KITCHEN:  {
        id: 'kitchen',
        defaultMessage: 'Kitchen',
        description: 'cut to size budget Kitchen'
    },
    FACADE:  {
        id: 'facade',
        defaultMessage: 'Facade',
        description: 'cut to size budget Facade'
    },
    WALL_CLADING:  {
        id: 'wall_clading',
        defaultMessage: 'Wall clading',
        description: 'cut to size budget Wall clading'
    },
    SKIRTING:  {
        id: 'skirting',
        defaultMessage: 'Skirting',
        description: 'cut to size budget Skirting'
    },
    CUT_TO_SIZE:  {
        id: 'cut_to_size',
        defaultMessage: 'Cut to size',
        description: 'cut to size budget Cut to size'
    },
    OTHER_ELABORATED:  {
        id: 'other_elaborated',
        defaultMessage: 'Other elaborated',
        description: 'cut to size budget Other elaborated'
    },
    BLUEPRINT_FILE_TYPE: {
        id: 'blueprint',
        defaultMessage: 'Blueprint',
        description: 'file type blueprint'
    },
    LABELS_FILE_TYPE: {
        id: 'labels',
        defaultMessage: 'Labels',
        description: 'file type labels'
    },
    EMAIL_FILE_TYPE: {
        id: 'email',
        defaultMessage: 'Email',
        description: 'file email labels'
    }
})


export const PreorderMessages = defineMessages({
    BX10: {
        id: 'labels', //croquis
        defaultMessage: 'Labels',
        description: 'file type labels'
    },
    BX03: {
        id: 'email',
        defaultMessage: 'Email',
        description: 'file email labels'
    },
    BX01: {
        id: 'blueprint', //email
        defaultMessage: 'Blueprint',
        description: 'file type blueprint'
    },
    BX04: {
        id: 'blueprint', //email
        defaultMessage: 'Blueprint',
        description: 'file type blueprint'
    },
    BX07: {
        id: 'blueprint', //email
        defaultMessage: 'Blueprint',
        description: 'file type blueprint'
    },
    //bx 10 => etiqueta
    //bx 04 => plano para confirmar
    //bx 07 => confirmacon pedido
    GENERIC: {
        id: 'generic',
        defaultMessage: 'Generic',
        description: 'generic file type'
    }
})




//TODO AÑADIR NUEVOS IDS *SOLO SE USA PARA MOSTRAR IMAGEN GENÉRICA DEL ELABORADO
export const ElaborationOptionsMapById = {
    80457: ElaborationOptionsMap.SOLERIA,
    80594: ElaborationOptionsMap.SOLERIA,
    80596: ElaborationOptionsMap.SOLERIA,
    80461: ElaborationOptionsMap.RODAPIES,
    80586: ElaborationOptionsMap.RODAPIES,
    80588: ElaborationOptionsMap.RODAPIES,
    80589: ElaborationOptionsMap.RODAPIES,
    80451: ElaborationOptionsMap.SOLO_CORTE,
    80561: ElaborationOptionsMap.SOLO_CORTE,
    80562: ElaborationOptionsMap.SOLO_CORTE,
    80563: ElaborationOptionsMap.SOLO_CORTE,
    80565: ElaborationOptionsMap.SOLO_CORTE,
    80567: ElaborationOptionsMap.SOLO_CORTE,
    80458: ElaborationOptionsMap.MOBILIARIO
}

//SOLO SE USA PARA OBTENER EL SKY GENÉRICO DEL ELABORADO EN ANALÍTICAS - FALTA MARCA
export const CtsIdsByElaboration = (elaboration, brand) =>  {
    const map = {
        SOLERIA: {
            SILESTONE: 80594,
            SCALEA: 80596
        },
        RODAPIES: {
            SILESTONE: 80586,
            DEKTON: 80588,
            SCALEA: 80589
        },
        SOLO_CORTE: {
            SILESTONE: 80561,
            DEKTON: 80562,
            SCALEA: 80563,
            SENSA: 80565,
            PREXURY: 80567
        },
        MOBILIARIO: 80458,
        CUSTOM_MOBILIARIO: 80458
    }

    if (elaboration === 'SOLO_CORTE') {
        return map.SOLO_CORTE[brand] ? map.SOLO_CORTE[brand] : 80451
    }
    if (elaboration === 'SOLERIA') {
        return map.SOLERIA[brand] ? map.SOLERIA[brand] : 80457
    }
    if (elaboration === 'RODAPIES') {
        return map.RODAPIES[brand] ? map.RODAPIES[brand] : 80461
    }

    return map[elaboration]
}

// const TranslationMessages = {}
// for (const [key, value] of Object.entries(ElaborationOptionsMap)) TranslationMessages[key] = value.name
// for (const [key, value] of Object.entries(Messages)) TranslationMessages[key] = value
// defineMessages(TranslationMessages)