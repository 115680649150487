import React from "react";
import {productLogic} from "./logic/ProductLogic";
import {mallProductLogic} from "./logic/MallProductLogic";
import {BaseProductLogic} from "./logic/BaseProductLogic";
import ProductIndicators from "./ProductIndicators";
import MallProductIndicators from "./MallProductIndicators";
import {ProductDetails} from "./ProductDetails";

export const CatalogProductContext = {
    logicComponent : (props) => productLogic(props, BaseProductLogic),
    indicatorsComponent: ProductIndicators,
    detailsComponent: ProductDetails
}

export const MallProductContext = {
    logicComponent : (props) => mallProductLogic(props, BaseProductLogic),
    indicatorsComponent: MallProductIndicators,
    detailsComponent: ProductDetails
}

const ProductContext = React.createContext(CatalogProductContext)

export default ProductContext