import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import ReactTable from 'react-table'
import Button from '../global/Button'
import ProductImage from '../catalog/ProductImage'
import ReactTooltip from 'react-tooltip'
import InputNumber from '../global/InputNumber'

export const getUnitBy = product => {
    if (parseInt(product.center, 10) === 5200) return product.minQty['MKTOOLS'] || 1
    if (product && product.typeId === '01') return 1
    if (product.unitsByLot) return parseInt(product.unitsByLot, 10)
    if (product.unitsByBox) return parseInt(product.unitsByBox, 10)
    return 1
}
class MktProductsMax extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
            search: ''
        }
    }
    componentDidMount() {
        this.props.fetchProductsMktToMax().then(() => {
            this.setState({ data: this.props.products })
        })
    }

    onChangeValue(productId, center, input, value) {
        let data = this.state.data.map(item => {
            if (item.productId === productId && item.center === center) {
                switch (input) {
                    case 'min':
                        if (isNaN(value) && value !== '') value = 0
                        return { ...item, min: value && parseInt(value, 10) }
                    case 'max':
                        if (isNaN(value) && value !== '') value = 0
                        return { ...item, max: value && parseInt(value, 10) }
                    case 'maxWeek':
                        if (isNaN(value) && value !== '') value = 0
                        return { ...item, maxWeek: value && parseInt(value, 10) }
                    case 'maxMonth':
                        if (isNaN(value) && value !== '') value = 0
                        return { ...item, maxMonth: value && parseInt(value, 10) }
                    case 'providerEmail':
                        if (!value) value = ''
                        return { ...item, providerEmail: value }
                    case 'haveOptions':
                        return { ...item, haveOptions: value }
                    default:
                        return item
                }
            } else return item
        })
        this.setState({ data: data })
    }
    getValue(productId, center, input) {
        let product = this.state.data.filter(item => item.productId === productId && item.center === center)[0]
        switch (input) {
            case 'min':
                return product.min
            case 'max':
                return product.max
            case 'maxWeek':
                return product.maxWeek
            case 'maxMonth':
                return product.maxMonth
            case 'providerEmail':
                return product.providerEmail
            case 'haveOptions':
                return product.haveOptions
            default:
                return 0
        }
    }
    itemReset(item) {
        let data = this.state.data.map(stateItem => {
            if (stateItem.productId === item.productId && stateItem.center === item.center)
                return this.props.products.filter(product => product.productId === item.productId && product.center === item.center)[0]
            else return stateItem
        })
        this.setState({ data: data })
    }
    itemChanged(item) {
        return !this.props.products.find(
            ele =>
                ele.productId === item.productId &&
                ele.center === item.center &&
                ele.max === item.max &&
                ele.maxMonth === item.maxMonth &&
                ele.maxWeek === item.maxWeek &&
                ele.providerEmail === item.providerEmail &&
                ele.min === item.min &&
                ele.haveOptions === item.haveOptions
        )
    }
    parseValues(items) {
        return items.map(item => {
            const newValues = { min: parseFloat(item.min), max: parseFloat(item.max), maxWeek: parseFloat(item.maxWeek), maxMonth: parseFloat(item.maxMonth) }
            return { ...item, ...newValues }
        })
    }
    checkValuesItem(item) {
        let minOk = true
        if (item.min) {
            if (item.max) minOk = item.min <= item.max
            if (item.maxWeek) minOk = item.min <= item.maxWeek
            if (item.maxMonth) minOk = item.min <= item.maxMonth
        }

        let maxOk = true
        if (item.max) {
            if (item.min) maxOk = item.max >= item.min
            if (item.maxWeek) maxOk = item.max <= item.maxWeek
            if (item.maxMonth) maxOk = item.max <= item.maxMonth
        }

        let maxWeekOk = true
        if (item.maxWeek) {
            if (item.min) maxWeekOk = item.maxWeek >= item.min
            if (item.max) maxWeekOk = item.maxWeek >= item.max
            if (item.maxMonth) maxWeekOk = item.maxWeek <= item.maxMonth
        }

        let maxMonthOk = true
        if (item.maxMonth) {
            if (item.min) maxMonthOk = item.maxMonth >= item.min
            if (item.max) maxMonthOk = item.maxMonth >= item.min
            if (item.maxWeek) maxMonthOk = item.maxMonth >= item.min
        }
        return minOk && maxOk && maxWeekOk && maxMonthOk
    }
    saveItems(item = null) {
        if (item !== null) {
            if (this.checkValuesItem(item))
                this.props
                    .saveProductsMktToMax(this.parseValues(this.state.data.filter(stateItem => stateItem.productId === item.productId && stateItem.center === item.center)))
                    .then(() => this.itemReset(item))
            else this.props.showAlertFailure(item.productId)
        } else {
            let itemsWithError = this.state.data.filter(stateItem => !this.checkValuesItem(stateItem))
            if (itemsWithError.length === 0)
                this.props
                    .saveProductsMktToMax(this.parseValues(this.state.data.filter(stateItem => this.itemChanged(stateItem))))
                    .then(() => this.setState({ data: this.props.products }))
            else this.props.showAlertFailure(itemsWithError.map(item => item.productId).toString())
        }
    }
    renderProviderEmail = ({ original }) => (
        <div>
            <input
                type="text"
                id={original.productId + original.center}
                value={this.getValue(original.productId, original.center, 'providerEmail')}
                key={original.productId + original.center}
                style={{ width: '100%' }}
                onChange={input => this.onChangeValue(original.productId, original.center, 'providerEmail', input.target.value, input.target)}
            />
        </div>
    )
    renderMin = ({ original }) => {
        return (
            <div>
                {/*
            //this was the old input on this section, changed to inputnumber to control step on mktools products
             <input
                type="text"
                value={this.getValue(original.productId, original.center, 'min')}
                key={original.productId + original.center}
                onChange={input => this.onChangeValue(original.productId, original.center, 'min', input.target.value)}
            /> */}
                <InputNumber
                    value={this.getValue(original.productId, original.center, 'min')}
                    change={value => this.onChangeValue(original.productId, original.center, 'min', value)}
                    size="small"
                    step={getUnitBy(original)}
                    allowNull={true}
                />
            </div>
        )
    }
    renderMax = ({ original }) => (
        <div>
            {/*
            //this was the old input on this section, changed to inputnumber to control step on mktools products
             <input
                type="text"
                value={this.getValue(original.productId, original.center, 'max')}
                key={original.productId + original.center}
                onChange={input => this.onChangeValue(original.productId, original.center, 'max', input.target.value)}
            /> */}
            <InputNumber
                value={this.getValue(original.productId, original.center, 'max')}
                change={value => this.onChangeValue(original.productId, original.center, 'max', value)}
                size="small"
                step={getUnitBy(original)}
                allowNull={true}
            />
        </div>
    )
    renderMaxMonth = ({ original }) => (
        <div>
            <input
                type="text"
                value={this.getValue(original.productId, original.center, 'maxMonth')}
                key={original.productId + original.center}
                onChange={input => this.onChangeValue(original.productId, original.center, 'maxMonth', input.target.value)}
            />
        </div>
    )
    render() {
        let maxColumnName = this.props.centerId == 5200 ? <Translation id="max_month" defaultMessage="Max month" /> : <Translation id="max_unit" defaultMessage="Max unit" />
        const columns = [
            {
                Header: <Translation id="image" defaultMessage="Image" />,
                accessor: 'image',
                Cell: ({ original }) => (
                    <div
                        key={`img-${original.productId}-${original.center}`}
                        style={{
                            height: '55px',
                            width: '80px'
                        }}
                    >
                        <ProductImage product={original} />
                    </div>
                ),
                maxWidth: 85,
                className: 'cell_background-image'
            },
            { Header: <Translation id="id" defaultMessage="ID" />, accessor: 'productId', maxWidth: 75 },
            { Header: <Translation id="product" defaultMessage="Product" />, accessor: 'productName', maxWidth: 125 },
            { Header: <Translation id="center" defaultMessage="Center" />, accessor: 'center', maxWidth: 75 },
            {
                Header: () => (
                    <span data-tip data-for="min">
                        <i className="fal fa-info-circle" /> <Translation id="min_unit" defaultMessage="Min unit" />
                    </span>
                ),
                accessor: 'min',
                Cell: this.renderMin,
                maxWidth: 160
            },
            {
                Header: () => (
                    <span data-tip data-for="max">
                        <i className="fal fa-info-circle" /> {maxColumnName}
                    </span>
                ),
                accessor: 'max',
                Cell: this.renderMax,
                maxWidth: 160
            },
            // {
            //     Header: 'Max week',
            //     accessor: 'maxWeek',
            //     Cell: ({ original }) => (
            //         <div>
            //             <input
            //                 type="text"
            //                 value={this.getValue(original.productId, original.center, 'maxWeek')}
            //                 key={original.productId + original.center}
            //                 onChange={input => this.onChangeValue(original.productId, original.center, 'maxWeek', input.target.value)}
            //             />
            //         </div>
            //     ),
            //     maxWidth: 75
            // },
            // {
            //     Header: () => (
            //         <span data-tip data-for="max-month">
            //             <i className="fal fa-info-circle" /> Max month
            //         </span>
            //     ),
            //     accessor: 'maxMonth',
            //     Cell: this.renderMaxMonth,
            //     maxWidth: 100
            // },
            {
                Header: <Translation id="provider_email" defaultMessage="Provider email" />,
                accessor: 'providerEmail',
                Cell: this.renderProviderEmail,
                minWidth: 200
            },
            {
                Header: <Translation id="options" defaultMessage="Options" />,
                accessor: 'haveOptions',
                Cell: ({ original }) => (
                    <div style={{ textAlign: 'center' }}>
                        <input
                            type="checkbox"
                            checked={this.getValue(original.productId, original.center, 'haveOptions')}
                            key={original.productId + original.center}
                            onChange={input => this.onChangeValue(original.productId, original.center, 'haveOptions', input.target.checked)}
                        />{' '}
                        <Translation id="options" defaultMessage="Options" />
                    </div>
                ),
                minWidth: 120
            },
            {
                Header: <Translation id="actions" defaultMessage="Actions" />,
                Cell: ({ original }) => (
                    <div className="group_buttons_row_table">
                        <Button size={'small'} disabled={!this.itemChanged(original)} onClick={() => this.itemReset(original)}>
                            <i className="fa fa-undo" />
                        </Button>
                        <Button size={'small'} disabled={!this.itemChanged(original)} onClick={() => this.saveItems(original)}>
                            <i className="fa fa-save" />
                        </Button>
                    </div>
                ),
                sortable: false
            }
        ]
        let dataFiltered = this.state.data
        if (this.state.search) {
            dataFiltered = this.state.data.filter(
                item =>
                    item.productId.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    item.productName.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    item.center
                        .toString()
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase())
            )
        }
        return (
            <div className="mkt_products_admin">
                <div className="container mkt_products_admin__container">
                    <div className="toolbar">
                        <div className="toolbar__search search">
                            <div className="search-bar">
                                <input
                                    type="text"
                                    data-qa="query"
                                    className="search-box form-control"
                                    placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                    value={this.state.search}
                                    onChange={e =>
                                        this.setState({
                                            search: e.target.value
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="toolbar__filters filters">
                            <div>
                                <Button onClick={() => this.setState({ data: this.props.products })}>
                                    <i className="fa fa-undo" /> <Translation id="cancel" defaultMessage="Cancel" />
                                </Button>
                            </div>
                            <div>
                                <Button inverted onClick={() => this.saveItems()}>
                                    <i className="fa fa-save" /> <Translation id="save" defaultMessage="Save" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <ReactTable
                            data={dataFiltered}
                            columns={columns}
                            defaultPageSize={50}
                            pageSizeOptions={[50, 100, 200]}
                            className="-highlight"
                            style={{ height: 'calc(100vh - 425px)', minHeight: '450px' }}
                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                            nextText={<Translation id="next" defaultMessage="Next" />}
                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                            pageText={<Translation id="page" defaultMessage="Page" />}
                            ofText={<Translation id="of" defaultMessage="Of" />}
                        />
                    </div>
                </div>
                <ReactTooltip id="min" effect="solid">
                    <Translation id="minimum_of_units_per_order" defaultMessage="Minimum of units per order" />
                </ReactTooltip>
                <ReactTooltip id="max" effect="solid">
                    <Translation id="maximum_of_units_per_order" defaultMessage="Maximum of units per order" />
                </ReactTooltip>
                <ReactTooltip id="max-month" effect="solid">
                    <Translation id="maximum_of_units_per_month" defaultMessage="Maximum of units per month" />
                </ReactTooltip>
            </div>
        )
    }
}

export default injectIntl(MktProductsMax)
