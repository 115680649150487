import React from 'react'
import Translation from './Translation'

class QuickGuides extends React.Component {
    quickGuidesData = () => {
        return [
            {
                title: 'Guía rápida lavabos',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/FC7C6628-D235-4317-A46F6C28B0C8C4DC/guia-rapida-lavabos.pdf',
                subtype: 'LAVABO',
                type: null,
                language: 'es'
            },
            {
                title: 'Guía rápida platos de ducha de obra',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/4994C85D-8D25-4A55-A95F3A13BCA16F66/guia-rapida-dk-platos-ducha-obra.pdf',
                type: null,
                subtype: 'PLATO DUCHA',
                language: 'es'
            },
            {
                title: 'Guía rápida solería Dekton®',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/CAB033B4-F9B4-4D62-ABE78DB755A62113/guia-rapida-soleria-dekton.pdf',
                type: null,
                subtype: 'SOLERIA',
                language: 'es'
            },
            {
                title: 'Guía rápida aplacado Dekton®',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/EEF914F1-BE4A-4E58-AD0D1D38FBDA8797/guia-rapida-aplacado-dekton.pdf',
                type: null,
                subtype: 'OPTIMMA',
                language: 'es'
            },
            {
                title: 'Guía rápida platos de ducha',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/713ECE1C-C432-4FC3-85D731D3AEE9EB9D/guia-rapida-platos-ducha.pdf',
                type: null,
                subtype: 'PLATO DUCHA',
                language: 'es'
            },
            {
                title: 'Shower trays Quick guide',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/24E824DA-F8D9-4604-84FE1D1C9506A51D/shower-trays-quick-guide.pdf',
                type: null,
                subtype: 'PLATO DUCHA',
                language: 'en'
            },
            {
                title: 'Dekton® flooring Quick guide',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/117C53B6-2928-40A7-B552459002BFAD29/dk-flooring-quick-guide.pdf',
                type: null,
                subtype: 'SOLERIA',
                language: 'en'
            },
            {
                title: 'Shower floors Quick guide',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/5B9E3D71-91C2-42F7-825D326A748EC183/dk-shower-floors-quick-guide.pdf',
                type: null,
                subtype: 'PLATO DUCHA',
                language: 'en'
            },
            {
                title: 'Dekton® wall cladding Quick guide',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/8420A11F-74CA-4E84-9A55643061E8A9B9/wall-cladding-dk-quick-guide.pdf',
                type: null,
                subtype: 'OPTIMMA',
                language: 'en'
            },
            {
                title: 'Washbasins Quick guide',
                link: 'https://assetstools.cosentino.com/api/v1/bynder/doc/34DE8526-1D68-4D12-873AEAECB1AD6322/whasbasin-quick-guide.pdf',
                subtype: 'LAVABO',
                type: null,
                language: 'en'
            },
        ]
    }

    /**
     * Filters quick guides by subtype(s) and language.
     * 
     * @param {string|string[]} subtypeId - The subtype or an array of subtypes to filter by.
     * @param {string} lang - The language to filter by.
     * @returns {Object[]} - The filtered list of quick guides.
     */
    filterQuickGuidesByLanguage(subtypeId, lang) {
        const englishGuides = []
        const spanishGuides = []

        const isSubtypeArray = Array.isArray(subtypeId)

        this.quickGuidesData().forEach(guide => {
            if (isSubtypeArray ? subtypeId.includes(guide.subtype) : guide.subtype === subtypeId) {
                if (guide.language === 'es') {
                    spanishGuides.push(guide)
                } else if (guide.language === 'en') {
                    englishGuides.push(guide)
                }
            }
        })

        return lang === 'es' ? spanishGuides : englishGuides
    }

    render() {
        const { subtypeId, lang } = this.props
        const quickGuides = this.filterQuickGuidesByLanguage(subtypeId, lang)

        const displayQuickGuides = (
            <div
                className="product-quick-guides p-3 mb-3 mt-3"
                style={{ background: 'white', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', textAlign: 'left', fontWeight: '400' }}
            >
                <Translation id="technical_documentation_related_to_this_product" defaultMessage="Technical documentation related to this product" />

                {quickGuides.map(guide => (
                    <div key={guide.title} className="mt-3" style={{ display: 'flex', flexDirection: 'row' }}>
                        <a href={guide.link} className="brand-new-blue" target="_blank" rel="noopener noreferrer" gtm-label="ec_producto_doc_tecnica">
                            <div style={{ display: 'inline-flex', marginRight: '8px' }}>
                                <i className="fas fa-external-link-alt"></i>
                            </div>
                            {guide.title}
                        </a>
                    </div>
                ))}
            </div>
        )

        return quickGuides.length > 0 ? displayQuickGuides : null
    }
}

export default QuickGuides