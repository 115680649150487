import { connect } from 'react-redux'
import BundleContent from './BundleContent'
import { getLotsImages } from '../../store/lots/reducers'
import { fetchLotsImages } from '../../store/lots/actions'
import * as ui from '../../store/ui/actions'
import { showBundleContent, getBundleContent, getOriginalBundle } from '../../store/ui/reducers'
import {getSelectedCenter, isLatinaVitoria} from '../../store/centers/reducers'
import {getImpersonatedBy, getIsAdmin} from "../../store/login/reducers";

const mapStateToProps = state => {
    return {
        show: showBundleContent(state),
        bundleContent: getBundleContent(state),
        lotsImages: getLotsImages(state),
        original: getOriginalBundle(state),
        center: getSelectedCenter(state),
        isAdmin: getIsAdmin(state),
        impersonatedBy: getImpersonatedBy(state),
        isLatinaVitoria: isLatinaVitoria(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeBundleContent())
        },
        fetchLotsImages: lotIds => {
            dispatch(fetchLotsImages(lotIds))
        }
    }
}

const BundleContentContainer = connect(mapStateToProps, mapDispatchToProps)(BundleContent)

export default BundleContentContainer
