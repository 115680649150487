import React from 'react'
import { injectIntl } from 'react-intl'
import ProfileContainer from '../../../profile/ProfileContainer'
import Spinner from '../../../global/Spinner'
import LocalizedLink from '../../../global/LocalizedLink'
import { ReactComponent as PrintIcon } from '../../../../assets/img/order-history/print-icon.svg'
import { ReactComponent as ShippingTruckIcon } from '../../../../assets/img/order-history/shipping-truck.svg'
import { addForwardSlash } from '../Utils'
import Translation from '../../../global/Translation'
import ProductImage from '../../../catalog/ProductImage'
import ProductDisplayName from '../../../global/ProductDisplayName'
import Timeline from '../../Timeline'
import moment from 'moment'
import { DeliveryStatus } from '../Enums/DeliveryStatus'
import { capitalizeFirstLetter } from '../../../../helpers'
import { getConfigLineName } from '../Utils'
import ModalConfigurationDetails from '../../../cut-to-size/configurable-baths/ModalConfigurationDetails'
import ConfigBathIndicator from '../indicators/ConfigBathIndicator'

class NewShippingDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showConfigBathModal: false,
            selectedOrderLine: null
        }
    }

    componentDidMount() {
        this.props.fetchOrderDeliveriesById(this.props.deliveryId)
        this.props.setIsShippingHistoryScreen(true)
    }

    componentWillUnmount() {
        this.props.setIsShippingHistoryScreen(false)
    }

    writeOrderBody = printWindow => {
        printWindow.document.write('<html><head>')
        printWindow.document.write(document.getElementsByTagName('head')[0].innerHTML)
        printWindow.document.write('</head><body class="order__shipping__details change-elements-print p-4">')
        printWindow.document.write(this.orderScreenBody.innerHTML)
        printWindow.document.write('</body></html>')

        return Promise.resolve(printWindow)
    }

    downloadPdfShippingBody = () => {
        let printWindow = window.open('', 'PRINT', 'height=400,width=600')
        setTimeout(() => {
            this.writeOrderBody(printWindow).then(printWindow => {
                setTimeout(() => {
                    printWindow.focus()
                    printWindow.print()
                    printWindow.close()
                }, 2000)
            })
        }, 1000)

        return false
    }

    thicknessFormatIntl = orderLine => {
        if (!orderLine.product.thickness) return null
        return this.props.replaceDotWithComma(orderLine.product.thickness, this.props.locale) + ' cm'
    }

    finishFormatIntl = (orderLine, intl) => {
        if (!orderLine.product.finish) return null
        return intl.formatMessage({ id: orderLine.product.finish })
    }

    productFormatIntl = orderLine => {
        if (!orderLine.product.format) return null
        return this.props.adaptedFormat(orderLine.product.format, { upper: true, showUnits: true })
    }

    showBtnToDetails = orderLine => {
        if (orderLine.order_id && orderLine.reservation_id && !this.props.isIkeaCenter) return true
        return false
    }

    handleBtnToDetailsOnClick = orderLine => {
        this.props.redirect(
            this.props.intl
                .formatMessage({ id: 'ROUTE_ORDER_DETAILS' })
                .replace(':filter', 'view-order')
                .replace(':reservationId', orderLine.reservation_id)
        )
    }

    handleOpenConfigBathModal(orderLine) {
        this.setState({
            showConfigBathModal: true,
            selectedOrderLine: orderLine
        })
    }

    handleCloseConfigBathModal() {
        this.setState({
            showConfigBathModal: false,
            selectedOrderLine: null
        })
    }

    descriptionBlockException = orderLine => {
        return (
            <React.Fragment>
                <div className="order__shipping__details__table--description">
                    <div className="product-img">
                        <ProductImage product={null} />
                    </div>
                    <div className="product-description-table">
                        {orderLine.short_text && (
                            <div>
                                <span className="product">
                                    <span>{capitalizeFirstLetter(orderLine.short_text)}</span>
                                </span>
                            </div>
                        )}
                        {orderLine.material_id && (
                            <div>
                                <span className="technical">{orderLine.material_id}</span>
                            </div>
                        )}
                        {orderLine.lot_id && (
                            <div className="lot-id">
                                <span>
                                    <Translation id="lot_id" defaultMessage="Lot ID" />
                                    :&nbsp;
                                </span>
                                <span>{orderLine.lot_id}</span>
                            </div>
                        )}
                        <div className="qty">
                            <span>
                                <Translation id="quantity" defaultMessage="Quantity" />
                                :&nbsp;
                            </span>
                            <span>{orderLine.delivery_qty ? parseInt(orderLine.delivery_qty) : '--'}</span>
                        </div>
                        {/* For tablet screen */}
                        <div className="d-block-only-tablet">{this.additionalInfoBlock(orderLine)}</div>
                    </div>
                </div>
                {/* For mobile screen */}
                <div className="d-block-only-mobile">
                    <div className="order-divider-x"></div>
                    {this.additionalInfoBlock(orderLine)}
                    <div className="order-divider-x"></div>
                    {this.orderInformationBlock(orderLine)}
                </div>
            </React.Fragment>
        )
    }

    descriptionBlock = orderLine => {
        return (
            <React.Fragment>
                <div className="order__shipping__details__table--description">
                    <div className="product-img">
                        <ProductImage product={orderLine.product} />
                    </div>
                    <div className="product-description-table">
                        <div>
                            <span className="brand">{capitalizeFirstLetter(orderLine.product.brand)} &reg;</span>
                        </div>
                        <div>
                            <span className="product">
                                <ProductDisplayName product={orderLine.product} />
                            </span>
                        </div>
                        <div>
                            <span className="technical">
                                {addForwardSlash(
                                    orderLine.product.productId,
                                    this.productFormatIntl(orderLine),
                                    this.finishFormatIntl(orderLine, this.props.intl),
                                    this.thicknessFormatIntl(orderLine)
                                )}
                            </span>
                        </div>
                        {orderLine.lot_id && (
                            <div className="lot-id">
                                <span>
                                    <Translation id="lot_id" defaultMessage="Lot ID" />
                                    :&nbsp;
                                </span>
                                <span>{orderLine.lot_id}</span>
                            </div>
                        )}
                        <div className="qty">
                            <span>
                                <Translation id="quantity" defaultMessage="Quantity" />
                                :&nbsp;
                            </span>
                            <span>{orderLine.delivery_qty ? parseInt(orderLine.delivery_qty) : '--'}</span>
                        </div>
                        {/* For tablet screen */}
                        <div className="d-block-only-tablet">{this.additionalInfoBlock(orderLine)}</div>
                    </div>
                </div>
                {/* For mobile screen */}
                <div className="d-block-only-mobile">
                    <div className="order-divider-x"></div>
                    {this.additionalInfoBlock(orderLine)}
                    <div className="order-divider-x"></div>
                    {this.orderInformationBlock(orderLine)}
                </div>
            </React.Fragment>
        )
    }

    descriptionBlockConfigBath = orderLine => {
        const cts = orderLine.cut_to_size
        return (
            <React.Fragment>
                <div className="order__shipping__details__table--description">
                    <div className="product-img">
                        <img src={cts.thumb} alt={getConfigLineName(cts.configurable)} className="is-img-configurable"/>
                        <ConfigBathIndicator />
                    </div>
                    <div className="product-description-table">
                        <div>
                            <span className="brand">{capitalizeFirstLetter(cts.configurable.material.brand)} &reg;</span>
                        </div>
                        <div>
                            <span className="product">
                                <span className="product-title">
                                    {getConfigLineName(cts.configurable)}
                                </span>
                            </span>
                        </div>
                        <div>
                            <span className="technical">
                                <Translation id="customized" defaultMessage="Custom" />
                            </span>
                        </div>
                        <div className="qty">
                            <span>
                                <Translation id="quantity" defaultMessage="Quantity" />
                                :&nbsp;
                            </span>
                            <span>{orderLine.delivery_qty ? parseInt(orderLine.delivery_qty) : '--'}</span>
                        </div>
                        <div className="configurable-bath-btn-orders">
                            <button onClick={() => this.handleOpenConfigBathModal(orderLine)}>
                                <Translation id="view_customization_details" defaultMessage="View customization details" />
                            </button>
                        </div>
                        {/* For tablet screen */}
                        <div className="d-block-only-tablet">{this.additionalInfoBlock(orderLine)}</div>
                    </div>
                </div>
                {/* For mobile screen */}
                <div className="d-block-only-mobile">
                    <div className="order-divider-x"></div>
                    {this.additionalInfoBlock(orderLine)}
                    <div className="order-divider-x"></div>
                    {this.orderInformationBlock(orderLine)}
                </div>
            </React.Fragment>
        )
    }

    additionalInfoBlock = orderLine => {
        return (
            <div className="order__shipping__details__table--additional">
                <div className="comments">
                    <span>
                        <Translation id="comments" defaultMessage="Comments" />
                        :&nbsp;
                    </span>
                    <span>{orderLine.comments ? orderLine.comments : '--'}</span>
                </div>
            </div>
        )
    }

    orderInformationBlock = orderLine => {
        if (this.showBtnToDetails(orderLine)) {
            return (
                <div className="order__shipping__details__table--order">
                    <div>
                        <span>
                            <Translation id="belongs_to_order" defaultMessage="Belongs to order" />
                            :&nbsp;
                        </span>
                    </div>
                    <div>
                        <button onClick={() => this.handleBtnToDetailsOnClick(orderLine)} className="btn-to-order-details">
                            {orderLine.order_id}
                        </button>
                    </div>
                </div>
            )
        }
        return false
    }

    displayShippingAddress = () => {
        let delivery = this.props.delivery
        let deliveryOrderLines = this.props.delivery.order_lines
        let shippingAddrByUser = this.props.shippingAddressesByUser

        let availableShippingAddrId =
            delivery &&
            deliveryOrderLines &&
            deliveryOrderLines.filter(orderLine => {
                return orderLine.shipping_address_id !== null
            })

        if (deliveryOrderLines && availableShippingAddrId[0]) {
            let newShippingAddrId = availableShippingAddrId[0]

            let orderLineShippingAddrId = newShippingAddrId.shipping_address_id

            let shippingAddress =
                shippingAddrByUser && orderLineShippingAddrId
                    ? shippingAddrByUser.filter(shippingAddress => {
                          return parseInt(shippingAddress.shippingAddressId) === parseInt(orderLineShippingAddrId)
                      })[0]
                    : null

            return shippingAddress ? (
                <span>
                    {shippingAddress.customerName}&nbsp;{shippingAddress.address}&nbsp;{shippingAddress.zipCode}&nbsp;{shippingAddress.city}&nbsp;{shippingAddress.country}
                </span>
            ) : (
                '--'
            )
        }
    }

    render() {
        const { deliveryId, delivery, isLoading, deliveryStatusMap } = this.props

        const headerButtonsGroup = (
            <React.Fragment>
                {delivery && delivery.order_lines && (
                    <div>
                        <button onClick={this.downloadPdfShippingBody} gtm-label="ec_historial_envios_imprimir_detalle">
                            <PrintIcon />
                            <span>
                                <Translation id="print_shipping_details" defaultMessage="Print my shipping details" />
                            </span>
                        </button>
                    </div>
                )}
            </React.Fragment>
        )

        const timelineDeliveryStatusColor = () => {
            let deliveryStatus = delivery && delivery.delivery_status
            if (deliveryStatus === DeliveryStatus.Treated_partially) return '--status-treated-partially'
            if (deliveryStatus === DeliveryStatus.Treated_completelly) return '--status--treated--completelly'
            if (deliveryStatus === DeliveryStatus.Not_treated) return '--status--not-treated'
        }

        const timelineDeliveryStatusLogic = () => {
            const deliveryStatus = delivery && delivery.delivery_status
            const deliveryDate = delivery && delivery.delivery_date
            const expectedDeliveryDate = delivery && delivery.expected_delivery_date

            return (
                <span className="timeline__delivery__status__text">
                    {deliveryStatus === DeliveryStatus.Treated_completelly ? (
                        <React.Fragment>
                            <span>
                                <Translation id="delivered_on" defaultMessage="Delivered on" />
                            </span>
                            :&nbsp;
                            <span>{moment(deliveryDate).isSame(moment(), 'day') ? <Translation id="today" defaultMessage="Today" /> : moment(deliveryDate).format('L')}</span>
                        </React.Fragment>
                    ) : deliveryStatus === DeliveryStatus.Treated_partially ? (
                        <React.Fragment>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>
                                {moment(expectedDeliveryDate).isSame(moment(), 'day') ? (
                                    <Translation id="today" defaultMessage="Today" />
                                ) : (
                                    moment(expectedDeliveryDate).format('L')
                                )}
                            </span>
                        </React.Fragment>
                    ) : deliveryStatus === DeliveryStatus.Not_treated ? (
                        <React.Fragment>
                            <span>
                                <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                            </span>
                            :&nbsp;
                            <span>
                                {moment(expectedDeliveryDate).isSame(moment(), 'day') ? (
                                    <Translation id="today" defaultMessage="Today" />
                                ) : (
                                    moment(expectedDeliveryDate).format('L')
                                )}
                            </span>
                        </React.Fragment>
                    ) : null}
                </span>
            )
        }

        const timelineDeliveryContentMsg = (
            <div className={'timeline__delivery__status ' + timelineDeliveryStatusColor()}>
                <span>
                    <ShippingTruckIcon />
                </span>
                {timelineDeliveryStatusLogic()}
            </div>
        )

        return (
            <div className="box-profile row">
                {/* Sidebar */}
                <div className="profile-container-box col-12 col-md-4 col-lg-3">
                    <ProfileContainer />
                </div>
                <div className="col-12 col-xl-9 order__shipping__details p-0">
                    {isLoading && <Spinner isLoading={isLoading} />}

                    <div ref={element => (this.orderScreenBody = element)} className="position-relative">
                        <header className="order__shipping__details__header">
                            <div className="order__shipping__details__header--goback">
                                <i class="fas fa-chevron-left"></i>
                                <LocalizedLink routeId="ROUTE_PROFILE" params={{ filter: 'shipping-history', open: '' }}>
                                    <span>
                                        <Translation id="return_shipment_history" defaultMessage="Return to shipment history" />
                                    </span>
                                </LocalizedLink>
                            </div>
                            <div className="order__shipping__details__header--title">
                                <div className="main-title">
                                    <h1>
                                        <Translation id="shipping_details" defaultMessage="Shipping details" />
                                        &nbsp;
                                        {deliveryId ? <span>{deliveryId}</span> : <span>--</span>}
                                    </h1>
                                </div>
                                {/* Desktop screen */}
                                <div className="d-none d-xl-block details-btn-group">
                                    <div className="desktop-screen">{headerButtonsGroup}</div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="order-divider-x"></div>
                            </div>

                            {timelineDeliveryContentMsg}

                            {delivery && delivery.timeline && (
                                <div>
                                    <Timeline delivery={delivery.timeline} orderStatusMap={deliveryStatusMap} withDates isHorizontal />
                                </div>
                            )}

                            {/* Responsive screen */}
                            <div className="d-block d-xl-none">
                                <div className="order-divider-x"></div>
                            </div>

                            <div className="d-block d-xl-none details-btn-group">
                                <div className="mobile-screen">{headerButtonsGroup}</div>
                            </div>
                        </header>

                        {/* Box resume */}
                        <h3 className="order__shipping__boxresume__title">
                            <Translation id="resume" defaultMessage="Resume" />
                        </h3>
                        <section className="order__shipping__details__boxresume">
                            <div className="order__shipping__details__boxresume--title">
                                <h4>
                                    <Translation id="shipment_number" defaultMessage="Shipment number" />
                                    :&nbsp;
                                    {deliveryId ? <span>{deliveryId}</span> : <span>--</span>}
                                </h4>
                            </div>
                            <div className="order-divider-x"></div>
                            <div className="order__shipping__details__boxresume--content shipping-view">
                                <div>
                                    {delivery && delivery.container_number && (
                                        <div>
                                            <span>
                                                <Translation id="container_number" defaultMessage="Container number" />
                                                :&nbsp;
                                            </span>
                                            <span>{delivery.container_number}</span>
                                        </div>
                                    )}
                                    {delivery && delivery.container_provider && (
                                        <div>
                                            <span>
                                                <Translation id="container_provider" defaultMessage="Container provider" />
                                                :&nbsp;
                                            </span>
                                            <span>{delivery.container_provider}</span>
                                        </div>
                                    )}
                                    <div>
                                        <span>
                                            <Translation id="delivery_address" defaultMessage="Delivery address" />
                                            :&nbsp;
                                        </span>
                                        <span>{this.displayShippingAddress()}</span>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <span>
                                            <Translation id="number_products_shipment" defaultMessage="Number of products in the shipment" />
                                            :&nbsp;
                                        </span>
                                        {delivery.order_lines ? <span>{Object.keys(delivery.order_lines).length}</span> : <span>--</span>}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <h3 className="order__shipping__boxresume__title">
                            <Translation id="shipment_contents" defaultMessage="Shipment contents" />
                        </h3>
                        <section className="order__shipping__details__table shipping-details">
                            <table className="order__shipping__details__table--table">
                                <tbody>
                                    {delivery &&
                                        delivery.order_lines &&
                                        delivery.order_lines.map((orderLine, idx) => {
                                            return (
                                                <React.Fragment>
                                                    <tr key={idx}>
                                                        <div className="order-details-divider-x"></div>
                                                        <td className="td-description">
                                                            {orderLine.cut_to_size && orderLine.cut_to_size.configurable ? (
                                                                this.descriptionBlockConfigBath(orderLine)
                                                            ) : orderLine.product ? (
                                                                this.descriptionBlock(orderLine)
                                                            ) : (
                                                                this.descriptionBlockException(orderLine)
                                                            )}
                                                        </td>
                                                        <td className="td-additional-info">{this.additionalInfoBlock(orderLine)}</td>
                                                        <td className="td-delivery-info">{this.orderInformationBlock(orderLine)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                        {this.state.showConfigBathModal && this.state.selectedOrderLine && (
                                            <ModalConfigurationDetails
                                                config={this.state.selectedOrderLine.cut_to_size && this.state.selectedOrderLine.cut_to_size.configurable}
                                                showBtnEdit={false}
                                                showBtnDownload={true}
                                                onClose={() => this.handleCloseConfigBathModal()}
                                            />
                                        )}
                                </tbody>
                            </table>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(NewShippingDetails)
