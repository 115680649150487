import React from 'react'
import { connect } from 'react-redux'
import Translation from '../../global/Translation'
import { getShowCheckboxAustraliaLegalProcess } from '../../../store/ui/reducers'
import { toggleAustraliaLegalProcessConditionsCheck } from '../../../store/checkout/actions'

class CheckboxAustraliaLegalProcessConditions extends React.Component {
    render() {
        const { showCheckboxAustraliaLegalProcess, toggleAustraliaLegalProcessConditionsCheck } = this.props
        
        if (!showCheckboxAustraliaLegalProcess) return null

        return(
            <label className="CheckboxLinkSaleConditions" data-qas="checkout_steptwo-checkbox-health-conditions-checkbox">
              <input
                name="health_conditions_check"
                type="checkbox"
                onClick={input => toggleAustraliaLegalProcessConditionsCheck(input.target.checked)} />
                {' '}
                <span className='checkmark' />
                <Translation id="australia_legal_process_conditions_check" defaultMessage="My acceptance of this order confirms that the products supplied by Cosentino Australia PTY are intended to fulfill a contract established before December 31, 2023." />
            </label>
        )
    }
}

const mapStateToProps = state => {
    return {
        showCheckboxAustraliaLegalProcess: getShowCheckboxAustraliaLegalProcess(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleAustraliaLegalProcessConditionsCheck: checked => {
            dispatch(toggleAustraliaLegalProcessConditionsCheck(checked))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckboxAustraliaLegalProcessConditions)