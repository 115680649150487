import { getIsSending } from '../../store/cut-to-size/reducers';
import React, { Component, forwardRef } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import GoBack from '../global/GoBack'
import Button from '../global/Button'
import Translation from '../global/Translation'
import { requestPreOrder } from '../../store/cut-to-size/actions'
import ElaborationDetails from './ElaborationDetails'
import {
    Messages,
    BudgetMessages
} from './CutToSizeMaps'
import FloatingAlert from '../alerts/FloatingAlert'
import { showToastNotification } from '../../store/toast-notifications/actions'
import DatePicker from "react-datepicker";
import {v4 as uuidv4} from "uuid";
import {getCutToSizeMaterials} from "../../store/cut-to-size/reducers";
import moment from "moment";
import LocalizedLink from "../global/LocalizedLink";

const FILE_LIMIT = 4 //MB
const BYTES_IN_MB = 1024 * 1024
const BLUEPRINT_FILE_TYPE = 'BX01'
const LABELS_FILE_TYPE = 'BX10'
const EMAIL_FILE_TYPE = 'BX03'


const mapStateToProps = state => {
    return {
        materials: getCutToSizeMaterials(state),
        isSending: getIsSending(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        requestPreOrder: (formData, callback) => dispatch(requestPreOrder(formData, callback)),
        showToastNotification: (text = '', type = 'none', duration = 10000) => dispatch(showToastNotification(text, type, duration)),
    }
}

export const ACCEPTED_EXT = {
    REQUIRED_BLUEPRINT:[
        'image/*',
        'application/pdf',
        '.dxf','.dwg'
    ],
    BLUEPRINT_FILE_TYPE:[
        'image/svg+xml',
        'image/jpeg',
        'image/png',
        'application/dxf',
        'application/pdf'
    ],
    LABELS_FILE_TYPE:[
        'image/jpeg',
        'image/png',
        'application/dxf',
        'application/pdf'
    ],
    EMAIL_FILE_TYPE:[
        'application/msword',
        'application/pdf',
        'application/octet-stream',
        'message/rfc822',
        'application/vnd.ms-outlook','msg','.msg'
    ]
}

class CutToSizeFormPreOrder extends Component {

    state = {
        currentProjectOptions: [],
        selectedProject: null,
        deadline: null,
        selectedFiles: {
            REQUIRED_BLUEPRINT:null,
            // BLUEPRINT_FILE_TYPE:null,
            LABELS_FILE_TYPE:null,
            EMAIL_FILE_TYPE:null
        },
        purchaseOrder: null,
        additionalFiles: {},
        showExceedFileSizeAlert: false,
        comments: null,
        showSelectFileType: false,
        preorderId: null,
        showCreatedModal: false
    }



    getFileOptions = () => {
        const fileOptions = []
        const addedFiles = this.state.additionalFiles

        // if (!addedFiles.BLUEPRINT_FILE_TYPE) {
        //     fileOptions.push(
        //         {
        //             label:<div>
        //                 <i className="far fa-pencil-ruler mx-2 w-25 text-center"/>{this.props.intl.formatMessage(BudgetMessages.BLUEPRINT_FILE_TYPE)}
        //             </div>,
        //             value:'BLUEPRINT_FILE_TYPE'
        //         }
        //     )
        // }
        if (!addedFiles.LABELS_FILE_TYPE) {
            fileOptions.push(
                {
                    label:<div>
                        <i className="far fa-tags mx-2 w-25 text-center"/>{this.props.intl.formatMessage(BudgetMessages.LABELS_FILE_TYPE)}
                    </div>,
                    value:'LABELS_FILE_TYPE'
                }
            )
        }
        if (!addedFiles.EMAIL_FILE_TYPE) {
            fileOptions.push(
                {
                    label:<div>
                        <i className="far fa-envelope mx-2 w-25 text-center"/>{this.props.intl.formatMessage(BudgetMessages.EMAIL_FILE_TYPE)}
                    </div>,
                    value:'EMAIL_FILE_TYPE'
                }
            )
        }
        return fileOptions
    }

    getMinDate = () => moment().add(3, 'days')

    getSelectedFiles = () => {
        const selectedFiles = {}
        selectedFiles[BLUEPRINT_FILE_TYPE] = this.state.selectedFiles.REQUIRED_BLUEPRINT
        // if (this.state.selectedFiles.BLUEPRINT_FILE_TYPE) selectedFiles[BLUEPRINT_FILE_TYPE] = this.state.selectedFiles.BLUEPRINT_FILE_TYPE
        if (this.state.selectedFiles.LABELS_FILE_TYPE) selectedFiles[LABELS_FILE_TYPE] = this.state.selectedFiles.LABELS_FILE_TYPE
        if (this.state.selectedFiles.EMAIL_FILE_TYPE) selectedFiles[EMAIL_FILE_TYPE] = this.state.selectedFiles.EMAIL_FILE_TYPE

        return selectedFiles
    }

    componentDidMount() {
        this.props.materials['BUDGET']['pre_order']['projects'].forEach(project => {
            this.state.currentProjectOptions.push({
                label: this.props.intl.formatMessage(BudgetMessages[project]),
                value: project
            })
        })

        this.forceUpdate()
    }

    disableSendButton = () => {
        return !this.state.selectedProject || !this.state.deadline || !this.state.comments || !this.state.selectedFiles.REQUIRED_BLUEPRINT || this.props.isSending
    }

    onFileChange = async (event, type) => {
        const file = event.target.files[0];

        if (file) {
            // if (!ACCEPTED_EXT[type].includes(file.type)) {
            //     event.target.value = null
            //     this.state.selectedFiles[type] = null
            // } else
            {
                const fileSizeInMB = file.size / BYTES_IN_MB
                if (fileSizeInMB > FILE_LIMIT) {
                    this.setState({showExceedFileSizeAlert: true})
                    event.target.value='';
                } else {
                    this.state.selectedFiles[type] = file
                }
            }
        } else {
            this.state.selectedFiles[type] = null
        }
        this.forceUpdate()
    }

    addAdditionalFile = (fileType) => {
        const key = uuidv4()
        this.state.additionalFiles[fileType] = true
        // this.forceUpdate()
    }

    delAdditionalFile = fileType => {
        // const fileType = this.state.additionalFiles[key]
        delete this.state.selectedFiles[fileType]
        delete this.state.additionalFiles[fileType]
        this.forceUpdate()
    }


    submitData = () => {
        const formData = new FormData()
        formData.append('project', this.state.selectedProject)
        formData.append('deadline', this.state.deadline)
        formData.append('comment', this.state.comments)
        formData.append('purchase_order', this.state.purchaseOrder)
        const files = this.getSelectedFiles()
        const fileKeys = []
        Object.entries(files).forEach(([type, file]) => {
            const key = `cts_preorder_file_${type}_0`
            fileKeys.push(key)
            formData.append(key, file)
        })
        formData.append('file_keys', fileKeys)

        this.props.requestPreOrder(formData, this.showNotification)
    }

    showNotification = (response) => {
        this.setState({showCreatedModal: true, preorderId: response.preorderId})
    }

    render() {
        const { selectedProject, currentProjectOptions, additionalFiles } = this.state
        const { intl, selectedElaboration } = this.props
        if (!selectedElaboration) return null

        const fileOptions = this.getFileOptions()
        const DesiredDatePicker = forwardRef(({ value, onClick }, ref) => {
            return (
                <div onClick={onClick} className="form-control d-flex justify-content-between align-items-center"
                     style={{cursor: 'pointer'}}
                >
                    {
                        !!value ? (
                            <React.Fragment>
                                {value}
                                <i className="far fa-calendar-check" style={{fontSize: "1.4em"}}/>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="form-control-placeholder">
                                    {intl.formatMessage(Messages.SET_DEADLINE)}
                                </div>
                                <i className="far fa-calendar" style={{fontSize: "1.4em"}}/>
                            </React.Fragment>
                        )
                    }
                </div>
            )
        })

        return (
            <section className="cut_to_size_budget">
                <div className="cut_to_size__go_back mt-4 mb-3">
                    <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} header={true} queryString={''} params={{'outlet':''}}>
                        <GoBack
                            text={intl.formatMessage(Messages.BACK_SELECT_ELABORATED_TYPE)}
                            dataCY={'product_detail__go_back'}
                        />
                    </LocalizedLink>
                </div>

                <div className="container form" >
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <div style={{ width: '100%' }}>
                                <ElaborationDetails
                                    id={selectedElaboration}                         
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-5">

                            {/**************** SELECT PROJECT **********************/}
                            <div className="group__input">
                                <label htmlFor="project-select" className="form-label">
                                    <Translation id="project-type" defaultMessage="Project type" />*
                                </label>
                                <div className="contain__select--project">
                                    <Select
                                        id="project-select"
                                        clearable={false}
                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_PROJECT_TYPE)}
                                        options={currentProjectOptions}
                                        value={selectedProject}
                                        onChange={selectedOption => this.setState({selectedProject: selectedOption.value})}
                                    />
                                </div>
                            </div>

                            {/**************** DEADLINE **********************/}
                            <div className="group__input">
                                <label htmlFor="input-deadline" className="form-label">
                                    <Translation id="deadline" defaultMessage="Preferred delivery date" />*
                                </label>
                                <div className="contain__set-deadline">
                                    <DatePicker
                                        id="input-deadline"
                                        selected={this.state.deadline}
                                        onChange={date => this.setState({deadline: date})}
                                        // minDate={this.props.selectedCenter == 1100 && !this.slaDate ? getNextWorkingDay(this.props.minDeliveryDate) :  getNextWorkingDay(1)}
                                        autoComplete="off"
                                        minDate={this.getMinDate()}
                                        onKeyDown={e => e.preventDefault()}
                                        dropdownMode="select"
                                        customInput={<DesiredDatePicker />}
                                    />
                                </div>
                            </div>

                            {/**************** PURCHASE ORDER **********************/}
                            <div className="group__input">
                                <label htmlFor="input-po" className="form-label">
                                    <Translation id="purchase-order" defaultMessage="Your reference" />
                                </label>
                                <div className="contain__input-po">
                                    <input
                                        type="text"
                                        id="input-po"
                                        placeholder={this.props.intl.formatMessage({id: 'purchase-order'})}
                                        value={this.state.purchaseOrder}
                                        onChange={e => this.setState({purchaseOrder: e.target.value})}
                                        className={`form-control`}
                                        maxLength="132"

                                    />
                                </div>
                            </div>


                            {/**************** ADJUNTAR DOCUMENTO **********************/}
                            <div className="colgroup__input">
                                <label htmlFor="attach_packaging_document_file" className="form-label">
                                    <Translation id="attached_documents" defaultMessage="Attached documents" />
                                </label>
                                <div className="row align-items-center mb-2">
                                    <div className="col-3 form-label" style={{fontSize:'90%'}}>
                                        <Translation id="blueprint" defaultMessage="Blueprint"/>*
                                    </div>
                                    <div className="col-9">
                                        <div className="attach-file">
                                            <input
                                                id="attach_packaging_document_file"
                                                type="file"
                                                accept={ACCEPTED_EXT['REQUIRED_BLUEPRINT'].join()}
                                                onChange={e => this.onFileChange(e, 'REQUIRED_BLUEPRINT')}
                                                // accept="image/jpeg, image/png, application/pdf"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {
                                    Object.keys(additionalFiles).map(fileType => {
                                        const key = 'ad_f_' + fileType
                                        return (
                                            <div key={key} className="row align-items-center mb-2">
                                                <div className="col-3 form-label" style={{fontSize:'90%'}}>
                                                    {intl.formatMessage(BudgetMessages[fileType])}
                                                </div>
                                                <div className="col-7 pr-0">
                                                    <div className="attach-file">
                                                        <input
                                                            id="attach_packaging_document_file"
                                                            type="file"
                                                            accept={ACCEPTED_EXT[fileType].join()}
                                                            onChange={e => this.onFileChange(e, fileType)}
                                                            // accept="image/jpeg, image/png, application/pdf"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="attach-file justify-content-center"
                                                         style={{fontSize:'16px', cursor:'pointer'}}
                                                         onClick={() => this.delAdditionalFile(fileType)}
                                                    >
                                                        <i className="far fa-trash"/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                                {/*<div className="field-info">*/}
                                {/*    <Translation id="attached_document_info" defaultMessage={"Add here the information you want to include within the packaging. May involve expenses and associated costs."} />*/}
                                {/*</div>*/}


                                {/**************** ADD ADDITIONAL FILE **********************/}
                                {fileOptions.length > 0 && (
                                    <div className="row">
                                        <div className="col-6 group__input mb-0">
                                            <div  style={{fontWeight:500, cursor:'pointer'}}
                                                  onClick={() => this.setState({showSelectFileType: true})}
                                            >
                                                <i className="fa fa-plus" style={{fontWeight:500}}/>
                                                &nbsp;&nbsp;
                                                <Translation id="add-additional-file" defaultMessage="Add additional file"/>
                                            </div>
                                        </div>
                                        { this.state.showSelectFileType && (
                                            <div className="col-6 group__input">
                                                <div className="contain__select--project">
                                                    <Select
                                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_FILE_TYPE)}
                                                        options={fileOptions}
                                                        onChange={selectedOption => {
                                                            this.addAdditionalFile(selectedOption.value)
                                                            this.setState({showSelectFileType: false})
                                                        }}
                                                        onBlur={() => this.setState({showSelectFileType: false})}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className="field-info mb-1">
                                    <Translation style={{all:"revert"}} id="exceed_file_limit" defaultMessage={`The file has a limit of {fileLimit} MB`} values={{ fileLimit: FILE_LIMIT }} />
                                </div>
                            </div>


                            {/**************** COMENTARIOS **********************/}
                            <div className="group__input">
                                <label htmlFor="input-comments" className="form-label">
                                    <Translation id="comment" defaultMessage="Comments" />*
                                </label>
                                <textarea
                                    className="form-control"
                                    id="input-comments"
                                    rows="3"
                                    maxLength="255"
                                    onChange={e => {
                                        e && this.setState({ comments: e.target.value })
                                    }}
                                />
                            </div>
                            <div>
                                <span className="field-info"><strong><Translation id="mandatory_field_meaning" defaultMessage="* Means mandatory field" /></strong></span>
                            </div>

                            {/**************** SOLICITAR PRESUPUESTO **********************/}
                            <div className="mt-1">
                                <Button
                                    customClass="bt-dark gt"
                                    dataCY="product_detail__add"
                                    disabled={this.disableSendButton()}
                                    onClick={() => (this.submitData())}
                                >
                                    <Translation id="request_for_preorder" defaultMessage="Request an order/offer" />
                                </Button>
                            </div>
                        </div>{' '}
                  
                        {this.state.showExceedFileSizeAlert && (
                            <FloatingAlert
                                warningMessage={<Translation id="exceed_file_limit" defaultMessage={`The file has a limit of {fileLimit} MB`} values={{ fileLimit: FILE_LIMIT }} />}
                                onClose={() => {
                                    this.setState({ showExceedFileSizeAlert: false })
                                }}
                            />
                        )}

                        {
                            this.state.showCreatedModal && (
                                <div className="preorder_created_modal" onClick={() => this.setState({showCreatedModal: false})}>
                                    <div className="modal-content" onClick={(e) => {e.stopPropagation()}}>

                                        <div className="success mb-2">
                                            <i className="far fa-check fa-3x"/>
                                        </div>

                                        <div className="success-text big">
                                            <Translation id="success!" defaultMessage="Success!"/>
                                        </div>

                                        <div className="success-text big">
                                            <Translation id="preorder-sent" defaultMessage="Your Order request has been sent."/>
                                        </div>

                                        <div className="success-text big">
                                            <Translation id="preorder-created" defaultMessage="A new Order request has been created."/>
                                        </div>

                                        <div className="mt-2 mb-4">
                                            <LocalizedLink routeId="ROUTE_PROFILE" params={{ filter: 'pre-orders-cts-details', open: this.state.preorderId }}>
                                                <Button customClass="bt-dark gt xt-pd" onClick={(e) => e.stopPropagation()}>
                                                    <Translation id="check-preorder" defaultMessage="Check your Pre-order status"/>
                                                </Button>
                                            </LocalizedLink>
                                        </div>

                                        <div className="success-text small mt-1">
                                            <Translation id="check-preorder-advise" defaultMessage="You can check the status of Pre-order at any time on your personal area"/>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CutToSizeFormPreOrder)
)
