import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from '../global/Button'

class MktAdminColors extends React.Component {
    constructor(props) {
        super(props)
        this.state = {selectedBrand: null}
        this.inputs = []
        this.listGroupColors = []
    }

    componentDidMount() {
        this.props.fetchColorsMktoolsAttr()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedBrand === null && this.props.brands && this.props.brands.length > 0) {
            this.setState({selectedBrand: this.props.brands[0]})
        }
    }

    navMenuColors() {
        return (
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    {this.props.brands.map((brand, i) => {
                        return (
                            <a
                                // class={`nav-item nav-link ${i === 0 ? 'active' : ''}`}
                                class={`nav-item nav-link ${this.state.selectedBrand === brand ? 'active' : ''}`}
                                id={`nav-${brand.replace(' ', '-')}-tab`}
                                data-toggle="tab"
                                // href={`#nav-${brand.replace(' ', '-')}`}
                                role="tab"
                                aria-controls={`nav-${brand.replace(' ', '-')}`}
                                onClick={() => this.setState({selectedBrand: brand})}
                                // aria-selected={i === 0 ? 'true' : 'false'}
                                aria-selected={this.state.selectedBrand === brand ? 'true' : 'false'}
                                style={{cursor: "pointer"}}
                            >
                                {brand}
                            </a>
                        )
                    })}
                </div>
            </nav>
        )
    }
    contentColors() {
        return (
            <div class="tab-content" id="nav-tabContent">
                {this.props.brands.map((brand, i) => {
                    return (
                        <div
                            class={`tab-pane fade ${this.state.selectedBrand === brand ? 'show active' : ''}`}
                            id={`nav-${brand.replace(' ', '-')}`}
                            role="tabpanel"
                            aria-labelledby={`nav-${brand.replace(' ', '-')}-tab`}
                        >
                            <div className="row">
                                <div className="col-12 col-md-8" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="row align-items-end">
                                        <div className="col-10">
                                            <div className="form-group">
                                                <label htmlFor="input-new-color">
                                                    <Translation id="add_new_color" defaultMessage="Add new color" />
                                                </label>
                                                <input ref={node => (this.inputs[brand] = node)} type="text" className="form-control" id="input-new-color" />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group">
                                                <Button onClick={() => this.addNewColorMktoolsAttr(this.inputs[brand], brand)}>>></Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ flexGrow: 1 }}>
                                        <div className="col-12 col-md-6">
                                            {this.props.colorsToAdd[brand] && !!this.props.colorsToAdd[brand].length && (
                                                <div className="card h-100">
                                                    <div className="card-body">
                                                        <h5 style={{ borderBottom: '1px solid #ddd' }}>
                                                            <Translation id="colors_will_be_added" defaultMessage="Colors will be added" />
                                                        </h5>
                                                        {this.props.colorsToAdd[brand].map(color => {
                                                            return (
                                                                <span
                                                                    class="badge badge-pill badge-light"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => this.scrollListGroupColors(this.props.getIndexOfColorsMktoolsAtter(color, brand), brand)}
                                                                >
                                                                    {color}{' '}
                                                                </span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {this.props.colorsToDel[brand] && !!this.props.colorsToDel[brand].length && (
                                                <div className="card h-100">
                                                    <div className="card-body">
                                                        <h5 style={{ borderBottom: '1px solid #ddd' }}>
                                                            <Translation id="colors_will_be_removed" defaultMessage="Colors will be removed" />
                                                        </h5>
                                                        {this.props.colorsToDel[brand].map(color => {
                                                            return (
                                                                <span
                                                                    class="badge badge-pill badge-light"
                                                                    style={{ cursor: 'poineter' }}
                                                                    onClick={() => (this.inputs[brand].value = color)}
                                                                >
                                                                    {color}{' '}
                                                                </span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <ul ref={node => (this.listGroupColors[brand] = node)} class="list-group" style={{ overflowY: 'scroll', maxHeight: '18.45em' }}>
                                        {this.props.colors[brand] &&
                                            this.props.colors[brand].map((color, i) => (
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    {color}
                                                    <button type="button" class="close" aria-label="Del" onClick={() => this.props.removeColorMktoolsAttr(i, brand)}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    addNewColorMktoolsAttr(input, brand) {
        let value = input.value
        input.value = ''
        this.scrollListGroupColors(this.props.addNewColorMktoolsAttr(value, brand), brand)
    }
    scrollListGroupColors(position, brand) {
        this.listGroupColors[brand].scrollTo({
            top: position * 49,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <div className="container">
                <div className="wrapper_admin_colors my-4">
                    <div className="row">
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h2>
                                <Translation id="admin_colors" defaultMessage="Admin colors" />
                            </h2>
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <Button customClass="mr-2" onClick={() => this.props.fetchColorsMktoolsAttr()}>
                                        <Translation id="cancel" defaultMessage="Cancel" />
                                    </Button>
                                    <Button onClick={() => this.props.saveColorsMktoolsAttr()}>
                                        <Translation id="save" defaultMessage="Save" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {this.navMenuColors()}
                            <div className="border border-top-0 rounded-bottom p-4">{this.contentColors()}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(MktAdminColors)
