import { connect } from 'react-redux'
import OldLogin from './OldLogin'
import {
    getCallbackCenter,
    getIsLogging,
    getLoginCallback,
    mandatoryChangePassword,
    showModalSelector,
    getIsLogged,
    getAlert,
    getCallbackSalesOrg
} from '../../store/login/reducers'
import { createAccount, login, mandatoryChangePasswordRequest, recoverPassword } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'
import { getEmployeeId } from '../../store/big-account/reducers'

const mapStateToProps = state => {
    return {
        alert: getAlert(state),
        callback: getLoginCallback(state),
        callbackCenter: getCallbackCenter(state),
        callbackSalesOrg: getCallbackSalesOrg(state),
        isLogging: getIsLogging(state),
        isLogged: getIsLogged(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (username, password, callback, callbackCenter, intl, ssoData, callbackSalesOrg) => {
            dispatch(login(username, password, callback, callbackCenter, intl, ssoData, callbackSalesOrg))
        },
    }
}

const OldLoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OldLogin)

export default OldLoginContainer
