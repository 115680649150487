import React from 'react'
import LotComponent from "./LotComponent";

const LotsGrid = (props) => {

    const lots = props.lots ? props.lots.slice(props.selectedIndex * props.pageSize, props.selectedIndex * props.pageSize + props.pageSize) : []

    return(
        <div className="lots__grid__container">
            {
                lots && lots.map(l => {
                    const key = l.bundleId ? l.bundleId : l.lotId
                    return (
                        <LotComponent
                            {...props}
                            lot={l}
                            defaultImage={props.original.img}
                            product={props.original}
                            isGrid={true}
                            key={key}

                            // centerId={props.center}
                            // lotImages={props.lotsImages}
                            // isAdmin={props.isAdmin}
                            // isUsaOrCanada={props.isUsaOrCanada}
                            // selectedProduct={props.selectedProduct}
                        />
                    )
                })
            }
        </div>
    )

}

export default LotsGrid
