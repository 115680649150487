import { PORTFOLIO_MODE_SHOW_ONLY_CODI } from '../../components/landing-manager/utils'

export const initState = {
    markets: [],
    productsLanding: [],
    productsLots: {},
    userLandings: [],
    landingBySlug: null,
    isLoadingLanding: false,
    isLoadingLots: false,
    currentPorfolioMode: PORTFOLIO_MODE_SHOW_ONLY_CODI,
    mallLots: null,
    selectedProductId: null,
    isLoadingProductsAndLots: false
}

const landing = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_MARKETS_SUCCESS':
            return { ...state, markets: action.markets }
        case 'FETCH_COUNTRIES_SUCCESS':
            return { ...state, countries: action.countries }
        case 'FETCH_CENTER_SUCCESS':
            return { ...state, centers: action.centers }
        case 'RESET_COUNTRIES_CENTERS':
            return { ...state, countries: action.countries, centers: action.centers }
        case 'FETCH_LANGS_SUCCESS':
            return { ...state, langs: action.langs }
        case 'FETCH_TEMPLATE_SUCCESS':
            return { ...state, templates: action.templates }
        case 'FETCH_LANDINGS_SUCCESS':
            return { ...state, landings: action.landings }
        case 'FETCH_USER_LANDINGS_SUCCESS':
            return { ...state, userLandings: action.landings }
        case 'FETCH_LANDINGSQUERY_SUCCESS':
            return { ...state, landings: action.landings }
        case 'FETCH_LANDING_SUCCESS':
            return { ...state, landing: action.landing }
        case 'FETCH_LANDINGID_SUCCESS':
            return { ...state, landing: action.landing }
        case 'FETCH_PRODUCTS_LANDINGS_REQUEST':
            return { ...state, isLoadingProductsAndLots: true }
        case 'FETCH_PRODUCTS_LANDINGS_FAILURE':
            return { ...state, isLoadingProductsAndLots: false }
        case 'FETCH_PRODUCTS_LANDINGS_SUCCESS':
            return {
                ...state,
                productsLanding: action.productsLanding,
                productsLots: action.productsLots,
                isLoadingProductsAndLots: false
            }
        case 'CREATE_LANDINGID_SUCCESS':
            return { ...state, create: action.create, error: undefined }
        case 'UPDATE_LANDINGID_SUCCESS':
            return { ...state, update: action.update, error: undefined }
        case 'FETCH_LANDINGS_SLUG_SUCCESS':
            return { ...state, slug: action.slug }
        case 'FETCH_LANDINGS_BY_SLUG_REQUEST':
        case 'FETCH_PREVIEW_LANDINGS_BY_SLUG_REQUEST':
            return { ...state, landingBySlug: [],isLoadingLanding: true }
        case 'FETCH_LANDINGS_BY_SLUG_SUCCESS':
        case 'FETCH_PREVIEW_LANDINGS_BY_SLUG_SUCCESS':
            return { ...state, landingBySlug: action.landing,isLoadingLanding: false }
        case 'FETCH_LANDINGS_BY_SLUG_FAILURE':
        case 'FETCH_PREVIEW_LANDINGS_BY_SLUG_FAILURE':
                return {...state, isLoadingLanding: false}
        case 'DELETE_LANDING_SUCCESS':
            return { ...state }
        case 'DELETE_LANDING_IMAGE_SUCCESS':
            return { ...state }
        case 'UPDATE_LANDINGID_FAILURE':
        case 'CREATE_LANDINGID_FAILURE':
            return { ...state, error: action.alert }
        case 'SET_PORTFOLIO_MODE':
            return { ...state, currentPorfolioMode: action.portfolioMode}
        case 'LOGOUT':
            return { ...initState }
        case 'FETCH_MALL_LOTS_REQUEST':
            return { ...state, mallLots: null, isLoadingLots: true }
        case 'FETCH_MALL_LOTS_FAILURE':
            return { ...state, isLoadingLots: false }
        case 'FETCH_MALL_LOTS_SUCCESS':
            return { ...state, mallLots: action.lots, isLoadingLots: false }
        case 'SET_SELECTED_MALL_PRODUCT':
            return { ...state, selectedProductId: action.productId }
        default:
            return state
    }
}

export const getUserLandings = state => {
    return state.landing ? (state.landing.userLandings ? state.landing.userLandings : initState.userLandings): null
}
export const getIsLoadingLanding = state => {
    return state.landing.isLoadingLanding
}

export const getLandingBySlug = state => {
    return state.landing.landingBySlug
}

export const getPreviewPorfolioMode = state => {
    return state.landing.currentPorfolioMode
}

export const getMallLots = state => {
    return state.landing.mallLots
}

export const getMallProductsLots = state => {
    return state.landing.productsLots
}

export const getIsLoadingLots = state => {
    return state.landing.isLoadingLots
}

export const getSelectedProductId = state =>  {
    return state.landing.selectedProductId
}

export const getProductMinStock = (state, productId) => {
    const landing = state.landing.userLandings && state.landing.userLandings[0]
    if (landing) {
        const product = landing.products.find(p => p.product_id = productId)
        if (product) return !!product.minimum_order ? product.minimum_order : 0
    }
    return 0
}

export const getSelectedProduct = (state) => {
    if (state.landing.selectedProductId) {
        const landing = state.landing.userLandings && state.landing.userLandings[0]
        if (landing) {
            return landing.products.find(p => p.id = state.landing.selectedProductId)
        }
    }

    return null
}

export const getLandingByCommonId = (state, lastSelectedProduct) => {
    if(!lastSelectedProduct || !lastSelectedProduct.landingId){
        return null
    }
    return state.landing.userLandings.find(l => l.common_id === lastSelectedProduct.landingId)
}

// export const getProductsLanding = state => {
//     return state.landing.
// }

export default landing
