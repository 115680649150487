import React, { Component } from 'react'
import {connect} from "react-redux";
import LocalizedLink from '../../global/LocalizedLink'
import Translation from '../../global/Translation'
import ConfigBathCard from './ConfigBathCard'
import CatalogFilter from './CatalogFilters'
import { getConfigurableModels } from '../../../store/cut-to-size/reducers'

class ConfigurableBathsCatalog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOptionType: null,
            selectedOptionModel: null,
            selectedOptionBrand: null,
            selectedOptionColor: null
        }
    }

    handleChangeType = selectedOption => {
        this.setState({ selectedOptionType: selectedOption })
    }

    handleChangeModel = selectedOption => {
        this.setState({ selectedOptionModel: selectedOption })
    }

    handleChangeBrand = selectedOption => {
        this.setState({ selectedOptionBrand: selectedOption })
    }

    handleChangeColor = selectedOption => {
        this.setState({ selectedOptionColor: selectedOption })
    }

    getFilteredModelsAndColors() {
        const { configurableModels } = this.props
        const models = configurableModels ? configurableModels.models : []

        let filteredModels = models

        if (this.state.selectedOptionModel) {
            filteredModels = filteredModels.filter(row => row.model === this.state.selectedOptionModel.value)
        }
        
        if (this.state.selectedOptionType) {
            filteredModels = filteredModels.filter(row => row.type === this.state.selectedOptionType.value)
        }

        if (this.state.selectedOptionBrand) {
            filteredModels = filteredModels.filter(row => row.brands.includes(this.state.selectedOptionBrand.value))
        }

        const availableColors = [...new Set(filteredModels && filteredModels.flatMap(row => row.colors))]

        return { filteredModels, availableColors }
    }
    
    render() {
        const { filteredModels, availableColors } = this.getFilteredModelsAndColors()

        return (
            <div className="catalog__configurable__baths">
                {/* Go back btn */}
                <div className="catalog__configurable__baths--goback">
                    <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} queryString={''} params={{ outlet: '' }}>
                        <i className="fas fa-chevron-left" />
                        <span>
                            <Translation id="back_select_elaborated_type" defaultMessage="Back to elaborated type selection" />
                        </span>
                    </LocalizedLink>
                </div>

                {/* Page title */}
                <div className="catalog__configurable__baths--page-title">
                    <h1><Translation id="washbasing_and_shower_trays" defaultMessage="Washbasins and shower trays" /></h1>
                </div>

                {/* Filters */}
                <div className="catalog__configurable__baths--filters">
                    <div className="filter-by">
                        <span><Translation id="filter_by" defaultMessage="Filter by" />:</span>
                    </div>
                    <div>
                    <CatalogFilter
                            configurableModels={filteredModels}
                            uniqueColors={availableColors}
                            handleChangeType={this.handleChangeType}
                            handleChangeModel={this.handleChangeModel}
                            handleChangeBrand={this.handleChangeBrand}
                            handleChangeColor={this.handleChangeColor}
                            selectedOptionType={this.state.selectedOptionType}
                            selectedOptionModel={this.state.selectedOptionModel}
                            selectedOptionBrand={this.state.selectedOptionBrand}
                            selectedOptionColor={this.state.selectedOptionColor}
                        />
                    </div>
                </div>

                {/* Materials */}
                <div className="catalog__configurable__baths--materials">
                    {filteredModels &&
                        filteredModels.map(item => {
                            return <ConfigBathCard data={item} />
                        })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        configurableModels: getConfigurableModels(state)
    }
}

export default connect(
        mapStateToProps,
        null
    )(ConfigurableBathsCatalog)
