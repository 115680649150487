import * as api from '../api'

export const fetchOrderDeliveriesById = (deliveryId) => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({type: 'FETCH_ORDER_DELIVERY_ID_REQUEST'})

    api.fetchOrderDeliveriesById(token, deliveryId).then(
        response => dispatch({
            type: 'FETCH_ORDER_DELIVERY_ID_SUCCESS',
            delivery: response.data.data
        }),
        error => dispatch({
            type: 'FETCH_ORDER_DELIVERY_ID_FAILURE',
            statusText: error.response.data.err
        })
    )
}