import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from './Button'
import Modal from './Modal'
import { connect } from 'react-redux'
import { toggleIsObsolete } from '../../store/ui/actions'

const mapDispatchToProps = dispatch => {
    return {
        toggleIsObsolete: () => {
            dispatch(toggleIsObsolete())
        }
    }
}
class AppObsolete extends React.Component {
    hardRefresh = () => {
        this.props.toggleIsObsolete()

        window.location.reload(true)
    }
    render() {
        return (
            <div className="container">
                <Modal
                    title={<Translation id="please_reload" defaultMessage="Please Reload" />}
                    footer={
                        <Button onClick={() => this.hardRefresh()} inverted size="medium">
                            <Translation id="reload" defaultMessage="Reload" />
                        </Button>
                    }
                    onClose={() => this.hardRefresh()}
                >
                    <div className="container">
                        <div className="col-12">
                            <span>
                                <Translation
                                    id="we_think_you_may_be_using_an_old_version_of_the_web"
                                    defaultMessage="You may be using an old version of e.Cosentino, please reload in order to continue."
                                />
                            </span>
                            <br />
                            <span>
                                <Translation id="if_problem_persist_please_contact_us" defaultMessage="If problem persist please contact us." />
                            </span>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(AppObsolete)
)
