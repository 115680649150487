import React from 'react'
import Translation from '../../global/Translation'

import iconoTemporalAcabado from '../../../assets/img/acabado.png'
import ProductDisplayName from '../../global/ProductDisplayName'
import LengthMeasureTranslation from '../../global/LengthMeasureTranslation'
import LengthMeasure from '../../global/LengthMeasure'

//Displays
import DisplayProductInfo from './displays/DisplayProductInfo'
import DisplayBrand from './displays/DisplayBrand'
import DisplayProductInfoTable from './displays/DisplayProductInfoTable'
import DisplayThickness from './displays/DisplayThickness'
import DisplayGroupDtails from './displays/DisplayGroupDtails'
import DisplayDetail from './displays/DisplayDetail'
import DisplayPriceMktools from './displays/DisplayPriceMktools'

export default class DetailsMktools extends React.Component {
    isSample() {
        return this.props.product.typeId === '01' || this.props.product.typeId === 'NEWS'
    }
    getUnitBy() {
        if (this.props.product.isMktool && this.props.product.unitsByLot) return this.props.product.unitsByLot
        else if (this.props.product.isMktool && this.props.product.unitsByBox) return this.props.product.unitsByBox
        else if (!this.props.product.isMktool && !this.props.product.unitsByLot && !this.props.product.unitsByBox) return <LengthMeasure product={this.props.product} />
    }

    render() {
        const available = this.props.product.type === 'TABLA' ? <Translation id="tables" defaultMessage="Slabs" /> : <Translation id="available" defaultMessage="Available" />
        let quantity
        if (this.props.product.type === 'TABLA') {
            quantity = (
                <div>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else if (this.props.product.isMktool) {
            if (this.isSample()) {
                quantity = (
                    <div>
                        <span>
                            <Translation defaultMessage="u/box" id="u/box" />
                        </span>
                    </div>
                )
            } else {
                quantity = (
                    <div>
                        {this.getUnitBy() && (
                            <span>
                                <Translation defaultMessage="u/lot" id="u/lot" />
                            </span>
                        )}
                    </div>
                )
            }
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }

        const watchingOutlet = this.props.outlet ? true : false
        const stock = watchingOutlet ? this.props.product.stock_outlet : this.props.product.stock

        let thData = []
        thData.push({ value: available })
        if (this.getUnitBy()) thData.push({ datacy: 'product_rectangle_result__surface', value: quantity })
        thData.push({ value: this.props.intl.formatMessage({ id: 'material_id' }) })

        let tdData = []
        tdData.push({ value: stock })
        if (this.getUnitBy()) tdData.push({ datacy: 'product_rectangle_result__surface_value', value: this.getUnitBy() })
        tdData.push({ value: this.props.product.productId })

        return (
            <DisplayProductInfo>
                <DisplayBrand brand={this.props.product.brand} />
                <div className="product-color-name">
                    <ProductDisplayName product={this.props.product} />
                </div>

                <DisplayProductInfoTable th={thData} td={tdData} />

                <DisplayGroupDtails>
                    <DisplayThickness thickness={this.props.product.thickness} />
                    <DisplayDetail icon={iconoTemporalAcabado} value={this.props.product.finish} />
                </DisplayGroupDtails>
                <DisplayGroupDtails>
                    {this.props.product && !this.isSample() && this.props.product.channelName && (
                        <DisplayDetail class="channelName" value={this.props.product.channelName.toLowerCase()} />
                    )}
                </DisplayGroupDtails>
                <DisplayGroupDtails>
                    {this.props.isMkToolsCenter && this.props.product && (
                        <div className="finish price">
                            <div className="text">
                                <DisplayPriceMktools prices={this.props.product.pricesMktools} group={this.props.priceGroup} />
                            </div>
                        </div>
                    )}
                </DisplayGroupDtails>
            </DisplayProductInfo>
        )
    }
}
