import React from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import Modal from '../../global/Modal'
import ReactTable from 'react-table'
import Spinner from '../../global/Spinner'
import Button from '../../global/Button'
import Alerts from '../../alerts/Alerts'

class ChooseCustomers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCustomers: this.props.customersSelected,
            showModalToSelect: false,
            search: '',
            searchOnModal: '',
            //Unsaved changes
            hasUnsavedChanges: {
                promotionCustomersDirty: false
            }
        }
    }

    handleSubmit = e => {
        if (e) e.preventDefault()
            //No more unsaved changes after send the form for step three
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCustomersDirty: false }}, () => {
                this.props.sendCustomersSelected(this.state.selectedCustomers)
                //Update global state after form submit
                this.props.setUnsavedChangesStepThree(this.state.hasUnsavedChanges)

                //Make sure the change step is the last function
                //Only if event exists we use this change step. If not we use the one inside StepsBar component
                if (e) {
                    this.props.changeStep(this.props.step + 1)
                }
            })
        
    }

    componentDidMount = () => {
        this.props.handleReference(this)
        this.props.fetchCustomersOfArea(this.props.market, this.props.countries, this.props.centers)
    }

    /**
     * Handle the state value customers unsaved changes.
     */
    handleCustomersUnsavedChanges = () => {
        const stateCustomers = this.state.selectedCustomers
        const propsCustomers = this.props.customersSelected

        if ((propsCustomers && JSON.stringify(propsCustomers).trim()) !== (stateCustomers && JSON.stringify(stateCustomers).trim())) {
            this.setState({hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCustomersDirty: true}}, () => {
                //Update global state
                this.props.setUnsavedChangesStepThree(this.state.hasUnsavedChanges)
            })
        } else if ((propsCustomers && JSON.stringify(propsCustomers).trim()) === (stateCustomers && JSON.stringify(stateCustomers).trim())) {
            this.setState({hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCustomersDirty: false}}, () => {
                //Update global state
                this.props.setUnsavedChangesStepThree(this.state.hasUnsavedChanges)
            })
        }
    }

    selectCustomer = customerId => {
        if (this.state.selectedCustomers.includes(customerId)) {
            this.setState({ selectedCustomers: this.state.selectedCustomers.filter(customer => customer !== customerId) }, () => {
                this.handleCustomersUnsavedChanges()
            })
        } else {
            this.setState({ selectedCustomers: this.state.selectedCustomers.concat(customerId) }, () => {
                this.handleCustomersUnsavedChanges()
            })
        }
    }

    render() {
        //search on users modal
        let usersFiltered = null
        if (this.state.searchOnModal) {
            usersFiltered = this.props.customersOfArea
            usersFiltered = usersFiltered.filter(row => {
                return row.login.toLowerCase().includes(this.state.searchOnModal.toLowerCase()) || row.name.toLowerCase().includes(this.state.searchOnModal.toLowerCase())
            })
        }
        const users = usersFiltered !== null ? usersFiltered : this.props.customersOfArea

        //search on users selected
        let usersSelectedFiltered = this.props.customersOfArea.filter(row => this.state.selectedCustomers.includes(row.login))
        if (this.state.search) {
            usersSelectedFiltered = usersSelectedFiltered.filter(row => {
                return (
                    row.login.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    row.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    row.market.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    this.props.intl
                        .formatMessage({ id: 'country_' + row.country })
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase())
                )
            })
        }
        const usersSelected = usersSelectedFiltered !== null ? usersSelectedFiltered : this.props.usersSelected
        if (!this.props.market || !this.props.countries || !this.props.centers) {
            return <Alerts alert={'NEED_TO_COMPLETE_STEP_2'} status="warning" />
        }
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-lg-10">
                        <h3 style={{ fontWeight: '400' }}>
                            <span style={{ fontWeight: '300' }}>
                                <Translation id="step" defaultMessage={'Step'} /> {this.props.step}
                            </span>{' '}
                            <Translation id="promotion_line_customer" defaultMessage="Customer" />
                        </h3>
                        <p>
                            <Translation id="select_the_customer_to_apply_your_campaign" defaultMessage="Select the customer to apply your campaign" />
                        </p>
                    </div>
                    <div className="col-12 col-lg-2">
                        <Button
                            gentle
                            onClick={() => {
                                this.setState({ showModalToSelect: true })
                            }}
                        >
                            <Translation id="add" defaultMessage="Add" />
                        </Button>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-lg 8">
                            <div className="search-bar">
                                <input
                                    type="text"
                                    data-qa="query"
                                    className="search-box form-control"
                                    placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                    data-cy="Impersonate_modal__search_box"
                                    value={this.state.search}
                                    onChange={e =>
                                        this.setState({
                                            search: e.target.value
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <ReactTable
                                data={usersSelected}
                                loading={this.props.isSending}
                                loadingText={<Spinner isLoading={this.props.isSending} />}
                                columns={[
                                    {
                                        Header: <Translation id="customer_id" defaultMessage="Customer id" />,
                                        accessor: 'login',
                                        maxWidth: 80
                                    },
                                    {
                                        Header: <Translation id="customer_name" defaultMessage="Customer name" />,
                                        accessor: 'name'
                                    },
                                    {
                                        Header: <Translation id="market" defaultMessage="Market" />,
                                        accessor: 'market',
                                        maxWidth: 80
                                    },
                                    {
                                        Header: <Translation id="country" defaultMessage="Country" />,
                                        accessor: 'country',
                                        maxWidth: 80,
                                        Cell: ({ original }) => {
                                            return this.props.intl.formatMessage({ id: 'country_' + original.country })
                                        }
                                    },
                                    {
                                        Header: <Translation id="center" defaultMessage="Center" />,
                                        accessor: 'center',
                                        maxWidth: 80
                                    },
                                    //     {
                                    //         Header: '',
                                    //         Cell: ({ original }) => {
                                    //             return (
                                    //                     <input
                                    //     type="checkbox"
                                    //     onClick={() => this.setState(original.login)}
                                    //     key={original.login}
                                    //     defaultChecked={this.state.selectedCustomers.includes(original.login)}
                                    // />
                                    //             )
                                    //         }
                                    //     }
                                    {
                                        Header: <Translation id="accept_email_promotions" defaultMessage="Accept Email Promotions" />,
                                        maxWidth: 80,
                                        Cell: ({ original }) => {
                                            return <div key={'have_accept_mailing_' + original.login}>{original.preferences ? <i className="fal fa-check" /> : null}</div>
                                        }
                                    },
                                    {
                                        Header: <Translation id="delete" defaultMessage="Delete" />,
                                        maxWidth: 80,
                                        Cell: ({ original }) => {
                                            return (
                                                <div>
                                                    <div className="tooltip">
                                                        <span
                                                            className="bt-delete"
                                                            onClick={() => {
                                                                const newSelectedCustomers = this.state.selectedCustomers.filter(it => it !== original.login)
                                                                this.setState({ selectedCustomers: newSelectedCustomers }, () => {
                                                                    this.handleCustomersUnsavedChanges()
                                                                })
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <i className="fal fa-times" />
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                ]}
                                defaultPageSize={4}
                                pageSizeOptions={[4, 8, 10, 25, 50, 100]}
                                previousText={<Translation id="previous" defaultMessage="Previous" />}
                                nextText={<Translation id="next" defaultMessage="Next" />}
                                noDataText={
                                    <div>
                                        <Translation id="you_need_to_select_customers_for_the_campaign" defaultMessage="You need to select customers for the campaign" />
                                        <Button
                                            gentle
                                            onClick={() => {
                                                this.setState({ showModalToSelect: true })
                                            }}
                                        >
                                            <Translation id="add" defaultMessage="Add" />
                                        </Button>
                                    </div>
                                }
                                pageText={<Translation id="page" defaultMessage="Page" />}
                                ofText={<Translation id="of" defaultMessage="Of" />}
                            />
                            <form id="form_current_step" onSubmit={e => this.handleSubmit(e)} />
                        </div>
                    </div>
                    {this.state.showModalToSelect && (
                        <Modal
                            title={<Translation id="select_campaign_customers" defaultMessage="Select campaign customers" />}
                            onClose={() => this.setState({ showModalToSelect: false })}
                            footer={
                                <div className="col-12 col-lg-offset-9 col-lg-3">
                                    <Button gentle onClick={() => this.setState({ showModalToSelect: false })}>
                                        <Translation id="ok" defaultMessage="Ok" />
                                    </Button>
                                </div>
                            }
                            size="xl"
                        >
                            <div className="container p-0">
                                <div className="col-12 p-0">
                                    <form onSubmit={e => this.handleSubmit(e)}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="search-bar">
                                                    <input
                                                        type="text"
                                                        data-qa="query"
                                                        className="search-box form-control"
                                                        placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                                        data-cy="Impersonate_modal__search_box"
                                                        value={this.state.searchOnModal}
                                                        onChange={e =>
                                                            this.setState({
                                                                searchOnModal: e.target.value
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-3">
                                                {/* <a href={} data-cy="impersonate__link_download_users_list"> */}
                                                <Button
                                                    onClick={() => {
                                                        this.setState({ selectedCustomers: users.map(a => a.login) })
                                                    }}
                                                >
                                                    <Translation id="select_all" defaultMessage="Select all" />
                                                </Button>
                                                {/* </a> */}
                                            </div>
                                            <div className="col-6 col-lg-3">
                                                {/* <a href={} data-cy="impersonate__link_download_users_list"> */}
                                                <Button
                                                    onClick={() => {
                                                        const usersFiltered = users.map(user => user.login)
                                                        const newSelectedCustomers = this.state.selectedCustomers.filter(it => !usersFiltered.includes(it))
                                                        this.setState({ selectedCustomers: newSelectedCustomers })
                                                    }}
                                                >
                                                    <Translation id="remove_all" defaultMessage="Remove all" />
                                                </Button>
                                                {/* </a> */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <ReactTable
                                                    data={users}
                                                    loading={this.props.isLoading}
                                                    loadingText={<Spinner isLoading={this.props.isLoading} />}
                                                    columns={[
                                                        {
                                                            Header: <Translation id="customer_id" defaultMessage="Customer id" />,
                                                            accessor: 'login'
                                                        },
                                                        {
                                                            Header: <Translation id="customer_name" defaultMessage="Customer name" />,
                                                            accessor: 'name'
                                                        },
                                                        {
                                                            Header: <Translation id="market" defaultMessage="Market" />,
                                                            accessor: 'market',
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: <Translation id="country" defaultMessage="Country" />,
                                                            accessor: 'country',
                                                            Cell: ({ original }) => {
                                                                return this.props.intl.formatMessage({ id: 'country_' + original.country })
                                                            },
                                                            maxWidth: 100
                                                        },
                                                        {
                                                            Header: <Translation id="center" defaultMessage="Center" />,
                                                            accessor: 'center',
                                                            maxWidth: 80
                                                        },
                                                        {
                                                            Header: <Translation id="select" defaultMessage="Select" />,
                                                            maxWidth: 80,
                                                            Cell: ({ original }) => {
                                                                return (
                                                                    <input
                                                                        type="checkbox"
                                                                        onClick={() => this.selectCustomer(original.login)}
                                                                        key={original.login}
                                                                        defaultChecked={this.state.selectedCustomers.includes(original.login)}
                                                                    />
                                                                )
                                                            }
                                                        }
                                                    ]}
                                                    defaultPageSize={8}
                                                    pageSizeOptions={[8, 25, 50, 100]}
                                                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                                                    nextText={<Translation id="next" defaultMessage="Next" />}
                                                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                                    pageText={<Translation id="page" defaultMessage="Page" />}
                                                    ofText={<Translation id="of" defaultMessage="Of" />}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
            </div>
        )
    }
}

export default ChooseCustomers = withRouter(injectIntl(ChooseCustomers))
