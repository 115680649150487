import React, { useState, useEffect } from 'react'
import { defineMessages, FormattedHTMLMessage, injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import ReactTooltip from 'react-tooltip'
import copyToClipboardIcon from '../../../assets/img/components/copy-to-clipboard.svg'
import rockMasterImg from '../../../assets/img/banners-rockmaster/rock-master.png'

defineMessages({
    MSRP_and_150_day_terms: {
        id: 'MSRP_and_150_day_terms',
        description: 'MSRP_and_150_day_terms',
        defaultMessage: 'MSRP and 150-day terms.'
    },
    when_you_purchase_a_minimum_of_30_silestone_and_or_dekton_slabs: {
        id: 'when_you_purchase_a_minimum_of_30_silestone_and_or_dekton_slabs',
        description: 'when_you_purchase_a_minimum_of_30_silestone_and_or_dekton_slabs',
        defaultMessage: 'When you purchase a minimum of 30 Silestone® and/or Dekton® slabs.'
    },
    total_discount: {
        id: 'total_discount',
        description: 'total_discount',
        defaultMessage: 'Total discount'
    },
    for_any_quantity_of_silestone_and_dekton_slabs: {
        id: 'for_any_quantity_of_silestone_and_dekton_slabs',
        description: 'for_any_quantity_of_silestone_and_dekton_slabs',
        defaultMessage: 'For any quantity of Silestone® and Dekton® slabs.'
    },
    additional_discount: {
        id: 'additional_discount',
        description: 'additional_discount',
        defaultMessage: 'Additional discount'
    },
})

const BigBannerRocketV2 = props => {
    const { intl, market, isOnlyUsaSalesOrg } = props
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768)
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // Data for Card with 35 percent off
    const _35percentOffData = {
        percentAmountTitle: intl.formatMessage({ id: 'MSRP_and_150_day_terms', defaultMessage: 'MSRP and 150-day terms.' }),
        bodyText: intl.formatMessage({ id: 'when_you_purchase_a_minimum_of_30_silestone_and_or_dekton_slabs', defaultMessage: 'When you purchase a minimum of 30 Silestone® and/or Dekton® slabs.' }),
        smallText: intl.formatMessage({ id: 'total_discount', defaultMessage: 'Total discount' }),
    }

    //Data for Card with 5 and 10 percent off 
    const commonPercentOffData = {
        bodyText: intl.formatMessage({ id: 'for_any_quantity_of_silestone_and_dekton_slabs', defaultMessage: 'For any quantity of Silestone® and Dekton® slabs.' }),
        smallText: intl.formatMessage({ id: 'additional_discount', defaultMessage: 'Additional disccount' }),
    }

    return (
        <div className="big-banner-rocket-promo-v2">
            <div
                className="big-banner-rocket-promo-v2-container container"
                style={{
                    backgroundImage: `url(${rockMasterImg})`,
                    backgroundSize: 'auto',
                    backgroundPosition: isMobile ? 'center center' : 'center left',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <div className="banner-title-content">
                    <div>
                        <h3>
                            <Translation id="rocket_main_title_big_banner" defaultMessage="The one-time Rock[et] Promo is here!" />
                        </h3>
                    </div>
                    <div>
                        <p>
                            <FormattedHTMLMessage
                                id="you_have_been_chosen_for_an_exclusive_promotion_on_all_silestone_and_dekton_slabs"
                                defaultMessage="You've been chosen for an exclusive promotion on all <b>Silestone</b>® and <b>Dekton</b>® slabs."
                            />
                        </p>
                    </div>
                </div>
                <div className="banner-card-content">
                    <div>
                        <Card
                            percentAmount={35}
                            percentAmountTitle={_35percentOffData.percentAmountTitle}
                            promotionCode={isOnlyUsaSalesOrg ? '100000479' : '100000452'}
                            bodyText={_35percentOffData.bodyText}
                            smallText={_35percentOffData.smallText}
                            intl={intl}
                        />
                    </div>
                    {market === 'USACA10' ? (
                        <div>
                            <Card
                                percentAmount={10}
                                promotionCode={'100000446'}
                                bodyText={commonPercentOffData.bodyText}
                                smallText={commonPercentOffData.smallText}
                                intl={intl}
                            />
                        </div>
                    ) : market === 'USACA5' ? (
                        <div>
                            <Card
                                percentAmount={5}
                                promotionCode={'100000431'}
                                bodyText={commonPercentOffData.bodyText}
                                smallText={commonPercentOffData.smallText}
                                intl={intl}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const Card = props => {
    const { percentAmount, percentAmountTitle, promotionCode, bodyText, smallText, intl } = props
    const [promotionCopied, setPromotionCopied] = useState(false)

    const copyPromotionCodeOnClick = e => {
        e.preventDefault()
        navigator.clipboard.writeText(promotionCode).then(() => {
            setPromotionCopied(true)
            ReactTooltip.hide()
            ReactTooltip.show()
            setTimeout(() => {
                setPromotionCopied(false)
                ReactTooltip.hide()
            }, 3000)
        })
    }

    return (
        <div className="rocket-promo-card">
            <div className="rocket-card-title">
                <h5>
                    {percentAmount}%* <span><Translation id="off" defaultMessage="off" />.&nbsp;{percentAmountTitle}</span>
                </h5>
            </div>
            <div className="rocket-card-content">
                <div>
                    <h6><Translation id="enter" defaultMessage="Enter" />: {promotionCode} <Translation id="at_checkout" defaultMessage="at checkout." /></h6>
                </div>
                <div>
                    <button
                        onClick={e => copyPromotionCodeOnClick(e)}
                        data-tip={
                            promotionCopied
                                ? intl.formatMessage({ id: 'copied', defaultMessage: 'Copied' })
                                : intl.formatMessage({ id: 'copy_to_clipboard', defaultMessage: 'Copy to clipboard' })
                        }
                        className="copy-to-clipboard__btn"
                    >
                        <img src={copyToClipboardIcon} alt="copy to clipboard" />
                    </button>
                    <ReactTooltip effect="solid" place="top" />
                </div>
            </div>
            <div className="rocket-card-footer">
                {bodyText && <p>{bodyText}</p>}
                {smallText && (
                    <p className="mt-1">
                        <small>* {smallText}</small>
                    </p>
                )}
            </div>
        </div>
    )
}

export default injectIntl(BigBannerRocketV2)
