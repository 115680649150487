import React from 'react'
import Translation from '../global/Translation'
import { injectIntl, defineMessages } from 'react-intl'
import Spinner from '../global/Spinner'
import ProfileContainer from './ProfileContainer'
import Alerts from '../alerts/Alerts'
import ProfileConfigurationEmails from './ProfileConfigurationEmails'
import ReactTooltip from 'react-tooltip'
import ProfileConfigurationPromotionEmails from './ProfileConfigurationPromotionEmails'
import LocalizedLink from '../global/LocalizedLink'
import Button from '../global/Button'
import CreateAccountForm from '../login-modal/CreateAccountForm'
import AddAccountCenterForm from '../login-modal/AddAccountCenterForm'
import Modal from '../global/Modal'

defineMessages({
    ROUTE_HOME: {
        id: 'repeat_password',
        description: 'repeat_password',
        defaultMessage: 'Repeat password'
    }
})

class ProfileConfiguration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            alert: { mayus: undefined, contain: undefined, number: undefined, minus: undefined, equal: undefined },
            canSendPassword: false,
            canSendEmailsOfShippingAddress: false,
            shippingAddressesEmails: {},
            promotionEmails: [],
            showModalSettings: false,
            showModalPassword: false,
            showModaNewsLetters: false,
            showModalEmailCenters: false,
            selectedCenter: null,
            selectedWeightUnit: null,
            selectedLengthUnit: null,
            selectedLang: null
        }
    }

    handleChangeCenter = e => {
        this.setState({ selectedCenter: e.target.value })
    }

    handleChangeWeightUnit = e => {
        this.setState({ selectedWeightUnit: e.target.value })
    }

    handleChangeLengthUnit = e => {
        this.setState({ selectedLengthUnit: e.target.value })
    }
    
    handleChangeLang = e => {
        this.setState({ selectedLang: e.target.value })
    }

    transformEmailArr = () => {
        if (this.props.profileConfiguration.email.length > 0) {
            const emailsArr = this.props.profileConfiguration.email
            return emailsArr.toString().replace(/,/g, ';')
        }
    }

    toggleNotificationsCheck = checked => {
        this.setState({ rejectNotifications: checked })
    }

    handleSubmit = e => {
        e.preventDefault()
        let profileConfigData = {
            centerId: this.center && this.center.value !== undefined ? this.center.value : this.props.profileConfiguration.centerId,
            weightUnit: this.weightUnit && this.weightUnit.value !== undefined ? this.weightUnit.value : this.props.profileConfiguration.weightUnit,
            lengthUnit: this.surfaceUnit && this.surfaceUnit.value !== undefined ? this.surfaceUnit.value : this.props.profileConfiguration.lengthUnit,
            email: this.emails && this.emails.length > 0 ? this.emails.join(';') : this.transformEmailArr(),
            locale: this.locale && this.locale.value !== undefined ? this.locale.value : this.props.profileConfiguration.locale,
            shippingAddressesEmails: this.state.shippingAddressesEmails,
            rejectNotifications: this.state.rejectNotifications
        }
        this.props.saveProfileConfiguration(profileConfigData)
        this.setState({ showModalEmailCenters: false })
    }
//    checkPassword = send => {
//        let haveMayus = /.*[A-Z]+.*/.test(this.passwordInput.value)
//        let haveMinus = /.*[a-z]+.*/.test(this.passwordInput.value)
//        let haveNumber = /.*[\d]+.*/.test(this.passwordInput.value)
//        let haveContain = /.{8}/.test(this.passwordInput.value)
//        let isEqual = this.passwordInput.value === this.passwordInputRepeated.value && this.passwordInput.value !== ''
//
//        if (haveContain) this.setState({ alert: Object.assign(this.state.alert, { contain: true }) })
//        else if (send || this.state.alert.contain) this.setState({ alert: Object.assign(this.state.alert, { contain: false }) })
//
//        if (haveNumber) this.setState({ alert: Object.assign(this.state.alert, { number: true }) })
//        else if (send || this.state.alert.number) this.setState({ alert: Object.assign(this.state.alert, { number: false }) })
//
//        if (haveMinus) this.setState({ alert: Object.assign(this.state.alert, { minus: true }) })
//        else if (send || this.state.alert.minus) this.setState({ alert: Object.assign(this.state.alert, { minus: false }) })
//
//        if (haveMayus) this.setState({ alert: Object.assign(this.state.alert, { mayus: true }) })
//        else if (send || this.state.alert.mayus) this.setState({ alert: Object.assign(this.state.alert, { mayus: false }) })
//
//        if (isEqual) this.setState({ alert: Object.assign(this.state.alert, { equal: true }) })
//        else if (send || this.state.alert.equal) this.setState({ alert: Object.assign(this.state.alert, { equal: false }) })
//        this.setState({ canSendPassword: haveMayus && haveMinus && haveNumber && haveContain && isEqual })
//        return haveMayus && haveMinus && haveNumber && haveContain && isEqual
//    }
    /* handleSubmitChangePassword = e => {
        e.preventDefault()
        if (this.checkPassword(true))
            this.props
                .changePassword(this.passwordInput.value)
                .then(() => {
                    this.setState({
                        alert: {
                            mayus: undefined,
                            contain: undefined,
                            number: undefined,
                            minus: undefined,
                            equal: undefined
                        },
                        canSendPassword: false
                    })
                })
                .catch()
    } */
    handleSubmitChangeEmailsOfShippingAddress = e => {
        e.preventDefault()
    }
    togglePromotionNotificationsCheck = checked => {
        this.setState({ acceptPromotionNotifications: checked })
    }

    validateAndTransformEmail(email) {
        let transformedEmail = email.toLowerCase()
        if (transformedEmail.includes('@cosentino')) {
            this.setState({ highlightCosentinoNotAllowed: true }, () => {
                setTimeout(() => this.setState({ highlightCosentinoNotAllowed: false }), 1000)
            })
            return null
        } else {
            return transformedEmail
        }
    }

    copyOnlyEmailsValid = () => {
        let emailsToConcat = []
        this.emails.forEach(email => {
            const transformedEmail = this.validateAndTransformEmail(email)
            if (transformedEmail) {
                emailsToConcat.push(transformedEmail)
            }
        })
        this.setState({
            copyEmailAddress: true,
            promotionEmails: this.state.promotionEmails.concat(emailsToConcat).filter((value, index, self) => self.indexOf(value) === index)
        })
    }
    togglePromotionPoliciesCheck = checked => {
        this.setState({ acceptPolicies: checked })
    }
    handlePromotionsSubmit = e => {
        e.preventDefault()
        let profileConfigPromotion = {
            promotionEmail: this.state.promotionEmails.join(';'),
            acceptPromotionNotifications: this.state.acceptPromotionNotifications,
            copyEmailAddress: this.state.copyEmailAddress,
            acceptPolicies: this.state.acceptPolicies
        }
        this.props.savePromotionConfiguration(profileConfigPromotion)
    }

    componentDidMount() {
        this.props.fetchProfileConfiguration().then(() => {
            this.setState({
                selectedCenter: this.props.profileConfiguration.centerId, 
                selectedWeightUnit: this.props.profileConfiguration.weightUnit, 
                selectedLengthUnit: this.props.profileConfiguration.lengthUnit, 
                selectedLang: this.props.profileConfiguration.locale,
            })
            if (!this.props.isAdmin) this.setState({ shippingAddressesEmails: this.props.profileConfiguration.shippingAddressesEmails })
        })

        this.props.fetchPromotionConfiguration().then(() => {
            this.setState({
                promotionEmails: this.props.promotionConfiguration.email,
                acceptNotifications: this.props.promotionConfiguration.accept_notifications,
                copyEmails: this.props.promotionConfiguration.copy_emails,
                acceptPolicies: this.props.promotionConfiguration.accept_policies
            })
        })
    }

    render() {
        const isLoading = this.props.isLoading
        const isSending = this.props.isSending
        const spinner = isLoading ? <Spinner isLoading={isLoading} /> : null
        const center = this.props.profileConfiguration.centerId
        const locale = this.props.profileConfiguration.locale
        let filteredShippingAddresses = []
        if (this.props.shippingAddresses) {
            filteredShippingAddresses = [...new Map(this.props.shippingAddresses.map(shipAddress => [shipAddress['shippingAddressId'], shipAddress])).values()]
        }

        return (
            <div className="box-profile row">
                <div className="box-profile-menu col-12 col-md-4 col-lg-3">
                    <ProfileContainer />
                </div>
                <div className="col-12 col-lg-9">
                    {isLoading ? (
                        spinner
                    ) : (
                        <div className="profile-form profile-configuration-screen" style={{ marginTop: 0, backgroundColor: 'white', padding: '1rem' }}>
                            <div className="form user-form">
                                <div className="row">
                                    <div className="col-12 col-lg-5">
                                        <span className="h1-profile mb-3">
                                            <Translation id="configuration" defaultMessage="Configuration" />
                                        </span>
                                        <div className="d-flex flex-column mb-2">
                                            <span className="txt-profile">
                                                <Translation id="center_preferred" defaultMessage="Center Preferred"></Translation> :
                                            </span>
                                            <span className="txt-profile" style={{ fontWeight: '500' }}>
                                                {this.props.centers.map(e => {
                                                    if (Number(e.centerId) === Number(this.props.profileConfiguration.centerId)) {
                                                        return <React.Fragment>{e.name}</React.Fragment>
                                                    }
                                                })}
                                            </span>
                                        </div>
                                        <div className="d-flex flex-column mb-2">
                                            <span className="txt-profile">
                                                <Translation id="favourite_lang" defaultMessage="Favourite language" />:
                                            </span>
                                            <span className="txt-profile" style={{ fontWeight: '500' }}>
                                                {this.props.availableLands.map(lang => {
                                                    if (lang.localecode === this.props.profileConfiguration.locale) return <React.Fragment>{lang.name}</React.Fragment>
                                                })}
                                            </span>
                                        </div>
                                        <div className="d-flex flex-column mb-2">
                                            <span className="txt-profile">
                                                <Translation id="measurement_unit" defaultMessage="Measurement Unit" />:
                                            </span>
                                            <span className="txt-profile" style={{ fontWeight: '500' }}>
                                                <Translation id="weight" defaultMessage="Weight" />:{' '}
                                                {this.props.profileConfiguration.weightUnit || <Translation id="default_by_language" defaultMessage="Default by language" />}
                                            </span>
                                            <span className="txt-profile" style={{ fontWeight: '500' }}>
                                                {' '}
                                                <Translation id="surface" defaultMessage="Surface" />:{' '}
                                                {this.props.profileConfiguration.lengthUnit || <Translation id="default_by_language" defaultMessage="Default by language" />}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-7 order-notifications-box">
                                        <div>
                                            <span className="h1-profile mb-3">
                                                <Translation id="orders_notifications" defaultMessage="Orders notifications" />
                                            </span>
                                            <span className="h2-profile">
                                                <Translation id="emails_for_your_center_by_default" defaultMessage="Emails for your center by default" />:
                                            </span>
                                            <span className="h4-profile">
                                                {this.props.profileConfiguration.rejectNotifications ? (
                                                    <Translation id="general_notifications_for_all_orders" defaultMessage="You will receive general notifications for all orders" />
                                                ) : (
                                                    <Translation
                                                        id="general_additional_notifications_for_all_orders"
                                                        defaultMessage="You will receive general and additional notifications for all orders"
                                                    />
                                                )}
                                            </span>
                                        </div>
                                        <div className="mt-2">
                                            {this.props.emails.map((value, index) => {
                                                return (
                                                    <span key={index} className="txt-email-profile" style={{ fontWeight: 500, textTransform: 'lowercase' }}>
                                                        {value}
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <span onClick={() => this.setState({ showModalSettings: true })} className="btn-link">
                                        <Translation id="change" defaultMessage="Change" />
                                    </span>
                                </div>
                            </div>

                            {!this.props.isAdmin && (
                                <React.Fragment>
                                    <hr />
                                    <div>
                                        <div className="form promotions-preferences-form">
                                            <div className="row" style={{ border: 'none' }}>
                                                <div className="col-12 ">
                                                    <span className="h2-profile">
                                                        <Translation id={'shipping_addresses_emails'} defaultMessage="Shipping Addresses Emails"></Translation>
                                                    </span>
                                                    <span className="h4-profile">
                                                        <Translation
                                                            id="receive_notifications_about_orders_related_to_the_associated_addresses"
                                                            defaultMessage="You will only receive notifications about orders related to the associated addresses"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                {filteredShippingAddresses &&
                                                    filteredShippingAddresses.map(shippingAddress => (
                                                        <div className="txt-email-profile mt-3" key={shippingAddress.shippingAddressId}>
                                                            <span>
                                                                {shippingAddress.customerName}{' - '}
                                                                {shippingAddress.address}
                                                            </span>
                                                            <span className="txt-email-profile" style={{ fontWeight: 500 }}>
                                                                {this.state.shippingAddressesEmails &&
                                                                    this.state.shippingAddressesEmails[shippingAddress.shippingAddressId] &&
                                                                    this.state.shippingAddressesEmails[shippingAddress.shippingAddressId].map((value, index) => {
                                                                        return(    
                                                                            <div>{value}</div>
                                                                        ) 
                                                                    })}
                                                            </span>
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="mt-3">
                                                <span onClick={() => this.setState({ showModalEmailCenters: true })} className="btn-link">
                                                    <Translation id="change" defaultMessage="Change" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            <form onSubmit={this.handleSubmit}>
                                {this.state.showModalSettings && (
                                    <Modal
                                        title={<Translation id="change_your_preferences" defaultMessage="Change your preferences" />}
                                        onClose={() => this.setState({ showModalSettings: false })}
                                        footer={
                                            <div style={{ float: 'right' }}>
                                                <div className="d-flex">
                                                    <button
                                                        style={{ marginRight: '8px' }}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({ showModalSettings: false })
                                                        }}
                                                        className="btn-secondary-m"
                                                    >
                                                        <Translation id="cancel" defaultMessage="Cancel" />
                                                    </button>
                                                    {isSending ? (
                                                        <button
                                                            type="submit"
                                                            className="btn-primary-m"
                                                            value={this.props.intl.formatMessage({
                                                                id: 'save_changes'
                                                            })}
                                                            disabled
                                                            style={{ marginBottom: '0' }}
                                                        >
                                                            {this.props.intl.formatMessage({
                                                                id: 'save_changes'
                                                            })}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            className="btn-primary-m"
                                                            value={this.props.intl.formatMessage({
                                                                id: 'save_changes'
                                                            })}
                                                            style={{ marginBottom: '0' }}
                                                        >
                                                            {this.props.intl.formatMessage({
                                                                id: 'save_changes'
                                                            })}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    >
                                        <Alerts alert={this.props.alert} status={this.props.status} />
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="select-default-center" className="txt-modal">
                                                        <Translation id="favourite_center" defaultMessage="Favourite center" />
                                                    </label>
                                                    <select
                                                        id="select-default-center"
                                                        ref={node => {
                                                            this.center = node
                                                        }}
                                                        className="form-control customDropdown"
                                                        value={this.state.selectedCenter}
                                                        onChange={e => this.handleChangeCenter(e)}
                                                    >
                                                        {this.props.centers.map(center => (
                                                            <option value={center.centerId} key={center.centerId}>
                                                                {center.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="select-default-lang" className="txt-modal">
                                                        <Translation id="favourite_lang" defaultMessage="Favourite language" />
                                                    </label>
                                                    <select
                                                        id="select-default-lang"
                                                        ref={node => {
                                                            this.locale = node
                                                        }}
                                                        className="form-control customDropdown"
                                                        value={this.state.selectedLang}
                                                        onChange={e => this.handleChangeLang(e)}
                                                    >
                                                        {this.props.availableLands.map(locale => (
                                                            <option value={locale.localecode} key={locale.localecode}>
                                                                {locale.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="h1-modal">
                                            <Translation id="measurement_unit" defaultMessage="Measurement Unit" />
                                        </span>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="select-weight-unit" className="txt-modal">
                                                        <Translation id="weight" defaultMessage="Weight" />
                                                    </label>
                                                    <select
                                                        id="select-weight-unit"
                                                        ref={node => {
                                                            this.weightUnit = node
                                                        }}
                                                        className="form-control customDropdown"
                                                        value={this.state.selectedWeightUnit}
                                                        onChange={e => this.handleChangeWeightUnit(e)}
                                                    >
                                                        <option value={'KG'} key={'kg'}>
                                                            kg
                                                        </option>
                                                        <option value={'LB'} key={'pounds'}>
                                                            lb
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="select-surface-unit" className="txt-modal">
                                                        <Translation id="surface" defaultMessage="Surface" />
                                                    </label>
                                                    <select
                                                        id="select-surface-unit"
                                                        ref={node => {
                                                            this.surfaceUnit = node
                                                        }}
                                                        className="form-control customDropdown"
                                                        value={this.state.selectedLengthUnit}
                                                        onChange={e => this.handleChangeLengthUnit(e)}
                                                    >
                                                        <option value={'M2'} key={'m2'}>
                                                            m2
                                                        </option>
                                                        <option value={'FT2'} key={'ft2'}>
                                                            ft2
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="h1-modal">
                                            <Translation id="emails_for_your_center_by_default" defaultMessage="Emails for your center by default" />
                                        </span>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="default-email" className="txt-modal">
                                                        <Translation id="email" defaultMessage="Email" />
                                                    </label>
                                                    <ProfileConfigurationEmails
                                                        idForInput={'input-email-configuration'}
                                                        ref={node => {
                                                            this.emailsNode = node
                                                        }}
                                                        change={emails => {
                                                            this.emails = emails
                                                        }}
                                                    />
                                                </div>
                                                <div className="promotions-preferences-form">
                                                    <label>
                                                        <input
                                                            name="reject_notifications_check"
                                                            type="checkbox"
                                                            onClick={input => this.toggleNotificationsCheck(input.target.checked)}
                                                            defaultChecked={this.props.profileConfiguration.rejectNotifications}
                                                        />
                                                        <span>
                                                            <Translation
                                                                id="reject_notifications_check"
                                                                defaultMessage="I do not want to receive additional notifications about the tracking of my orders"
                                                            />
                                                        </span>
                                                    </label>
                                                    <div>
                                                        <span className="additional-info-msg">
                                                            (<Translation id="changes_in_its_status_or_delivery_date" defaultMessage="As changes in its status or delivery date" />)
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                )}
                                {this.state.showModalEmailCenters && (
                                    <Modal
                                        title={
                                            <Translation id="notifications_for_your_additional_centers" defaultMessage="Modify your notifications for your additional centers" />
                                        }
                                        onClose={() => this.setState({ showModalEmailCenters: false })}
                                        showFooter={false}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <span className="h1-profile"></span>
                                                <span className="h4-profile mb-3">
                                                    (
                                                    <Translation
                                                        id="receive_notifications_about_orders_related_to_the_associated_addresses"
                                                        defaultMessage="You will only receive notifications about orders related to the associated addresses"
                                                    />
                                                    )
                                                </span>
                                                <div className="row">
                                                    {filteredShippingAddresses &&
                                                        filteredShippingAddresses.map(shippingAddress => (
                                                            <div className="col-12" key={shippingAddress.shippingAddressId}>
                                                                <div className="form-group">
                                                                    <label
                                                                        className="txt-modal"
                                                                        style={{ display: 'flex', overflow: 'hidden' }}
                                                                        htmlFor="default-email"
                                                                        data-tip={
                                                                            shippingAddress.centerName +
                                                                            ' ' +
                                                                            shippingAddress.customerName +
                                                                            ' ' +
                                                                            shippingAddress.address +
                                                                            ' ' +
                                                                            shippingAddress.zipCode +
                                                                            ' ' +
                                                                            shippingAddress.city +
                                                                            ' ' +
                                                                            shippingAddress.country +
                                                                            ' ' +
                                                                            shippingAddress.salesOrg
                                                                        }
                                                                    >
                                                                        <span style={{ marginRight: '5px' }}>
                                                                            <Translation id="email" defaultMessage="Email" />
                                                                        </span>
                                                                        <span style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                            {shippingAddress.customerName} - {shippingAddress.centerName}
                                                                        </span>
                                                                        <i className="fal fa-info-circle" style={{ margin: '5px', flex: '0 0 auto' }} />
                                                                        <ReactTooltip bg-coloreffect="solid" place="bottom" />
                                                                    </label>
                                                                    <ProfileConfigurationEmails
                                                                        key={
                                                                            shippingAddress.shippingAddressId +
                                                                            this.state.shippingAddressesEmails[shippingAddress.shippingAddressId]
                                                                        }
                                                                        idForInput={shippingAddress.shippingAddressId}
                                                                        emails={
                                                                            this.state.shippingAddressesEmails &&
                                                                            this.state.shippingAddressesEmails[shippingAddress.shippingAddressId]
                                                                                ? this.state.shippingAddressesEmails[shippingAddress.shippingAddressId]
                                                                                : []
                                                                        }
                                                                        change={emails => {
                                                                            this.setState({
                                                                                shippingAddressesEmails: {
                                                                                    ...this.state.shippingAddressesEmails,
                                                                                    [shippingAddress.shippingAddressId]: emails
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ float: 'right' }}>
                                            <div className="d-flex">
                                                <button
                                                    style={{ marginRight: '8px' }}
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.setState({ showModalEmailCenters: false })
                                                    }}
                                                    className="btn-secondary-m"
                                                >
                                                    <Translation id="cancel" defaultMessage="Cancel" />
                                                </button>
                                                {isSending ? (
                                                    <button
                                                        type="submit"
                                                        className="btn-primary-m"
                                                        value={this.props.intl.formatMessage({
                                                            id: 'save_changes'
                                                        })}
                                                        disabled
                                                        style={{ marginBottom: '0' }}
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'save_changes'
                                                        })}
                                                    </button>
                                                ) : (
                                                    <button
                                                        id="send-address-mails-button"
                                                        type="submit"
                                                        className="btn-primary-m"
                                                        value={this.props.intl.formatMessage({
                                                            id: 'save_changes'
                                                        })}
                                                        style={{ marginBottom: '0' }}
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'save_changes'
                                                        })}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </Modal>
                                )}
                            </form>

                            <hr />
                            <div className="toolbar" style={{ padding: '0' }}>
                                <div className="container">
                                    <div className="row" style={{ border: 'none' }}>
                                        <div className="col-12 ">
                                            <span className="h1-profile">
                                                <Translation id="other_notifications" defaultMessage="Other notifications" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form promotions-preferences-form">
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-column mb-2">
                                            <span className="h3-profile">
                                                <strong>
                                                    <Translation id="newsletter" defaultMessage="Newsletter" />
                                                </strong>
                                            </span>
                                            {this.state.promotionEmails.length === 0 && (
                                                <span className="h4-profile">
                                                    <Translation
                                                        id="option_receive_promotional_offers_campaigns"
                                                        defaultMessage="You have not activated the option to receive promotional offers and campaigns in your Cosentino center"
                                                    />
                                                </span>
                                            )}
                                        </div>
                                        {this.state.promotionEmails.length > 0 && (
                                            <div>
                                                {this.state.promotionEmails.map(email => (
                                                    <div
                                                        style={{
                                                            fontFamily: 'Roboto',
                                                            fontWeight: '500',
                                                            fontSize: '14px',
                                                            lineHeight: ' 20px',
                                                            color: '#4d4d4d'
                                                        }}
                                                    >
                                                        {email}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-3">
                                        <span onClick={() => this.setState({ showModaNewsLetters: true })} className="btn-link">
                                            <Translation id="change" defaultMessage="Change" />
                                        </span>
                                    </div>
                                </div>
                                {this.state.showModaNewsLetters && (
                                    <Modal onClose={() => this.setState({ showModaNewsLetters: false })}>
                                        <form onSubmit={this.handlePromotionsSubmit}>
                                            <Alerts alert={this.props.alert} status={this.props.status} />
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="promotion-email">
                                                        <Translation id="email" defaultMessage="Email" />
                                                    </label>
                                                    {
                                                        <ProfileConfigurationPromotionEmails
                                                            idForInput={'promotion-email-configuration'}
                                                            ref={node => {
                                                                this.emailsNode = node
                                                            }}
                                                            promotionEmails={this.state.promotionEmails}
                                                            change={emails => {
                                                                this.setState({ promotionEmails: emails })
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="CheckboxPoliciesCheck">
                                                        <input
                                                            name="accept_policies_check"
                                                            type="checkbox"
                                                            onClick={input => this.togglePromotionPoliciesCheck(input.target.checked)}
                                                            defaultChecked={this.state.acceptPolicies}
                                                        />{' '}
                                                        <Translation id="i_accept_the" defaultMessage="I accept the" />{' '}
                                                        <LocalizedLink routeId="ROUTE_PRIVACY">
                                                            <Translation id="privacy_policy" defaultMessage="Privacy policy" />
                                                        </LocalizedLink>{' '}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label className="CheckboxNotificationCheck">
                                                        <input
                                                            name="accept_notifications_check"
                                                            type="checkbox"
                                                            onClick={input => this.togglePromotionNotificationsCheck(input.target.checked)}
                                                            defaultChecked={this.state.acceptNotifications}
                                                            disabled={!this.state.acceptPolicies}
                                                        />{' '}
                                                        <Translation
                                                            id="promotion_preferences_accept_notifications_check"
                                                            defaultMessage="I want to receive notifications about promotions and offers from my center in eCosentino"
                                                        />{' '}
                                                    </label>
                                                </div>
                                            </div>
                                            <br />
                                            <div style={{ float: 'right' }}>
                                                <div className="d-flex  justify-content-end">
                                                    <button
                                                        style={{ marginRight: '8px' }}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            this.setState({ showModaNewsLetters: false })
                                                        }}
                                                        className="btn-secondary-m"
                                                    >
                                                        <Translation id="cancel" defaultMessage="Cancel" />
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn-primary-m"
                                                        value={this.props.intl.formatMessage({ id: 'save_changes' })}
                                                        data-cy="change_password__form-submit"
                                                        style={{ marginBottom: '0' }}
                                                    >
                                                        {this.props.intl.formatMessage({ id: 'save_changes' })}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </Modal>
                                )}
                            </div>
                            {this.props.isAdmin && (
                                <div>
                                    <div className="toolbar">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 ">
                                                    <h4>
                                                        <Translation id="add_user_center" defaultMessage="Add user center" />
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <AddAccountCenterForm onAddEmailCenter={this.props.onAddEmailCenter} userName={this.props.userName} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.props.isAdmin && (
                                <div>
                                    <div className="toolbar">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 ">
                                                    <h4>
                                                        <Translation id="create_customer_account" defaultMessage="Create customer account" />
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <CreateAccountForm showLogin={this.showLogin} onCreateAccount={this.props.onCreateAccount} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
        
    }
}

export default injectIntl(ProfileConfiguration)
