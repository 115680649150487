import React from 'react'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'
import * as CustomStyles from '../styles/CustomStyles'
import { connect } from 'react-redux'
import { submitWalkthrough } from '../../../store/ui/actions'
import { getHasWalkthrough } from '../../../store/login/reducers'
import { fetchHasWalkthrough } from '../../../store/login/actions'
import Translation from '../../global/Translation'

const mapStateToProps = (state) => {
    return {
        hasWalkthrough: getHasWalkthrough(state, 'SHIPPING_HISTORY')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitWalkthrough: (type, noShowMore) => dispatch(submitWalkthrough(type, noShowMore)),
        refreshData: () => dispatch(fetchHasWalkthrough())
    }
}

class ShippingWalkthrough extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stepIndex: 0,
            type: 'SHIPPING_HISTORY'
        }
    }

    /**
     *  Body content for step one
     */
    stepOneContent = () => {
        return (
            <div className="walkthrough__tooltip__content__main">
                <div className="walkthrough__tooltip__content__main--heading">
                    <h2><Translation id="walkthrough_new_shipments_view" defaultMessage="New Shipments view" /></h2>
                </div>
                <div className="walkthrough__tooltip__content__main--info">
                    <span><Translation id="walkthrough_where_you_will_be_able_to_check_the_status" defaultMessage="Where you will be able to check the status of the shipments you are going to receive, the delivery date and the details of the materials included in the shipment." /></span>
                </div>
            </div>
        )
    }

    /**
     * Body content for step two
     */
    stepTwoContent = () => {
        return (
            <div className="walkthrough__tooltip__content">
                <div className="walkthrough__tooltip__content--heading">
                    <h2><Translation id="walkthrough_new_quick_filters" defaultMessage="New quick filters" /></h2>
                </div>
                <div className="walkthrough__tooltip__content--info">
                    <span><Translation id="walkthrough_you_can_choose_if_you_want_to_see_all_shipments" defaultMessage="You can choose if you want to see all shipments, only the ones that will arrive today, only the last week etc... with a single click." /></span>
                </div>
            </div>
        )
    }

    /**
     * Body content for step three
     */
    stepThreeContent = () => {
        return (
            <div className="walkthrough__tooltip__content">
                <div className="walkthrough__tooltip__content--heading">
                    <h2><Translation id="walkthrough_what_products_are_included_in_this_shipment" defaultMessage="What products are included in this shipment?" /></h2>
                </div>
                <div className="walkthrough__tooltip__content--info">
                    <span><Translation id="walkthrough_check_the_products_coming_in_the_next_shipment" defaultMessage="Check the products coming in the next shipment." /></span>
                </div>
            </div>
        )
    }

    /**
     * Body content for step four
     */
    stepFourContent = () => {
        return (
            <div className="walkthrough__tooltip__content">
                <div className="walkthrough__tooltip__content--heading">
                    <h2><Translation id="status_and_estimated_delivery_date" defaultMessage="Status and estimated delivery date" /></h2>
                </div>
                <div className="walkthrough__tooltip__content--info">
                    <span><Translation id="walkthrough_view_the_status_of_your_shipment_and_the_estimated_delivery_date" defaultMessage="View the status of your shipment and the estimated delivery date." /></span>
                </div>
            </div>
        )
    }

    /**
     * Determines which step content to display
     * @returns boolean
     */
    breakpointIndicator = () => {
        if (window.innerWidth <= 767) return true
        return false
    }

    // Desktop version
    stepThreeDesktop = {
        content: this.stepThreeContent(),
        locale: {
            next: <span aria-label="next"><Translation id="next" defaultMessage="Next" /></span>,
            back: <span aria-label="back"><Translation id="back" defaultMessage="Back" /></span>
        },
        target: '.show-more-less-shipping',
        styles: {
            buttonSkip: {
                display: 'none'
            }
        }
    }

    stepThreeResponsive = {
        content: this.stepThreeContent(),
        locale: {
            next: <span aria-label="next"><Translation id="next" defaultMessage="Next" /></span>,
            back: <span aria-label="back"><Translation id="back" defaultMessage="Back" /></span>
        },
        target: '.slick-slide.slick-active.slick-current .order__card__item',
        styles: {
            buttonSkip: {
                display: 'none'
            }
        }
    }

    /**
     * steps
     */
    steps = {
        steps: [
            {
                content: this.stepOneContent(),
                locale: {
                    next: <span aria-label="next"><Translation id="get_started" defaultMessage="Get started" /></span>,
                    skip: <span aria-label="skip"><Translation id="do_not_show_again" defaultMessage="Do not show again" /></span>
                },
                placement: 'center',
                target: 'body',
            },
            {
                content: this.stepTwoContent(),
                locale: {
                    next: <span aria-label="next"><Translation id="next" defaultMessage="Next" /></span>,
                    back: <span aria-label="back"><Translation id="back" defaultMessage="Back" /></span>
                },
                placement: 'bottom-start',
                target: '.shipping-walkthrough-filter-quick',
                styles: {
                    buttonSkip: {
                        display: 'none'
                    }
                }
            },
            this.breakpointIndicator() ? this.stepThreeResponsive : this.stepThreeDesktop,
            {
                content: this.stepFourContent(),
                locale: {
                    last: <span aria-label="last"><Translation id="understood" defaultMessage="Understood" /></span>,
                    back: <span aria-label="back"></span>
                },
                target: '.vertical-timeline-container',
                styles: {
                    buttonSkip: {
                        display: 'none'
                    }
                }
            },
        ]
    }

    /**
     * Callback function to manipulate the walkthrough logic
     */
    handleJoyrideCallback = data => {
        const { action, index, type } = data

        const endStatus = [EVENTS.TOUR_END]

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) })
        }

        if (index === 0) {
            if (endStatus.includes(type)) {
                this.props.onSubmitWalkthrough(this.state.type, true).then(() => this.props.refreshData())
            }
        } else if (endStatus.includes(type)) {
            this.props.onSubmitWalkthrough(this.state.type, false).then(() => this.props.refreshData())
        }
    }

    render() {
        const { steps } = this.steps
        const { hasWalkthrough } = this.props

        return hasWalkthrough && hasWalkthrough.shouldShow ? (
            <React.Fragment>
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    showProgress
                    steps={steps}
                    styles={{
                        tooltip: CustomStyles.TooltipStyles,
                        tooltipContent: CustomStyles.TooltipContentStyles,
                        options: CustomStyles.OptionStyles,
                        buttonNext: CustomStyles.ButtonNextStyles,
                        buttonBack: CustomStyles.ButtonBackStyles,
                        tooltipFooter: CustomStyles.TooltipFooterStyles,
                        spotlight: CustomStyles.SpotlightSyles
                    }}
                    disableScrolling
                    showSkipButton
                />
            </React.Fragment>
        ) : null
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingWalkthrough)