import { canViewThresholdMax } from './../../../store/ui/reducers';
import React from 'react'
import Translation from '../../global/Translation'

import iconoTemporalAcabado from '../../../assets/img/acabado.png'
import ProductDisplayName from '../../global/ProductDisplayName'
import LengthMeasureTranslation from '../../global/LengthMeasureTranslation'
import LengthMeasure from '../../global/LengthMeasure'
import mallLarge from '../../../assets/img/logos/mall-large.jpg'
//Displays
import DisplayProductInfo from './displays/DisplayProductInfo'
import DisplayBrand from './displays/DisplayBrand'
import DisplayProductInfoTable from './displays/DisplayProductInfoTable'
import DisplayThickness from './displays/DisplayThickness'
import DisplayGroupDtails from './displays/DisplayGroupDtails'
import DisplayDetail from './displays/DisplayDetail'
import DisplayStock from './displays/DisplayStock'
import DisplayPrice from './displays/DisplayPrice'
import ProgressBar from '../../global/ProgressBar'
import LocalizedLink from '../../global/LocalizedLink'
import { connect } from 'react-redux'
import { getAdaptedFormat, getReplaceDotWithComma } from '../../../helpers'

class DetailsDefaultV2TemporalFix extends React.Component {
    state = { showHelp: false }
    getUnitBy() {
        const { product } = this.props
        if (product.unitsByLot) return product.unitsByLot
        else if (product.unitsByBox) return product.unitsByBox
        else if (!product.unitsByLot && !product.unitsByBox) return <LengthMeasure product={product} />
    }

    render() {
        const { product, canViewThresholdMax, intl } = this.props
        const available = product.type === 'TABLA' ? <Translation id="tables" defaultMessage="Slabs" /> : <Translation id="available" defaultMessage="Available" />
        let quantity
        if (product.type === 'TABLA') {
            quantity = (
                <div style={{display:'inline'}}>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }
        const watchingOutlet = this.props.outlet ? true : false
        let stock
        if (watchingOutlet) {
            stock = product.stock_outlet
        } else {
                stock = product.stock
        }
        const styleBorder = !this.props.notDescriptionProduct ? { borderBottom: '1px solid lightgray', width: '100%', paddingBottom: '10px', color: '#808080' } : { width: '80%', paddingBottom: '10px', color: '#808080' }

        return (
            <DisplayProductInfo mallPromotionSection= {this.props.mallPromotionSection}>
                
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <DisplayBrand brand={product.brand} />
                {
                    this.props.landingMall &&
                    <img style={{ width: '60px' }} className={"landing-mall-img"} src={mallLarge} alt="logo mall" />
                }
                </div>
                
                <div className="product-color-name">
                    <ProductDisplayName product={product} />
                </div>
                {this.props.isExclusiveHomeDepot && (
                    <small>
                        <span style={{ color: '#f96303' }}>
                            <Translation id="home_depot_exclusive" defaultMessage="Home Depot exclusive"></Translation>
                        </span>
                    </small>
                )}
                {
                    !this.props.mallPromotionSection &&
                    <div className="product-info-details_productid">
                        <div style={styleBorder}>
                            <DisplayGroupDtails>
                                <DisplayDetail class="productId" value={product.productId} label={<Translation id="product" defaultMessage="Product"></Translation>} />
                            </DisplayGroupDtails>
                        </div>
                    </div>
                }
                

                {!this.props.notDescriptionProduct && !this.props.mallPromotionSection  && (
                    <React.Fragment>
                    <div className="product-info-details__content">
                        <DisplayGroupDtails>
                            <DisplayDetail class="measure-unit format" value={this.props.adaptedFormat(product.format, {upper:true, showUnits:true})} label={<Translation id="format" defaultMessage="Format"></Translation>} />
                        </DisplayGroupDtails>
                        <DisplayGroupDtails>
                            <DisplayDetail class="subtype" value={product.subtypeName} label={<Translation id="subtype" defaultMessage="Subtype" />} />
                        </DisplayGroupDtails>
                        <DisplayGroupDtails>
                            <DisplayDetail class="thickness" value={this.props.replaceDotWithComma(product.thickness, intl.locale)} label={<Translation id="thickness" defaultMessage="Thickness"></Translation>} />
                            {product.thickness && (<span className="thickness-measure">cm</span>)}
                        </DisplayGroupDtails>
                        <DisplayGroupDtails>
                            <DisplayDetail class="finish" value={product.finish} label={<Translation id="finish" defaultMessage="Finish"></Translation>} />
                        </DisplayGroupDtails>
                    </div>
                        <DisplayGroupDtails>{<DisplayStock value={stock} measure={quantity} surface={this.getUnitBy()} product={this.props.product} landingMall = {this.props.landingMall} watchingOutlet={this.props.outlet} canViewThresholdMax={this.props.canViewThresholdMax}/>}</DisplayGroupDtails>
                    </React.Fragment>
                )}
                {
                    this.props.landingMall &&
                    <DisplayGroupDtails>
                        <DisplayDetail class="finish minimum" value={this.props.landingMall.minimumOrder || "1"} label={<Translation id="minimun_order" defaultMessage="Minimun Order" />} />
                    </DisplayGroupDtails>

                }
                {
                    this.props.landingMall &&
                    <div class="box-buttons-lots-v2">  
                        
                        {
                            (this.props.landingMall.stock > 0 || this.props.landingMall.data.stock)  > 0 &&
                            this.props.landingMall.data.type === 'TABLA' &&
                            (this.props.landingMall.data.lotViewAvailable === undefined || this.props.landingMall.data.lotViewAvailable ? (
                                <div className="buttons-lots-v2">
                                    <LocalizedLink routeId="ROUTE_LOTS" params={{ id: this.props.landingMall.product_id, outlet: '' }}>
                                        <div className="lots">
                                            <Translation id="view_lots" defaultMessage="View lots" /> (
                                            {this.props.landingMall.stock || this.props.landingMall.data.stock})
                                        </div>
                                    </LocalizedLink>
                                </div>
                            ) : (
                                <div className="buttons-lots-v2">
                                    <React.Fragment>
                                                <Translation id="lots" defaultMessage="Lots" /> ({this.props.landingMall.stock || this.props.landingMall.data.stock})
                                    </React.Fragment>
                                </div>
                            ))
                        }
                    </div>
                }
               
                <DisplayGroupDtails>{<DisplayPrice product={this.props.product} outlet={this.props.outlet} />}</DisplayGroupDtails>
                {
                    this.props.mallPromotionSection &&
                    <React.Fragment>                       
                            <div className='promotion-progress-bar'>
                                <span style={{
                                        width: `${
                                            this.props.product.stock > 0 ? 
                                            //(this.props.product.stock_mall / this.props.product.stock) * 100
                                            100
                                            : 0}%`,
                                        backgroundColor: `rgb(54, 165, 150)`
                                    }}></span>
                                </div>
                        
                        <DisplayGroupDtails>{<DisplayStock value={stock} measure={quantity} surface={this.getUnitBy()} product={this.props.product}  />}</DisplayGroupDtails>
                                 
                    </React.Fragment>
                }

                {/* <span>{this.props.intl.formatMessage({ id: 'material_id' })}:product.productId</span> */}
                {/* <DisplayProductInfoTable
                    th={[{ value: available }, { datacy: 'product_rectangle_result__surface', value: quantity }, { value: this.props.intl.formatMessage({ id: 'material_id' }) }]}
                    td={[{ value: stock }, { datacy: 'product_rectangle_result__surface_value', value: this.getUnitBy() }, { value: product.productId }]}
                /> */}
                {/* <DisplayGroupDtails></DisplayGroupDtails> */}
                {/* <DisplayGroupDtails>
                    <DisplayDetail class="subtypeName" value={product.subtypeName} />
                    <DisplayDetail class="format" value={product.format} />
                </DisplayGroupDtails> */}
                
            </DisplayProductInfo>
        )
    }
}

const mapStateToProps = state => {
    return {
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state)
    }
}

export default connect(
    mapStateToProps,
    null
)(DetailsDefaultV2TemporalFix)