import React from 'react'

import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl'

import logo from '../../assets/img/e.cosentino.svg'
import SignInForm from './SignInForm'
// import OldSignInForm from './OldSignInForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import CreateAccountForm from './CreateAccountForm'
import MandatoryChangePasswordForm from './MandatoryChangePasswordForm'
import Translation from "../global/Translation";
import {Link} from "react-router-dom";

class LoginModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = { currentView: 'AD_LOGIN' }
    }
    showCreateAccount = () => {
        this.setState({ currentView: 'CREATE' })
    }
    showForgotPassword = () => {
        this.setState({ currentView: 'PASSWORD_RECOVERY' })
    }
    showLogin = () => {
        this.setState({ currentView: 'AD_LOGIN' })
    }
    render() {
        const { show, onClose, isLogging } = this.props

        // console.log(this.state)

        let form = null

        if (this.state.currentView === 'CREATE') {
            form = <CreateAccountForm showLogin={this.showLogin} onCreateAccount={this.props.onCreateAccount} />
        } else if (this.state.currentView === 'PASSWORD_RECOVERY') {
            form = <ForgotPasswordForm showLogin={this.showLogin} onRecoverPassword={this.props.onRecoverPassword} />
        } else if (this.state.currentView === 'MANDATORY_CHANGE_PASSWORD') {
            form = <MandatoryChangePasswordForm onMandatoryChangePassword={this.props.onMandatoryChangePassword} />
        } else {
            form = (
                <SignInForm
                    isLogging={this.props.isLogging}
                    isLogged={this.props.isLogged}
                    onLogin={this.props.onLogin}
                    showForgotPassword={this.showForgotPassword}
                    showCreateAccount={this.showCreateAccount}
                    showLogin={this.showLogin}
                    callback={this.props.callback}
                    callbackCenter={this.props.callbackCenter}
                    callbackSalesOrg={this.props.callbackSalesOrg}
                    alert={this.props.alert}
                />
            )
        }
        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                <div id="modal-login-container" className="modal-login" style={{ display: show ? 'block' : 'none' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-5 logo-container d-none d-lg-flex flex-column p-0">
                                <div>
                                    <img alt="" id="profile-img-fixed" className="profile-img-card" src={logo} />
                                    <div className="my-4 horizontal-line" />
                                    {/* <span className="text-left px-5 mt-2" style={{ color: 'black', fontWeight: '700' }}>
                                        <Translation id="new_changes" defaultMessage="New changes!" />
                                    </span>
                                    <div className="px-5 mt-3" style={{ fontWeight: '400' }}>
                                        <p>
                                            <FormattedHTMLMessage id="unify_advise" defaultMessage="We are unifying access to all our Cosentino platforms to improve:" />
                                        </p>
                                        <ul className="pl-4 list-unstyled">
                                            <li className="mb-2">
                                                - <Translation id="security_matters" defaultMessage="Security matters" />
                                            </li>
                                            <li className="mb-2">
                                                - <Translation id="ease_of_use" defaultMessage="Ease of use" />
                                            </li>
                                        </ul>
                                    </div> */}
                                    <span className="text-left px-5 mt-2" style={{ color: 'black', fontWeight: '700' }}>
                                        <Translation id="how_to_acces_now" defaultMessage="How to access now?" />
                                    </span>
                                    <div className="px-5 mt-3 mb-5" style={{ fontWeight: '400' }}>
                                        <ul className="pl-4 list-unstyled">
                                            <li className="mb-2">
                                                - <Translation id="use_the_new_access" defaultMessage="Use the new access" />
                                            </li>
                                            <li className="mb-2">
                                                - <Translation id="enter_your_work_email_address" defaultMessage="Enter your work email address" />
                                            </li>
                                            <li className="mb-2">
                                                - <Translation id="enter_your_current_password" defaultMessage="Enter your current password" />
                                            </li>
                                            <li>
                                                - <Translation id="your_account_will_be_updated_automatically" defaultMessage="Your account will be updated automatically" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <div className="sky mt-auto text-center py-5" style={{"color":"black","fontWeight":"400"}}>
                                    <p className="mb-0">
                                        <FormattedHTMLMessage id="dont-have-we" defaultMessage="Don't have a <strong>Cosentino We</strong> account yet?"/>
                                    </p>
                                    <a href="#" style={{"color":"#235F7D"}}>
                                        <Translation id="create-account-now" defaultMessage="Create an account now!"/>
                                    </a>
                                </div> */}
                            </div>
                            <div className="col-12 col-lg-7 card-container">
                                <header className="container-fluid">
                                    <div className="row">
                                        <div className="col-3 offset-9 my-auto text-right">
                                            <div className="bt-close" onClick={onClose}>
                                                <i className="fal fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <div className="col-12" style={{ justifyContent: 'center', aligItems: 'center', display: 'flex', flexDirection: 'column', minHeight: '70%' }}>
                                    {form}
                                </div>
                                <footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(LoginModal)
