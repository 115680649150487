import React from 'react'

import { defineMessages, injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import { getStatusMap } from '../../store/orders/reducers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

defineMessages({
    //1
    pendiente_de_crear: {
        id: 'pendiente_de_crear',
        description: 'pendiente_de_crear',
        defaultMessage: 'Pending to create'
    },
    //2
    pedido_creado: {
        id: 'pedido_creado',
        description: 'pedido_creado',
        defaultMessage: 'Order created'
    },
    //3
    aprobado: {
        id: 'aprobado',
        description: 'aprobado',
        defaultMessage: 'Approved'
    },
    //4
    preparado_para_carga: {
        id: 'preparado_para_carga',
        description: 'preparado_para_carga',
        defaultMessage: 'Prepared for loading'
    },
    //5
    salida_de_mercancías_realizada: {
        id: 'salida_de_mercancías_realizada',
        description: 'salida_de_mercancías_realizada',
        defaultMessage: 'Goods issue made'
    },
    entregado: {
        id: 'entregado',
        description: 'entregado',
        defaultMessage: 'Delivered'
    },
    //6
    facturado_finalizado: {
        id: 'facturado_finalizado',
        description: 'facturado_finalizado',
        defaultMessage: 'Billed / Finished'
    },
    //7
    reserva_cancelada: {
        id: 'reserva_cancelada',
        description: 'reserva_cancelada',
        defaultMessage: 'Reservation canceled'
    },
    error_creating_order: {
        id: 'error_creating_order',
        description: 'error_creating_order',
        defaultMessage: 'Error creating order'
    },
    error_procesando_oferta: {
        id: 'error_procesando_oferta',
        description: 'error_procesando_oferta',
        defaultMessage: 'Error processing offer'
    },
    quote_rejected: {
        id: 'quote_rejected',
        description: 'quote_rejected',
        defaultMessage: 'Quote rejected'
    },

    //9
    cambios_pendientes: {
        id: 'cambios_pendientes',
        description: 'cambios_pendientes',
        defaultMessage: 'Pending changes'
    },
    oferta_creada: {
        id: 'oferta_creada',
        description: 'oferta_creada',
        defaultMessage: 'Offer created'
    },
    PENDING_CANCELLATION: {
        id: 'PENDING_CANCELLATION',
        description: 'PENDING_CANCELLATION',
        defaultMessage: 'Pending cancellation'
    },
    PENDING_ACCEPT_OFFER: {
        id: 'PENDING_ACCEPT_OFFER',
        description: 'PENDING_ACCEPT_OFFER',
        defaultMessage:"Quote in process to accept"
    },
    PENDING_CANCEL_OFFER: {
        id: 'PENDING_CANCEL_OFFER',
        description: 'PENDING_CANCEL_OFFER',
        defaultMessage: 'Quote in process to reject'
    },
    QUOTE_EXPIRED: {
        id: 'QUOTE_EXPIRED',
        description: 'QUOTE_EXPIRED',
        defaultMessage: 'Quote is expired'
    }
})
const mapStateToProps = (state, { match }) => {
    return {
        statusMap: getStatusMap(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}
class OrderStatus extends React.Component {
    render() {
        let { statusMap } = this.props
        const original = this.props.original
        if (this.props.rejected) {
            return (
                <div className="reject-col">
                    <div className="reject-indicator">
                        <i className="fa fa-times" /> <Translation id="order_rejected" defaultMessage="Order rejected" />
                    </div>
                </div>
            )
        }
        if (!statusMap[original.statusText]) return null
        return (
            <div>
                <div className="tracking-progress-bar">
                    {parseInt(statusMap[original.statusText].status, 10) < 7 && (
                        <div>
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                    ${
                                        parseInt(statusMap[original.statusText].status, 10) >= 1 && parseInt(statusMap[original.statusText].status, 10) < 7
                                            ? 'tracking-progress-bar__item--active'
                                            : null
                                    }`}
                            >
                                <i className="far fa-paper-plane pp-align-center" />
                            </div>

                            <span
                                className={`tracking-progress-bar__item__bar ${
                                    parseInt(statusMap[original.statusText].status, 10) >= 2 && parseInt(statusMap[original.statusText].status, 10) < 7
                                        ? 'tracking-progress-bar__item__bar--active'
                                        : null
                                }`}
                            />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${
                                                                parseInt(statusMap[original.statusText].status, 10) >= 3 && parseInt(statusMap[original.statusText].status, 10) < 7
                                                                    ? 'tracking-progress-bar__item--active'
                                                                    : null
                                                            }`}
                            >
                                <i className="far fa-thumbs-up" />
                            </div>

                            <span
                                className={`tracking-progress-bar__item__bar ${
                                    parseInt(statusMap[original.statusText].status, 10) >= 4 && parseInt(statusMap[original.statusText].status, 10) < 7
                                        ? 'tracking-progress-bar__item__bar--active'
                                        : null
                                }`}
                            />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${
                                                                parseInt(statusMap[original.statusText].status, 10) >= 5 && parseInt(statusMap[original.statusText].status, 10) < 7
                                                                    ? 'tracking-progress-bar__item--active'
                                                                    : null
                                                            }`}
                            >
                                <i className="fas fa-truck pp-align-center" />
                            </div>
                            <span
                                className={`tracking-progress-bar__item__bar ${
                                    parseInt(statusMap[original.statusText].status, 10) >= 6 && parseInt(statusMap[original.statusText].status, 10) < 7
                                        ? 'tracking-progress-bar__item__bar--active'
                                        : null
                                }`}
                            />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${
                                                                parseInt(statusMap[original.statusText].status, 10) >= 6 && parseInt(statusMap[original.statusText].status, 10) < 7
                                                                    ? 'tracking-progress-bar__item--active'
                                                                    : null
                                                            }`}
                            >
                                <i className="fas fa-file" />
                            </div>
                        </div>
                    )}
                    {parseInt(statusMap[original.statusText].status, 10) >= 7 && parseInt(statusMap[original.statusText].status, 10) < 9 && (
                        <div className="error">
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${parseInt(statusMap[original.statusText].status, 10) === 7 ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="far fa-paper-plane pp-align-center" />
                            </div>

                            <span
                                className={`tracking-progress-bar__item__bar ${
                                    parseInt(statusMap[original.statusText].status, 10) === 7 ? 'tracking-progress-bar__item__bar--active' : null
                                }`}
                            />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first 
                                                            ${parseInt(statusMap[original.statusText].status, 10) === 7 ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="far fa-thumbs-down .td-align-center" />
                            </div>
                        </div>
                    )}
                    {parseInt(statusMap[original.statusText].status, 10) >= 9 && parseInt(statusMap[original.statusText].status, 10) < 20 &&(
                        <div className="update">
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${parseInt(statusMap[original.statusText].status, 10) === 9 ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="far fa-sync icon-align-vertical td-align-center" />
                            </div>
                        </div>
                    )}
                    {parseInt(statusMap[original.statusText].status, 10) === 20 && (
                        <div className="update">
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first tracking-progress-bar__item--active`}
                            >
                                <i className="fas fa-ban icon-align-vertical td-align-center" />
                            </div>
                        </div>
                    )}
                </div>
                <span>
                    {this.props.intl.formatMessage({
                        id: statusMap[original.statusText].textToShow
                    })}{' '}
                    {/* ({original.statusText}) */}
                </span>
            </div>
        )
    }
}

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(OrderStatus)
    )
)
