import React from 'react'
import Translation from './Translation'

import { injectIntl } from 'react-intl'

import Button from './Button'
import Modal from './Modal'
import { connect } from 'react-redux'
import { getEmployeeId } from '../../store/big-account/reducers'
import { getIsIkeaCenter } from '../../store/centers/reducers'
import { emptyCart, fetchCart } from '../../store/cart/actions'
import Spinner from './Spinner'
import mallDiscount from '../../assets/img/logos/mall-discount-bg-green.svg'
import { showIncompatibleTypesExceptionModal } from '../../store/cart/reducers'

const mapStateToProps = state => {
    return {
        showIncompatibleTypesExceptionModal: showIncompatibleTypesExceptionModal(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        emptyCart: () => dispatch(emptyCart()),
        toggleIncompatibleTypesExceptionModal: open => Promise.resolve(dispatch({ type: 'TOGGLE_INCOMPATIBLE_TYPES_EXCEPTION_MODAL', open: open }))
    }
}
class IncompatibleTypesExceptionModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false }
    }

    render() {
        const { emptyCart, toggleIncompatibleTypesExceptionModal, showIncompatibleTypesExceptionModal } = this.props

        if (!showIncompatibleTypesExceptionModal) {
            return null
        }
        return (
            <div className="container">
                <Modal
                    // title={

                    // }
                    customClass={'mall-discount'}
                    footer={
                        <div className="modal-footer__buttons row" style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
                            <Button
                                onClick={() => {
                                    emptyCart()
                                    toggleIncompatibleTypesExceptionModal()
                                }}
                                size="medium"
                            >
                                <Translation id="yes" defaultMessage="Yes" />,{' '}
                                <span style={{ textTransform: 'lowercase' }}>
                                    <Translation id="empty_cart" defaultMessage="Empty cart" />
                                </span>
                            </Button>

                            <Button onClick={() => toggleIncompatibleTypesExceptionModal()} inverted size="medium">
                                <Translation id="no" defaultMessage="No" />,{' '}
                                <span style={{ textTransform: 'lowercase' }}>
                                    <Translation id="keep_buying" defaultMessage="Keep Buying" />
                                </span>
                            </Button>
                        </div>
                    }
                    onClose={() => toggleIncompatibleTypesExceptionModal()}
                >
                    <div className="container">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                            <img alt="" src={mallDiscount} className="mall-discount-image" />
                        </div>

                        <Spinner isLoading={this.state.isLoading} />
                        <div className="row" style={{ textAlign: 'center' }}>
                            <div className="col-12">
                                <Translation
                                    id="mall_cart_type_incompatible_exception_header"
                                    defaultMessage="It is not possible to combine Mall products and normal items from your portfolio in the same cart."
                                />
                            </div>

                            <div className="col-12">
                                <strong style={{ fontWeight: 600 }}>
                                    <Translation id="mall_cart_type_incompatible_exception_body" defaultMessage="Do you want to empty your current cart?" />
                                </strong>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(IncompatibleTypesExceptionModal)
)
