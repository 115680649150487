import React from 'react'

import { injectIntl } from 'react-intl'

class DisplayOutletIndicator extends React.Component {
    
    render() {
        if (this.props.outletQuality)
            return (
                <div className="outlet-indicator" style={{height:'fit-content'}}>
                    {this.props.outletQuality &&
                        this.props.intl.formatMessage({
                            id: `outlet_${this.props.outletQuality.toLowerCase()}`
                        })}{' '}
                    {this.props.outletDeviated &&
                        this.props.intl.formatMessage({
                            id: 'deviated'
                        })}
                </div>
            )
        else return null
    }
}
export default injectIntl(DisplayOutletIndicator)
