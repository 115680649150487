import { connect } from 'react-redux'
import { getCenterData, getShippingMethod } from '../../store/centers/reducers'
import { changeShippingMethod } from '../../store/centers/actions'
import ShippingMethods from './ShippingMethods'
import { getEndiShippingTypeByShippingAddress } from '../../store/shipping-addresses/reducers'

const mapStateToProps = state => {
    return {
        shippingMethods: getCenterData(state).shippingTypes,
        selectedShippingMethod: getShippingMethod(state),
        selectedEndiShippingMethod: getEndiShippingTypeByShippingAddress(state)
    }
}

const ShippingMethodsContainer = connect(
    mapStateToProps,
    { onChangeShippingMethod: changeShippingMethod }
)(ShippingMethods)

export default ShippingMethodsContainer
