import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

const initState = {
    showHelp: false
}
class MoreInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = initState
    }
    componentDidMount() {
        if (this.props.showHelp !== undefined) this.setState({ showHelp: this.props.showHelp })
        if (this.props.static) this.setState({ showHelp: true })
    }
    render() {
        return (
            <div>
                <div className={`catalog_info info ${!this.state.showHelp ? 'catalog_info--hidden' : ''}`}>
                    {!this.props.static && (
                        <button
                            onClick={() => this.setState({ showHelp: !this.state.showHelp })}
                            className="btn btn-cerrar btn_close_lowes_need_help"
                            data-role="market-selector-switcher"
                        />
                    )}
                    {this.props.children}
                </div>
                {!this.props.static && (
                    <BtnNeedHelp
                        showHelp={this.state.showHelp}
                        moreInfoLowes={this.props.moreInfoLowes}
                        changeLog={this.props.changeLog}
                        btnText={this.props.btnText ? this.props.intl.formatMessage({ id: this.props.btnText }) : this.props.btnText}
                        onClick={() => {
                            this.setState({ showHelp: !this.state.showHelp })
                        }}
                    />
                )}
            </div>
        )
    }
}

const BtnNeedHelp = ({ showHelp, moreInfoLowes, moreInfoOutlet, changeLog, onClick, btnText }) => {
    if (btnText)
        return (
            <div className="button_info button_info--left" onClick={onClick}>
                <i className="fal fa-info-circle" style={{ margin: '5px' }} />
                <span>{btnText}</span>
            </div>
        )
    if (moreInfoLowes)
        return !showHelp ? (
            <div className="button_info" onClick={onClick}>
                <i className="fal fa-info-circle" style={{ margin: '5px' }} />
                <Translation id="need_help" defaultMessage="Need help?" />
            </div>
        ) : (
            <div className="button_info">
                <a href="mailto:lowescustomercare@cosentino.com">
                    <i className="fal fa-info-circle" style={{ margin: '5px' }} />
                    <Translation id="need_more_help" defaultMessage="Need more help?" />
                </a>
            </div>
        )
    else if (moreInfoOutlet)
        return !showHelp ? (
            <div className="button_info" onClick={onClick}>
                <i className="fal fa-info-circle" style={{ margin: '5px' }} />
                <Translation id="need_help" defaultMessage="Need help?" />
            </div>
        ) : (
            <div className="button_info">
                <a href="mailto:ProductOutlet@cosentino.com; cmartinez@cosentino.com">
                    <i className="fal fa-info-circle" style={{ margin: '5px' }} />
                    <Translation id="need_more_help" defaultMessage="Need more help?" />
                </a>
            </div>
        )
    else if (changeLog)
        return (
            <div className="button_info" style={{ left: 'initial' }} onClick={onClick}>
                <i class="fal fa-info-circle" style={{ margin: '5px' }} />
                <Translation id="plus_info" defaultMessage="+ Info" />
            </div>
        )
    else
        return (
            <div className="button_info" onClick={onClick}>
                <i className="fal fa-info-circle" style={{ margin: '5px' }} />
                <Translation id="need_help" defaultMessage="Need help?" />
            </div>
        )
}

export default injectIntl(MoreInfo)
