export const initState = {
    delivery: [],
    isLoading: false,
    error: null,
}

const delivery = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ORDER_DELIVERY_ID_REQUEST':
            return Object.assign({}, state, { isLoading: true })

        case 'FETCH_ORDER_DELIVERY_ID_SUCCESS':
            return Object.assign({}, state, {
                delivery: action.delivery,
                isLoading: false
            })

        case 'FETCH_ORDER_DELIVERY_ID_FAILURE':
            return Object.assign({}, state, { isLoading: false })

        default:
            return state
    }
}

export default delivery

export const getDelivery = state => {
    return state.delivery.delivery
}

export const getDeliveryIsLoading = state => {
    return state.delivery.isLoading
}