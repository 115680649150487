import React from 'react'

import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'

const mapStateToProps = state => {
    return { userPreferences: getProfileConfiguration(state) }
}
const LengthMeasureTranslation = ({ userPreferences, intl, square, smaller }) => {
    const meters = smaller ? 'cm' : 'm'
    const feets = smaller ? 'in' : 'ft'
    const lengthUnitTranslationPrefer = userPreferences.lengthUnit === 'M2' ? meters : feets
    const lengthUnitTranslationDefault = intl.locale === 'en-US' || intl.locale === 'fr-CA' || intl.locale === 'en-CA' ? feets : meters
    const lengthUnitTranslation = userPreferences.lengthUnit ? lengthUnitTranslationPrefer : lengthUnitTranslationDefault
    return (
        <React.Fragment>
            {lengthUnitTranslation}
            {square && <sup>2</sup>}
        </React.Fragment>
    )
}
export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(LengthMeasureTranslation)
)
