import Translation from '../global/Translation'
import React from 'react'
import { injectIntl } from 'react-intl'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import IntegerInputNumber from '../input-number-other/IntegerInputNumber'
import LocalizedLink from '../global/LocalizedLink'
import noBundleImage from '../../assets/img/no-image-bundle.png'
import cameraOff from '../../assets/img/icons/camera-off.svg'
import DisplayNaturalStoneOrigin from '../catalog/rectangleResult/displays/DisplayNaturalStoneOrigin'
import { rejectionCodeMap, getBundleRejectionCode } from './utils'

class LotGridCell extends React.Component {
    render() {
        const {
            product,
            centerId,
            defaultImage,
            lot,
            isAdmin,
            impersonatedBy,
            outlet,
            userType,
            width,
            length,
            surface,
            getMinimumValue,
            isLot,
            lotImage,
            maxQuantity,
            unit,
            lunit,
            setQuantity,
            addToCart,
            canViewLotId,
            isUsaOrCanada,
            selectedProduct,
            intl,
            bundlesRejectionCodes
        } = this.props

        let _defaultImage = defaultImage
        if (!_defaultImage) {
            if (!isLot) _defaultImage = noBundleImage
            else _defaultImage = cameraOff
        }

        return (
            <div className="lots__grid__cell__container">
                <div className="wrapper-product-image">
                    {lotImage ? (
                        <LocalizedLink
                            routeId="ROUTE_LOT_IMAGE_VIEWER"
                            params={{
                                parentUrl: window.location.href,
                                productId: product.productId,
                                lotId: lot.lotId,
                                center: centerId,
                                quarryBlock: lot.quarryBlock.replace('/', '-'),
                                quality: lot.quality.replace('/', '-'),
                                outlet: outlet ? outlet : false,
                                position: 'relative'
                            }}
                        >
                            <div
                                className="magnifier"
                                style={{
                                    backgroundImage: `url(${lotImage})`,
                                    height: '190px',
                                    backgroundSize: '150%',
                                    backgroundPosition: 'center',
                                    borderRadius: '4px 4px 0 0',
                                    position: 'relative'
                                }}
                            >
                                {lot.qualityOutlet && (
                                    <span className="outlet__indicator">
                                        {(outlet || true) && (
                                            <DisplayOutletIndicator
                                                outletQuality={lot.qualityOutlet} //{this.product.outletQuality}
                                                outletDeviated={isAdmin || impersonatedBy ? product.outletDeviated : null}
                                            />
                                        )}
                                    </span>
                                )}
                            </div>
                            <i className="fas fa-search" style={{ position: 'absolute', top: '16px', right: '16px', fontSize: '22px', color: '#ffffff' }} />
                        </LocalizedLink>
                    ) : (
                        <div
                            className="tooltip-container magnifier-withouth-image"
                            style={{
                                backgroundImage: `url(${_defaultImage})`,
                                height: '190px',
                                backgroundSize: `${defaultImage ? 'cover' : isLot ? '30%' : 'contain'}`,
                                backgroundPosition: 'center',
                                borderRadius: '4px 4px 0 0',
                                backgroundColor: '#d8d8d8',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            {!defaultImage && (
                                <span className="tooltip plus">
                                    <Translation id="image-no-available" defaultMessage="Sorry, the image is not available" />
                                </span>
                            )}
                        </div>
                    )}

                    {/*<AddFavourite productId={product.productId}/>*/}
                </div>

                {/*********** INFO SECTION ***********/}
                <div className="lots__grid__cell__container__info--content">
                    <div className="lots__grid__header">
                        {isLot ? (
                            <div className="lot-name">
                                <h4 className={lot.bundleId ? 'm-0' : ''}>
                                    <Translation id="lot" defaultMessage="Lot" /> {canViewLotId ? lot.lotId : ''}
                                </h4>
                                {lot.bundleId && <h6>Bundle ID {lot.bundleId}</h6>}
                            </div>
                        ) : (
                            <div className="lot-name">
                                <h4>Bundle ID {lot.bundleId}</h4>
                                {this.props.isLatinaVitoria
                                    ? lot.lots && (
                                          <h6 className="is-latina-victoria-view-lots"
                                              onClick={() => this.props.openBundleContent(lot)}
                                          >
                                              <Translation id="view_lots" defaultMessage="View lots" />
                                          </h6>
                                      )
                                    : lot.lotId && canViewLotId && (
                                          <h6>
                                              <Translation id="lot" defaultMessage="Lot" /> {lot.lotId}
                                          </h6>
                                    )}
                            </div>
                        )}

                        <div className="quarry-block">
                            <span>
                                <Translation id="quarryBlock" defaultMessage="Quarry Block" />: {lot.quarryBlock}
                            </span>
                        </div>
                    </div>

                    {/* Divider */}
                    <div className="w-100 py-3">
                        <div
                            style={{
                                width: '100%',
                                height: '1px',
                                backgroundColor: '#D8D8D8'
                            }}
                        />
                    </div>

                    <div className="middle-details-content">
                        {(!isUsaOrCanada || isAdmin) && (
                            <div className="row__lot__info">
                                <div className="row__lot__info--key">
                                    <span>
                                        <Translation id="quality" defaultMessage="Quality" />
                                    </span>
                                    :
                                </div>
                                <div className="row__lot__info--value">
                                    <span>{lot.quality}</span>
                                </div>
                            </div>
                        )}

                        {(isAdmin || impersonatedBy) && (
                            <React.Fragment>
                                <div className="row__lot__info">
                                    <div className="row__lot__info--key">
                                        <span>
                                            <Translation id="shade" defaultMessage="Shade" />
                                        </span>
                                        :
                                    </div>
                                    <div className="row__lot__info--value">
                                        <span>{lot.shade}</span>
                                    </div>
                                </div>
                                <div className="row__lot__info">
                                    <div className="row__lot__info--key">
                                        <span>
                                            ID <Translation id="location" defaultMessage="Location" />
                                        </span>
                                        :
                                    </div>
                                    <div className="row__lot__info--value">
                                        <span>{lot.location}</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                        <div className="row__lot__info">
                            {isLot ? (
                                <div className="row__lot__info--key">
                                    <span>
                                        <Translation id="size" defaultMessage="Size" />
                                    </span>
                                    :
                                </div>
                            ) : (
                                <div className="row__lot__info--key">
                                    <span>
                                        <Translation id="main_size" defaultMessage="Main size" />
                                    </span>
                                    :
                                </div>
                            )}
                            <div className="row__lot__info--value">
                                <span>
                                    {length} x {width} {lunit()}
                                </span>
                            </div>
                        </div>

                        <div className="row__lot__info">
                            {isLot ? (
                                <div className="row__lot__info--key">
                                    <span>
                                        <Translation id="surface" defaultMessage="Surface" />
                                    </span>
                                    :
                                </div>
                            ) : (
                                <div className="row__lot__info--key">
                                    <span>
                                        <Translation id="main_surface" defaultMessage="Main surface" />
                                    </span>
                                    :
                                </div>
                            )}
                            <div className="row__lot__info--value">
                                <span>
                                    {surface} {unit}2
                                </span>
                            </div>
                        </div>

                        <div className="row__lot__info">
                            <div className="row__lot__info--key">
                                <span>
                                    <Translation id="units" defaultMessage="Units" />
                                </span>
                                :
                            </div>
                            <div className="row__lot__info--value">
                                <span>{maxQuantity}</span>
                            </div>
                        </div>

                        <DisplayNaturalStoneOrigin product={product} isLotGridView={true} />
                    </div>

                    {/* Rejection code when is not Bundle, that means when is only lot */}
                    {isLot && lot.rejectionCode && (
                        <React.Fragment>
                            <div className="w-100 py-3">
                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: '#D8D8D8'
                                    }}
                                />
                            </div>

                            <div className="row_lot__info__status">
                                <div className="row_lot__info__status--icon">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <div className="row_lot__info__status--text">
                                    <div>
                                        <span className="main-text">
                                            <Translation id="material_status_information" defaultMessage="Material status information" />
                                        </span>
                                        :&nbsp;
                                    </div>
                                    <div>
                                        <span className="categories">{rejectionCodeMap[lot.rejectionCode]}</span>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    {/* Rejection code when is Bundle, that means when is not only lot */}
                    {!isLot && getBundleRejectionCode(lot, bundlesRejectionCodes) && (
                        <React.Fragment>
                            <div className="w-100 py-3">
                                <div
                                    style={{
                                        width: '100%',
                                        height: '1px',
                                        backgroundColor: '#D8D8D8'
                                    }}
                                />
                            </div>

                            <div className="row_lot__info__status">
                                <div className="row_lot__info__status--icon">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <div className="row_lot__info__status--text">
                                    <div>
                                        <span className="main-text">
                                            <Translation id="material_status_information" defaultMessage="Material status information" />
                                        </span>
                                        :&nbsp;
                                    </div>
                                    <div>
                                        <span className="categories">{rejectionCodeMap[getBundleRejectionCode(lot, bundlesRejectionCodes)]}</span>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    {/* Divider */}
                    <div className="w-100 py-3">
                        <div
                            style={{
                                width: '100%',
                                height: '1px',
                                backgroundColor: '#D8D8D8'
                            }}
                        />
                    </div>
                </div>
                {!isAdmin && (
                    <div className="lots__grid__cell__container__info--buy">
                        <IntegerInputNumber min={getMinimumValue()} max={maxQuantity} onChangeValue={v => setQuantity(v)} />
                        <button className="lots__grid__cell__container__info--btn" onClick={() => addToCart()} gtm-label="ec_comprar_cuadricula">
                            {userType === 'SHOP' ? <Translation id="reserve" defaultMessage="Reserve" /> : <Translation id="buy" defaultMessage="Buy" />}
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

export default injectIntl(LotGridCell)
