/**
 * Order status for order history
 */
export const OrderStatus = {
    Created: 'CREATED',
    Approved: 'APPROVED',
    Pending: 'PENDING',
    In_process: 'IN_PROCESS',
    Partially_delivered: 'PARTIALLY_DELIVERED',
    Delivered: 'DELIVERED',
    Order_cancelled: 'ORDER_CANCELLED'
}