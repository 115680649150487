import React from 'react'

import { connect } from 'react-redux'
import Translation from './Translation'
import Select from 'react-select'
import { getShippingAddressFilteredToSelectByCenter, isLoadingShippingAddress } from '../../store/shipping-addresses/reducers'
import { injectIntl } from 'react-intl'
import Button from './Button'
import { changeEndiShippingAddress } from '../../store/shipping-addresses/actions'
import { logout } from '../../store/login/actions'
import Spinner from './Spinner'

const mapStateToProps = state => {
    return {
        shippingAddressesFilteredByCenter: getShippingAddressFilteredToSelectByCenter(state),
        isLoading: isLoadingShippingAddress(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        changeShippingAddress: shippingAddressEcosentinoKey => {
            dispatch(changeEndiShippingAddress(shippingAddressEcosentinoKey))
        },
        logout: () => dispatch(logout())
    }
}
class SelectShippingAddress extends React.Component {
    state = {
        shippingAddressEcosentinoKey: null
    }
    changeShippingAddress(shippingAddressEcosentinoKey) {
        this.setState({ shippingAddressEcosentinoKey: shippingAddressEcosentinoKey })
    }
    render() {
        return (
            <div className={'remember-select-center card h-25 p-4'}>
                <h3>
                    <Translation
                        id="please_select_your_shipping_address_to_continue_with_your_reservation"
                        defaultMessage="Please select your shipping address to continue with your reservation"
                    />
                </h3>
                {this.props.isLoading ? (
                    <div className="my-4">
                        <Spinner isLoading={this.props.isLoading} relative />
                    </div>
                ) : this.props.shippingAddressesFilteredByCenter.length ? (
                    <Select
                        className="form-control"
                        placeholder={`${this.props.intl.formatMessage({ id: 'select_shipping_address' })}...`}
                        value={this.state.shippingAddressEcosentinoKey}
                        onChange={e => {
                            e && this.changeShippingAddress(e.value)
                        }}
                        options={this.props.shippingAddressesFilteredByCenter}
                        clearable={false}
                    />
                ) : (
                    <div style={{ fontSize: '1rem' }}>
                        <p style={{ fontSize: '1.2em' }}>
                            <Translation
                                id={'your_shipping_address_is_not_valid_please_contact_your_cosentino_admin'}
                                defaultMessage={'Your shipping address is not valid. Please contact your Cosentino admin'}
                            />
                        </p>
                        <p style={{ fontSize: '1em' }}>
                            <Translation id={'please_change_center'} defaultMessage={'Please change center'} />
                        </p>
                    </div>
                )}
                {!this.props.isLoading && (
                    <div className="d-flex h-100 justify-content-end align-items-end pt-2">
                        {this.props.shippingAddressesFilteredByCenter.length ? (
                            <Button disabled={!this.state.shippingAddressEcosentinoKey} onClick={() => this.props.changeShippingAddress(this.state.shippingAddressEcosentinoKey)}>
                                <i className="fa fa-check" /> <Translation id="accept" />
                            </Button>
                        ) : (
                            <Button onClick={() => this.props.logout()}>
                                <i className="fa fa-times" /> <Translation id="logout" />
                            </Button>
                        )}
                    </div>
                )}
            </div>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SelectShippingAddress)
)
