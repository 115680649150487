import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import BusinessSegment from './BusinessSegment'
import Channel from './Channel'
import Typology from './Typology'
import Use from './Use'
import SubChannel from './SubChannel'
import KB from './KB'
import Builders from './Builders'
import Modal from '../global/Modal'
import confirm from '../global/confirm'
import Spinner from '../global/Spinner'
import Alerts from '../alerts/Alerts'

const confirmDeleteSegmentation = (currentFavourite, deletePreferredSegmentation, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM' }), { intl: intl }).then(
        confirm => {
            return deletePreferredSegmentation(currentFavourite)
        },
        cancel => {
            return Promise.reject()
        }
    )
}
class Segmentation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            saveSegmentationPromptOpen: false,
            active: null,
            excuse: null
        }
    }
    clearSegmentation = () => {
        this.setState({ active: null })
        this.props.clearSegmentation()
    }
    deleteSegmentation = async currentFavourite => {
        confirmDeleteSegmentation(currentFavourite, this.props.deletePreferredSegmentation, this.props.intl).then(() => {
            if (currentFavourite === this.state.active) this.setState({ active: null })
        })
    }
    loadSegmentation = name => {
        this.props.loadPreferredSegmentation(name)
        this.setState({ active: name })
    }
    saveSegmentation = name => {
        this.props.saveSegmentationPreferred(name)
        this.setState({ saveSegmentationPromptOpen: false, active: name })
    }
    handleSubmit() {
        const { callback } = this.props
        if (this.poInput) {
            const po = this.poInput.value.split(';')
            let poTrue = po.filter(function (poItem) {
                return poItem !== ''
            })
            this.props.setPO(poTrue)
            this.poInput.value = ''
        }
        this.props.onSubmitSegmentation(callback)
    }

    canAddMaterial() {
        if (this.props.isExclusiveHomeDepot) {
            // if (exclusiveMaterialsHomeDepot.includes(this.props.segmentatingProductId)) {
            if (!this.props.validHomeDepotSegmentation) {
                if (!this.state.excuse) this.setState({ excuse: 'EXCLUSIVE_HOME_DEPOT' })
                return false
            }
        }
        if (this.state.excuse) this.setState({ excuse: null })
        return true
    }
    componentDidMount() {
        this.props.fetchSegmentation()
    }
    render() {
        const { modal, businessSegment, channel, subchannel, use, useOptions, buildersOptions, mode, po } = this.props
        const checkBS = ['NOT', 'KB', 'BU', 'CO', 'RE', null]
        const useValidate = (useOptions !== undefined && use) || useOptions === undefined ? true : false
        const builderValidate = (buildersOptions !== undefined && use) || buildersOptions === undefined ? true : false
        const formIsFullfilled = businessSegment && channel && useValidate && builderValidate
        const submitButton =
            formIsFullfilled && this.canAddMaterial() ? (
                <Button onClick={() => this.handleSubmit()} inverted>
                    {mode === 'edit' ? <Translation id="edit" defaultMessage="Edit" /> : <Translation id="add_to_cart" defaultMessage="Add to cart" />}
                </Button>
            ) : (
                <Button disabled inverted>
                    {mode === 'edit' ? <Translation id="edit" defaultMessage="Edit" /> : <Translation id="add_to_cart" defaultMessage="Add to cart" />}
                </Button>
            )
        return (
            <div>
                {(this.props.isSaving || this.props.isLoading) && <Spinner isLoading={this.props.isSaving || this.props.isLoading} />}

                <div className="container-fluid">
                    {this.state.excuse && <Alerts alert={this.state.excuse} status="warning" />}
                    <br />
                    <div className="row">
                        {this.props.preferredSegmentation && (
                            <div className="col-12 col-lg-4">
                                <div className="form-group">
                                    <h6>
                                        <Translation id="saved_segmentations" defaultMessage="Saved segmentations" />
                                    </h6>
                                    <div className="saved-segmentations">
                                        {this.props.preferredSegmentation.map(option => (
                                            <div className="row" onClick={selectedOption => this.loadSegmentation(option.name)} key={option.name}>
                                                <div className="col-7 col-md-9 col-lg-6" onClick={selectedOption => this.loadSegmentation(option.name)}>
                                                    <span className={option.name === this.state.active ? 'active' : ''}>{option.name}</span>
                                                </div>
                                                <div className="bt-delete col-5 col-md-3 col-lg-6 text-right">
                                                    <i
                                                        className="fal fa-clone bt-delete cy-bt-duplicate"
                                                        data-cy="modal_segmentation__bt_segmentation_duplicate"
                                                        onClick={() => this.setState({ saveSegmentationPromptOpen: true })}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <i
                                                        className="fal fa-check bt-delete cy-bt-ok"
                                                        data-cy="modal_segmentation__bt_segmentation_check"
                                                        onClick={selectedOption => this.loadSegmentation(option.name)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    <i
                                                        className="fal fa-times bt-delete cy-bt-del"
                                                        data-cy="modal_segmentation__bt_segmentation_delete"
                                                        onClick={() => this.deleteSegmentation(option.name)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="form-group scroll">
                                    <div className="col-12">
                                        <BusinessSegment />
                                    </div>
                                    <div className="col-12">
                                        <Channel />
                                    </div>
                                    {channel != null && this.props.viewFullSegmentation && (
                                        <div className="col-12">
                                            <SubChannel />
                                        </div>
                                    )}
                                    {subchannel != null && this.props.viewFullSegmentation && (
                                        <div className="col-12">
                                            <Typology />
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <Use />
                                    </div>
                                    {businessSegment === 'KB' && (
                                        <div className="col-12">
                                            <KB />
                                        </div>
                                    )}
                                    {businessSegment === 'BU' && (
                                        <div className="col-12">
                                            <Builders />
                                        </div>
                                    )}
                                    {!checkBS.includes(businessSegment) && (
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="input-po">
                                                    <Translation id="insert_po" defaultMessage="Insert your PO#" />
                                                </label>

                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="1234;1235;1236"
                                                    ref={input => {
                                                        this.poInput = input
                                                    }}
                                                    key={po}
                                                    defaultValue={po}
                                                />
                                                <small>
                                                    <Translation id="add_more_than_one_po" defaultMessage="Add more than one email separating them by ';'" />
                                                </small>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer>
                        <div>
                            {formIsFullfilled ? (
                                <Button
                                    inverted
                                    onClick={
                                        this.state.active
                                            ? () => this.saveSegmentation(this.state.active)
                                            : () => {
                                                this.setState({ saveSegmentationPromptOpen: true })
                                            }
                                    }
                                >
                                    <Translation id="save" defaultMessage="Save" />
                                </Button>
                            ) : (
                                <Button inverted disabled>
                                    <Translation id="save" defaultMessage="Save" />
                                </Button>
                            )}
                        </div>
                        <div>
                            <Button inverted onClick={() => this.clearSegmentation()}>
                                <Translation id="clear_segmentation" defaultMessage="Clear segmentation" />
                            </Button>
                        </div>
                        {modal && <div>{submitButton}</div>}
                    </footer>
                </div>
                {this.state.saveSegmentationPromptOpen && (
                    <Modal
                        title={<Translation id="segmentation_prompt_title" defaultMessage="Saving segmentation" />}
                        onClose={() =>
                            this.setState({
                                saveSegmentationPromptOpen: false
                            })
                        }
                        footer={
                            <Button onClick={() => this.saveSegmentation(this.inputSegmentation.value)} inverted size="medium">
                                <Translation id="save" defaultMessage="Save" />
                            </Button>
                        }
                    >
                        <p>
                            <Translation id="segmentation_prompt_body" defaultMessage="Please choose a unique name for this configuration" />
                        </p>
                        <div className="form-group">
                            <b>
                                <small>
                                    <Translation id="name" defaultMessage="Name" />
                                </small>
                            </b>
                            <input
                                ref={ref => (this.inputSegmentation = ref)}
                                type="text"
                                className="form-control"
                                data-cy="modal_segmentation__input_name_save_segmentation"
                                placeholder={this.props.intl.formatMessage({
                                    id: 'name'
                                })}
                            />
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}
export default injectIntl(Segmentation)
