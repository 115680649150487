import { connect } from 'react-redux'
import { getIsLogged } from '../../store/login/reducers'
import Translation from "../global/Translation";
import React from "react";
import { canViewMktools } from "../../store/centers/reducers";
import {fetchRelatedProducts} from "../../store/crossSelling/actions";
import {getBoughtTogetherProducts, getCurrentCrossSelling} from "../../store/crossSelling/reducers";
import ProductsSlider from "./ProductsSlider";

const mapStateToProps = state => {
    const isLogged = getIsLogged(state)
    const getProducts = () => {
        if (isLogged) return getBoughtTogetherProducts(state)
        else return getCurrentCrossSelling(state).map(product => {return {product:product}})
    }

    return {
        products: getProducts(),
        hide: !getIsLogged(state) || canViewMktools(state),
        title: <Translation id="bought_together" defaultMessage="Bought together" />,
        customClass: 'products-similar-colors',
        analyticsTag: 'usually_purchased_cross',
        minWidth: 215,
        gtmLabel: 'ec_carrusel_usually_bought_together'
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetch: () => dispatch(fetchRelatedProducts(props.productId))
    }
}

const RelatedProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider)

export default RelatedProductsContainer
