import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import { showingSegmentationModal } from '../../store/ui/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import SegmentationContainer from './SegmentationContainer'

const mapStateToProps = state => {
    return {
        show: showingSegmentationModal(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeSegmentationModal())
        }
    }
}

class SegmentationModal extends React.Component {
    render() {
        const { show, onClose } = this.props

        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                <div id="modal-segmentation-container" className="modal-segmentation container segmentation" style={{ display: show ? 'block' : 'none' }}>
                    <header>
                        <div className="row">
                            <div className="col-9 my-auto">
                                <h4>
                                    <Translation id="segmentation" defaultMessage="Segmentation" />
                                </h4>
                            </div>
                            <div className="col-3 my-auto text-right">
                                <div className="bt-close" onClick={onClose}>
                                    <i className="fal fa-times" />
                                </div>
                            </div>
                        </div>
                    </header>
                    <div>
                        <SegmentationContainer modal />
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SegmentationModal)
)
