import React from 'react'

import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Button from './Button'
import { getProductsIdFavourite } from '../../store/products/reducers'
import { toogleFavourite, fetchProductsFavourite } from '../../store/products/actions'

const mapDispatchToProps = dispatch => {
    return {
        onToggleFavourite: productId => {
            dispatch(toogleFavourite(productId))
        },
        fetchProductsFavourite: () => {
            dispatch(fetchProductsFavourite())
        }
    }
}
const mapStateToProps = state => {
    return {
        productsFavourite: getProductsIdFavourite(state)
    }
}

class AddFavourite extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { productId, onToggleFavourite, intl } = this.props

        const isAdded = this.props.productsFavourite && this.props.productsFavourite.find(id => id === this.props.productId) !== undefined

        return (
            <Button
                icon="heart"
                far={isAdded ? undefined : true}
                size="small"
                inverted
                title={`${isAdded ? intl.formatMessage({ id: 'remove_favourite' }) : intl.formatMessage({ id: 'add_favourite' })}`}
                customClass={`bt-add-favourite ${isAdded ? 'isFavourite' : ''}`}
                datacy="global__favourite_heart"
                onClick={() => {
                    onToggleFavourite(productId)
                }}
            />
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddFavourite)
)
