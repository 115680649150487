import {getCanCancelPreorder, getPreOrderById, isLoading} from './../../store/cut-to-size/reducers';
import {fetchPreOrdersCts, sendPreorderMessage, sendApprovePreorderMessage} from './../../store/cut-to-size/actions';
import { connect } from 'react-redux'
import PreOrdersCtsDetails from './PreOrdersCtsDetails'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'

const mapStateToProps = (state, { match }) => {
    return {
        preOrder: getPreOrderById(state,match.params.open),
        isLoading: isLoading(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPreOrdersCts: (callback) => dispatch(fetchPreOrdersCts(callback)),
        sendMessage: (formData, callback) => dispatch(sendPreorderMessage(formData, callback)),
        sendApproveMessage: (formData, callback) => dispatch(sendApprovePreorderMessage(formData, callback))
    }
}

const PreOrdersCtsDetailsContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(PreOrdersCtsDetails)
    )
)

export default PreOrdersCtsDetailsContainer
