import React from 'react'
import Translation from '../global/Translation'

import { injectIntl, defineMessages } from 'react-intl'
import Spinner from '../global/Spinner'
import ProfileContainer from './ProfileContainer'
import Alerts from '../alerts/Alerts'
defineMessages({
    ROUTE_HOME: {
        id: 'repeat_password',
        description: 'repeat_password',
        defaultMessage: 'Repeat password'
    },
    STORE_INFO_NAME: {
        id: 'store_info_Name',
        description: 'Name',
        defaultMessage: 'Name'
    },
    STORE_INFO_SHIPPINGCOUNTRY: {
        id: 'store_info_Shippingcountry',
        description: 'Shippingcountry',
        defaultMessage: 'Country'
    },
    STORE_INFO_SHIPPINGSTREET: {
        id: 'store_info_Shippingstreet',
        description: 'Shippingstreet',
        defaultMessage: 'Street'
    },
    STORE_INFO_SHIPPINGCITY: {
        id: 'store_info_Shippingcity',
        description: 'Shippingcity',
        defaultMessage: 'City'
    },
    STORE_INFO_SHIPPINGSTATE: {
        id: 'store_info_Shippingstate',
        description: 'Shippingstate',
        defaultMessage: 'State'
    },
    STORE_INFO_POSTALCODES: {
        id: 'store_info_PostalcodeS',
        description: 'PostalcodeS',
        defaultMessage: 'ZP Code'
    },
    STORE_INFO_ZPHONE: {
        id: 'store_info_Zphone',
        description: 'phone',
        defaultMessage: 'Phone'
    }
})
class ProfileLowesConfiguration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            alert: { mayus: undefined, contain: undefined, number: undefined, minus: undefined, equal: undefined }
        }
    }

    componentDidMount() {
        // this.props.fetchProfileConfiguration()
    }

    render() {
        const { intl, isLoading } = this.props

        const spinner = isLoading ? <Spinner isLoading={isLoading} /> : null

        return (
            <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                    <ProfileContainer />
                </div>
                <div className="col-12 col-lg-9">
                    {isLoading ? (
                        spinner
                    ) : (
                        <div className="profile-form">
                            <div className="toolbar">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 ">
                                            <h4>
                                                <Translation id="store_info" defaultMessage="Store Info" />
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form user-form lowesStoreData">
                                <Alerts alert={this.props.alert} status={this.props.status} />
                                {this.props.lowesStoreData &&
                                    Object.keys(this.props.lowesStoreData).map(key => {
                                        return (
                                            <div className="row">
                                                <div className="offset-1 col-4">
                                                    <span className="lowesStoreData-label">{intl.formatMessage({ id: 'store_info_' + key })}:</span>
                                                </div>
                                                <div className="col-6">
                                                    <span className="lowesStoreData-value">{this.props.lowesStoreData[key]}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default injectIntl(ProfileLowesConfiguration)
