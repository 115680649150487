/**
 * Downloads a PDF from a given URL and saves it with a specified filename.
 * @param {string} url - The URL of the PDF to download.
 * @param {string} model - The model name to include in the filename.
 * @param {string} refcode - The refcode to include in the filename.
 */
export const handlePlanPdfDownload = (url, model, refcode) => {
    if (!url) return

    // Fetch the PDF file from the URL using fetch
    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('handlePlanPdfDownload -> Network response was not ok')
            }
            return response.blob() // Convert the response into a Blob object
        })
        .then(blob => {
            // Create a URL for the Blob object
            const downloadUrl = URL.createObjectURL(blob)

            // Create a temporary anchor element to initiate download
            const link = document.createElement('a')
            link.href = downloadUrl

            // Set the download filename
            const fileName = `${model}-${refcode}.pdf`
            link.setAttribute('download', fileName)

            // Append the link to the body and trigger the download
            document.body.appendChild(link)
            link.click()

            // Clean up: remove the link and revoke the object URL
            document.body.removeChild(link)
            URL.revokeObjectURL(downloadUrl)
        })
        .catch(error => {
            console.error('handlePlanPdfDownload -> Failed to download the file', error)
        })
}


export const getFormatConfigData = (config, isPlanAccepted, locale, units, planDocumentUrl, refcode) => {
    return {
        "config": {
            ...config.configuration,
            isPlanAccepted: isPlanAccepted,
            planDocumentUrl: planDocumentUrl,
            refcode: refcode
        },
        "colorId": config.configuration.material.color,
        "finish": config.configuration.material.finish,
        "brand": config.configuration.material.brand,
        "elaboration": 'CONFIGURABLE_BATH',
        "ref": config.reference,
        "lang": locale,
        "units": units,
        "colorName": config.configuration.material.colorName
    }
}

export const getCheckoutLineId = (checkoutLines, refcode) => {
    if (!refcode) {
        return null
    }

    const matchingLine = checkoutLines && checkoutLines.find(line =>
        line.cutToSize &&
        line.cutToSize.config &&
        line.cutToSize.ref &&
        line.cutToSize.ref === refcode
    )

    return matchingLine ? matchingLine.lineId : null
}

export const getConfigurablePlanPdfUrl = (configurableDrawingList, refcode) => {
    const configurableDrawing = configurableDrawingList && refcode && configurableDrawingList[refcode]

    return configurableDrawing && configurableDrawing.documentUrl
}