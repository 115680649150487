import React from "react";
import Button from "../../global/Button";
import Translation from "../../global/Translation";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {
    applyCampaignDiscount,
    checkPromotionId, fetchPromotionsList
} from "../../../store/promotions/actions";


class ApproveDiscount extends React.Component {

    lastInputId=0;

    state = {
        promotionIds: [],
        comments: ''
    }

    componentDidMount() {
        this.addPromotionId()
    }

    checkPromotionId = (checkData) => {
        const formatedValue = checkData.promotionId.padStart(10,'0');
        checkData.promotionId = formatedValue
        checkData.inputRef.current.value = formatedValue

        this.props.checkPromotionId(this.props.campaign.id, formatedValue).then(p => {
            if ('isValid' in p) {
                checkData.checkStatus = !!p.isValid
                this.forceUpdate()
            } else {
                this.props.showAlert()
            }
        })
    }

    addPromotionId = () => {
        this.state.promotionIds.push({id:this.lastInputId++, promotionId:null, checkStatus:null, deleted:false, inputRef:React.createRef()})
        this.forceUpdate()
    }

    sendApproveDiscounts = (filteredPrommotionIds) => {
        this.props.applyCampaignDiscount(this.props.campaign.id, filteredPrommotionIds.map(p => p.promotionId), this.state.comments)
            .then(r => r ? this.props.showResponseModal() : this.props.showAlert())
    }


    isDisableSendButton = (undeletedPids) => {
        return undeletedPids.find(p => !!p.checkStatus === false)
    }

    fixValues = (undeletedPids) => {
        undeletedPids.forEach(p => {
            if (p.inputRef) {
                console.log('INPUT', p.inputRef.current.value)
                p.inputRef.current.value=p.promotionId
                console.log('INPUT2', p.inputRef.current.value)
            }
        })
    }

    render() {
        const undeletedPids = this.state.promotionIds.filter(p => !p.deleted)
        console.log('UNDELETEDS', undeletedPids)

        return (
            <div className="row px-4 pb-2 h-100">
                {/*<h5><Translation id="check_promotion_ids" defaultMessage="Check promotion IDs"/></h5>*/}
                <div className="col-12 col-lg-4 h-lg-100 mb-4">
                    <p>Introduce tantos ids (KNUMA_AG) como promociones <i>vistex</i> hayas creado para cumplir los requisitos de esta campaña.</p>
                    <b>Todos deberán validarse correctamente para que puedas aprobar el descuento.</b>
                </div>
                <div className="displayNone d-md-inline col-lg-1 h-lg-100"/>
                <div className="col-12 col-lg-7 d-flex flex-column justify-content-between h-lg-100  mh-100">
                    <div className="flex-grow-1 pr-3 mb-3 overflow-auto">
                    {
                        undeletedPids.map((pId, index) => {
                            const idsCount = undeletedPids.length
                            return (
                                <div className="discount-input-row" key={`discount-checkInput-${pId.id}`}>
                                    <div className="d-flex flex-column">
                                        <label htmlFor={`discount-checkInput-${index}`} className="m-0">
                                            <small><Translation id="promotion_vistex_id" defaultMessage="Promotion Vistex ID"/></small>
                                        </label>
                                        <input
                                            ref={pId.inputRef}
                                            id={`discount-checkInput-${pId.id}`}
                                            type="text"
                                            // value={pId.promotionId}
                                            maxLength="10"
                                            style={{
                                                textAlign:"end",
                                                fontSize:"1.2em",
                                                width: "9em",
                                                padding:"2px 6px 1px",
                                                borderRadius:"6px",
                                                border:"3px solid #d8d8d8"
                                            }}
                                            onChange={e => {
                                                pId.promotionId = e.target.value
                                                pId.inputRef.current.value = pId.promotionId
                                                this.forceUpdate()
                                            }}
                                            disabled={pId.checkStatus === true}
                                        />
                                    </div>

                                    <div style={{display:'flex', columnGap: '24px', alignItems: 'center', width: '100%'}}>
                                        <Button inline
                                                size="large"
                                                onClick={() => this.checkPromotionId(pId)}
                                                disabled={!pId.promotionId || pId.checkStatus === true}
                                        >
                                            <Translation id="check" defaultMessage="Check"/>
                                        </Button>
                                        {
                                            pId.checkStatus === null && <i className="far fa-question-circle fa-2x" style={{color:'blue'}}/>
                                        }
                                        {
                                            pId.checkStatus === true && <i className="fas fa-check-circle fa-2x" style={{color:'green'}}/>
                                        }
                                        {
                                            pId.checkStatus === false && <i className="fas fa-times-circle fa-2x" style={{color:'red'}}/>
                                        }
                                        <div className="k-flex-grow"/>
                                        {
                                            idsCount > 1 && <i className="pointer far fa-minus fa-2x" onClick={() => {
                                                pId.deleted = true
                                                pId.inputRef = null
                                                // this.forceUpdate()
                                                this.fixValues(undeletedPids)
                                                this.setState({promotionIds: undeletedPids})
                                                console.log('DELETED: ', pId)
                                            }}/>
                                        }
                                        {
                                            index === (idsCount - 1) && <i
                                                className="pointer far fa-plus fa-2x"
                                                onClick={this.addPromotionId}
                                            />
                                        }
                                    </div>

                                </div>
                            )
                        })
                    }
                    </div>
                    <div className="d-flex flex-column align-items-end">

                        <div className="w-100 my-3">
                            <Translation id="comments" defaultMessage="Comments"/>
                            <textarea
                                value={this.state.comments}
                                rows="4"
                                maxLength="512"
                                style={{width:'100%', border:'3px solid grey'}}
                                onChange={e => this.setState({comments: e.target.value})}
                            />
                        </div>


                        <Button className="approve_deny_button"
                                onClick={() => this.sendApproveDiscounts(undeletedPids)}
                                disabled={this.isDisableSendButton(undeletedPids)}
                        >
                            <Translation id="approve_discounts" defaultMessage="Approve discounts"/>
                        </Button>
                    </div>
                </div>

            </div>
        )
    }
}

// {
//     id: 'promotion_id',
//     Header: <Translation id="promotion_id" defaultMessage="Promotion ID" />,
//     Cell: ({ index }) => {
//         return this.getPromotionIdInput(index)
//         // return  this.state.promotionIds[index] ? <input type="text"
//         //              ref={this.state.promotionIds[index].input}
//         //              // value={this.state.promotionIds[index].promotionId}
//         //              onChange={e => this.setPromotionId(index, e.target.value)}
//         //              maxLength="12"
//         //              style={{textAlign:"end", fontSize:"1.2em", width: "9em", padding:"2px 6px 1px"}}
//         //              id={`input_${++this.counter}`}
//         // /> : null
//     }
// },
// {
//     id: 'auth_promotion',
//     Header: <Translation id="auth_promotion" defaultMessage="Auth promotion" />,
//     Cell: ({ original }) => {
//         return original.hasPromotionDiscount ? (
//                 <i className="fas fa-check fa-2x" style={{color:'green'}}/>
//             ) : (
//                 <i className="fas fa-times fa-2x" style={{color:'red'}}/>
//             )
//     }
// }

const mapStateToProps = state => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
        checkPromotionId: (promotionId, vistexId) => dispatch(checkPromotionId(promotionId, vistexId)),
        applyCampaignDiscount: (promotionId, vistexIds, comments) => dispatch(applyCampaignDiscount(promotionId, vistexIds, comments))
    }
}


export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ApproveDiscount)
)