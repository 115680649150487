/**
 * Función para gestionar las cargas por las que se deba mostrar un loader bloqueante.
 * Primero se llamará a removeLoader dentro de un timeout cuyo ID se pasará a esta función y,
 * a continuación, fuera del loader, a la de añadir Loader.
 * 
 * @param {number} id ID devuelto por el setTimeout
 * @returns 
 */

export const addLoader = (id) => dispatch => {
    dispatch({ type: 'ADD_LOADER', loader: id });
}

export const removeLoader = (id) => dispatch => {
    clearTimeout(id);
    dispatch({ type: 'REMOVE_LOADER', loader: id });
}