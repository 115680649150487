import { connect } from 'react-redux'
import { getIsLogged } from '../../store/login/reducers'
import Translation from "../global/Translation";
import React from "react";
import ProductsSlider from "./ProductsSlider";
import {canViewMktools} from "../../store/centers/reducers";
import {fetchCartComplementaryProducts} from "../../store/crossSelling/actions";
import {getCartSimilarProducts} from "../../store/crossSelling/reducers";

const mapStateToProps = state => {
    return {
        products: getCartSimilarProducts(state),
        hide: !getIsLogged(state) || canViewMktools(state),
        title: <Translation id="other_products" defaultMessage="Other products" />,
        analyticsTag: 'other_products'
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetch: () => {
            return dispatch(fetchCartComplementaryProducts(props.productIds, props.quantities))
        }
    }
}

const CartOtherProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider)


export default CartOtherProductsContainer
