import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'

import { withRouter } from 'react-router-dom'

class LowesViewSelector extends React.Component {
    render() {
        const { match, small } = this.props
        if (small) {
            return (
                <div className="lowes-container">
                    {/*<LocalizedLink*/}
                    {/*    className={match.params.filter === 'brochures' && !match.params.open ? 'active' : ''}*/}
                    {/*    routeId="ROUTE_CATALOG_LOWES"*/}
                    {/*    params={{ filter: 'brochures' }}*/}
                    {/*>*/}
                    {/*    <div className="lowes-button">*/}
                    {/*        <span className="lowes-title">*/}
                    {/*            <i className="fas fa-newspaper">*/}
                    {/*                {' '}*/}
                    {/*                <Translation id="brochures" defaultMessage="Brochures" />*/}
                    {/*            </i>*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*</LocalizedLink>*/}
                    <LocalizedLink
                        className={match.params.filter === 'customer-samples' && !match.params.open ? 'active' : ''}
                        routeId="ROUTE_CATALOG_LOWES"
                        params={{ filter: 'customer-samples' }}
                    >
                        <div className="lowes-button">
                            <span className="lowes-title">
                                <i className="fas fa-tags">
                                    {' '}
                                    <Translation id="customer_samples" defaultMessage="Customer Samples" />{' '}
                                </i>
                            </span>
                        </div>
                    </LocalizedLink>
                    <LocalizedLink
                        className={match.params.filter === 'reports' && !match.params.open ? 'active' : ''}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'orders', open: '' }}
                    >
                        <div className="lowes-button">
                            <span className="lowes-title">
                                <i className="fas fa-chart-bar">
                                    {' '}
                                    <Translation id="order_history" defaultMessage="Order history" />
                                </i>
                            </span>
                        </div>
                    </LocalizedLink>
                </div>
            )
        } else {
            return (
                <div className="container">
                    {/* <div className="lowes-container-left">
                        <div className="lowes-step">Step 1</div>
                        <h2>
                            <Translation id="what_would_you_like_to_do" defaultMessage="What would you like to do?" />
                        </h2>
                    </div> */}
                    <div className="lowes-container" style={{ height: 'calc(100vh - 346px)' }}>
                        {/*<div className="lowes-col">*/}
                        {/*    <LocalizedLink*/}
                        {/*        className={match.params.filter === 'brochures' && !match.params.open ? 'active' : ''}*/}
                        {/*        routeId="ROUTE_CATALOG_LOWES"*/}
                        {/*        params={{ filter: 'brochures' }}*/}
                        {/*    >*/}
                        {/*        <div className="lowes-button">*/}
                        {/*            /!* <i className="fas fa-th-large fa-2x" /> *!/*/}
                        {/*            <span className="lowes-title">*/}
                        {/*                {' '}*/}
                        {/*                <Translation id="brochures" defaultMessage="Brochures" />{' '}*/}
                        {/*            </span>*/}
                        {/*            <span className="lowes-subtitle">*/}
                        {/*                <Translation id="order_brochures" defaultMessage="Order Brochures" />*/}
                        {/*            </span>*/}
                        {/*        </div>*/}
                        {/*    </LocalizedLink>*/}
                        {/*</div>*/}
                        <div className="lowes-col">
                            <LocalizedLink
                                className={match.params.filter === 'customer-samples' && !match.params.open ? 'active' : ''}
                                routeId="ROUTE_CATALOG_LOWES"
                                params={{ filter: 'customer-samples' }}
                            >
                                <div className="lowes-button">
                                    {/* <i className="fas fa-user fa-2x" />{' '} */}
                                    <span className="lowes-title">
                                        <Translation id="customer_samples" defaultMessage="Customer Samples" />{' '}
                                    </span>
                                    <span className="lowes-subtitle">
                                        <Translation id="selling_customer" defaultMessage="Order 2x4 Silestone Customer Samples" />
                                    </span>
                                </div>
                            </LocalizedLink>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default injectIntl(withRouter(LowesViewSelector))
