//import { createSelector } from 'reselect'

const initState = {
    reservations: [],
    customers: undefined,
    isLoading: false,
    countUnassigned: undefined
}
const reservations = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_CUSTOMERS_SUCCESS':
            return Object.assign({}, state, { customers: action.customers })
        case 'FETCH_RESERVATIONS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_RESERVATIONS_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_RESERVATIONS_SUCCESS':
            return Object.assign({}, state, { reservations: action.reservations, isLoading: false })
        case 'FETCH_RESERVATIONS_UNASSIGNED_COUNT':
            return Object.assign({}, state, { countUnassigned: action.reservations.length })
        case 'EMPTY_RESERVATIONS':
            return Object.assign({}, state, { reservations: [], countUnassigned: undefined })
        default:
            return state
    }
}
export const getReservations = state => {
    return state.reservations.reservations
}
export const getReservationsLoading = state => {
    return state.reservations.isLoading
}
export const getCustomers = state => {
    return state.reservations.customers
}
export const countUnassignedReservations = state => {
    return state.reservations.countUnassigned
}

export default reservations
