import React from 'react'
import Translation from '../global/Translation'
import truck from '../../assets/img/weights/truck.svg'
import container from '../../assets/img/weights/container.svg'

import {injectIntl} from "react-intl";
import ProgressBarWeights from "./ProgressBarWeights";
import LocalizedLink from "../global/LocalizedLink";


/**
 *
 * @param intl
 * @param unit
 * @param isMktoolCenter
 * @param shippingMethod
 * @param languageDir
 * @param numberFormat
 * @param selectedCenter
 * @param {{units,
 * isValid,
 * qtyForNextUnit,
 * currentQtyWeight,
 * currentMinWeight,
 * currentMaxWeight}} fullUnits
 * @param spentBudget
 * @param newSpentBudgetWithCartContents
 * @param maxBudget
 * @param coin
 * @returns {JSX.Element}
 */
const Weights = ({
    intl,
    unit,
    isMktoolCenter,
    shippingMethod,
    languageDir,
    numberFormat,
    selectedCenter,
    fullUnits,
    spentBudget,
    newSpentBudgetWithCartContents,
    maxBudget,
    coin,
    isLatinaVitoria
}) => {

    if (isLatinaVitoria){
        unit = intl.formatMessage({ id: 'tables'})
    } else {
        unit = unit.toLowerCase() + '.'
    }

    const formattedCurrentQtyWeight = numberFormat.format(fullUnits.currentQtyWeight) + ' ' + unit;
    const formattedCurrentMinWeight = numberFormat.format(fullUnits.currentMinWeight) + ' ' + unit;
    const formattedCurrentMaxWeight = numberFormat.format(fullUnits.currentMaxWeight) + ' ' + unit;
    const formattedQtyForNextUnit = numberFormat.format(fullUnits.qtyForNextUnit) + ' ' + unit;

    const currentUnit = fullUnits.currentQtyWeight < fullUnits.currentMinWeight ?
        fullUnits.units + 1 :
        fullUnits.units

    let mainText;
    let subText;
    let isMainTextBold = true;

    let middlePointText;
    let endPointText;

    let shouldShowMiddlePoint = true;

    const translationWeightInYourCart =
        (isLatinaVitoria) ?
            <Translation
                id="slabs_in_your_cart"
                defaultMessage="Slabs in your cart: {varWeight}"
                values={{varWeight: formattedCurrentQtyWeight}}
            /> :
            <Translation
                id="weight_in_your_cart"
                defaultMessage="Weight in your cart: {varWeight}"
                values={{varWeight: formattedCurrentQtyWeight}}
            />

    const translationDiscount =
        <Translation
            id="weight_needed_for_extra_discount"
            defaultMessage="You need {varWeight} more to get an extra discount on your order"
            values={{varWeight: formattedQtyForNextUnit}}
        />

    const translationWeightNeeded =
        (isLatinaVitoria) ?
            <Translation
                id="weight_needed_for_order_latina"
                defaultMessage="You need {varWeight} more to fill a container."
                values={{varWeight: formattedQtyForNextUnit}}
            />
            :
            <Translation
                id="weight_needed_for_order"
                defaultMessage="You need {varWeight} more to place your order."
                values={{varWeight: formattedQtyForNextUnit}}
            />

    const translationMinNeeded =
        (isLatinaVitoria) ?
            <Translation
                id="must_reach_min_slabs_latina"
                defaultMessage="The container will be shipped when completed, otherwise delivery time may be longer than usual."
            />
             :
            <Translation
                id="must_reach_min_weight"
                defaultMessage="You must reach, at least, the minimum weight indicated."
            />

    const translationMinOrMaxNeeded =
        (isLatinaVitoria) ?
            <Translation
                id="must_reach_min_slabs_or_previous_max_latina"
                defaultMessage="You must reach the minimum number of slabs indicated, or not exceed the previous maximum of {varWeight}, otherwise delivery time may be longer than usual."
                values={{varWeight: (numberFormat.format(fullUnits.currentMaxWeight - fullUnits.currentMaxWeight / currentUnit) + ' ' + unit.toLowerCase())}}
            /> :
            <Translation
                id="must_reach_min_weight_or_previous_max"
                defaultMessage="You must reach the minimum weight indicated, or not exceed the previous maximum weight {varWeight}"
                values={{varWeight: (numberFormat.format(fullUnits.currentMaxWeight - fullUnits.currentMaxWeight / currentUnit) + ' ' + unit.toLowerCase())}}
            />

    switch (shippingMethod) {
        case 'SINGLE_SLAB':
        case 'ENDI_TRUCK':
        case 'ENDI_CONTAINER':
            if(shippingMethod !== 'ENDI_CONTAINER' && (shippingMethod === 'ENDI_TRUCK' || selectedCenter == 1100)){
                mainText = currentUnit > 1 ? translationWeightInYourCart : translationDiscount;
                isMainTextBold = currentUnit > 1;
                currentUnit === 1 && (fullUnits.currentQtyWeight < fullUnits.currentMinWeight ?
                    middlePointText = <Translation id="discount" defaultMessage="Discount" /> :
                    endPointText = <Translation id="discount" defaultMessage="Discount" />);
                shouldShowMiddlePoint = currentUnit === 1
            } else {
                mainText = translationWeightInYourCart;
                shouldShowMiddlePoint = false;
            }
            break;
        case 'FULL_TRUCK':
        case 'DIRECT_TRUCK':
        case 'CONTAINER':
            mainText = fullUnits.currentQtyWeight < fullUnits.currentMinWeight ?
                translationWeightNeeded :
                translationWeightInYourCart;
            subText = currentUnit === 1 ? (fullUnits.currentQtyWeight < fullUnits.currentMinWeight ?
                    translationMinNeeded :
                    null)
                :
                fullUnits.currentQtyWeight < fullUnits.currentMinWeight ?
                    translationMinOrMaxNeeded :
                    null;

            middlePointText = (isLatinaVitoria) ?
                <Translation id="min" defaultMessage="Minimum" /> :
                <Translation id="min_weight" defaultMessage="Minimum weight" />
            //endPointText = <Translation id="min_weight" defaultMessage="Minimum weight" />
            break;
        case 'MKTOOLS':
            if(newSpentBudgetWithCartContents <= maxBudget)
                mainText =
                    <React.Fragment>
                        <Translation id="remaining_budget" defaultMessage="Remaining budget" /> : {numberFormat.format(maxBudget - newSpentBudgetWithCartContents)} {coin}
                    </React.Fragment>
            else
                subText = <Translation id="out_of_budget" defaultMessage="You are out of budget" />
    }
    const isValid = isMktoolCenter && newSpentBudgetWithCartContents <= maxBudget || !isMktoolCenter && fullUnits.isValid

    return (
        <div className={isValid ? 'weights-container' : 'weights-container weights-container__error'}>
            <div className="container">
                <div className="d-flex flex-wrap justify-content-between align-middle">
                    <div className="k-d-flex-col col-12 col-xl-5 justify-content-center align-items-start">
                        <p className={'weights__text' + (isMainTextBold ? ' weights__text--bold' : '')}>{mainText}</p>
                        {subText && <p className="weights__text">{subText}</p>}
                        {!isMktoolCenter && (
                            <div className='weights__button d-flex'>
                                <LocalizedLink style={{ cursor: 'pointer' }} routeId="ROUTE_CATALOG" queryString="?type[0]=TABLA" params={{ outlet: '' }}>
                                    <Translation id={'add_more_items'} defaultMessage={'Add more items'} />
                                </LocalizedLink>
                            </div>
                        )}
                    </div>
                    <div className="weights-container__progress-bar d-flex align-items-center col-12 col-xl-7">
                        {!isMktoolCenter && (
                            <div>
                                <div className="weights__unit-counter">
                                    <div className="weights__inner-circle"></div>
                                    <img
                                        src={shippingMethod === 'CONTAINER' || shippingMethod === 'ENDI_CONTAINER' ? container : truck}
                                        alt={shippingMethod === 'CONTAINER' || shippingMethod === 'ENDI_CONTAINER' ? 'Container' : 'Truck'}
                                    />
                                    {currentUnit > 1 && <p className="weights__text weights__text--bold">x{currentUnit}</p>}
                                </div>
                            </div>
                        )}
                        <div className="flex-grow-1">
                            {
                                isMktoolCenter ? (
                                    <ProgressBarWeights
                                        startPoint={0}
                                        endPoint={maxBudget}
                                        middlePoint={null}
                                        currentPoint={newSpentBudgetWithCartContents}
                                        middlePointText={null}
                                        endPointText={null}
                                        unit={coin}
                                        languageDir={languageDir}
                                        numberFormat={numberFormat}
                                    />
                                ) : (
                                    <ProgressBarWeights
                                        startPoint={fullUnits.currentMaxWeight - fullUnits.currentMaxWeight / currentUnit}
                                        endPoint={fullUnits.currentMaxWeight}
                                        middlePoint={shouldShowMiddlePoint ? fullUnits.currentMinWeight : null}
                                        currentPoint={fullUnits.currentQtyWeight}
                                        middlePointText={middlePointText}
                                        endPointText={endPointText}
                                        unit={unit}
                                        languageDir={languageDir}
                                        numberFormat={numberFormat}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(Weights)
