import { connect } from 'react-redux'
import { getLastProductBoughts } from '../../store/products/reducers'

import { fetchLastProductsBought } from '../../store/products/actions'
import { getIsLogged } from '../../store/login/reducers'
import { canViewMktools } from '../../store/centers/reducers'
import ProductsSlider from "./ProductsSlider";
import Translation from "../global/Translation";
import React from "react";

const mapStateToProps = state => {
    return {
        products: getLastProductBoughts(state),
        hide: !getIsLogged(state) || canViewMktools(state),
        title: <Translation id="recently-purchased-products" defaultMessage="Recently purchased products" />,
        analyticsTag: 'recently_purchased',
        gtmLabel: 'ec_carrusel_rencently_purchased'
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetch: () => {
            return dispatch(fetchLastProductsBought())
        }
    }
}

const LastBoughtProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider)

export default LastBoughtProductsContainer
