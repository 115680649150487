import React from 'react'
import { injectIntl } from 'react-intl'
import LandingOne from './template/LandingOne'
import LandingTwo from './template/LandingTwo'
import Select from 'react-select'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import Error from '../../components/alerts/Error'
import GoBack from '../global/GoBack'

class LandingPromotions extends React.Component {
    constructor(props) {
        super(props)

        if(!this.props.isLogged) {
            this.props.loginRequired()
        }
        
        this.props.fetchPromotionData(this.props.promotionId)
        this.state = { userSelected: null }
    }

    template(templateId) {
        switch (templateId) {
            case '1':
                return <LandingOne {...this.props} />
            case '2':
                return <LandingTwo {...this.props} />
            default:
                return null
        }
    }
    componentWillUnmount() {
        if (this.props.isAdmin) {
            this.props.removePriceList()
        }
    }

    render() {
        if (!this.props.promotionData && this.props.error) return <Error alert={this.props.intl.formatMessage({ id: this.props.error })} />

        return (
            <div className={this.props.landingId === '2' ? "catalog-background-color" : ""}>
                {this.props.isAdmin && (
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pt-3">
                                <GoBack></GoBack>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="my-4">
                                    <Translation id="campaigns_preview" defaultMessage="Campaigns Preview" />
                                </h2>
                            </div>
                            <div className="col-4">
                                <LocalizedLink className="btn btn-outline-secondary mr-2" routeId="ROUTE_PROMOTIONS_LIST">
                                    <Translation id="campaign_list" defaultMessage="Campaing list" />
                                </LocalizedLink>{' '}
                            </div>
                            <div className="col-4 offset-4">
                                <Select
                                    placeholder={this.props.intl.formatMessage({ id: 'promotion_select_user' })}
                                    options={
                                        this.props.users &&
                                        this.props.users.map(item => {
                                            return { label: item.name, value: item.login }
                                        })
                                    }
                                    value={this.state.userSelected}
                                    onChange={e => {
                                        if (e) {
                                            this.setState({ userSelected: e.value }, () => {
                                                this.props.fetchUserPriceListByUser(this.state.userSelected)
                                                this.props.gerPriceListByUserId(this.state.userSelected)
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {this.template(this.props.landingId)}
            </div>
        )
    }
}

export default injectIntl(LandingPromotions)
