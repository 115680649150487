import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import Slider from 'react-slick'
import DisplayBrand from '../catalog/rectangleResult/displays/DisplayBrand'
import * as analytics from '../../analyticsEvents'

function SampleNextArrow(props) {
    const { style, onClick } = props
    return (
        <i
            className={'fal fa-chevron-right'}
            style={{ ...style, display: 'block', position: 'absolute', top: '50px', right: '-5px', fontSize: '30px', cursor: 'pointer', zIndex: '10' }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { style, onClick } = props
    return (
        <i
            className={'fal fa-chevron-left'}
            style={{ ...style, display: 'block', position: 'absolute', top: '50px', fontSize: '30px', cursor: 'pointer', zIndex: '10' }}
            onClick={onClick}
        />
    )
}

class SimilarProducts extends React.Component {
    constructor(props) {
        super(props)
        this.myWrapper = React.createRef()
    }
    componentDidMount() {
        if (this.props.products) analytics.sendCatalogImpressions(this.props.lastProductsbought, 'SIMILAR_PRODUCTS')
    }
    render() {
        let { products } = this.props

        if (products.length === 0) {
            return ''
        }

        let maxSlidesToShow = products.length
        let breakpoints = [468, 768, 992, 1200, 1280]
        let shouldBeVariable = false
        if (window.innerWidth < breakpoints[0]) {
            shouldBeVariable = maxSlidesToShow <= 1
        } else if (breakpoints[0] <= window.innerWidth && window.innerWidth < breakpoints[1]) {
            shouldBeVariable = maxSlidesToShow <= 2
        } else if (breakpoints[1] <= window.innerWidth && window.innerWidth < breakpoints[3]) {
            shouldBeVariable = maxSlidesToShow <= 3
        } else if (breakpoints[2] <= window.innerWidth && window.innerWidth < breakpoints[4]) {
            shouldBeVariable = maxSlidesToShow <= 4
        } else if (breakpoints[4] <= window.innerWidth) {
            shouldBeVariable = maxSlidesToShow <= 5
        }
        let settings = {
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            dots: false,
            speed: 300,
            slidesToShow: products.length > 5 ? 5 : products.length,
            slidesToScroll: 1,
            infinite: false,
            variableWidth: shouldBeVariable,
            responsive: [
                { breakpoint: breakpoints[0], settings: { slidesToShow: maxSlidesToShow > 1 ? 1 : maxSlidesToShow } },
                { breakpoint: breakpoints[1], settings: { slidesToShow: maxSlidesToShow > 2 ? 2 : maxSlidesToShow } },
                { breakpoint: breakpoints[2], settings: { slidesToShow: maxSlidesToShow > 3 ? 3 : maxSlidesToShow } },
                { breakpoint: breakpoints[3], settings: { slidesToShow: maxSlidesToShow > 4 ? 4 : maxSlidesToShow } },
                { breakpoint: breakpoints[4], settings: { slidesToShow: maxSlidesToShow > 5 ? 5 : maxSlidesToShow } }
            ]
        }
        return (
            <div className="similar_colors">
                <h4>
                    <Translation id="similar_colors" defaultMessage="Similar colors" />
                </h4>
                <div className="similar_colors__results-list">
                    <Slider {...settings}>
                        {products.map((similar, index) => (
                            <div ref={this.myWrapper} className="similar_colors__result" key={index} style={{ width: '180px' }}>
                                <LocalizedLink routeId="ROUTE_PRODUCT_DETAIL" params={{ id: similar.productId }} onClick={() => this.props.clickDetail(this.product, index)}>
                                    <div className="product-image-container">
                                        <div className="product-image" style={{ backgroundImage: 'url("' + similar.thumb + '")' }} />
                                    </div>
                                </LocalizedLink>
                                <div className="product-info">
                                    <DisplayBrand brand={similar.brand} />

                                    <div className="product-color-name">{similar.displayName.toLowerCase()}</div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        )
    }
}

export default SimilarProducts
