import React from 'react'

class Spinner extends React.Component {
    render() {
        let className = 'sk-cube-grid '
        if (this.props.relative) className += 'spinner-relative '
        if (this.props.inner) className += 'spinner-inner '
        if (this.props.small) className += 'spinner-small '
        if (this.props.innerCenter) className += 'spinner-inner-center'

        const spinner = (
            <div className={className} data-cy="global__sk_cube_grid">
                {this.props.small ? (
                    <React.Fragment>
                        <div className="sk-cube sk-cube7" />
                        <div className="sk-cube sk-cube8" />
                        <div className="sk-cube sk-cube9" />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="sk-cube sk-cube1" />
                        <div className="sk-cube sk-cube2" />
                        <div className="sk-cube sk-cube3" />
                        <div className="sk-cube sk-cube4" />
                        <div className="sk-cube sk-cube5" />
                        <div className="sk-cube sk-cube6" />
                        <div className="sk-cube sk-cube7" />
                        <div className="sk-cube sk-cube8" />
                        <div className="sk-cube sk-cube9" />
                    </React.Fragment>
                )}
            </div>
        )
        if (!this.props.isLoading) {
            return null
        } else {
            return (this.props.relative || this.props.inner || this.props.innerCenter) ? (
                spinner
            ) : (
                <div className="full-overlay" style={{ display: 'block' }}>
                    {spinner}
                </div>
            )
        }
    }
}
export default Spinner
