import React from 'react'
import Translation from '../global/Translation'

import moment from 'moment'
import KpisFilters from './KpisFilters'
import iconCart from '../../assets/img/icons/cart-thin.svg'
import iconCaution from '../../assets/img/icons/caution.svg'
import LocalizedLink from '../global/LocalizedLink'
import { connect } from 'react-redux'
import { fetchKpis } from '../../store/kpis/actions'
import { getSummary } from '../../store/kpis/reducers'

const mapStateToProps = state => {
    return {
        summary: getSummary(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchKpis: (startDate, endDate, customers, centers, topColors) => {
            dispatch(fetchKpis(startDate, endDate, customers, centers, topColors))
        }
    }
}
class Kpis extends React.Component {
    componentDidMount() {
        this.props.fetchKpis(moment().startOf('year'), moment(), null, null, null)
    }
    calcOverCount = (unit, total) => {
        if (total === 0) return 0
        return (unit * 100) / total
    }

    render() {
        return (
            <section id="section-kpis">
                <div className="top-background">
                    <div className="waves" />
                </div>
                <div className="kpis-container">
                    <div className="container">
                        <KpisFilters {...this.props} />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="kpi-box">
                                            <div className="row">
                                                <div className="icon col-12 col-md-3">
                                                    <img alt="" src={iconCart} />
                                                </div>
                                                <div className="col-12 col-md-9 text-right">
                                                    <div className="chart">
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.openOrders.byBrand.SCALEA, this.props.summary.openOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.openOrders.byBrand.SCALEA} SCALEA</div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.openOrders.byBrand.SENSA, this.props.summary.openOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.openOrders.byBrand.SENSA} SENSA</div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.openOrders.byBrand.SILESTONE, this.props.summary.openOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.openOrders.byBrand.SILESTONE} SILESTONE</div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.openOrders.byBrand.DEKTON, this.props.summary.openOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.openOrders.byBrand.DEKTON} DEKTON</div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.openOrders.byBrand.PREXURY, this.props.summary.openOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.openOrders.byBrand.PREXURY} PREXURY</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="info col-12">
                                                    <LocalizedLink routeId="ROUTE_KPI_DETAIL" params={{ filter: 'open-orders' }}>
                                                        <div className="title">
                                                            <Translation id="open_orders" defaultMessage="Open orders" />
                                                        </div>
                                                        <div className="subtitle">
                                                            <span className="number">{this.props.summary && this.props.summary.openOrders.count}</span>
                                                            <Translation id="orders_in_progress" defaultMessage="Orders in progress" />
                                                            <i className="goto-icon fal fa-chevron-right" />
                                                        </div>
                                                    </LocalizedLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <div className="kpi-box">
                                            <div className="row">
                                                <div className="icon col-12 col-md-3">
                                                    <img alt="" src={iconCaution} />
                                                </div>
                                                <div className="col-12 col-md-9 text-right">
                                                    <div className="chart">
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.backOrders.byBrand.SCALEA, this.props.summary.backOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.backOrders.byBrand.SCALEA} SCALEA</div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.backOrders.byBrand.SENSA, this.props.summary.backOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.backOrders.byBrand.SENSA} SENSA</div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.backOrders.byBrand.SILESTONE, this.props.summary.backOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.backOrders.byBrand.SILESTONE} SILESTONE</div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.backOrders.byBrand.DEKTON, this.props.summary.backOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.backOrders.byBrand.DEKTON} DEKTON</div>
                                                        </div>
                                                        <div
                                                            className="bar"
                                                            style={{
                                                                height:
                                                                    this.props.summary &&
                                                                    this.calcOverCount(this.props.summary.backOrders.byBrand.PREXURY, this.props.summary.backOrders.count) + '%'
                                                            }}
                                                        >
                                                            <div className="number">{this.props.summary && this.props.summary.backOrders.byBrand.PREXURY} PREXURY</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="info col-12">
                                                    <LocalizedLink routeId="ROUTE_KPI_DETAIL" params={{ filter: 'back-orders' }}>
                                                        <div className="title">
                                                            <Translation id="back_orders" defaultMessage="Back orders" />
                                                        </div>
                                                        <div className="subtitle">
                                                            <span className="number">{this.props.summary && this.props.summary.backOrders.count}</span>
                                                            <Translation id="items_withouth_stock" defaultMessage="Items withouth stock" />
                                                            <i className="goto-icon fal fa-chevron-right" />
                                                        </div>
                                                    </LocalizedLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
Kpis = connect(
    mapStateToProps,
    mapDispatchToProps
)(Kpis)

export default Kpis
