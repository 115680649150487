import React from 'react'
import Translation from '../../global/Translation'
import { defineMessages, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getIsLoadingVoucher, getIsValidVoucher, getVoucher, getVoucherStatusText } from '../../../store/cart/reducers'
import { checkVoucher, removeVoucher } from '../../../store/cart/actions'
import { canViewPricesPhase3 } from '../../../store/products/reducers'

const mapStateToProps = state => {
    return {
        isLoading: getIsLoadingVoucher(state),
        voucher: getVoucher(state),
        isValidVoucher: getIsValidVoucher(state),
        voucherStatusText: getVoucherStatusText(state),
        canViewPricesPhase3: canViewPricesPhase3(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        checkVoucher: voucher => {
            if (voucher) dispatch(checkVoucher(voucher))
        },
        removeVoucher: voucher => dispatch(removeVoucher(voucher)).then(() => dispatch({ type: 'CART_CHECK_VALID_VOUCHER_RESET' })),
        resetValidVoucher: () => dispatch({ type: 'CART_CHECK_VALID_VOUCHER_RESET' })
    }
}

class GroupInputVoucher extends React.Component {
    constructor(props) {
        super(props)
        this.state = { voucher: this.props.voucher, isValidVoucher: this.props.voucher !== '' ? true : null }
    }

    addVoucher() {
        this.props.checkVoucher(this.state.voucher)
    }
    removeVoucher() {
        this.props.resetValidVoucher()
        this.props.removeVoucher(this.state.voucher)
        this.setState({ voucher: '' })
    }
    render() {
        if (!this.props.canViewPricesPhase3) return null
        return (
            <div
                className={`form-group form-group__voucher ${
                    this.props.isValidVoucher ? 'form-group__voucher--success' : this.props.isValidVoucher === false && !this.props.isLoading ? 'form-group__voucher--failure' : ''
                }`}
            >
                <label htmlFor="input-voucher">
                    <Translation id="insert_voucher" defaultMessage="Insert your Voucher" />
                </label>
                {this.props.voucherStatusText && (
                    <span>
                        {' '}
                        - <Translation id={this.props.voucherStatusText} />
                    </span>
                )}
                <div className="input-group mb-3">
                    <div className="wrapper-input">
                        <input
                            name="input-voucher"
                            data-cy="cart__input_voucher"
                            type="text"
                            className={`form-control input-voucher ${this.props.voucher ? 'disabled' : ''}`}
                            disabled={this.props.voucher}
                            value={this.props.voucher ? this.props.voucher : this.state.voucher}
                            onChange={e => this.setState({ voucher: e.target.value }, this.props.resetValidVoucher)}
                            onKeyPress={e => {
                                if (e.key === 'ENTER') this.props.checkVoucher(this.state.voucher)
                            }}
                        />
                        <div className="icons-status">
                            {this.props.isValidVoucher && <i className="far fa-check" />}
                            {this.props.isLoading && <i className="far fa-spinner fa-pulse" />}
                            {this.props.isValidVoucher === false && !this.props.isLoading && <button className="clean-voucher-btn" onClick={() => this.removeVoucher()}><i className="far fa-times" /></button>}
                        </div>
                    </div>
                    <div className="input-group-append">
                        {!this.props.isValidVoucher ? (
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                    if (!this.props.isLoading) this.addVoucher()
                                }}
                                disabled={this.props.isLoading}
                            >
                                <Translation id="add" defaultMessage="add" />
                            </button>
                        ) : (
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                    if (!this.props.isLoading) this.removeVoucher()
                                }}
                                disabled={this.props.isLoading}
                            >
                                <Translation id="remove" defaultMessage="Remove" />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(GroupInputVoucher))
