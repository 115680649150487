import React from 'react'
import Translation from '../global/Translation'

import ReactTable from 'react-table'
import DirectSlabAccessForm from './DirectSlabAccessForm'
import Spinner from '../global/Spinner'
import Button from '../global/Button'

class DirectSlabAccess extends React.Component {
    getOrderImagesDownloadUrl = () => {
        const id = this.props.reservationId ? 'reservationId=' + this.props.reservationId : 'invoiceId=' + this.props.invoiceId
        // return process.env.REACT_APP_API_HOST + '/users/order-images?' + id + '&lang=' + this.props.lang + '&authToken=' + this.props.token
        return process.env.REACT_APP_API_HOST + '/users/order-images?' + id + '&lang=' + this.props.lang
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="toolbar">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 ">
                                        <h4>
                                            <Translation id="direct_slab_access" defaultMessage="Direct slab access" />
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DirectSlabAccessForm fetchDirectSlabAccess={this.props.fetchDirectSlabAccess} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {this.props.directSlabAccess && (
                            <div>
                                {(this.props.reservationId || this.props.invoiceId) && (
                                    <div className="row">
                                        <div className="col-12 text-right mb-3">
                                            <Button inline size="tiny" icon="download" onClick={() => this.props.getFileByUrl(this.getOrderImagesDownloadUrl(), 'images', 'zip')}>
                                                <Translation id="download_images" defaultMessage="Download images" />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                <ReactTable
                                    data={this.props.directSlabAccess}
                                    columns={[
                                        { Header: <Translation id="centerName" defaultMessage="Center Name" />, accessor: 'centerName' },
                                        { Header: <Translation id="brand" defaultMessage="Brand" />, accessor: 'brand' },
                                        { Header: <Translation id="productId" defaultMessage="Product ID" />, accessor: 'productId' },
                                        { Header: <Translation id="productName" defaultMessage="Product name" />, accessor: 'productName' },
                                        { Header: <Translation id="shade" defaultMessage="Shade" />, accessor: 'shade', minWidth: 60 },
                                        { Header: <Translation id="lotId" defaultMessage="Lot ID" />, accessor: 'lotId' },
                                        { Header: <Translation id="length" defaultMessage="Length" />, accessor: 'length', minWidth: 60 },
                                        { Header: <Translation id="width" defaultMessage="Width" />, accessor: 'width', minWidth: 60 },
                                        { Header: <Translation id="quality" defaultMessage="Quality" />, accessor: 'quality', minWidth: 60 },
                                        { Header: <Translation id="thickness" defaultMessage="Thickness" />, accessor: 'thickness', minWidth: 60 },
                                        { Header: <Translation id="qty_sqf" defaultMessage="QTY-SQF" />, accessor: 'qty_sqf' },
                                        { Header: <Translation id="qty_m2" defaultMessage="QTY-M2" />, accessor: 'qty_m2' },
                                        {
                                            Header: <Translation id="download" defaultMessage="Download" />,
                                            Cell: ({ original }) => {
                                                if (original.lotId) {
                                                    if (this.props.lotsImages && this.props.lotsImages[original.lotId] && this.props.lotsImages[original.lotId].dot_slab) {
                                                        return (
                                                            <a href={this.props.lotsImages[original.lotId].dot_slab} className="bt">
                                                                <i className="fal fa-download " />
                                                            </a>
                                                        )
                                                    } else return null
                                                } else return null
                                            }
                                        }
                                    ]}
                                    defaultPageSize={8}
                                    pageSizeOptions={[8, 16, 32, 64, 128]}
                                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                                    nextText={<Translation id="next" defaultMessage="Next" />}
                                    loading={this.props.isLoading}
                                    loadingText={<Spinner isLoading={this.props.isLoading} />}
                                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                    pageText={<Translation id="page" defaultMessage="Page" />}
                                    ofText={<Translation id="of" defaultMessage="Of" />}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
export default DirectSlabAccess
