import {clone} from "lodash";

const initState = {
    lotsImages: {},
    currentProductLots: [],
    lots:null,
    lastLotCenterLoaded: null,
    lastLotProductIdLoaded: null,
    isLastLotOutlet: null,
    lotsInCart: null,
    isLoading: false,
    lotsToBuy: {}
}

const lots = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_LOTS_REQUEST':
            return Object.assign({}, state, { isLoading: true,lastLotCenterLoaded:null,lastLotProductIdLoaded:null, lots:null})

        case 'FETCH_LOTS_SUCCESS':
            return Object.assign({}, state, {
                filtered: action.response.filtered,
                lots: action.response.data,
                isLoading: false,
                lastLotCenterLoaded:action.centerId,
                lastLotProductIdLoaded:action.productId,
                isLastLotOutlet: action.outlet
            })

        case 'FETCH_LOTS_FAILURE':
            return Object.assign({}, state, {
                isLoading: false
            })

        case 'FETCH_LOTS_IMAGES_SUCCESS':
            return Object.assign({}, state, { lotsImages: { ...state.lotsImages, ...action.response } })

        case 'EMPTY_LOT_DETAIL':
            return Object.assign({}, state, {
                lotDetail: null
            })

        case 'FETCH_LOT_SUCCESS':
            return Object.assign({}, state, {
                lotDetail: action.response
            })

        case 'SET_CURRENT_PRODUCT_LOTS':
            return Object.assign({}, state, {
                currentProductLots: action.productLots
            })

        case 'FETCH_CART_SUCCESS':
            let lotsInCart = null;
            if (action.cart && action.cart.lines) {
                lotsInCart = [];
                action.cart.lines.forEach(line => {
                    if (line.lotId) lotsInCart.push(line.lotId)
                    // else if (line.lots && (!line.extra || !line.extra.mall)) line.lots.forEach(lot => lotsInCart.push(lot.lotId))
                    else if (line.lots) {
                        line.lots.forEach(lot => lotsInCart.push(lot.lotId))
                    }
                })
            }
            return Object.assign({}, state, { lotsInCart: lotsInCart , isLoading: false })

        case 'FETCH_CART_FAILURE':
        case 'ADD_CART_LINE_FAILURE':
            return Object.assign({}, state, { isLoading: false })

        case 'EMPTY_CART':
            return Object.assign({}, state, { lotsInCart: null })

        case 'ADD_CART_LINE_CALL_API_REQUEST':
        case 'ADD_CART_BULK_LOTS_CALL_API_REQUEST':
            return Object.assign({}, state, { isLoading: true })

        case 'ADD_CART_LINE_CALL_API_SUCCESS':
        case 'ADD_CART_BULK_LOTS_CALL_API_SUCCESS':
            return Object.assign({}, state, { isLoading: false })

        case 'SUBMIT_CHECKOUT_SUCCESS':
            return Object.assign({}, state, { lastLotProductIdLoaded: null, isLastLotOutlet: null})

        case 'ADD_LOT_TO_BUY':
            if (action.selectedLot) state.lotsToBuy[action.uuid] = action.selectedLot
            else delete state.lotsToBuy[action.uuid]

            return Object.assign({}, state, { lotsToBuy: clone(state.lotsToBuy)})

        case 'CLEAR_LOTS_TO_BUY':
            return Object.assign({}, state, { lotsToBuy: {}})

        default:
            return state
    }
}
export const lastLotCenterLoaded = state => {
    return state.lots.lastLotCenterLoaded
}
export const lastLotProductIdLoaded = state => {
    return state.lots.lastLotProductIdLoaded
}
export const isLastLotOutlet = state => {
    return state.lots.isLastLotOutlet
}
export const getLots = state => {

    if (state.lots.lots && state.lots.lotsInCart) {

        const lotsInCartMap = {}
        state.lots.lotsInCart.forEach(lotId => lotsInCartMap[lotId] = true)

        let lotsCopy = [...state.lots.lots]
        lotsCopy = lotsCopy.map(lot => {
            const lotCopy = {...lot}
            if (lotCopy.lots) lotCopy.lots = lotCopy.lots.filter(l => !(l.lotId in lotsInCartMap))
            return lotCopy
        })

        const res = lotsCopy.filter(l => {
            const haveInnerLots = l.lots && l.lots.length > 0
            if (!haveInnerLots && l.lotId) return !(l.lotId in lotsInCartMap)
            else return haveInnerLots
        })
        return res
    }

    return state.lots.lots
}
export const getLotsImages = state => {
    return state.lots.lotsImages
}
export const getIsLoading = state => {
    return state.lots.isLoading
}
export const getFiltered = state => {
    return state.lots.filtered
}
export const getLotDetail = state => {
    return state.lots.lotDetail
}
export const getCurrentProductLots = state => {
    return state.lots.currentProductLots
}
export const getSelectedLotsToBy = state => {
    return state.lots.lotsToBuy
}
/**
 * Get the bundles rejection codes for all bundles.
 * @param {object} state 
 * @returns {(object|null)}
 */
export const getBundlesRejectionCodes = state => {
    const lots = state.lots.lots
    let bundlesRejectionCodes = null
    if (lots && lots.length > 0) {
        bundlesRejectionCodes = lots.map(lot => {
            return {
                'bundleId': lot.bundleId,
                'rejectionCode': lot.rejectionCode
            }
        })
    }

    return bundlesRejectionCodes
}
export default lots
