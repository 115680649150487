import { connect } from 'react-redux'
import { getLastVisitedProduct } from '../../store/products/reducers'
import { getIsLogged } from '../../store/login/reducers'
import Translation from "../global/Translation";
import React from "react";
import ProductsSlider from "./ProductsSlider";
import { fetchLastVisitedProducts } from "../../store/products/actions";
import {getSelectedCenter} from "../../store/centers/reducers";

const mapStateToProps = state => {
    return {
        products: getLastVisitedProduct(state),
        hide: !getIsLogged(state),
        title: <Translation id="recently_view_products" defaultMessage="Recently view products" />,
        analyticsTag: 'recently_seen',
        gtmLabel: 'ec_carrusel_recently_seen',
        currentCenter: getSelectedCenter(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetch: () => {
            return dispatch(fetchLastVisitedProducts())
        }
    }
}

const RecentlyVisitedProductsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider)


export default RecentlyVisitedProductsContainer
