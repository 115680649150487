export const TooltipStyles = {
    padding: '24px 16px',
    borderRadius: 0
}

export const TooltipContentStyles = {
    padding: 0
}

export const OptionStyles = {
    arrowColor: '#ffffff',
    backgroundColor: '#20367A',
    beaconSize: 36,
    primaryColor: '#20367a',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#ffffff',
    width: 600,
    zIndex: 100,
}

export const SpotlightSyles = {
    borderRadius: 0
}

export const ButtonNextStyles = {
    color: '#232323;',
    fontSize: '14px',
    fontWeight: 500,
    backgroundColor: '#ffffff',
    borderRadius: 0
}

export const ButtonBackStyles = {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 500
}

export const TooltipFooterStyles = {
    marginTop: '16px',
    paddingTop: '16px',
    borderTop: '1px solid #DDE4F6'
}