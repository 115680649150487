import React, {Component} from "react";
import {langFromLocale} from "../../../helpers";
import { withRouter } from "react-router-dom";
import { cloneDeep } from "lodash";
import { getFormatConfigData, getCheckoutLineId, getConfigurablePlanPdfUrl } from '../utils'

class ConfigurableCutToSize extends Component {

    constructor(props, context) {
        super(props, context);

        const lang = langFromLocale[this.props.intl.locale]
        this.state = {
            language: lang
        }
    }

    componentDidMount() {
        const cosConfigurable = document.querySelector('cos-configurable')
        if (cosConfigurable) {
            cosConfigurable.addEventListener('salida', (config) => {
                this.handleSalidaEvent(config)
            })
        }
        
        this.props.setIsConfigurableBathEditScreen(true)
    }
    
    componentWillUnmount() {
        this.props.setIsConfigurableBathEditScreen(false)
    }

    handleSalidaEvent = (config) => {
        const { refcode, configurablePlanAcceptanceList } = this.props

        // Determine if the plan has been accepted
        const isPlanAccepted = configurablePlanAcceptanceList && 
        configurablePlanAcceptanceList[refcode] && 
        configurablePlanAcceptanceList[refcode].isPlanAcceptanceChecked

        // Check if the configuration has been modified
        const isModified = this.isConfigModified(config)

        if (isPlanAccepted && !isModified) {
            // If the plan is accepted and not modified
            if (refcode) {
                // Editing: Use changeCartLineCutToSize when refcode is present (editing)
                this.changeCartLineCutToSize(config)
            } else {
                // Creating: Use addToCart when refcode is null (creating)
                this.addToCart(config.detail.quantity, config)
            }
        } else {
            // If the conditions are not met, handle plan acceptance
            this.handlePlanAcceptance(config)
        }
    }

    isConfigModified = (config) => {
        const { configurableInProgress, refcode, addConfigurablePlanAcceptanceChecked, addConfigurableModifiedList } = this.props

        // Ensure that configurableInProgress and config.detail exist
        const storeConfig = refcode && configurableInProgress[refcode] ? cloneDeep(configurableInProgress[refcode]) : null
        const currentConfig = config && config.detail ? cloneDeep(config.detail) : null

        // Exclude quantity and price from the comparison if the fields exist
        if (storeConfig) {
            if (storeConfig.price) delete storeConfig.price
            if (storeConfig.quantity) delete storeConfig.quantity
            if (storeConfig.configuration.quantity) delete storeConfig.configuration.quantity
        }

        if (currentConfig) {
            if (currentConfig.price) delete currentConfig.price
            if (currentConfig.quantity) delete currentConfig.quantity
            if (currentConfig.configuration.quantity) delete currentConfig.configuration.quantity
        }

        const isModified = JSON.stringify(storeConfig) !== JSON.stringify(currentConfig)

        // Update global state
        if (refcode) {
            addConfigurableModifiedList(refcode, isModified)
            addConfigurablePlanAcceptanceChecked(refcode, !isModified)
        }
        
        return isModified
    }

    changeCartLineCutToSize = (config) => {
        this.props.changeCartLineCutToSize(this.getCheckoutLineIdForCurrentConfig(), this.getFormattedData(config))
    }

    getCheckoutLineIdForCurrentConfig = () => {
        const { checkoutLines, refcode } = this.props

        return getCheckoutLineId(checkoutLines, refcode)
    }
    
    addToCart = (qty, ctsData) => {
        this.props.addCartLine(qty, this.getFormattedData(ctsData))
    }

    handlePlanAcceptance = (config) => {
        const { history, localizedUrl, model, type } = this.props

        this.props.addConfigurableInProgress(config.detail).then(() => {
            const refcode = config.detail.reference

            const routePath = localizedUrl('ROUTE_CONFIG_CUT_TO_SIZE_PLAN_ACCEPTANCE')
                                .replace(':type', type)
                                .replace(':model', model)
                                .replace(':refcode', refcode)

            history.push(routePath)
        })
    }

    getFormattedData = (config) => {
        const { refcode, configurablePlanAcceptanceList, intl, units, configurableDrawingList } = this.props

        const isPlanAccepted = configurablePlanAcceptanceList &&
        configurablePlanAcceptanceList[refcode] &&
        configurablePlanAcceptanceList[refcode].isPlanAcceptanceChecked

        return getFormatConfigData(config.detail, isPlanAccepted, intl.locale, units, getConfigurablePlanPdfUrl(configurableDrawingList, refcode), refcode)
    }

    getCurrentConfigQuantity = () => {
        const { refcode, configurableInProgress } = this.props
        const currentConfig = refcode && configurableInProgress[refcode]

        return currentConfig && parseInt(currentConfig.quantity)
    }

    getUserCurrency = () => {
        const { priceListAtSelectedCenter } = this.props
        const currency = priceListAtSelectedCenter && priceListAtSelectedCenter.length > 0 ? priceListAtSelectedCenter[0].currency : null

        return currency
    }

    render() {
        const { model, refcode, username, salesOrg, selectedCenter, type, intl } = this.props

        return (
            <div>
                <cos-configurable product={type}
                                  model={model.toUpperCase()}
                                  market={"HQ"}
                                  apikey={process.env.REACT_APP_CONFIGURABLES_APIKEY}
                                  callback="callback"
                                  reference={refcode}
                                  lang={this.state.language}
                                  //locale={this.props.locale}
                                  locale={this.state.language}
                                  currency={this.getUserCurrency()}
                                  kunnr={username}
                                  salesOrg={salesOrg}
                                  werks={selectedCenter}
                                  quantity={this.getCurrentConfigQuantity()}
                >
                </cos-configurable>
            </div>
        )
    }
}

export default withRouter(ConfigurableCutToSize)