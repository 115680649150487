import React from 'react'
import Translation from '../global/Translation'
import { injectIntl } from 'react-intl'
import Button from '../global/Button'
import GoBack from '../global/GoBack'
import InputNumber from '../global/InputNumber'
import LocalizedLink from '../global/LocalizedLink'
import ProductDisplayName from '../global/ProductDisplayName'
import Select from 'react-select'
import withRouter from 'react-router/withRouter'
import iconDownload from '../../assets/img/icons/download-gray.svg'
import DisplayBrand from '../catalog/rectangleResult/displays/DisplayBrand'
import DisplayStock from '../catalog/rectangleResult/displays/DisplayStock'
import mallLarge from '../../assets/img/logos/mall-large.jpg'
import FiltersProduct from '../FiltersProduct'
import ProductCarouselV2 from './ProductCarouselV2'
import DisplayPrice from '../catalog/rectangleResult/displays/DisplayPrice'
import RelatedProductsContainer from '../products/RelatedProductsContainer'
import ProductInfoTabs from './ProductInfoTabs'
import ProductsSimilarColorsContainer from '../products/ProductsSimilarColorsContainer'
import BannerRockMaster from '../banners/banner-rockmaster/BannerRockMaster'
import LengthMeasureTranslation from '../global/LengthMeasureTranslation'
import LengthMeasure from '../global/LengthMeasure'
import BannerHybriq from '../banners/banner-hybriq/BannerHybriq'
import CosentinoGlobalIndicatorExtended from '../cart-line/indicators/CosentinoGlobalIndicatorExtended'
import DisplayNaturalStoneOrigin from '../catalog/rectangleResult/displays/DisplayNaturalStoneOrigin'
import BannerEarthic from '../banners/banner-earthic/BannerEarthic'
import BannerSilestoneXM from '../banners/banner-silestone-xm/BannerSilestoneXM'
import QuickGuides from '../global/QuickGuides'
const typeIdShowDownlad = ['03', '09', '04']

class ProductCardV2 extends React.Component {
    getUnitBy() {
        const { product } = this.props
        if (product.unitsByLot) return product.unitsByLot
        else if (product.unitsByBox) return product.unitsByBox
        else if (!product.unitsByLot && !product.unitsByBox) return <LengthMeasure product={product} />
    }

    flagIndicator = (

        <div className="group_tag">
            {((this.props.outlet && this.props.product.outlet && this.props.product.outletQuality) || (this.props.product.fromMall && this.props.product.outletQuality && this.props.product.stock_outlet > 0)) && (
                <div className="outlet-indicator">{this.props.product.outletQuality && this.props.intl.formatMessage({ id: `outlet_${this.props.product.outletQuality.toLowerCase()}` })}</div>
            )}
            {this.props.productIsInPromo && (
                <div className="promo-indicator">
                    <Translation id="promotion" defaultMessage="Promotion" />
                </div>
            )}
            {this.props.product.discontinued && this.props.product.discontinued !== 'DD' && this.props.product.discontinued !== 'DF' && (
                <div className="discontinued_wrapper">
                    <div className="tooltip">
                        <div className="discontinued-indicator">
                            <Translation id="discontinued" defaultMessage="Discontinued" />
                            {' - '}
                            {this.props.intl.formatMessage({ id: this.props.product.discontinued + '_SHORT' })}
                        </div>
                        <p className="tooltiptext tooltip-discontinued">{this.props.intl.formatMessage({ id: this.props.product.discontinued + '_LONG' })}</p>
                    </div>
                </div>
            )}
        </div>
    )



    render() {

        // const extra = this.props.product && {...this.props.product.extra, variant: `variant_${this.props.product.productId}`}
        let { product, isLogged, canBuy, canViewOutlet, isMkToolsCenter, colorsMktoolsAttr, brandsMktoolsAttr, isIkeaCenter } = this.props

        //TODO: review callback current center
        // const callbackCenter = location.search.indexOf('promo=true') > -1 ? 7500 : null
        // // let price = <DisplayPrice product={this.props.product} outlet={this.props.outlet} returnPrice={true} />
        // let price = <DisplayPrice product={this.props.product} outlet={this.props.outlet} />
        // console.log('`PRECIO', price.text );
        let quantity
        if (product.type === 'TABLA') {
            quantity = (
                <div style={{ display: 'inline' }}>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }


        const displayStock = (
            <DisplayStock
                value={this.props.getStock()}
                measure={quantity}
                surface={this.getUnitBy()}
                product={product}
                hideSurface={product.fromMall}
                watchingOutlet={this.props.outlet}
                canViewThresholdMax={this.props.canViewThresholdMax}
            />
        )
        const displayLotsButton = this.props.showLots() && !this.props.outlet ? (
            <div className="buttons-lots-v2">
                <LocalizedLink routeId="ROUTE_LOTS" params={{ id: product.productId, outlet: '' }} gtmLabel="ec_ver_lotes">
                    <div className="lots">
                        <Translation id="view_lots" defaultMessage="View lots" />
                        &nbsp; ({this.props.getStock()})
                    </div>
                </LocalizedLink>
            </div>
        ) : (
            ''
        )

        const displayOutletLotsButton = this.props.showLots() && canViewOutlet && this.props.outlet ? (
            <div className="buttons-lots-v2">
                <LocalizedLink routeId="ROUTE_LOTS" params={{ id: product.productId, outlet: 'outlet' }}>
                    <div className="lots">
                        <Translation id="outlet" defaultMessage="Outlet" /> ({this.props.getStock()} <Translation id="lots" defaultMessage="Lots" />)
                    </div>
                </LocalizedLink>
            </div>
        ) : (
            ''
        )

        return (
            <div className="container product-detail product-detail-card" data-type={product.type}>
                <div style={{ marginBottom: '16px' }}>
                    <GoBack text={this.props.intl.formatMessage({ id: 'keep_buying' })} datacy={'product_detail__go_back'} />
                </div>
                <div className="row">
                    <div id="box" className="col-12 col-md-8 text-center">
                        {product && <ProductCarouselV2 product={product} isLogged={isLogged} />}
                        {isMkToolsCenter && product.download && typeIdShowDownlad.includes(product.typeId) && (
                            <a href={product.download} className="bt-download">
                                <div className="icon">
                                    <img alt="" src={iconDownload} />
                                </div>
                                <div className="label">
                                    <Translation id="download_pdf" defaultMessage="Download pdf" />
                                </div>
                            </a>
                        )}
                        {product.technology == 'HYBRIQ' && (
                            <div className="product-image" style={{ minHeight: '0' }}>
                                <div className="fit-image-v2 row no-gutters" style={{ fontSize: '12px', textAlign: 'left' }}>
                                    <div style={{ paddingRight: '10px' }}>
                                        <img style={{ width: '80px' }} className="image img-fluid" src="https://assetsstatic.cosentino.com/img/Patent-Pending-Logo.svg"></img>
                                    </div>
                                    <div className="col-8 col-md-10 box-patent-pending">
                                        <Translation
                                            id="hybriq_incorporate_patented"
                                            defaultMessage="HybriQ and HybriQ+® incorporate patented or patent-pending technologies."
                                        ></Translation>
                                        <br />
                                        <Translation
                                            id="hybriq_registered_trademarks"
                                            defaultMessage="HybriQ+® and HybriQ Technology® are registered trademarks owned by Cosentino S.A.U."
                                        ></Translation>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div id="product-description-phone" className="col-12 product-description">
                            {this.flagIndicator}
                            <div className="d-flex justify-content-between flex-wrap align-items-center">
                                {isMkToolsCenter && product.webHeadingDesc && product.subtypeId !== 'MONOCOLOR BOX' ? (
                                    <p className="product-serie">{product.webHeadingDesc.toLowerCase()}</p>
                                ) : (
                                    <DisplayBrand brand={product.brand} />
                                )}
                                {this.props.product.fromMall && (
                                    <div className="wrapper__logo_mall">
                                        <img style={{ maxWidth: '75px' }} src={mallLarge} alt="logo mall" />
                                    </div>
                                )}
                            </div>
                            <h2 className="product-title v2" data-cy="product_detail__title">
                                <ProductDisplayName product={product} />
                            </h2>
                            <div className="productid-info">
                                <span>
                                    <Translation id="product" defaultMessage="Product" />
                                </span>
                                : <span>{this.props.productId}</span>
                                {
                                    product.zaddon === 'MAL' &&
                                    <span> (<Translation id='MAL' defaultMessage='Meshed'/>)</span>
                                }
                            </div>
                            {this.props.product && this.props.product.extra && this.props.product.extra.isCosentinoGlobal && (
                                <div style={{marginTop: '10px'}}>
                                    <CosentinoGlobalIndicatorExtended />
                                </div>
                            )}

                            <DisplayNaturalStoneOrigin product={product} />

                            {product.technology == 'HYBRIQ' && (product.brand && product.brand !== 'XERON') && (
                                <div style={{ textAlign: 'initial' }}>
                                    <p style={{ fontSize: '12px' }}>
                                        <Translation
                                            id="hybriq_loft_series"
                                            defaultMessage="The Silestone® LOFT series designs have been registered by Cosentino S.A.U."
                                        ></Translation>
                                        <br />
                                    </p>
                                </div>
                            )}

                            {process.env.REACT_APP_FEATURE_ZAPATILLAS && product.type === 'TABLA' && (
                                <div className="product-filter-phone">
                                    {product.thickness && this.props.alternatives && (
                                        <FiltersProduct
                                            selectHeading={<Translation id="thickness" defaultMessage="Thickness" />}
                                            alternatives={this.props.alternatives.thickness}
                                            outlet={this.props.outlet}
                                            productId={this.props.productId}
                                            value={product.thickness}
                                        />
                                    )}

                                    {product.format && this.props.alternatives && (
                                        <FiltersProduct
                                            selectHeading={<Translation id="format" defaultMessage="Format" />}
                                            alternatives={this.props.alternatives.format}
                                            outlet={this.props.outlet}
                                            productId={this.props.productId}
                                            value={product.format}
                                        />
                                    )}

                                    {product.finish && this.props.alternatives && (
                                        <FiltersProduct
                                            selectHeading={<Translation id="finish" defaultMessage="Finish" />}
                                            alternatives={this.props.alternatives.finish}
                                            outlet={this.props.outlet}
                                            productId={this.props.productId}
                                            value={product.finish}
                                            translateValue={true}
                                        />
                                    )}
                                </div>
                            )}

                            {product.haveOptions && brandsMktoolsAttr && (
                                <div className="row" style={{ marginBottom: '15px' }}>
                                    <div className="col-12">
                                        <Select
                                            placeholder="Choose Brand"
                                            options={['No Brand'].concat(brandsMktoolsAttr).map(brand => ({
                                                label: brand.toLowerCase(),
                                                value: brand
                                            }))}
                                            className="select-mktools-attr select-mktools-attr--brand"
                                            value={this.props.state.selectedBrandMktoolAttr}
                                            onChange={selectedOption => this.props.changeBrandOption(selectedOption)}
                                            clearable={false}
                                        />
                                    </div>
                                </div>
                            )}
                            {product.type === 'PPCC' && product.colorFormulations && product.colorFormulations.length > 0 && (
                                <div className="row row-others">
                                    <div className="col-12">
                                        <div className="finished">
                                            <p className="finished-title">
                                                <Translation id="compatible_with_next_colors" defaultMessage="Compatible with next colors:"></Translation>
                                            </p>
                                        </div>

                                        <div className="finished" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                            {product.colorFormulations.map((colorFormulation, index) => (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '10px' }}>
                                                    <img alt="" style={{ height: '40px', width: '60px', border: '1px dashed #ccc' }} src={colorFormulation.thumb}></img>
                                                    <p className="finished-title">
                                                        <span style={{ textTransform: 'Capitalize', fontSize: '12px' }}>{colorFormulation.colorName.toLowerCase()}</span>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {product.haveOptions && colorsMktoolsAttr && (
                                <div className="row">
                                    <div className="col-12">
                                        <Select
                                            placeholder="Choose Color"
                                            options={this.props.getOptionsColors()}
                                            className="select-mktools-attr select-mktools-attr--color"
                                            value={this.props.state.selectedColorMktoolAttr}
                                            onChange={selectedOption => this.props.changeColorOption(selectedOption)}
                                            clearable={false}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="row row-price" style={{ margin: 0, display: 'flex', justifyContent: 'center' }}>
                                {displayStock}

                                <div class="box-buttons-lots-v2">
                                    {displayLotsButton}

                                    {product.outlet && displayOutletLotsButton}
                                </div>

                                <div className="col-12">
                                    {canBuy && (
                                        <div className="form-group" style={{ marginBottom: '0' }}>
                                            {isLogged && (
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {/*<IntegerInputNumberProduct max={80} min={1} valueDefault={5} price={75} />*/}
                                                    <DisplayPrice product={this.props.product} outlet={this.props.outlet} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-md-12 pt-3" style={{ padding: 0 }}>
                                    {canBuy && (
                                        <div className="row">
                                            <div className="col-12">
                                                <InputNumber
                                                    value={this.props.getValueToAdd()}
                                                    change={qty => this.props.state.setQtyToAdd(qty)}
                                                    // size="small"
                                                    step={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 0.01 : this.props.state.step}
                                                    limit={this.props.getLimit()}
                                                    min={this.props.getMin()}
                                                    decimals={this.props.isIkeaCenter && (this.product && this.product.unit === 'M2') === 'M2' ? 2 : 0}
                                                />
                                            </div>
                                            <div className="bt-add-to-cart-static col-12">
                                                <Button
                                                    far={true}
                                                    inverted
                                                    icon="shopping-cart"
                                                    disabled={this.props.isAdding || !this.props.canAddCart()}
                                                    onClick={() => this.props.addToCart()}
                                                    datacy="product_detail__add"
                                                    customClass="bt-add-to-cart-product-v2"
                                                    dataQas="global_product_detail-add-to-cart-button"
                                                >
                                                    <Translation id="add_to_cart" defaultMessage="Add" />
                                                </Button>
                                            </div>
                                            {!this.props.isShowingSegmentationModal && (
                                                <div className="bt-add-to-cart fixed-bottom">
                                                    <Button
                                                        far={true}
                                                        inverted
                                                        icon="shopping-cart"
                                                        disabled={this.props.isAdding || !this.props.canAddCart()}
                                                        onClick={() => {
                                                            this.addCartFunction()
                                                        }}
                                                        datacy="product_detail__add"
                                                        customClass="bt-add-to-cart-product-v2"
                                                        dataQas="global_product_detail-add-to-cart-button"
                                                    >
                                                        <Translation id="add_to_cart" defaultMessage="Add" />
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div id="product-description-tablet" className="col-12 product-description">
                            {this.flagIndicator}
                            <div className="d-flex justify-content-between flex-wrap align-items-center">
                                {isMkToolsCenter && product.webHeadingDesc && product.subtypeId !== 'MONOCOLOR BOX' ? (
                                    <p className="product-serie">{product.webHeadingDesc.toLowerCase()}</p>
                                ) : (
                                    <DisplayBrand brand={product.brand} />
                                )}
                                {this.props.product.fromMall && (
                                    <div className="wrapper__logo_mall">
                                        <img style={{ maxWidth: '75px' }} src={mallLarge} alt="logo mall" />
                                    </div>
                                )}
                            </div>
                            <h2 className="product-title v2" data-cy="product_detail__title" style={{ textAlign: 'initial' }}>
                                <ProductDisplayName product={product} />
                            </h2>
                            <div className="productid-info">
                                <span>
                                    <Translation id="product" defaultMessage="Product" />
                                </span>
                                : <span>{this.props.productId}</span>
                                {
                                    product.zaddon === 'MAL' &&
                                    <span> (<Translation id='MAL' defaultMessage='Meshed'/>)</span>
                                }
                            </div>
                            {this.props.product && this.props.product.extra && this.props.product.extra.isCosentinoGlobal && (
                                <div style={{marginTop: '10px'}}>
                                    <CosentinoGlobalIndicatorExtended />
                                </div>
                            )}
                            <DisplayNaturalStoneOrigin product={product} />

                            {product.technology == 'HYBRIQ' && (product.brand && product.brand !== 'XERON') && (
                                <div style={{ textAlign: 'initial' }}>
                                    <p style={{ fontSize: '12px' }}>
                                        <Translation
                                            id="hybriq_loft_series"
                                            defaultMessage="The Silestone® LOFT series designs have been registered by Cosentino S.A.U."
                                        ></Translation>
                                        <br />
                                    </p>
                                </div>
                            )}

                            {process.env.REACT_APP_FEATURE_ZAPATILLAS && product.type === 'TABLA' && (
                                <div className="row" style={{ textAlign: 'initial', paddingTop: '0.8rem' }}>
                                    {product.thickness && this.props.alternatives && (
                                        <FiltersProduct
                                            width={'33.3333%'}
                                            selectHeading={<Translation id="thickness" defaultMessage="Thickness" />}
                                            alignInitial={true}
                                            alternatives={this.props.alternatives.thickness}
                                            outlet={this.props.outlet}
                                            productId={this.props.productId}
                                            value={this.props.product.thickness}
                                        />
                                    )}
                                    {product.finish && this.props.alternatives && (
                                        <FiltersProduct
                                            width={'33.3333%'}
                                            selectHeading={<Translation id="format" defaultMessage="Format" />}
                                            alignInitial={true}
                                            alternatives={this.props.alternatives.finish}
                                            outlet={this.props.outlet}
                                            productId={this.props.productId}
                                            translate={'finish'}
                                            value={this.props.product.finish}
                                            translateValue={true}
                                        />
                                    )}
                                    {product.format && this.props.alternatives && (
                                        <FiltersProduct
                                            width={'33.3333%'}
                                            selectHeading={<Translation id="finish" defaultMessage="Finish" />}
                                            alignInitial={true}
                                            alternatives={this.props.alternatives.format}
                                            outlet={this.props.outlet}
                                            productId={this.props.productId}
                                            translate={'format'}
                                            value={this.props.product.format}
                                        />
                                    )}
                                    {/*<div className="col-3" style={{*/}
                                    {/*    position: 'absolute',*/}
                                    {/*    right: '32px',*/}
                                    {/*    top: '531px'*/}
                                    {/*}}>*/}
                                    {/*    {isLogged && canBuy && (*/}
                                    {/*        <div>*/}
                                    {/*            /!*<IntegerInputNumberProduct reverse={true} max={80} min={1} valueDefault={5} price={75} />*!/*/}
                                    {/*            <DisplayPrice product={this.props.product} outlet={this.props.outlet} />*/}
                                    {/*        </div>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}
                                </div>
                            )}

                            {product.haveOptions && brandsMktoolsAttr && (
                                <div className="row" style={{ marginBottom: '15px' }}>
                                    <div className="col-12">
                                        <Select
                                            placeholder="Choose Brand"
                                            options={['No Brand'].concat(brandsMktoolsAttr).map(brand => ({
                                                label: brand.toLowerCase(),
                                                value: brand
                                            }))}
                                            className="select-mktools-attr select-mktools-attr--brand"
                                            value={this.props.state.selectedBrandMktoolAttr}
                                            onChange={selectedOption => this.props.changeBrandOption(selectedOption)}
                                            clearable={false}
                                        />
                                    </div>
                                </div>
                            )}
                            {product.type === 'PPCC' && product.colorFormulations && product.colorFormulations.length > 0 && (
                                <div className="row row-others">
                                    <div className="col-12">
                                        <div className="finished">
                                            <p className="finished-title">
                                                <Translation id="compatible_with_next_colors" defaultMessage="Compatible with next colors:"></Translation>
                                            </p>
                                        </div>

                                        <div className="finished" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                            {product.colorFormulations.map((colorFormulation, index) => (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '10px' }}>
                                                    <img alt="" style={{ height: '40px', width: '60px', border: '1px dashed #ccc' }} src={colorFormulation.thumb}></img>
                                                    <p className="finished-title">
                                                        <span style={{ textTransform: 'Capitalize', fontSize: '12px' }}>{colorFormulation.colorName.toLowerCase()}</span>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {product.haveOptions && colorsMktoolsAttr && (
                                <div className="row">
                                    <div className="col-12">
                                        <Select
                                            placeholder="Choose Color"
                                            options={this.props.getOptionsColors()}
                                            className="select-mktools-attr select-mktools-attr--color"
                                            value={this.props.state.selectedColorMktoolAttr}
                                            onChange={selectedOption => this.props.changeColorOption(selectedOption)}
                                            clearable={false}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="row row-price" style={{ margin: 0, display: 'flex', justifyContent: 'center' }}>
                                {displayStock}

                                <div class="box-buttons-lots-v2">
                                    {displayLotsButton}

                                    {product.outlet && displayOutletLotsButton}
                                </div>

                                <div className="col-12">
                                    {canBuy && (
                                        <div className="form-group" style={{ marginBottom: '0' }}>
                                            {isLogged && (
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {/*<IntegerInputNumberProduct max={80} min={1} valueDefault={5} price={75} />*/}
                                                    <DisplayPrice product={this.props.product} outlet={this.props.outlet} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-md-12 pt-3" style={{ padding: 0 }}>
                                    {canBuy && (
                                        <div className="row">
                                            <div className="col-3">
                                                <InputNumber
                                                    value={this.props.getValueToAdd()}
                                                    change={qty => this.props.state.setQtyToAdd(qty)}
                                                    // size="small"
                                                    step={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 0.01 : this.props.state.step}
                                                    limit={this.props.getLimit()}
                                                    min={this.props.getMin()}
                                                    decimals={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 2 : 0}
                                                />
                                            </div>
                                            <div className="bt-add-to-cart col-9">
                                                <Button
                                                    far={true}
                                                    inverted
                                                    icon="shopping-cart"
                                                    disabled={this.props.isAdding || !this.props.canAddCart()}
                                                    onClick={() => this.props.addToCart()}
                                                    datacy="product_detail__add"
                                                    customClass="bt-add-to-cart-product-v2"
                                                    dataQas="global_product_detail-add-to-cart-button"
                                                >
                                                    <Translation id="add_to_cart" defaultMessage="Add" />
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        
                        <QuickGuides subtypeId={this.props.product.subtypeId} lang={this.props.locale} />

                        <RelatedProductsContainer productId={this.props.productId} sectionFlag="FJ" />
                        {/*{this.props.crossSellingView}*/}
                        <ProductInfoTabs />
                        <ProductsSimilarColorsContainer productId={this.props.productId} sectionFlag="FS" />
                    </div>

                    <div id="product-description-desktop" className="col-12 col-md-4 product-description">
                        {this.flagIndicator}
                        <div className="d-flex justify-content-between flex-wrap align-items-center" style={{ marginTop: '8px' }}>
                            {isMkToolsCenter && product.webHeadingDesc && product.subtypeId !== 'MONOCOLOR BOX' ? (
                                <p className="product-serie">{product.webHeadingDesc.toLowerCase()}</p>
                            ) : (
                                <DisplayBrand brand={product.brand} />
                            )}
                            {this.props.product.fromMall && (
                                <div className="wrapper__logo_mall">
                                    <img style={{ maxWidth: '75px' }} src={mallLarge} alt="logo mall" />
                                </div>
                            )}
                        </div>
                        <h2 className="product-title v2" data-cy="product_detail__title">
                            <ProductDisplayName product={product} />
                        </h2>
                        <div className="productid-info">
                            <span>
                                <Translation id="product" defaultMessage="Product" />
                            </span>
                            : <span>{this.props.productId}</span>
                            {
                                product.zaddon === 'MAL' &&
                                <span> (<Translation id='MAL' defaultMessage='Meshed'/>)</span>
                            }
                        </div>
                        {this.props.product && this.props.product.extra && this.props.product.extra.isCosentinoGlobal && (
                            <div style={{marginTop: '10px'}}>
                                <CosentinoGlobalIndicatorExtended />
                            </div>
                        )}

                        <DisplayNaturalStoneOrigin product={product} />

                        {product.technology == 'HYBRIQ' && (product.brand && product.brand !== 'XERON') && (
                            <p style={{ fontSize: '12px' }}>
                                <Translation id="hybriq_loft_series" defaultMessage="The Silestone® LOFT series designs have been registered by Cosentino S.A.U."></Translation>
                                <br />
                            </p>
                        )}
                        {process.env.REACT_APP_FEATURE_ZAPATILLAS && product.type === 'TABLA' && (
                            <div className="row" style={{ paddingTop: '0.8rem' }}>
                                {product.thickness && this.props.alternatives && (
                                    <FiltersProduct
                                        width={'50%'}
                                        selectHeading={<Translation id="thickness" defaultMessage="Thickness" />}
                                        alternatives={this.props.alternatives.thickness}
                                        outlet={this.props.outlet}
                                        productId={this.props.productId}
                                        translate={'thickness'}
                                        value={product.thickness}
                                    />
                                )}

                                {product.format && this.props.alternatives && (
                                    <FiltersProduct
                                        width={'50%'}
                                        selectHeading={<Translation id="format" defaultMessage="Format" />}
                                        alternatives={this.props.alternatives.format}
                                        outlet={this.props.outlet}
                                        productId={this.props.productId}
                                        translate={'format'}
                                        value={this.props.product.format}
                                    />
                                )}

                                {product.finish && this.props.alternatives && (
                                    <FiltersProduct
                                        width={'50%'}
                                        selectHeading={<Translation id="finish" defaultMessage="Finish" />}
                                        alternatives={this.props.alternatives.finish}
                                        outlet={this.props.outlet}
                                        productId={this.props.productId}
                                        translate={'finish'}
                                        value={this.props.product.finish}
                                        translateValue={true}
                                    />
                                )}
                            </div>
                        )}
                        {product.haveOptions && brandsMktoolsAttr && (
                            <div className="row" style={{ marginBottom: '15px' }}>
                                <div className="col-12">
                                    <Select
                                        placeholder="Choose Brand"
                                        options={['No Brand'].concat(brandsMktoolsAttr).map(brand => ({
                                            label: brand.toLowerCase(),
                                            value: brand
                                        }))}
                                        className="select-mktools-attr select-mktools-attr--brand"
                                        value={this.props.state.selectedBrandMktoolAttr}
                                        onChange={selectedOption => this.props.changeBrandOption(selectedOption)}
                                        clearable={false}
                                    />
                                </div>
                            </div>
                        )}
                        {product.type === 'PPCC' && product.colorFormulations && product.colorFormulations.length > 0 && (
                            <div className="row row-others">
                                <div className="col-12">
                                    <div className="finished">
                                        <p className="finished-title">
                                            <Translation id="compatible_with_next_colors" defaultMessage="Compatible with next colors:"></Translation>
                                        </p>
                                    </div>

                                    <div className="finished" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {product.colorFormulations.map((colorFormulation, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '10px' }}>
                                                <img alt="" style={{ height: '40px', width: '60px', border: '1px dashed #ccc' }} src={colorFormulation.thumb}></img>
                                                <p className="finished-title">
                                                    <span style={{ textTransform: 'Capitalize', fontSize: '12px' }}>{colorFormulation.colorName.toLowerCase()}</span>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                        {product.haveOptions && colorsMktoolsAttr && (
                            <div className="row">
                                <div className="col-12">
                                    <Select
                                        placeholder="Choose Color"
                                        options={this.props.getOptionsColors()}
                                        className="select-mktools-attr select-mktools-attr--color"
                                        value={this.props.state.selectedColorMktoolAttr}
                                        onChange={selectedOption => this.props.changeColorOption(selectedOption)}
                                        clearable={false}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row row-price" style={{ margin: 0, display: 'flex', justifyContent: 'center' }}>
                            {displayStock}

                            <div class="box-buttons-lots-v2">
                                {displayLotsButton}

                                {product.outlet && displayOutletLotsButton}
                            </div>

                            <div className="col-12">
                                {canBuy && (
                                    <div className="form-group" style={{ marginBottom: '0' }}>
                                        {isLogged && (
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                {/*<IntegerInputNumberProduct max={80} min={1} valueDefault={5} price={75} />*/}
                                                <DisplayPrice product={this.props.product} outlet={this.props.outlet} />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-12 pt-3" style={{ padding: 0 }}>
                                {canBuy && (
                                    <div className="row" style={{ alignItems: 'center' }}>
                                        <div className="col-6">
                                            <InputNumber
                                                value={this.props.getValueToAdd()}
                                                change={qty => this.props.state.setQtyToAdd(qty)}
                                                step={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 0.01 : this.props.state.step}
                                                limit={this.props.getLimit()}
                                                min={this.props.getMin()}
                                                decimals={this.props.isIkeaCenter && this.product && this.product.unit === 'M2' ? 2 : 0}
                                            />
                                        </div>
                                        <div className="bt-add-to-cart col-6">
                                            <Button
                                                far={true}
                                                inverted
                                                icon="shopping-cart"
                                                disabled={this.props.isAdding || !this.props.canAddCart()}
                                                onClick={() => this.props.addToCart()}
                                                datacy="product_detail__add"
                                                customClass="bt-add-to-cart-product-v2"
                                                dataQas="global_product_detail-add-to-cart-button"
                                            >
                                                <Translation id="add_to_cart" defaultMessage="Add" />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        {this.props.canViewEarthicPromotion && (
                            <BannerEarthic />
                        )}

                        {this.props.canViewSilestonePromotionXM && (
                            <BannerSilestoneXM />
                        )}

                        {this.props.canViewRockMasterPromotion && (
                            <BannerRockMaster />
                        )}
                        
                        {/* {this.props.canViewHybriqPromotion && (
                            <BannerHybriq />
                        )} */}
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(injectIntl(ProductCardV2))
