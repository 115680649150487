import React, { useState, useEffect } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import Button from '../../global/Button'
import DisplayPricePhase2 from '../../catalog/rectangleResult/displays/DisplayPricePhase2'
import { canAddCartLine, generateUnitsData, getMaxQtyLeftProduct } from '../utils'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'
import Translation from '../../global/Translation'

const AddToCartModalItem = ({ sap_landing_id, product, setProductSelectedToAdd, addCartLine, addProductInFetchedProducts, intl, commonParamsAnalytics, priceList, userPriceList, selectedCenter, userType, showToastNotification, lines }) => {
    const [unitsSelected, setUnitsSelected] = useState(null)
    const [errorUnits, setErrorUnits] = useState(false)

    useEffect(() => {
        setUnitsSelected(null)
        setErrorUnits(false)
    }, [setProductSelectedToAdd])

    return (
        <React.Fragment>
            <p className="mall__modal__title"><Translation id="select_cart_units" defaultMessage="Select units to add to cart" /></p>
            <div className="modal-card">
                <div className="row no-gutters modal-card--content">

                    {product && product.outlet && product.realStock > 0 &&
                        <DisplayOutletIndicator outletQuality={product.outletQuality} />
                    }

                    <div className="col-lg-6 col-md-5 col-12 modal-card--image" style={{ backgroundImage: `url(${product.detailImage})` }}></div>
                    <div className="col-lg-6 col-md-7 col-12 modal-card--text">
                        {product.brand === 'DEKTON' && selectedCenter === 7130 ?
                            <span>DKTN &reg;</span>
                            :
                            !!product.hasImage && (
                                <img
                                    className={`modal-card__brand modal-card__brand--image modal-card__brand--${product.brandWithoutSpaces}`}
                                    alt={product.brand}
                                    src={product.hasImage}
                                />
                            )
                        }
                        {!product.hasImage && product.brand && <p className="modal-card__brand modal-card__brand--text">{product.brand} &reg;</p>}
                        <p className="modal-card__name">{product.name}</p>
                        <div className="modal-card__data">
                            <div className="modal-card__data--specs">
                                <p className="modal-card__data--thickness">
                                    <i className="icon-thickness"></i>
                                    <span>{product.thickness}</span>
                                </p>
                                <p className="modal-card__data--finish">
                                    <i className="icon-finish"></i>
                                    <span>{product.finish}</span>
                                </p>
                            </div>
                            {product && userType !== 'ADMIN' ?
                                <p className="modal-card__data--price">
                                    <DisplayPricePhase2 product={product} />
                                </p>
                                :
                                <span><Translation id="price_not_available" defaultMessage="Price not available" /></span>
                            }
                        </div>
                        <div className="modal-card__order">
                            <div className="modal-card__order--left">
                                <p className="modal-card__order--stock">
                                    <strong>{intl.formatMessage({ id: 'stock' })}:</strong> {product.realStock}
                                </p>
                                <p className="modal-card__order--minimum">
                                    {product.minimumOrder > 0 && (
                                        <React.Fragment>
                                            <strong><Translation id="minimum_order" defaultMessage="Minimum order" />:</strong> {product.minimumOrder}
                                        </React.Fragment>
                                    )}
                                </p>
                            </div>
                            <div className="modal-card__order--right">
                                {product.format && (
                                    <p className="modal-card__order--format">
                                        <i className="fas fa-cut"></i>
                                        <span>{product.format}</span>
                                        <small><Translation id="max_format" defaultMessage="Max format" /></small>
                                    </p>
                                )}
                            </div>
                        </div>
                        {product.realStock > 0 &&
                            <div className="modal-card__buttons">
                                <DropDownList
                                    className={`modal-card__buttons__btn modal-card__buttons__btn--units ${errorUnits ? 'modal-card__buttons__btn--error' : ''}`}
                                    label="Unidades"
                                    data={generateUnitsData(product.minimumOrder || 1, product.realStock + 1)}
                                    value={unitsSelected}
                                    onChange={event => {
                                        setErrorUnits(!event.value)
                                        setUnitsSelected(event.value)
                                    }}
                                />
                                <Button
                                    size="medium"
                                    icon="shopping-cart"
                                    customClass="modal-card__buttons__btn"
                                    onClick={() => {
                                        addProductInFetchedProducts(product, parseInt(product.centerId, 10), intl.locale)
                                        if (!unitsSelected) {
                                            setErrorUnits(true)
                                            return
                                        }
                                        setErrorUnits(false)

                                        if (canAddCartLine(lines, product, unitsSelected)) {
                                            //this is going dupe, the ADD_CART_LINE_SUCCESS is making the event already
                                            // analytics.sendAddToCart(product, unitsSelected, priceList, userPriceList, commonParamsAnalytics)
                                            addCartLine(
                                                product.productId,
                                                unitsSelected,
                                                {
                                                    mall: true,
                                                    lots: !!product.productLots ? true : null,
                                                    outlet: product.outlet ? product.outletQuality : null,
                                                    sap_landing_id: sap_landing_id,
                                                    landing_id: product.landingId
                                                },
                                                null,
                                                product.finish === 'AND' && ['DEKTON', 'DKTN'].includes(product.brand),
                                                product.centerId
                                            )
                                            setProductSelectedToAdd(null)
                                        } else {
                                            const maxQty = getMaxQtyLeftProduct(lines, product);

                                            showToastNotification(maxQty > 0 ? 'Solo puedes añadir ' + maxQty + ' uds. más al carrito' : 'No se pueden añadir más unidades al carrito', 'error')
                                        }
                                    }}
                                >
                                    {intl.formatMessage({ id: 'add_to_cart' })}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddToCartModalItem
