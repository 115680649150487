import React from "react";
import Translation from "../../global/Translation";
import {defineMessages, injectIntl} from "react-intl";


defineMessages(({
    DISCOUNT_REQUESTED: {
        id: 'DISCOUNT_REQUESTED',
        description: 'DISCOUNT_REQUESTED',
        defaultMessage: 'Requested'
    },
    APPROVED: {
        id: 'APPROVED',
        description: 'APPROVED',
        defaultMessage: 'Approved'
    },
    DENIED: {
        id: 'DENIED',
        description: 'DENIED',
        defaultMessage: 'Denied'
    },
    NONE: {
        id: 'NONE',
        description: 'NONE',
        defaultMessage: 'Not needed'
    },
}))

class CampaignInfo extends React.Component {


    getCountries = () => {
        const countries = this.props.campaign.countries.split(',')
        let countriesString = ''
        countries.forEach((countyCode, i) => {
            countriesString += this.props.intl.formatMessage({ id: 'country_' + countyCode, defaultMessage: countyCode })
            if (countries.length - 1 < i) countriesString += ', '
        })
        return countriesString
    }


    render() {
        const {campaign} = this.props
        // const statusId = campaign.status.toLowerCase()
        // const statusMsg = statusId.replace(/^\w/, (c) => c.toUpperCase());

        return (
            <div style={{
                padding:'16px 64px',
                fontSize: '18px',
                lineHeight: '32px',
                color: 'black'
            }}>
                <div>
                    <b>
                        <Translation id="name" defaultMessage="Name"/>:&nbsp;
                    </b>
                    <span>{campaign.name}</span>
                </div>
                <div>
                    <b>
                        <Translation id="created_by" defaultMessage="Created by"/>:&nbsp;
                    </b>
                    <span>{campaign.created_by}</span>
                </div>
                <div>
                    <b>
                        <Translation id="market" defaultMessage="Market"/>:&nbsp;
                    </b>
                    <span>{campaign.market}</span>
                </div>
                <div>
                    <b>
                        <Translation id="countries" defaultMessage="Countries"/>:&nbsp;
                    </b>
                    <span>{this.getCountries()}</span>
                </div>
                <div className="d-flex">
                    <b>
                        <Translation id="centers" defaultMessage="Centers"/>:
                    </b>
                    <ul>
                    {
                        campaign.centersData.map(c => `(${c.id}) - ${c.name}`)
                    }
                    </ul>

                </div>
                <div>
                    <b>
                        <Translation id="start_date" defaultMessage="Start date"/>:&nbsp;
                    </b>
                    <span>{campaign.start_date}</span>
                </div>
                <div>
                    <b>
                        <Translation id="end_date" defaultMessage="End date"/>:&nbsp;
                    </b>
                    <span>{campaign.end_date}</span>
                </div>
                <div>
                    <b>
                        <Translation id="status" defaultMessage="Status"/>:&nbsp;
                    </b>
                    <span>
                        {
                            this.props.intl.formatMessage({id:campaign.status})
                        }
                        {/*<Translation id={statusId} defaultMessage={statusMsg}/>*/}
                    </span>
                </div>
            </div>
        )
    }


}

export default injectIntl(CampaignInfo)
