import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

class ElaborationType extends Component {

    render () {
        const {title, icon, description} = this.props

        return (
            <div className={"custom-products-card"}>
                <div className="custom-products-card__img">
                    <img src={icon} alt={title} />
                </div>
                <div className="custom-products-card__title">
                    <h5>{title}</h5>
                </div>
                <div className="custom-products-card__content">
                    <p>{description}</p>
                </div>
            </div>
        )
    }
}

export default injectIntl(ElaborationType)