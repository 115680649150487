import { connect } from 'react-redux'
import ProfileModal from './ProfileModal'
import { logout } from '../../store/login/actions'
import { closeProfileModal } from '../../store/ui/actions'
import { showingProfileModal } from '../../store/ui/reducers'
import { getUserName, getName, getUserImage, getUserType, getEmail } from '../../store/login/reducers'
import { getEmployeeId } from '../../store/big-account/reducers'
// import { fetchUnassignedReservations } from '../../store/api'
//import { countUnassignedReservations } from '../../store/reservations/reducers'

const mapStateToProps = state => {
    return {
        show: showingProfileModal(state),
        username: getUserName(state),
        name: getName(state),
        userimage: getUserImage(state),
        email: getEmail(state),
        employeeId: getEmployeeId(state),
        //totalUnassignedReservations: countUnassignedReservations(state),
        userType: getUserType(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => {
            dispatch(logout())
        },
        onClose: () => {
            dispatch(closeProfileModal())
        }
        // fetchUnassignedReservations: () => {
        //     dispatch(fetchUnassignedReservations())
        // }
    }
}

const ProfileModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileModal)

export default ProfileModalContainer
