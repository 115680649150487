import { injectIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

const FilterButton = props => {

    const [ isSelected, setIsSelected] = useState(props.isSelected)

    const onSelect = () => {
        let _isSelected
        if (props.toggle && props.isSelected) {
            _isSelected = !isSelected
        } else {
            _isSelected = true
        }
        setIsSelected(_isSelected)
        props.onSelect(_isSelected)
    }

    return (
        <button className={`filter__button ${props.isSelected ? 'is-selected' : ''} `}
                onClick={onSelect}
        >
            {props.label}
        </button>
    )
}

export default FilterButton