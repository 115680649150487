import React, { Component } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import GoBack from '../global/GoBack'
import InputNumber from '../global/InputNumber'
import Button from '../global/Button'
import { addCartLineCutToSize } from '../../store/cart/actions'
import Translation from '../global/Translation'
import { getCutToSizeSecondaryParams, getCutToSizeMaterials, getCutToSizeCatalog, getCutToSizeCustomerPricingGroup,getCutToSizeSoleriaColors } from '../../store/cut-to-size/reducers'
import { colorOption, colorValue } from './ColorOptions'
import { fetchCutToSizeSecondaryParams } from '../../store/cut-to-size/actions'
import ElaborationDetails from './ElaborationDetails'
import { Messages, BrandOptionsMap } from './CutToSizeMaps'
import FloatingAlert from '../alerts/FloatingAlert'
import { showToastNotification } from '../../store/toast-notifications/actions'
import {
    getAdaptedFormat,
    getCmOrInches,
    getMeasureFromMetric,
    getMetricMeasureFromInput
} from "../../helpers";
import LocalizedLink from "../global/LocalizedLink";
import {cloneDeep} from "lodash";
import {getAddonOptions, getFinishOptions, getThicknessOptions} from "./CalculateFromOptions";

const FILE_LIMIT = 4 //MB
const BYTES_IN_MB = 1024 * 1024
const DEFAULT_FILE_TYPE = 'BX01'


const mapStateToProps = state => {
    return {
        secondaryParams: getCutToSizeSecondaryParams(state),
        materials: getMaterials(state),
        catalog: getCutToSizeCatalog(state),
        soleriaColors: getCutToSizeSoleriaColors(state),
        pricingGroup: getCutToSizeCustomerPricingGroup(state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        units: getCmOrInches(state),
        measureFromMetric: (measure, decimals) => getMeasureFromMetric(measure, decimals, state),
        metricMeasureFromInput: (measure, decimals) => getMetricMeasureFromInput(measure, decimals, state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchSecondaryParams: (elaboration, brand, color, pricingGroup, hasSoleriaDekton, callback) => {
            dispatch(fetchCutToSizeSecondaryParams(elaboration, brand, color, pricingGroup, hasSoleriaDekton, callback))
        },
        addCartLineCutToSize: (qty, cutToSize) => {
            dispatch(addCartLineCutToSize(qty, cutToSize))
        },
        showToastNotification: (text = '', type = 'none', duration = 10000) => dispatch(showToastNotification(text, type, duration)),
    }
}

//Tratar genéricos como solo corte
const getMaterials = (state) => {
    const materials = Object.assign({}, getCutToSizeMaterials(state))
    if (materials) {
        materials['BUDGET'] = materials['SOLO_CORTE']
    }
    return materials
}

const CM_DECIMALS = 2
const IN_DECIMALS = 0
const MIN_WIDTH = 1
const MIN_HEIGHT = 1
const MAX_WIDTH = 270 
const MAX_HEIGHT = 170
const SECURITY_MARGIN = 5

const INITIAL_STATE = {
    selectedBrand: null,
    selectedColor: null,
    currentColorOptions: [],
    currentBrandOptions: [],
    width: null,
    height: null,
    selectedThickness: null,
    selectedFinished: null,
    selectedFormat: null,
    selectedQuality: null,
    selectedAddon: null,
    selectedFiles: {},
    quoteFile: null,
    showDetail: false,
    showExceedFileSizeAlert: false,
    comments: null
}

class CutToSizeDefault extends Component {

    _minWidth = this.props.measureFromMetric(MIN_WIDTH, IN_DECIMALS)
    _minHeight = this.props.measureFromMetric(MIN_HEIGHT, IN_DECIMALS)
    _securityMargin = this.props.measureFromMetric(SECURITY_MARGIN)

    constructor(props) {
        super(props)
        this.widthInput = React.createRef()
        this.heightInput = React.createRef()
        this.state = cloneDeep(INITIAL_STATE)
    }

    isSoleria = () => this.props.selectedElaboration === 'SOLERIA'
    isRodapie = () => this.props.selectedElaboration === 'RODAPIES'
    isJusCut = () => this.props.selectedElaboration === 'SOLO_CORTE'

    componentDidMount() {
       this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedElaboration !== this.props.selectedElaboration) {
            this.setState(cloneDeep(INITIAL_STATE), this.init)
        }
    }

    init = () => {
        Object.keys(this.props.materials[this.props.selectedElaboration]).forEach(brand => {
            this.state.currentBrandOptions.push(
                {
                    label: BrandOptionsMap[brand].name,
                    value: brand
                }
            )
        })

        this.fillColors()

        if (this.widthInput && this.widthInput.current) this.widthInput.current.addEventListener("keyup", (event) => {
                event.preventDefault();
                if (event.keyCode === 13) {
                    this.handleChangeWidth(event.target.value)
                }
            }
        )
        if (this.heightInput && this.heightInput.current) this.heightInput.current.addEventListener("keyup", (event) => {
                event.preventDefault();
                if (event.keyCode === 13) {
                    this.handleChangeHeight(event.target.value)
                }
            }
        )

        this.updateOptions()
        this.forceUpdate()
    }

    fillColors = () => {
        Object.entries(this.props.materials[this.props.selectedElaboration]).forEach(([brand,brandColors]) => {
            brandColors.forEach(colorKey => {
                const catalogColor = this.props.catalog[colorKey]
                if (catalogColor) {
                    this.state.currentColorOptions.push({
                        label: catalogColor.name + ' (' + brand + ')',
                        value: colorKey,
                        thumb: catalogColor.thumb,
                        brand: brand
                    })
                } 
                
                this.state.currentColorOptions.sort((a, b) => a.label.localeCompare(b.label))
            })
        }) 
    }

    setDimensionsFromFormat = (format) => {
        const s_format = format.split(/[xX]/)
        this.setState({width: s_format[1], height: s_format[0]})
    }

    resetProducts = () => {
        this.setState({
            selectedBrand: null,
            selectedColor: null,
            selectedFormat: null,
            selectedThickness: null,
            selectedFinished: null,
            selectedAddon: null
        })

        for (let m in this.state.selectedFiles) delete this.state.selectedFiles[m]
        for (let o in this.state.selectedModelOption) delete this.state.selectedModelOption[o]
        const fileInputs = window.top.document.querySelectorAll('.attach-file')
        if (fileInputs) fileInputs.forEach(i => i.value = '')
        const radio = window.top.document.querySelectorAll('input[type="radio"]')
        if (radio) radio.forEach(r => r.checked = false)
        const text = window.top.document.querySelectorAll('input[type="text"]')
        if (text) text.forEach(r => r.value = null)
        this.qtyToAdd = 1
        if (this.widthInput.current) this.widthInput.current.value = null
        if (this.heightInput.current) this.heightInput.current.value = null
    }

    updateOptions = () => {
        if (this.state.currentBrandOptions.length === 1 && this.state.currentBrandOptions[0].value != this.state.selectedBrand) this.handleSelectBrand(this.state.currentBrandOptions[0])
        if (this.state.currentColorOptions.length === 1 && this.state.currentColorOptions[0].value != this.state.selectedColor) this.handleSelectColor(this.state.currentColorOptions[0])
    }

    handleSelectBrand = brandOption => {
        const brand = brandOption ? brandOption.value : null
        const { materials, catalog, selectedElaboration } = this.props
        this.resetProducts()
        this.state.currentColorOptions.length = 0

        if (brand) {
            const materialColors = materials[selectedElaboration][brand]

            materialColors.forEach(color => {
                if (catalog[color]) {
                    this.state.currentColorOptions.push({
                        label: catalog[color].name,
                        value: color,
                        thumb: catalog[color].thumb,
                        brand: brand
                    })
                }
            })  
            this.state.currentColorOptions.sort((a, b) => a.label.localeCompare(b.label))          
        } else this.fillColors()

        this.setState({ selectedBrand: brand }, this.updateOptions)        
    }

    handleSelectColor = colorOption => {
        if (colorOption) {
            if (this.props.secondaryParams) this.props.secondaryParams.length = 0
            this.props.fetchSecondaryParams(
                this.props.selectedElaboration,
                colorOption.brand,
                colorOption.value,
                this.props.pricingGroup,
                this.isSoleria() && colorOption.brand === 'DEKTON' && this.props.soleriaColors.includes(colorOption.value),
                () => {
                    this.setState({
                        selectedFormat: null,
                        selectedThickness: null,
                        selectedFinished: null,
                        selectedAddon: null,
                        width: null,
                        height: null,
                        selectedColor: colorOption && colorOption.value,
                        selectedBrand: colorOption.brand
                    })
                }
            )
        } else {
            this.setState({
                selectedFormat: null,
                selectedThickness: null,
                selectedFinished: null,
                selectedAddon: null,
                width: null,
                height: null,
                selectedColor: null
            })
        }
    }

    handleSelectFinish = finishOptions => {
        // console.log('SECOND hANDLE' , finishOptions)
        const finish = finishOptions && finishOptions.value
        if (finish === this.state.selectedFinished) return
        this.setState({ selectedFinished: finish })
        if (!finish) {
            this.setState({ selectedThickness: null, selectedAddon: null })
        }
    }

    handleSelectThickness = thicknessOption => {
        const thickness = thicknessOption && thicknessOption.value
        if (thickness === this.state.selectedThickness) return

        this.setState({ selectedThickness: thickness })
    }

    handleSelectFormat = formatOption => {
        const format = formatOption && formatOption.value
        if (format === this.state.selectedFormat) return

        if (format) {
            this.setDimensionsFromFormat(format)
        }
        this.setState({ selectedFormat: format })
    }

    handleSelectAddon = addonOption => {
        const addon = addonOption && addonOption.value
        if (addon === this.state.selectedAddon) return

        this.setState({ selectedAddon: addon })
    }

    handleRodapiesWidth = widthOption => {
        // console.log('SECOND rod' , widthOption)
        const width = widthOption && widthOption.value
        if (width === this.state.selectedFormat) return
        this.setState({ width: width, selectedFormat: width })
    }

    handleChangeWidth = (value, ext) => {
        value = Math.max(Math.min(value, this._maxWidth), this._minWidth)
        this.setState({ width: value })
        if (this.widthInput.current) this.widthInput.current.value = value
    }

    handleChangeHeight = (value, ext) => {
        value = value && Math.max(Math.min(value, this._maxHeight), this._minHeight)
        this.setState({ height: value })
        if (this.heightInput.current) this.heightInput.current.value = value
    }

    disableSendButton = () => {
        return !this.state.selectedBrand ||
                !this.state.selectedColor ||
                !this.state.selectedThickness ||
                !this.state.selectedFinished ||
                !this.state.width ||
                !this.state.height ||
                !this.state.selectedAddon ||
                (this.isSoleria() && !this.state.selectedFormat)
    }

    onFileChange = async (event, type) => {        
        const file = event.target.files[0];
        if (file) {
            const fileSizeInMB = file.size / BYTES_IN_MB
            if (fileSizeInMB > FILE_LIMIT) {
                this.setState({showExceedFileSizeAlert: true})
                event.target.value='';
            } else {
                this.state.selectedFiles[type] = file;
            }            
        } else {
            this.state.selectedFiles[type] = null;
        }  
    }

    getFormatOptions = () => {
        let filterOptions
        if (this.state.selectedColor && this.props.secondaryParams && this.props.secondaryParams.relations
            && (this.isSoleria() || this.isRodapie())) {

            filterOptions = this.props.secondaryParams.relations.filter((p) => {
                if (p.format.toUpperCase() === 'N/A') return false
                let check = true;
                if (this.state.selectedFinished) check = check && p.finish === this.state.selectedFinished
                if (this.state.selectedAddon) check = check && p.addon === this.state.selectedAddon
                if (this.state.selectedThickness) check = check && p.thickness === this.state.selectedThickness
                return check
            })
            filterOptions = [...new Set(filterOptions.map(v => v.format))]

            if (this.isSoleria()) {
                filterOptions = filterOptions.sort((a, b) => {
                    const fa = a.split(/[xX]/)
                    const fb = b.split(/[xX]/)
                    const firstComp = parseInt(fa[0]) - parseInt(fb[0])
                    if (firstComp != 0) return firstComp
                    return parseInt(fa[1]) - parseInt(fb[1])
                })
                filterOptions = filterOptions.map(format => {
                    return {
                        label: this.props.adaptedFormat(format, {showUnits:true}),
                        value: format
                    }
                })
                // if (filterOptions.length === 1) this.handleSelectFormat(filterOptions[0])
            }

            if (this.isRodapie()) {
                filterOptions = filterOptions.map(height => {
                    return {
                        label: `${this.props.measureFromMetric(height, IN_DECIMALS)} ${this.props.units}`,
                        value: height
                    }
                })
                // if (filterOptions.length === 1) this.handleRodapiesWidth(filterOptions[0])
            }
        }

        return filterOptions
    }

    getSku = () => {
        if (this.props.selectedElaboration === 'SOLERIA' && this.state.selectedBrand === 'DEKTON') {

            const material = this.props.secondaryParams.relations.find(m =>
                m.finish === this.state.selectedFinished &&
                m.thickness === this.state.selectedThickness &&
                m.format === this.state.selectedFormat  
            )

            return material ? material.productId : null
        }

        return null
    }

    getRawFormat = () => {
        const relation = this.props.secondaryParams.relations.find(m =>
            m.finish === this.state.selectedFinished &&
            m.thickness === this.state.selectedThickness &&
            m.format === this.state.selectedFormat
        )

        return relation ? relation.raw_format : null
    }

    submitCartLine = () => {
        let width = parseFloat(this.state.width)
        let height = parseFloat(this.state.height)

        if (this.isJusCut() || this.isRodapie()) {
            height = this.props.metricMeasureFromInput(height, CM_DECIMALS)
        }
        if (this.isJusCut()) {
            width =this.props.metricMeasureFromInput(width, CM_DECIMALS)
        }

        this.props.addCartLineCutToSize(
            this.qtyToAdd, {
                elaboration: this.props.selectedElaboration,
                colorId: this.state.selectedColor,
                finish: this.state.selectedFinished,
                thickness: this.state.selectedThickness,
                format: this.getRawFormat(),
                addon: this.state.selectedAddon,
                // type: this.state.selectedType,
                width: Math.round((width + Number.EPSILON) * 100) / 100,
                height: Math.round((height + Number.EPSILON) * 100) / 100,
                files: this.state.selectedFiles,
                sku: this.getSku(),
                brand: this.state.selectedBrand,
                colorName: this.props.catalog[this.state.selectedColor].name,
                thumb: this.props.catalog[this.state.selectedColor].img,
                units: this.props.units
        })
    }

    qtyToAdd = 1;

    render() {
        console.log('SECOND state' , this.state)

        const { selectedBrand, selectedColor, currentBrandOptions, currentColorOptions } = this.state
        const { intl, selectedElaboration, secondaryParams, catalog, units} = this.props

        if (!selectedElaboration) return null

        const finishesOptions = getFinishOptions(this.props, this.state)
        const thicknessOptions = getThicknessOptions(this.props, this.state)
        const addonsOptions = getAddonOptions(this.props, this.state)
        const formatsOptions = this.getFormatOptions()

        // console.log('SECOND finishesOptions' , finishesOptions)
        // console.log('SECOND thicknessOptions' , thicknessOptions)
        // console.log('SECOND addonsOptions' , addonsOptions)

        if (addonsOptions && addonsOptions.length === 1) this.handleSelectAddon(addonsOptions[0])
        if (thicknessOptions && thicknessOptions.length === 1) this.handleSelectThickness(thicknessOptions[0])
        if (thicknessOptions && finishesOptions.length === 1) this.handleSelectFinish(finishesOptions[0])

        // const rodapiesHeightOptions = _currentFormats && this.isRodapie() && _currentFormats.map(format => {
        //     const height = format.split('x')[0]
        //     return {
        //         label: `${this.props.measureFromMetric(height, IN_DECIMALS)} ${units}`,
        //         value: height
        //     }
        // })
        //
        // const formatsOptions = this.isSoleria() && selectedColor && _currentFormats && _currentFormats.map(format => {
        //     return {
        //         label: this.props.adaptedFormat(format, {showUnits:true}),
        //         value: format
        //     }
        // })


        if (catalog && catalog[selectedColor] && /\d+[xX]\d+/.exec(catalog[selectedColor].format)) {
            const maxValues = catalog[selectedColor].format.split(/[xX]/)
            if (maxValues[0]) maxValues[0] -= this._securityMargin
            if (maxValues[1]) maxValues[1] -= this._securityMargin
            this._maxWidth = Math.max(maxValues[0], maxValues[1])
            this._maxHeight = this.isRodapie() ? this._maxWidth : Math.min(maxValues[0], maxValues[1])
        } else {
            this._maxWidth = MAX_WIDTH
            this._maxHeight = this.isRodapie() ? MAX_WIDTH : MAX_HEIGHT
        }
        this._maxWidth = this.props.measureFromMetric(this._maxWidth, IN_DECIMALS)
        this._maxHeight = this.props.measureFromMetric(this._maxHeight, IN_DECIMALS)

        return (
            <section className="cut_to_size">

                <div className="cut_to_size__go_back mt-4 mb-3">
                    <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'}>
                        <GoBack
                            text={intl.formatMessage(Messages.BACK_SELECT_ELABORATED_TYPE)}
                            dataCY={'product_detail__go_back'}
                        />
                    </LocalizedLink>
                </div>

                <div className="container form">                   

                    <div className="row">

                        <div className="col-12 col-md-7">
                            <div style={{width:'100%'}}>
                                <ElaborationDetails id={selectedElaboration}/>
                            </div>
                        </div>

                        <div className="col-12 col-md-5 cut_to_size__filters">

                            {/**************** MARCA **********************/}
                            { selectedElaboration && (
                                <div className="group__input">
                                    <label htmlFor="brand-select" className="form-label">
                                        <Translation id="brand" defaultMessage="Brand" />
                                    </label>
                                    <div className="contain__select--brand" data-qas="cts_form_default-brand-dropdown">
                                        <Select
                                            id="brand-select"
                                            placeholder={this.props.intl.formatMessage(Messages.CHOOSE_BRAND)}
                                            options={currentBrandOptions}
                                            value={selectedBrand}
                                            onChange={selectedOption => this.handleSelectBrand(selectedOption)}
                                            disabled={!currentBrandOptions || currentBrandOptions.length <= 1}
                                            className={`${!currentBrandOptions || currentBrandOptions.length <=1 ? ' disabled' : ' '}`}
                                        />
                                    </div>
                                </div>
                            )}

                            {/**************** COLORES **********************/}
                            <div className="group__input">
                                <label htmlFor="color-select" className="form-label">
                                    <Translation id="color" defaultMessage="Color" />
                                </label>
                                <div data-qas="cts_form_default-color-dropdown">
                                    <Select
                                        id="color-select"
                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_COLOR)}
                                        options={currentColorOptions}
                                        value={selectedColor}
                                        onChange={selectedOption => this.handleSelectColor(selectedOption)}
                                        optionComponent={colorOption}
                                        valueComponent={colorValue}
                                        disabled={!currentColorOptions || currentColorOptions.length <= 1}
                                        className={`${!currentColorOptions || currentColorOptions.length <=1 ? ' disabled' : ' '}`}
                                    />
                                </div>
                            </div>

                            {/**************** ACABADOS **********************/}
                            <div className="group__input group__input--finish">
                                <label htmlFor="finish-select" className="form-label">
                                    <Translation id="finish" defaultMessage="Finish" />
                                </label>
                                <div data-qas="cts_form_default-finish-dropdown">
                                    <Select
                                        id="finish-select"
                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_FINISH)}
                                        options={finishesOptions}
                                        value={this.state.selectedFinished}
                                        onChange={selectedOption => this.handleSelectFinish(selectedOption)}
                                        disabled={!finishesOptions || finishesOptions.length === 0}
                                        className={`capitalize ${!finishesOptions || finishesOptions.length === 0 ? ' disabled' : ''}`}
                                        // clearable={finishesOptions && finishesOptions.length > 1}
                                    />
                                </div>
                            </div>

                            {/**************** GROSOR **********************/}
                            <div className="group__input group__input--thickness">
                                <label htmlFor="thickness-select" className="form-label">
                                    <Translation id="thickness" defaultMessage="Thickness" />
                                </label>
                                <div data-qas="cts_form_default-thickness-input">
                                    <Select
                                        id="thickness-select"
                                        // clearable={false}
                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_THICKNESS)}
                                        options={thicknessOptions}
                                        value={this.state.selectedThickness}
                                        onChange={selectedOption => this.handleSelectThickness(selectedOption)}
                                        disabled={!thicknessOptions || thicknessOptions.length <= 1}
                                        className={`${!thicknessOptions || thicknessOptions.length === 0 ? ' disabled' : ''}`}
                                        // clearable={thicknessOptions && thicknessOptions.length > 1}
                                    />
                                </div>
                            </div>



                            {/**************** ADDONS **********************/}
                            <div className="group__input group__input--addon">
                                <label htmlFor="addon-select" className="form-label">
                                    <Translation id="MAL" defaultMessage="Meshed" />
                                </label>
                                <div data-qas="cts_form_default-addon-dropdown">
                                    <Select
                                        id="addon-select"
                                        placeholder={this.props.intl.formatMessage(Messages.CHOOSE_OPTION)}
                                        options={addonsOptions}
                                        value={this.state.selectedAddon}
                                        onChange={selectedOption => this.handleSelectAddon(selectedOption)}
                                        disabled={!addonsOptions || addonsOptions.length <= 1}
                                        className={`capitalize ${!addonsOptions || addonsOptions.length === 0 ? ' disabled' : ''}`}
                                        // clearable={addonsOptions && addonsOptions.length > 1}
                                    />
                                </div>
                            </div>
                            

                            {/**************** FORMATO **********************/}
                            {
                                this.isSoleria() && (
                                    <div className="group__input group__input--format">
                                        <label htmlFor="format-select" className="form-label">
                                            <Translation id="format" defaultMessage="Format" />
                                        </label>
                                        <div data-qas="cts_form_default-soleria-format-dropdown">
                                            <Select
                                                id="format-select"
                                                placeholder={this.props.intl.formatMessage(Messages.CHOOSE_FORMAT)}
                                                options={formatsOptions}
                                                value={this.state.selectedFormat}
                                                onChange={selectedOption => this.handleSelectFormat(selectedOption)}
                                                // onChange={selectedOption => this.handleFilterChange(selectedOption, 'selectedThickness')}
                                                disabled={!formatsOptions || formatsOptions.length === 0}
                                                className={`${formatsOptions && formatsOptions.length > 0 ? ' ' : ' disabled'}`}
                                                // clearable={formatsOptions && formatsOptions.length > 1}
                                            />
                                        </div>
                                    </div>
                                )
                            }

                            {/************ FORMATO RODAPIES ******************/}
                            {
                                this.isRodapie() && (      
                                    <div className="row group__input">
                                        <div className="col-6 group__input--height" lang="en-US">
                                            <label htmlFor="length-input" className="form-label">
                                                <Translation id="length" defaultMessage="Length" /> ({units})
                                            </label>
                                            <input
                                                type="number"
                                                id="length-input"
                                                ref={this.heightInput}
                                                step={0.1}
                                                // min={this._minWidth}
                                                // max={this._maxWidth}
                                                placeholder={this.props.intl.formatMessage({ id: 'length' })}
                                                required
                                                // value={width}
                                                // onChange={event => this.handleChangeWidth(event.target.value)}
                                                onBlur={event => this.handleChangeHeight(event.target.value)}
                                                // onChange={event => this.setState({ width: event.target.value })}
                                                disabled={!selectedColor}
                                                className={`form-control ${selectedColor ? ' ' : ' disabled'}`}
                                                data-qas="cts_form_default-rodapie-length-input"
                                            />
                                        </div>
                                        <div className="col-6 group__input--format">
                                            <label htmlFor="sk-format-select" className="form-label">
                                                <Translation id="width" defaultMessage="Width" /> ({units})
                                            </label>
                                            <div data-qas="cts_form_default-rodapie-width-dropdown">
                                                <Select                                            
                                                    id="sk-format-select"
                                                    placeholder={this.props.intl.formatMessage(Messages.CHOOSE_WIDTH)}
                                                    options={formatsOptions}
                                                    value={this.state.selectedFormat}
                                                    onChange={selectedOption => this.handleRodapiesWidth(selectedOption)}
                                                    //onChange={selectedOption => this.handleFilterChange(selectedOption, 'selectedThickness')}
                                                    disabled={!formatsOptions || formatsOptions.length === 0}
                                                    className={`${formatsOptions && formatsOptions.length > 0 ? ' ' : ' disabled'}`}
                                                    // clearable={formatsOptions && formatsOptions.length > 1}
                                                />
                                            </div>
                                        </div> 
                                        <div className="col-12">
                                            <small>
                                                { this.props.intl.formatMessage(Messages.MAX_LENGTH)}: {this._maxHeight} {units}
                                                {/* { this.props.intl.formatMessage(Messages.MAX_LENGTH)}: <CalculateMeasure cm={this._maxWidth} withUnit={true} /> */}
                                            </small>
                                        </div>
                                    </div>
                                )
                            }
                            

                            {/**************** CALIDAD **********************/}
                            {/* <div className="group__input group__input--quality">
                            <span>
                                <Translation id="quality" defaultMessage="Quality" />
                            </span>
                            <Select
                                placeholder="Choose Quality"
                                options={qualityOptions}
                                value={this.state.selectedQuality}                                                
                                onChange={selectedOption => this.setState({selectedQuality : selectedOption && selectedOption.value})}
                                // onChange={selectedOption => this.handleFilterChange(selectedOption, 'selectedThickness')}
                                disabled={ !qualityOptions || qualityOptions.length === 0 }
                                className={`${qualityOptions && qualityOptions.length > 0 ? ' ' : ' disabled'}`}
                            />
                        </div> */}

                            {/**************** DIMENSIONES SOLO CORTE **********************/}
                            {
                                this.isJusCut() && (
                                    <div className="row group__input">
                                        <div className="col-6">
                                            <label htmlFor="width-input" className="form-label">
                                                <Translation id="length" defaultMessage="Length" /> ({units})
                                            </label>
                                            <input
                                                type="number"
                                                id="width-input"
                                                ref={this.widthInput}
                                                step={0.1}
                                                placeholder={this.props.intl.formatMessage({id: 'length'})}
                                                required
                                                // value={width}
                                                // onChange={event => this.handleChangeWidth(event.target.value)}
                                                onBlur={event => this.handleChangeWidth(event.target.value)}
                                                // onChange={event => this.setState({ width: event.target.value })}
                                                disabled={!selectedColor}
                                                className={`form-control ${selectedColor ? ' ' : ' disabled'}`}
                                                data-qas="cts_form_default-just-cut-length-input"
                                            />
                                        </div>  
                                        <div className="col-6">
                                            <label htmlFor="height-input" className="form-label">
                                                <Translation id="width" defaultMessage="Width" /> ({units})
                                            </label>
                                            <input
                                                type="number"
                                                id="height-input"
                                                ref={this.heightInput}
                                                step={0.1}
                                                placeholder={this.props.intl.formatMessage({id: 'width'})}
                                                required
                                                // value={height}
                                                onBlur={event => this.handleChangeHeight(event.target.value)}
                                                // onChange={event => this.setState({ height: event.target.value })}
                                                disabled={!selectedColor}
                                                className={`form-control ${selectedColor ? ' ' : ' disabled'}`}
                                                data-qas="cts_form_default-just-cut-width-input"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <small>
                                                { this.props.intl.formatMessage(Messages.MAX_DIMENSIONS)}: {this._maxWidth} x {this._maxHeight} {units}
                                                {/* { this.props.intl.formatMessage(Messages.MAX_DIMENSIONS)}: <CalculateMeasure cm={this._maxWidth}/> x <CalculateMeasure cm={this._maxHeight}/> <CalculateMeasure/> */}
                                            </small>
                                        </div>
                                    </div>
                                )
                            }



                            {/**************** ADJUNTAR DOCUMENTO **********************/}
                            <div className="colgroup__input">
                                <label htmlFor="attach_file" className="form-label">
                                    <Translation id="document" defaultMessage="Document" />
                                </label>
                                <div>
                                    <input 
                                        id="attach_file"
                                        className="attach-file"
                                        type="file" 
                                        onChange={(e) => this.onFileChange(e, DEFAULT_FILE_TYPE)} 
                                        data-qas="cts_form_default-attach-file-input"
                                        // accept="image/jpeg, image/png, application/pdf"
                                    />
                                </div>
                                <small>
                                    <Translation id="exceed_file_limit" 
                                            defaultMessage={`The file has a limit of {fileLimit} MB`}
                                            values={{fileLimit: FILE_LIMIT}}/>
                                </small>
                            </div>

                            {/**************** COMENTARIOS **********************/}
                            {/*{*/}
                            {/*    this.isBudget() && (*/}
                            {/*        <div className="group__input">*/}
                            {/*            <label htmlFor="input-comments" className="form-label">*/}
                            {/*                <Translation id="comment" defaultMessage="Comments" />*/}
                            {/*            </label>*/}
                            {/*            <textarea*/}
                            {/*                className="form-control"*/}
                            {/*                id="input-comments"*/}
                            {/*                rows="3"*/}
                            {/*                maxLength="2499"*/}
                            {/*                onChange={e => {*/}
                            {/*                    e && this.setState({comments:e.target.value})*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        </div> */}
                            {/*    )*/}
                            {/*}*/}
                           

                            <div className="row">
                                {/**************** CANTIDAD **********************/}
                                <div className="col-6 group__input">
                                    <div>
                                        <label htmlFor="quantity-input" className="form-label">
                                            <Translation id="quantity" defaultMessage="Quantity" />
                                        </label>
                                        <InputNumber
                                            id="quantity-input"
                                            className="form-control"
                                            value={this.qtyToAdd}
                                            change={qty => {
                                                this.qtyToAdd = qty
                                                this.forceUpdate()
                                            }}
                                            step={1}
                                            dataQas="cts_form_default-quantity-input"
                                            oldStyle={true}
                                        />
                                    </div>
                                </div>
                                {/**************** PRECIO **********************/}
                                <div className="col-6 group__input">
                                    {/*<div>*/}
                                    {/*    <label htmlFor="price-text" className="form-label" data-qas="cts_form_default-price-input">*/}
                                    {/*        <Translation id="price" defaultMessage="Price" />*/}
                                    {/*    </label>*/}
                                    {/*    <div*/}
                                    {/*        id="price-text"*/}
                                    {/*        className="form-control text-right"*/}
                                    {/*        style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}*/}
                                    {/*    >*/}
                                    {/*        {*/}
                                    {/*            secondaryParams && secondaryParams.unitaryPrice ? (*/}
                                    {/*                `${secondaryParams.unitaryPrice * this.qtyToAdd}€`*/}
                                    {/*            ) : (*/}
                                    {/*                <Translation id="price_not_available" defaultMessage="Price not available" />*/}
                                    {/*            )*/}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>


                            {/**************** AÑADIR AL CARRITO **********************/}
                            <div className="mt-1">
                                <Button
                                    customClass="bt-dark gt"
                                    icon="shopping-cart"
                                    dataCY="product_detail__add"
                                    disabled={this.disableSendButton()}
                                    onClick={this.submitCartLine}
                                    dataQas="cts_form_default-add-to-cart-button"
                                    gtmLabel="ec_cam_preorder"
                                >
                                    <Translation id="add" defaultMessage="Add" />
                                </Button>
                            </div>



                        </div> {/*** cut_to_size__filters */}

                        {
                            this.state.showExceedFileSizeAlert && (
                            <FloatingAlert
                                warningMessage={
                                    <Translation id="exceed_file_limit" 
                                    defaultMessage={`The file has a limit of {fileLimit} MB`}
                                    values={{fileLimit: FILE_LIMIT}}/>
                                }
                                onClose={() => {this.setState({showExceedFileSizeAlert: false})}}
                            />
                        )}

                    </div>
                </div>
            </section>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CutToSizeDefault)
)
