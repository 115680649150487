import { connect } from 'react-redux'
import { getProductsForYou } from '../../store/products/reducers'
import { getIsLogged } from '../../store/login/reducers'
import Translation from "../global/Translation";
import React from "react";
import ProductsSlider from "./ProductsSlider";
import { fetchProductsForYou } from "../../store/products/actions";
import { canViewMktools } from "../../store/centers/reducers";

const mapStateToProps = state => {
    return {
        products: getProductsForYou(state),
        hide: !getIsLogged(state) || canViewMktools(state),
        title: <Translation id="products_for_you" defaultMessage="Products for you" />,
        analyticsTag: 'recomended_products_cross',
        gtmLabel: 'ec_carrusel_products_for_you'
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetch: () => {
            return dispatch(fetchProductsForYou())
        }
    }
}

const ProductsForYouContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider)


export default ProductsForYouContainer
