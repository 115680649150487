import React from 'react'
import LocalizedLink from '../../global/LocalizedLink'
import { ReactComponent as OrderHistoryIcon } from '../../../assets/img/order-history/order-history.svg'
import { ReactComponent as ShippingTruckIcon } from '../../../assets/img/order-history/shipping-truck.svg'
import Translation from '../../global/Translation'

class NavigationTabs extends React.Component {
    render() {
        return (            
            <div className="header__tabs">
                <div className="header__tabs__buttons">
                    <LocalizedLink
                        className={`tab__link ${window.location.pathname.indexOf('orders') !== -1 ? 'active' : ''}`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'orders', open: '' }}
                        gtmLabel="ec_historial_pedidos"
                    >
                        <OrderHistoryIcon />
                        <span><Translation id="orders" defaultMessage="Orders" /></span>
                    </LocalizedLink>

                    <LocalizedLink
                        className={`tab__link ${window.location.pathname.indexOf('shipping-history') !== -1 ? 'active' : ''}`}
                        routeId="ROUTE_PROFILE"
                        params={{ filter: 'shipping-history', open: '' }}
                        gtmLabel="ec_historial_envios"
                    >
                        <ShippingTruckIcon />
                        <span><Translation id="shippings" defaultMessage="Shippings" /></span>
                    </LocalizedLink>
                </div>
            </div>
        )
    }
}

export default NavigationTabs