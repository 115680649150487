import * as api from '../api'
import { getSelectedCenter } from '../centers/reducers'
import { getAdvertisements} from './reducers'

export const fetchAdvertisements = () => (dispatch, getState) => {

    const state = getState();
    // const advs = getAdvertisements(state)

    const center = getSelectedCenter(state)

    dispatch({type: 'FETCH_ADVERTISEMENTS', center})

    return api.fetchAdvertisements(center)
        .then(response => {
            let advertisements = null
            if (response.data) advertisements = response.data
            dispatch({type: 'FETCH_ADVERTISEMENTS_SUCCESS', advertisements })
        })
        .catch(error => {
            dispatch({type: 'FETCH_ADVERTISEMENTS_FAILURE'})
        })
}