import { connect } from 'react-redux'
import DirectSlabAccess from './DirectSlabAccess'
import { getDirectSlabAccess, getDirectSlabAccessLoading, getReservationId, getInvoiceId } from '../../store/direct-slab-access/reducers'
import { injectIntl } from 'react-intl'
import { fetchDirectSlabAccess } from '../../store/direct-slab-access/actions'
import { getToken } from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import { getLotsImages } from '../../store/lots/reducers'
import { getFileByUrl } from '../../store/orders/actions'

const mapStateToProps = (state, { match }) => {
    return {
        directSlabAccess: getDirectSlabAccess(state),
        key: getDirectSlabAccess(state),
        isLoading: getDirectSlabAccessLoading(state),
        token: getToken(state),
        lang: getLanguage(state),
        reservationId: getReservationId(state),
        invoiceId: getInvoiceId(state),
        lotsImages: getLotsImages(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDirectSlabAccess: (reservationIdValue, invoiceIdValue) => {
            dispatch(fetchDirectSlabAccess(reservationIdValue, invoiceIdValue))
        },
        getFileByUrl: url => {
            dispatch(getFileByUrl(url))
        }
    }
}

const DirectSlabAccessContainer = injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DirectSlabAccess)
)

export default DirectSlabAccessContainer
