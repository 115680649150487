import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import {
    getSelectedBusinesSegment,
    getSelectedChannel,
    getSelectedSubchannel,
    getSelectedTypology,
    getTypologyOptions,
    getCurrentSegmentation
} from '../../store/segmentation/reducers'
import { toggleSegmentation, selectTypology } from '../../store/segmentation/actions'
const mapStateToProps = state => {
    const businessSegment = getSelectedBusinesSegment(state)
    const channel = getSelectedChannel(state)
    const subchannel = getSelectedSubchannel(state)

    return {
        show: getCurrentSegmentation(state) === 'TYPOLOGY',
        selected: getSelectedTypology(state),
        options: getTypologyOptions(state, businessSegment, channel, subchannel)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('TYPOLOGY'))
        },
        onSelect: (value, name) => {
            dispatch(selectTypology(value, name))
        }
    }
}
const Typology = ({ show, selected, onToggle, onSelect, options }) => {
    if (!options || Object.entries(options).length === 0) {
        return ''
    }
    if (Object.keys(options).length === 1 && selected === null) {
        Object.entries(options).map(option => onSelect(option[0], option[1].name))
    }
    const selectedValue = selected ? <span>{options[selected].name}</span> : null
    return (
        <div>
            <div onClick={onToggle} className="option">
                <Translation id="typology" defaultMessage="Typology" /> : {selectedValue}
                <i className={show ? 'fa fa-caret-down' : 'fa fa-caret-right'} aria-hidden="true" />
                <br />
            </div>

            <div style={{ display: show ? 'block' : 'none' }}>
                <div className="row segment-selections">
                    {Object.entries(options).map(option => (
                        <span
                            key={option[0]}
                            onClick={() => {
                                onSelect(option[0], option[1].name)
                            }}
                        >
                            {option[1].name}
                        </span>
                    ))}
                </div>
            </div>
            <hr />
        </div>
    )
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Typology)
