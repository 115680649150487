import React from 'react'

import PropTypes from 'prop-types'
import { confirmable } from 'react-confirm'
import Button from './Button'

class ConfirmDialogIkeaCenter extends React.Component {
    constructor(props) {
        super(props)
        this.state = { employeeId: this.props.options.employeeId }
        this.form = React.createRef()
    }

    render() {
        let { show, proceed, cancel, confirmation, options } = this.props
        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
                <div id="confirm-container" className="confirm" style={{ display: show ? 'block' : 'none' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 container">
                                <header className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div>
                                                <h2>{confirmation}</h2>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group employee-id">
                                                            <form
                                                                ref={this.form}
                                                                onSubmit={e => {
                                                                    e.preventDefault()
                                                                    if (
                                                                        (!HTMLFormElement.prototype.reportValidity && /[0-9]{8}/.test(this.state.employeeId)) ||
                                                                        this.form.current.reportValidity()
                                                                    )
                                                                        options.setEmployeeId(this.state.employeeId).then(() => proceed('same as cancel'))
                                                                }}
                                                            >
                                                                <label htmlFor="employee-id">
                                                                    <span>{this.props.strEmployeeId}</span>:
                                                                </label>{' '}
                                                                <input
                                                                    data-cy="ikea__input_employeeId-center_select"
                                                                    pattern="[0-9]{8}"
                                                                    title="8 digits (12345678)"
                                                                    placeholder="12345678"
                                                                    required
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            employeeId: e.target.value
                                                                        })
                                                                    }
                                                                    defaultValue={options.employeeId}
                                                                    name="employee-id"
                                                                    type="text"
                                                                />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-md-3 offset-md-3">
                                                        <Button onClick={() => cancel('arguments will be passed to the callback')} datacy="confirm_dialog__cancel">
                                                            CANCEL
                                                        </Button>
                                                    </div>
                                                    <div className="col-6 col-md-3">
                                                        <Button
                                                            disabled={!(this.state.employeeId && this.state.employeeId !== '')}
                                                            onClick={() => {
                                                                if (
                                                                    (!HTMLFormElement.prototype.reportValidity && /[0-9]{8}/.test(this.state.employeeId)) ||
                                                                    this.form.current.reportValidity()
                                                                )
                                                                    options.setEmployeeId(this.state.employeeId).then(() => proceed('same as cancel'))
                                                            }}
                                                            datacy="confirm_dialog__ok"
                                                        >
                                                            OK
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
ConfirmDialogIkeaCenter.propTypes = {
    show: PropTypes.bool, // from confirmable. indicates if theyarn start dialog is shown or not.
    proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object // arguments of your confirm function
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmDialogIkeaCenter)
