import * as api from '../api'
import { getToken } from '../login/reducers'
import { getSelectedCenter, getIsLowesCenter } from '../centers/reducers'
import { getLanguage, getLanguageToFetchZ2 } from '../ui/reducers'
import { IntlProvider, load, LocalizationProvider, loadMessages, IntlService } from '@progress/kendo-react-intl'
import { parseLandingStatus } from '../../components/landing-manager/utils'
import { getPreviewPorfolioMode } from './reducers'
import moment from 'moment'

const DATE_FORMAT = 'yyyy-mm-dd hh:mm:ss.SSS'
const intl = new IntlService()

export const fetchMarkets = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_MARKETS_REQUEST' })
    return api
        .fetchMarkets(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_MARKETS_SUCCESS', markets: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_MARKETS_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchCountries = salesOrg => (dispatch, getState) => {
    dispatch({ type: 'FETCH_COUNTRIES_REQUEST' })
    return api
        .fetchCountries(getToken(getState()), salesOrg)
        .then(response => {
            dispatch({ type: 'FETCH_COUNTRIES_SUCCESS', countries: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_COUNTRIES_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchCenter = salesOrg => (dispatch, getState) => {
    dispatch({ type: 'FETCH_CENTER_REQUEST' })
    return api
        .fetchCenters(getToken(getState()), salesOrg)
        .then(response => {
            dispatch({ type: 'FETCH_CENTER_SUCCESS', centers: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_CENTER_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const resetCountriesCenters = () => (dispatch, getState) => {
    dispatch({ type: 'RESET_COUNTRIES_CENTERS', countries: [], centers: [] })
}

export const fetchLangs = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LANGS_REQUEST' })
    return api
        .fetchLangs(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_LANGS_SUCCESS', langs: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LANGS_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchTemplates = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_TEMPLATE_REQUEST' })
    return api
        .fetchTemplates(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_TEMPLATE_SUCCESS', templates: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_TEMPLATE_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchLandings = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LANDINGS_REQUEST' })
    return api
        .fetchLandings(getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            var landings = response.data
            landings.landings.forEach(l => {
                l.start_at = l.start_at ? moment(l.start_at, 'YYYY-MM-DD HH:mm:ss').toDate() : '';
                l.end_at = l.end_at ? moment(l.end_at, 'YYYY-MM-DD HH:mm:ss').toDate() : '';
                l.created_at = l.created_at ? moment(l.created_at, 'YYYY-MM-DD HH:mm:ss').toDate() : '';
                // l.start_at = intl.parseDate(new Date(l.start_at), DATE_FORMAT)
                // l.end_at = intl.parseDate(new Date(l.end_at), DATE_FORMAT)
                // l.create_at = intl.parseDate(new Date(l.create_at), DATE_FORMAT)
                l.status = parseLandingStatus(l);
            })
            // console.log(landings)
            dispatch({ type: 'FETCH_LANDINGS_SUCCESS', landings: landings })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LANDINGS_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchLandingsQuery = query => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LANDINGSQUERY_REQUEST' })
    return api
        .fetchLandingsQuery(getToken(getState()), query)
        .then(response => {
            dispatch({ type: 'FETCH_LANDINGSQUERY_SUCCESS', landings: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LANDINGSQUERY_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchLanding = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LANDING_REQUEST' })
    return api
        .fetchLanding(getToken(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_LANDING_SUCCESS', landing: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LANDING_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchLandinById = (id, iso) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LANDINGID_REQUEST' })
    return api
        .fetchLandingbyId(getToken(getState()), id, iso)
        .then(response => {
            dispatch({ type: 'FETCH_LANDINGID_SUCCESS', landing: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LANDINGID_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const createLanding = landing => (dispatch, getState) => {
    dispatch({ type: 'CREATE_LANDINGID_REQUEST' })
    return api
        .createLanding(getToken(getState()), landing)
        .then(response => {
            dispatch({ type: 'CREATE_LANDINGID_SUCCESS', create: response.data })
        })
        .catch(error => {
            dispatch({ type: 'CREATE_LANDINGID_FAILURE', alert: error.response })

            //api.errorHandler(dispatch, error.response)
        })
}

export const createLandingTranslated = landing => (dispatch, getState) => {
    dispatch({ type: 'CREATE_LANDINGID_REQUEST' })
    return api
        .createLandingTranslated(getToken(getState()), landing)
        .then(response => {
            dispatch({ type: 'CREATE_LANDINGID_SUCCESS', create: response.data })
        })
        .catch(error => {
            dispatch({ type: 'CREATE_LANDINGID_FAILURE', alert: error.response })
        })
}

export const updateLanding = landing => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_LANDINGID_REQUEST' })
    return api
        .updateLanding(getToken(getState()), landing)
        .then(response => {
            dispatch({ type: 'UPDATE_LANDINGID_SUCCESS', update: response.data })
        })
        .catch(error => {
            dispatch({ type: 'UPDATE_LANDINGID_FAILURE', alert: error.response })

            //api.errorHandler(dispatch, error.response)
        })
}

export const updateLandingTranslated = landing => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_LANDINGID_REQUEST' })
    return api
        .updateLandingTranslated(getToken(getState()), landing)
        .then(response => {
            dispatch({ type: 'UPDATE_LANDINGID_SUCCESS', update: response.data })
        })
        .catch(error => {
            dispatch({ type: 'UPDATE_LANDINGID_FAILURE', alert: error.response })
        })
}

export const getLandingSlug = data => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LANDINGS_SLUG_REQUEST' })
    return api
        .getLandingSlug(getToken(getState()), data)
        .then(response => {
            dispatch({ type: 'FETCH_LANDINGS_SLUG_SUCCESS', slug: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LANDINGS_SLUG_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const deleteLanding = (id, iso) => (dispatch, getState) => {
    dispatch({ type: 'DELETE_LANDING_REQUEST' })
    return api
        .deleteLanding(getToken(getState()), id, iso)
        .then(response => {
            dispatch({ type: 'DELETE_LANDING_SUCCESS', slug: response.data })
        })
        .catch(error => {
            dispatch({ type: 'DELETE_LANDING_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const deleteLandingImage = (id, type) => (dispatch, getState) => {
    dispatch({ type: 'DELETE_LANDING_IMAGE_REQUEST' })
    return api
        .deleteLandingImage(getToken(getState()), id, type)
        .then(response => {
            dispatch({ type: 'DELETE_LANDING_IMAGE_SUCCESS', slug: response.data })
        })
        .catch(error => {
            dispatch({ type: 'DELETE_LANDING_IMAGE_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchUserLandings = () => (dispatch, getState) => {
    const selectedCenter = getSelectedCenter(getState());

    if (selectedCenter) {
        dispatch({ type: 'FETCH_USER_LANDINGS_REQUEST' })
        return api
            .fetchUserLandings(getToken(getState()), getLanguage(getState()), selectedCenter)
            .then(response => {
                dispatch({ type: 'FETCH_USER_LANDINGS_SUCCESS', landings: response.data.landings || [] })
            })
            .catch(error => {
                dispatch({ type: 'FETCH_USER_LANDINGS_FAILURE' })
            })
    }
}

export const fetchProductsLandingsByCenters = (landingId, centers = [], products = [], portfolioMode = null) => (dispatch, getState) => {

    if (!portfolioMode) portfolioMode = getPreviewPorfolioMode(getState())

    dispatch({ type: 'FETCH_PRODUCTS_LANDINGS_REQUEST' })
    
    // return Promise.all(centers.map(centerId => {

    //     // const productIds = products.map(p => p.product_id)

    //     const productsByCenter = products.filter((product) => product.center_id == centerId).map(product => product.product_id);
    //     console.log('PRODUCT BY CNTEER', centers, products, productsByCenter)

    //     if (!productsByCenter.length) {
    //         return  new Promise((resolve) => {
    //             setTimeout(resolve, 100, { data: [] });
    //         });
    //     }

    //     return api.fetchProducts(centerId, productsByCenter)
    //     // return api.fetchMallProducts(centers, [])
    // }))
    const productIds = products.map(p => p && (p.product_id || p.productId))
    return api.fetchMallProducts(landingId, centers, productIds, portfolioMode)
    .then(results => {
        // const productsLanding = results.reduce((acc, response) => [...acc, ...response.data], [])        
        dispatch({
            type: 'FETCH_PRODUCTS_LANDINGS_SUCCESS',
            productsLanding: results.data.products,
            productsLots: results.data.lots
        })

    }).catch(error => {
        console.log('FETCH_PRODUCTS_LANDINGS_FAILURE' , error)
        dispatch({ type: 'FETCH_PRODUCTS_LANDINGS_FAILURE' })

        //api.errorHandler(dispatch, error.response)
    })

}


export const fetchLandingBySlug = (slug) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LANDINGS_BY_SLUG_REQUEST' })
    return api
        .getLandingBySlug(getToken(getState()), slug, getLanguage(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_LANDINGS_BY_SLUG_SUCCESS', landing: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_LANDINGS_BY_SLUG_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}

export const fetchPreviewLandingBySlug = (slug, langID) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PREVIEW_LANDINGS_BY_SLUG_REQUEST' })
    return api
        .getPreviewLandingBySlug(getToken(getState()), slug, langID)
        .then(response => {
            dispatch({ type: 'FETCH_PREVIEW_LANDINGS_BY_SLUG_SUCCESS', landing: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PREVIEW_LANDINGS_BY_SLUG_FAILURE' })

            //api.errorHandler(dispatch, error.response)
        })
}


export const setPortfolioMode = (portfolioMode) => (dispatch, getState) => {
    dispatch({ type: 'SET_PORTFOLIO_MODE', portfolioMode })

}

export const fetchMallLots = (centers, productId, landingCommonId, landingProductId) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_MALL_LOTS_REQUEST' })

    const state = getState()

    return api
        .getMallLots(getToken(state), centers, productId, landingCommonId, landingProductId, getLanguage(state))
        .then(response => {
            dispatch({ type: 'FETCH_MALL_LOTS_SUCCESS', lots: response.data.lots })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_MALL_LOTS_FAILURE' })
        })
}

