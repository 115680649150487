import { connect } from 'react-redux'

import { addCartLineLot } from '../../store/cart/actions'
import {getIsLogged, getIsAdmin, getImpersonatedBy} from '../../store/login/reducers'
import LotImageViewer from './LotImageViewer'
import withRouter from 'react-router-dom/withRouter'
import {getProfileConfiguration, getUnit} from "../../store/profile-configuration/reducers";
import {showingSegmentationModal} from "../../store/ui/reducers";

const mapStateToProps = (state, { match }) => {
    return {
        isLogged: getIsLogged(state),
        isAdmin: getIsAdmin(state),
        impersonatedBy: getImpersonatedBy(state),
        parentUrl: match.params.parentUrl,
        productId: match.params.productId,
        centerId: match.params.center,
        lotId: match.params.lotId,
        quarryBlock: match.params.quarryBlock,
        quality: match.params.quality,
        outlet: match.params.outlet,
        unit: getUnit(state),
        showSegmentation: showingSegmentationModal(state)
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         buyLot: (productId, lotId, callbackCenter) => {
//             dispatch(addCartLineLot(productId, lotId, callbackCenter))
//         },
//         buyTables: (productId, tableData, centerId) => {
//
//         }
//     }
// }

export default withRouter(connect(mapStateToProps, null)(LotImageViewer))
