export const initState = {
    templates: [],
    planograms: [],
    idConfirmation: null,
    isLoadingCheckout: false,
    isLoadingTemplate: false,
    isLoadingStores: false,
    isLoadingProduct: false,
    isLoadingBrochure: false,
    isAdmin: false,
    usersShopper: [],
    products: null,
    brochures: [],
    alertSendForm: null
}
const lowes = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_TEMPLATES_LOWES_REQUEST':
            return Object.assign({}, state, { isLoadingTemplate: true })
        case 'FETCH_TEMPLATES_LOWES_SUCCESS':
            const loadedTemplates = action.response.map(template => {
                let result = { ...template, samples: template.plan }
                delete result.plan
                return result
            })
            return Object.assign({}, state, { templates: loadedTemplates, isLoadingTemplate: false })
        case 'FETCH_TEMPLATES_LOWES_FAILURE':
            return Object.assign({}, state, { isLoadingTemplate: false })
        case 'SEND_CHECKOUT_LOWES_REQUEST':
            return Object.assign({}, state, { isLoadingCheckout: true })
        case 'SEND_CHECKOUT_LOWES_SUCCESS':
            return Object.assign({}, state, { idConfirmation: action.response.idConfirmation, isLoadingCheckout: false })
        case 'SEND_CHECKOUT_LOWES_FAILURE':
            return Object.assign({}, state, { isLoadingCheckout: false })
        case 'FETCH_USERS_SHOPPER_LOWES_REQUEST':
            return Object.assign({}, state, { isLoadingStores: true })
        case 'FETCH_USERS_SHOPPER_LOWES_SUCCESS':
            return Object.assign({}, state, { usersShopper: action.response, isLoadingStores: false })
        case 'FETCH_USERS_SHOPPER_LOWES_FAILURE':
            return Object.assign({}, state, { isLoadingStores: false })
        case 'FETCH_PRODUCTS_LOWES_REQUEST':
            return Object.assign({}, state, { isLoadingProduct: true })
        case 'FETCH_PRODUCTS_LOWES_SUCCESS':
            return Object.assign({}, state, { products: action.response.filter(item => item.type !== 'FREGADERO'), isLoadingProduct: false })
        case 'FETCH_PRODUCTS_LOWES_FAILURE':
            return Object.assign({}, state, { isLoadingProduct: false })
        case 'FETCH_BROCHURES_LOWES_REQUEST':
            return Object.assign({}, state, { isLoadingBrochure: true })
        case 'FETCH_BROCHURES_LOWES_SUCCESS':
            return Object.assign({}, state, { brochures: action.response.brochures, isLoadingBrochure: false })
        case 'FETCH_BROCHURES_LOWES_FAILURE':
            return Object.assign({}, state, { isLoadingBrochure: false })
        case 'SAVE_TEMPLATE_REQUEST':
            return Object.assign({}, state, { isLoadingTemplate: true })
        case 'SAVE_TEMPLATE_SUCCESS':
            return Object.assign({}, state, { isLoadingTemplate: false })
        case 'SAVE_TEMPLATE_FAILURE':
            return Object.assign({}, state, { isLoadingTemplate: false })
        case 'UPDATE_TEMPLATE_REQUEST':
            return Object.assign({}, state, { isLoadingTemplate: true })
        case 'UPDATE_TEMPLATE_SUCCESS':
            return Object.assign({}, state, { isLoadingTemplate: false })
        case 'UPDATE_TEMPLATE_FAILURE':
            return Object.assign({}, state, { isLoadingTemplate: false })
        case 'DELETE_TEMPLATE_REQUEST':
            return Object.assign({}, state, { isLoadingTemplate: true })
        case 'DELETE_TEMPLATE_SUCCESS':
            return Object.assign({}, state, { isLoadingTemplate: false })
        case 'DELETE_TEMPLATE_FAILURE':
            return Object.assign({}, state, { isLoadingTemplate: false })
        case 'CHANGE_TEMPLATE_NAME_SUCCESS':
            return Object.assign({}, state, {
                templates: state.templates.map(template => {
                    return template.id === action.response.id ? { ...template, name: action.response.name } : template
                })
            })
        case 'ADD_NEW_TEMPLATE':
            return Object.assign({}, state, { templates: state.templates.concat({ ...action.response, id: state.templates.length.toString(), users: [] }) })
        // case 'SAVE_TEMPLATE':
        //     let newsTemaplates = state.templates.map(template => {
        //         if (action.response.id === template.id) return action.response
        //         else return template
        //     })
        //     return Object.assign({}, state, { templates: newsTemaplates })
        case 'SEND_FORM_REGISTER_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'SEND_FORM_REGISTER_SUCCESS':
            return Object.assign({}, state, { isLoading: false, alertSendForm: action.response.data })
        case 'SEND_FORM_REGISTER_FAILURE':
            return Object.assign({}, state, { isLoading: false, alertSendForm: action.error.data })
        default:
            return state
    }
}
export const getTemplates = state => {
    return state.lowes.templates
}
export const getPlanograms = state => {
    return state.lowes.planograms
}
export const getIsLoading = state => {
    return state.lowes.isLoadingCheckout || state.lowes.isLoadingTemplate || state.lowes.isLoadingStores || state.lowes.isLoadingProduct || state.lowes.isLoadingBrochure
}
export const getIdConfirmation = state => {
    return state.lowes.idConfirmation
}
export const getIsLowesAdmin = state => {
    return state.lowes.isAdmin
}
export const getUsersShopper = state => {
    return state.lowes.usersShopper
}
export const getProductsLowes = state => {
    return state.lowes.products
}
export const getBrochuresLowes = state => {
    return state.lowes.brochures
}
export const getAlertSendForm = state => {
    return state.lowes.alertSendForm
}

export default lowes
