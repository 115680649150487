import React from 'react'
import Translation from '../../../global/Translation'

export default class DisplayUpcomingIndicator extends React.Component {
    render() {
        return (
            <div className="new-indicator new-indicator--upcoming-product">
                Coming soon
            </div>
        )
    }
}
