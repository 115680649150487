import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { getBusinesSegmentOptions, getUseOptions, getHasSegmentationV3, getChannelOptionsV3, getTypologyOptionsV3 } from '../../store/segmentation/reducers'
import { getShippingMethod } from '../../store/centers/reducers'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        businesSegmentOptions: getBusinesSegmentOptions(state),
        useOptions: getUseOptions(state),
        shippingMethod: getShippingMethod(state),
        segmentationV3: getHasSegmentationV3(state),
        channelOptionsV3: getChannelOptionsV3(state),
        typologyOptionsV3: getTypologyOptionsV3(state)
    }
}
class LineSegmentationRecurring extends React.Component {
    constructor(props) {
        super(props)
        this.state = { showHelp: false }
    }

    render() {
        let { line, businesSegmentOptions, useOptions, segmentationV3, channelOptionsV3, typologyOptionsV3 } = this.props

        let productBS, businessSegment, channel, subchannel, salesforceId, projectId, typology, use
        if (line.segmentation) {
            if (!segmentationV3) {
                productBS = line.businessSegment === null ? 'NOT' : line.businessSegment
                businessSegment = businesSegmentOptions ? businesSegmentOptions[productBS] : undefined
                channel = businessSegment ? businessSegment.channels[line.channel] : undefined
                subchannel = channel ? channel.subchannels[line.subchannel] : undefined
                typology = subchannel ? subchannel.typologies[line.typology] : undefined
                use = useOptions ? useOptions.find(option => line.use === option.id) : undefined
            } else {
                channel = channelOptionsV3 ? channelOptionsV3[line.channel] : undefined
                // subchannel = channel ? channel.subchannels[line.subchannel] : undefined //subchannel is no longer needed to retrieve the salesforceid
                businessSegment = line.segmentation.businessSegment ? line.segmentation.businessSegment : undefined
                productBS = line.businessSegment === null ? 'NOT' : line.businessSegment
                salesforceId = channel ? channel.salesforceRelations[line.segmentation.salesforceId] : undefined
                projectId = salesforceId && line.segmentation.projectId ? salesforceId.projectOptions[line.segmentation.projectId] : undefined
                typology = typologyOptionsV3 ? typologyOptionsV3.find(option => line.typology === option.id) : undefined
                use = useOptions ? useOptions.find(option => line.use === option.id) : undefined
            }
        }

        return (
            Object.values(line.segmentation).some(value => {
                if (Array.isArray(value)) return value.length > 0
                else return value !== null
            }) &&
            <React.Fragment>
                {channel && (
                    <React.Fragment>
                        {channel.name}
                    </React.Fragment>
                )}
                {/*{'/'}*/}
                <br/>
                {line.segmentation.businessSegment && (
                    <React.Fragment>
                        {line.segmentation.businessSegment && productBS && <FormattedMessage id={productBS} />}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(LineSegmentationRecurring)
)
