import React from 'react'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'
import * as CustomStyles from '../styles/CustomStyles'
import { connect } from 'react-redux'
import { submitWalkthrough } from '../../../store/ui/actions'
import { getHasWalkthrough } from '../../../store/login/reducers'
import { fetchHasWalkthrough } from '../../../store/login/actions'
import Translation from '../../global/Translation'

const mapStateToProps = (state) => {
    return {
        hasWalkthrough: getHasWalkthrough(state, 'ORDER_HISTORY')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitWalkthrough: (type, noShowMore) => dispatch(submitWalkthrough(type, noShowMore)),
        refreshData: () => dispatch(fetchHasWalkthrough())
    }
}

class OrderWalkthrough extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            stepIndex: 0,
            type: 'ORDER_HISTORY'
        }
    }

    /**
     *  Body content for step one
     */
    stepOneContent = () => {
        return (
            <div className="walkthrough__tooltip__content__main">
                <div className="walkthrough__tooltip__content__main--heading">
                    <h2><Translation id="walkthrough_welcome_to_the_new_tool" defaultMessage="Welcome to the new tool: Order History!" /></h2>
                </div>
                <div className="walkthrough__tooltip__content__main--info">
                    <span><Translation id="walkthrough_we_have_many_improvements" defaultMessage="We have many improvements and we want you to know them all. Click on start to learn more about this tool. It will only take you a few seconds." /></span>
                </div>
            </div>
        )
    }

    /**
     * Body content for step two
     */
    stepTwoContent = () => {
        return (
            <div className="walkthrough__tooltip__content">
                <div className="walkthrough__tooltip__content--heading">
                    <h2><Translation id="walkthrough_check_the_status_of_your_orders" defaultMessage="Check the status of your orders and shipments simultaneously" /></h2>
                </div>
                <div className="walkthrough__tooltip__content--info">
                    <span><Translation id="walkthrough_now_you_can_see_the_status_of_your_orders" defaultMessage="Now you can see the status of your orders and shipments by simply changing the view." /></span>
                </div>
            </div>
        )
    }

    /**
     * Body content for step three
     */
    stepThreeContent = () => {
        return (
            <div className="walkthrough__tooltip__content">
                <div className="walkthrough__tooltip__content--heading">
                    <h2><Translation id="walkthrough_new_quick_filters" defaultMessage="New quick filters" /></h2>
                </div>
                <div className="walkthrough__tooltip__content--info">
                    <span><Translation id="walkthrough_you_can_choose_if_you_want_to_see_all" defaultMessage="You can choose if you want to see all your orders or just the ones you have made today, the last week, the last month etc... with a single click." /></span>
                </div>
            </div>
        )
    }

    /**
     * Body content for step four
     */
    stepFourContent = () => {
        return (
            <div className="walkthrough__tooltip__content">
                <div className="walkthrough__tooltip__content--heading">
                    <h2><Translation id="walkthrough_and_the_usual_filters" defaultMessage="And the usual filters" /></h2>
                </div>
                <div className="walkthrough__tooltip__content--info">
                    <span><Translation id="walkthrough_in_more_filters_you_can_further_filter_by" defaultMessage="In More filters you can further filter by date range, order status etc..." /></span>
                </div>
            </div>
        )
    }

    /**
     * Body content for step five
     */
    stepFiveContent = () => {
        return (
            <div className="walkthrough__tooltip__content">
                <div className="walkthrough__tooltip__content--heading">
                    <h2><Translation id="walkthrough_new_quick_order_view" defaultMessage="New quick order view" /></h2>
                </div>
                <div className="walkthrough__tooltip__content--info">
                    <span><Translation id="walkthrough_visualize_your_product_status_and_more" defaultMessage="Visualize your product, its status and know when the estimated delivery will be." /></span>
                </div>
            </div>
        )
    }

    /**
     * Determines which step content to display
     * @returns boolean
     */
    breakpointIndicator = () => {
        if (window.innerWidth <= 767) return true
        return false
    }
    
    // Desktop version
    stepFourDesktop = {
        content: this.stepFourContent(),
        locale: {
            next: <span aria-label="next"><Translation id="next" defaultMessage="Next" /></span>,
            back: <span aria-label="back"><Translation id="back" defaultMessage="Back" /></span>
        },
        target: '.order-walkthrough-more-filter-desktop',
        styles: {
            buttonSkip: {
                display: 'none'
            }
        }
    }

    // Responsive version
    stepFourResponsive = {
        content: this.stepFourContent(),
        locale: {
            next: <span aria-label="next"><Translation id="next" defaultMessage="Next" /></span>,
            back: <span aria-label="back"><Translation id="back" defaultMessage="Back" /></span>
        },
        target: '.order-walkthrough-more-filter-responsive',
        styles: {
            buttonSkip: {
                display: 'none'
            }
        }
    }

    /**
     * steps
     */
    steps = {
        steps: [
            {
                content: this.stepOneContent(),
                locale: {
                    next: <span aria-label="next"><Translation id="get_started" defaultMessage="Get started" /></span>,
                    skip: <span aria-label="skip"><Translation id="do_not_show_again" defaultMessage="Do not show again" /></span>
                },
                placement: 'center',
                target: 'body',
            },
            {
                content: this.stepTwoContent(),
                locale: {
                    next: <span aria-label="next"><Translation id="next" defaultMessage="Next" /></span>,
                    back: <span aria-label="back"><Translation id="back" defaultMessage="Back" /></span>
                },
                target: '.header__tabs',
                styles: {
                    buttonSkip: {
                        display: 'none'
                    }
                }
            },
            {
                content: this.stepThreeContent(),
                locale: {
                    next: <span aria-label="next"><Translation id="next" defaultMessage="Next" /></span>,
                    back: <span aria-label="back"><Translation id="back" defaultMessage="Back" /></span>
                },
                placement: 'bottom-start',
                target: '.order-walkthrough-filter-quick',
                styles: {
                    buttonSkip: {
                        display: 'none'
                    }
                }
            },
            this.breakpointIndicator() ? this.stepFourResponsive : this.stepFourDesktop,
            {
                content: this.stepFiveContent(),
                locale: {
                    last: <span aria-label="last"><Translation id="understood" defaultMessage="Understood" /></span>,
                    back: <span aria-label="back"></span>
                },
                target: '.order__card',
                styles: {
                    buttonSkip: {
                        display: 'none'
                    }
                }
            },
        ]
    }

    /**
     * Callback function to manipulate the walkthrough logic
     */
    handleJoyrideCallback = data => {
        const { action, index, type } = data

        const endStatus = [EVENTS.TOUR_END]

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) })
        }

        if (index === 0) {
            if (endStatus.includes(type)) {
                this.props.onSubmitWalkthrough(this.state.type, true).then(() => this.props.refreshData())
            }
        } else if (endStatus.includes(type)) {
            this.props.onSubmitWalkthrough(this.state.type, false).then(() => this.props.refreshData())
        }
    }

    render() {
        const { steps } = this.steps
        const { hasWalkthrough } = this.props

        return hasWalkthrough && hasWalkthrough.shouldShow ? (
            <React.Fragment>
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    showProgress
                    steps={steps}
                    styles={{
                        tooltip: CustomStyles.TooltipStyles,
                        tooltipContent: CustomStyles.TooltipContentStyles,
                        options: CustomStyles.OptionStyles,
                        buttonNext: CustomStyles.ButtonNextStyles,
                        buttonBack: CustomStyles.ButtonBackStyles,
                        tooltipFooter: CustomStyles.TooltipFooterStyles,
                        spotlight: CustomStyles.SpotlightSyles
                    }}
                    disableScrolling
                    showSkipButton
                />
            </React.Fragment>
        ) : null
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderWalkthrough)