import 'babel-polyfill'
import React from 'react'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'bootstrap-v4-rtl/dist/css/bootstrap.min.css'
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css'
import 'react-table/react-table.css'
import './assets/local-flag-icon-css/css/flag-icon.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './assets/scss/app.scss'
import 'react-resizable/css/styles.css'
import 'react-grid-layout/css/styles.css'
import { ConnectedRouter, connectRouter } from 'connected-react-router'
import { LOCATION_CHANGE, routerMiddleware } from 'react-router-redux'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import advertisements from './store/advertisement/reducers'
import cart from './store/cart/reducers'
import infoEnv from './store/infoEnv/reducers'
import products from './store/products/reducers'
import profileConfiguration from './store/profile-configuration/reducers'
import crossSelling from './store/crossSelling/reducers'
import error from './store/error/reducers'
import lots from './store/lots/reducers'
import login from './store/login/reducers'
import shippingAddresses from './store/shipping-addresses/reducers'
import lowes from './store/lowes/reducers'
import ui from './store/ui/reducers'
import floatingAlerts from './store/floating-alerts/reducers'
import bigAccount from './store/big-account/reducers'
import checkout from './store/checkout/reducers'
import centers from './store/centers/reducers'
import kpis from './store/kpis/reducers'
import impersonate from './store/impersonate/reducers'
import reservations from './store/reservations/reducers'
import orders from './store/orders/reducers'
import deliveries from './store/deliveries/reducers'
import delivery from './store/delivery/reducers'
import directSlabAccess from './store/direct-slab-access/reducers'
import { createBrowserHistory }  from "history";
import segmentation from './store/segmentation/reducers'
import suggestion from './store/suggestion/reducers'
import maintenanceMode from './store/maintenance-mode/reducers'
import changelog from './store/changelog/reducers'
import landing from './store/landing/reducers'
import toastNotifications from './store/toast-notifications/reducers'
import loader from './store/loader/reducers'
import promotions from './store/promotions/reducers'
import cutToSize from './store/cut-to-size/reducers'
import selectedObjects from './store/selected-objects/reducers'

import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { PersistGate } from 'redux-persist/integration/react'

import { loadingBarMiddleware, loadingBarReducer } from 'react-redux-loading-bar'
import promiseMiddleware from 'redux-promise-middleware'
import ConnectedIntlProvider from './i18n'
import analyticsEventsMiddleware from './analyticsEvents'
import Raven from 'raven-js'
import LogRocket from 'logrocket'
import { composeWithDevTools } from 'redux-devtools-extension'
import '@progress/kendo-theme-default/dist/all.css'
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'

if (process.env.REACT_APP_LOGROCKET_ACTIVE === 'true') {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY)
}

Raven.config(process.env.REACT_APP_SENTRY_URL).install()
// MSAL configuration
const configuration: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_APP_ID,
        authority: process.env.REACT_APP_INSTANCE + process.env.REACT_APP_TENANT_NAME + '/' + process.env.REACT_APP_SIGN_IN_POLICY,
        knownAuthorities: [process.env.REACT_APP_INSTANCE],
        // instance: 'https://cosentinogroupb2cpre.b2clogin.com/', not working with msal-react
        // signInPolicy: process.env.REACT_APP_SIGN_IN_POLICY, not working with msal-react
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI
        // scopes: [process.env.REACT_APP_SCOPES], not working with msal-react
        // tenant: process.env.REACT_APP_TENANT_NAME not working with msal-react
    }
}

const pca = new PublicClientApplication(configuration)

let historyProps = {}
if (process.env.PUBLIC_URL) {
    historyProps = { ...historyProps, basename: process.env.PUBLIC_URL }
}
// need to test this on production
const history = createBrowserHistory(historyProps)

const middlewares = [
    thunk,
    analyticsEventsMiddleware,
    promiseMiddleware(),
    routerMiddleware(history),
    loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE', LOCATION_CHANGE]
    })
]
if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger({ collapsed: true, predicate: (getState, action) => !action.type.includes('loading-bar') }))
}
if (process.env.REACT_APP_LOGROCKET_ACTIVE === 'true') {
    middlewares.push(LogRocket.reduxMiddleware())
}

const migration = (state, currentVersion) => {
    if (state && state._persist.version < currentVersion) return Promise.resolve()
    else return Promise.resolve(state)
}

const loginConfig = {
    key: 'login',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.01,
    migrate: migration
}
const centersConfig = {
    key: 'centers',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.01,
    migrate: migration
}
const shippingAddressesConfig = {
    key: 'shippingAddresses',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.01,
    migrate: migration
}
const bigAccountConfig = {
    key: 'bigAccount',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.01,
    migrate: migration
}
const lotsConfig = {
    key: 'lots',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.01,
    migrate: migration
}

const promotionsConfig = {
    key: 'promotions',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.01,
    migrate: migration
}

const selectedObjectsConfig = {
    key: 'selectedObjects',
    storage,
    stateReconciler: autoMergeLevel2,
    version: 0.01,
    migrate: migration
}


let reducer = combineReducers({
    error,
    advertisements,
    cart,
    checkout,
    // products: persistReducer(productsConfig,products),
    products,
    profileConfiguration,
    crossSelling,
    // lots,
    lots: persistReducer(lotsConfig, lots),
    login: persistReducer(loginConfig, login),
    lowes,
    centers: persistReducer(centersConfig, centers),
    kpis,
    // ui: persistReducer(uiConfig, ui), //TODO:: REMOVE THIS ON PRODUCTION
    ui,
    floatingAlerts,
    bigAccount: persistReducer(bigAccountConfig, bigAccount),
    impersonate,
    infoEnv,
    segmentation,
    suggestion,
    reservations,
    changelog,
    shippingAddresses: persistReducer(shippingAddressesConfig, shippingAddresses),
    orders,
    directSlabAccess,
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
    maintenanceMode,
    landing,
    toastNotifications,
    loader,
    promotions: persistReducer(promotionsConfig, promotions),
    cutToSize,
    selectedObjects: persistReducer(selectedObjectsConfig, selectedObjects),
    deliveries,
    delivery
})

const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
    predicate: (getState, action) => !action.type.includes('loading-bar')
})

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)))
const persistor = persistStore(store)

export default store
ReactDOM.render(
    <Provider store={store}>
        <MsalProvider instance={pca}>
            <PersistGate persistor={persistor}>
                <ConnectedRouter history={history}>
                    <ConnectedIntlProvider />
                </ConnectedRouter>
            </PersistGate>
        </MsalProvider>
    </Provider>,
    document.getElementById('root')
)
