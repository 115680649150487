import React from 'react'
import Translation from '../global/Translation'
import Button from '../global/Button'
import InfoModal from './InfoModal'

class ThankYouSubscribeModal extends React.Component {
    render() {
        const { show, onClose } = this.props

        return (
            <InfoModal
                show={show}
                content={
                    <div className="row">
                        <div className="col-12">
                            <h2>
                                <i className="fal fa-check-circle check-icon" />
                                <Translation id="you_have_successfully_subscribed" defaultMessage="You have successfully subscribed" />
                            </h2>
                            <Translation id="thanks_for_subscribing" defaultMessage="Thanks for subscribing" />
                        </div>
                    </div>
                }
                footer={
                    <div className="col-12 col-md-6 offset-md-6">
                        <Button datacy="global__close" inverted onClick={onClose}>
                            <Translation id="close" defaultMessage="Close" />
                        </Button>
                    </div>
                }
            />
        )
    }
}
export default ThankYouSubscribeModal
