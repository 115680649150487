import { connect } from 'react-redux'
import MktAdminColors from './MktAdminColors'
import { injectIntl } from 'react-intl'
import { fetchColorsMktoolsAttr, addNewColorMktoolsAttr, removeColorMktoolsAttr, saveColorsMktoolsAttr, getIndexOfColorsMktoolsAtter } from '../../store/products/actions'
import { getBrandsMktoolsAttr, getColorsMktoolsAttr, getColorsMktoolsAttrToDel, getColorsMktoolsAttrToAdd } from '../../store/products/reducers'

const mapStateToProps = state => {
    return {
        brands: getBrandsMktoolsAttr(state),
        colors: getColorsMktoolsAttr(state),
        colorsToDel: getColorsMktoolsAttrToDel(state),
        colorsToAdd: getColorsMktoolsAttrToAdd(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchColorsMktoolsAttr: () => dispatch(fetchColorsMktoolsAttr()),
        addNewColorMktoolsAttr: (nameColor, brand) => dispatch(addNewColorMktoolsAttr(nameColor, brand)),
        getIndexOfColorsMktoolsAtter: (name, brand) => dispatch(getIndexOfColorsMktoolsAtter(name, brand)),
        removeColorMktoolsAttr: (i, brand) => dispatch(removeColorMktoolsAttr(i, brand)),
        saveColorsMktoolsAttr: () => dispatch(saveColorsMktoolsAttr())
    }
}

const MktAdminColorsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MktAdminColors)

export default injectIntl(MktAdminColorsContainer)
