import React from 'react'

import nboost from '../../../../assets/img/logos/nboost.svg'
import grip from '../../../../assets/img/logos/grip.svg'
import hybriq from '../../../../assets/img/logos/hybriq.svg'
import ukiyo from '../../../../assets/img/logos/ukiyo.svg'
import optimma from '../../../../assets/img/logos/optimma-2.svg'


export default class DisplayTechnology extends React.Component {
    render() {
        if (this.props.technology)
            return (
                <div className={`technology-indicator ${this.props.technology === 'GRIP+' && 'plus'} ${this.props.technology === 'HYBRIQ' && 'hybriq'}`}>
                    {this.props.technology === 'NBOOST' && <img src={nboost} alt="nboost" />}
                    {this.props.technology === 'GRIP' && <img src={grip} alt="grip" />}
                    {this.props.technology === 'GRIP+' && <img src={grip} className="plus" alt="gripplus" />}
                    {this.props.technology === 'HYBRIQ' && <img src={hybriq} className="hybriq" alt="hybriq" />}
                    {this.props.technology === 'OPTIMMA' && <img src={optimma} className="optimma" alt="optimma" />}
                    {this.props.technology === 'UKIYO' && <img src={ukiyo} className="ukiyo" alt="ukiyo" />}
                </div>
            )
        else return null
    }
}
