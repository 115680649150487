import React from 'react'

import { injectIntl } from 'react-intl'
import { Route, Switch } from 'react-router-dom'
import BackOrders from '../components/kpis/KpiDetail/KpiBody/BackOrders'
import KpiDetailModal from '../components/kpis/KpiDetail/KpiDetailModal'
import KpiHeader from '../components/kpis/KpiDetail/KpiHeader/KpiHeader'
import KpisFilters from '../components/kpis/KpisFilters'
import OpenOrders from '../components/kpis/KpiDetail/KpiBody/OpenOrders'
import { connect } from 'react-redux'
import { getLocalizedUrl } from '../store/ui/reducers'

const mapStateToProps = (state, props) => {
    return {
        localizedUrl: (routeId) => getLocalizedUrl(state, props.intl, routeId)
    }
}

class KpiDetailScreen extends React.Component {
    render() {
        return (
            <div>
                <section id="section-kpis" className="detail">
                    <div className="top-background">
                        <div className="waves" />
                    </div>
                    <div className="kpis-container">
                        <div className="container">
                            <KpisFilters {...this.props} />

                            <KpiHeader />
                            <div className="row">
                                <div className="col-12">
                                    <div className="results">
                                        <Switch>
                                            <Route path={this.props.localizedUrl('ROUTE_KPI_DETAIL').replace(':filter', 'open-orders')} component={OpenOrders} />{' '}
                                            <Route path={this.props.localizedUrl('ROUTE_KPI_DETAIL').replace(':filter', 'back-orders')} component={BackOrders} />{' '}
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <KpiDetailModal />
                </section>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(KpiDetailScreen)
)
