import { LOCATION_CHANGE } from 'react-router-redux/reducer'
import jwtDecode from 'jwt-decode'
import { getLanguageSF } from '../ui/reducers'
import { getProfileConfigurationEmails } from '../profile-configuration/reducers'

import { getLanguage } from '../ui/reducers'
import { getSelectedCenter, getIsLowesCenter, getIsMkToolsCenter, getIsIkeaCenter, getNaturalStoneSpainCenterId, getCenterData, getIsEndiCenter, getHQCenterId, isEuropeOrIberia, isUsaOrCanada, isEuropeOrIberiaSalesOrg } from '../centers/reducers'
import * as centers from '../centers/reducers'
import { PromotionRocketCustomersDataUSA5Percent } from '../../components/banners/banner-rocket/PromotionRocketCustomersDataUSA5Percent'
import { PromotionRocketCustomersDataUSA10Percent } from '../../components/banners/banner-rocket/PromotionRocketCustomersDataUSA10Percent'
import { getShippingAddresses } from '../shipping-addresses/reducers';

export const initState = {
    isLogged: false,
    username: null,
    name: null,
    email: null,
    emailAAD: null,
    token: null,
    showModal: false,
    showSSOModal: false,
    showJTModal: false,
    alert: null,
    status: null,
    impersonatedBy: null,
    userType: null,
    clientType: null,
    salesOrg: null,
    specialNames: [],
    userPriceList: null,
    //  discounts: [],
    priceList: null,
    lowes: false,
    shippingAddresses: null,
    shippingAddressesCenters: [],
    seenOutletModal: false,
    sapId: null,
    lowesStoreDataIsLoading: false,
    lowesStoreData: null,
    multicart: false,
    showPricesDisclaimer: true,
    userCenter: null,
    canViewNewOrders: false,
    mandatoryChangePasswordOldPassword: null,
    mandatoryChangePassword: false,
    allProductsDiscounts: [],
    userAuthorized: null,
    showAskForEmailModal: false,
    isCampaignAdmin: false,
    showBannerComments: false,
    aiRecommendations:false,
    dataFromSalesforce: null,
    emailUsedToLogin: null,
    isDistributor: false,
    hasWalkthrough: []
}

const login = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, { alert: null, status: null })
        case 'FETCH_SPECIAL_NAMES_SUCCESS':
            return Object.assign({}, state, { specialNames: action.specialNames })
        case 'FETCH_USER_PRICE_LIST_SUCCESS':
            return Object.assign({}, state, { userPriceList: action.priceList })
        case 'SHOW_REDIRECT_SSO_MODAL':
            return Object.assign({}, state, { showSSOModal: true })
        case 'CLOSE_REDIRECT_SSO_MODAL':
            return Object.assign({}, state, { showSSOModal: false })
        case 'SHOW_REDIRECT_JT_MODAL':
            return Object.assign({}, state, { showJTModal: true })
        case 'CLOSE_REDIRECT_JT_MODAL':
            return Object.assign({}, state, { showJTModal: false })
        case 'SHOW_LOGIN_MODAL':
            return Object.assign({}, state, { showModal: true })
        case 'CLOSE_LOGIN_MODAL':
            return Object.assign({}, state, { showModal: false, alert: null, status: null, mandatoryChangePassword: false })
        case 'CLOSE_PRICES_DISCLAIMER':
            return Object.assign({}, state, { showPricesDisclaimer: false })
        case 'LOGIN_REQUIRED':
            return Object.assign({}, state, { showModal: true, callbackCenter: action.callbackCenter, callbackSalesOrg: action.callbackSalesOrg, callback: action.callback, alert: `${action.payload.statusText}` })
        case 'IMPERSONATE_PROCESS_FINISH':
        case 'LOGIN_PROCESS_FINISH':
            return Object.assign({}, state, { isLogged: true, showModal: false, showSSOModal: false, userimage: null, alert: null, userPriceList: null })
        case 'SIMPLE_LOGIN_SUCCESS':
        case 'SIMPLE_IMPERSONATE_SUCCESS':
            const decoded = jwtDecode(action.token)
            return Object.assign({}, state, {
                username: decoded.login,
                token: action.token,
                name: decoded.name || decoded.login,
                email: decoded.email,
                emailAAD: decoded.emailAAD,
                userType: decoded.type,
                typeClient: decoded.typeClient,
                impersonatedBy: decoded.impersonatedBy,
                salesOrg: decoded.salesOrg,
                lowes: decoded.lowes,
                priceList: decoded.priceList,
                seenOutletModal: false,
                accountCreated: decoded.accountCreated,
                multicart: decoded.multicart,
                userCenter: decoded.userCenter,
                canViewNewOrders: decoded.canViewNewOrders,
                allProductsDiscounts: [],
                aiRecommendations: decoded.aiRecomendations,
                dataFromSalesforce: decoded.dataFromSalesForce,
                emailUsedToLogin: decoded.emailUsedToLogin,
                isDistributor: decoded.isDistributor
            })
        case 'CHECK_LOGIN_SUCCESS':
            return { ...state, userAuthorized: action.userAuthorized }
        case 'IMPERSONATE_PROCESS_FAILURE':
        case 'LOGIN_PROCESS_FAILURE':
            return Object.assign({}, state, { alert: action.statusText })
        case 'GET_PRICE_LIST_SUCCESS':
            const tokenDecoded = jwtDecode(action.token)
            return Object.assign({}, state, {
                priceList: tokenDecoded.priceList
            })
        case 'REMOVE_PRICE_LIST':
            return Object.assign({}, state, {
                priceList: []
            })
        case 'LOGOUT':
            return {...initState}
        case 'CREATE_ACCOUNT_SUCCESS':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'CREATE_ACCOUNT_FAILURE':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'RECOVER_PASSWORD_SUCCESS':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'RECOVER_PASSWORD_FAILURE':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'RESET_UI':
            return Object.assign({}, state, { showModal: false, showSSOModal: false })
        case 'DONT_SHOW_OUTLET_MODAL_AGAIN':
            return Object.assign({}, state, { seenOutletModal: true })
        case 'SET_SAP_ID':
            return { ...state, sapId: action.sapId, simpleAccess: action.simpleAccess }
        case 'FETCH_LOWES_STORE_DATA_REQUEST':
            return { ...state, lowesStoreDataIsLoading: true }
        case 'FETCH_LOWES_STORE_DATA_SUCCESS':
            return { ...state, lowesStoreDataIsLoading: false, lowesStoreData: action.lowesStoreData }
        case 'FETCH_LOWES_STORE_DATA_FAILURE':
            return { ...state, lowesStoreDataIsLoading: false }
        case 'MANDATORY_CHANGE_PASSWORD':
            return {
                ...state,
                alert: action.alert,
                status: 'warning',
                mandatoryChangePasswordUsername: action.username,
                mandatoryChangePasswordOldPassword: action.oldPassword,
                mandatoryChangePassword: true
            }
        case 'MANDATORY_CHANGE_PASSWORD_REQUEST':
            return Object.assign({}, state, { alert: null, status: null })
        case 'MANDATORY_CHANGE_PASSWORD_NOT_MATCH':
            return Object.assign({}, state, { alert: action.alert, status: 400 })
        case 'MANDATORY_CHANGE_PASSWORD_SUCCESS':
            return Object.assign({}, state, { mandatoryChangePassword: false, alert: action.alert, status: 'info' })
        case 'MANDATORY_CHANGE_PASSWORD_FAILURE':
            return Object.assign({}, state, { alert: action.alert, status: action.status })
        case 'FETCH_PRODUCTS_DISCOUNTS_SUCCESS':
            return { ...state, allProductsDiscounts: action.discounts }
        case 'FETCH_PRODUCTS_DISCOUNTS_FAILURE':
            return Object.assign({}, state, { allProductsDiscounts: [] })
        case 'SET_USER_EMAIL_AAD_SUCCESS':
            return Object.assign({}, state, { showAskForEmailModal: false })
        case 'TOGGLE_SHOW_ASK_FOR_EMAIL_MODAL':
            return Object.assign({}, state, { showAskForEmailModal: !state.showAskForEmailModal })

        case 'GET_IS_CAMPAIGN_ADMIN_REQUEST':
            return { ...state, isCampaignAdmin: action.isCampaignAdmin }
        case 'GET_IS_CAMPAIGN_ADMIN_SUCCESS':
            return { ...state, isCampaignAdmin: action.isCampaignAdmin }
        case 'GET_IS_CAMPAIGN_ADMIN_FAILURE':
            return { ...state, isCampaignAdmin: action.isCampaignAdmin }
        case 'HAS_FEEDBACK':
            return Object.assign({}, state, { showBannerComments: action.response })
        case 'FETCH_HAS_WALKTHROUGH_SUCCESS':
            return Object.assign({}, state, { hasWalkthrough: action.hasWalkthrough })
        default:
            return state
    }
}

export const getToken = state => {
    return state.login.token
}
export const getUserAuthorization = state => {
    return state.login.userAuthorized ? state.login.userAuthorized.authorized : ''
}
export const showModalSelector = state => {
    return state.login.showModal
}
export const showRedirectSSOModal = state => {
    return state.login.showSSOModal
}
export const showRedirectJTModal = state => {
    return state.login.showJTModal
}
export const getIsLogging = state => {
    return state.ui.isLogging
}
export const getIsLogged = state => {
    return state.login && state.login.isLogged
}
export const getIsAdmin = state => {
    if (state.login.userType === 'ADMIN') {
        return true
    } else {
        return false
    }
}
export const getUserType = state => {
    return state.login && state.login.userType
}
export const getTypeClient = state => {
    return (state.login.typeClient && state.login.typeClient !== '') ? state.login.typeClient : 1
}
export const getTestAB = (state) => {
    return 'A'
}
export const getAlert = state => {
    return state.login.alert
}
export const getStatus = state => {
    return state.login.status
}
export const getUserName = state => {
    return state.login.username
}
export const getName = state => {
    return state.login.name
}
export const getEmail = state => {
    return state.login.email
}
export const getEmailAAD = state => {
    return state.login.emailAAD
}
export const getSalesOrg = state => {
    return state.login.salesOrg
}

export const getUserImage = state => {
    return state.login.userimage
}
export const getImpersonatedBy = state => {
    return state.login.impersonatedBy
}
export const getLoginCallback = state => {
    return state.login.callback
}
export const getCallbackCenter = state => {
    return state.login.callbackCenter
}
export const getCallbackSalesOrg = state => {
    return state.login.callbackSalesOrg
}
export const getSpecialNames = state => {
    return state.login.specialNames
}
export const getUserLowes = state => {
    return state.login.lowes
}
export const getHaveUserSeenOutletModal = state => {
    return state.login.seenOutletModal
}
export const getIsMktoolsAdmin = state => {
    return (
        state.login.userType === 'ADMIN' &&
        (state.login.username === 'MKTOOLS' || state.login.username === 'MKTOOLSUSA' || state.login.username === 'TRADEMARKETING' || state.login.username === 'MKTUSA')
    )
}
export const getIsMallAdmin = state => {
    let centerNames = ['OUTLET']
    if (centerNames.includes(getImpersonatedBy(state))) return true

    return state.login.userType === 'ADMIN' && state.login.username === 'OUTLET'
}
export const getIsMallPilotUser = state => {
    //SWITCH BETWEEN BLOCK 1 or BLOCK 2 to enable for every user or restrict only for pricing

    //BLOCK1
    return true
    //BLOCK2
    // let centerNames = ['PRICING']
    // if (!getIsLogged(state)) return false
    // if (centerNames.includes(getUserName(state))) return true
    // if (centerNames.includes(getImpersonatedBy(state))) return true
    // return false
}
export const getIsCampaignsPilotUser = state => {
    return !!state.login.isCampaignAdmin
}
export const getIsShowBannerComments = state => {
    return state.login.showBannerComments
}
export const getIsCampaignsPricingUser = state => {
    return state.login.isCampaignAdmin === 'PRICING' //&& false
}
export const getIsDeviatedAdmin = state => {
    return state.login.userType === 'ADMIN' && (state.login.username === 'RMCERRO' || state.login.username === 'CMARTINEZ')
}
export const getIsMagazineMk = state => {
    return state.login.username === 'MAGAZINEMK'
}
export const getSapId = state => {
    return state.login.sapId
}
export const getIsLowesAdmin = state => {
    return state.login.username === 'BRAD' || state.login.username === 'LOWESADMIN'
}
export const getLowesStoreData = state => {
    return state.login.lowesStoreData
}
export const getUserPriceList = state => {
    return state.login.userPriceList
}
// export const getUserDiscounts = state => {
//     return state.login.discounts
// }
export const getPriceList = state => {
    return state.login.priceList
}
export const getUserCenter = state => {
    return state.login.userCenter
}

export const getPriceListAtSelectedCenter = state => {
    let priceList = []

    if (getPriceList(state)) {
        if (getSelectedCenter(state) == getNaturalStoneSpainCenterId()) return getPriceList(state)
        priceList = getPriceList(state).filter(priceList => {
            return priceList.centerId == getSelectedCenter(state)
        })

        //si estamos en el centro 1000, y el filter anterior no encuentra lista de precios valido.
        if (getSelectedCenter(state) == 1100 && priceList && priceList.length == 0) {
            priceList = getPriceList(state).filter(priceList => {
                return priceList.salesOrg == centers.getCenterData(state).salesOrg
            })
        }
    }

    return priceList
}
export const getMulticartEnabled = state => {
    return state.login.multicart
}
export const getVirtualVisitURL = state => {
    try {
        /* TODO por ahora subimos sin codificar.
        let AES123Key = process.env.REACT_APP_AES128_KEY
        var iv = "eCommerceVectorB" ;
        var aesCBC = new aesJs.ModeOfOperation.cbc(aesJs.utils.utf8.toBytes(AES123Key),aesJs.utils.utf8.toBytes(iv))
        */
        let sfLang = getLanguageSF(state)
        //Proccess Sap ID
        let SapID = state.login.username
        //let sapIDBytes = aesJs.utils.utf8.toBytes(state.login.username)
        // let sapIDBytes = aesJs.utils.utf8.toBytes("TextMustBe16Byte")

        // let encryptedSapID = aesCBC.encrypt(sapIDBytes)
        // var base64SapID = btoa(String.fromCharCode.apply(null,encryptedSapID ))

        //Proccess email
        //var base64Email = null
        let emails = getProfileConfigurationEmails(state)
        let email = null
        if (emails && emails.length > 0) {
            //  console.log("EMAIL "+ emails[0])
            email = emails[0]
            // let encryptedEmail = aesCBC.encrypt(aesJs.utils.utf8.toBytes(emails[0]))
            // base64Email = btoa(String.fromCharCode.apply(null,encryptedEmail))
        }

        let URLVirtualVisit = `${process.env.REACT_APP_SF_BOOKING_URL}?sapID=${state.login.username}${email ? '&email=' + email : ''}&language=${sfLang}`
        return URLVirtualVisit
    } catch (e) {
        // console.log(e)
    }
}
export const getCommonParams = state => {
    let commonParams = {}
    if (getIsLogged(state)) {
        if (getUserType(state) === 'ADMIN') {
            commonParams = {
                userId: getUserName(state),
                idCenter: getSelectedCenter(state),
                idCustomer: null,
                idioma: getLanguage(state)
            }
        } else if (getImpersonatedBy(state)) {
            commonParams = {
                userId: getImpersonatedBy(state),
                idCenter: getSelectedCenter(state),
                idCustomer: getUserName(state),
                idioma: getLanguage(state)
            }
        } else {
            commonParams = {
                userId: getUserName(state),
                idCenter: getSelectedCenter(state),
                idCustomer: getUserName(state),
                idioma: getLanguage(state)
            }
        }
    } else {
        commonParams = {
            userId: null,
            idCenter: null,
            idCustomer: null,
            idioma: null
        }
    }

    //global var for events
    window.userId = commonParams.userId
    window.idCenter = commonParams.idCenter
    window.idCustomer = commonParams.idCustomer
    window.idioma = commonParams.idioma
    window.idSupplierCenter = getSelectedCenter(state)
    window.crossType = hasAiRecommendations(state) ? 'dinamico' : 'estatico'
    return commonParams
}

export const showPricesDisclaimer = state => {
    if (getIsLowesCenter(state) || getIsMkToolsCenter(state) || getIsIkeaCenter(state)) {
        return false
    }
    return state.login.showPricesDisclaimer
}

export const getMandatoryChangePasswordUsername = state => {
    return state.login.mandatoryChangePasswordUsername
}

export const getMandatoryChangePasswordOldPassword = state => {
    return state.login.mandatoryChangePasswordOldPassword
}

export const mandatoryChangePassword = state => {
    return state.login.mandatoryChangePassword
}
export const getShowAskForEmailModal = state => {
    return state.login.showAskForEmailModal
}
export const isEmserUser = state => {
    return state.login.username === '0000410124'
}

export const isSimpleAccess = state => {
    return state.login.simpleAccess
}

export const canViewCutToSize = state => {
    // if (centerId) includes----
    const centerData = getCenterData(state)
    return centerData && centerData.access['CAN_VIEW_CUT_TO_SIZE']
}

export const canViewPreorder = state => {
    // if (centerId) includes----
    const centerData = getCenterData(state)
    return centerData && centerData.access['CAN_VIEW_PREORDER']
}

export const canViewFurniture = state => {
    // if (centerId) includes----
    const centerData = getCenterData(state)
    return centerData && centerData.access['CAN_VIEW_FURNITURE']
}

export const canViewConfigurableBath = state => {
    const centerData = getCenterData(state)
    return centerData && centerData.access['CAN_VIEW_CONFIGURABLE_BATH']
}

export const canViewV2 = state => {
    const centerData = getCenterData(state)
    return centerData && centerData.access && centerData.access['CAN_VIEW_V2']
}

export const isUserDistributor = state => {
    return state.login && state.login.isDistributor
}

/**
 * Shown to all users except distributors, not loogged, admins, supply center 1100, distribution channel 10 and 11.
 * @param {object} state
 * @returns {boolean}
 */
export const canViewOutletAndNaturalStoneFromHQInOtherCenters = state => {
    if (!getIsLogged(state)) return false
    if (getIsEndiCenter(state)) return false
    if (getSelectedCenter(state) === getHQCenterId()) return false
    if (isEuropeOrIberia(state) !== null && !isEuropeOrIberia(state)) return false
    if (getUserType(state) == 'ADMIN') return false
    if (isUserDistributor(state)) return false

    let channel11 = []
    if (getShippingAddresses(state)) {
        channel11 = getShippingAddresses(state).filter((shippingAddress) =>
            shippingAddress.channel == 11
        )
    }
    
    if (channel11.length > 0) return false

    let channel10 = []
    if (getShippingAddresses(state)) {
        channel10 = getShippingAddresses(state).filter((shippingAddress) =>
            shippingAddress.centerId == '1100' && shippingAddress.channel == 10
        )
    }
    
    if (channel10.length > 0) return false

    return true
}

export const canViewBannerRecurring = state => {
    const centerData = getCenterData(state)
    return centerData && centerData.access && centerData.access['CAN_VIEW_BANNER_RECURRING']
}

export const canViewCrosselling = state => {
    const centerData = getCenterData(state)
    const userType = getUserType(state)
    return centerData && userType == 'CUSTOMER' && centerData.access['CAN_VIEW_CROSS_SELLING']
}

export const hasAiRecommendations = state => {
    return state.login && state.login.aiRecommendations
}
export const getDataFromSalesforce = state => {
    return state.login && state.login.dataFromSalesforce
}

export const getEmailUsedToLogin = state => {
    return state.login && state.login.emailUsedToLogin
}

export const canViewRocketPromotion = state => {
    if (!getIsLogged(state)) return false
    if (getIsMkToolsCenter(state)) return false
    if (getIsIkeaCenter(state)) return false
    if (getIsLowesCenter(state)) return false
    if (PromotionRocketCustomersDataUSA5Percent.includes(getUserName(state).padStart(10,'0'))) return 'USACA5'
    if (PromotionRocketCustomersDataUSA10Percent.includes(getUserName(state).padStart(10,'0'))) return 'USACA10'
    //if (PromotionRocketCustomersDataUSA.includes(getUserName(state).padStart(10,'0'))) return 'USACA'
    //if (PromotionRocketCustomersDataFR.includes(getUserName(state).padStart(10, '0'))) return 'FR'
    //if (PromotionRocketCustomersDataDE.includes(getUserName(state).padStart(10, '0'))) return 'DE'
    //if (PromotionRocketCustomersDataBE.includes(getUserName(state).padStart(10, '0'))) return 'BE'
    //if (PromotionRocketCustomersDataNLD.includes(getUserName(state).padStart(10, '0'))) return 'NLD'
    //if (PromotionRocketCustomersDataAT.includes(getUserName(state).padStart(10, '0'))) return 'AT'
    //if (PromotionRocketCustomersDataIT.includes(getUserName(state).padStart(10, '0'))) return 'IT'
    //if (PromotionRocketCustomersDataCH.includes(getUserName(state).padStart(10, '0'))) return 'CH'
    //if (PromotionRocketCustomersDataIberia.includes(getUserName(state).padStart(10, '0'))) return 'Iberia'
    //if (PromotionRocketCustomersDataIRLSCOT.includes(getUserName(state).padStart(10, '0'))) return 'IRLSCOT'
    //if (PromotionRocketCustomersDataPL.includes(getUserName(state).padStart(10, '0'))) return 'PL'
    //if (PromotionRocketCustomersDataSE.includes(getUserName(state).padStart(10, '0'))) return 'SE'
    //if (PromotionRocketCustomersDataUK.includes(getUserName(state).padStart(10, '0'))) return 'UK'
    //if (PromotionRocketCustomersDataNO.includes(getUserName(state).padStart(10, '0'))) return 'NO'
    //if (PromotionRocketCustomersDataDK.includes(getUserName(state).padStart(10, '0'))) return 'DK'
    //if (PromotionRocketCustomersDataFI.includes(getUserName(state).padStart(10, '0'))) return 'FI'
    //if (PromotionRocketCustomersDataBaltic.includes(getUserName(state).padStart(10, '0'))) return 'Baltic'
    return false
}


/**
 * If it is europe or iberia or canada or USA. Return true.
 * If the selected center is 1100 but the logged user matches the PromotionSilestoneCustomerDataAlmeria. Return true.
 * If the selected center is 1100. Return false.
 * If the user is not logged. Return false.
 * @param {object} state 
 * @returns {boolean}
 */
export const canViewSilestonePromotion = state => {
    //const userName = getUserName(state)

    /** @type {boolean} */
    //const isUserFromAlmeria = userName && PromotionSilestoneCustomerDataAlmeria.includes(userName.toString().padStart(10, '0'))
    /** @type {boolean} */
    //const timeOut = moment() > moment('2024-03-09')
    
    //if (!getIsLogged(state) || (getSelectedCenter(state) == getHQCenterId() && !isUserFromAlmeria) || timeOut) return false
    //if (isUserFromAlmeria || isUsaOrCanada(state) || isEuropeOrIberiaSalesOrg(state)) return true
    
    //return true

    return false
}

export const canViewRockMasterPromotion = state => {
    if (centers.isAustraliaSalesOrg(state)) return true
    if (!getIsLogged(state)) return false
    return false
}

/**
 * Determines which walkthrough the user should see
 * @param {object} state 
 * @param {string} walkthroughType 
 * @returns object
 */
export const getHasWalkthrough = (state, walkthroughType) => {
    const walkthroughArr = state.login && state.login.hasWalkthrough.filter((walkthrough) => walkthrough.type === walkthroughType)
    return walkthroughArr[0]
}

/**
 * Check if the user is from a spanish sales org
 * @param {object} state 
 * @returns {boolean}
 */
export const isSpanishSalesOrg = (state) => {
    if (['1100', '1000'].includes(getSalesOrg(state))) return true
    return false
}

/**
 * Check if the user can see the earthic promotion
 * @param {object} state 
 * @returns {boolean}
 */
export const canViewEarthicPromotion = (state) => {
    if (centers.isAustraliaSalesOrg(state)) return false
    if (!getIsLogged(state)) return false
    return true
}

/**
 * Check if the user can see the Hybriq banner promotion.
 * @param {object} state 
 * @returns {boolean}
 */
export const canViewHybriqPromotion = (state) => {
    if (!getIsLogged(state)) return false
    return true
}

/**
 * Check if the user can see the silestone promotion mx
 * @param {object} state 
 * @returns {boolean}
 */
export const canViewSilestonePromotionXM = (state) => {
    if (centers.isAustraliaSalesOrg(state)) return false    
    if (!getIsLogged(state)) return false
    return true
}

export default login
