import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { selectBuilder, toggleSegmentation } from '../../store/segmentation/actions'
import { getCurrentSegmentation, getSelectedBuilder, getBuildersOptions } from '../../store/segmentation/reducers'
const mapStateToProps = state => {
    return {
        show: getCurrentSegmentation(state) === 'BUILDER',
        selected: getSelectedBuilder(state),
        options: getBuildersOptions(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('BUILDER'))
        },
        onSelect: value => {
            dispatch(selectBuilder(value))
        }
    }
}
const Builders = ({ show, selected, onToggle, onSelect, options }) => {
    if (!options || options.length === 0) {
        return ''
    }
    const selectedValue = selected ? <span>{selected.name}</span> : null
    return (
        <div>
            <div onClick={onToggle} className="option">
                <Translation id="builder" defaultMessage="Builder" /> : {selectedValue}
                <i className={show ? 'fa fa-caret-down' : 'fa fa-caret-right'} aria-hidden="true" />
                <br />
            </div>

            <div style={{ display: show ? 'block' : 'none' }}>
                <div className="row segment-selections">
                    {options.map(option => (
                        <span
                            key={option.name}
                            onClick={() => {
                                onSelect(option)
                            }}
                        >
                            {option.name}
                        </span>
                    ))}
                </div>
            </div>
            <hr />
        </div>
    )
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Builders)
