import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import NewShippingCardItem from './NewShippingCardItem'
import Button from '../../../global/Button'
import Translation from '../../../global/Translation'
import moment from 'moment'
import Slider from 'react-slick'
import { SliderNextArrow, SliderPrevArrow } from '../Utils'
import Timeline from '../../Timeline'
import { DeliveryStatus } from '../Enums/DeliveryStatus'

defineMessages({
    planned_transportation: {
        id: 'planned_transportation',
        description: 'planned_transportation',
        defaultMessage: 'Planned transportation'
    }
})

class NewShippingCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showMoreProducts: false
        }
    }

    showBtnToDetails = delivery => {
        if (delivery && delivery.delivery_id && delivery.order_lines.length > 0 && !this.props.isIkeaCenter) return true
        return false
    }
    render() {
        const { delivery, redirect, adaptedFormat, intl, deliveryStatusMap, locale, replaceDotWithComma } = this.props

        let orderLinesByDeliveryLength = Object.keys(delivery.order_lines).length

        const positionsByDeliveryMap = delivery =>
            delivery.order_lines &&
            delivery.order_lines.length > 0 &&
            delivery.order_lines.map((orderLine, idx) => {
                return <NewShippingCardItem key={idx} orderLine={orderLine} intl={intl} adaptedFormat={adaptedFormat} locale={locale} replaceDotWithComma={replaceDotWithComma} />
            })

        const sliderSettings = {
            arrows: true,
            nextArrow: <SliderNextArrow />,
            prevArrow: <SliderPrevArrow />,
            swipeToSlide: true,
            dots: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            variableWidth: false
        }

        const handleBtnToDetailsOnClick = (delivery) => {
            redirect(
                intl
                    .formatMessage({ id: 'ROUTE_SHIPPING_DETAILS' })
                    .replace(':filter', 'view-shipping')
                    .replace(':deliveryId', delivery.delivery_id)
            )
        }

        const showMoreLessProducts = () => {
            this.setState({ showMoreProducts: !this.state.showMoreProducts })
        }

        const deliveryStatusText = deliveryStatus => {
            if (deliveryStatus === DeliveryStatus.Treated_partially) return intl.formatMessage({ id: 'order_status_shipped' })
            if (deliveryStatus === DeliveryStatus.Treated_completelly) return intl.formatMessage({ id: 'entregado' })
            if (deliveryStatus === DeliveryStatus.Not_treated) return intl.formatMessage({ id: 'planned_transportation' })
            return false
        }

        return (
            <React.Fragment>
                {delivery.order_lines && delivery.order_lines.length > 0 && (
                    <div className="order__card" key={delivery.delivery_id}>
                        <header className="order__card__header">
                            <div className="col-12 col-md-9 col-xl-9 p-0 order__card__header__info">
                                <div className="card__header__text">
                                    <div className="card__header__text--title">
                                        <span>
                                            <Translation id="status" defaultMessage="Status" />
                                            :&nbsp;
                                        </span>
                                    </div>
                                    <div className="card__header__text--subtitle">
                                        {delivery.delivery_status ? <span>{deliveryStatusText(delivery.delivery_status)}</span> : <span>--</span>}
                                    </div>
                                </div>
                                <div className="card__header__text">
                                    {delivery.delivery_status && delivery.delivery_status === DeliveryStatus.Treated_completelly ? (
                                        <React.Fragment>
                                            <div className="card__header__text--title">
                                                <span>
                                                    <Translation id="delivered_on" defaultMessage="Delivered on" />
                                                    :&nbsp;
                                                </span>
                                            </div>
                                            <div className="card__header__text--subtitle">
                                                <span>
                                                    {moment(delivery.delivery_date).isSame(moment(), 'day') ? (
                                                        <Translation id="today" defaultMessage="Today" />
                                                    ) : (
                                                        moment(delivery.delivery_date).format('L')
                                                    )}
                                                </span>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="card__header__text--title">
                                                <span>
                                                    <Translation id="estimated_delivery" defaultMessage="Estimated delivery" />
                                                    :&nbsp;
                                                </span>
                                            </div>
                                            <div className="card__header__text--subtitle">
                                                <span>
                                                    {moment(delivery.expected_delivery_date).isSame(moment(), 'day') ? (
                                                        <Translation id="today" defaultMessage="Today" />
                                                    ) : (
                                                        moment(delivery.expected_delivery_date).format('L')
                                                    )}
                                                </span>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="card__header__text">
                                    <div className="card__header__text--title">
                                        <span>
                                            <Translation id="shipment_number" defaultMessage="Shipment number" />
                                            :&nbsp;
                                        </span>
                                    </div>
                                    <div className="card__header__text--subtitle">{delivery.delivery_id ? <span>{delivery.delivery_id}</span> : <span>--</span>}</div>
                                </div>

                                <div className="card__header__text">
                                    <div className="card__header__text--title">
                                        <span>
                                            <Translation id="number_of_products" defaultMessage="Number of products" />
                                            :&nbsp;
                                        </span>
                                    </div>
                                    <div className="card__header__text--subtitle">
                                        {delivery.order_lines ? <span>{Object.keys(delivery.order_lines).length}</span> : <span>--</span>}
                                    </div>
                                </div>
                            </div>

                            {this.showBtnToDetails(delivery) && (
                                <div className="col-12 col-md-3 col-xl-3 p-0 order__card__header--btn">
                                    <Button
                                        className="bt bt-inverted"
                                        gtmLabel="ec_historial_envios_detalles"
                                        onClick={() => {
                                            handleBtnToDetailsOnClick(delivery)
                                        }}
                                    >
                                        <Translation id="view_details" defaultMessage="View details" />
                                    </Button>
                                </div>
                            )}
                        </header>

                        <section className="order__card__item--container">
                            <div className="order-divider-x"></div>
                            <div className="order__card__item--subcontainer">
                                <div className="w-100">
                                    <div className="d-none d-md-block">
                                        <div className={`${this.state.showMoreProducts ? '' : 'show-more-less-shipping'}`}>{positionsByDeliveryMap(delivery)}</div>
                                    </div>

                                    <div className="d-block d-md-none">
                                        <Slider ref={s => (this.slider = s)} {...sliderSettings}>
                                            {positionsByDeliveryMap(delivery)}
                                        </Slider>
                                    </div>

                                    {orderLinesByDeliveryLength > 3 && (
                                        <div className="show__more__less">
                                            <button onClick={() => showMoreLessProducts()}>
                                                {this.state.showMoreProducts ? (
                                                    <React.Fragment>
                                                        <span>
                                                            <Translation id="view_less" defaultMessage="View less" />
                                                        </span>
                                                        <i class="fas fa-chevron-up"></i>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <span>
                                                            <Translation id="view_all_products" defaultMessage="View all products" />
                                                        </span>
                                                        &nbsp;
                                                        <span>&#40;{orderLinesByDeliveryLength}&#41;</span>
                                                        <i class="fas fa-chevron-down"></i>
                                                    </React.Fragment>
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="order-divider-y__flex"></div>
                                <div className="d-flex flex-nowrap vertical-timeline-container">
                                    <Timeline delivery={delivery.timeline} orderStatusMap={deliveryStatusMap} withDates={true} isVertical />
                                    {/* This code is just for accomodation. Don't delete */}
                                    <div className="timeline-text-div__vertical">
                                        <div>
                                            <Translation id={deliveryStatusMap[delivery.timeline[0].statusText].textToShow} />
                                        </div>
                                        <div>
                                            <Translation id={deliveryStatusMap[delivery.timeline[1].statusText].textToShow} />
                                        </div>
                                        <div>
                                            <Translation id={deliveryStatusMap[delivery.timeline[2].statusText].textToShow} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {this.showBtnToDetails(delivery) && (
                            <footer className="order__card__footer">
                                <div>
                                    <Button
                                        className="bt bt-inverted"
                                        gtmLabel="ec_historial_envios_detalles"
                                        onClick={() => {
                                            handleBtnToDetailsOnClick(delivery)
                                        }}
                                    >
                                        <Translation id="view_details" defaultMessage="View details" />
                                    </Button>
                                </div>
                            </footer>
                        )}
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default injectIntl(NewShippingCard)
