import React from 'react'

import { connect } from 'react-redux'
import ReservationsContainer from '../components/unassigned-reservations/ReservationsContainer'

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}

class ReservationsScreen extends React.Component {
    render() {
        return <ReservationsContainer />
    }
}
ReservationsScreen = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReservationsScreen)

export default ReservationsScreen
