import React from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { connect } from 'react-redux'
import { currentDeliveryId, currentDeliveryDetailLines } from '../../../store/kpis/reducers'
import { closeDeliveryDetail } from '../../../store/kpis/actions'

const mapStateToProps = state => {
    return {
        deliveryDetail: currentDeliveryDetailLines(state),
        deliveryId: currentDeliveryId(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(closeDeliveryDetail())
        }
    }
}

const KpiDetailModal = ({ deliveryDetail, deliveryId, onClose }) => (
    <div style={{ display: deliveryId ? 'block' : 'none' }}>
        <div className="modal-kpi-overlay" onClick={onClose} />
        <div className="modal-kpi-container">
            <div className="container header row no-gutters">
                <div className="col-6 my-auto">
                    <h3>Order: {deliveryId}</h3>
                </div>
                <div className="col-6 my-auto text-right">
                    <div className="bt-close" onClick={onClose}>
                        <i className="fal fa-times" />
                    </div>
                </div>
            </div>
            <div className="table">
                <ReactTable
                    data={deliveryDetail}
                    columns={[
                        {
                            Header: 'deliveryId',
                            accessor: 'deliveryId'
                        },
                        {
                            Header: 'pos',
                            accessor: 'pos'
                        },
                        {
                            Header: 'productId',
                            accessor: 'productId'
                        },
                        {
                            Header: 'product',
                            accessor: 'product'
                        },
                        {
                            Header: 'qty',
                            accessor: 'qty'
                        },
                        {
                            Header: '_UM',
                            accessor: '_UM'
                        },
                        {
                            Header: 'shippingPlace',
                            accessor: 'shippingPlace'
                        },
                        {
                            Header: 'createdBy',
                            accessor: 'createdBy'
                        },
                        {
                            Header: '_Doc.compr.',
                            accessor: '_Doc.compr.'
                        },
                        {
                            Header: '_Doc.modelo',
                            accessor: '_Doc.modelo'
                        },
                        {
                            Header: '_PosMod',
                            accessor: '_PosMod'
                        },
                        {
                            Header: 'lotId',
                            accessor: 'lotId'
                        },
                        {
                            Header: '_Nº stock esp.',
                            accessor: '_Nº stock esp.'
                        }
                    ]}
                    defaultPageSize={8}
                    pageSizeOptions={[8, 16, 32, 64, 128]}
                    previousText="Anterior"
                    nextText="Siguiente"
                    loadingText="Cargando..."
                    noDataText="No hay datos"
                    pageText="Página"
                    ofText="de"
                />
            </div>
        </div>
    </div>
)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KpiDetailModal)
