import React from 'react'
import Translation from '../global/Translation'

import Button from '../global/Button'
import logo from '../../assets/img/e.cosentino.svg'
import LocalizedLink from '../global/LocalizedLink'

class Add2CartSuccess extends React.Component {
    render() {
        const { visible, productId, close } = this.props

        return (
            <div>
                <div className="full-overlay" style={{ display: visible ? 'block' : 'none' }} />
                <div id="order-finished-container" className="order-finished" style={{ display: visible ? 'block' : 'none' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-5 logo-container d-none d-lg-block">
                                <img alt="" id="logo-img" className="profile-img-card" src={logo} />
                            </div>
                            <div className="col-12 col-lg-7 container">
                                <header className="container-fluid" />
                                <div className="row">
                                    <div className="col-12">
                                        <h2>
                                            <i className="fal fa-check-circle check-icon" />
                                            <Translation id="add2cart_success_header" defaultMessage="Product added to the cart successfully" />
                                        </h2>
                                    </div>
                                </div>
                                <footer>
                                    <div className="col-12 col-md-6 offset-md-6">
                                        <Button inverted onClick={close}>
                                            <Translation id="keep_buying" defaultMessage="Keep Buying" />
                                        </Button>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Add2CartSuccess
