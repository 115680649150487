import Translation from "../../global/Translation";
import Button from "../../global/Button";
import SimpleModal from "../../global/SimpleModal";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {connect} from "react-redux";
import {getAvailableLands} from "../../../store/ui/reducers";
import {injectIntl} from "react-intl";

const AdditionalInfoModal = props => {

    const getParsedAdditionalInfo = (value) => {
        try {
            return JSON.parse(value);
        } catch (e) {
            return null;
        }
    }

    const getInfoLanguages = (info) => {
        if (info) {
            let languages = Object.keys(info)
            const defaultIndex = languages.indexOf('default');
            if (defaultIndex > -1) {
                languages.splice(defaultIndex, 1);
            }
            return languages.map(l => _availableLanguages.find(al => al.value === l))
        }
        return null
    }

    const { onClose, setAdditionalInfo, additionalInfo, lands, intl } = props
    const [_languages, _setLanguages] = useState(null);
    const [_defaultLanguage, _setDefaultLanguage] = useState(null);
    const [_translations, _setTranslations] = useState(getParsedAdditionalInfo(additionalInfo));

    const getLanguages = () => {
        return lands
            .map(land => {
                return { value: land.localecode, label: land.name, name: land.locale, code: land.code }
            })
            .sort((a, b) => {
                if (a.label < b.label) return -1
                if (a.label > b.label) return 1
                return 0
            })
    }

    let _availableLanguages = getLanguages()

    useEffect(() => {
        const currentLanguages = getInfoLanguages(_translations)
        _setLanguages(currentLanguages)
        if (_translations) {
            const defaultLang = _translations.default ? _translations.default : Object.keys(_translations)[0]
            setDefaultLanguage(defaultLang)
        }
    }, [])

    const setDefaultLanguage = (lang) => {
        _setTranslations({..._translations, default: lang})
        _setDefaultLanguage(lang)
    }

    const handleSetDefaultLanguage = (event) => {
        let value = undefined
        if (event.target && event.target.checked) {
            value = event.target.id.split('_').length > 1 ? event.target.id.split('_')[1] : ''
        }
        setDefaultLanguage(value)
    }

    const handleChangeComment = (event) => {
        let value = event.target.value
        let key = event.target.id

        const newTranslations = {..._translations, [key]: value}
        if (!newTranslations.default) {
            newTranslations.default = key
            _setDefaultLanguage(key)
        }

        _setTranslations(newTranslations)
    }

    const handleSetTranslations = () => {
        const usedLangs = _languages && _languages.map(l => l.value)
        if (!usedLangs || usedLangs.length === 0) _setTranslations(null)

        if (_translations) {
            const unusedLangs = Object.keys(_translations).filter(l => !usedLangs.includes(l) && l !== 'default')
            if (!usedLangs.includes(_translations.default)) {
                _translations.default = usedLangs[0]
            }
            unusedLangs.forEach(l => delete _translations[l])
        }


        setAdditionalInfo(JSON.stringify(_translations))
        onClose()
    }

    return (
        <SimpleModal
            customClass="landings__modal landings__modal--text"
            title={''}
            onClose={onClose}
            size="large"
            footer={null}
        >
            <div style={{minHeight:'50vh', display:'flex', flexFlow:'column', justifyContent:'space-between'}}>
                <h3><Translation id="product_additional_info" defaultMessage="Product additional info"/></h3>

                <div className="row" style={{flexGrow:0}}>
                    <div className="col-12">
                        <div className="form-group select_languages">
                            <label htmlFor="select_additional_info_languages">
                                <strong><Translation id="select_languages" defaultMessage="Select languages" /></strong>
                            </label>
                            <Select
                                required
                                id="select_additional_info_languages"
                                multi={true}
                                className="form-control"
                                placeholder={intl.formatMessage({ id: 'promotion_select_languages' })}
                                value={_languages}
                                onChange={selectedOption => _setLanguages(selectedOption)}
                                options={_availableLanguages}
                            />
                        </div>
                        <div>
                            <strong><Translation id="set_additional_info" defaultMessage="Set additional info" /></strong>
                        </div>
                    </div>
                </div>

                <div className="container add_info_container" style={{overflowY:'auto', flexGrow:1, maxHeight:'25vh'}}>
                    <div className="row my-2">
                        <div className="col-4">
                            <Translation id="default_language" defaultMessage="Default language" />
                        </div>
                        <div className="col-8">
                            <Translation id="additional_info" defaultMessage="Additional info" />
                        </div>
                    </div>
                    {_languages && _languages.map(lang => {
                        return (
                            <div id={'group_' + lang.value} className="row add_info_row">
                                <div className="col-1">
                                    <input
                                        id={`default_${lang.value}`}
                                        type="radio"
                                        checked={_defaultLanguage === lang.value}
                                        onChange={handleSetDefaultLanguage}
                                    />
                                </div>
                                <div className="col-1">
                                    <span className={`flag-icon flag-icon-${lang.code} mx-1`} />
                                </div>
                                <div className="col-2">
                                    <label>{lang.label}</label>
                                </div>
                                <div className="col-8">
                                    <input type="text"
                                           id={lang.value}
                                           value={_translations && _translations[lang.value]}
                                           onChange={handleChangeComment}
                                           style={{width:'100%'}}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="d-flex w-100 justify-content-end mt-3">
                    <Button
                        inverted
                        className="landings__products__item__file__buttons__image"
                        onClick={handleSetTranslations}
                    >
                        <Translation id="accept" defaultMessage="Accept"/>
                    </Button>

                </div>
            </div>
        </SimpleModal>
    )
}

const mapStateToProps = (state, { match }) => {
    return {
        lands: getAvailableLands(state),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    null
)(AdditionalInfoModal))