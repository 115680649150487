import React from 'react'
import Translation from '../global/Translation'
import {injectIntl, defineMessages} from 'react-intl'

import { connect } from 'react-redux'

import TipModal from '../global/TipModal'
import { getCurrentCartCrossSelling, showCrossSellingModal } from '../../store/crossSelling/reducers'
import SimpleCartLine from '../cart-line/SimpleCartLine'
import { getLastLine } from '../../store/cart/reducers'
import SingleRectangleResult from '../catalog/rectangleResult/SingleRectangleResult'
import { canViewCrosselling } from '../../store/login/reducers'

defineMessages({
    added_products_to_your_cart: {
        id: 'added_products_to_your_cart',
        description: 'You have added products to your cart!',
        defaultMessage: 'You have added products to your cart!'
    }
})


const mapStateToProps = state => {
    return {               
        showCrossSellingModal: showCrossSellingModal(state),
        crossSelling: getCurrentCartCrossSelling(state),
        line : getLastLine(state),
        canViewCrosselling: canViewCrosselling(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hide: () => dispatch({type: 'HIDE_CROSSELLLING_MODAL'}),
        sendMetrics: (lines) => dispatch({type: 'SHOW_CROSS_SELLING_MODAL', lines})
    }
}

class CrossSellingModal extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        let hide = this.props.hide
        window.addEventListener('click', function(e) {
            let element = document.getElementById('cross-selling-modal')
            if(element){
                if (!document.getElementById('cross-selling-modal').contains(e.target)) {
                    hide()
                }
            }
        })
    }


    componentDidUpdate(prevProps) {
        if (prevProps.crossSelling !== this.props.crossSelling) {
            if (this.props.crossSelling) this.props.sendMetrics(this.props.crossSelling)
        }
    }

    isProductTypeSlab = () => {
        const { line } = this.props

        if (line && line.cutToSize && line.cutToSize.config) {
            return false
        }

        if (line && line.product && line.product.type && line.product.type == 'TABLA') {
            return true
        }

        return false
    }

    render() {
        const { line, crossSelling, showCrossSellingModal , hide, intl} = this.props
        const showCrossSelling = crossSelling && ((crossSelling.single && crossSelling.single.length) || crossSelling.multi)
        let index = 1

        if (!showCrossSellingModal ||!line) return null
        return (
            <div className="cross-selling-modal fade show" id='cross-selling-modal'>
                <TipModal onClose={hide} title={intl.formatMessage({ id: 'added_products_to_your_cart' })} >
                    <SimpleCartLine line={line}/>
                    { showCrossSelling && canViewCrosselling && this.isProductTypeSlab() && (
                        <div className="cross-selling-list-section">
                            <div className="tip-modal-header py-2">
                                <Translation id="bought_together" defaultMessage="Usually bought together"/>
                            </div>

                            <div className="cross-selling-list">
                                {
                                    crossSelling.single && crossSelling.single.map(product => (
                                        <SingleRectangleResult product={product} key={product.productId} list={'crossselling-popupcarrito'} indexOfList={index++} sectionFlag="CA"/>
                                    )
                                )}
                                {
                                    crossSelling.multi && (
                                        <SingleRectangleResult product={crossSelling.multi} list={'crossselling-popupcarrito'} key={crossSelling.multi.productId} count={crossSelling.multi_size} sectionFlag="CA"/>
                                    )
                                }
                            </div>
                        </div>
                    )}
                    { !showCrossSelling && (
                        <div style={{height:'8px'}} />
                    )}
                    
                </TipModal>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CrossSellingModal)
)
