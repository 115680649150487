import React, { Component } from 'react'
import { injectIntl } from 'react-intl'         
import ElaborationType from './ElaborationType'
import { ElaborationOptionsMap } from './CutToSizeMaps'
import Translation from '../global/Translation'
import backgroundImageV2 from '../../assets/img/cut-to-size/Path-v2.png'
import LocalizedLink from "../global/LocalizedLink";


class ElaborationTypeSelector extends Component {

    render () {
        const {materials} = this.props

        return (
            <div name="cut_to_size_elaboration_type_selector">
                <div className="row no-gutters">
                    <h2 className="questa-bold title-cts">
                        <Translation id="custom_products" defaultMessage="Custom Products" />
                    </h2>
                </div>
                {
                    materials && (
                        <React.Fragment>
                            <div className="custom-products-block">
                                {
                                    Object.keys(materials).filter(el => el !== 'BUDGET').map(elaboration => {
                                        const option = ElaborationOptionsMap[elaboration]
                                        return (
                                            <div key={`id_${elaboration}`} className="custom-products-block__key">
                                                <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} header={true} queryString={`?type=${elaboration.toLowerCase()}`} params={{'outlet':''}} gtmLabel={option.gtmLabel}>
                                                    <ElaborationType
                                                        id={elaboration}
                                                        brands={Object.keys(materials[elaboration])}
                                                        title={this.props.intl.formatMessage(option.name)}
                                                        icon={option.image}
                                                        description={this.props.intl.formatMessage(option.text)}
                                                    />
                                                </LocalizedLink>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="custom-products-banner-block">
                                <div className="custom-products-banner-block__content">
                                    <div>
                                        <h4><Translation id="dont_find_your_cut" defaultMessage="Don't find the cut to size you need?" /></h4>
                                    </div>
                                    <div>
                                        <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} header={true} queryString={`?type=${this.props.hasPreOrder ? 'preorder' : 'budget'}`} params={{'outlet':''}}>
                                            <button dataQas="cts_main_request-quotation-button">
                                                {
                                                    this.props.hasPreOrder ?
                                                        <span><Translation id="request_for_preorder" defaultMessage="Request an order/offer" /></span>
                                                        :
                                                        <span><Translation id="request_for_quote" defaultMessage="Request for quote" /></span>
                                                }
                                                <i class="fas fa-chevron-right"></i>
                                            </button>
                                        </LocalizedLink>                             
                                    </div>
                                </div>
                                <div className="custom-products-banner-block__img">
                                    <img src={backgroundImageV2} alt="Request an order" />
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        )
    }
}

export default injectIntl(ElaborationTypeSelector)