import React from 'react'
import { connect } from 'react-redux'
import ecosentinoNegativeLogo from '../../assets/img/logos/brands/ecosentino-logo-negative.svg'
import silestoneLogo from '../../assets/img/logos/brands/logo-silestone.svg'
import dektonLogo from '../../assets/img/logos/brands/logo-dekton.svg'
import dktnLogo from '../../assets/img/DKTN.svg'
import sensaLogo from '../../assets/img/logos/brands/logo-sensa.svg'
import naturalStoneLogo from '../../assets/img/logos/brands/logo-natural-stone.svg'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import { getUsaCanadaSalesOrg } from '../../store/centers/reducers'
import { getUsaCaPrTermsAndConditionsByLang } from '../../helpers'
import DeveloperBar from '../global/DeveloperBar'

const TinyFooter = props => {
    const { selectedCenter, salesOrg, language , isAustralianCenter} = props

    return (
        
        <footer className="tinyfooter-main-container">
            {process.env.NODE_ENV !== 'production' && <DeveloperBar />}
            <div className="tinyfooter-main-container__first-level">
                <div className="tinyfooter-main-container__first-level--ecosentino-logo">
                    <img src={ecosentinoNegativeLogo} alt="eCosentino" />
                </div>
                <div className="tinyfooter-main-container__first-level--cosentino-brands">
                    {!isAustralianCenter && (
                        <div>
                            <a href="https://qr.cosentino.com/brand/silestone" target="_blank" rel="noopener noreferrer">
                                <img src={silestoneLogo} alt="Silestone" className="silestone-brand" />
                            </a>
                        </div>
                    )}
                    <div>
                        <a href="https://qr.cosentino.com/brand/dekton" target="_blank" rel="noopener noreferrer">
                            {selectedCenter == 7130 ? <img src={dktnLogo} alt="DKTN" className="dktn-brand" /> : <img src={dektonLogo} alt="Dekton" className="dekton-brand" />}
                        </a>
                    </div>
                    <div>
                        <a href="https://qr.cosentino.com/brand/sensa" target="_blank" rel="noopener noreferrer">
                            <img src={sensaLogo} alt="Sensa" className="sensa-brand" />
                        </a>
                    </div>
                    <div>
                        <a href="https://qr.cosentino.com/brand/scalea" target="_blank" rel="noopener noreferrer">
                            <img src={naturalStoneLogo} alt="Natural Stone" className="natural-stone-brand" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="tinyfooter-main-container__second-level">
                <div>
                    <span>
                        © Cosentino S.A. <Translation id="all_rights_reserved" defaultMessage="All rights reserved" />
                    </span>
                </div>
                <div className="tinyfooter-main-container__second-level--terms">
                    <div>
                        <LocalizedLink routeId="ROUTE_COOKIES" target="_blank">
                            <Translation id="cookies_policy" defaultMessage="Cookies policy" />
                        </LocalizedLink>
                    </div>
                    <div>
                        <LocalizedLink routeId="ROUTE_PRIVACY" target="_blank">
                            <Translation id="privacy_policy" defaultMessage="Privacy policy" />
                        </LocalizedLink>
                    </div>
                    <div>
                        {getUsaCanadaSalesOrg().includes(salesOrg) ? (
                            <a href={getUsaCaPrTermsAndConditionsByLang(language)} target="_blank" rel="nofollow noopener noreferrer">
                                <Translation id="general_conditions_of_sale" defaultMessage="General conditions of sale" />
                            </a>
                        ) : (
                            <a
                                href="https://assetstools.cosentino.com/api/v1/bynder/doc/511A2D40-054A-41D5-89CE81CF6DB105D8/cgv.pdf"
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                            >
                                <Translation id="general_conditions_of_sale" defaultMessage="General conditions of sale" />
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default TinyFooter
