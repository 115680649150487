import React from 'react'
import OldOrders from './OldOrders'
import NewOrderHistoryContainer from './new-order-history/order/NewOrderHistoryContainer'

class OrdersSelector extends React.Component {
    render() {
        let showOldOrders = this.props.isIkeaCenter || !this.props.canViewNewTracking
        return showOldOrders ? <OldOrders {...this.props} /> : <NewOrderHistoryContainer />
    }
}

export default OrdersSelector
