import React from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import Modal from '../../global/Modal'
import BannerPicker from '../BannerPicker'
import BannerPickerContainer from '../BannerPickerContainer'
import I18nPromotionModal from '../I18nPromotionModal'
import Button from '../../global/Button'
import { defineMessages } from 'react-intl'
import Alerts from '../../alerts/Alerts'
import { cloneDeep } from 'lodash'

defineMessages({
    MAX_SIZE: {
        id: 'max_size',
        description: 'max_size',
        defaultMessage: 'Max size'
    },
    ERROR_DIMENSIONS: {
        id: 'error_dimensions',
        description: 'error_dimensions',
        defaultMessage: 'Error dimensions'
    }
})

class ChooseBanner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newBannerLanding: false,
            alertBannerLanding: '',
            bannerLanding: this.props.headLandingBannerData,
            newBannerHome: false,
            alertBannerHome: '',
            bannerHome: this.props.homeBannerData,
            newBannerNewsLetter: false,
            alertBannerNewsLetter: '',
            bannerNewsLetter: this.props.headEmailBannerData,
            newSideBannerNewsLetter: false,
            alertSideBannerNewsLetter: '',
            sideBannerNewsLetter: this.props.lateralBanner,
            showModal: false,
            showModalI18n: false,
            objectToTranslate: null,
            objectTrans: null,
            currentBannerEdit: '', // [landing || home || letter || side-letter ]
            currentBannerSelectedId: null,
            //Unsaved changes
            hasUnsavedChanges: {
                promotionBannerTitleDirty: false,
                promotionBannerDescriptionDirty: false,
            },
            bannerTitleCloned: null,
            bannerDescriptionCloned: null
        }
        this.inputFileBannerLanding = React.createRef()
        this.inputFileBannerHome = React.createRef()
        this.inputFileBannerNewsletter = React.createRef()
        this.inputFileSideBannerNewsletter = React.createRef()
        this.bannerSizes = {
            home: {
                width: 1140,
                height: 200
            },
            landing: {
                width: 1140,
                height: 800
            },
            letter: {
                width: 800,
                height: 800
            },
            'side-letter': {
                width: 1200,
                height: 1200
            }
        }

        if (!this.props.translations.banner_title) this.props.saveTranslationObject('banner_title', { default: '' })
        if (!this.props.translations.banner_description) this.props.saveTranslationObject('banner_description', { default: '' })
    }

    componentDidMount(){
        this.props.handleReference(this)

        //Get the initial value of banner title to have something to compare
        const bannerTitleCloned = cloneDeep(this.props.translations.banner_title)
        this.setState({ bannerTitleCloned: bannerTitleCloned })

        //Get the initial value of banner title to have something to compare
        const bannerDescriptionCloned = cloneDeep(this.props.translations.banner_description)
        this.setState({ bannerDescriptionCloned: bannerDescriptionCloned })

    }

    /**
     * Handle the state value banner title unsaved changes.
     */
    handleBannerTitleUnsavedChanges = () => {
        const stateBannerTitle = this.state.bannerTitleCloned && this.state.bannerTitleCloned.default
        const propsBannerTitle = this.props.translations.banner_title && this.props.translations.banner_title.default

        // Check if old value is same or diferent of new the value. Then update state.
        if (propsBannerTitle !== stateBannerTitle) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionBannerTitleDirty: true} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepSeven(this.state.hasUnsavedChanges)
            })
        } else if (propsBannerTitle === stateBannerTitle) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionBannerTitleDirty: false} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepSeven(this.state.hasUnsavedChanges)
            })
        }
    }

    /**
     * Handle the state value banner description unsaved changes.
     */
    handleBannerDescriptionUnsavedChanges = () => {
        const stateBannerDescription = this.state.bannerDescriptionCloned && this.state.bannerDescriptionCloned.default
        const propsBannerDescription = this.props.translations.banner_description && this.props.translations.banner_description.default

        // Check if old value is same or diferent of new the value. Then update state.
        if (propsBannerDescription !== stateBannerDescription) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionBannerDescriptionDirty: true} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepSeven(this.state.hasUnsavedChanges)
            })
        } else if (propsBannerDescription === stateBannerDescription) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionBannerDescriptionDirty: false} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepSeven(this.state.hasUnsavedChanges)
            })
        }
    }

    validateImage = (img, typeOfImage, width, height, fnAlert) => {
        let isValid = true
        if ((typeOfImage === 'banner-home' || typeOfImage === 'side-banner-newsletter') && (img.height !== height || img.width !== width)) {
            //banner home and banner portfolio must be exact dimensions
            isValid = false
            fnAlert(
                this.props.intl.formatMessage({
                    id: 'error_dimensions',
                    defaultMessage: 'Error dimensions'
                }) +
                    ' width: ' +
                    width +
                    ', height: ' +
                    height
            )
        } else if ((typeOfImage === 'banner-landing' || typeOfImage === 'banner-newsletter') && (img.height > height || img.width !== width)) {
            //banner landing and newsletter only checks width and up to 800 height
            isValid = false
            fnAlert(
                this.props.intl.formatMessage({
                    id: 'error_dimensions',
                    defaultMessage: 'Error dimensions'
                }) +
                    ' width: ' +
                    width +
                    ', max-height: ' +
                    height
            )
        }

        return isValid
    }
    handleSubmitUploadImage = (e, input, width, height, fnAlert, typeOfImage) => {
        e.preventDefault()
        let maxSize = 100000000
        if (input && input.files && input.files[0]) {
            let _URL = window.URL || window.webkitURL
            let img = new Image()
            img.onload = () => {
                let isValid = this.validateImage(img, typeOfImage, width, height, fnAlert)
                if (input.files[0].size >= maxSize) {
                    isValid = false
                    fnAlert(
                        this.props.intl.formatMessage({
                            id: 'max_size',
                            defaultMessage: 'Max size'
                        }) +
                            ': ' +
                            maxSize
                    )
                }
                if (isValid) {
                    let formData = new FormData()
                    formData.append('file', input.files[0])
                    formData.append('folder', 'promotions_ecommerce')
                    this.props.uploadPromotionFile(formData).then(action => this.setCurrentBannerEdit(action.banner))
                }
            }
            img.src = _URL.createObjectURL(input.files[0])
        }
    }

    handleSubmit = e => {
        if(e) e.preventDefault()
        this.setDefaultLanguage()
        if (!Object.keys(this.state.bannerLanding).length || !Object.keys(this.state.bannerNewsLetter).length) {
            this.setState({ showAlertRequiredBanners: true })
        } else {
            this.setState({ showAlertRequiredBanners: false })

            //No more unsaved changes after send the form for step one
            this.setState({ hasUnsavedChanges: {
                ...this.state.hasUnsavedChanges,
                promotionBannerTitleDirty: false,
                promotionBannerDescriptionDirty: false,
            }}, () => {
                this.props.sendBanners(
                    this.state.bannerLanding,
                    this.state.bannerHome,
                    this.state.bannerNewsLetter,
                    this.state.sideBannerNewsLetter,
                    JSON.stringify(this.props.translations.banner_title),
                    JSON.stringify(this.props.translations.banner_description)
                )

                //Update global state after form submit
                this.props.setUnsavedChangesStepSeven(this.state.hasUnsavedChanges)

                //Make sure the change step is the last function
                //Only if event exists we use this change step. If not we use the one inside StepsBar component
                if (e) {
                    this.props.changeStep(this.props.step + 1)
                }
            })
        }
    }

    setCurrentBannerEdit = banner => {
        switch (this.state.currentBannerEdit) {
            case 'landing':
                //                 banner-home
                // side-banner-newsletter
                // banner-landing
                // banner-newsletter
                this.setState({ bannerLanding: banner, newBannerLanding: false })
                break
            case 'home':
                this.setState({ bannerHome: banner, newBannerHome: false })
                break
            case 'letter':
                this.setState({ bannerNewsLetter: banner, newBannerNewsLetter: false })
                break
            case 'side-letter':
                this.setState({ sideBannerNewsLetter: banner, newSideBannerNewsLetter: false })
                break
            default:
                break
        }
    }

    stepBack() {
        this.props.stepBack(this.props.step - 1)
    }

    openModalLanding = () => {
        this.setState({
            newBannerLanding: false,
            showModal: true,
            currentBannerEdit: 'landing',
            currentBannerSelectedId: this.state.bannerLanding.id
        })
    }
    openModalHome = () => {
        this.setState({
            newBannerHome: false,
            showModal: true,
            currentBannerEdit: 'home',
            currentBannerSelectedId: this.state.bannerHome.id
        })
    }
    openModalNewsletter = () => {
        this.setState({
            newBannerNewsLetter: false,
            showModal: true,
            currentBannerEdit: 'letter',
            currentBannerSelectedId: this.state.bannerNewsLetter.id
        })
    }

    openModalSideNewsletter = () => {
        this.setState({
            newSideBannerNewsLetter: false,
            showModal: true,
            currentBannerEdit: 'side-letter',
            currentBannerSelectedId: this.state.sideBannerNewsLetter.id
        })
    }

    updateTranslations(object, updatedTranslations) {
        this.props.saveTranslationObject(object, updatedTranslations)
        this.setState({
            showModalI18n: false,
            objectToTranslate: null,
            objectTrans: null,
            [object]: updatedTranslations
        }, () => {
            this.handleBannerTitleUnsavedChanges()
            this.handleBannerDescriptionUnsavedChanges()
        })
    }

    showI18nModal(objectId) {
        if (objectId) {
            this.setState({
                showModalI18n: true,
                objectToTranslate: objectId,
                objectTrans: this.props.translations[objectId]
            }, () => {
                this.handleBannerTitleUnsavedChanges()
                this.handleBannerDescriptionUnsavedChanges()
            })
        }
    }

    setDefaultLanguage() {
        let banner_title = this.props.translations.banner_title
        banner_title['default_text'] = banner_title[banner_title['default']]
        let banner_description = this.props.translations.banner_description
        banner_description['default_text'] = banner_description[banner_description['default']]

        this.setState({
            banner_title: banner_title,
            banner_description: banner_description
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-lg-10">
                        <h3 style={{ fontWeight: '400' }}>
                            <span style={{ fontWeight: '300' }}>
                                <Translation id="step" defaultMessage={'Step'} /> {this.props.step}
                            </span>{' '}
                            <Translation id="promotion_line_banner" defaultMessage="Banners" />
                        </h3>
                        <p>
                            <Translation id="select_the_banner_to_apply_your_campaign" defaultMessage="Select the banner to apply your campaign" />
                        </p>
                    </div>
                </div>
                <form id="form_current_step" onSubmit={e => this.handleSubmit(e)} ref={form => (this.form = form)}>
                    {this.state.showAlertRequiredBanners && <Alerts alert={'BANNER_HOME_AND_BANNER_NEWSLETTER_REQUIRED'} status="warning" />}
                    <div className="row my-4">
                        <SelectOrUpload
                            title={<Translation id="banner_landing" defaultMessage="Landing Banner" />}
                            requirements={
                                <small>
                                    <Translation id="banner-landing-requirements" defaultMessage="Banner landing must be 1140 width and up to 800 height" />
                                </small>
                            }
                            isNewBanner={this.state.newBannerLanding}
                            banner={this.state.bannerLanding}
                            openModal={() => this.openModalLanding()}
                            setNewBanner={() => this.setState({ newBannerLanding: true })}
                            image={'banner-landing'}
                        />

                        <SelectOrUpload
                            title={<Translation id="banner_home" defaultMessage="Home Banner" />}
                            requirements={
                                <small>
                                    <Translation id="banner-home-requirements" defaultMessage="Banner home must be 1140 width and 200 height" />
                                </small>
                            }
                            isNewBanner={this.state.newBannerHome}
                            banner={this.state.bannerHome}
                            openModal={() => this.openModalHome()}
                            setNewBanner={() => this.setState({ newBannerHome: true })}
                            image={'banner-home'}
                            clear={() => this.setState({ bannerHome: {}, newBannerHome: false })}
                        />
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 px-5">
                            {this.state.alertBannerLanding && (
                                <div className="alert alert-danger d-flex justify-content-between my-2" role="alert">
                                    <span>{this.state.alertBannerLanding}</span>
                                    <i className="fal fa-times " style={{ cursor: 'pointer' }} onClick={() => this.setState({ alertBannerLanding: '' })} />
                                </div>
                            )}
                            {this.state.newBannerLanding ? (
                                <div className="d-flex justify-content-between py-2">
                                    <div className="form-group input_name overflow-hidden my-0 mr-2">
                                        <input
                                            name="new-banner-landing"
                                            id="new-banner-landing"
                                            ref={this.inputFileBannerLanding}
                                            required
                                            type="file"
                                            max-size="1000"
                                            className="form-control"
                                            data-cy="new-banner-landing"
                                        />
                                    </div>

                                    <div className="d-flex align-items-center">
                                        <Button
                                            onClick={e =>
                                                this.setState({ currentBannerEdit: 'landing' }, () =>
                                                    this.handleSubmitUploadImage(
                                                        e,
                                                        this.inputFileBannerLanding.current,
                                                        1140,
                                                        800,
                                                        msg => this.setState({ alertBannerLanding: msg }),
                                                        'banner-landing'
                                                    )
                                                )
                                            }
                                        >
                                            <Translation id="upload" defaultMessage="Upload" />
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                this.state.bannerLanding &&
                                this.state.bannerLanding.url && (
                                    <div className="py-4" onClick={() => this.openModalLanding()}>
                                        <div>
                                            <img src={this.state.bannerLanding.url} alt="img-fluid" className="img-fluid" />
                                            <div className="my-2">
                                                <span>{this.state.bannerLanding.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <div className="col-12 col-md-6 px-5">
                            {this.state.alertBannerHome && (
                                <div className="alert alert-danger d-flex justify-content-between my-2" role="alert">
                                    <span>{this.state.alertBannerHome}</span>
                                    <i className="fal fa-times " style={{ cursor: 'pointer' }} onClick={() => this.setState({ alertBannerHome: '' })} />
                                </div>
                            )}
                            {this.state.newBannerHome ? (
                                <div className="d-flex justify-content-between py-2">
                                    <div className="form-group input_name overflow-hidden my-0 mr-2">
                                        <input
                                            name="new-banner-home"
                                            id="new-banner-home"
                                            ref={this.inputFileBannerHome}
                                            required
                                            type="file"
                                            max-size="1000"
                                            className="form-control"
                                            data-cy="new-banner-home"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Button
                                            onClick={e =>
                                                this.setState({ currentBannerEdit: 'home' }, () =>
                                                    this.handleSubmitUploadImage(
                                                        e,
                                                        this.inputFileBannerHome.current,
                                                        1140,
                                                        200,
                                                        msg => this.setState({ alertBannerHome: msg }),
                                                        'banner-home'
                                                    )
                                                )
                                            }
                                        >
                                            <Translation id="upload" defaultMessage="Upload" />
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                this.state.bannerHome &&
                                this.state.bannerHome.url && (
                                    <div className="py-4" onClick={() => this.openModalHome()}>
                                        <div>
                                            <img src={this.state.bannerHome.url} alt="img-fluid" className="img-fluid" />
                                            <div className="my-2">
                                                <span>{this.state.bannerHome.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className="row my-4">
                        <SelectOrUpload
                            title={<Translation id="banner_newsletter" defaultMessage="Newsletter Banner" />}
                            requirements={
                                <small>
                                    <Translation id="banner-newsletter-requirements" defaultMessage="Banner newsletter must be 800 width and up to 800 height" />
                                </small>
                            }
                            isNewBanner={this.state.newBannerNewsLetter}
                            banner={this.state.bannerNewsLetter}
                            openModal={() => this.openModalNewsletter()}
                            setNewBanner={() => this.setState({ newBannerNewsLetter: true })}
                            image={'banner-newsletter'}
                        />

                        <SelectOrUpload
                            title={<Translation id="side_banner_newsletter" defaultMessage="Portfolio Banner" />}
                            requirements={
                                <small>
                                    <Translation id="banner-portfolio-requirements" defaultMessage="Banner portfolio must be 1200 width and 1200 height" />
                                </small>
                            }
                            isNewBanner={this.state.newSideBannerNewsLetter}
                            banner={this.state.sideBannerNewsLetter}
                            openModal={() => this.openModalSideNewsletter()}
                            setNewBanner={() => this.setState({ newSideBannerNewsLetter: true })}
                            image={'side-banner-newsletter'}
                            clear={() => this.setState({ sideBannerNewsLetter: {}, newSideBannerNewsLetter: false })}
                        />
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 px-5">
                            {this.state.alertBannerNewsLetter && (
                                <div className="alert alert-danger d-flex justify-content-between my-2" role="alert">
                                    <span>{this.state.alertBannerNewsLetter}</span>
                                    <i className="fal fa-times " style={{ cursor: 'pointer' }} onClick={() => this.setState({ alertBannerNewsLetter: '' })} />
                                </div>
                            )}
                            {this.state.newBannerNewsLetter ? (
                                <div className="d-flex justify-content-between py-2">
                                    <div className="form-group input_name overflow-hidden my-0 mr-2">
                                        <input
                                            name="new-banner-newsletter"
                                            id="new-banner-newsletter"
                                            ref={this.inputFileBannerNewsletter}
                                            required
                                            type="file"
                                            max-size="1000"
                                            className="form-control"
                                            data-cy="new-banner-newsletter"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Button
                                            onClick={e =>
                                                this.setState({ currentBannerEdit: 'letter' }, () =>
                                                    this.handleSubmitUploadImage(
                                                        e,
                                                        this.inputFileBannerNewsletter.current,
                                                        800,
                                                        800,
                                                        msg => this.setState({ alertBannerNewsLetter: msg }),
                                                        'banner-newsletter'
                                                    )
                                                )
                                            }
                                        >
                                            <Translation id="upload" defaultMessage="Upload" />
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                this.state.bannerNewsLetter &&
                                this.state.bannerNewsLetter.url && (
                                    <div className="py-4" onClick={() => this.openModalLanding()}>
                                        <div>
                                            <img src={this.state.bannerNewsLetter.url} alt="img-fluid" className="img-fluid" />
                                            <div className="my-2">
                                                <span>{this.state.bannerNewsLetter.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <div className="col-12 col-md-6 px-5">
                            {this.state.alertSideBannerNewsletter && (
                                <div className="alert alert-danger d-flex justify-content-between my-2" role="alert">
                                    <span>{this.state.alertSideBannerNewsletter}</span>
                                    <i className="fal fa-times " style={{ cursor: 'pointer' }} onClick={() => this.setState({ alertSideBannerNewsletter: '' })} />
                                </div>
                            )}
                            {this.state.newSideBannerNewsLetter ? (
                                <div className="d-flex justify-content-between py-2">
                                    <div className="form-group input_name overflow-hidden my-0 mr-2">
                                        <input
                                            name="new-side-banner-newsletter"
                                            id="new-side-banner-newsletter"
                                            ref={this.inputFileSideBannerNewsletter}
                                            required
                                            type="file"
                                            max-size="1000"
                                            className="form-control"
                                            data-cy="new-side-banner-newsletter"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Button
                                            onClick={e =>
                                                this.setState({ currentBannerEdit: 'side-letter' }, () =>
                                                    this.handleSubmitUploadImage(
                                                        e,
                                                        this.inputFileSideBannerNewsletter.current,
                                                        1200,
                                                        1200,
                                                        msg => this.setState({ alertSideBannerNewsletter: msg }),
                                                        'side-banner-newsletter'
                                                    )
                                                )
                                            }
                                        >
                                            <Translation id="upload" defaultMessage="Upload" />
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                this.state.sideBannerNewsLetter &&
                                this.state.sideBannerNewsLetter.url && (
                                    <div className="py-4" onClick={() => this.openModalHome()}>
                                        <div>
                                            <img src={this.state.sideBannerNewsLetter.url} alt="img-fluid" className="img-fluid" />
                                            <div className="my-2">
                                                <span>{this.state.sideBannerNewsLetter.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <hr />
                            <h5>
                                <Translation
                                    id="enter_the_title_and_description_to_appear_inside_the_banner_optional"
                                    defaultMessage="Enter the title and description to appear inside the banner (optional)"
                                />
                            </h5>
                        </div>
                        <div className="col-6">
                            <div className="form-group set_commercial_name">
                                <label htmlFor="banner_title">
                                    <Translation id="banner_title" defaultMessage="Banner title" />
                                </label>
                                <Button className="promotion-button d-inline-block" onClick={() => {
                                    this.showI18nModal('banner_title')
                                    this.handleBannerTitleUnsavedChanges()
                                }}>
                                    <Translation id="promotion_edit_view_title_name" defaultMessage="Edit/View title name" />
                                </Button>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group set_description">
                                <label htmlFor="banner_description">
                                    <Translation id="banner_description" defaultMessage="Banner description" />
                                </label>
                                <Button className="promotion-button d-inline-block" onClick={() => {
                                    this.showI18nModal('banner_description')
                                    this.handleBannerDescriptionUnsavedChanges()
                                }}>
                                    <Translation id="promotion_edit_view_description" defaultMessage="Edit/View description" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
                {this.state.showModal && (
                    <BannerPickerContainer
                        bannerSizes={this.bannerSizes}
                        selectedId={this.state.currentBannerSelectedId}
                        setBanner={banner => this.setCurrentBannerEdit(banner)}
                        onClose={() => this.setState({ showModal: false })}
                        currentBannerEdit={this.state.currentBannerEdit}
                    />
                )}
                {this.state.showModalI18n && (
                    <I18nPromotionModal
                        bannerPreview={this.state.bannerHome}
                        allTranslations={this.props.translations}
                        translations={this.state.objectTrans}
                        languages={this.props.languages}
                        title={this.props.intl.formatMessage({ id: this.state.objectToTranslate })}
                        type={this.state.objectToTranslate}
                        onCloseModal={() =>
                            this.setState({
                                showModalI18n: false,
                                objectToTranslate: null,
                                objectTrans: null
                            }, () => {
                                this.handleBannerTitleUnsavedChanges()
                                this.handleBannerDescriptionUnsavedChanges()
                            })
                        }
                        onSaveForm={(object, updatedTranslations) => this.updateTranslations(object, updatedTranslations)}
                    />
                )}
            </div>
        )
    }
}

export default ChooseBanner = withRouter(injectIntl(ChooseBanner))

const SelectOrUpload = ({ title, isNewBanner, banner, openModal, setNewBanner, clear, requirements, image }) => {
    return (
        <div className="col-12 col-md-6 px-5 d-flex flex-column">
            <h4>{title}</h4>
            <div className="flex-grow-1" style={{ margin: '5px 0px' }}>
                <ul>
                    <li>{requirements}</li>
                    <li>
                        <small>
                            <Translation id="10MB_limit" defaultMessage="Max size is 10 mb" />
                        </small>
                    </li>
                    {(image === 'banner-landing' || image === 'banner-newsletter') && (
                        <li>
                            <small>
                                <Translation id="required_image" defaultMessage="Required image" />
                            </small>
                        </li>
                    )}
                </ul>
            </div>
            <div>
                <div className="btn-group btn-group-toggle">
                    <label className={`btn btn-secondary ${!isNewBanner && banner && !!banner.id ? 'active' : ''}`} onClick={openModal}>
                        <Translation id="select" defaultMessage="Select" />
                    </label>
                    <label className={`btn btn-secondary ${isNewBanner ? 'active' : ''}`} onClick={setNewBanner}>
                        <Translation id="upload_new" defaultMessage="Upload New" />
                    </label>
                    {clear && (
                        <label className={`btn btn-secondary`} onClick={clear}>
                            <Translation id="clear" defaultMessage="Clear" />
                        </label>
                    )}
                </div>
            </div>
        </div>
    )
}
