import React from 'react'

const BannerImage = ({ landingData }) => (
    <div className="row">
        <div className="col-12">
            <div className="mall__banner">
                {landingData.banner_desktop_image_uri && <img src={`${landingData.banner_desktop_image_uri}`} alt="" className="mall__banner__image mall__banner__image--desktop" />}
                {landingData.banner_mobile_image_uri && <img src={`${landingData.banner_mobile_image_uri}`} alt="" className="mall__banner__image mall__banner__image--mobile" />}
            </div>
        </div>
    </div>
);

export default BannerImage;

