import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import { connect } from 'react-redux'

import logo from '../../assets/img/e.cosentino.svg'
import { getIsLogging, showRedirectJTModal, getIsLogged, getSapId } from '../../store/login/reducers'
import { login, logout } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'
import Button from '../global/Button'
import Spinner from '../global/Spinner'
import { push } from 'react-router-redux'
import Alerts from '../alerts/Alerts'
import withRouter from 'react-router-dom/withRouter'
import axios from 'axios'
import 'url-search-params-polyfill'
import { dispatchPushURL } from '../../store/ui/actions'

const mapStateToProps = state => {
    return {
        show: showRedirectJTModal(state),
        isLogging: getIsLogging(state),
        isLogged: getIsLogged(state),
        sapId: getSapId(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (username, password, callback, callbackCenter, intl, JTData, callbackSalesOrg) => {
            dispatch(login(username, password, callback, callbackCenter, intl, JTData, callbackSalesOrg))
        },
        onClose: () => {
            dispatch(ui.closeLoginModal())
        },
        onShowUpRedirect: () => {
            dispatch(ui.showRedirectJTModal())
        },
        logout: () => {
            return dispatch(logout())
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        }
    }
}
class RedirectJT extends React.Component {
    state = { error: null }

    componentDidMount() {
        this.props.onShowUpRedirect()
        let params = new URLSearchParams(this.props.location.search)
        this.checkJobTracker(params.get('SessionToken'))
    }
    handleSubmitJT = JTData => {
        let JTDataJSON = {
            sapId: JTData.location.SapID,
            email: 'ecommerce@cosentino.com',
            type: 'JT'
        }
        if (this.props.isLogged)
            this.props.logout().then(() => {
                this.props.onLogin(JTData.location.SapID, null, this.props.callback, this.props.callbackCenter, this.props.intl, JTDataJSON, this.props.callbackSalesOrg)
            })
        else this.props.onLogin(JTData.location.SapID, null, this.props.callback, this.props.callbackCenter, this.props.intl, JTDataJSON, this.props.callbackSalesOrg)
    }
    // trickJt = () => {
    //     let fakeSapId = '705176'
    //     let JTDataJSON = {
    //         sapId: fakeSapId,
    //         email: 'ecommerce@cosentino.com',
    //         type: 'JT'
    //     }
    //     if (this.props.isLogged)
    //         this.props.logout().then(() => {
    //             this.props.onLogin(fakeSapId, null, this.props.callback, this.props.callbackCenter, this.props.intl, JTDataJSON)
    //         })
    //     else this.props.onLogin(fakeSapId, null, this.props.callback, this.props.callbackCenter, this.props.intl, JTDataJSON)
    // }
    checkJobTracker = sessionToken => {
        let data = { SessionToken: sessionToken }
        // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
        axios.defaults.headers.common['Content-Type'] = 'application/json'

        axios
            .post(process.env.REACT_APP_JOBTRACKER_API + '/v1/validatesession/?appid=' + process.env.REACT_APP_JOBTRACKER_APIKEY, data, {
                auth: {
                    username: 'eCosentinoAPI',
                    password: process.env.REACT_APP_JOBTRACKER_PASSWORD
                }
            })
            .then(
                response => {
                    if (response.data.error) {
                        this.setState({ error: response.data.error.code })
                    } else {
                        if (response.data.location.SapID !== this.props.sapId) {
                            this.handleSubmitJT(response.data)
                        } else this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_HOME' }))
                    }
                },
                error => {
                    // this.setState({ error: error.error.code })
                }
            )
    }
    render() {
        const { show, isLogging } = this.props
        if (this.props.isLogged && this.props.sapId) this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_HOME' }))
        let params = new URLSearchParams(this.props.location.search)
        return (
            <div>
                <div
                    className="full-overlay"
                    style={{ display: show ? 'block' : 'none' }}
                    onClick={() => this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_HOME' }))}
                />

                <div id="modal-login-container" className="modal-login JT" style={{ display: show ? 'block' : 'none' }}>
                    {isLogging && <Spinner isLoading={isLogging} />}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 logo-container">
                                <header className="container-fluid">
                                    <div className="row">
                                        <div className="col-3 offset-9 my-auto text-right">
                                            <div className="bt-close" onClick={() => this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_HOME' }))}>
                                                <i className="fal fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12 col-lg-6 offset-lg-3 text-center ">
                                            <img alt="" className="profile-img-card" src={logo} />

                                            {this.state.error && <Alerts alert={this.state.error} status="404" />}
                                            <Button inverted onClick={() => this.checkJobTracker(params.get('SessionToken'))}>
                                                <Translation id="continue_with_my_jobtracker_account" defaultMessage="Continue with my JobTracker account" />
                                            </Button>
                                            {/* <Button inverted onClick={() => this.trickJt()}>
                                                trick jt
                                            </Button> */}
                                            
                                        </div>
                                    </div>
                                </header>
                                <footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(RedirectJT)
    )
)
