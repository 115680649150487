import React from 'react'
import { injectIntl } from 'react-intl'
import ProductLandingTwo from './ProductLandingTwo'

class LandingOne extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const bannerSrc = this.props.headLandingBannerData.url ? this.props.headLandingBannerData.url : this.props.srcBanner

        return (
            <div className="container my-4">
                <section id="section-landing-promotions" className="v2">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <h1 style={{ fontFamily: 'diagramm' }} className="title" dangerouslySetInnerHTML={{ __html: this.props.promotionTitle }} />
                                <div dangerouslySetInnerHTML={{ __html: this.props.promotionDescription }} />
                            </div>
                            <div style={{ position: 'relative', marginBottom: '20px' }}>
                                <div className="promotion-home-banner">
                                    <div className="promotion-home-banner-text" style={{backgroundImage: 'url(' + bannerSrc + ')'}}>
                                        <div dangerouslySetInnerHTML={{ __html: this.props.promotionBannerTitle }} />
                                        <div dangerouslySetInnerHTML={{ __html: this.props.promotionBannerDescription }} />
                                    </div>
                                    <div className="promotion-home-banner-image">
                                        <img src={bannerSrc} alt="Promo Banner" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="products-promotion-landing-two">
                        {this.props.products && this.props.products.map((item, key) => (
                            <React.Fragment>
                                <ProductLandingTwo
                                    key={key + item.product.productId}
                                    product={item.product}
                                    promotionId={this.props.promotionId}
                                    isSellingOutlet={item.isSellingOutlet} />
                            </React.Fragment>
                        ))}
                    </div>
                </section>
            </div>
        )
    }
}

export default injectIntl(LandingOne)
