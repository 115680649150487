import React from 'react'

import HeaderContainer from '../header/HeaderContainer'
import GlobalModals from './GlobalModals'
import { connect } from 'react-redux'
import * as ui from '../../store/ui/reducers'
import { getSelectedCenter, isAustralianCenter, getSalesOrg } from '../../store/centers/reducers'
import Raven from 'raven-js'
import { fetchMaintenance } from '../../store/ui/actions'
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { removeToastNotification } from '../../store/toast-notifications/actions'
import Spinner from '../global/Spinner'
import { withRouter } from 'react-router-dom'
import { getIsOrderHistoryScreen, getIsShippingHistoryScreen, getLanguage } from '../../store/ui/reducers'
import FooterV2 from '../footer/FooterV2'
import TinyFooter from '../footer/TinyFooter'

const mapStateToProps = state => {
    return {
        showingAnyModal: ui.showingAnyModal(state),
        selectedCenter: getSelectedCenter(state),
        maintenanceScheduledText: ui.getMaintenanceScheduledText(state),
        toastNotifications: state.toastNotifications,
        loader: state.loader,
        isOrderHistoryScreen: getIsOrderHistoryScreen(state),
        isShippingHistoryScreen: getIsShippingHistoryScreen(state),
        isConfigurableBathEditScreen: ui.getIsConfigurableBathEditScreen(state),
        isConfigurablePlanAcceptanceScreen: ui.getIsConfigurablePlanAcceptanceScreen(state),
        isAustralianCenter: isAustralianCenter(state),
        language: getLanguage(state),
        salesOrg: getSalesOrg(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchMaintenance: () => {
            dispatch(fetchMaintenance())
        },
        removeToastNotification: id => dispatch(removeToastNotification(id))
    }
}
class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null, showMaintenanceText: null }
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        if (process.env.NODE_ENV === 'production') {
            Raven.captureException(error, { extra: errorInfo })
        }

        // You can also log error messages to an error reporting service here
    }

    componentDidMount() {
        this.props.fetchMaintenance()
    }

    render() {
        if (this.props.showingAnyModal) {
            document.body.classList.add('noscroll')
        } else {
            document.body.classList.remove('noscroll')
        }
        if (this.state.errorInfo) {
            return (
                <div>
                    <HeaderContainer />
                    <div className="container" style={{ minHeight: 'calc(100vh - 323px)' }}>
                        <div className="row">
                            <div className="col">
                                <h2>Something went wrong</h2>
                                <p onClick={() => Raven.lastEventId() && Raven.showReportDialog()}>Our team has been notified, but click here fill out a report.</p>
                                {process.env.NODE_ENV !== 'production' && (
                                    <details style={{ whiteSpace: 'pre-wrap' }}>
                                        {this.state.error && this.state.error.toString()}
                                        <br />
                                        {this.state.errorInfo.componentStack}
                                    </details>
                                )}
                            </div>
                        </div>
                    </div>

                    <FooterV2
                        selectedCenter={this.props.selectedCenter}
                        isOrderHistoryScreen={this.props.isOrderHistoryScreen}
                        isShippingHistoryScreen={this.props.isShippingHistoryScreen}
                        isAustralianCenter={this.props.isAustralianCenter}
                        language={this.props.language}
                        salesOrg={this.props.salesOrg}
                    />
                    <GlobalModals />
                </div>
            )
        }
        return (
            <div id="wrapper-full" className={'v2'}>
                <div id="wrapper-header">
                    <HeaderContainer />
                </div>
                <div id="wrapper-contain">{this.props.children}</div>
                {this.props.toastNotifications.length > 0 && (
                    <NotificationGroup className={'top right'}>
                        {this.props.toastNotifications.map((t, i) => (
                            <Notification
                                key={i}
                                closable={true}
                                type={{
                                    style: t.type,
                                    icon: true
                                }}
                                onClose={() => this.props.removeToastNotification(t.id)}
                            >
                                {t.text}
                            </Notification>
                        ))}
                    </NotificationGroup>
                )}

                {this.props.loader.length > 0 && (
                    <div id="global-loader" className="global-loader-wrapper">
                        <Spinner relative={true} isLoading={true} />
                    </div>
                )}

                
                {this.props.isConfigurableBathEditScreen || this.props.isConfigurablePlanAcceptanceScreen ? (
                    <TinyFooter selectedCenter={this.props.selectedCenter} salesOrg={this.props.salesOrg}  language={this.props.language} isAustralianCenter={this.props.isAustralianCenter}/>
                ) : (
                    <div id="wrapper-footer">
                        <FooterV2
                            selectedCenter={this.props.selectedCenter}
                            isOrderHistoryScreen={this.props.isOrderHistoryScreen}
                            isShippingHistoryScreen={this.props.isShippingHistoryScreen}
                            isAustralianCenter={this.props.isAustralianCenter}
                            language={this.props.language}
                            salesOrg={this.props.salesOrg}
                        />
                    </div>
                )}
              
                <GlobalModals />
            </div>
        )
    }
}

Layout = withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))

export default Layout
