import { getCenterData, getSelectedCenter } from '../centers/reducers'

export const initState = {
    shippingAddresses: null,
    isLoading: false,
    fetchedOn: null,
    ikeaSelectedShippingAddress: null,
    endiSelectedShippingAddressEcosentinoKey: null,
    endiSelectedShippingType: null,
    remembered: false
}
const shippingAddresses = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_SHIPPING_ADDRESSES_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_SHIPPING_ADDRESSES_SUCCESS':
            return Object.assign({}, state, { shippingAddresses: action.data, isLoading: false, fetchedOn: action.fetchedOn })
        case 'FETCH_SHIPPING_ADDRESSES_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'CHANGE_IKEA_SHIPPING_ADDRESS':
            return Object.assign({}, state, { ikeaSelectedShippingAddress: action.shippingAddressId })
        case 'CHANGE_ENDI_SHIPPING_ADDRESS':
            return Object.assign({}, state, {
                endiSelectedShippingAddressEcosentinoKey: action.shippingAddressEcosentinoKey,
                endiSelectedShippingType: action.endiSelectedShippingType,
                remembered: true
            })
        case 'REMEMBER_SELECT_SHIPPING_ADDRESS_ENDI':
            return Object.assign({}, state, { endiSelectedShippingAddressEcosentinoKey: null, endiSelectedShippingType: null, remembered: false })
        case 'SIMPLE_IMPERSONATE_SUCCESS':
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export const getShippingAddresses = state => {
    if (!state.shippingAddresses.shippingAddresses) return null
    return state.shippingAddresses.shippingAddresses.shippingAddresses
}
export const getIsShippingAddressRemembered = state => state.shippingAddresses.remembered

export const getEndiSelectedShippingAddressId = state => state.shippingAddresses.endiSelectedShippingAddressEcosentinoKey

export const getEndiSelectedShippingAddress = state => {
    return getShippingAddresses(state).find(shippingAddress => {
        return shippingAddress.ecosentinoKey === state.shippingAddresses.endiSelectedShippingAddressEcosentinoKey
    })
}

export const getEndiShippingTypeByShippingAddress = state => state.shippingAddresses.endiSelectedShippingType

export const getShippingAddressFilteredToSelectByCenter = state => {
    if (state.shippingAddresses.shippingAddresses && getCenterData(state)) {
        let shippingTypes = getCenterData(state).shippingTypes
        const shippingAddressAllowed = state.shippingAddresses.shippingAddresses.shippingAddresses.filter(shippingAddress => {
            let isAllowed = false
            shippingTypes.forEach(shippingType => {
                if (
                    (shippingAddress.allowedShippingTypes.includes(shippingType) && shippingAddress.centerId == getSelectedCenter(state)) ||
                    (shippingAddress.allowedShippingTypes.includes(shippingType) && shippingAddress.salesOrg == 1100)
                )
                    isAllowed = true
            })
            return isAllowed
        })
        return shippingAddressAllowed.map(shippingAddress => ({
            value: shippingAddress.ecosentinoKey,
            label:
                shippingAddress.centerName +
                ' ' +
                shippingAddress.customerName +
                ' ' +
                shippingAddress.address +
                ' ' +
                shippingAddress.zipCode +
                ' ' +
                shippingAddress.city +
                ' ' +
                shippingAddress.country
        }))
    } else return []
}
export const getShippingAddressesCenters = state => {
    if (!state.shippingAddresses.shippingAddresses) return null
    return state.shippingAddresses.shippingAddresses.shippingCenters
}
export const getCostCenters = state => {
    if (!state.shippingAddresses.shippingAddresses) return null
    return state.shippingAddresses.shippingAddresses.costCenters
}
export const isLoadingShippingAddress = state => {
    return state.shippingAddresses.isLoading
}
export const getFetchedOn = state => {
    return state.shippingAddresses.fetchedOn
}
export const getSelectedShippingAddressForIkea = state => {
    return state.shippingAddresses.ikeaSelectedShippingAddress
}

export const getFirstShippingAddres = state => {
    if (!state.shippingAddresses.shippingAddresses) return null
    return state.shippingAddresses.shippingAddresses.shippingAddresses[0]
}

export default shippingAddresses
