import React from 'react'

// import WallSamplesLowes from '../components/catalog-lowes/WallSamplesLowes'
import { connect } from 'react-redux'
import { getUserType } from '../store/login/reducers'
import { injectIntl } from 'react-intl'
import { Route, Switch } from 'react-router-dom'
import CatalogScreen from './../screens/CatalogScreen'
import LowesViewSelector from '../components/catalog-lowes/LowesViewSelector'
import { getLocalizedUrl } from '../store/ui/reducers'

const mapStateToProps = (state, props) => {
    return {
        userType: getUserType(state),
        localizedUrl: (routeId) => getLocalizedUrl(state, props.intl, routeId)
    }
}

class CatalogLowesScreen extends React.Component {
    render() {
        return (
            <Switch>
                <Route path={this.props.localizedUrl('ROUTE_CATALOG_LOWES').replace(':filter', 'home')} component={LowesViewSelector} />
                {/* <Route path={this.props.localizedUrl('ROUTE_CATALOG_LOWES').replace(':filter', 'wall-samples')} component={WallSamplesLowes} /> */}
                <Route path={this.props.localizedUrl('ROUTE_CATALOG_LOWES').replace(':filter', 'customer-samples')} component={CatalogScreen} />
                <Route path={this.props.localizedUrl('ROUTE_CATALOG_LOWES').replace(':filter', 'brochures')} component={CatalogScreen} />
            </Switch>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(CatalogLowesScreen)
)
