import React, { useState } from 'react'
import SimpleModal from '../../global/SimpleModal'
import RectangleResultContainer from "../../catalog/rectangleResult/RectangleResultContainer";
import ProductContext, {MallProductContext} from "../../catalog/rectangleResult/contexts/ProductContext";

const ProductItem = ({
    product,
    onZoomClick,
    sapLandingId
}) => {
    return (

        <React.Fragment>
            <ProductContext.Provider value={MallProductContext}>
                <RectangleResultContainer
                    product={product}
                    key={product.productId}
                    list={'MALL'}
                    showImg={true}
                    onZoomClick={onZoomClick}
                    sapLandingId={sapLandingId}
                />
            </ProductContext.Provider>
        </React.Fragment>
    )
}

export default ProductItem
