import { connect } from 'react-redux'
import Suggestion from './Suggestion'
import * as action from '../../store/suggestion/actions'
import * as reducer from '../../store/suggestion/reducers'
import { injectIntl } from 'react-intl'
import { getUserName, getUserType, getImpersonatedBy } from '../../store/login/reducers'
import { getSelectedCenter } from '../../store/centers/reducers'

const mapStateToProps = state => {
    return {
        isSending: reducer.getIsSending(state),
        alert: reducer.getAlert(state),
        status: reducer.getStatus(state),
        userName: getUserName(state),
        userType: getUserType(state),
        impersonatedBy: getImpersonatedBy(state),
        centerId: getSelectedCenter(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendSuggestion: (name, email, subject, tipology, suggestion) => {
            let data = { name, email, subject, tipology, message: suggestion }
            return dispatch(action.sendSuggestion(data))
        },
        deleteAlert: () => {
            return dispatch({ type: 'DEL_ALERT_SUGGESTION' })
        }
    }
}

const SuggestionContainer = injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Suggestion)
)

export default SuggestionContainer
