import React from 'react'
import Translation from '../global/Translation'

function ProductInfoTabs() {
    return (
        <React.Fragment>
            {/* Headings */}
            <div className="product-info-tabs__container d-none">
                <div className="product-info-tabs__heading">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">
                                <Translation id="description" defaultMessage="Description" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="specification-tab" data-toggle="tab" href="#specification" role="tab" aria-controls="specification" aria-selected="false">
                                <Translation id="product_tab_specification" defaultMessage="Specifications" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="formats-tab" data-toggle="tab" href="#formats" role="tab" aria-controls="formats" aria-selected="false">
                                <Translation id="product_tab_formats" defaultMessage="Formats" />
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" id="galleries-tab" data-toggle="tab" href="#galleries" role="tab" aria-controls="galleries" aria-selected="false">
                                <Translation id="product_tab_galleries" defaultMessage="Galleries" />
                            </a>
                        </li>
                    </ul>
                        
                    <div>
                        <button
                            className="open-close-info"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTab"
                            aria-expanded="false"
                            aria-controls="collapseTab"
                        >
                            <i class="fas fa-angle-down rotate-icon"></i>
                        </button>
                    </div>
                </div>
                <div className="product-info-tabs__content collapse show" id="collapseTab">
                    {/* Content */}
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                            <h5 className="product-info-tabs__title">¿Qúe es Scalea?</h5>
                            <p className="product-info-tabs__text">
                                Scalea® by Cosentino es la marca que engloba toda la oferta de piedra natural del Grupo Cosentino, ofreciendo una opción única e irrepetible que une
                                diseños modernos y tradicionales.
                            </p>
                            <div className="product-info-tabs__box row">
                                <div className="product-info-tabs__cards col">
                                    <div className="product-info-tabs__img"><i class="far fa-sun"></i></div>
                                    <div className="product-info-tabs__img-text">Resistente al fuego y al calor</div>
                                </div>
                                <div className="product-info-tabs__cards col">
                                    <div className="product-info-tabs__img"><i class="far fa-sun"></i></div>
                                    <div className="product-info-tabs__img-text">Resistencia a los rayos UV</div>
                                </div>
                                <div className="product-info-tabs__cards col">
                                    <div className="product-info-tabs__img"><i class="far fa-sun"></i></div>
                                    <div className="product-info-tabs__img-text">Durabilidad superior</div>
                                </div>
                                <div className="product-info-tabs__cards col">
                                    <div className="product-info-tabs__img"><i class="far fa-sun"></i></div>
                                    <div className="product-info-tabs__img-text">Estabilidad del color a lo largo del tiempo</div>
                                </div>
                                <div className="product-info-tabs__cards col">
                                    <div className="product-info-tabs__img"><i class="far fa-sun"></i></div>
                                    <div className="product-info-tabs__img-text">Estetica y belleza natural</div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="specification" role="tabpanel" aria-labelledby="specification-tab">
                            <h5 className="product-info-tabs__title">Las especificaciones</h5>
                            <p className="product-info-tabs__text">
                                Scalea® by Cosentino es la marca que engloba toda la oferta de piedra natural del Grupo Cosentino, ofreciendo una opción única e irrepetible que une
                                diseños modernos y tradicionales.
                            </p>
                        </div>
                        <div className="tab-pane fade" id="formats" role="tabpanel" aria-labelledby="formats-tab">
                            <h5 className="product-info-tabs__title">Los formatos</h5>
                            <p className="product-info-tabs__text">
                                Scalea® by Cosentino es la marca que engloba toda la oferta de piedra natural del Grupo Cosentino, ofreciendo una opción única e irrepetible que une
                                diseños modernos y tradicionales.
                            </p>
                        </div>
                        <div className="tab-pane fade" id="galleries" role="tabpanel" aria-labelledby="galleries-tab">
                            <h5 className="product-info-tabs__title">La galería</h5>
                            <p className="product-info-tabs__text">
                                Scalea® by Cosentino es la marca que engloba toda la oferta de piedra natural del Grupo Cosentino, ofreciendo una opción única e irrepetible que une
                                diseños modernos y tradicionales.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProductInfoTabs
