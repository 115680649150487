import React from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import Button from '../../global/Button'
import Select from 'react-select'
import Modal from '../../global/Modal'

class ChooseTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedLandingTemplate: this.props.landingTemplateId && this.getOptionsLandingTemplate().filter(optionLanding => optionLanding.value === this.props.landingTemplateId)[0],
            selectedNewsLetterTemplate: this.props.mailTemplateId && this.getOptionsMailTemplate().filter(optionMail => optionMail.value === this.props.mailTemplateId)[0],
            //Unsaved changes
            hasUnsavedChanges: {
                promotionLandingTemplateDirty: false,
                promotionNewsletterTemplateDirty: false
            }
        }
    }

    componentDidMount(){
        this.props.handleReference(this)
    }

    getOptionsLandingTemplate() {
        return this.props.landingTemplates.map(item => ({
            label: item.name,
            value: item.id,
            urlPreview: item.urlPreview
        }))
    }
    getOptionsMailTemplate() {
        return this.props.newsLetterTemplates.map(item => ({
            label: item.name,
            value: item.id,
            urlPreview: item.urlPreview
        }))
    }
    stepBack() {
        this.props.stepBack(this.props.step - 1)
    }

    handleSubmit = e => {
        if(e) e.preventDefault()
        //No more unsaved changes after send the form for step one
        this.setState({ hasUnsavedChanges: {
            ...this.state.hasUnsavedChanges,
            promotionLandingTemplateDirty: false,
            promotionNewsletterTemplateDirty: false
        }}, () => {
            this.props.sendPromotionTemplateSelected(this.state.selectedLandingTemplate.value, this.state.selectedNewsLetterTemplate.value)
            //Update global state after form submit
            this.props.setUnsavedChangesStepSix(this.state.hasUnsavedChanges)
            //Make sure the change step is the last function
            
            //Only if event exists we use this change step. If not we use the one inside StepsBar component
            if (e) {
                this.props.changeStep(this.props.step + 1)
            }
        })
    }

    /**
     * Handle the state value landing template unsaved changes.
     * @param {object} selectedOption 
     */
    handleLandingTemplateUnsavedChanges = (selectedOption) => {
        this.setState({ selectedLandingTemplate: selectedOption }, () => {

            const propsLandingTemplateId = this.props.landingTemplateId && parseInt(this.props.landingTemplateId)
            const stateSelectedLandingTemplate = this.state.selectedLandingTemplate && parseInt(this.state.selectedLandingTemplate.value)
    
            if (propsLandingTemplateId !== stateSelectedLandingTemplate) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionLandingTemplateDirty: true} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepSix(this.state.hasUnsavedChanges)
                })
            } else if (propsLandingTemplateId === stateSelectedLandingTemplate) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionLandingTemplateDirty: false} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepSix(this.state.hasUnsavedChanges)
                })
            }
        })
    }

    /**
     * Handle the state value mail template unsaved changes.
     * @param {object} selectedOption 
     */
    handleMailTemplateUnsavedChanges = (selectedOption) => {
        this.setState({ selectedNewsLetterTemplate: selectedOption }, () => {

            const propsMailTemplateId = this.props.mailTemplateId && parseInt(this.props.mailTemplateId)
            const stateSelectedNewsLetterTemplate = this.state.selectedNewsLetterTemplate && parseInt(this.state.selectedNewsLetterTemplate.value)

            if (propsMailTemplateId !== stateSelectedNewsLetterTemplate) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionNewsletterTemplateDirty: true} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepSix(this.state.hasUnsavedChanges)
                })
            } else if (propsMailTemplateId === stateSelectedNewsLetterTemplate) {
                this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionNewsletterTemplateDirty: false} }, () => {
                    //Update global state
                    this.props.setUnsavedChangesStepSix(this.state.hasUnsavedChanges)
                })
            }
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-lg-10">
                        <h3 style={{ fontWeight: '400' }}>
                            <span style={{ fontWeight: '300' }}>
                                <Translation id="step" defaultMessage={'Step'} /> {this.props.step}
                            </span>{' '}
                            <Translation id="promotion_line_templates" defaultMessage="Templates" />
                        </h3>
                        <p>
                            <Translation
                                id="select_the_template_of_landing_and_mailing_to_apply_your_campaign"
                                defaultMessage="Select the template of landing and mailing to apply your campaign"
                            />
                        </p>
                    </div>
                </div>
                <form id="form_current_step" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="input_name">
                                    <Translation id="template_to_use_in_landing" defaultMessage="Template to use in landing" />
                                </label>
                                <div className="form-group form-inline">
                                    <Select
                                        className="flex-grow-1 border-bottom border-secondary"
                                        name="landing_template"
                                        id="select_landing_template"
                                        options={this.getOptionsLandingTemplate()}
                                        value={this.state.selectedLandingTemplate}
                                        clearable={false}
                                        onChange={selectedOption => {
                                            this.handleLandingTemplateUnsavedChanges(selectedOption)
                                        }}
                                        placeholder={this.props.intl.formatMessage({ id: 'select' }) + '...'}
                                        required
                                    />
                                    <Button
                                        className="mx-2"
                                        disabled={!this.state.selectedLandingTemplate}
                                        onClick={() => this.setState({ urlPreview: this.state.selectedLandingTemplate.urlPreview })}
                                    >
                                        <Translation id="preview" defaultMessage="Preview" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="input_name">
                                    <Translation id="template_to_use_in_email" defaultMessage="Template to use in news letter" />
                                </label>
                                <div className="form-group form-inline">
                                    <Select
                                        className="flex-grow-1 border-bottom border-secondary"
                                        name="news_letter_template"
                                        id="select_news_letter_template"
                                        options={this.getOptionsMailTemplate()}
                                        value={this.state.selectedNewsLetterTemplate}
                                        clearable={false}
                                        onChange={selectedOption => {
                                            this.handleMailTemplateUnsavedChanges(selectedOption)
                                        }}
                                        placeholder={this.props.intl.formatMessage({ id: 'select' }) + '...'}
                                        required
                                    />
                                    <Button
                                        className="mx-2"
                                        disabled={!this.state.selectedNewsLetterTemplate}
                                        onClick={() => this.setState({ urlPreview: this.state.selectedNewsLetterTemplate.urlPreview })}
                                    >
                                        <Translation id="preview" defaultMessage="Preview" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {this.state.urlPreview && (
                    <Modal
                        title={this.props.intl.formatMessage({ id: 'template_preview', defaultMessage: 'Template preview' })}
                        onClose={() => this.setState({ urlPreview: null })}
                    >
                        <img style={{ maxWidth: '100%', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }} src={this.state.urlPreview} alt={this.props.intl.formatMessage({ id: 'preview' })} />
                    </Modal>
                )}
            </div>
        )
    }
}

export default ChooseTemplate = withRouter(injectIntl(ChooseTemplate))