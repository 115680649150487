import React from 'react'

import Select from 'react-select'
import { injectIntl } from 'react-intl'

const ShippingMethods = ({ shippingMethods, intl, onChangeShippingMethod, selectedShippingMethod, selectedEndiShippingMethod, v2 }) => {
    let input = ''
    switch (shippingMethods.length) {
        case 0:
            input = !v2 ? <input type="text" readOnly /> : null
            break
        case 1:
            input = !v2 ? intl.formatMessage({ id: selectedShippingMethod }) : null
            break
        default:
            if (selectedEndiShippingMethod) {
                input = !v2 ? intl.formatMessage({ id: selectedShippingMethod }) : null
            } else {
                if (selectedShippingMethod === 'FULL_TRUCK' && !shippingMethods.includes('FULL_TRUCK')) shippingMethods.push('FULL_TRUCK')
                let options = shippingMethods.map(shippingMethod => {
                    return { value: shippingMethod, label: intl.formatMessage({ id: shippingMethod }) }
                })
                input = (
                    <Select
                        className="select__shipping_method"
                        onChange={selected => onChangeShippingMethod(selected.value)}
                        value={selectedShippingMethod}
                        options={options}
                        clearable={false}
                        searchable={false}
                        optionClassName={'select__shipping_method--option'}
                    />
                )
            }
    }
    return input
}

export default injectIntl(ShippingMethods)
