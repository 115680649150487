import React from "react";
import Translation from "../../global/Translation";
import Modal from "../../global/Modal";
import {
    getAcceptedDiscounts,
    getDataToResolveDiscounts,
    getIsLoading
} from "../../../store/promotions/reducers";
import {fetchPromotionsList, getDataToResolveDiscount} from "../../../store/promotions/actions";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import Spinner from "../../global/Spinner";
import CustomersTable from "./CustomersTable";
import MaterialsTable from "./MaterialsTable";
import ApproveDiscount from "./ApproveDiscount";
import DenyDiscount from "./DenyDiscount";
import Button from "../../global/Button";
import FloatingAlert from "../../alerts/FloatingAlert";
import CampaignInfo from "./CampaignInfo";

class ApplyDiscountsModal extends React.Component {

    state = {
        showApprove: true,
        showDeny: false,
        showCustomers: false,
        showMaterials: false,
        showResponse: false,
        showAlert: false,
        showCampaignInfo: false
    }

    componentDidMount() {
        const centers = this.props.campaign.centersData.map(c => {return {
            id: c.id, name: c.name, salesOrg: c.salesOrg
        }})

        this.props.getDataToResolveDiscount(this.props.campaign.id, centers)
    }

    showResponseDeniedModal = () => {
        this.setState({showResponse: 'denied'})
        this.props.fetchPromotionsList()
    }

    showResponseApprovedModal = () => {
        this.setState({showResponse: 'approved'})
        this.props.fetchPromotionsList()
    }

    showAlert = () => {
        this.setState({showAlert: true})
    }

    render() {
        const {campaign, onClose, dataToResolveDiscount, isLoading, intl} = this.props
        const {showMaterials, showCustomers, showApprove, showDeny, showResponse, showCampaignInfo} = this.state

        return (
            <Modal title={<React.Fragment><Translation id="campaign" defaultMessage="Campaign"/><span>{`: ${campaign.name}`}</span></React.Fragment>}
                   showFooter={false}
                   onClose={onClose}
                   size="xl"
            >
                <Spinner isLoading={isLoading} />
                
                <section className="resolve-discount p-0">
                {
                    !showResponse && (
                        <React.Fragment>
                            <div className="d-flex mb-3" style={{columnGap: '16px'}}>
                                <div className={`p-0 selector ${showApprove ? 'selected' : 'not-selected'}`}
                                     onClick={() => this.setState({showMaterials:false, showCustomers:false, showApprove: true, showDeny: false, showCampaignInfo: false})}
                                >
                                    <Translation id="approve" defaultMessage="Approve" />
                                </div>
                                <div className={`p-0 selector ${showDeny ? 'selected' : 'not-selected'}`}
                                     onClick={() => this.setState({showMaterials:false, showCustomers:false, showApprove: false, showDeny: true, showCampaignInfo: false})}
                                >
                                    <Translation id="deny" defaultMessage="Deny" />
                                </div>
                                <div className={`p-0 selector ${showMaterials ? 'selected' : 'not-selected'}`}
                                     onClick={() => this.setState({showMaterials:true, showCustomers:false, showApprove: false, showDeny: false, showCampaignInfo: false})}
                                >
                                    <Translation id="materials" defaultMessage="Materials"/>
                                </div>
                                <div className={`p-0 selector ${showCustomers ? 'selected' : 'not-selected'}`}
                                     onClick={() => this.setState({showMaterials:false, showCustomers:true, showApprove: false, showDeny: false, showCampaignInfo: false})}
                                >
                                    <Translation id="customers" defaultMessage="Customers" />
                                </div>
                                <div className={`p-0 selector ${showCampaignInfo ? 'selected' : 'not-selected'}`}
                                     onClick={() => this.setState({showMaterials:false, showCustomers:false, showApprove: false, showDeny: false, showCampaignInfo: true})}
                                >
                                    <Translation id="campaign_info" defaultMessage="Campaign info" />
                                </div>
                            </div>

                            <div className="row" style={{height:'60vh'}}>
                                <div className="col-12 h-100">
                                {/********* TABLA MATERIALES **********/}
                                {
                                    showMaterials && <MaterialsTable products={dataToResolveDiscount.products} campaign={campaign}/>
                                }
                                {/********* TABLA CLIENTES **********/}
                                {
                                    showCustomers && <CustomersTable customers={dataToResolveDiscount.customers} campaign={campaign}/>
                                }
                                {/********* APROBAR DESCUENTOS **********/}
                                {
                                    showApprove && <ApproveDiscount campaign={campaign}
                                                                    showResponseModal={this.showResponseApprovedModal}
                                                                    showAlert={this.showAlert}
                                    />
                                }
                                {/********* DENEGAR DESCUENTOS **********/}
                                {
                                    showDeny && <DenyDiscount campaign={campaign}
                                                              showResponseModal={this.showResponseDeniedModal}
                                                              showAlert={this.showAlert}
                                    />
                                }
                                {/********* INFO DE CAMPAÑA **********/}
                                {
                                    showCampaignInfo && <CampaignInfo campaign={campaign} intl={intl}/>
                                }
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
                {/********* MENSAJE RESPUESTA **********/}
                {
                    showResponse && (
                        <div style={{height:'60vh', display:'flex', flexFlow:'column', alignItems:'center', justifyContent:'space-around'}}>
                            <div style={{textAlign:'center'}}>
                                <h3>El descuento solicitado para esta campaña ha sido {showResponse === 'approved' ? 'APROBADO' : 'DENEGADO'}</h3>
                                <h5 style={{marginTop:'16px'}}>Se ha enviado un correo a los administradores de los centros</h5>
                            </div>

                            <Button onClick={onClose}>Ir al listado de campañas</Button>
                        </div>
                    )
                }
                {/********* ALERTA FALLO PETICIÓN **********/}
                {
                    this.state.showAlert && (
                        <FloatingAlert
                            warningMessage={
                                <Translation id="fail" defaultMessage="Fail"/>
                            }
                            onClose={() => {this.setState({showAlert: false})}}
                        />
                    )
                }


                </section>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: getIsLoading(state),
        dataToResolveDiscount: getDataToResolveDiscounts(state),
        acceptedDiscounts: getAcceptedDiscounts(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getDataToResolveDiscount: (promotionId, centers) => dispatch(getDataToResolveDiscount(promotionId, centers)),
        fetchPromotionsList: (page = 1, itemsPerPage = 8) => dispatch(fetchPromotionsList(page, itemsPerPage))
    }
}
export default ApplyDiscountsModal = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ApplyDiscountsModal)
    )
)

