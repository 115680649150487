import React from 'react'
import mallDiscount from '../../assets/img/logos/mall-discount.svg'
import Translation from '../global/Translation'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getGoalMin, getKgPoundRatio, weightSelector } from '../../store/cart/reducers'
import { emptyCart } from '../../store/cart/actions'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { getLanguage } from '../../store/ui/reducers'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        unit: unit,
        goalMin: getGoalMin(state),
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio())
    }
}
const mapDispatchToProps = dispatch => {
    return {
        emptyCart: () => dispatch(emptyCart()),
        toggleIncompatibleTypesExceptionModal: open => Promise.resolve(dispatch({ type: 'TOGGLE_INCOMPATIBLE_TYPES_EXCEPTION_MODAL', open: open }))
    }
}

class MallIncentives extends React.Component {
    render() {
        return (
            <div className="extra-discount-mall">
                <div>
                    <img alt="" src={mallDiscount} className="mall-discount-image" />
                    <span>
                        <Translation id="you_currently_have" defaultMessage="You currently have" />{' '}
                        <b>
                            {this.props.current} {this.props.unit.toLowerCase()}
                        </b>{' '}
                        <Translation id="in_your_cart" defaultMessage="in your cart" />
                        {'. '}
                    </span>
                    <span>
                        <Translation id="you_must_reach" defaultMessage="You must reach" />{' '}
                        <b>
                            {this.props.goalMin} {this.props.unit.toLowerCase()}
                        </b>{' '}
                        <Translation id="to_get_an_extra_discount_for_full_containers" defaultMessage="to get an extra discount for full containers" />
                    </span>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MallIncentives)
)
