import React from 'react'

import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { getUserType, getSalesOrg, getIsLogged } from '../../store/login/reducers'
import { getSelectedCenter, hasPromoActivated } from '../../store/centers/reducers'
import { dispatchPushURL } from '../../store/ui/actions'

const mapStateToProps = state => {
    return {
        userType: getUserType(state),
        center: getSelectedCenter(state),
        salesOrg: getSalesOrg(state),
        isLogged: getIsLogged(state),
        isPromoActive: hasPromoActivated(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: url => {
            dispatch(dispatchPushURL(url))
        }
    }
}
const DeveloperBar = ({ userType, center, salesOrg, isLogged, isPromoActive }) => {
    let production = process.env.REACT_APP_API_HOST === 'https://apiepro.cosentino.com' ? true : false
    return (
        <div style={{ background: production ? 'red' : 'aquamarine', color: production ? 'white' : 'black', position: 'fixed', bottom: '0', width: '100%', zIndex: '1000' }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>API: {process.env.REACT_APP_API_HOST}</span>
                        {isLogged && (
                            <React.Fragment>
                                <span>userType: {userType}</span>
                                <span>center: {center}</span>
                                <span>salesOrg: {salesOrg}</span>
                                <span>promoActive: {isPromoActive ? 'yes' : 'no'}</span>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DeveloperBar)
)
