import { connect } from 'react-redux'
import NewOrderHistory from './NewOrderHistory'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import {
    getOrders,
    getOrderStatusMap,
    getOrdersLoading,
    getOrdersCurrentPage,
    getOrdersLastPage
} from '../../../../store/orders/reducers'
import { fetchOrders, fetchOrdersPaginate } from '../../../../store/orders/actions'
import { getIsIkeaCenter } from '../../../../store/centers/reducers'
import { dispatchPushURL } from '../../../../store/ui/actions'
import { getAdaptedFormat, getReplaceDotWithComma, getCtsMeasures } from '../../../../helpers'
import { setIsOrderHistoryScreen } from '../../../../store/ui/actions'
import { getLanguage } from '../../../../store/ui/reducers'


const mapStateToProps = (state) => {
    return {
        orders: getOrders(state),
        orderStatusMap: getOrderStatusMap(state),
        isLoading: getOrdersLoading(state),
        isIkeaCenter: getIsIkeaCenter(state),
        currentPage: getOrdersCurrentPage(state),
        lastPage: getOrdersLastPage(state),
        locale: getLanguage(state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state),
        getMeasure: (cutToSize) => getCtsMeasures(cutToSize, state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrders: (type, startDate, endDate, filterTypeDate, status, shippingType, supplierCenter, search) => {
            return dispatch(fetchOrders(type, startDate, endDate, filterTypeDate, status, shippingType, supplierCenter, search))
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        fetchOrdersPaginate: (type, filterTypeDate, startDate, endDate, status, shippingType, supplierCenter, search, page) => {
            dispatch(fetchOrdersPaginate(type, filterTypeDate, startDate, endDate, status, shippingType, supplierCenter, search, page))
        },
        setIsOrderHistoryScreen: isOrderHistoryScreen => {
            dispatch(setIsOrderHistoryScreen(isOrderHistoryScreen))
        }
    }
}

const NewOrderHistoryContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(NewOrderHistory)
    )
)

export default NewOrderHistoryContainer
