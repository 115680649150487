import { connect } from 'react-redux'
import DeviatedAdmin from './DeviatedAdmin'
import { injectIntl } from 'react-intl'
import { fetchProductsDeviatedAdmin, getImageBackground, saveProductsDeviatedAdmin } from '../../store/products/actions'
import { getProductsDeviatedAdmin } from '../../store/products/reducers'
import { getAvailableLands } from '../../store/ui/reducers'

const mapStateToProps = state => {
    return {
        products: getProductsDeviatedAdmin(state),
        locales: getAvailableLands(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProductsDeviatedAdmin: () => dispatch(fetchProductsDeviatedAdmin()),
        getImageBackground: product => dispatch(getImageBackground(product)),
        saveProductsDeviatedAdmin: product => dispatch(saveProductsDeviatedAdmin(product))
    }
}

const DeviatedAdminContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviatedAdmin)

export default injectIntl(DeviatedAdminContainer)
