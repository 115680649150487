export const initState = {
    deliveriesCurrentPage: 1,
    deliveriesLastPage: 1,
    deliveries: [],
    delivery: null,
    isLoading: false,
    isLoadingDetails: false,
    showModal: false,
    error: null,
}

const deliveries = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ORDER_DELIVERIES_PAGINATE_REQUEST':
            return Object.assign({}, state, { isLoading: true })

        case 'FETCH_ORDER_DELIVERIES_PAGINATE_SUCCESS':
            return Object.assign({}, state, {
                deliveries: action.deliveriesCurrentPage > 1 ? state.deliveries.concat(action.deliveries) : action.deliveries,
                deliveriesCurrentPage: action.deliveriesCurrentPage,
                deliveriesLastPage: action.deliveriesLastPage,
                isLoading: false
            })

        case 'FETCH_ORDER_DELIVERIES_PAGINATE_FAILURE':
            return Object.assign({}, state, { isLoading: false })

        default:
            return state
    }
}

export const getDeliveries = state => {
    return state.deliveries.deliveries
}

export const getDeliveriesCurrentPage = state => {
    return state.deliveries.deliveriesCurrentPage
}

export const getDeliveriesLastPage = state => {
    return state.deliveries.deliveriesLastPage
}

export const getDeliveriesLoading = state => {
    return state.deliveries.isLoading
}

export default deliveries
