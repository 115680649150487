import React from 'react'
import Translation from '../../../global/Translation'

class DisplaySalesndicator extends React.Component {
    
    render() {
        return (
            <div className="sales-indicator">
                <Translation id="SALE" defaultMessage="SALE" />
            </div>
        )
    }
}
export default DisplaySalesndicator
