import { LOCATION_CHANGE } from 'react-router-redux'
import { getLanguage } from '../ui/reducers'

const localeWithft2 = ['en-US', 'fr-CA', 'en-CA']

export const initState = {
           isLoading: false,
           isSending: false,
           alert: null,
           alertOfPassword: null,
           status: null,
           statusOfPassword: null,
           profileConfiguration: {
               centerId: null,
               weightUnit: null,
               lengthUnit: null,
               email: [],
               locale: null,
               promotionEmail: []
           },
           errorOnSetEmail: false,
           promotionConfiguration: {
               email: [],
               has_previous_configuration: false,
               accept_notifications: false,
               copy_emails: false,
               accept_policies: false
           },
           whatsappConfiguration: {
               email: null,
               has_previous_configuration: false,
               accept_notifications: false,
           },
           isSubscribed: true
       }
const profileConfiguration = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, { alert: null, status: null })

        case 'SET_USER_EMAIL_AAD_FAILURE':
            return Object.assign({}, state, { errorOnSetEmail: action.statusText })
        case 'FETCH_PROFILE_CONFIGURATION_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_PROFILE_CONFIGURATION_SUCCESS':
            return Object.assign({}, state, { profileConfiguration: action.profileConfiguration, isLoading: false })
        case 'FETCH_PROFILE_CONFIGURATION_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'SAVE_PROFILE_CONFIGURATION_REQUEST':
            return Object.assign({}, state, { isSending: true })
        case 'SAVE_PROFILE_CONFIGURATION_SUCCESS':
            return Object.assign({}, state, { isSending: false, alert: action.alert, status: 200 })
        case 'SAVE_PROFILE_CONFIGURATION_FAILURE':
            return Object.assign({}, state, { isSending: false, alert: action.alert, status: action.status })
        case 'CHANGE_PASSWORD_REQUEST':
            return Object.assign({}, state, { isSending: true })
        case 'CHANGE_PASSWORD_SUCCESS':
            return Object.assign({}, state, { isSending: false, alertOfPassword: action.alert, statusOfPassword: 200 })
        case 'CHANGE_PASSWORD_FAILURE':
            return Object.assign({}, state, { isSending: false, alertOfPassword: action.alert, statusOfPassword: action.status })
        case 'PRELOAD_USER_PREFERENCES_SUCCESS':
            return Object.assign({}, state, { profileConfiguration: action.profileConfiguration })
        case 'IMPERSONATE_REQUEST':
            return Object.assign({}, state, { profileConfiguration: { centerId: null, weightUnit: null, lengthUnit: null, email: null } })
        case 'FETCH_PROFILE_CONFIGURATION_IKEA_ADDRESS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_PROFILE_CONFIGURATION_IKEA_ADDRESS_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_PROFILE_CONFIGURATION_IKEA_ADDRESS_SUCCESS':
            return Object.assign({}, state, { profileConfigurationIkeaAddress: action.profileConfiguration, isLoading: false })
        case 'FETCH_PROMOTION_CONFIGURATION_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_PROMOTION_CONFIGURATION_SUCCESS':
            return Object.assign({}, state, { promotionConfiguration: action.promotionConfiguration, isLoading: false })
        case 'FETCH_PROMOTION_CONFIGURATION_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_WHATSAPP_CONFIGURATION_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_WHATSAPP_CONFIGURATION_SUCCESS':
            return Object.assign({}, state, { whatsappConfiguration: action.whatsappConfiguration, isLoading: false })
        case 'FETCH_WHATSAPP_CONFIGURATION_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'UNSUBSCRIBE_NEWSLETTER_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'UNSUBSCRIBE_NEWSLETTER_SUCCESS':
            return Object.assign({}, state, { isLoading: false, isSubscribed: false })
        case 'UNSUBSCRIBE_NEWSLETTER_FAILURE':
            return Object.assign({}, state, { isLoading: false, isSubscribed: true })
        case 'RESUBSCRIBE_NEWSLETTER_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'RESUBSCRIBE_NEWSLETTER_SUCCESS':
            return Object.assign({}, state, { isLoading: false, isSubscribed: true })
        case 'RESUBSCRIBE_NEWSLETTER_FAILURE':
            return Object.assign({}, state, { isLoading: false, isSubscribed: false })
        case 'SAVE_PROMOTION_CONFIGURATION_REQUEST':
            return Object.assign({}, state, { isSending: true })
        case 'SAVE_PROMOTION_CONFIGURATION_SUCCESS':
            return Object.assign({}, state, { isSending: false, alert: action.alert, status: 200 })
        case 'SAVE_PROMOTION_CONFIGURATION_FAILURE':
            return Object.assign({}, state, { isSending: false, alert: action.alert, status: action.status })
        case 'LOGOUT':
            return Object.assign({}, state, { profileConfiguration: initState.profileConfiguration, promotionConfiguration: initState.promotionConfiguration })
        default:
            return state
    }
}
export const getProfileConfiguration = state => {
    return state.profileConfiguration.profileConfiguration
}
export const getProfileConfigurationEmails = state => {
    return state.profileConfiguration.profileConfiguration.email
}
export const getProfileConfigurationLoading = state => {
    return state.profileConfiguration.isLoading
}
export const getProfileConfigurationSending = state => {
    return state.profileConfiguration.isSending
}
export const getProfileConfigurationAlert = state => {
    return state.profileConfiguration.alert
}
export const getProfileConfigurationAlertOfPassword = state => {
    return state.profileConfiguration.alertOfPassword
}
export const getProfileConfigurationStatus = state => {
    return state.profileConfiguration.status
}
export const getProfileConfigurationStatusOfPassword = state => {
    return state.profileConfiguration.statusOfPassword
}
export const getPreferredCenter = state => {
    return state.profileConfiguration.profileConfiguration.centerId
}
export const getErrorOnSetEmail = state => {
    return state.profileConfiguration.errorOnSetEmail
}
export const getWeightUnit = state => {
    let defaultUnit = getLanguage(state) === 'en' || getLanguage(state) === 'en-US' || getLanguage(state) === 'fr-CA' ? 'LB' : 'KG'
    return state.profileConfiguration.profileConfiguration.weightUnit ? state.profileConfiguration.profileConfiguration.weightUnit : defaultUnit
}
export const getWhatsappOptinPreferences = state => {
    return state.profileConfiguration.whatsappConfiguration
}
export const getWhatsappOptinPreviousPreferences = state => {
           return state.profileConfiguration.whatsappConfiguration.has_previous_configuration
       }
export const getPromotionConfiguration = state => {
    return state.profileConfiguration.promotionConfiguration
}
export const getPromotionConfigurationEmails = state => {
    return state.profileConfiguration.promotionConfiguration.email
}
export const getPromotionConfigurationAcceptPromotionNotifications = state => {
    return state.profileConfiguration.promotionConfiguration.accept_notifications
}
/*export const getPromotionConfigurationPromotionCopyEmailAddress = state => {
    return state.profileConfiguration.promotionConfiguration.copy_emails
}*/
export const getPromotionConfigurationAcceptPromotionPolicies = state => {
    return state.profileConfiguration.promotionConfiguration.accept_policies
}
export const getPromotionConfigurationPreviousPreferences = state => {
    return state.profileConfiguration.promotionConfiguration.has_previous_configuration
}
export const getIsSubscribed = state => state.profileConfiguration.isSubscribed
export const getIsLoading = state => state.profileConfiguration.isLoading

export const getUnit = state => {
    const isMetric = state.profileConfiguration.profileConfiguration.lengthUnit !== 'FT2' && !localeWithft2.includes(state.ui.language)
    return isMetric ? 'm' : 'ft'
}

export const getDefaultFurniturePackaging = state => {
    return state.profileConfiguration.profileConfiguration.defaultFurniturePackaging
}

export const setDefaultFurniturePackaging = (state, defaultPackaging) => {
    state.profileConfiguration.profileConfiguration.defaultFurniturePackaging = defaultPackaging
}

export default profileConfiguration
