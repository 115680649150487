import React from 'react'
import Translation from '../global/Translation'

import silestone from '../../assets/img/logos/brands/logo-silestone.svg'
import dekton from '../../assets/img/logos/brands/logo-dekton.svg'
import dktn from '../../assets/img/DKTN.svg'
import sensa from '../../assets/img/logos/brands/logo-sensa.svg'
import pinterest from '../../assets/img/icons/pinterest.svg'
import instagram from '../../assets/img/icons/instagram.svg'
import youtube from '../../assets/img/icons/youtube.svg'
import facebook from '../../assets/img/icons/facebook.svg'
import twitter from '../../assets/img/icons/twitter.svg'
import linkedin from '../../assets/img/icons/linkedin.svg'
import naturalStone from '../../assets/img/logos/brands/logo-natural-stone.svg'
import LocalizedLink from '../global/LocalizedLink'
import RecentlyVisitedProductsContainer from '../products/RecentlyVisitedProductsContainer'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import MenuContainer from '../header/MenuContainer'
import DeveloperBar from '../global/DeveloperBar'
import { getUsaCanadaSalesOrg } from '../../store/centers/reducers'
import { getUsaCaPrTermsAndConditionsByLang } from '../../helpers'

class FooterV2 extends React.Component {
    render() {
        const { salesOrg } = this.props

        return (
            <div>
                {process.env.NODE_ENV !== 'production' && <DeveloperBar />}

                {/* <Suggestions /> */}
                {!this.props.location.pathname.includes(this.props.intl.formatMessage({ id: 'ROUTE_CHECKOUT' })) &&
                    !window.location.href.includes('landing') &&
                    !this.props.isOrderHistoryScreen &&
                    !this.props.isShippingHistoryScreen && (
                        <div className="recently-visited-products-container">
                            <div className="container">{<RecentlyVisitedProductsContainer sectionFlag="VR" />}</div>
                        </div>
                    )}
                <footer className="footer footer-v2">
                    <div className="container-fluid">
                        <div className="row footer-links">
                            <div className="col-12 col-md-3">
                                <ul className="about-footer">
                                    <li>
                                        <strong>
                                            <Translation id="about_cosentino" defaultMessage="About Cosentino" />
                                        </strong>
                                    </li>
                                    <p>
                                        <Translation
                                            id="cosentino_group_is_a_global"
                                            defaultMessage="Cosentino Group is a global, Spanish, family-owned company that produces and distributes high value innovative surfaces for the world of design and architecture. It works together with its clients and partners to provide with solutions that offer design and value, and inspire the life of many people."
                                        />
                                    </p>
                                    <a target={'_blank'} href="https://www.cosentino.com/cosentino-group/">
                                        <Translation id="view_more" defaultMessage="View more" />
                                    </a>
                                </ul>
                            </div>
                            <div className="col-12 col-md-3">
                                <ul>
                                    <li className="chevron-reponsive-footer">
                                        <strong>
                                            <Translation id="sitemap" defaultMessage="Sitemap" />
                                        </strong>
                                        <i
                                            className="fal fa-chevron-down"
                                            id="chevron-down"
                                            onClick={() => {
                                                document.querySelectorAll(`#links-footer`).forEach(e => {
                                                    e.style.display = 'block'
                                                })
                                                document.querySelectorAll(`#chevron-up`).forEach(e => {
                                                    e.style.display = 'block'
                                                })
                                                document.querySelectorAll(`#chevron-down`).forEach(e => {
                                                    e.style.display = 'none'
                                                })
                                            }}
                                        />
                                        <i
                                            className="fal fa-chevron-up"
                                            id="chevron-up"
                                            style={{ display: 'none' }}
                                            onClick={() => {
                                                document.querySelectorAll(`#links-footer`).forEach(e => {
                                                    e.style.display = 'none'
                                                })
                                                document.querySelectorAll(`#chevron-up`).forEach(e => {
                                                    e.style.display = 'none'
                                                })
                                                document.querySelectorAll(`#chevron-down`).forEach(e => {
                                                    e.style.display = 'block'
                                                })
                                            }}
                                        />
                                    </li>
                                    <div id="links-footer">
                                        <MenuContainer responsive={true} footer={true} />
                                    </div>
                                </ul>
                            </div>
                            <div className="col-12 col-md-3">
                                <ul>
                                    <li className="chevron-reponsive-footer">
                                        <strong>Cosentino We</strong>
                                        <i
                                            className="fal fa-chevron-down"
                                            id="chevron-down2"
                                            onClick={() => {
                                                document.querySelectorAll(`#links-footer2`).forEach(e => {
                                                    e.style.display = 'block'
                                                })
                                                document.querySelectorAll(`#chevron-up2`).forEach(e => {
                                                    e.style.display = 'block'
                                                })
                                                document.querySelectorAll(`#chevron-down2`).forEach(e => {
                                                    e.style.display = 'none'
                                                })
                                            }}
                                        />
                                        <i
                                            className="fal fa-chevron-up"
                                            id="chevron-up2"
                                            style={{ display: 'none' }}
                                            onClick={() => {
                                                document.querySelectorAll(`#links-footer2`).forEach(e => {
                                                    e.style.display = 'none'
                                                })
                                                document.querySelectorAll(`#chevron-up2`).forEach(e => {
                                                    e.style.display = 'none'
                                                })
                                                document.querySelectorAll(`#chevron-down2`).forEach(e => {
                                                    e.style.display = 'block'
                                                })
                                            }}
                                        />
                                    </li>
                                    <div id="links-footer2">
                                        <li>
                                            <a href="https://www.cosentino.com/professional/cosentino-we/" target={'_blank'}>
                                                <Translation id="how_does_it_work" defaultMessage="How does it work?" />
                                            </a>
                                        </li>
                                        {/*<li>
                                            <a target={"_blank"} href="https://we.cosentino.com/s/register?language=en_GB&_ga=2.121019777.823869124.1658757583-1365358577.1589184868">
                                                <Translation id="register_an_account" defaultMessage="Register an account" />
                                            </a>
                                        </li>*/}
                                        <li>
                                            <a target={'_blank'} href="https://we.cosentino.com/s/rewards?language=en">
                                                <Translation id="benefits" defaultMessage="Benefits" />
                                            </a>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                            <div className="social-links col-12 col-md-3">
                                <ul>
                                    <li>
                                        <strong>
                                            <Translation id="follow_us" defaultMessage="Follow us" />
                                        </strong>
                                    </li>
                                    <li>
                                        <a href="http://facebook.com/grupocosentino" target="_blank" rel="noopener noreferrer">
                                            <img src={facebook} alt="facebook" />
                                        </a>
                                        <a href="http://instagram.com/grupocosentino" target="_blank" rel="noopener noreferrer">
                                            <img src={instagram} alt="instagram" />
                                        </a>
                                        <a href="https://www.pinterest.es/grupocosentino/" target="_blank" rel="noopener noreferrer">
                                            <img src={pinterest} alt="pinterest" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/cosentino/" target="_blank" rel="noopener noreferrer">
                                            <img src={linkedin} alt="linkedin" />
                                        </a>
                                        <a href="http://twitter.com/grupocosentino" target="_blank" rel="noopener noreferrer">
                                            <img src={twitter} alt="twitter" />
                                        </a>
                                        <a href="http://youtube.com/cosentinotv" target="_blank" rel="noopener noreferrer">
                                            <img src={youtube} alt="youtube" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row brands " style={{ margin: '40px 0px' }}>
                            {!this.props.isAustralianCenter && (
                                <div className="col-12 col-md-auto text-center p-2">
                                    <a href="https://qr.cosentino.com/brand/silestone" target="_blank" rel="noopener noreferrer">
                                        <img src={silestone} alt="Silestone" className="img-fluid silestone" />
                                    </a>
                                </div>
                            )}
                            <div className="col-12 col-md-auto text-center p-2">
                                <a href="https://qr.cosentino.com/brand/dekton" target="_blank" rel="noopener noreferrer">
                                    {this.props.selectedCenter === 7130 ? (
                                        <img src={dktn} alt="Dekton" className="img-fluid dekton" />
                                    ) : (
                                        <img src={dekton} alt="Dekton" className="img-fluid dekton" />
                                    )}
                                </a>
                            </div>
                            <div className="col-12 col-md-auto text-center p-2">
                                <a href="https://qr.cosentino.com/brand/sensa" target="_blank" rel="noopener noreferrer">
                                    <img src={sensa} alt="Sensa" className="img-fluid sensa" />
                                </a>
                            </div>
                            <div className="col-12 col-md-auto text-center p-2">
                                <a href="https://qr.cosentino.com/brand/scalea" target="_blank" rel="noopener noreferrer">
                                    <img src={naturalStone} alt="Natural Stone" className="img-fluid natural-stone" />
                                </a>
                            </div>
                        </div>

                        <div className="legal row">
                            <div className="col-12 col-md-6 col-lg-3">
                                <ul>
                                    <li>
                                        © Cosentino S.A. <Translation id="all_rights_reserved" defaultMessage="All rights reserved" />
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6" style={{ textAlign: 'center' }}>
                                <ul>
                                    <li>
                                        <LocalizedLink routeId="ROUTE_COOKIES" target="_blank">
                                            <Translation id="cookies_policy" defaultMessage="Cookies policy" />
                                        </LocalizedLink>
                                    </li>
                                    <li>
                                        <LocalizedLink routeId="ROUTE_PRIVACY" target="_blank">
                                            <Translation id="privacy_policy" defaultMessage="Privacy policy" />
                                        </LocalizedLink>
                                    </li>

                                    {getUsaCanadaSalesOrg().includes(salesOrg) ? (
                                        <li>
                                            <a href={getUsaCaPrTermsAndConditionsByLang(this.props.language)} target="_blank" rel="nofollow noopener noreferrer" style={{ fontWeight: 'default' }}>
                                                <Translation id="general_conditions_of_sale" defaultMessage="General conditions of sale" />
                                            </a>
                                        </li>
                                    ) : (
                                        <li>
                                            <a
                                                href="https://assetstools.cosentino.com/api/v1/bynder/doc/511A2D40-054A-41D5-89CE81CF6DB105D8/cgv.pdf"
                                                target="_blank"
                                                rel="nofollow noopener noreferrer"
                                                style={{ fontWeight: 'default' }}
                                            >
                                                <Translation id="general_conditions_of_sale" defaultMessage="General conditions of sale" />
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default withRouter(injectIntl(FooterV2))
