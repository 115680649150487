import React from 'react'
import { injectIntl } from 'react-intl'
import confirm from '../global/confirm'
import { mapValues } from 'lodash'

let Sphere = ({ active, filled, statusText, sphereIndex, onClick, disabled }) => {
    return (
        <div
            className={`tracking-status-bar__item tracking-status-bar__item--action ${filled ? 'tracking-status-bar__item--active' : ''}  ${
                disabled ? 'tracking-status-bar__item--disabled' : ''
            }`}
            onClick={() => (disabled ? null : onClick(parseInt(sphereIndex, 10)))}
        >
            <div className={`tracking-status-bar__item__sphere`} />
            <div className={`tracking-status-bar__item__text`}>
                <div>{statusText}</div>
                {active && (
                    <div>
                        <i className="fas fa-caret-down" />
                    </div>
                )}
            </div>
        </div>
    )
}

let LineSphere = ({ active, filled, statusText, index, onClick, disabled }) => {
    return (
        <React.Fragment>
            <div className={`tracking-status-bar__line ${filled ? 'tracking-status-bar__line--active' : ''}  ${disabled ? 'tracking-status-bar__item--disabled' : ''}`} />
            <Sphere active={active} disabled={disabled} filled={filled} statusText={statusText} sphereIndex={index} onClick={onClick} />
        </React.Fragment>
    )
}

class StepsBar extends React.Component {
    render() {
        const {
            step,
            availableSteps,
            canViewDiscountStep,
            hasUnsavedChangesStepOne,
            hasUnsavedChangesStepTwo,
            hasUnsavedChangesStepThree,
            hasUnsavedChangesStepFour,
            hasUnsavedChangesStepFive,
            hasUnsavedChangesStepSix,
            hasUnsavedChangesStepSeven
        } = this.props

        /**
         * Handle the confirm modal popup for each step.
         * @param {number} incomingStep
         */
        const triggerConfirmUnsavedChanges = incomingStep => {
            return confirm(this.props.intl.formatMessage({ id: 'confirm_unsaved_changes' }), {
                textCancel: this.props.intl.formatMessage({ id: 'continue_without_saving' }),
                textOk: this.props.intl.formatMessage({ id: 'save_and_continue' })
            }).then(
                confirm => {
                    switch (step) {
                        case 1:
                            confirmFromStepOne(incomingStep)
                            break
                        case 2:
                            confirmFromStepTwo(incomingStep)
                            break
                        case 3:
                            confirmFromStepThree(incomingStep)
                            break
                        case 4:
                            confirmFromStepFour(incomingStep)
                            break
                        case 5:
                            confirmFromStepFive(incomingStep)
                            break
                        case 6:
                            confirmFromStepSix(incomingStep)
                            break
                        case 7:
                            confirmFromStepSeven(incomingStep)
                            break
                        default:
                            break
                    }

                    return this.props.changeStep(incomingStep)
                },
                cancel => {
                    switch (step) {
                        case 1:
                            cancelFromStepOne()
                            break
                        case 2:
                            cancelFromStepTwo()
                            break
                        case 3:
                            cancelFromStepThree()
                            break
                        case 4:
                            cancelFromStepFour()
                            break
                        case 5:
                            cancelFromStepFive()
                            break
                        case 6:
                            cancelFromStepSix()
                            break
                        case 7:
                            cancelFromStepSeven()
                            break
                        default:
                            break
                    }

                    return this.props.changeStep(incomingStep)
                }
            )
        }

        /**
         * Handles the call to the submit function of step 1 according to the requested step.
         * @param {number} incomingStep
         */
        const confirmFromStepOne = incomingStep => {
            const submit = this.props.handleHeaderSubmit()
            switch (incomingStep) {
                case 2:
                    return submit
                case 3:
                    return submit
                case 4:
                    return submit
                case 5:
                    return submit
                case 6:
                    return submit
                case 7:
                    return submit
                default:
                    break
            }
        }

        /**
         * Handles the call to the submit function of step 2 according to the requested step.
         * @param {number} incomingStep
         */
        const confirmFromStepTwo = incomingStep => {
            const submit = this.props.references.chooseAreaRef.current.handleSubmit()
            switch (incomingStep) {
                case 1:
                    return submit
                case 3:
                    return submit
                case 4:
                    return submit
                case 5:
                    return submit
                case 6:
                    return submit
                case 7:
                    return submit
                default:
                    break
            }
        }

        /**
         * Handles the call to the submit function of step 3 according to the requested step.
         * @param {number} incomingStep
         */
        const confirmFromStepThree = incomingStep => {
            const submit = this.props.references.chooseCustomersRef.current.handleSubmit()
            switch (incomingStep) {
                case 1:
                    return submit
                case 2:
                    return submit
                case 4:
                    return submit
                case 5:
                    return submit
                case 6:
                    return submit
                case 7:
                    return submit
                default:
                    break
            }
        }

        /**
         * Handles the call to the submit function of step 4 according to the requested step.
         * @param {number} incomingStep
         */
        const confirmFromStepFour = incomingStep => {
            const submit = this.props.references.chooseMaterialsRef.current.handleSubmit()
            switch (incomingStep) {
                case 1:
                    return submit
                case 2:
                    return submit
                case 3:
                    return submit
                case 5:
                    return submit
                case 6:
                    return submit
                case 7:
                    return submit
                default:
                    break
            }
        }

        /**
         * Handles the call to the submit function of step 5 according to the requested step.
         * @param {number} incomingStep
         */
        const confirmFromStepFive = incomingStep => {
            const submit = this.props.references.chooseDiscountsRef.current.handleSubmit()
            switch (incomingStep) {
                case 1:
                    return submit
                case 2:
                    return submit
                case 3:
                    return submit
                case 4:
                    return submit
                case 6:
                    return submit
                case 7:
                    return submit
                default:
                    break
            }
        }

        /**
         * Handles the call to the submit function of step 6 according to the requested step.
         * @param {number} incomingStep
         */
        const confirmFromStepSix = incomingStep => {
            const submit = this.props.references.chooseTemplateRef.current.handleSubmit()
            switch (incomingStep) {
                case 1:
                    return submit
                case 2:
                    return submit
                case 3:
                    return submit
                case 4:
                    return submit
                case 5:
                    return submit
                case 7:
                    return submit
                default:
                    break
            }
        }

        /**
         * Handles the call to the submit function of step 6 according to the requested step.
         * @param {number} incomingStep
         */
        const confirmFromStepSeven = incomingStep => {
            const submit = this.props.references.chooseBannerRef.current.handleSubmit()
            switch (incomingStep) {
                case 1:
                    return submit
                case 2:
                    return submit
                case 3:
                    return submit
                case 4:
                    return submit
                case 5:
                    return submit
                case 6:
                    return submit
                default:
                    break
            }
        }

        /**
         * Handle the continue without saving action for step 2.
         */
        const cancelFromStepOne = () => {
            /** @type {object} */
            let stepOneUnsavedChanges = this.props.stepOneUnsavedChanges

            // Transform the object values to false value
            stepOneUnsavedChanges = mapValues(stepOneUnsavedChanges, () => false)

            // Update the global state
            this.props.setUnsavedChangesStepOne(stepOneUnsavedChanges)
        }

        /**
         * Handle the continue without saving action for step 2.
         */
        const cancelFromStepTwo = () => {
            /** @type {object} */
            const chooseAreaRef = this.props.references.chooseAreaRef.current

            /** @type {object} */
            let unsavedChanges = chooseAreaRef && chooseAreaRef.state.hasUnsavedChanges

            // Transform the object values to false value
            unsavedChanges = mapValues(unsavedChanges, () => false)
            // Update the global state
            this.props.setUnsavedChangesStepTwo(unsavedChanges)
        }

        /**
         * Handle the continue without saving action for step 3.
         */
        const cancelFromStepThree = () => {
            /** @type {object} */
            const chooseCustomersRef = this.props.references.chooseCustomersRef.current

            /** @type {object} */
            let unsavedChanges = chooseCustomersRef && chooseCustomersRef.state.hasUnsavedChanges

            // Transform the object values to false value
            unsavedChanges = mapValues(unsavedChanges, () => false)
            // Update the global state
            this.props.setUnsavedChangesStepThree(unsavedChanges)
        }

        /**
         * Handle the continue without saving action for step 4.
         */
        const cancelFromStepFour = () => {
            /** @type {object} */
            const chooseMaterialsRef = this.props.references.chooseMaterialsRef.current

            /** @type {object} */
            let unsavedChanges = chooseMaterialsRef && chooseMaterialsRef.state.hasUnsavedChanges

            // Transform the object values to false value
            unsavedChanges = mapValues(unsavedChanges, () => false)
            // Update the global state
            this.props.setUnsavedChangesStepFour(unsavedChanges)
        }

        /**
         * Handle the continue without saving action for step 5.
         */
        const cancelFromStepFive = () => {
            /** @type {object} */
            const chooseDiscountsRef = this.props.references.chooseDiscountsRef.current

            /** @type {object} */
            let unsavedChanges = chooseDiscountsRef && chooseDiscountsRef.state.hasUnsavedChanges

            // Transform the object values to false value
            unsavedChanges = mapValues(unsavedChanges, () => false)
            // Update the global state
            this.props.setUnsavedChangesStepFive(unsavedChanges)
        }

        /**
         * Handle the continue without saving action for step 6.
         */
        const cancelFromStepSix = () => {
            /** @type {object} */
            const chooseTemplateRef = this.props.references.chooseTemplateRef.current

            /** @type {object} */
            let unsavedChanges = chooseTemplateRef && chooseTemplateRef.state.hasUnsavedChanges

            // Transform the object values to false value
            unsavedChanges = mapValues(unsavedChanges, () => false)
            // Update the global state
            this.props.setUnsavedChangesStepSix(unsavedChanges)
        }

        /**
         * Handle the continue without saving action for step 7.
         */
        const cancelFromStepSeven = () => {
            /** @type {object} */
            const chooseBannerRef = this.props.references.chooseBannerRef.current

            /** @type {object} */
            let unsavedChanges = chooseBannerRef && chooseBannerRef.state.hasUnsavedChanges

            // Transform the object values to false value
            unsavedChanges = mapValues(unsavedChanges, () => false)
            // Update the global state
            this.props.setUnsavedChangesStepSeven(unsavedChanges)
        }

        /**
         * Handle the change step. If the step has unsaved changes, trigger the confirm modal popup.
         * @param {number} incomingStep
         */
        const handleChangeStep = incomingStep => {
            if (step === 1 && hasUnsavedChangesStepOne) {
                return triggerConfirmUnsavedChanges(incomingStep)
            } else if (step === 2 && hasUnsavedChangesStepTwo) {
                return triggerConfirmUnsavedChanges(incomingStep)
            } else if (step === 3 && hasUnsavedChangesStepThree) {
                return triggerConfirmUnsavedChanges(incomingStep)
            } else if (step === 4 && hasUnsavedChangesStepFour) {
                return triggerConfirmUnsavedChanges(incomingStep)
            } else if (step === 5 && hasUnsavedChangesStepFive) {
                return triggerConfirmUnsavedChanges(incomingStep)
            } else if (step === 6 && hasUnsavedChangesStepSix) {
                return triggerConfirmUnsavedChanges(incomingStep)
            } else if (step === 7 && hasUnsavedChangesStepSeven) {
                return triggerConfirmUnsavedChanges(incomingStep)
            } else {
                this.props.changeStep(incomingStep)
            }
        }

        return (
            <div className={'detail-view wrapper__tracking-status-bar'}>
                <div className="tracking-status-bar tracking-status-bar--ok">
                    <Sphere
                        filled={step >= 1}
                        active={step === 1}
                        disabled={!availableSteps.includes(1)}
                        statusText={this.props.intl.formatMessage({ id: 'promotion_line_general_info' })}
                        sphereIndex="1"
                        onClick={() => handleChangeStep(1)}
                    />
                    <LineSphere
                        filled={step >= 2}
                        active={step === 2}
                        disabled={!availableSteps.includes(2)}
                        statusText={this.props.intl.formatMessage({ id: 'promotion_line_market_target' })}
                        index="2"
                        onClick={() => handleChangeStep(2)}
                    />
                    <LineSphere
                        filled={step >= 3}
                        active={step === 3}
                        disabled={!availableSteps.includes(3)}
                        statusText={this.props.intl.formatMessage({ id: 'promotion_line_customer' })}
                        index="3"
                        onClick={() => handleChangeStep(3)}
                    />
                    <LineSphere
                        filled={step >= 4}
                        active={step === 4}
                        disabled={!availableSteps.includes(4)}
                        statusText={this.props.intl.formatMessage({ id: 'promotion_line_materials' })}
                        index="4"
                        onClick={() => handleChangeStep(4)}
                    />
                    {canViewDiscountStep && (
                        <LineSphere
                            filled={step >= 5}
                            active={step === 5}
                            disabled={!availableSteps.includes(5)}
                            statusText={this.props.intl.formatMessage({ id: 'promotion_line_request_discount', defaultMessage: 'Request discount' })}
                            index="5"
                            onClick={() => handleChangeStep(5)}
                        />
                    )}
                    <LineSphere
                        filled={step >= (canViewDiscountStep ? 6 : 5)}
                        active={step === (canViewDiscountStep ? 6 : 5)}
                        disabled={!availableSteps.includes(canViewDiscountStep ? 6 : 5)}
                        statusText={this.props.intl.formatMessage({ id: 'promotion_line_templates' })}
                        index={canViewDiscountStep ? '6' : '5'}
                        onClick={() => handleChangeStep(canViewDiscountStep ? 6 : 5)}
                    />
                    <LineSphere
                        filled={step >= (canViewDiscountStep ? 7 : 6)}
                        active={step === (canViewDiscountStep ? 7 : 6)}
                        disabled={!availableSteps.includes(canViewDiscountStep ? 7 : 6)}
                        statusText={this.props.intl.formatMessage({ id: 'promotion_line_banner' })}
                        index={canViewDiscountStep ? '7' : '6'}
                        onClick={() => handleChangeStep(canViewDiscountStep ? 7 : 6)}
                    />
                </div>
            </div>
        )
    }
}

export default injectIntl(StepsBar)
