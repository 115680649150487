import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import AlertsContainer from '../alerts/AlertsContainer'
import Alerts from "../alerts/Alerts";

defineMessages({
    user_center_placeholder: {
        id: 'user_center_placeholder',
        description: 'user_center_placeholder',
        defaultMessage: 'User center'
    },
    email_user_center_placeholder: {
        id: 'email_user_center_placeholder',
        description: 'email_user_center_placeholder',
        defaultMessage: 'Email center'
    },
    add_account: {
        id: 'add_account',
        description: 'add_account',
        defaultMessage: 'Add account'
    },
    add_user_center: {
        id: 'add_user_center',
        description: 'add_user_center',
        defaultMessage: 'Add center user'
    },
    EMAIL_ERROR_CENTER: {
        id: 'EMAIL_ERROR_CENTER',
        description: 'EMAIL_ERROR_CENTER',
        defaultMessage: 'The email is incorrect, it has to be @cosentino.com'
    },
    EMAIL_EXISTS_CENTER: {
        id: 'EMAIL_EXISTS_CENTER',
        description: 'EMAIL_EXISTS_CENTER',
        defaultMessage: 'This email is already registered in the center'
    },
    SUCCESS_EMAIL_CENTER: {
        id: 'SUCCESS_EMAIL_CENTER',
        description: 'SUCCESS_EMAIL_CENTER',
        defaultMessage: 'Email registered in the center'
    },
    EMAIL_CENTER_IS_NOT_FORMAT: {
        id: 'EMAIL_CENTER_IS_NOT_FORMAT',
        description: 'EMAIL_CENTER_IS_NOT_FORMAT',
        defaultMessage: 'The email has to be @cosentino.com'
    },
    EMAIL_CENTER_NOT_EMPTY: {
        id: 'EMAIL_CENTER_NOT_EMPTY',
        description: 'EMAIL_CENTER_NOT_EMPTY',
        defaultMessage: 'The email cannot be empty'
    },
    USER_CENTER_NOT_EMPTY: {
        id: 'USER_CENTER_NOT_EMPTY',
        description: 'USER_CENTER_NOT_EMPTY',
        defaultMessage: 'The user cannot be left empty'
    },
})
class AddAccountCenterForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: '',
            msg: '',
            show: false
        }
    }
    validateForm(){
        if(this.emailInput.value !== ''){
            if(this.emailInput.value.split('@')[1] === 'cosentino.com') {
                if(this.userInput.value !== ''){
                    this.setState({show: false})
                    return true
                }else{
                    this.emptyForm();
                    this.setState({show: true});
                    this.setState({status: 'warning'})
                    this.setState({msg: 'USER_CENTER_NOT_EMPTY'})
                    return false
                }
            }else{
                this.emptyForm();
                this.setState({show: true});
                this.setState({status: 'warning'})
                this.setState({msg: 'EMAIL_CENTER_IS_NOT_FORMAT'})
                return false
            }
        }else{
            this.emptyForm();
            this.setState({show: true});
            this.setState({status: 'warning'})
            this.setState({msg: 'EMAIL_CENTER_NOT_EMPTY'})
            return false
        }
    }
    emptyForm(){
        this.emailInput.value = '';
        this.userInput.value = '';
    }
    handleSubmit = e => {
        e.preventDefault()
        if(this.validateForm()) {
            this.props.onAddEmailCenter(this.userInput.value, this.emailInput.value)
            this.emptyForm();
        }
    }
    render() {
        return (
            <div className="form-signin">
                {this.state.show && (
                    <Alerts status={this.state.status} alert={this.state.msg}/>
                )}
                <form onSubmit={this.handleSubmit}>
                    <AlertsContainer />
                    <input
                        type="text"
                        id="inputUser"
                        className="form-control"
                        placeholder={this.props.intl.formatMessage({ id: 'user_center_placeholder' })}
                        ref={node => {
                            this.userInput = node
                        }}
                    />
                    <input
                        type="text"
                        id="inputEmail"
                        className="form-control"
                        placeholder={this.props.intl.formatMessage({ id: 'email_user_center_placeholder' })}
                        ref={node => {
                            this.emailInput = node
                        }}
                    />
                    <input type="submit" className="bt bt-inverted button" value={this.props.intl.formatMessage({ id: 'add_account' })} />
                </form>
            </div>
        )
    }
}
export default injectIntl(AddAccountCenterForm)
