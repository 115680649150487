import { connect } from 'react-redux'
import { getIsLogged } from '../../store/login/reducers'
import Translation from "../global/Translation";
import React from "react";
import ProductsSlider from "./ProductsSlider";
import {canViewMktools} from "../../store/centers/reducers";
import {fetchCartSimilarProducts} from "../../store/crossSelling/actions";
import {getCartComplementaryProducts} from "../../store/crossSelling/reducers";

const mapStateToProps = state => {
    return {
        products: getCartComplementaryProducts(state),
        hide: !getIsLogged(state) || canViewMktools(state),
        title: <Translation id="bought_together" defaultMessage="Bought together" />,
        analyticsTag: 'usually_purchased_cross',
        gtmLabel: 'ec_cart_usually_bought_together'
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetch: () => {
            return dispatch(fetchCartSimilarProducts(props.productIds, props.quantities))
        }
    }
}

const CartBoughtToguetherContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider)


export default CartBoughtToguetherContainer
