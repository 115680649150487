import { canViewRocketPromotion } from '../login/reducers';
import * as api from '../api'
import { getIsLogged, getToken, getUserName } from '../login/reducers'
import * as segmentation from '../segmentation/reducers'
import { fetchLots } from '../lots/actions'
import { getLanguage, getLanguageToFetchZ2, getCurrentUrl } from '../ui/reducers'
import {byProductId, canViewPricesPhase2} from '../products/reducers'

import {
    getShippingMethod,
    canBuy,
    getSelectedCenter,
    getLatinaVitoriaCenterId,
    getIsMkToolsCenter,
    getIsLowesCenter,
    getIsIkeaCenter
} from '../centers/reducers'
import { fetchProduct } from '../products/actions'
import {
    byLineId,
    getLines,
    getLinesByMatId,
    qtySelector,
    getPhase2ClientByProduct,
    getIsCheckoutPage
} from './reducers'
import confirm from '../../components/global/confirm'
import { getSelectedShippingAddressForIkea, getFirstShippingAddres } from '../shipping-addresses/reducers'
import { getEmployeeId } from '../big-account/reducers'
import { getIsValidVoucher, getVoucher } from './reducers'
import { getCheckoutData, getSelectedShippingAddressId, getSelectedShippingCenterId } from '../checkout/reducers'
import { fetchCartCrossSelling} from '../crossSelling/actions'
import { isProductIdInPromotion, getCurrentPromotionId } from '../promotions/reducers'
import {setStep} from "../checkout/actions";

export const genericAddCartLine = (
    productId,
    qty = null,
    lotId = null,
    bundleId = null,
    extra = null,
    callbackCenter = null,
    segmentationRequested = true,
    cutToSize = null,
    dektonGripRequested = false,
    productSupplierCenter = null
) => async (dispatch, getState) => {

    qty = qty > 0 ? qty : 1

    const state = getState()
    if (isProductIdInPromotion(state, productId)) extra = {...extra, promotionId: getCurrentPromotionId(state)}
    if(bundleId && lotId && qty > 1){
        lotId = null;
    }

    dispatch({
        type: 'ADD_CART_LINE_REQUEST',
        line: { productId, qty, lotId, bundleId },
        segmentation: segmentation.getFullSelectedSegmentation(state),
        extra,
        productSupplierCenter
    })
    if (!getIsLogged(state)) {
        dispatch({
            type: 'LOGIN_REQUIRED',
            callbackCenter: callbackCenter,
            callback: genericAddCartLine(productId, qty, lotId, bundleId, extra, callbackCenter, segmentationRequested),
            payload: { statusText: 'LOGIN_REQUIRED' }
        })
        dispatch({ type: 'ADD_CART_LINE_FAILURE', payload: { statusText: null } })
        return
    }
    if (!canBuy(state)) {
        dispatch({ type: 'ADD_CART_LINE_FAILURE', payload: { statusText: null } })
        return
    }
    if (dektonGripRequested) {
        dispatch({ type: 'SHOW_DEKTON_GRIP_MODAL', callback: genericAddCartLine(productId, qty, lotId, bundleId, extra, callbackCenter, segmentationRequested, cutToSize) })
        return
    }

    if (productId !== null) await dispatch(fetchProduct(productId))

    if (segmentationRequested) {
        if (!segmentation.segmentationDone(state)) {
            dispatch({
                type: 'SHOW_SEGMENTATION_MODAL',
                callback: genericAddCartLine(productId, qty, lotId, bundleId, extra, callbackCenter, segmentationRequested, cutToSize, dektonGripRequested, productSupplierCenter),
                productId: productId,
                outlet: extra && extra.outlet ? true : false,
                productSupplierCenter: productSupplierCenter
            })
            return
        }
    }

    // if (productId !== null) await dispatch(fetchProduct(productId))

    if (qty === 'NOT_SET') {
        try {
            qty = await byProductId(state, productId).minQty[getShippingMethod(state)]
        } catch (e) {}
    }
    dispatch({ type: 'ADD_CART_LINE_CALL_API_REQUEST' })
    return api
        .genericAddCartLine(
            state.centers.selectedCenter,
            productId,
            qty,
            lotId,
            bundleId,
            getToken(state),
            segmentation.getFullSelectedSegmentation(state),
            getLanguageToFetchZ2(state),
            extra,
            cutToSize,
            getSelectedShippingAddressForIkea(state),
            getEmployeeId(state),
            productSupplierCenter,
            getShippingMethod(state)
        )
        .then(response => {
            dispatch({ type: 'ADD_CART_LINE_SUCCESS', productId, qty, segmentation: segmentation.getFullSelectedSegmentation(state), cutToSize, extra })
            dispatch({ type: 'SHOW_ADD2CARTSUCCESS_MODAL' })
            dispatch({ type: 'RESET_SEGMENTATION' })
            dispatch({ type: 'ADD_CART_LINE_CALL_API_SUCCESS' })
            if (qty === null) {
                dispatch(fetchLots(productId, null, null, null, null, getCurrentUrl(state).pathname.includes('outlet')))
            }
            dispatch(fetchCart(state.centers.selectedCenter)).then(() => {
                if (bundleId !== null && bundleId.length >= 1) {
                    let product = byProductId(state, productId)
                    if (product.brand === 'SCALEA' && getSelectedCenter(state) === getLatinaVitoriaCenterId()) {
                        dispatch(possibleScaleaToSensa(productId, bundleId))
                    }
                }
            })
            if (canViewPricesPhase2(state)) {
                dispatch(checkLinesPrice())
            }

            if (getIsCheckoutPage(state) && !getIsMkToolsCenter(state) && !getIsLowesCenter(state) && !getIsIkeaCenter(state)) {
                dispatch(setLinesSlaDate())
            }

            dispatch(fetchCartCrossSelling(productId)).then(() => dispatch({ type: 'SHOW_CROSSELLLING_MODAL' }) )

        })
        .catch(error => {
            dispatch({ type: 'ADD_CART_LINE_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
            dispatch({ type: 'RESET_SEGMENTATION' })
            api.errorHandler(dispatch, error.response)
        })
}
export const bulkAddToCart = (productId, lots, bundles, outlet) => (dispatch, getState) => {
    dispatch(genericAddCartLine(productId, null, lots, bundles, { outlet }))
}
export const addTablesToCart = (productId, bundleId, lotId, centerId, quantity) => (dispatch, getState) => {
    dispatch(genericAddCartLine(productId, quantity, lotId, bundleId, null, centerId))
}
export const addCartLineLot = (productId, lotId, callbackCenter, extra=null, supplierCenterId) => (dispatch, getState) => {
    dispatch(genericAddCartLine(productId, null, lotId, null, extra, callbackCenter, true, null, false, supplierCenterId))
}
export const addCartLineBundle = (productId, bundleId, lotId, qty, callbackCenter, extra=null, supplierCenterId) => (dispatch, getState) => {
    dispatch(genericAddCartLine(productId, qty, lotId, bundleId, extra, callbackCenter, true, null, false, supplierCenterId))
}
export const addCartLine = (productId, qty, extra = null, callbackCenter, dektonGripRequested, productSupplierCenter=null) => (dispatch, getState) => {
    dispatch(genericAddCartLine(productId, qty, null, null, extra, callbackCenter, true, null, dektonGripRequested, productSupplierCenter))
}
export const addCartLineCutToSize = (qty, cutToSize, callbackCenter) => (dispatch, getState) => {
    dispatch(genericAddCartLine('CUT_TO_SIZE', qty, null, null, null, callbackCenter, true, cutToSize))
}
//No es necesario
export const addMallCartLine = (productId, qty, extra = null, callbackCenter, dektonGripRequested, productSupplierCenter) => (dispatch, getState) => {
    dispatch(genericAddCartLine(productId, qty, null, null, extra, callbackCenter, true, null, dektonGripRequested, productSupplierCenter))
}

export const addCartBulkLots = (productId, selectedLots, isOutlet, segmentationRequested=true) => (dispatch, getState) => {
    const state = getState()

    dispatch({
        type: 'ADD_CART_BULK_LOTS_REQUEST',
        lines: { selectedLots },
        segmentation: segmentation.getFullSelectedSegmentation(state)
    })

    if (segmentationRequested) {
        if (!segmentation.segmentationDone(state)) {
            dispatch({
                type: 'SHOW_SEGMENTATION_MODAL',
                callback: addCartBulkLots(productId, selectedLots, isOutlet, segmentationRequested),
                productId: productId,
                outlet: isOutlet
            })
            return
        }
    }

    dispatch({ type: 'ADD_CART_BULK_LOTS_CALL_API_REQUEST' })
    return api
        .addCartBulkLots(
            state.centers.selectedCenter,
            selectedLots,
            getToken(state),
            segmentation.getFullSelectedSegmentation(state),
            getLanguageToFetchZ2(state),
            getEmployeeId(state),
            getShippingMethod(state)
        )
        .then(response => {
            dispatch({ type: 'ADD_CART_BULK_LOTS_SUCCESS', productId, lines: selectedLots, segmentation: segmentation.getFullSelectedSegmentation(state)})
            dispatch({ type: 'SHOW_ADD2CARTSUCCESS_MODAL' })
            dispatch({ type: 'RESET_SEGMENTATION' })
            dispatch({ type: 'ADD_CART_BULK_LOTS_CALL_API_SUCCESS' })
            // dispatch(fetchLots(productId, null, null, null, null, getCurrentUrl(state).pathname.includes('outlet')))
            dispatch(fetchCart(state.centers.selectedCenter))/*.then(() => {
                if (bundleId !== null && bundleId.length >= 1) {
                    let product = byProductId(state, productId)
                    if (product.brand === 'SCALEA' && getSelectedCenter(state) === getLatinaVitoriaCenterId()) {
                        dispatch(possibleScaleaToSensa(productId, bundleId))
                    }
                }
            })*/
            if (canViewPricesPhase2(state)) {
                dispatch(checkLinesPrice())
            }
            dispatch(fetchCartCrossSelling(productId)).then(() => dispatch({ type: 'SHOW_CROSSELLLING_MODAL' }) )

        })
        .catch(error => {
            dispatch({ type: 'ADD_CART_LINE_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
            dispatch({ type: 'RESET_SEGMENTATION' })
            api.errorHandler(dispatch, error.response)
        })
}


export const addCartLineNotSegmentation = (productId, qty, extra = null, callbackCenter, dektonGripRequested) => (dispatch, getState) => {
    window.dataLayer.push({
        'products': [{
            'id': productId,
            'dimension3': true
        }]
    })
    dispatch(genericAddCartLine(productId, qty, null, null, extra, callbackCenter, false, null, dektonGripRequested))
}

export const checkVoucher = voucher => (dispatch, getState) => {
    dispatch({ type: 'CART_CHECK_VALID_VOUCHER_REQUEST', voucher })

    //Hotfix tonto para evitar que le valide el cupon al usuario y el cupon baje a SAP cuando no es un cliente de la promo ROCKET.
    if(voucher == 100000331 && !canViewRocketPromotion(getState())){
        dispatch({
            type: 'CART_CHECK_VALID_VOUCHER_FAILURE',
            error: 'INVALID_VOUCHER',
            voucherStatusText: 'INVALID_VOUCHER',
            isValidVoucher: false
        })
        dispatch(checkLinesPrice())
        return Promise.reject()
    }

    return api.checkVoucher(getToken(getState()), getSelectedCenter(getState()), voucher, getLanguage(getState())).then(
        response => {
            dispatch({ type: 'CART_CHECK_VALID_VOUCHER_SUCCESS', cart: response.data })
            dispatch(checkLinesPrice())
        },
        error => {
            dispatch({
                type: 'CART_CHECK_VALID_VOUCHER_FAILURE',
                error: error,
                voucherStatusText: error.response && error.response.data && error.response.data.err,
                isValidVoucher: false
            })
            dispatch(checkLinesPrice())
            return Promise.reject()
        }
    )
}

export const removeVoucher = voucher => (dispatch, getState) => {
    dispatch({ type: 'CART_REMOVE_VOUCHER_REQUEST', voucher })

    return api.removeVoucher(getToken(getState()), getSelectedCenter(getState()), voucher, getLanguage(getState())).then(
        response => {
            dispatch({ type: 'CART_REMOVE_VOUCHER_SUCCESS', cart: response.data })
            dispatch(checkLinesPrice())
        },
        error => {
            dispatch({
                type: 'CART_REMOVE_VOUCHER_FAILURE',
                error: error,
                voucherStatusText: error.response && error.response.data && error.response.data.err
            })
            dispatch(checkLinesPrice())
            return Promise.reject()
        }
    )
}

export const addCartLineWithouthSeg = (productId, qty, extra, callbackCenter, dektonGripRequested) => (dispatch, getState) => {
    if (getSelectedCenter(getState()) === 7777) {
        let found = getLines(getState()).find(line => line.productId === productId && line.extra && line.extra.type === extra.type)
        if (found) {
            if (!(found.extra && found.extra.type === 'wall_sample')) return dispatch({ type: 'CANT_ADD_CART_LINE_AGAIN' } /*changeCartLineQty(found.lineId, found.qty + qty)*/)
        } else {
            return dispatch(genericAddCartLine(productId, qty, null, null, extra, callbackCenter, false))
        }
    } else {
        return dispatch(genericAddCartLine(productId, qty, null, null, extra, callbackCenter, false))
    }
}
export const removeCartLine = lineId => (dispatch, getState) => {
    return api
        .removeCartLine(lineId, getState().centers.selectedCenter, getToken(getState()), getSelectedShippingAddressForIkea(getState()), getEmployeeId(getState()))
        .then(response => {
            dispatch({ type: 'REMOVE_CART_LINE', lineId })
            dispatch(fetchCart(getState().centers.selectedCenter))
            if (canViewPricesPhase2(getState())) {
                dispatch(checkLinesPrice())
            }

            if(getIsCheckoutPage(getState()) && !getIsMkToolsCenter(getState()) && !getIsLowesCenter(getState()) && !getIsIkeaCenter(getState())) {
                dispatch(setLinesSlaDate())
            }
        })
        .catch(error => {
            dispatch({
                type: 'REMOVE_CART_LINE_FAILED',
                lineId
            })
        })
}

export const emptyCart = () => (dispatch, getState) => {
    return api
        .emptyCart(getState().centers.selectedCenter, getToken(getState()), getSelectedShippingAddressForIkea(getState()), getEmployeeId(getState()))
        .then(response => {
            dispatch({ type: 'EMPTY_CART' })
        })
        .catch(error => {
            dispatch({ type: 'EMPTY_CART_FAILED' })
        })
}

export const changeCartLineSegmentation = line => (dispatch, getState) => {
    const newSeg = segmentation.getFullSelectedSegmentation(getState())
    dispatch({ type: 'UPDATE_SEGMENTATION_CART_LINE_REQUEST', segmentation: newSeg, line })

    return api
        .changeCartLineSegmentation(
            line.lineId,
            newSeg,
            getSelectedCenter(getState()),
            getToken(getState()),
            getSelectedShippingAddressForIkea(getState()),
            getEmployeeId(getState())
        )
        .then(() => {
            dispatch({ type: 'UPDATE_SEGMENTATION_CART_LINE_SUCCESS' })
            dispatch({ type: 'RESET_SEGMENTATION' })
            dispatch(fetchCart(getState().centers.selectedCenter))
        })
        .catch(error => {
            dispatch({ type: 'UPDATE_SEGMENTATION_CART_LINE_FAILURE' })
            dispatch({ type: 'RESET_SEGMENTATION' })
            api.errorHandler(dispatch, error.response)
        })
}
export const changeCartLineQty = (lineId, qty) => (dispatch, getState) => {
    dispatch({ type: 'CHANGE_CART_LINE_QTY_REQUEST_START'})
    return api
        .changeCartLineQty(lineId, qty, getState().centers.selectedCenter, getToken(getState()), getSelectedShippingAddressForIkea(getState()), getEmployeeId(getState()))
        .then(response => {
            dispatch(fetchCart(getState().centers.selectedCenter, lineId))
            if (canViewPricesPhase2(getState())) {
                dispatch(checkLinesPrice())
            }

            if(getIsCheckoutPage(getState()) && !getIsMkToolsCenter(getState()) && !getIsLowesCenter(getState()) && !getIsIkeaCenter(getState())) {
                dispatch(setLinesSlaDate())
            }
        })
        .catch(error => {
            dispatch({ type: 'CHANGE_CART_LINE_QTY_FAILED', lineId, qty, payload: { statusText: error.response.data.err, values: error.response.data.info } })
        })
        .finally(() => dispatch({ type: 'CHANGE_CART_LINE_QTY_REQUEST_FINISH'}))
}
export const checkLinesPrice = () => (dispatch, getState) => {
    if (qtySelector(getState()) === 0) {
        return null
    }
    dispatch({ type: 'CHECK_LINES_PRICE_REQUEST' })

    return api
        .checkLinesPrice(
            getState().centers.selectedCenter,
            getToken(getState()),
            getSelectedShippingAddressForIkea(getState()),
            getEmployeeId(getState()),
            getShippingMethod(getState()),
            getIsValidVoucher(getState()) ? getVoucher(getState()) : undefined
        )
        .then(response => {
            dispatch({ type: 'CHECK_LINES_PRICE_SUCCESS', data: response.data })
        })
        .catch(error => {
            dispatch({ type: 'CHECK_LINES_PRICE_FAILURE', payload: { statusText: error.response ? error.response.data.err : "Error", values: error.response ? error.response.data.info : "Error" } })
        })
}
export const checkPricePhase2ByProduct = (productId, center = null, outletType) => (dispatch, getState) => {

    const state = getState()
    const storedPhase2 = getPhase2ClientByProduct(state, productId)
    const userName = getUserName(state)

    if (!center) center = getSelectedCenter(state)
    
    if ( storedPhase2 && storedPhase2 === userName) return Promise.resolve()

    dispatch({ type: 'STORE_PRICE_PHASE2_PRODUCT_BY_CLIENT', productId, userName})
    dispatch({ type: 'CHECK_PRICE_PHASE2_BY_PRODUCT_REQUEST', outletType })

    return api
        .checkPricePhase2ByProduct(productId, center, getToken(state), outletType)
        .then(response => {
            dispatch({ type: 'CHECK_PRICE_PHASE2_BY_PRODUCT_SUCCESS', data: response.data, productId })
        })
        .catch(error => {
            dispatch({ type: 'CHECK_PRICE_PHASE2_BY_PRODUCT_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
        })
}
export const changeCartLineComment = (lineId, comment) => (dispatch, getState) => {
    let commentsWithouthEmojis = comment.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')

    dispatch({ type: 'CHANGE_CART_LINE_COMMENT', lineId, commentsWithouthEmojis })
}
export const saveCartLineComment = (lineId, comment) => (dispatch, getState) => {
    return api
        .changeCartLineComment(lineId, comment, getState().centers.selectedCenter, getToken(getState()), getSelectedShippingAddressForIkea(getState()), getEmployeeId(getState()))
        .then(response => {
            dispatch({ type: 'SAVE_CART_LINE_COMMENT', lineId, comment })
        })
        .catch(error => {
            dispatch({ type: 'SAVE_CART_LINE_COMMENT_FAILED', lineId, comment, payload: { statusText: error.response.data.err, values: error.response.data.info } })
        })
}

export const fetchCart = (centerId, lineId = null) => (dispatch, getState) => {
    const isLogged = getIsLogged(getState())
    // dispatch(emptyCart())
    if (!isLogged) return
    dispatch({
        type: 'FETCH_CART_REQUEST'
    })
    return api
        .getCart(getSelectedCenter(getState()), getSelectedShippingAddressForIkea(getState()), getEmployeeId(getState()), getLanguageToFetchZ2(getState()), getToken(getState()))
        .then(
            cart => {
                if (lineId) {
                    const line = byLineId(getState(), lineId)
                    const nextLine = cart.data.lines.find(line => line.lineId === lineId)
                    const diffQty = nextLine.qty - line.qty

                    if (diffQty > 0) dispatch({ type: 'CHANGE_CART_LINE_ADD_QTY', line, diffQty, newQty: nextLine.qty })
                    else if (diffQty < 0) dispatch({ type: 'CHANGE_CART_LINE_REMOVE_QTY', line, diffQty: Math.abs(diffQty), newQty: nextLine.qty })
                }
                dispatch({ type: 'FETCH_CART_SUCCESS', cart: cart.data })
                if (canViewPricesPhase2(getState())) {
                    dispatch(checkLinesPrice())
                }
            },
            error => {
                dispatch({ type: 'FETCH_CART_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const checkout = (isCustomer) => (dispatch, getState) => {
    const state = getState()
    const checkoutData = getCheckoutData(isCustomer, state)
    dispatch({
        type: 'SUBMIT_CHECKOUT_REQUEST',
        checkoutData
    })

    return api
        .submitCheckout(state.centers.selectedCenter, getToken(state), checkoutData, getSelectedShippingAddressForIkea(state), getEmployeeId(state))
        .then(response => {
            const regReservationId = response.data.regularOrder &&
                (response.data.regularOrder.reservationId ?
                    response.data.regularOrder.reservationId
                    : response.data.regularOrder.tempReservationId)
            const ctsReservationId = response.data.ctsOrder &&
                (response.data.ctsOrder.reservationId ?
                    response.data.ctsOrder.reservationId
                    : response.data.ctsOrder.tempReservationId)
            const configurableReservationId = response.data.ctsConfigurableOrder && response.data.ctsConfigurableOrder.tempReservationId

            dispatch({ type: 'SUBMIT_CHECKOUT_SUCCESS',
                        code: regReservationId,
                        cutToSizeCode: ctsReservationId,
                        configurableCode: configurableReservationId
            })

            dispatch(setStep(2))
            
            dispatch(emptyCart())
        })
        .catch(error => {
            dispatch(fetchCart())
            if (error.response && error.response.data.err == 'PRODUCT_WITHOUT_STOCK') {
                dispatch({ type: 'PRODUCT_WITHOUT_STOCK', data: error.response.data.products })
            } else {
                dispatch({
                    type: 'SUBMIT_CHECKOUT_FAILURE',
                    payload: {
                        status: error.response.status,
                        statusText: error.response.data.err,
                        monthItems: error.response.data.monthItems,
                        waitTillDate: error.response.data.waitTillDate
                    }
                })
            }
        })
}
export const possibleScaleaToSensa = (matId, bundleId) => (dispatch, getState) => {
    dispatch({ type: 'SCALEA_TO_SENSA_POSSIBLE_REQUEST', matId })

    return api.possibleScaleaToSensa(matId, getSelectedCenter(getState()), getToken(getState())).then(
        response => {
            dispatch({ type: 'SCALEA_TO_SENSA_POSSIBLE', response: response.data })
            if (response.data.result === true) {
                confirm('Would you like to apply Sensa treatment?').then(
                    confirm => {
                        dispatch(transformScaleaToSensa(getLinesByMatId(getState(), matId).map(line => line.lineId)))
                    },
                    cancel => {}
                )
            }
        },
        error => {
            dispatch({ type: 'SCALEA_TO_SENSA_NOT_POSSIBLE', error })
        }
    )
}
export const transformScaleaToSensa = lineIds => (dispatch, getState) => {
    dispatch({ type: 'TRANSFORM_SCALEA_TO_SENSA_REQUEST', lineIds, token: getToken(getState()), center: getSelectedCenter(getState()) })

    return api.transformScaleaToSensa(getSelectedCenter(getState()), lineIds, getToken(getState())).then(
        response => {
            dispatch({ type: 'TRANSFORM_SCALEA_TO_SENSA_SUCCESS', response: response.data })
            dispatch(fetchCart(getState().centers.selectedCenter))
        },
        error => {
            dispatch({ type: 'TRANSFORM_SCALEA_TO_SENSA_FAILURE', error })
        }
    )
}

export const transformSkuToLots = () => (dispatch, getState) => {
    dispatch({ type: 'TRANSFORM_SKU_TO_LOTS_REQUEST', token: getToken(getState()), center: getSelectedCenter(getState()) })

    return api.transformSkuToLots(getSelectedCenter(getState()), getToken(getState())).then(
        response => {
            // dispatch(fetchCart(getState().centers.selectedCenter))
            dispatch({ type: 'TRANSFORM_SKU_TO_LOTS_SUCCESS', response: response.data })
        },
        error => {
            dispatch({
                type: 'TRANSFORM_SKU_TO_LOTS_FAILURE',
                error,
                payload: {
                    status: error.response.status,
                    statusText: error.response.data.err
                }
            })
            return Promise.reject()
        }
    )
}

export const setShowModalNoStock = value => dispatch => {
    dispatch({
        type: 'SET_SHOW_MODAL_NOSTOCK',
        value: value
    })
}

export const setLineDeliveryDate = (lineId, deliveryDate) => (dispatch) => {
    return dispatch({ type: 'CART_SET_LINE_DELIVERYDATE', lineId, deliveryDate })
}

export const unsetLineDeliveryDate = (lineId) => (dispatch) => {
    return dispatch({ type: 'CART_UNSET_LINE_DELIVERYDATE', lineId })
}

export const setAllLinesDeliveryDates = (deliveryDate, isDefaultDeliveryDate) => dispatch => {
    return dispatch({ type: 'CART_SET_ALL_LINES_DELIVERYDATES', deliveryDate, isDefaultDeliveryDate })
}

export const unsetAllLinesDeliveryDates = () => dispatch => {
    return dispatch({ type: 'CART_UNSET_ALL_LINES_DELIVERYDATES' })
}

export const setLinesSlaDate = () => (dispatch, getState) => {
    const centerId = getState().centers.selectedCenter
    const shippingMethod = getShippingMethod(getState())
    const token = getToken(getState())
    const firstShippingAddress = getFirstShippingAddres(getState())

    const firstShippingAddrId = firstShippingAddress && firstShippingAddress.shippingAddressId
    const firstShippingCenterId = firstShippingAddress && firstShippingAddress.centerId

    const checkoutShippingAddrId = getSelectedShippingAddressId(getState())
    const checkoutShippingCenterId = getSelectedShippingCenterId(getState())

    const finalShippingAddrId = checkoutShippingAddrId !== null ? checkoutShippingAddrId : firstShippingAddrId
    const finalShippingCenterId = checkoutShippingCenterId !== null ? checkoutShippingCenterId : firstShippingCenterId

    dispatch({ type: 'CART_SET_LINE_SLA_DATE_REQUEST' })

    return api.setLinesSlaDate(centerId, shippingMethod, finalShippingAddrId, finalShippingCenterId, token).then(response => {
        dispatch({ type: 'CART_SET_LINE_SLA_DATE_SUCCESS', linesSlaDate: response.data })
    })
    .catch(error => {
        dispatch({ type: 'CART_SET_LINE_SLA_DATE_FAILURE', error })
    })
}

export const isCheckoutPageScreen = (isCheckoutPage) => (dispatch, getState) => {
    dispatch({type: 'IS_CHECKOUT_PAGE_SCREEN', isCheckoutPage: isCheckoutPage})

    if (isCheckoutPage && !getIsMkToolsCenter(getState()) && !getIsLowesCenter(getState()) && !getIsIkeaCenter(getState()))  {
        return dispatch(setLinesSlaDate())
    }
}

export const setAllLinesDeliveryDatesCts = (deliveryDateCts, slaDateState) => (dispatch) => {
    return dispatch({ type: 'CART_SET_ALL_LINES_DELIVERYDATES_CTS', deliveryDateCts, slaDateState })
}

export const changeCartLineCutToSize = (lineId, cutToSize) => (dispatch, getState) => {

    dispatch({ type: 'UPDATE_CUT_TO_SIZE_CART_LINE_REQUEST' })

    return api
        .changeCartLineCutToSize(
            lineId,
            cutToSize,
            getSelectedCenter(getState()),
            getToken(getState()),
            getSelectedShippingAddressForIkea(getState()),
            getEmployeeId(getState())
        )
        .then(() => {
            dispatch({ type: 'UPDATE_CUT_TO_SIZE_CART_LINE_SUCCESS' })
            dispatch(fetchCart(getState().centers.selectedCenter))
        })
        .catch(error => {
            dispatch({ type: 'UPDATE_CUT_TO_SIZE_CART_LINE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}