import React from 'react'
import moment from 'moment'
import DisplayOutletIndicator from '../displays/DisplayOutletIndicator'
import DisplaySalesIndicator from '../displays/DisplaySalesIndicator'
import DisplayDiscontinued from '../displays/DisplayDiscontinued'
import DisplayPromoIndicator from '../displays/DisplayPromoIndicator'
import DisplayNewIndicator from '../displays/DisplayNewIndicator'
import DisplayBlackIndicator from '../displays/DisplayBlackIndicator'
import DisplayUpcomingIndicator from '../displays/DisplayUpcomingIndicator'
import Translation from "../../../global/Translation";
import Button from "../../../global/Button";

const MallProductIndicators = (props) => {

    const getIndicators = () => {
        const indicators = props.indicators ? props.indicators : []

        if ((props.onZoomClick && props.product.detailImage && props.product.detailImage !== 'null') || (props.onZoomClick && props.product.imgLarge && props.product.imgLarge !== 'null')) indicators.push(
            <Button
                className="slide-card__zoom"
                icon="expand"
                size="medium"
                inverted
                onClick={() => props.onZoomClick(props.product)}
            />
        )

        if (props.product.realStock === 0) indicators.push(
            <div className="sold-out-indicator-wrapper" key={'mall-sold-out-ind_' + props.product.mallProductId}>
                <span className="sold-out-indicator sold-out-indicator__detail">
                    <Translation id="sold_out" defaultMessage="Sold out" />
                </span>
            </div>
        )
        else if (props.product.outlet) indicators.push(
            <DisplayOutletIndicator
                key={'mall-outlet-ind_' + props.product.mallProductId}
                outletQuality={props.product.outletQuality}
                outletDeviated={props.isAdmin || props.impersonatedBy ? props.product.outletDeviated : null}
            />
        )

        return indicators
    }

    const getSphereIndicators = () => {
        const sphereIndicators = props.sphereIndicators ? props.sphereIndicators : []
        const todayMinusOneYear = new moment().subtract(365, 'days')
        const todayPlus15Days = new moment().add(15, 'days')
        const today = new moment()
        const creationDate = moment(props.product.creationDate)
        const showNewIndicator = creationDate > todayMinusOneYear && creationDate < today
        const showUpcomingIndicator = creationDate > today && creationDate < todayPlus15Days

        if (showNewIndicator) sphereIndicators.push(<DisplayNewIndicator />)
        if (showUpcomingIndicator) sphereIndicators.push(<DisplayUpcomingIndicator />)

        if (props.canViewPricesPhase3 && props.tate && props.state.promotions && props.state.promotions.length) {
            if (props.state.promotions[0].dealType === 'A') {
                sphereIndicators.push(
                    <DisplayBlackIndicator value={props.intl.formatMessage({ id: 'new_price' })}
                                           productDiscounts={props.productDiscounts}
                                           key={'sphere-A-ind_' + props.product.mallProductId}
                    />
                )
            }
            else if (props.state.promotions[0].dealType === 'B') {
                sphereIndicators.push(
                    <DisplayBlackIndicator value={`-${props.state.promotions[0].value}%`}
                                           productDiscounts={props.productDiscounts}
                                           key={'sphere-A-ind_' + props.product.mallProductId}
                    />)
            }
        }

        return sphereIndicators
    }


    if (props.product) {
        return (
            <React.Fragment>
                <div className="indicators w-100 h-100">
                    {
                        getIndicators().map(i => i)
                    }
                </div>
                <div className="spheres-indicator">
                    {
                        getSphereIndicators().map(i => i)
                    }
                </div>
            </React.Fragment>
        )
    } else return <React.Fragment/>
}

export default MallProductIndicators
