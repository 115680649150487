import React from 'react'
import { injectIntl } from 'react-intl'
import SimpleModal from '../global/SimpleModal'
import confirm from '../global/confirm'
import Button from '../global/Button'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { SearchkitManager, SearchkitProvider, SearchBox, ResetFilters, QueryAccessor } from 'searchkit'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { DateRangePicker, DateTimePicker } from '@progress/kendo-react-dateinputs'
import { Input, RadioButton } from '@progress/kendo-react-inputs'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import { Editor, EditorTools } from '@progress/kendo-react-editor'
import { Sortable } from '@progress/kendo-react-sortable'
import SortableItemUI from './components/SortableItemUI.js'
import 'draft-js/dist/Draft.css'
import cameraOff from '../../assets/img/icons/camera-off.svg'
import { CENTERS, DATE_FORMAT,LANG_TO_INDEX, CREATION_MODE, getMarkets, getCountries, getCenters, 
    getStatuses, arrayToFormData, isImageValid, getFileData, PORTFOLIO_MODE_SHOW_ONLY_CODI, PORTFOLIO_MODE_SHOW_ONLY_USER_CENTER, PORTFOLIO_MODE_SHOW_BOTH_CENTERS } from './utils'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import ProductDisplayName from '../global/ProductDisplayName'
import { defineMessages } from 'react-intl'
import Translation from '../global/Translation'
import LotsModal from "./components/LotsModal";
import AdditionalInfoModal from "./components/AdditionalInfoModal";

const { Bold, Underline, FontSize, BackColor, ForeColor, Link, Unlink } = EditorTools

defineMessages({
    CONFIRM_DELETE_IMAGE: {
        id: 'CONFIRM_DELETE_IMAGE',
        description: 'CONFIRM_DELETE_IMAGE',
        defaultMessage: 'Are you sure you want to delete this image?'
    },
    CONFIRM_DELETE_LANDING: {
        id: 'CONFIRM_DELETE_LANDING',
        description: 'CONFIRM_DELETE_LANDING',
        defaultMessage: 'Are you sure you want to delete this Landing?'
    },
    CONFIRM_DELETE_LANGUAGE: {
        id: 'CONFIRM_DELETE_LANGUAGE',
        description: 'CONFIRM_DELETE_LANGUAGE',
        defaultMessage: 'Are you sure you want to delete this translation?'
    },
    change: {
        id: 'change',
        description: 'change',
        defaultMessage: 'Change'
    },
    creation_error: {
        id: 'creation_error',
        description: 'creation_error',
        defaultMessage: 'There was an error creating'
    },
    creation_success: {
        id: 'creation_success',
        description: 'creation_success',
        defaultMessage: 'Created successfully'
    },
    error_image_file: {
        id: 'error_image_file',
        description: 'error_image_file',
        defaultMessage: 'The image must be in jpg/png and not weigh more than 10MB'
    },
    landing_edit: {
        id: 'landing_edit',
        description: 'landing_edit',
        defaultMessage: 'Edit landing'
    },
    landing_new: {
        id: 'landing_new',
        description: 'landing_new',
        defaultMessage: 'New landing'
    },
    product_already_added: {
        id: 'product_already_added',
        description: 'product_already_added',
        defaultMessage: 'Product has already been added'
    },
    template: {
        id: 'template',
        description: 'template',
        defaultMessage: 'Template'
    },
    translation_edit: {
        id: 'translation_edit',
        description: 'translation_edit',
        defaultMessage: 'Edit translation'
    },
    translation_new: {
        id: 'translation_new',
        description: 'translation_new',
        defaultMessage: 'New translation'
    },
    update_error: {
        id: 'update_error',
        description: 'update_error',
        defaultMessage: 'There was an error updating'
    },
    update_success: {
        id: 'update_success',
        description: 'update_success',
        defaultMessage: 'Updated successfuly'
    },
    select_center: {
        id: 'select_center',
        description: 'Select center',
        defaultMessage: 'Select center'
    }
})

const PORTFOLIO_MODES = {
    [PORTFOLIO_MODE_SHOW_ONLY_CODI]:{value:PORTFOLIO_MODE_SHOW_ONLY_CODI, label:"HQ Center"}, 
    [PORTFOLIO_MODE_SHOW_ONLY_USER_CENTER]:{value:PORTFOLIO_MODE_SHOW_ONLY_USER_CENTER,label:"User Center"}, 
    [PORTFOLIO_MODE_SHOW_BOTH_CENTERS]:{value:PORTFOLIO_MODE_SHOW_BOTH_CENTERS, label:"Both Centers"}
}

const skRef = {
    data : null,
    sk : null
}
class LandingCreation extends React.Component {

    getUri = () => {

        let lang = 'es'
        const catalogUri = '/catalog/search-multi-center-edit/'
        const uri = catalogUri + '?lang=' + lang
        const uri2 = this.props.outlet ? uri + '&outlet=true' : uri

        return process.env.REACT_APP_API_HOST + uri2
    }

    centers = () => this.state.labelsInput.centers.map(v => v.value)

    setExtraData = () => {
        const salesOrgMap = {}
        this.state.labelsInput.countries.forEach(v => v.salesOrg.forEach(v => {
            salesOrgMap[v] = true
        }))
        let salesOrg = Object.keys(salesOrgMap)
        if (!salesOrg.length) salesOrg = this.state.labelsInput.market.salesOrg
        skRef.data = {centers: this.centers(), salesOrg, portfolioMode: this.state.portfolioMode}
        // console.log('RURIDATA', skRef.data)
        // return encodeURIComponent(JSON.stringify(data));
    }

    setSk = () => {
        const myHttpHeaders = this.props.token ? { Authorization: 'Bearer ' + this.props.token } : {}
        const sk = new SearchkitManager('/', {
            searchUrlPath: this.getUri(),
            timeout: 60000,
            httpHeaders: myHttpHeaders,
            searchOnLoad: false,
            useHistory: false
        })

        sk.addResultsListener((results, second) => {
            this.setState({ hits: results, showResults: true, searchingProducts: false })
        })

        sk.setQueryProcessor(plainQueryObject => {
            if (!plainQueryObject.query) {
                this.setState({ showResults: false, searchingProducts: false })
                return null
            } else {
                this.setState({ showResults: true, searchingProducts: true })
                plainQueryObject.extra = skRef.data
                return plainQueryObject
            }
        })

        skRef.sk = sk;
    }

    constructor(props) {
        super(props)

        this.ref = React.createRef();
        
        this.state = {
            hasErrors: false,
            mode: null,
            redirect: false,
            showModal: false,
            showLotsModal: false,
            showModalImage: false,
            showModalAddProduct: false,
            lastModalProduct: null,
            modalText: '',
            modalTextError: '',
            modalImageSrc: null,
            longDescription: null,
            showResults: false,
            bannerDesktopKey: uuidv4(),
            bannerMobileKey: uuidv4(),
            hits: [],
            newLandingDraft: {},
            labelsInput: {
                status: { label: '', value: '' },
                template_id: '',
                market: '',
                countries: [],
                centers: [],
                slug: '',
                promotion: '',
                banner_desktop_image: null,
                banner_mobile_image: null
            },
            title: '',
            products: [],
            searchingProducts: false,
            slugTimeout: null,
            portfolioMode: PORTFOLIO_MODE_SHOW_ONLY_CODI,
            lotsSource: null,
            selectedLots: {},
            selectedBundles: {},
            showAdditionalInfoModal: false,
            modalItemData: null
        }

        this.setSk()

        this.handleRLDDChange = newItems => {
            this.setState({ products: newItems })
        }
    }

    componentDidMount() {
        const {
            fetchLanding,
            landingId,
            fetchLandingbyId,
            currentIso,
            intl,
            fetchCountriesDescriptions,
            fetchCentersDescriptions,
            fetchLangs,
            resetCountriesCenters,
            removeLoader,
            addLoader
        } = this.props

        const timeout = setTimeout(() => {
            removeLoader(timeout)
        }, 20000)

        addLoader(timeout)

        resetCountriesCenters()
        fetchLangs()
        fetchLanding().then(() => {
            if (landingId && currentIso && landingId !== 'new') {
                let mode = CREATION_MODE.EDIT

                if (currentIso !== this.props.landing.baseLang.id) {
                    mode = window.location.href.indexOf('/edit') > -1 ? CREATION_MODE.EDIT_LANGUAGE : CREATION_MODE.NEW_LANGUAGE
                }

                let isoValue = currentIso

                if (mode === CREATION_MODE.NEW_LANGUAGE) {
                    isoValue = this.props.landing.baseLang.id
                }

                fetchLandingbyId(landingId, isoValue).then(res => {
                    const market = getMarkets(this.props.landing.markets).find(a => a.value === this.props.landing.landing.market)
                    
                    this.props.storePortfolioMode(this.props.landing.landing.portfolio_mode)

                    fetchCountriesDescriptions(market.salesOrg).then(() => {
                        fetchCentersDescriptions(
                            this.selectedSalesOrgs(
                                getCountries(this.props.landing.countries, intl).filter(
                                    a => this.props.landing.landing.countries && this.props.landing.landing.countries.find(b => b === a.id)
                                )
                            )
                        ).then(() => {
                            const data = {
                                ...this.props.landing.landing
                            }

                            const dataToShow = {
                                status: { value: this.props.landing.landing.status, label: intl.formatMessage({ id: 'status_' + this.props.landing.landing.status }) },
                                template_id: this.props.landing.templates.find(template => template.id === this.props.landing.landing.template_id),
                                market: market,
                                countries: getCountries(this.props.landing.countries, intl).filter(
                                    a => this.props.landing.landing.countries && this.props.landing.landing.countries.find(b => b === a.id)
                                ),
                                centers: getCenters(this.props.landing.centers).filter(
                                    a => this.props.landing.landing.centers && this.props.landing.landing.centers.find(b => b === a.value)
                                ),
                                dates: {
                                    start: this.props.landing.landing.start_at ? moment(this.props.landing.landing.start_at, 'YYYY-MM-DD HH:mm:ss').toDate() : null,
                                    end: this.props.landing.landing.end_at ? moment(this.props.landing.landing.end_at, 'YYYY-MM-DD HH:mm:ss').toDate() : null
                                },
                                slug: this.props.landing.landing.slug,
                                promotion: this.props.landing.landing.promotion,
                                banner_desktop_image_filename:
                                    this.props.landing.landing.banner_desktop_image_filename && this.props.landing.landing.banner_desktop_image_filename != 'null'
                                        ? this.props.landing.landing.banner_desktop_image_filename
                                        : '',
                                banner_desktop_image_uri:
                                    this.props.landing.landing.banner_desktop_image_uri && this.props.landing.landing.banner_desktop_image_uri != 'null'
                                        ? this.props.landing.landing.banner_desktop_image_uri
                                        : '',
                                banner_mobile_image_filename:
                                    this.props.landing.landing.banner_mobile_image_filename && this.props.landing.landing.banner_mobile_image_filename != 'null'
                                        ? this.props.landing.landing.banner_mobile_image_filename
                                        : '',
                                banner_mobile_image_uri:
                                    this.props.landing.landing.banner_mobile_image_uri && this.props.landing.landing.banner_mobile_image_uri != 'null'
                                        ? this.props.landing.landing.banner_mobile_image_uri
                                        : ''
                            }

                            this.setState(
                                {
                                    newLandingDraft: { ...data, status: dataToShow.status },
                                    title: this.props.landing.landing.title,
                                    labelsInput: dataToShow,
                                    products: this.props.landing.landing.products,
                                    portfolioMode: this.props.landing.landing.portfolio_mode,
                                    mode
                                },
                                () => {
                                    removeLoader(timeout)
                                    this.setExtraData()
                                }
                            )
                        })
                    })
                })
            } else {
                this.setState({ mode: CREATION_MODE.NEW })
                removeLoader(timeout)
            }
        })
    }

    hideResults = e => {
        this.setState({ showResults: false })
    }

    parseBackError = () => {
        let sMessage = ''

        try {
            if (this.props.error.data) {
                for (var key in this.props.error.data) {
                    sMessage += ` ${key} : ${this.props.error.data[key].join(', ')} `
                }
            }
        } catch (error) {
            sMessage = JSON.stringify(this.props.error)
        }

        return sMessage
    }

    onDeleteLanguage = () => {
        const { currentIso, landing, deleteLanding, intl } = this.props
        confirm(intl.formatMessage({ id: 'CONFIRM_DELETE_LANGUAGE' }), {
            textOk: intl.formatMessage({ id: 'ok' }),
            textCancel: intl.formatMessage({ id: 'cancel' }),
            intl: intl
        }).then(
            confirm => {
                return deleteLanding(landing.landing.common_id, currentIso).then(() => {
                    this.forceUpdate()
                    this.props.showToastNotification(intl.formatMessage({ id: 'remove_success' }), 'success')
                    this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_LANDING_MANAGER' }))
                })
            },
            cancel => {
                return Promise.reject()
            }
        )
    }

    handleSaveLanding = dataInput => {
        const { landing, currentIso, langs } = this.props
        const { labelsInput, mode } = this.state

        const lang = (langs || []).find(l => l.id === currentIso)

        const dataFinal = {
            ...dataInput,
            id: mode === CREATION_MODE.NEW_LANGUAGE || mode === CREATION_MODE.NEW ? uuidv4() : landing.landing.id,
            common_id: mode === CREATION_MODE.NEW || !landing.landing.common_id ? uuidv4() : landing.landing.common_id,
            lang_id: lang ? lang.id : landing && landing.baseLang ? landing.baseLang.id : '',
            status: dataInput.status.value,
            portfolio_mode: this.state.portfolioMode
        }

        const data = new FormData()

        for (const [key, value] of Object.entries(dataFinal)) {
            if (key !== 'countries' && key !== 'centers' && key !== 'banner_desktop_image' && key !== 'banner_mobile_image' && key !== 'start_at' && key !== 'end_at') {
                data.append(key, value)
            } else if ((key !== 'start_At' || key !== 'end_at') && value) {
                data.append(key, value)
            }
        }

        if (dataFinal.countries && dataFinal.countries.length) {
            arrayToFormData(dataFinal.countries, data, 'countries')
        } else {
            data.append('countries', [])
        }
        if (dataFinal.centers && dataFinal.centers.length) {
            arrayToFormData(dataFinal.centers, data, 'centers')
        } else {
            data.append('centers', [])
        }

        if (dataFinal.banner_desktop_image) {
            data.append('banner_desktop_image', dataFinal.banner_desktop_image)
        } else if (!labelsInput.banner_desktop_image_uri) {
            data.append('banner_desktop_image_uri', '')
        }
        if (dataFinal.banner_mobile_image) {
            data.append('banner_mobile_image', dataFinal.banner_mobile_image)
        } else if (!labelsInput.banner_mobile_image_uri) {
            data.append('banner_mobile_image_uri', '')
        }

        for (var i = 0; i < this.state.products.length; i++) {
            let pData = getFileData(this.state.products[i].data)
            if (pData) {
                try {
                    pData = { ...pData, original_thumbnail: pData.original_thumbnail || this.state.products[i].thumbnail_image_uri || this.state.products[i].tumbnail_image_url }
                } catch (e) {}
            }

            data.append(`products[${i}][id]`, this.state.products[i].id)
            data.append(`products[${i}][product_id]`, this.state.products[i].product_id)
            data.append(`products[${i}][data]`, pData ? JSON.stringify(pData) : '')
            data.append(`products[${i}][name]`, this.state.products[i].name)
            data.append(`products[${i}][brand]`, this.state.products[i].brand)
            data.append(`products[${i}][thickness]`, this.state.products[i].thickness)
            data.append(`products[${i}][format]`, this.state.products[i].format)
            data.append(`products[${i}][catalog_image]`, this.state.products[i].catalog_image)
            data.append(`products[${i}][finish]`, this.state.products[i].finish)
            data.append(`products[${i}][center_id]`, this.state.products[i].center_id)
            data.append(`products[${i}][sort]`, i)
            data.append(`products[${i}][minimum_order]`, this.state.products[i].minimum_order)

            if (this.state.products[i].thumbnail_image) {
                data.append(`products[${i}][thumbnail_image]`, this.state.products[i].thumbnail_image)
            } else {
                data.append(`products[${i}][thumbnail_image_uri]`, this.state.products[i].thumbnail_image_uri || this.state.products[i].thumbnail_image_uri)
                if (this.state.products[i].thumbnail_image_filename) {
                    data.append(`products[${i}][thumbnail_image_filename]`, this.state.products[i].thumbnail_image_filename)
                }
            }
            if (this.state.products[i].detail_image) {
                data.append(`products[${i}][detail_image]`, this.state.products[i].detail_image)
            } else {
                data.append(`products[${i}][detail_image_uri]`, this.state.products[i].detail_image_uri || this.state.products[i].detail_image_url)
                if (this.state.products[i].detail_image_filename) {
                    data.append(`products[${i}][detail_image_filename]`, this.state.products[i].detail_image_filename)
                }
            }
            if (this.state.products[i].background_image) {
                data.append(`products[${i}][background_image]`, this.state.products[i].background_image)
            } else {
                data.append(`products[${i}][background_image_uri]`, this.state.products[i].background_image_uri || this.state.products[i].background_image_url)
                if (this.state.products[i].background_image_filename) {
                    data.append(`products[${i}][background_image_filename]`, this.state.products[i].background_image_filename)
                }
            }
        }

        const timeout = setTimeout(() => {
            this.props.removeLoader(timeout)
        }, 10000)

        this.props.addLoader(timeout)

        if (mode === CREATION_MODE.NEW) {
            this.props.createLanding(data).then(() => {
                this.handleFinishSaveLanding(timeout)
            })
        } else if (mode === CREATION_MODE.NEW_LANGUAGE) {
            this.props.createLandingTranslated(data).then(() => {
                this.handleFinishSaveLanding(timeout)
            })
        } else if (mode === CREATION_MODE.EDIT) {
            this.props.updateLanding(data).then(() => {
                this.handleFinishSaveLanding(timeout)
            })
        } else if (mode === CREATION_MODE.EDIT_LANGUAGE) {
            this.props.updateLandingTranslated(data).then(() => {
                this.handleFinishSaveLanding(timeout)
            })
        }
    }

    preProccessProduct = (hit) => {
        // console.log('(hit.', (hit))
        if (hit.inner_hits) {
            // console.log('(hit._inner_hits', (hit.inner_hits))
            try {
                const centers = []
                let stock = 0
                let stockOutlet = 0
                let stockMall = 0
                hit.inner_hits.byCenter.hits.hits.forEach(h => {
                    centers.push(h._source.centerId)
                    stock += h._source.stock
                    stockOutlet += h._source.stock_outlet
                    stockMall += h._source.stock_mall
                })
                hit._source.centers = centers
                hit._source.stock = stock
                hit._source.stock_outlet = stockOutlet
                hit._source.stock_mall = stockMall
                hit._source.outlet = stockOutlet > 0
                hit._source.outletQuality = stockOutlet > 0 ? hit._source.outletQuality : null
            } catch (e) {
                // console.log('HI err', e)
            }
        }
        // console.log('_source', hit._source)
        return hit._source
    }

    handleFinishSaveLanding = timeout => {
        this.props.removeLoader(timeout)

        if (this.props.error) {
            this.props.showToastNotification(
                this.props.intl.formatMessage({ id: this.state.mode === CREATION_MODE.NEW ? 'creation_error' : 'update_error' }) + ': ' + this.parseBackError(),
                'error'
            )
        } else {
            this.props.showToastNotification(this.props.intl.formatMessage({ id: this.state.mode === CREATION_MODE.NEW ? 'creation_success' : 'update_success' }), 'success')
            this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_LANDING_MANAGER' }))
        }
    }

    ResetSearchbox = ({ hasFilters, resetFilters }) => {
        return <div onClick={resetFilters} className="bt-reset-searchbox"></div>
    }

    SortItemUI = props => (
        <SortableItemUI
            {...props}
            onEdit={dataItem => {
                let products = this.state.products.map(product => {
                    // if (product.product_id !== dataItem.product_id || (product.product_id === dataItem.product_id && product.data.isStockOutlet !== dataItem.data.isStockOutlet)) {
                    if (product.id !== dataItem.id) {
                        return product
                    }

                    return { ...product, ...dataItem }
                })

                this.setState({ products: products })
            }}
            onDelete={(dataItem, index) => {
                // console.log('ON DELETE', dataItem, index)
                var products = this.state.products.filter(
                    // a => a.product_id !== dataItem.product_id || (a.product_id === dataItem.product_id && a.data.isStockOutlet !== dataItem.data.isStockOutlet)
                    a => a.id !== dataItem.id
                )

                dataItem.data && dataItem.data.lots && dataItem.data.lots.forEach(l => delete this.state.selectedLots[l])
                dataItem.data && dataItem.data.bundles && dataItem.data.bundles.forEach(b => delete this.state.selectedBundles[b])

                this.setState({ products: products })
            }}
            onDeleteImage={(id, type, action) => {
                if (this.props.landingId) {
                    confirm(this.props.intl.formatMessage({ id: 'CONFIRM_DELETE_IMAGE' }), {
                        textOk: this.props.intl.formatMessage({ id: 'ok' }),
                        textCancel: this.props.intl.formatMessage({ id: 'cancel' }),
                        intl: this.props.intl
                    }).then(
                        confirm => {
                            if (action) {
                                action()
                            }
                            return this.props.deleteLandingImage(id, type).then(() => {
                                this.props.showToastNotification(this.props.intl.formatMessage({ id: 'remove_success' }), 'success')
                                this.forceUpdate()
                            })
                        },
                        cancel => {
                            return Promise.reject()
                        }
                    )
                }
            }}
            onShowImage={src => {
                if (src) {
                    this.setState({
                        showModalImage: true,
                        modalImageSrc: src
                    })
                }
            }}
            showLotsModal={(dataItem) => this.setState({
                showLotsModal:true,
                lotsSource: {product: dataItem.data, centers: null, dataItem, toEdit: true}
            })}
            onFileError={() => this.props.showToastNotification(this.props.intl.formatMessage({ id: 'error_image_file' }), 'error')}

            showAdditionalInfoModal={itemData => this.setState({showAdditionalInfoModal: true, modalItemData: itemData})}
        />
    )

    tryAddProductWithLots = () => {}

    tryAddProduct = (prod, checkOutlet = true, isOutlet = prod.outlet, callback) => {
        if (checkOutlet && prod.outlet && prod.stock > 0 && prod.stock_outlet > 0) {
            this.setState({ showModalAddProduct: true, lastModalProduct: prod })
        } else {
            const prd = [...this.state.products]
            const bFound = prd.find(a => a.product_id === prod.productId && a.data.isStockOutlet === isOutlet && !a.data.isGroup)

            if (!bFound) {
                this.addProduct(prod, isOutlet, () => {
                    if (typeof callback === 'function') callback()
                })
            } else {
                this.props.showToastNotification(this.props.intl.formatMessage({ id: 'product_already_added' }), 'error')
            }
        }
    }

    addProduct = (prod, isOutlet, callback) => {
        const product = {
            id: uuidv4(),
            landing_id: this.props.landingId ? this.props.landingId : null,
            product_id: prod.productId,
            brand: prod.brand,
            finish: prod.finish,
            thickness: prod.thickness,
            center_id: prod.centerId,
            format: prod.format,
            name: prod.displayName,
            sort: this.state.products.length,
            minimun_order: prod.min,
            thumbnail_image_uri: prod.thumb,
            detail_image_url: prod.img,
            background_image_url: prod.colorImg,
            catalog_image: prod.img,
            original_thumbnail: prod.thumb,
            outlet: prod.outlet,
            data: { ...prod, isStockOutlet: isOutlet }
        }

        this.setState(
            {
                products: [...this.state.products, product]
            },
            () => {
                if (typeof callback === 'function') callback()
            }
        )
        this.hideResults()
    }

    onValidate(dataInput) {
        let bValid = true,
            el

        if (!dataInput.status || !dataInput.status.value) {
            el = document.getElementById('status-field')
            bValid = false
        }
        if (!dataInput.template_id) {
            this.setState({ hasErrors: true })
            el = el || document.getElementById('template-field')
            bValid = false
        }
        if (!dataInput.market) {
            this.setState({ hasErrors: true })
            el = el || document.getElementById('market-field')
            bValid = false
        }
        if (!this.state.title) {
            this.setState({ hasErrors: true })
            el = el || document.getElementById('title-field')
            bValid = false
        }
        if (!dataInput.slug) {
            this.setState({ hasErrors: true })
            el = el || document.getElementById('slug-field')
            bValid = false
        }
        if (this.state.products.length === 0) {
            this.setState({ hasErrors: true })
            el = el || document.getElementById('products-row')
            bValid = false
        }

        if (!bValid) {
            this.setState({ hasErrors: true })

            if (el) {
                const y = el.getBoundingClientRect().top + window.pageYOffset - 128
                window.scrollTo({ top: y, behavior: 'smooth' })
            }

            return false
        }

        return true
    }

    selectedSalesOrgs(countries = this.state.labelsInput.countries) {
        const salesOrg = []

        countries.forEach(c => {
            c.salesOrg.forEach(s => salesOrg.push(s))
        })

        return salesOrg
    }

    onSave = dataInput => {
        if (!this.onValidate(dataInput)) return
        this.setState({ hasErrors: false })
        this.handleSaveLanding(dataInput)
    }

    getModeText = mode => {
        switch (mode) {
            case CREATION_MODE.NEW:
                return this.props.intl.formatMessage({ id: 'landing_new' })
            case CREATION_MODE.EDIT:
                return this.props.intl.formatMessage({ id: 'landing_edit' })
            case CREATION_MODE.NEW_LANGUAGE:
                return this.props.intl.formatMessage({ id: 'translation_new' })
            case CREATION_MODE.EDIT_LANGUAGE:
                return this.props.intl.formatMessage({ id: 'translation_edit' })
            default:
                return ''
        }
    }

    render() {
        // console.log(this.ref.current)
        const { redirect, intl, landing, countries, centers, fetchCountriesDescriptions, fetchCentersDescriptions, currentIso } = this.props
        const { newLandingDraft, labelsInput, bannerDesktopKey, bannerMobileKey, mode, portfolioMode } = this.state

        return (
            <section id="section-landing-creation">
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>{this.getModeText(mode)}</h2>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="landings">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 landings__button">
                                <Button
                                    size="medium"
                                    onClick={() => {
                                        redirect(intl.formatMessage({ id: 'ROUTE_LANDING_MANAGER' }))
                                    }}
                                    gentle
                                >
                                    {intl.formatMessage({ id: 'back' })}
                                </Button>
                            </div>
                            <div className="col-md-1">{/* Spacer block */}</div>
                            <div className="col-md-3 landings__button">
                                {mode === CREATION_MODE.EDIT_LANGUAGE && (
                                    <Button onClick={this.onDeleteLanguage} size="medium" gentle>
                                        <Translation id="delete_translation" defaultMessage="Remove translation" />
                                    </Button>
                                )}
                            </div>
                            <div className="col-md-3 landings__button">
                                {mode !== CREATION_MODE.NEW && (
                                    <Button
                                        size="medium"
                                        gentle
                                        onClick={() => {
                                            redirect(
                                                intl
                                                    .formatMessage({ id: 'ROUTE_LANDING_TEMPLATE' })
                                                    .replace(':slug?', this.props.landing.landing.slug)
                                                    .replace(':iso?', currentIso)
                                                    .replace('/:preview?', '/preview')
                                            )
                                        }}
                                    >
                                        <Translation id="preview" defaultMessage="Preview" />
                                    </Button>
                                )}
                            </div>
                            <div className="col-md-3 landings__button">
                                <Button size="medium" onClick={() => this.onSave(newLandingDraft)}>
                                    {intl.formatMessage({ id: 'save' })}
                                </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 landings__filter">
                                <DropDownList
                                    disabled={this.state.mode === CREATION_MODE.NEW_LANGUAGE || this.state.mode === CREATION_MODE.EDIT_LANGUAGE}
                                    id="status-field"
                                    valid={!this.state.hasErrors || (this.state.hasErrors && this.state.newLandingDraft.status != undefined)}
                                    label={intl.formatMessage({ id: 'status' })}
                                    textField="label"
                                    data={getStatuses(landing ? landing.statuses : [], intl)}
                                    value={labelsInput.status}
                                    onChange={e => {
                                        this.setState({
                                            newLandingDraft: {
                                                ...newLandingDraft,
                                                status: e.target.value
                                            },
                                            labelsInput: {
                                                ...labelsInput,
                                                status: e.target.value
                                            }
                                        })
                                    }}
                                />
                                {this.state.hasErrors && (!newLandingDraft.status || !this.state.newLandingDraft.status.value) && (
                                    <span style={{ color: '#f31700' }}>
                                        <Translation id="must_select_status" defaultMessage="You must select a status" />
                                    </span>
                                )}
                            </div>

                            <div className="col-md-6 landings__filter">
                                <DropDownList
                                    disabled={this.state.mode === CREATION_MODE.NEW_LANGUAGE || this.state.mode === CREATION_MODE.EDIT_LANGUAGE}
                                    id="portfolio-center-field"
                                    // valid={!this.state.hasErrors || (this.state.hasErrors && this.state.newLandingDraft.status != undefined)}
                                    label="Portfolio center"
                                    textField="label"
                                    data={Object.values(PORTFOLIO_MODES)}
                                    value={PORTFOLIO_MODES[portfolioMode]}
                                    onChange={e => {
                                        this.setState({portfolioMode:e.target.value.value}, this.setExtraData)
                                        this.props.storePortfolioMode(e.target.value.value)
                                    }}
                                />
                                {/* {this.state.hasErrors && (!newLandingDraft.status || !this.state.newLandingDraft.status.value) && (
                                    <span style={{ color: '#f31700' }}>
                                        <Translation id="must_select_status" defaultMessage="You must select a status" />
                                    </span>
                                )} */}
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6 landings__filter">
                                <DropDownList
                                    disabled={this.state.mode === CREATION_MODE.NEW_LANGUAGE || this.state.mode === CREATION_MODE.EDIT_LANGUAGE}
                                    id="template-field"
                                    valid={!this.state.hasErrors || (this.state.hasErrors && newLandingDraft.template_id != undefined)}
                                    label={intl.formatMessage({ id: 'template' })}
                                    dataItemKey="id"
                                    textField="name"
                                    data={landing ? landing.templates : []}
                                    value={labelsInput.template_id}
                                    onChange={e => {
                                        this.setState({
                                            labelsInput: {
                                                ...labelsInput,
                                                template_id: e.target.value
                                            },
                                            newLandingDraft: {
                                                ...newLandingDraft,
                                                template_id: e.target.value.id
                                            }
                                        })
                                    }}
                                />
                                {this.state.hasErrors && !newLandingDraft.template_id && (
                                    <span style={{ color: '#f31700' }}>
                                        <Translation id="must_select_template" defaultMessage="You must select a template" />
                                    </span>
                                )}
                            </div>
                            <div className="col-md-6 landings__filter landings__filter--date">
                                <label>
                                    <Translation id="dates" defaultMessage="Dates" />
                                </label>
                                <DateRangePicker
                                    calendarSettings={{ className: 'creation-rangepicker' }}
                                    disabled={this.state.mode === CREATION_MODE.NEW_LANGUAGE || this.state.mode === CREATION_MODE.EDIT_LANGUAGE}
                                    startDateInput={props => <DateTimePicker {...props} label={undefined} />}
                                    endDateInput={props => <DateTimePicker {...props} label={undefined} />}
                                    format="dd/MM/yyyy HH:mm:ss"
                                    value={labelsInput.dates}
                                    onChange={e => {
                                        this.setState({
                                            labelsInput: {
                                                ...labelsInput,
                                                dates: e.target.value
                                            },
                                            newLandingDraft: {
                                                ...newLandingDraft,
                                                start_at: moment(e.target.value.start).format(DATE_FORMAT),
                                                end_at: moment(e.target.value.end).format(DATE_FORMAT)
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 landings__filter">
                                <DropDownList
                                    disabled={this.state.mode === CREATION_MODE.NEW_LANGUAGE || this.state.mode === CREATION_MODE.EDIT_LANGUAGE}
                                    id="market-field"
                                    valid={!this.state.hasErrors || (this.state.hasErrors && newLandingDraft.market != undefined)}
                                    label={intl.formatMessage({ id: 'markets' })}
                                    dataItemKey="value"
                                    textField="label"
                                    data={getMarkets(landing ? landing.markets : [])}
                                    value={labelsInput.market}
                                    onChange={e => {
                                        this.setState({
                                            newLandingDraft: {
                                                ...newLandingDraft,
                                                market: e.target.value.value
                                            },
                                            labelsInput: {
                                                ...labelsInput,
                                                market: e.target.value
                                            }
                                        })
                                        fetchCountriesDescriptions(e.target.value.salesOrg).then(() =>
                                            this.setState({
                                                labelsInput: {
                                                    ...this.state.labelsInput,
                                                    countries: [],
                                                    centers: []
                                                },
                                                newLandingDraft: {
                                                    ...this.state.newLandingDraft,
                                                    countries: [],
                                                    centers: []
                                                }
                                            })
                                        )
                                    }}
                                />
                                {this.state.hasErrors && !newLandingDraft.market && (
                                    <span style={{ color: '#f31700' }}>
                                        <Translation id="must_select_market" defaultMessage="You must select a market" />
                                    </span>
                                )}
                            </div>
                            <div className="col-md-4 landings__filter">
                                <MultiSelect
                                    disabled={this.state.mode === CREATION_MODE.NEW_LANGUAGE || this.state.mode === CREATION_MODE.EDIT_LANGUAGE}
                                    label={intl.formatMessage({ id: 'countries' })}
                                    dataItemKey="id"
                                    textField="label"
                                    data={getCountries(countries ? countries : [], intl)}
                                    value={labelsInput.countries}
                                    onChange={e => {
                                        this.setState({
                                            labelsInput: {
                                                ...labelsInput,
                                                countries: e.target.value
                                            },
                                            newLandingDraft: {
                                                ...newLandingDraft,
                                                countries: e.target.value ? e.target.value.map(country => country.id) : []
                                            }
                                        }, this.setExtraData)
                                        fetchCentersDescriptions(this.selectedSalesOrgs(e.target.value)).then(data =>
                                            this.setState({
                                                labelsInput: {
                                                    ...this.state.labelsInput,
                                                    centers: []
                                                },
                                                newLandingDraft: {
                                                    ...this.state.newLandingDraft,
                                                    centers: []
                                                }
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div className="col-md-4 landings__filter">
                                <MultiSelect
                                    disabled={this.state.mode === CREATION_MODE.NEW_LANGUAGE || this.state.mode === CREATION_MODE.EDIT_LANGUAGE}
                                    label={intl.formatMessage({ id: 'centers' })}
                                    dataItemKey="value"
                                    textField="label"
                                    data={getCenters(centers ? centers : [])}
                                    value={labelsInput.centers}
                                    onChange={e => {
                                        this.setState({
                                            labelsInput: {
                                                ...labelsInput,
                                                centers: e.target.value
                                            },
                                            newLandingDraft: {
                                                ...newLandingDraft,
                                                centers: e.target.value ? e.target.value.map(center => center.value) : []
                                            }
                                        }, this.setExtraData)
                                    }}
                                />
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-md-12">
                                <div style={{
                                    border: "1px solid #707070",
                                    padding: "4px 8px",
                                    borderRadius: "4px"}}
                                >
                                    <div className="row my-3">
                                        <div className="col-md-3"><b>Portfolio mode</b></div>                                
                                        <div className="col-md-3">                                            
                                            <RadioButton label="Only HQ center" id="radioHQCenter" name="portfolioCenter" value="1" />
                                        </div>

                                        <div className="col-md-3">
                                            <RadioButton label="Only client center" id="radioClientCenter" name="portfolioCenter" value="2"/>
                                        </div>

                                        <div className="col-md-3">
                                            <RadioButton label="Both centers" id="radioBothCenters" name="portfolioCenter" value="3"/>
                                        </div>
                                    </div>
                                </div>                                                                
                            </div>                            
                        </div> */}

                        <div className="row">
                            <div className="col-md-12">
                                <h4>
                                    <i className="k-icon k-i-set-column-position"></i>
                                    <Translation id="contents" defaultMessage="Contents" />
                                </h4>
                            </div>
                            <div className="col-md-12 landings__filter landings__filter--input">
                                <Input
                                    id="title-field"
                                    valid={!this.state.hasErrors || (this.state.hasErrors && this.state.title != '')}
                                    label={intl.formatMessage({ id: 'title' })}
                                    value={this.state.title}
                                    onChange={e => {
                                        this.setState({
                                            title: e.target.value,
                                            newLandingDraft: {
                                                ...this.state.newLandingDraft,
                                                title: e.target.value
                                            }
                                        })
                                    }}
                                    onKeyUp={e => {
                                        e.preventDefault()
                                        clearTimeout(this.state.slugTimeout)
                                        this.setState({
                                            slugTimeout: setTimeout(
                                                sText => {
                                                    const data = {
                                                        text: sText,
                                                        lang_id: currentIso || landing.baseLang.id
                                                    }
                                                    this.props.getLandingSlug(data).then(newSlug => {
                                                        this.setState({
                                                            title: this.state.title,
                                                            labelsInput: {
                                                                ...this.state.labelsInput,
                                                                slug: this.props.slug.slug
                                                            },
                                                            newLandingDraft: {
                                                                ...this.state.newLandingDraft,
                                                                slug: this.props.slug.slug
                                                            }
                                                        })
                                                    })
                                                },
                                                500,
                                                e.target.value
                                            )
                                        })
                                    }}
                                />
                                {this.state.hasErrors && !this.state.title && (
                                    <span style={{ color: '#f31700' }}>
                                        <Translation id="mandatory_field" defaultMessage="This field is required" />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 landings__filter landings__filter--input">
                                <Input
                                    id="slug-field"
                                    valid={!this.state.hasErrors || (this.state.hasErrors && newLandingDraft.slug != undefined && newLandingDraft.slug != '')}
                                    label="Slug"
                                    value={labelsInput.slug}
                                    onChange={e => {
                                        this.setState({
                                            labelsInput: {
                                                ...labelsInput,
                                                slug: e.target.value
                                            },
                                            newLandingDraft: {
                                                ...newLandingDraft,
                                                slug: e.target.value
                                            }
                                        })
                                    }}
                                    onKeyUp={e => {
                                        e.preventDefault()
                                        clearTimeout(this.state.slugTimeout)
                                        this.setState({
                                            slugTimeout: setTimeout(
                                                sText => {
                                                    const data = {
                                                        text: sText,
                                                        lang_id: currentIso || landing.baseLang.id
                                                    }
                                                    this.props.getLandingSlug(data).then(newSlug => {
                                                        this.setState({
                                                            labelsInput: {
                                                                ...this.state.labelsInput,
                                                                slug: this.props.slug.slug
                                                            },
                                                            newLandingDraft: {
                                                                ...this.state.newLandingDraft,
                                                                slug: this.props.slug.slug
                                                            }
                                                        })
                                                    })
                                                },
                                                500,
                                                e.target.value
                                            )
                                        })
                                    }}
                                />
                                {this.state.hasErrors && !newLandingDraft.slug && (
                                    <span style={{ color: '#f31700' }}>
                                        <Translation id="mandatory_field" defaultMessage="This field is required" />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 landings__filter landings__editor">
                                <label>{intl.formatMessage({ id: 'promotion' })}</label>
                                <Editor
                                    tools={[[Link, Unlink, Bold, Underline, BackColor, ForeColor, FontSize]]}
                                    defaultContent={labelsInput.promotion || ''}
                                    value={labelsInput.promotion || ''}
                                    onChange={event => {
                                        this.setState({
                                            labelsInput: {
                                                ...labelsInput,
                                                promotion: event.html
                                            },
                                            newLandingDraft: {
                                                ...newLandingDraft,
                                                promotion: event.html
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 landings__file">
                                {!labelsInput.banner_desktop_image_filename && (
                                    <Input
                                        className="landings__file__input"
                                        label={intl.formatMessage({ id: 'banner_image_1110' })}
                                        type="file"
                                        accept="image/jpg,image/jpeg,image/png"
                                        key={bannerDesktopKey}
                                        value={newLandingDraft.banner_desktop_image}
                                        onChange={e => {
                                            if (isImageValid(e.target.element.files[0])) {
                                                this.setState({
                                                    labelsInput: {
                                                        ...labelsInput,
                                                        banner_desktop_image_uri: URL.createObjectURL(e.target.element.files[0]),
                                                        banner_desktop_image_filename: e.target.element.files[0].name,
                                                        banner_desktop_image_preview: true
                                                    },
                                                    newLandingDraft: {
                                                        ...newLandingDraft,
                                                        banner_desktop_image: e.target.element.files[0],
                                                        banner_desktop_image_filename: e.target.element.files[0].name
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    labelsInput: {
                                                        ...labelsInput,
                                                        banner_desktop_image_uri: '',
                                                        banner_desktop_image_filename: ''
                                                    },
                                                    newLandingDraft: {
                                                        ...newLandingDraft,
                                                        banner_desktop_image: '',
                                                        banner_desktop_image_filename: ''
                                                    }
                                                })
                                                this.props.showToastNotification(intl.formatMessage({ id: 'error_image_file' }), 'error')
                                            }
                                        }}
                                    />
                                )}

                                {labelsInput.banner_desktop_image_filename && (
                                    <React.Fragment>
                                        <label className="landings__file__label">
                                            <Translation id="banner_image_1110" defaultMessage="Banner image (1110px width)" />
                                        </label>
                                        {!this.state.labelsInput.banner_desktop_image_preview && (
                                            <Button
                                                className="landings__file__button landings__file__button--image"
                                                inverted
                                                icon="image"
                                                onClick={() =>
                                                    this.setState({
                                                        showModalImage: !!labelsInput.banner_desktop_image_uri,
                                                        modalImageSrc: labelsInput.banner_desktop_image_uri
                                                    })
                                                }
                                            />
                                        )}
                                        <Button
                                            className="landings__file__button landings__file__button--delete"
                                            inverted
                                            icon="times"
                                            far
                                            onClick={() =>
                                                this.setState({
                                                    labelsInput: {
                                                        ...labelsInput,
                                                        banner_desktop_image_uri: '',
                                                        banner_desktop_image_filename: ''
                                                    },
                                                    newLandingDraft: {
                                                        ...newLandingDraft,
                                                        banner_desktop_image: '',
                                                        banner_desktop_image_filename: ''
                                                    },
                                                    bannerDesktopKey: uuidv4()
                                                })
                                            }
                                        >
                                            {intl.formatMessage({ id: 'delete' })}
                                        </Button>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="col-md-6 landings__file">
                                {!labelsInput.banner_mobile_image_filename && (
                                    <Input
                                        className="landings__file__input"
                                        label={intl.formatMessage({ id: 'banner_image_responsive_510' })}
                                        type="file"
                                        accept="image/jpg,image/jpeg,image/png"
                                        key={bannerMobileKey}
                                        value={newLandingDraft.banner_mobile_image}
                                        onChange={e => {
                                            if (isImageValid(e.target.element.files[0])) {
                                                this.setState({
                                                    labelsInput: {
                                                        ...labelsInput,
                                                        banner_mobile_image_uri: URL.createObjectURL(e.target.element.files[0]),
                                                        banner_mobile_image_filename: e.target.element.files[0].name,
                                                        banner_mobile_image_preview: true
                                                    },
                                                    newLandingDraft: {
                                                        ...newLandingDraft,
                                                        banner_mobile_image: e.target.element.files[0],
                                                        banner_mobile_image_filename: e.target.element.files[0].name
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    labelsInput: {
                                                        ...labelsInput,
                                                        banner_mobile_image_uri: '',
                                                        banner_mobile_image_filename: ''
                                                    },
                                                    newLandingDraft: {
                                                        ...newLandingDraft,
                                                        banner_mobile_image: '',
                                                        banner_mobile_image_filename: ''
                                                    }
                                                })
                                                this.props.showToastNotification(intl.formatMessage({ id: 'error_image_file' }), 'error')
                                            }
                                        }}
                                    />
                                )}

                                {labelsInput.banner_mobile_image_filename && (
                                    <React.Fragment>
                                        <label className="landings__file__label">
                                            <Translation id="banner_image_responsive_510" defaultMessage="Responsive banner image (510px width)" />
                                        </label>
                                        {!this.state.labelsInput.banner_mobile_image_preview && (
                                            <Button
                                                className="landings__file__button landings__file__button--image"
                                                inverted
                                                icon="image"
                                                onClick={() =>
                                                    this.setState({
                                                        showModalImage: !!labelsInput.banner_mobile_image_uri,
                                                        modalImageSrc: labelsInput.banner_mobile_image_uri
                                                    })
                                                }
                                            />
                                        )}
                                        <Button
                                            className="landings__file__button landings__file__button--delete"
                                            inverted
                                            icon="times"
                                            onClick={() =>
                                                this.setState({
                                                    labelsInput: {
                                                        ...labelsInput,
                                                        banner_mobile_image_uri: '',
                                                        banner_mobile_image_filename: ''
                                                    },
                                                    newLandingDraft: {
                                                        ...newLandingDraft,
                                                        banner_mobile_image: '',
                                                        banner_mobile_image_filename: ''
                                                    },
                                                    bannerMobileKey: uuidv4()
                                                })
                                            }
                                        >
                                            {intl.formatMessage({ id: 'delete' })}
                                        </Button>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        {this.state.mode !== CREATION_MODE.NEW_LANGUAGE && this.state.mode !== CREATION_MODE.EDIT_LANGUAGE ? (
                            <div className="row" id="products-row">
                                <div className="col-md-12 landings__filter">
                                    <h4>
                                        <i className="k-icon k-i-list-bulleted"></i>
                                        <Translation id="product_search" defaultMessage="Product search" />
                                    </h4>
                                    <div className="landings__filter__search">
                                        <SearchkitProvider searchkit={skRef.sk} ref={this.ref}>
                                            <SearchBox
                                                readOnly={true}
                                                onBlur={this.hideResults}
                                                searchThrottleTime={1500}
                                                searchOnChange={true}
                                                queryFields={[
                                                    'colorId',
                                                    'colorName',
                                                    'description',
                                                    'brand',
                                                    'color',
                                                    'productId',
                                                    'productName',
                                                    'subtypeName',
                                                    'format',
                                                    'bigAccountsErpCode',
                                                    `translations.${LANG_TO_INDEX[this.props.intl.locale]}.displayName`,
                                                    `translations.${LANG_TO_INDEX[this.props.intl.locale]}.colorName`,
                                                    `translations.${LANG_TO_INDEX[this.props.intl.locale]}.productName`,
                                                    `colorFormulations.colorName`
                                                ]}
                                                queryOptions={{ default_operator: 'AND' }}
                                                placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                            />
                                            <ResetFilters component={this.ResetSearchbox} options={{ query: true, filter: false, pagination: true }} />
                                            {this.state.showResults && (
                                                <div
                                                    className="landings__results"
                                                    onBlur={this.hideResults}
                                                    onMouseOver={e => {
                                                        e.target.focus()
                                                    }}
                                                >
                                                    {!this.state.searchingProducts &&
                                                        (this.state.hits.hits && this.state.hits.hits.hits && this.state.hits.hits.hits.length > 0 ? (
                                                            this.state.hits.hits.hits.map(hit => {

                                                                const centers = hit.inner_hits.byCenter.hits.hits.map(h => h._source.centerId)

                                                                return (
                                                                    <div key={hit._id} className="d-flex justify-content-between align-items-center border-bottom border-dark">
                                                                        <div className="landings__results__item border-0" key={hit._source.productId}>
                                                                            <Button
                                                                                className="landings__results__item__button"
                                                                                icon="plus"
                                                                                far
                                                                                noborder
                                                                                onClick={e => this.tryAddProduct(this.preProccessProduct(hit))}
                                                                            />
                                                                            <div className="landings__results__item__img-wrapper">
                                                                                <img className="landings__results__item__img" src={hit._source.thumb || cameraOff} alt="" title="" />
                                                                                {hit && hit._source && hit._source.outletQuality && hit._source.stock_outlet > 0 && (
                                                                                    <DisplayOutletIndicator outletQuality={hit._source.outletQuality}></DisplayOutletIndicator>
                                                                                )}
                                                                            </div>
                                                                            <div className="landings__results__item__info">
                                                                                <p>
                                                                                    <span>
                                                                                        <ProductDisplayName product={hit._source} />
                                                                                    </span>
                                                                                    <small>{hit._source.productId}</small>
                                                                                </p>
                                                                                <p>{hit._source.brand}</p>
                                                                                {hit._source.thickness && (
                                                                                    <p>
                                                                                        <i className="icon-thickness"></i>
                                                                                        {hit._source.thickness} cm
                                                                                    </p>
                                                                                )}
                                                                                <p>
                                                                                    <i className="icon-finish"></i>
                                                                                    {hit._source.finish}
                                                                                </p>
                                                                                <p>
                                                                                    <b><Translation id="centers" defaultMessage="Centers" />: </b>
                                                                                    {centers.join(', ')}
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        {hit._source.type === 'TABLA' && (hit._source.brand === 'SCALEA' || hit._source.brand === 'SENSA') && (
                                                                            <div className="landings__button p-0"
                                                                                 style={{width: '15%'}}>
                                                                                <Button
                                                                                    size="medium"
                                                                                    onClick={() => this.setState({
                                                                                        showLotsModal:true, lotsSource: {product: this.preProccessProduct(hit), centers}
                                                                                    })}
                                                                                    gentle
                                                                                >
                                                                                    <Translation id="add_lots" defaultMessage="Add lots"/>
                                                                                </Button>
                                                                            </div>
                                                                        )}

                                                                    </div>
                                                                )
                                                            })
                                                        ) : (
                                                            <span>
                                                                <Translation id="no_materials_found" defaultMessage="No materials found according to the search parameters" />
                                                            </span>
                                                        ))}
                                                </div>
                                            )}
                                        </SearchkitProvider>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="row" id="products-row">
                                <div className="col-md-12 landings__filter">
                                    <h4>
                                        <Translation id="products" defaultMessage="Products" />
                                    </h4>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-12 landing__products">
                                {this.state.products.length > 0 ? (
                                    this.state.mode === CREATION_MODE.NEW_LANGUAGE || this.state.mode === CREATION_MODE.EDIT_LANGUAGE ? (
                                        this.state.products.map((p, i) => <this.SortItemUI key={i} dataItem={p} editable={false} intl={intl} />)
                                    ) : (
                                        <Sortable
                                            idField={'key'}
                                            disabledField={'disabled'}
                                            data={this.state.products.map((p, i) => {
                                                p.key = i
                                                return p
                                            })}
                                            itemUI={props => this.SortItemUI({ ...props, intl, editable: true, commingFromMall: true })}
                                            onDragOver={event => {
                                                this.setState({
                                                    products: event.newState
                                                })
                                            }}
                                        />
                                    )
                                ) : (
                                    <span>
                                        <Translation id="no_product_added" defaultMessage="No product has been added" />
                                    </span>
                                )}
                            </div>
                        </div>
                        {this.state.hasErrors && this.state.products.length === 0 && (
                            <span style={{ color: '#f31700' }}>
                                <Translation id="must_select_product" defaultMessage="You must select at least one product" />
                            </span>
                        )}
                        <div className="row">
                            <div className="col-md-2 landings__button">
                                <Button
                                    size="medium"
                                    onClick={() => {
                                        redirect(intl.formatMessage({ id: 'ROUTE_LANDING_MANAGER' }))
                                    }}
                                    gentle
                                >
                                    {intl.formatMessage({ id: 'back' })}
                                </Button>
                            </div>
                            <div className="col-md-1">{/* Spacer block */}</div>
                            <div className="col-md-3 landings__button">
                                {mode === CREATION_MODE.EDIT_LANGUAGE && (
                                    <Button onClick={this.onDeleteLanguage} size="medium" gentle>
                                        <Translation id="delete_translation" defaultMessage="Remove translation" />
                                    </Button>
                                )}
                            </div>
                            <div className="col-md-3 landings__button">
                                {mode !== CREATION_MODE.NEW && (
                                    <Button
                                        size="medium"
                                        gentle
                                        onClick={() => {
                                            redirect(
                                                intl
                                                    .formatMessage({ id: 'ROUTE_LANDING_TEMPLATE' })
                                                    .replace(':slug?', this.props.landing.landing.slug)
                                                    .replace(':iso?', currentIso)
                                                    .replace('/:preview?', '/preview')
                                            )
                                        }}
                                    >
                                        <Translation id="preview" defaultMessage="Preview" />
                                    </Button>
                                )}
                            </div>
                            <div className="col-md-3 landings__button">
                                <Button size="medium" onClick={() => this.onSave(newLandingDraft)}>
                                    {intl.formatMessage({ id: 'save' })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showModal && (
                    <SimpleModal
                        customClass="landings__modal landings__modal--text"
                        title={''}
                        onClose={() => {
                            this.setState({ showModal: false })
                            if (this.state.redirect) {
                                this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_LANDING_MANAGER' }))
                            }
                        }}
                        size="large"
                        footer={null}
                    >
                        <p>{this.state.modalText}</p>
                    </SimpleModal>
                )}

                {this.state.showModalImage && (
                    <SimpleModal
                        customClass="landings__modal landings__modal--image"
                        title={''}
                        onClose={() => {
                            this.setState({ showModalImage: false, modalImageSrc: null })
                        }}
                        size="large"
                        footer={null}
                    >
                        {this.state.modalImageSrc && <img src={this.state.modalImageSrc} />}
                    </SimpleModal>
                )}

                {this.state.showModalAddProduct && (
                    <SimpleModal
                        customClass="landings__modal landings__modal--add-product"
                        title={''}
                        onClose={() => this.setState({ showModalAddProduct: false, lastModalProduct: null })}
                        size="large"
                        footer={null}
                    >
                        <p>
                            <Translation
                                id="product_ambiguous_stock"
                                defaultMessage="The product you are trying to add has normal stock and outlet stock available, which one do you want to use?"
                            />
                        </p>

                        <div className="btn-row">
                            <Button
                                gentle
                                size="medium"
                                onClick={() => {
                                    this.tryAddProduct(this.state.lastModalProduct, false, false, () => this.setState({ showModalAddProduct: false, lastModalProduct: null }))
                                }}
                            >
                                <Translation id="normal" defaultMessage="Normal" />
                            </Button>
                            <Button
                                gentle
                                size="medium"
                                onClick={() => {
                                    this.tryAddProduct(this.state.lastModalProduct, false, true, () => this.setState({ showModalAddProduct: false, lastModalProduct: null }))
                                }}
                            >
                                <Translation id="outlet" defaultMessage="Outlet" />
                            </Button>
                            <Button
                                gentle
                                size="medium"
                                onClick={() => {
                                    this.tryAddProduct(this.state.lastModalProduct, false, false, () => {
                                        this.tryAddProduct(this.state.lastModalProduct, false, true, () => this.setState({ showModalAddProduct: false, lastModalProduct: null }))
                                    })
                                }}
                            >
                                <Translation id="both" defaultMessage="Both" />
                            </Button>
                        </div>
                    </SimpleModal>
                )}

                {this.state.showLotsModal && (
                    <LotsModal centers={this.state.lotsSource.centers}
                               product={this.state.lotsSource.product}
                               dataItem={this.state.lotsSource.dataItem}
                               onClose={() => this.setState({showLotsModal:false})}
                               addGroup={this.addProduct}
                               selectedLots={this.state.selectedLots}
                               selectedBundles={this.state.selectedBundles}
                    />
                )}

                {this.state.showAdditionalInfoModal && (
                    <AdditionalInfoModal
                        onClose={() => this.setState({showAdditionalInfoModal: false, modalItemData: null})}
                        additionalInfo = {this.state.modalItemData.additionalInfo}
                        setAdditionalInfo = {(value) => {
                            this.state.modalItemData.additionalInfo = value
                            this.setState({showAdditionalInfoModal: false, modalItemData: null})
                        }}
                    />
                )}
            </section>
        )
    }
}

export default injectIntl(LandingCreation)
