import React from 'react'

import { connect } from 'react-redux'
import LowesAdminViewSelector from '../components/catalog-lowes/LowesAdminViewSelector'
import { Route, Switch } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import LowesUsers from '../components/catalog-lowes/LowesUsers'
import LowesReports from '../components/catalog-lowes/LowesReports'
import { getUserType } from '../store/login/reducers'
import CatalogLowesAdminContainer from '../components/catalog-lowes/CatalogLowesAdminContainer'
import { getLocalizedUrl } from '../store/ui/reducers'
const mapStateToProps = (state, props) => {
    return {
        userType: getUserType(state),
        localizedUrl: (routeId) => getLocalizedUrl(state, props.intl, routeId)
    }
}
class CatalogLowesAdminScreen extends React.Component {
    render() {
        return (
            <div>
                {/* {this.props.history.location.pathname !== '/catalog-lowes-admin/home' && <LowesAdminViewSelector small />} */}

                <Switch>
                    <Route path={this.props.localizedUrl('ROUTE_CATALOG_LOWES_ADMIN').replace(':filter', 'home')} component={LowesAdminViewSelector} />
                    <Route path={this.props.localizedUrl('ROUTE_CATALOG_LOWES_ADMIN').replace(':filter', 'pog')} component={CatalogLowesAdminContainer} />
                    <Route path={this.props.localizedUrl('ROUTE_CATALOG_LOWES_ADMIN').replace(':filter', 'stores')} component={LowesUsers} />
                    <Route path={this.props.localizedUrl('ROUTE_CATALOG_LOWES_ADMIN').replace(':filter', 'reports')} component={LowesReports} />
                </Switch>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(CatalogLowesAdminScreen)
)
