import * as api from './api'
import {errorHandler} from '../api'
import {getToken} from '../login/reducers'
import {getLanguage} from '../ui/reducers'
import {BrandOptionsMap} from "../../components/cut-to-size/CutToSizeMaps"
import {getSelectedCenter} from '../centers/reducers'
import * as segmentation from '../segmentation/reducers'


export const fetchCutToSizeMainParams = () => (dispatch, getState) => {
    const state = getState()
    dispatch({ type: 'FETCH_CUT_TO_SIZE_MAIN_PARAMS_REQUEST' })
    return  api.fetchCutToSizeMainParams(getLanguage(state), getToken(state), getSelectedCenter(state))
        .then(response => {
            // Ordenar las marcas según la posición en BrandOptionsMap
            response.data && response.data.materials && Object.entries(response.data.materials).forEach(([k, v]) => {
                if (k != 'CUSTOM_MOBILIARIO' && k != 'BUDGET') {
                    response.data.materials[k] = Object.entries(v)
                    .sort(([a],[b]) => BrandOptionsMap[a].position - BrandOptionsMap[b].position)
                    .reduce((r, [key, value]) => ({ ...r, [key]: value }), {});
                }                
            })
            dispatch({ type: 'FETCH_CUT_TO_SIZE_MAIN_PARAMS_SUCCESS', mainParams: response.data })
            dispatch(fetchConfigurableModels())
        })
        .catch (error => {
            dispatch({ type: 'FETCH_CUT_TO_SIZE_MAIN_PARAMS_FAILURE' })
            errorHandler(dispatch, error.response)
        })
}

export const fetchCutToSizeSecondaryParams = (elaboration, brand, color, pricingGroup, hasSoleriaDekton, callback) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_CUT_TO_SIZE_SECONDARY_PARAMS_REQUEST' })
    return  api.fetchCutToSizeSecondaryParams(elaboration, brand, color, pricingGroup, hasSoleriaDekton, getToken(getState()))
        .then(response => {            
            dispatch({ type: 'FETCH_CUT_TO_SIZE_SECONDARY_PARAMS_SUCCESS', secondaryParams: response.data })
            if (callback) {
                callback()
            }
        })
        .catch (error => {
            dispatch({ type: 'FETCH_CUT_TO_SIZE_SECONDARY_PARAMS_FAILURE' })
            errorHandler(dispatch, error.response)
        })
}

export const askForBudget = (formData, callback) => (dispatch, getState) => {
    const state = getState()
    const lang = getLanguage(state)
    const centerId = getSelectedCenter(state)
    formData.append('lang', lang)
    formData.append('centerId', centerId)

    dispatch({ type: 'CUT_TO_SIZE_ASK_FOR_BUDGET_REQUEST' })
    return api.askForBudget(formData, getToken(state))
        .then(response => {            
            dispatch({ type: 'CUT_TO_SIZE_ASK_FOR_BUDGET_SUCCESS' })
            dispatch({ type: 'RESET_SEGMENTATION' })
            if (callback) {
                callback()
            }
        })
        .catch (error => {
            console.log('PRE. err' , error)
            dispatch({ type: 'CUT_TO_SIZE_ASK_FOR_BUDGET_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
            errorHandler(dispatch, error.response)
        })
}

export const requestPreOrder = (formData, callback, segmentationRequested = true) => (dispatch, getState) => {
    const state = getState()

    if (segmentationRequested) {
        if (!segmentation.segmentationDone(state)) {
            dispatch({
                type: 'SHOW_SEGMENTATION_MODAL',
                callback: requestPreOrder(formData, callback, false),
                productId: 'CTS_CUOTATION',
                outlet: false,
                productSupplierCenter: null
            })
            return false
        }
    }

    const lang = getLanguage(state)
    const centerId = getSelectedCenter(state)
    const fullSelectedSegmentation = segmentation.getFullSelectedSegmentation(state)

    formData.append('lang', lang)
    formData.append('centerId', centerId)
    formData.append('segmentation', JSON.stringify(fullSelectedSegmentation))

    dispatch({ type: 'CUT_TO_SIZE_PREORDER_REQUEST' })
    return api.preOrderCts(formData, getToken(state))
        .then(response => {
            dispatch({ type: 'CUT_TO_SIZE_PREORDER_REQUEST_SUCCESS' })
            dispatch({ type: 'RESET_SEGMENTATION' })
            if (callback) {
                callback(response.data)
            }
        })
        .catch (error => {
            console.log('PRE. err' , error)
            dispatch({ type: 'CUT_TO_SIZE_PREORDER_REQUEST_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
            errorHandler(dispatch, error.response)
        })
}

export const downloadPackagingTypesPdf = () => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)
    return api.downloadPackagingTypesPdf(token)
}

export const downloadPreorderFile = (file) => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)
    return api.downloadPreorderFile(token, file)
}

export const fetchPreOrdersCtsPaginate = (startDate, endDate, filterTypeDate, status = null, search,page, callback = null) => (dispatch, getState) => {
   const state = getState()

   dispatch({ type: 'FETCH_PRE_ORDERS_CTS_REQUEST' })
   return api
       .preOrderCtsList(getToken(state),startDate,endDate,filterTypeDate,status,search,page)
       .then(response => {
           // console.log('RESPONSE::' , response)
           const preorderData = response.data.data.map(p => {
               try {
                   p.segmentation = JSON.parse(p.segmentation)
               } catch (e) {
                   p.segmentation = {}
               }
               // p.segmentation = JSON.parse(p.segmentation)
               return p
           })
           dispatch({ type: 'FETCH_PRE_ORDERS_CTS_SUCCESS', data: preorderData, ordersCurrentPage: response.data.current_page, ordersLastPage: response.data.last_page })
           if (callback) {
               callback()
           }
       })
       .catch(error => {
           dispatch({ type: 'FETCH_PRE_ORDERS_CTS_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
           errorHandler(dispatch, error.response)
       })
}
export const fetchPreOrdersProjectAvailables = () => (dispatch,getState) => {
 dispatch({ type: 'FETCH_PRE_ORDERS_CTS_PROJECT_AVAILABLES_REQUEST' })
 return api
     .fetchPreOrdersProjectAvailables(getToken(getState()))
     .then(response => {
         // console.log('RESPONSE::' , response)
         
         dispatch({
             type: 'FETCH_PRE_ORDERS_CTS_PROJECT_AVAILABLES_SUCCESS',
             projects: response.data,
       
         })
     })
     .catch(error => {
         dispatch({ type: 'FETCH_PRE_ORDERS_CTS_PROJECT_AVAILABLES_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
     })
}
export const fetchPreOrdersCts = (callback) => (dispatch, getState) => {
   const state = getState()

   dispatch({ type: 'FETCH_PRE_ORDERS_CTS_REQUEST' })
   return api
       .preOrderCtsList(getToken(state))
       .then(response => {
           const preorderData = response.data.data.map(p => {
               p.isCancelable = p.status === 'TECHNICAL_REVIEW'
               try {
                   p.segmentation = JSON.parse(p.segmentation)
               } catch (e) {
                    p.segmentation = {}
               }
               // p.segmentation = JSON.parse(p.segmentation)
               return p
           })
           dispatch({ type: 'FETCH_PRE_ORDERS_CTS_SUCCESS',data: preorderData})
           if (callback) {
               callback()
           }
       })
       .catch(error => {
           dispatch({ type: 'FETCH_PRE_ORDERS_CTS_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
           errorHandler(dispatch, error.response)
       })
}

export const sendPreorderMessage = (formData, callback) => (dispatch, getState) => {
    const state = getState()
    const lang = getLanguage(state)
    formData.append('lang', lang)
    dispatch({ type: 'CUT_TO_SIZE_SEND_PREORDER_MESSAGE' })
    return api.sendPreorderMessage(formData, getToken(state))
        .then(response => {
            dispatch({
                type: 'CUT_TO_SIZE_SEND_PREORDER_MESSAGE_SUCCESS',
                preorderId: response.data.preorderId,
                communications: response.data.communications,
                files: response.data.files
            })

            if (callback) {
                callback()
            }
        })
        .catch (error => {
            console.log('PRE. err' , error)
            dispatch({ type: 'CUT_TO_SIZE_SEND_PREORDER_MESSAGE_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
            errorHandler(dispatch, error.response)
        })
}

export const sendApprovePreorderMessage = (formData, callback) => (dispatch, getState) => {
    const state = getState()
    const lang = getLanguage(state)
    formData.append('lang', lang)
    dispatch({ type: 'CUT_TO_SIZE_SEND_APPROVE_PREORDER_MESSAGE' })
    return api.sendApprovePreorderMessage(formData, getToken(state))
        .then(response => {
            dispatch({
                type: 'CUT_TO_SIZE_SEND_APPROVE_PREORDER_MESSAGE_SUCCESS',
                preorderId: response.data.preorderId,
                communications: response.data.communications,
                files: response.data.files
            })

            if (callback) {
                callback()
            }
        })
        .catch (error => {
            console.log('PRE. err' , error)
            dispatch({ type: 'CUT_TO_SIZE_SEND_APPROVE_PREORDER_MESSAGE_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
            errorHandler(dispatch, error.response)
        })
}

export const markMessagesAsReaded = (preorderId) => (dispatch, getState) => {
    const state = getState()
    const data = {preorder_id: preorderId}
    dispatch({ type: 'CUT_TO_SIZE_MARK_MESSAGES_AS_READED' })
    return api.markMessages(data, getToken(state))
        .then(response => {
            dispatch({
                type: 'CUT_TO_SIZE_MARK_MESSAGES_AS_READED_SUCCESS'
            })
        })
        .catch (error => {
            console.log('PRE. err' , error)
            dispatch({ type: 'CUT_TO_SIZE_MARK_MESSAGES_AS_READED_FAILURE', payload: { statusText: error.response.data.err, values: error.response.data.info } })
            errorHandler(dispatch, error.response)
        })
}

export const cancelPreorder = (preorderId) => async (dispatch, getState) => {
    const state = getState()
    const data = {preorder_id: preorderId}
    dispatch({ type: 'CUT_TO_SIZE_CANCEL_PREORDER' })

    return api.cancelPreorder(data, getToken(state))
        .then(response => {
            dispatch({type: 'CUT_TO_SIZE_CANCEL_PREORDER_SUCCESS'})
            dispatch(fetchPreOrdersCts())
            return Promise.resolve()
        })
        .catch (error => {
            console.log('PRE. err' , error)
            dispatch({ type: 'CUT_TO_SIZE_CANCEL_PREORDER_FAILURE', message: error.response.data.info })
            errorHandler(dispatch, error.response)
            return Promise.reject(error.response.data.info)
        })
}

// Configurable Bathrooms - START

export const fetchConfigurableModels = () => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)
    const lang = getLanguage(state)

    dispatch({ type: 'FETCH_CONFIGURABLE_BATHS_MODELS_REQUEST' })

    return api.fetchConfigurableModels(lang, token)
        .then((response) => {
            dispatch({ 
                type: 'FETCH_CONFIGURABLE_BATHS_MODELS_SUCCESS',
                configurableModels: response.data
            })
    })
    .catch ((error) => {
        dispatch({ type: 'FETCH_CONFIGURABLE_BATHS_MODELS_FAILURE', message: error.response.data.info })
    })
}

export const addConfigurableInProgress = (config) => (dispatch) => {
    const refcode = config && config.reference
    
    return new Promise((resolve) => {
        dispatch({ 
            type: 'ADD_CONFIGURABLE_IN_PROGRESS', 
            refcode, 
            configData: config 
        })
        
        resolve()
    })
}

export const addConfigurablePlanAcceptanceChecked = (refcode, checked = false) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({ 
            type: 'ADD_CONFIGURABLE_PLAN_ACCEPTANCE_CHECKED', 
            refcode, 
            isPlanAcceptanceChecked: checked 
        })
        
        resolve()
    })
}

export const addConfigurableModifiedList = (refcode, isModified = false) => (dispatch) => {
    dispatch({ 
        type: 'ADD_CONFIGURABLE_MODIFIED_LIST', 
        refcode, 
        isConfigurableModified: isModified 
    })
}

export const fetchConfigurableDrawing = (referenceCode) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_CONFIGURABLE_DRAWING_REQUEST' })

    const token = getToken(getState())

    return api.fetchConfigurableDrawing(referenceCode, token)
        .then((response) => {
            dispatch({
                type: 'FETCH_CONFIGURABLE_DRAWING_SUCCESS',
                configurableDrawing: response.data
            })
            return response
        })
        .catch((error) => {
            dispatch({
                type: 'FETCH_CONFIGURABLE_DRAWING_FAILURE',
                message: error.response.data.info
            });
            throw error
        })
}