import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import ReactTable from 'react-table'
import Button from '../global/Button'

class DeviatedAdmin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
            search: '',
            open: []
        }
    }
    componentDidMount() {
        this.props.fetchProductsDeviatedAdmin().then(() => {
            this.setState({ data: this.props.products })
        })
    }
    onChangeValue(productId, centerId, input, value, lang = { localecode: 'default' }) {
        let data = this.state.data.map(item => {
            if (item.productId === productId && item.centerId === centerId) {
                switch (input) {
                    case 'deviatedDescription':
                        let deviatedDescription = item.deviatedDescription ? item.deviatedDescription : []
                        deviatedDescription[lang.localecode] = value
                        return { ...item, deviatedDescription: { ...item.deviatedDescription } }
                    default:
                        return item
                }
            } else return item
        })
        this.setState({ data: data })
    }
    getValue(productId, centerId, input, lang = { localecode: 'default' }) {
        let product = this.state.data.filter(item => item.productId === productId && item.centerId === centerId)[0]
        switch (input) {
            case 'deviatedDescription':
                return product.deviatedDescription ? product.deviatedDescription[lang.localecode] : ''
            default:
                return 0
        }
    }
    itemReset(item) {
        let data = this.state.data.map(stateItem => {
            if (stateItem.productId === item.productId && stateItem.centerId === item.centerId)
                return this.props.products.filter(product => product.productId === item.productId && product.centerId === item.centerId)[0]
            else return stateItem
        })
        this.setState({ data: data })
    }
    itemChanged(item) {
        return !this.props.products.find(ele => ele.productId === item.productId && ele.centerId === item.centerId && ele.deviatedDescription === item.deviatedDescription)
    }

    saveItems(item = null) {
        if (item !== null) {
            this.props
                .saveProductsDeviatedAdmin(this.state.data.filter(stateItem => stateItem.productId === item.productId && stateItem.centerId === item.centerId))
                .then(() => this.itemReset(item))
        } else {
            this.props.saveProductsDeviatedAdmin(this.state.data.filter(stateItem => this.itemChanged(stateItem))).then(() => this.setState({ data: this.props.products }))
        }
    }
    render() {
        const columns = [
            {
                Header: 'Image',
                accessor: 'image',
                Cell: ({ original }) => (
                    <div
                        key={`img-${original.productId}-${original.centerId}`}
                        style={{
                            height: '55px',
                            width: '80px',
                            background: `url(${original.img})`
                        }}
                    />
                ),
                maxWidth: 80,
                className: 'cell_background-image'
            },
            { Header: 'Id', accessor: 'productId', maxWidth: 75 },
            { Header: 'Product', accessor: 'productName' },
            {
                Header: 'Deviated Description',
                accessor: 'deviatedDescription',
                Cell: ({ original }) => (
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ margin: '5px' }}>
                                <input
                                    type="text"
                                    value={this.getValue(original.productId, original.centerId, 'deviatedDescription')}
                                    key={original.productId + original.centerId + 'default'}
                                    maxLength={60}
                                    onChange={input => this.onChangeValue(original.productId, original.centerId, 'deviatedDescription', input.target.value)}
                                />

                                <div
                                    key={original.productId}
                                    className={this.state.open.includes(original.productId) ? 'rt-expander -open' : 'rt-expander'}
                                    style={{ width: '20px', cursor: 'pointer' }}
                                    onClick={() => {
                                        if (this.state.open.includes(original.productId)) {
                                            this.setState({ open: this.state.open.filter(product => product !== original.productId) })
                                        } else {
                                            this.setState({ open: this.state.open.concat(original.productId) })
                                        }
                                    }}
                                />
                            </div>

                            {this.state.open.includes(original.productId) &&
                                this.props.locales.map(lang => {
                                    return (
                                        <div style={{ margin: '5px' }}>
                                            <input
                                                type="text"
                                                value={this.getValue(original.productId, original.centerId, 'deviatedDescription', lang)}
                                                key={original.productId + original.centerId + lang.localecode}
                                                maxLength={60}
                                                onChange={input => this.onChangeValue(original.productId, original.centerId, 'deviatedDescription', input.target.value, lang)}
                                            />
                                            <span className={`flag-icon flag-icon-${lang.code}`} style={{ borderRadius: '0', margin: '5px', height: '27px' }} />
                                            <span> {lang.name} </span>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                ),
                maxWidth: 350
            },

            {
                Header: 'Actions',
                Cell: ({ original }) => (
                    <div className="group_buttons_row_table">
                        <Button size={'small'} disabled={!this.itemChanged(original)} onClick={() => this.itemReset(original)}>
                            <i className="fa fa-undo" />
                        </Button>
                        <Button size={'small'} disabled={!this.itemChanged(original)} onClick={() => this.saveItems(original)}>
                            <i className="fa fa-save" />
                        </Button>
                    </div>
                ),
                maxWidth: 100,
                sortable: false
            }
        ]
        let dataFiltered = this.state.data
        if (this.state.search) {
            dataFiltered = this.state.data.filter(
                item =>
                    item.productId.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    item.productName.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    item.centerId.toLowerCase().includes(this.state.search.toLowerCase())
            )
        }
        return (
            <div className="mkt_products_admin">
                <div className="container mkt_products_admin__container">
                    <div className="toolbar">
                        <div className="toolbar__search search">
                            <div className="search-bar">
                                <input
                                    type="text"
                                    data-qa="query"
                                    className="search-box form-control"
                                    placeholder="Search"
                                    value={this.state.search}
                                    onChange={e =>
                                        this.setState({
                                            search: e.target.value
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="toolbar__filters filters">
                            <div>
                                <Button onClick={() => this.setState({ data: this.props.products })}>
                                    <i className="fa fa-undo" /> Cancel
                                </Button>
                            </div>
                            <div>
                                <Button inverted onClick={() => this.saveItems()}>
                                    <i className="fa fa-save" /> Save all
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <ReactTable
                            data={dataFiltered}
                            columns={columns}
                            defaultPageSize={50}
                            pageSizeOptions={[50, 100, 200]}
                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                            nextText={<Translation id="next" defaultMessage="Next" />}
                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                            pageText={<Translation id="page" defaultMessage="Page" />}
                            ofText={<Translation id="of" defaultMessage="Of" />}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(DeviatedAdmin)
