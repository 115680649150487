import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import AlertsContainer from '../alerts/AlertsContainer'

class MandatoryChangePasswordForm extends React.Component {
    handleSubmit = e => {
        e.preventDefault()
        this.props.onMandatoryChangePassword(this.passwordInput.value, this.passwordRepeatInput.value)
    }

    render() {
        return (
            <div className="form-signin">
                <form onSubmit={this.handleSubmit}>
                    <AlertsContainer />

                    <div className="text-white py-3">
                        <b>
                            <Translation id="your_new_password_must_meet" defaultMessage="Your new password must meet the following requirements:" />
                        </b>
                        <ul>
                            <li>
                                <Translation id="password_not_number" defaultMessage="At least one numeral" />
                            </li>
                            <li>
                                <Translation id="password_not_minus" defaultMessage="At least one lower case letter" />
                            </li>
                            <li>
                                <Translation id="password_not_mayus" defaultMessage="At least one upper case letter" />
                            </li>
                            <li>
                                <Translation id="password_should_contain" defaultMessage="Minimum 8 characters" />
                            </li>
                        </ul>
                    </div>

                    <label htmlFor="email">
                        <Translation id="password" defaultMessage="Password" />
                    </label>
                    <input
                        type="password"
                        id="inputPassword"
                        className="form-control"
                        placeholder={this.props.intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}
                        ref={node => {
                            this.passwordInput = node
                        }}
                    />
                    <label htmlFor="email">
                        <Translation id="repeat_password" defaultMessage="Repeat Password" />
                    </label>
                    <input
                        type="password"
                        id="inputRepeatPassword"
                        className="form-control"
                        placeholder={this.props.intl.formatMessage({ id: 'repeat_password', defaultMessage: 'Repeat Password' })}
                        ref={node => {
                            this.passwordRepeatInput = node
                        }}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.handleSubmit(e)
                            }
                        }}
                    />
                    <br />
                    <input
                        type="submit"
                        className="bt bt-inverted loginbutton"
                        value={this.props.intl.formatMessage({ id: 'change_password', defaultMessage: 'Change Password' })}
                    />
                </form>
            </div>
        )
    }
}
export default injectIntl(MandatoryChangePasswordForm)
