import React from 'react'

const ProgressBarWeights = ({
    startPoint,
    endPoint,
    middlePoint,
    currentPoint,
    unit,
    startPointText,
    endPointText,
    middlePointText,
    startPointUnits = true,
    endPointUnits = true,
    languageDir,
    numberFormat
}) => {
    const percentageCurrentNumber = (currentPoint - startPoint) / (endPoint - startPoint) * 100;
    const percentageCurrentString = percentageCurrentNumber > 100 ? 100 : percentageCurrentNumber.toFixed(2)
    const percentageMiddle = middlePoint ? ((middlePoint - startPoint) / (endPoint - startPoint) * 100).toFixed(2) : 0
    const middlePointPositionStyle = languageDir === 'rtl' ?
        {
            right: percentageMiddle + '%'
        } : {
            left: percentageMiddle + '%'
        }

    const formattedStartPoint = startPointUnits ?
        numberFormat.format(startPoint) + ' ' + unit :
        numberFormat.format(startPoint);
    const formattedEndPoint = endPointUnits ?
        numberFormat.format(endPoint) + ' ' + unit :
        numberFormat.format(endPoint);
    const formattedCurrentPoint = numberFormat.format(currentPoint) + ' ' + unit;
    const formattedMiddlePoint = numberFormat.format(middlePoint ? middlePoint : 0) + ' ' + unit;

    return (
        <div className="progress-bar-weights__container">
            <div className="d-flex align-items-center">
                <div className="progress-bar-weights__label">
                    <p>{formattedStartPoint}</p>
                    {startPointText && <p>({startPointText})</p>}
                </div>
                <div className="progress-bar-weights">
                    {!!middlePoint && (
                        <div className="middle" style={middlePointPositionStyle}>
                            <div className="progress-bar-weights__label" >
                                {middlePointText && <p>{middlePointText}</p>}
                                {middlePointText ? <p>({formattedMiddlePoint})</p> : <p>{formattedMiddlePoint}</p>}
                            </div>
                        </div>
                    )}
                    <div className="progress-bar-weights__bar" style={{width: 'calc(' + percentageCurrentString + '% + 3px)'}}>
                        <div className="progress-bar-weights__ball">
                            <div className="progress-bar-weights__current">
                                {formattedCurrentPoint}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="progress-bar-weights__label">
                    <p>{formattedEndPoint}</p>
                    {endPointText && <p className="progress-bar-weights__label-small">({endPointText})</p>}
                </div>
            </div>
        </div>
    )
}

export default ProgressBarWeights;
