export const PromotionRocketCustomersDataUSA5Percent = [
    '0000417087',
    '0000410217',
    '0000422707',
    '0000424165',
    '0000415999',
    '0000411951',
    '0000417999',
    '0000418241',
    '0000417057',
    '0000423091',
    '0000427320',
    '0000426426',
    '0000436356',
    '0000421884',
    '0000409176',
    '0000430145',
    '0000409469',
    '0000425563',
    '0000432640',
    '0000434340',
    '0000427202',
    '0000409813',
    '0000411813',
    '0000409880',
    '0000428360',
    '0000428564',
    '0000425394',
    '0000432635',
    '0000414401',
    '0000410427',
    '0000410482',
    '0000415001',
    '0000420105',
    '0000427100',
    '0000430100',
    '0000415410',
    '0000425931',
    '0000410982',
    '0000415608',
    '0000411231',
    '0000411307',
    '0000411385',
    '0000431597',
    '0000413523',
    '0000431865',
    '0000415886',
    '0000428062',
    '0000424316',
    '0000431074',
    '0000430329',
    '0000412310',
    '0000416328',
    '0000429719',
    '0000413578',
    '0000412583',
    '0000428164',
    '0000412605',
    '0000414038',
    '0000428158',
    '0000412837',
    '0000432129',
    '0000412879',
    '0000418696',
    '0000418743',
    '0000413591',
    '0000408897',
    '0000415478',
    '0000427512',
    '0000420637',
    '0000409528',
    '0000422111',
    '0000409893',
    '0000410015',
    '0000413281',
    '0000428089',
    '0000410372',
    '0000410417',
    '0000414794',
    '0000428179',
    '0000436256',
    '0000426168',
    '0000420913',
    '0000423977',
    '0000413814',
    '0000426073',
    '0000418948',
    '0000424496',
    '0000421266',
    '0000427890',
    '0000416469',
    '0000432402',
    '0000412148',
    '0000416478',
    '0000417891',
    '0000423262',
    '0000414658',
    '0000426219',
    '0000412945',
    '0000414445',
    '0000423639',
    '0000426833',
    '0000432969',
    '0000428387',
    '0000408754',
    '0000426028',
    '0000430087',
    '0000409196',
    '0000409298',
    '0000424187',
    '0000409565',
    '0000426751',
    '0000430188',
    '0000409686',
    '0000413790',
    '0000415920',
    '0000409979',
    '0000424776',
    '0000427188',
    '0000410355',
    '0000410359',
    '0000416857',
    '0000410474',
    '0000417488',
    '0000423946',
    '0000410727',
    '0000410781',
    '0000430542',
    '0000431232',
    '0000430896',
    '0000425946',
    '0000429190',
    '0000425569',
    '0000411095',
    '0000436522',
    '0000411364',
    '0000411404',
    '0000411619',
    '0000409175',
    '0000428525',
    '0000411978',
    '0000411991',
    '0000426779',
    '0000423120',
    '0000413340',
    '0000412159',
    '0000412196',
    '0000412300',
    '0000412470',
    '0000412512',
    '0000430810',
    '0000412543',
    '0000412954',
    '0000421969',
    '0000412763',
    '0000412768',
    '0000416739',
    '0000430159',
    '0000428284',
    '0000418294',
    '0000418902',
    '0000420452',
    '0000418253',
    '0000418299',
    '0000422155',
    '0000420170',
    '0000418215',
    '0000428190',
    '0000418344',
    '0000420511',
    '0000423146',
    '0000418365',
    '0000420300',
    '0000420118',
    '0000418987',
    '0000428417',
    '0000414735',
    '0000428661',
    '0000423375',
    '0000423887',
    '0000410251',
    '0000410755',
    '0000413582',
    '0000421768',
    '0000414759',
    '0000411687',
    '0000433589',
    '0000417204',
    '0000414795',
    '0000423690',
    '0000412894',
    '0000431250',
    '0000429217',
    '0000417317',
    '0000409254',
    '0000409662',
    '0000410188',
    '0000416280',
    '0000410444',
    '0000410447',
    '0000417191',
    '0000414001',
    '0000410792',
    '0000432275',
    '0000411056',
    '0000432267',
    '0000409664',
    '0000411276',
    '0000411340',
    '0000415110',
    '0000434449',
    '0000412341',
    '0000412452',
    '0000417149',
    '0000411294',
    '0000419287',
    '0000423997',
    '0000424475',
    '0000414189',
    '0000414898',
    '0000415737',
    '0000420047',
    '0000420813',
    '0000412357',
    '0000415672',
    '0000414301',
    '0000418984',
    '0000421760',
    '0000411504',
    '0000428479',
    '0000409105',
    '0000420836',
    '0000416641',
    '0000431868',
    '0000409513',
    '0000409754',
    '0000414969',
    '0000417237',
    '0000423800',
    '0000410305',
    '0000428122',
    '0000410341',
    '0000424992',
    '0000410428',
    '0000410445',
    '0000410452',
    '0000410563',
    '0000421897',
    '0000410688',
    '0000410698',
    '0000410800',
    '0000431387',
    '0000434280',
    '0000429654',
    '0000420080',
    '0000433063',
    '0000411359',
    '0000411413',
    '0000411434',
    '0000411474',
    '0000411476',
    '0000411531',
    '0000411626',
    '0000428664',
    '0000422067',
    '0000411904',
    '0000421766',
    '0000431990',
    '0000411955',
    '0000420834',
    '0000412252',
    '0000427772',
    '0000413915',
    '0000412514',
    '0000430101',
    '0000430398',
    '0000412750',
    '0000412812',
    '0000414830',
    '0000428519',
    '0000408833',
    '0000434285',
    '0000409236',
    '0000409332',
    '0000435216',
    '0000429466',
    '0000426523',
    '0000409600',
    '0000433861',
    '0000409805',
    '0000417901',
    '0000409818',
    '0000417993',
    '0000410027',
    '0000423884',
    '0000410218',
    '0000431261',
    '0000431706',
    '0000429752',
    '0000426895',
    '0000424309',
    '0000410908',
    '0000423579',
    '0000411148',
    '0000411218',
    '0000413660',
    '0000421097',
    '0000417742',
    '0000414373',
    '0000430475',
    '0000417863',
    '0000418903',
    '0000414924',
    '0000412172',
    '0000417864',
    '0000412242',
    '0000428681',
    '0000412365',
    '0000412414',
    '0000417880',
    '0000412669',
    '0000412737',
    '0000428779',
    '0000412784',
    '0000412799',
    '0000417369',
    '0000423485',
    '0000435987',
    '0000424694',
    '0000412564',
    '0000421702',
    '0000419203',
    '0000419214',
    '0000419221',
    '0000419259',
    '0000419261',
    '0000419263',
    '0000419304',
    '0000419318',
    '0000421862',
    '0000419330',
    '0000419339',
    '0000433132',
    '0000431920',
    '0000424806',
    '0000433501',
    '0000430894',
    '0000419378',
    '0000427116',
    '0000419391',
    '0000419412',
    '0000419439',
    '0000419450',
    '0000432309',
    '0000420800',
    '0000419459',
    '0000429922',
    '0000427033',
    '0000436265',
    '0000419492',
    '0000419495',
    '0000419498',
    '0000419506',
    '0000419531',
    '0000422897',
    '0000419535',
    '0000408771',
    '0000435400',
    '0000424136',
    '0000408951',
    '0000426331',
    '0000408996',
    '0000427611',
    '0000418756',
    '0000428328',
    '0000409075',
    '0000426720',
    '0000430779',
    '0000409138',
    '0000422621',
    '0000432499',
    '0000409197',
    '0000415867',
    '0000409323',
    '0000425945',
    '0000415437',
    '0000409491',
    '0000409516',
    '0000423929',
    '0000426049',
    '0000434215',
    '0000409936',
    '0000409962',
    '0000414194',
    '0000410159',
    '0000410152',
    '0000413700',
    '0000425703',
    '0000410293',
    '0000428922',
    '0000418184',
    '0000408980',
    '0000410435',
    '0000428554',
    '0000414165',
    '0000419116',
    '0000422982',
    '0000424509',
    '0000424762',
    '0000430134',
    '0000416065',
    '0000434786',
    '0000415564',
    '0000433164',
    '0000410912',
    '0000426425',
    '0000421277',
    '0000428524',
    '0000427291',
    '0000433127',
    '0000414574',
    '0000411117',
    '0000411238',
    '0000411261',
    '0000436510',
    '0000422334',
    '0000430857',
    '0000411511',
    '0000411537',
    '0000430742',
    '0000430650',
    '0000417240',
    '0000417499',
    '0000435123',
    '0000431178',
    '0000414209',
    '0000424004',
    '0000427471',
    '0000430519',
    '0000435492',
    '0000411963',
    '0000433054',
    '0000412033',
    '0000435877',
    '0000425975',
    '0000412154',
    '0000412194',
    '0000422320',
    '0000434027',
    '0000429601',
    '0000415256',
    '0000412353',
    '0000414540',
    '0000414710',
    '0000423085',
    '0000412486',
    '0000412433',
    '0000427072',
    '0000418421',
    '0000415820',
    '0000424197',
    '0000412777',
    '0000412860',
    '0000412875',
    '0000412898',
    '0000432526',
    '0000431424',
    '0000436404',
    '0000413049',
    '0000422853',
    '0000413054',
    '0000415208',
    '0000420335',
    '0000408967',
    '0000427959',
    '0000409269',
    '0000420910',
    '0000409333',
    '0000431993',
    '0000409434',
    '0000420801',
    '0000414516',
    '0000432489',
    '0000436663',
    '0000429211',
    '0000409672',
    '0000420525',
    '0000435991',
    '0000413303',
    '0000424805',
    '0000435956',
    '0000424152',
    '0000431371',
    '0000423993',
    '0000410647',
    '0000410674',
    '0000416807',
    '0000422746',
    '0000424804',
    '0000423655',
    '0000426199',
    '0000414515',
    '0000413680',
    '0000417658',
    '0000411083',
    '0000431175',
    '0000434196',
    '0000432146',
    '0000411297',
    '0000428091',
    '0000417426',
    '0000411480',
    '0000434550',
    '0000416783',
    '0000421065',
    '0000411868',
    '0000424656',
    '0000415460',
    '0000424591',
    '0000431676',
    '0000417192',
    '0000412222',
    '0000429675',
    '0000436582',
    '0000422640',
    '0000434856',
    '0000430903',
    '0000416558',
    '0000424555',
    '0000425134',
    '0000412975',
    '0000434421',
    '0000409262',
    '0000415269',
    '0000409510',
    '0000409793',
    '0000431737',
    '0000412897',
    '0000411050',
    '0000425928',
    '0000412150',
    '0000425535',
    '0000417116',
    '0000410235',
    '0000410863',
    '0000417344',
    '0000416983',
    '0000415903',
    '0000428067',
    '0000415955',
    '0000425679',
    '0000415603',
    '0000409589',
    '0000432980',
    '0000414858',
    '0000415403',
    '0000418398',
    '0000430220',
    '0000428278',
    '0000416453',
    '0000422712',
    '0000414747',
    '0000417996',
    '0000419070',
    '0000434916',
    '0000421881',
    '0000430481',
    '0000416748',
    '0000415784',
    '0000416528',
    '0000414925',
    '0000415591',
    '0000415277',
    '0000414246',
    '0000414633',
    '0000415121',
    '0000428915',
    '0000415464',
    '0000418434',
    '0000420964',
    '0000414567',
    '0000429503',
    '0000416401',
    '0000424998',
    '0000415565',
    '0000414352',
    '0000416056',
    '0000421238',
    '0000418867',
    '0000432636',
    '0000409263',
    '0000410189',
    '0000410298',
    '0000410398',
    '0000410407',
    '0000435485',
    '0000429624',
    '0000410832',
    '0000410837',
    '0000432584',
    '0000411373',
    '0000414035',
    '0000411458',
    '0000411485',
    '0000411623',
    '0000411677',
    '0000115488',
    '0000412139',
    '0000417226',
    '0000412170',
    '0000424143',
    '0000412740',
    '0000426509',
    '0000424215',
    '0000417295',
    '0000432043',
    '0000434103',
    '0000416010',
    '0000417680',
    '0000415240',
    '0000427250',
    '0000423376',
    '0000434967',
    '0000415202',
    '0000415401',
    '0000417080',
    '0000435120',
    '0000428310',
    '0000432520',
    '0000424054',
    '0000413646',
    '0000418595',
    '0000434886',
    '0000424796',
    '0000413903',
    '0000424620',
    '0000416670',
    '0000418315',
    '0000415970',
    '0000412373',
    '0000431181',
    '0000408814',
    '0000416374',
    '0000426505',
    '0000409185',
    '0000422135',
    '0000409725',
    '0000410457',
    '0000415229',
    '0000410673',
    '0000428409',
    '0000411026',
    '0000411189',
    '0000411342',
    '0000423630',
    '0000436826',
    '0000412388',
    '0000412391',
    '0000412678',
    '0000412681',
    '0000425172',
    '0000416696',
    '0000409531',
    '0000429861',
    '0000409994',
    '0000426968',
    '0000422817',
    '0000429513',
    '0000420236',
    '0000425963',
    '0000410533',
    '0000410555',
    '0000410572',
    '0000410574',
    '0000426042',
    '0000426144',
    '0000413341',
    '0000416644',
    '0000416326',
    '0000414981',
    '0000412893',
    '0000424711',
    '0000431352',
    '0000422639',
    '0000422771',
    '0000423580',
    '0000422993',
    '0000413801',
    '0000423525',
    '0000414348',
    '0000422856',
    '0000432394',
    '0000417272',
    '0000424910',
    '0000431301',
    '0000410968',
    '0000428552',
    '0000426126',
    '0000422708',
    '0000413800',
    '0000427138',
    '0000424173',
    '0000427576',
    '0000422886',
    '0000412377',
    '0000422207',
    '0000421744',
    '0000433238',
    '0000413008',
    '0000424394',
    '0000414362',
    '0000426912',
    '0000409093',
    '0000424130',
    '0000425474',
    '0000414010',
    '0000422328',
    '0000424393',
    '0000414741',
    '0000420727',
    '0000415905',
    '0000421258',
    '0000421635',
    '0000423508',
    '0000424510',
    '0000423618',
    '0000416181',
    '0000420803',
    '0000423765',
    '0000421054',
    '0000423516',
    '0000415838',
    '0000424604',
    '0000428105',
    '0000413361',
    '0000421293',
    '0000434462',
    '0000425322',
    '0000425927',
    '0000413590',
    '0000414576',
    '0000428041',
    '0000417254',
    '0000416939',
    '0000420905',
    '0000423799',
    '0000413265',
    '0000421306',
    '0000421357',
    '0000415492',
    '0000418038',
    '0000434398',
    '0000420296',
    '0000429313',
    '0000424109',
    '0000426749',
    '0000420757',
    '0000429489',
    '0000417887',
    '0000415104',
    '0000430121',
    '0000411118',
    '0000411550',
    '0000430454',
    '0000434907',
    '0000429981',
    '0000427447',
    '0000434846',
    '0000418665',
    '0000430070',
    '0000415063',
    '0000417701',
    '0000423409',
    '0000423416',
    '0000412790',
    '0000421890',
    '0000409097',
    '0000422911',
    '0000409714',
    '0000410216',
    '0000430261',
    '0000428124',
    '0000410654',
    '0000410986',
    '0000414358',
    '0000427957',
    '0000412490',
    '0000412796',
    '0000413821',
    '0000415451',
    '0000416445',
    '0000429360',
    '0000415753',
    '0000409074',
    '0000430271',
    '0000435266',
    '0000409179',
    '0000430917',
    '0000430618',
    '0000417729',
    '0000409508',
    '0000428764',
    '0000415982',
    '0000431273',
    '0000428948',
    '0000409890',
    '0000429777',
    '0000420277',
    '0000431262',
    '0000410418',
    '0000415409',
    '0000430515',
    '0000430293',
    '0000413829',
    '0000418210',
    '0000434290',
    '0000436403',
    '0000414700',
    '0000411348',
    '0000411363',
    '0000423727',
    '0000423816',
    '0000427364',
    '0000428718',
    '0000426619',
    '0000418614',
    '0000411730',
    '0000411873',
    '0000112661',
    '0000416578',
    '0000427566',
    '0000413820',
    '0000412491',
    '0000427552',
    '0000431265',
    '0000435895',
    '0000420642',
    '0000425576',
    '0000428201',
    '0000418480',
    '0000430457',
    '0000419736',
    '0000429901',
    '0000431700',
    '0000431806',
    '0000418569',
    '0000419855',
    '0000434914',
    '0000418577',
    '0000419989',
    '0000428386',
    '0000413677',
    '0000431141',
    '0000420652',
    '0000422615',
    '0000427750',
    '0000421870',
    '0000417970',
    '0000429591',
    '0000415065',
    '0000415939',
    '0000415347',
    '0000413354',
    '0000416163',
    '0000414259',
    '0000414738',
    '0000413956',
    '0000415732',
    '0000414836',
    '0000414500',
    '0000431171',
    '0000424622',
    '0000435776',
    '0000431956',
    '0000413547',
    '0000414848',
    '0000413967',
    '0000426382',
    '0000417058',
    '0000417220',
    '0000409056',
    '0000414959',
    '0000408894',
    '0000409543',
    '0000415446',
    '0000416161',
    '0000423179',
    '0000423435',
    '0000431866',
    '0000429415',
    '0000410897',
    '0000415179',
    '0000418419',
    '0000411127',
    '0000425717',
    '0000411593',
    '0000411611',
    '0000411918',
    '0000412048',
    '0000417123',
    '0000415264',
    '0000417363',
    '0000433752',
    '0000418006',
    '0000408926',
    '0000428472',
    '0000419231',
    '0000419270',
    '0000418052',
    '0000428945',
    '0000420359',
    '0000431018',
    '0000418305',
    '0000430347',
    '0000423934',
    '0000422147',
    '0000422133',
    '0000432812',
    '0000426488',
    '0000419517',
    '0000426233',
    '0000408944',
    '0000413845',
    '0000409776',
    '0000423372',
    '0000415731',
    '0000414705',
    '0000417234',
    '0000411288',
    '0000414192',
    '0000417677',
    '0000419129',
    '0000432169',
    '0000411786',
    '0000420883',
    '0000427378',
    '0000412950',
    '0000418467',
    '0000418486',
    '0000418515',
    '0000418497',
    '0000429774',
    '0000418565',
    '0000429797',
    '0000430464',
    '0000409410',
    '0000412971',
    '0000409583',
    '0000422715',
    '0000424876',
    '0000414577',
    '0000415140',
    '0000415704',
    '0000429475',
    '0000417651',
    '0000427695',
    '0000417625',
    '0000412710',
    '0000423076',
    '0000419134',
    '0000413684',
    '0000418030',
    '0000416756',
    '0000416460',
    '0000413391',
    '0000426205',
    '0000417275',
    '0000431192',
    '0000428463',
    '0000414867',
    '0000417877',
    '0000428985',
    '0000415000',
    '0000414743',
    '0000417961',
    '0000418230',
    '0000417580',
    '0000414215',
    '0000414718',
    '0000418397',
    '0000409080',
    '0000420382',
    '0000426229',
    '0000413463',
    '0000410998',
    '0000414072',
    '0000425325',
    '0000415862',
    '0000416792',
    '0000411944',
    '0000427623',
    '0000414338',
    '0000412200',
    '0000432585',
    '0000428206',
    '0000409109',
    '0000415306',
    '0000414951',
    '0000409504',
    '0000431773',
    '0000430153',
    '0000414985',
    '0000417972',
    '0000431898',
    '0000432689',
    '0000413953',
    '0000430540',
    '0000416096',
    '0000416266',
    '0000417983',
    '0000427545',
    '0000409460',
    '0000431058',
    '0000410661',
    '0000427006',
    '0000422780',
    '0000414646',
    '0000428341',
    '0000427510',
    '0000431485',
    '0000414536',
    '0000423427',
    '0000430117',
    '0000416426',
    '0000421287',
    '0000413626',
    '0000412528',
    '0000420084',
    '0000432564',
    '0000412892',
    '0000413895',
    '0000408885',
    '0000409046',
    '0000409102',
    '0000431079',
    '0000409763',
    '0000412187',
    '0000431174',
    '0000410404',
    '0000424898',
    '0000416866',
    '0000410788',
    '0000414984',
    '0000423810',
    '0000411421',
    '0000411804',
    '0000429720',
    '0000411988',
    '0000431264',
    '0000412492',
    '0000412800',
    '0000412877',
    '0000412937',
    '0000412982',
    '0000432796',
    '0000428214',
    '0000409208',
    '0000424945',
    '0000409261',
    '0000432909',
    '0000436878',
    '0000435683',
    '0000409899',
    '0000438130',
    '0000410115',
    '0000410181',
    '0000422316',
    '0000410421',
    '0000436636',
    '0000410754',
    '0000411123',
    '0000416121',
    '0000411372',
    '0000411441',
    '0000425122',
    '0000414383',
    '0000425351',
    '0000411881',
    '0000411930',
    '0000432366',
    '0000428639',
    '0000435947',
    '0000412425',
    '0000412537',
    '0000412577',
    '0000412845',
    '0000437927',
    '0000413452',
    '0000426039',
    '0000410228',
    '0000411170',
    '0000411401',
    '0000434169',
    '0000412231',
    '0000412325',
    '0000424031',
    '0000432579',
    '0000416703',
    '0000424756',
    '0000428078',
    '0000413995',
    '0000416127',
    '0000415203',
    '0000416150',
    '0000413795',
    '0000416286',
    '0000420985',
    '0000430769',
    '0000408801',
    '0000427334',
    '0000434828',
    '0000423596',
    '0000414475',
    '0000409566',
    '0000409817',
    '0000409913',
    '0000409932',
    '0000413835',
    '0000420291',
    '0000429306',
    '0000422880',
    '0000410559',
    '0000414405',
    '0000424868',
    '0000411469',
    '0000427630',
    '0000422061',
    '0000428204',
    '0000427751',
    '0000428888',
    '0000429103',
    '0000426499',
    '0000428907',
    '0000424095',
    '0000428642',
    '0000409872',
    '0000415241',
    '0000413467',
    '0000416607',
    '0000419554',
    '0000423641',
    '0000419617',
    '0000424400',
    '0000425642',
    '0000419657',
    '0000419669',
    '0000419673',
    '0000419675',
    '0000430698',
    '0000419703',
    '0000419708',
    '0000419733',
    '0000421957',
    '0000432424',
    '0000428725',
    '0000419788',
    '0000419810',
    '0000419816',
    '0000419871',
    '0000116576',
    '0000419918',
    '0000427457',
    '0000420451',
    '0000419607',
    '0000419654',
    '0000419663',
    '0000430860',
    '0000426241',
    '0000431283',
    '0000422175',
    '0000419982',
    '0000432080',
    '0000430225',
    '0000423405',
    '0000423512',
    '0000423791',
    '0000420120',
    '0000423301',
    '0000426963',
    '0000432596',
    '0000431703',
    '0000431335',
    '0000436649',
    '0000430037',
    '0000436886',
    '0000430459',
    '0000420771',
    '0000430360',
    '0000436753',
    '0000424107',
    '0000431419',
    '0000434410',
    '0000424927',
    '0000414935',
    '0000413100',
    '0000433972',
    '0000413549',
    '0000426616',
    '0000413106',
    '0000417591',
    '0000413125',
    '0000413138',
    '0000435432',
    '0000432880',
    '0000415339',
    '0000413636',
    '0000417693',
    '0000422731',
    '0000428143',
    '0000429800',
    '0000426617',
    '0000427223',
    '0000417095',
    '0000413170',
    '0000413184',
    '0000413192',
    '0000413321',
    '0000413454',
    '0000431955',
    '0000432867',
    '0000413222',
    '0000428326',
    '0000423766',
    '0000420351',
    '0000418207',
    '0000418752',
    '0000432200',
    '0000430549',
]
