import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import LowesReports from '../catalog-lowes/LowesReports'

class LowesOrders extends React.Component {
    search = e => {
        this.setState({ search: e.target.value })
    }
    changeStartDate = date => {
        this.setState({ startDate: date })
    }

    changeEndDate = date => {
        this.setState({ endDate: date }, () => this.props.fetchOrders(this.props.type, this.state.startDate, this.state.endDate))
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="toolbar">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 order_header">
                                        <div className="group_title">
                                            <h4>
                                                {/* {!this.props.type && <Translation id="orders_history" defaultMessage="Orders history" />} */}
                                                {this.props.type === 'open' && <Translation id="open_orders" defaultMessage="Open orders" />}
                                                {this.props.type === 'transfer' && <Translation id="transfer_orders" defaultMessage="Transfer orders" />}
                                            </h4>
                                        </div>
                                        <LowesReports />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(LowesOrders)
