import React from 'react'
import Translation from '../../../global/Translation'

import ReactTable from 'react-table'
import { getIsLoading, getBackOrders } from '../../../../store/kpis/reducers'
import { connect } from 'react-redux'
import Spinner from '../../../global/Spinner'
import { fetchBackOrders } from '../../../../store/kpis/actions'
import GoBack from '../../../global/GoBack'
const mapStateToProps = state => {
    return {
        backOrders: getBackOrders(state),
        isLoading: getIsLoading(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchBackOrders: page => {
            dispatch(fetchBackOrders(page))
        }
    }
}
class BackOrders extends React.Component {
    componentDidMount() {
        this.props.fetchBackOrders()
    }
    render() {
        const { isLoading, backOrders } = this.props
        if (!backOrders) return <Spinner isLoading={isLoading} />
        return (
            <div>
                <GoBack />
                <h2>
                    <Translation id="back_orders" defaultMessage="Back orders" />
                </h2>

                <ReactTable
                    data={backOrders.data}
                    columns={[
                        { Header: <Translation id="order_id" defaultMessage="Order ID" />, accessor: 'orderId' },
                        { Header: <Translation id="order_type" defaultMessage="Order Type" />, accessor: 'orderType' },
                        { Header: <Translation id="position" defaultMessage="Position" />, accessor: 'position' },
                        { Header: <Translation id="priority" defaultMessage="Priority" />, accessor: 'priority' },
                        { Header: <Translation id="prefered_delivery_date" defaultMessage="Preferred delivery date" />, accessor: 'preferredDeliveryDate' },
                        { Header: <Translation id="order_date" defaultMessage="Order date" />, accessor: 'createdAt' },
                        { Header: <Translation id="quantity" defaultMessage="Quantity" />, id: 'qty', accessor: 'qty', className: 'text-right' },
                        {
                            Header: <Translation id="pending_quantity" defaultMessage="Pending Quantity" />,
                            id: 'pendingQty',
                            accessor: 'pendingQty',
                            className: 'text-right'
                        },
                        { Header: <Translation id="price" defaultMessage="Price" />, id: 'price', accessor: 'price', className: 'text-right' },
                        { Header: <Translation id="type_of_sale" defaultMessage="Type of sale" />, accessor: 'typeOfSale' },
                        { Header: <Translation id="channel" defaultMessage="Channel" />, accessor: 'channel' },
                        { Header: <Translation id="use" defaultMessage="Use" />, accessor: 'use' },
                        { Header: <Translation id="center_id" defaultMessage="Center ID" />, accessor: 'centerId' },
                        { Header: <Translation id="client_id" defaultMessage="Client ID" />, accessor: 'customerId' },
                        { Header: <Translation id="client" defaultMessage="Client" />, accessor: 'customerName' },
                        { Header: <Translation id="productId" defaultMessage="Product ID" />, accessor: 'productId' },
                        { Header: <Translation id="product" defaultMessage="Product" />, accessor: 'productDescription' }
                    ]}
                    defaultPageSize={20}
                    showPageSizeOptions={false}
                    pageSizeOptions={[8, 16, 32, 64, 128]}
                    manual
                    pages={this.props.backOrders.last_page}
                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                    nextText={<Translation id="next" defaultMessage="Next" />}
                    loadingText={<Translation id="loading" defaultMessage="Loading" />}
                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                    pageText={<Translation id="page" defaultMessage="Page" />}
                    ofText={<Translation id="of" defaultMessage="Of" />}
                    onFetchData={(state, instance) => {
                        this.props.fetchBackOrders(state.page + 1)
                        this.setState({ loading: true })
                    }}
                />
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BackOrders)
