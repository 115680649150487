import React from 'react'
import Translation from '../../global/Translation'

import iconoTemporalAcabado from '../../../assets/img/acabado.png'
import ProductDisplayName from '../../global/ProductDisplayName'
import LengthMeasureTranslation from '../../global/LengthMeasureTranslation'
import LengthMeasure from '../../global/LengthMeasure'

//Displays
import DisplayProductInfo from './displays/DisplayProductInfo'
import DisplayBrand from './displays/DisplayBrand'
import DisplayProductInfoTable from './displays/DisplayProductInfoTable'
import DisplayThickness from './displays/DisplayThickness'
import DisplayGroupDtails from './displays/DisplayGroupDtails'
import DisplayDetail from './displays/DisplayDetail'
import DisplayPrice from './displays/DisplayPrice'
import DisplayPricePhase2 from './displays/DisplayPricePhase2'
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {getAdaptedFormat} from "../../../helpers";


class DetailsDefault extends React.Component {
    // componentDidMount() {
    //     console.log('DETAILS DEFAULT', this.props.product)
    // }

    state = { showHelp: false }
    getUnitBy() {
        const { product } = this.props
        if (product.unitsByLot) return product.unitsByLot
        else if (product.unitsByBox) return product.unitsByBox
        else if (!product.unitsByLot && !product.unitsByBox) return <LengthMeasure product={product} />
    }

    render() {
        const { product, canViewThresholdMax, userType } = this.props
        const available = product.type === 'TABLA' ? <Translation id="tables" defaultMessage="Slabs" /> : <Translation id="available" defaultMessage="Available" />
        let quantity
        if (product.type === 'TABLA') {
            quantity = (
                <div>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }
        //const watchingOutlet = this.props.outlet ? true : false
        const watchingOutlet = !!(this.props.outlet || (product.fromMall && product.outlet))
        const stock = this.props.getStock()

        return (
            <DisplayProductInfo>
                <DisplayBrand brand={product.brand} />
                <div className="product-color-name">
                    <ProductDisplayName product={product} />
                </div>
                {this.props.isExclusiveHomeDepot && (
                    <small>
                        <span style={{ color: '#f96303' }}>
                            <Translation id="home_depot_exclusive" defaultMessage="Home Depot exclusive"></Translation>
                        </span>
                    </small>
                )}

                <DisplayProductInfoTable
                    th={[{ value: available }, { datacy: 'product_rectangle_result__surface', value: quantity }, { value: this.props.intl.formatMessage({ id: 'material_id' }) }]}
                    td={[{ value: stock }, { datacy: 'product_rectangle_result__surface_value', value: this.getUnitBy() }, { value: product.productId }]}
                />
                <DisplayGroupDtails>
                    <DisplayThickness thickness={product.thickness} />
                    <DisplayDetail class="finish" icon={iconoTemporalAcabado} value={product.finish} />
                </DisplayGroupDtails>
                <DisplayGroupDtails>
                    <DisplayDetail class="subtypeName" value={product.subtypeName} />
                    <DisplayDetail class="measure-unit format" value={this.props.adaptedFormat(product.format, {upper:true, showUnits:true})} />
                </DisplayGroupDtails>
                {product.fromMall ?
                    <React.Fragment>
                        {this.props.minimumOrder > 0 ?
                            <DisplayGroupDtails>
                                <DisplayDetail class="finish minimum" value={this.props.minimumOrder} label={<Translation id="minimun_order" defaultMessage="Minimun Order" />} />
                            </DisplayGroupDtails>
                            :
                            null
                        }
                        <DisplayGroupDtails>
                            {product && userType !== 'ADMIN' ? 
                                <DisplayPricePhase2 product={product} /> 
                                :
                                <span className="price-not-available"><Translation id="price_not_available" defaultMessage="Price not available" /></span>}
                        </DisplayGroupDtails>
                    </React.Fragment>
                :
                    <DisplayGroupDtails>
                        <DisplayPrice product={product} outlet={watchingOutlet} />
                    </DisplayGroupDtails>
                }
            </DisplayProductInfo>
        )
    }
}

const mapStateToProps = state => {
    return {
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state)
    }
}

export default connect(
    mapStateToProps,
    null
)(DetailsDefault)