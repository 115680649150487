import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

class PrivacyPolicy extends React.Component {
    // componentDidMount() {
    //     const script = document.createElement('script')
    //     script.src = 'https://consent.cookiebot.com/f7a14091-0ea6-4e3b-bb15-248d40629e42/cd.js'
    //     script.async = true
    //     document.body.appendChild(script)
    // }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1 my-4">
                        <h2>
                            <FormattedMessage id="privacy_policy" defaultMessage="Privacy policy" />
                        </h2>
                        <div id="CookieDeclaration">
                            <div class="entry-content">
                                <p>
                                    COSENTINO is aware that you would like to know how your personal information is used and distributed. We take the management of confidential
                                    information seriously. You can find more information on our Privacy Policy below.
                                </p>
                                <ol>
                                    <li>
                                        <strong>Identity of Person/Company Responsible for the File</strong>
                                    </li>
                                </ol>
                                <p>
                                    Information provided by the USER will be processed by &nbsp;COSENTINO GLOBAL, S.L.U. (hereinafter, COSENTINO), with Company Tax Code A04117297
                                    with registered address at Ctra. Baza a Hu&eacute;rcal &ndash; Overa, Km. 59 &ndash; 04860 Cantoria (Almer&iacute;a) as data controller.
                                </p>
                                <ol start="2">
                                    <li>
                                        <strong>Purpose of the Processing and legal basis</strong>
                                    </li>
                                </ol>
                                <p>COSENTINO will process information provided by the USER for different purposes:</p>
                                <ul>
                                    <li>To process and manage the registration of the USER.</li>
                                    <li>To provide the services requested by the USER.</li>
                                    <li>To be informed about benefits, promotions, events and other COSENTINO products via Whatsapp.</li>
                                </ul>
                                <p>
                                    The USER consents to the processing of their data as per the outlined purposes, without prejudice to their right to confirm or revoke such
                                    consent by checking the corresponding box for this purpose or by following the procedure for the Exercise of Rights indicated in this Policy.
                                </p>
                                <p>COSENTINO will process the USER's data for the execution of the contract that binds it with the USER for:</p>
                                <ul>
                                    <li>To manage the contractual and business relationship between COSENTINO and USER as required.</li>
                                    <li>To process, manage, provide, expand, and improve the services the USER has decided to subscribe to or register for.</li>
                                    <li>To send information regarding changes in our products or services.</li>
                                </ul>
                                <p>
                                    In addition to the above purposes, COSENTINO will process the data based on our legitimate interest, to which the USER may object by following
                                    the procedure for the Exercise of Rights indicated in this Policy. These purposes will include the following:
                                </p>
                                <ul>
                                    <li>To adapt services to improve their quality for the USER.</li>
                                    <li>To design new services related to previous ones.</li>
                                    <li>To perform statistical studies that allow for design improvements in provided services.</li>
                                    <li>To send information regarding new or similar services to those originally purchased.</li>
                                </ul>
                                <p>&nbsp;</p>
                                <p>
                                    As per the above-mentioned purposes, your data will be processed with the legal basis indicated, as long as the USER&rsquo;s registration
                                    remains active or until you state your desire to revoke such consent or refuse the data processing. In the cases where a business relationship
                                    exists between COSENTINO and the USER, your data will be saved during the duration of the business relationship and as required afterwards to
                                    fulfil legal responsibilities.
                                </p>
                                <p>
                                    Regarding commercial or informative communications, the user is informed that their personal data being processed by COSENTINO via online tools
                                    for sending electronic notifications provided by companies such as SalesForce, located in the United States of America and adhering to the
                                    <a href="dataprivacyframework.gov" target="_blank">
                                        EU-USA Data Privacy Framework
                                    </a>
                                    , providing adequate guarantees for the processing of your personal data. These tools gather information through pixels (tiny transparent
                                    electronic images linked to your address), that allows us to know who is accessing our campaign and at what time. The USER can stop this use in
                                    their email management system settings in order to stop the automatic download of images.
                                </p>
                                <p>
                                    Also, in the event that the user expressly consents to the sending of commercial or informative communications through WhatsApp, you are
                                    informed that your personal data will be subject to international transfer, since the Meta servers are located in the United States, a country
                                    whose legislation does not offer a level of data protection equivalent to the European; however, we inform you that this entity offers adequate
                                    data protection guarantees through its compliance with the <u>EU-USA Data Privacy Framework</u>
                                    <u>&nbsp;</u>, that declares an adequate level of protection by the European Commission.
                                </p>
                                <ol start="3">
                                    <li>
                                        <strong>Recipients of Information</strong>
                                    </li>
                                </ol>
                                <p>We inform that your information will not be sent to third-parties, except when:</p>
                                <ul>
                                    <li>
                                        The transfer is authorized or required by law or by an authority, competent public administration or judges/courts in the exercise of their
                                        respective functions.
                                    </li>
                                    <li>
                                        Disclosure is made to a COSENTINO GROUP company and is necessary to develop our business relationship with you or because you have requested
                                        a particular product or service, in response to a legitimate interest, or for corporate purposes. Click on the following link to view the
                                        list of{' '}
                                        <a href="https://forms.cosentino.com/cosentino-group-subsidiaries/" target="_blank">
                                            companies
                                        </a>{' '}
                                        Some companies from the COSENTINO GROUP may be located in third-party countries, including countries that, in the opinion of the European
                                        authorities, do not provide an adequate level of data protection. However, COSENTINO adopts the necessary guarantees for the protection of
                                        your data, such as the Standard Contractual Clauses approved by the European Commission.
                                    </li>
                                    <li>
                                        The data processing is in response to the free and legitimate acceptance of a legal relationship whose development, fulfilment, and control
                                        thus imply a connection between said data processing and third-party files, such as: stores where you are to pick-up samples or where
                                        you&rsquo;ve requested an appointment; banks, for the billing of purchased products and services; or delivery companies for sending
                                        purchased products.
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <p>Data can also be sent to suppliers that provide some type of service to COSENTINO and which required access to your personal information.</p>
                                <ol start="4">
                                    <li>
                                        <strong> Mandatory or Optional Nature of Requested Information</strong>
                                    </li>
                                </ol>
                                <p>
                                    Mandatory information in each form will be indicated as such with an asterisk. Not providing this information will not allow the service
                                    requested by the USER to be provided.
                                </p>
                                <ol start="5">
                                    <li>
                                        <strong> Exercising Rights</strong>
                                    </li>
                                </ol>
                                <p>
                                    The USER can revoke their consent and exercise their rights to access, correction, cancellation, opposition, limitation, and portability by
                                    emailing our Data Protection Officer at gdpr@cosentino.com , or by post at the address indicated above, identifying themselves as USER of the
                                    WEBSITE, by proving your identity, and specifying their request.
                                </p>
                                <p>
                                    Also, in the event that you feel your personal data rights have been violated, you can submit a claim to the Data Protection Agency of Spain
                                    (www.aepd.es).
                                </p>
                                <ol start="6">
                                    <li>
                                        <strong> USER Commitments</strong>
                                    </li>
                                </ol>
                                <p>THE USER assures they are of legal age and that any information provided is accurate and truthful.</p>
                                <p>
                                    THE USER undertakes to inform COSENTINO of any changes to the information provided by emailing our Data Protection Officer at gdpr@cosentino.com
                                    , identifying themselves as USER of the WEBSITE and indicating the information to be modified.
                                </p>
                                <ol start="7">
                                    <li>
                                        <strong>Third-Party Data Provided by USER</strong>
                                    </li>
                                </ol>
                                <p>
                                    If the USER provides personal data of third-parties for any type of purpose, they guarantee that they informed the concerned parties in advance
                                    and obtained their consent to their data being communicated to COSENTINO.
                                </p>
                                <p>THE USER guarantees that concerned parties are of legal age and that the information provided is accurate and truthful.</p>
                                <p>
                                    In the event of any liability arising from a breach of these conditions by the USER, the same must answer for the consequences of such breach.
                                </p>
                                <ol start="8">
                                    <li>
                                        <strong> Cookies and Tracking Files</strong>
                                    </li>
                                </ol>
                                <p>
                                    THE USER can configure their consent to the use of cookies and tracking files through the cookie banner, which allows COSENTINO to collect
                                    statistical data concerning: date of the first visit; the number of sessions; last date of visit; URL of origin; browser used; and screen
                                    resolution.
                                </p>
                                <p>You can access more information about COOKIES in our <a href="http://forms.cosentino.com/cookie-policy/">cookies policy</a></p>
                                <ol start="9">
                                    <li>
                                        <strong> Security Measures</strong>
                                    </li>
                                </ol>
                                <p>
                                    COSENTINO has implemented the necessary technical and organisational measures to ensure the safety of personal data and to prevent alteration,
                                    loss, non-authorized access or processing of said data, taking into account the technology used, the nature of the stored data and potential
                                    human, natural, or physical risks they are exposed to.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(PrivacyPolicy)
