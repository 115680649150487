import React from 'react'
import Translation from '../global/Translation'

import Button from '../global/Button'
import logo from '../../assets/img/e.cosentino.svg'
import LocalizedLink from '../global/LocalizedLink'
import {defineMessages} from "react-intl";

defineMessages({
    quote_succesfully_requested: {
        id: 'quote_successfully_requested',
        description: 'quote_successfully_requested',
        defaultMessage: "Your quote {quote} has been successfully requested"
    },
    quote_finished_header: {
        id: 'quote_finished_header',
        description: 'quote_finished_header',
        defaultMessage: "Your quote has been finished successfully"
    }
})

class OrderFinished extends React.Component {
    render() {
        const { show, reservationCode, reservationCutToSizeCode, isIkeaCenter, haveOffer } = this.props
        if (!reservationCode && !reservationCutToSizeCode) {
            return null
        }
        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
                <div id="order-finished-container" className="order-finished" style={{ display: show ? 'block' : 'none' }}>
                    <div className="container-fluid" data-cy="global__modal_thank_you">
                        <div className="row">
                            <div className="col-12 col-lg-5 logo-container d-none d-lg-block">
                                <img alt="" id="logo-img" className="profile-img-card" src={logo} />
                            </div>
                            <div className="col-12 col-lg-7 container">
                                <header className="container-fluid" />
                                <div className="row">
                                    <div className="col-12">
                                        {reservationCode && (
                                            <h2>
                                                <i className="fal fa-check-circle check-icon" />
                                                {haveOffer ? (
                                                    <Translation
                                                    id="quote_successfully_requested"
                                                    values={{
                                                        quote: reservationCode
                                                    }}
                                                />
                                                ) : (
                                                    <div>
                                                        <Translation id="order_finished_header" defaultMessage="Your order has been finished successfully" />
                                                        {' '}
                                                        <span data-cy="order_finished__reservation_code">{reservationCode}</span>
                                                    </div>                                                
                                                )}
                                                
                                                {/* {haveOffer ? offerText.replace('%quote%', reservationCode) : offerText.replace('%quote%', '')} */}
                                            </h2>
                                        )}
                                        {reservationCutToSizeCode && (
                                            <h2>
                                                <i className="fal fa-check-circle check-icon" />
                                                <Translation
                                                    id="order_finished_header_cut_to_size"
                                                    defaultMessage="Your order for cut to size has been finished successfully"
                                                />{' '}
                                                <span data-cy="order_finished__reservation_code">{reservationCutToSizeCode}</span>
                                            </h2>
                                        )}
                                        {haveOffer ? (
                                            <Translation id="thank_you_for_your_request" defaultMessage="Thank you for your request" />
                                        ) : (
                                            <Translation id="order_finished_body" defaultMessage="Thank you for your order" />
                                        )}

                                        <div className="col-12 col-md-4 p-1" />
                                    </div>
                                </div>
                                <footer>
                                    <div className="col-12 col-md-6 offset-md-6">
                                        {this.props.isLowesCenter ? (
                                            <LocalizedLink routeId="ROUTE_CATALOG_LOWES" params={{ filter: 'customer-samples' }}>
                                                <Button datacy="global__keep_buying" inverted>
                                                    <Translation id="keep_buying" defaultMessage="Keep Buying" />
                                                </Button>
                                            </LocalizedLink>
                                        ) : (
                                            <LocalizedLink routeId="ROUTE_CATALOG" params={{ outlet: '' }} onClick={() => this.props.rememberCenter(isIkeaCenter)}>
                                                <Button datacy="global__keep_buying" inverted>
                                                    <Translation id="keep_buying" defaultMessage="Keep Buying" />
                                                </Button>
                                            </LocalizedLink>
                                        )}
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default OrderFinished
