export const initState = {
    directSlabAccess: [],
    isLoading: false
}
const directSlabAccess = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_DIRECT_SLAB_ACCESS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_DIRECT_SLAB_ACCESS_SUCCESS':
            return Object.assign({}, state, { directSlabAccess: action.response, isLoading: false, reservationId: action.reservationId, invoiceId: action.invoiceId })
        case 'FETCH_DIRECT_SLAB_ACCESS_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        default:
            return state
    }
}
export const getDirectSlabAccess = state => {
    return state.directSlabAccess.directSlabAccess
}
export const getReservationId = state => {
    return state.directSlabAccess.reservationId
}
export const getInvoiceId = state => {
    return state.directSlabAccess.invoiceId
}
export const getDirectSlabAccessLoading = state => {
    return state.directSlabAccess.isLoading
}

export default directSlabAccess
