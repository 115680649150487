import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'
import { injectIntl } from 'react-intl'

import ReactTable from 'react-table'
import LengthLotMeasure from '../global/LengthLotMeasure'
import WidthLotMeasure from '../global/WidthLotMeasure'
import QtyLotMeasure from '../global/QtyLotMeasure'
import LengthMeasureTranslation from '../global/LengthMeasureTranslation'

class BundleContent extends React.Component {
    render() {
        const { show, onClose, bundleContent, center } = this.props

        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />
                <div className="modal-login bundle-content-modal" style={{ display: show ? 'block' : 'none' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 ">
                                <header className="container-fluid">
                                    <div className="row">
                                        <div className="col-3 offset-9 my-auto text-right">
                                            <div className="bt-close" onClick={onClose}>
                                                <i className="fal fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <div className="row">
                                    <div className="col-12">
                                        <ReactTable
                                            data={bundleContent}
                                            columns={[
                                                {
                                                    Header: '',
                                                    Cell: ({ original }) => {
                                                        // loadingText={<Spinner isLoading={isLoading} />} // loading={isLoading}

                                                        const lotImageResult = this.props.lotsImages[parseInt(original.lotId, 10)]

                                                        if (lotImageResult && lotImageResult.thumb) {
                                                            return (
                                                                <LocalizedLink
                                                                    target="_blank"
                                                                    routeId="ROUTE_LOT_IMAGE_VIEWER"
                                                                    params={{
                                                                        productId: original.productId,
                                                                        lotId: original.lotId,
                                                                        center: center,
                                                                        quarryBlock: original.quarryBlock.replace('/', '-'),
                                                                        quality: original.quality.replace('/', '-')
                                                                    }}
                                                                >
                                                                    <img src={lotImageResult.thumb} className="lot-thumb" alt="" style={{ width: '50px' }} />
                                                                </LocalizedLink>
                                                            )
                                                        } else {
                                                            return <img src={this.props.original.thumb} className="lot-thumb" alt="" style={{ width: '50px' }} />
                                                        }
                                                    },
                                                    minWidth: 60
                                                },
                                                {
                                                    id: 'quarryBlock',
                                                    Header: <Translation id="quarryBlock" defaultMessage="Quarry Block" />,
                                                    Cell: ({ original }) => {
                                                        if (original.lots) {
                                                            return (
                                                                <span className="bundle-tag">
                                                                    BUNDLE
                                                                    <span className="count-container">
                                                                        <span className="count">{original.lots.length} lots</span>
                                                                    </span>
                                                                </span>
                                                            )
                                                        } else {
                                                            return original.quarryBlock
                                                        }
                                                    },
                                                    minWidth: 90
                                                },
                                                { Header: <Translation id="shade" defaultMessage="Shade" />, accessor: 'shade', minWidth: 40 },
                                                {
                                                    Header: <Translation id="bundleId" defaultMessage="Bundle ID" />,
                                                    Cell: ({ original }) => {
                                                        return <b>{original.bundleId}</b>
                                                    },
                                                    minWidth: 90
                                                },
                                                {
                                                    Header: <Translation id="lotId" defaultMessage="Lot ID" />,
                                                    Cell: ({ original }) => {
                                                        if (original.lots) {
                                                            return ''
                                                        } else {
                                                            return original.lotId
                                                        }
                                                    },
                                                    minWidth: 90,
                                                    show: !!(this.props.isAdmin || this.props.isLatinaVitoria || this.props.impersonatedBy)
                                                },
                                                {
                                                    Header: <Translation id="length" defaultMessage="Length" />,
                                                    Cell: ({ original }) => {
                                                        return (
                                                            <React.Fragment>
                                                                <LengthLotMeasure original={original} /> <LengthMeasureTranslation smaller={true} />
                                                            </React.Fragment>
                                                        )
                                                    },
                                                    className: 'text-right',
                                                    headerClassName: 'text-right',
                                                    minWidth: 50
                                                },
                                                {
                                                    Header: <Translation id="width" defaultMessage="Width" />,
                                                    Cell: ({ original }) => {
                                                        return (
                                                            <React.Fragment>
                                                                <WidthLotMeasure original={original} /> <LengthMeasureTranslation smaller={true} />
                                                            </React.Fragment>
                                                        )
                                                    },
                                                    className: 'text-right',
                                                    headerClassName: 'text-right',
                                                    minWidth: 50
                                                },
                                                { Header: <Translation id="quality" defaultMessage="Quality" />, accessor: 'quality', minWidth: 40 },
                                                {
                                                    Header: <Translation id="qty" defaultMessage="Quantity" />,
                                                    Cell: ({ original }) => {
                                                        return (
                                                            <React.Fragment>
                                                                <QtyLotMeasure original={original} /> <LengthMeasureTranslation square={true} />
                                                            </React.Fragment>
                                                        )
                                                    },
                                                    className: 'text-right',
                                                    headerClassName: 'text-right',
                                                    minWidth: 40
                                                },
                                                { Header: <Translation id="location" defaultMessage="Location" />, accessor: 'location', minWidth: 80 },
                                                { Header: <Translation id="qualityOutlet" defaultMessage="Outlet Quality" />, accessor: 'qualityOutlet', minWidth: 80 }
                                            ]}
                                            defaultPageSize={10}
                                            pageSizeOptions={[4, 8, 16, 32]}
                                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                                            nextText={<Translation id="next" defaultMessage="Next" />}
                                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                            pageText={<Translation id="page" defaultMessage="Page" />}
                                            ofText={<Translation id="of" defaultMessage="Of" />}
                                        />
                                    </div>
                                </div>
                                <footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(BundleContent)
