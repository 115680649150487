import { connect } from 'react-redux'
import NewShippingDetails from './NewShippingDetails'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getShippingAddresses } from '../../../../store/shipping-addresses/reducers'
import { getFileByUrl } from '../../../../store/orders/actions'
import { getToken } from '../../../../store/login/reducers'
import { getIsIkeaCenter } from '../../../../store/centers/reducers'
import { dispatchPushURL, setIsShippingHistoryScreen } from '../../../../store/ui/actions'
import { getAdaptedFormat, getReplaceDotWithComma } from '../../../../helpers'
import { fetchOrderDeliveriesById } from '../../../../store/delivery/actions'
import { getDelivery, getDeliveryIsLoading } from '../../../../store/delivery/reducers'
import { getDeliveryStatusMap } from "../../../../store/orders/reducers"
import { getLanguage } from '../../../../store/ui/reducers'

const mapStateToProps = (state, {match}) => {
    return {
        delivery: getDelivery(state),
        isLoading: getDeliveryIsLoading(state),
        deliveryId: match.params.deliveryId,
        shippingAddressesByUser: getShippingAddresses(state),
        token: getToken(state),
        isIkeaCenter: getIsIkeaCenter(state),
        deliveryStatusMap: getDeliveryStatusMap(state),
        locale: getLanguage(state),
        adaptedFormat: (format, params) => getAdaptedFormat(format, params, state),
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrderDeliveriesById: deliveryId => {
            dispatch(fetchOrderDeliveriesById(deliveryId))
        },
        getFileByUrl: url => dispatch(getFileByUrl(url)),
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        setIsShippingHistoryScreen: isShippingHistoryScreen => {
            dispatch(setIsShippingHistoryScreen(isShippingHistoryScreen))
        }
    }
}

const NewShippingDetailsContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(NewShippingDetails)
    )
)

export default NewShippingDetailsContainer
