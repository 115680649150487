import React from 'react'
import Alert from './Alerts'


const CloseableAlerts = ({ alerts, status, onClose }) => {

    if (alerts) {
        return (
            <div style={{position:'relative'}}>
                <div style={{width:'100%'}}>
                    {
                        alerts.map(alert => <Alert alert={alert.id} status={status} values={alert.values}/>)
                    }
                </div>

                <div style={{position:"absolute",right:'12px',top:'4px',color:'gray'}}>
                    <i className="far fa-times" onClick={onClose}/>
                </div>
            </div>
        )
    }


}

export default CloseableAlerts
