import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import LocalizedLink from '../global/LocalizedLink'
import Button from '../global/Button'
import { connect } from 'react-redux'
import Spinner from '../global/Spinner'
import { getIsLoading, getAlertSendForm } from '../../store/lowes/reducers'
import { sendFormNewRegister } from '../../store/lowes/actions'
import logo from '../../assets/img/e.cosentino.svg'
import Alerts from '../alerts/Alerts'

const mapStateToProps = state => {
    return {
        isLoading: getIsLoading(state),
        alertSendForm: getAlertSendForm(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        sendForm: (e, email, name, lastName, numberStore, pass) => {
            e.preventDefault()
            dispatch(sendFormNewRegister(email, name, lastName, numberStore, pass))
        }
    }
}
let initState = {
    canSend: false,
    alert: {
        mayus: undefined,
        contain: undefined,
        number: undefined,
        minus: undefined,
        equal: undefined
    },
    listRequirementsVisible: false
}
class LowesNewUserRegister extends React.Component {
    constructor(props) {
        super(props)
        this.state = initState
    }
    componentDidMount() {}
    decodeEmail(email) {
        try {
            return atob(email)
        } catch (e) {
            return ''
        }
    }
    checkForm() {
        let haveMayus = /.*[A-Z]+.*/.test(this.passwordInput.value)
        let haveMinus = /.*[a-z]+.*/.test(this.passwordInput.value)
        let haveNumber = /.*[\d]+.*/.test(this.passwordInput.value)
        let haveContain = /.{8}/.test(this.passwordInput.value)
        let isEqual = this.passwordInput.value === this.repeatPasswordInput.value && this.passwordInput.value !== ''
        let ok =
            haveMayus &&
            haveMinus &&
            haveNumber &&
            haveContain &&
            isEqual &&
            this.emailInput.value !== '' &&
            this.nameInput.value !== '' &&
            this.lastNameInput.value !== '' &&
            this.storeNumberInput.value !== '' &&
            this.passwordInput.value !== ''
        if (haveContain) this.setState({ alert: Object.assign(this.state.alert, { contain: true }) })
        else if (this.state.alert.contain) this.setState({ alert: Object.assign(this.state.alert, { contain: false }) })

        if (haveNumber) this.setState({ alert: Object.assign(this.state.alert, { number: true }) })
        else if (this.state.alert.number) this.setState({ alert: Object.assign(this.state.alert, { number: false }) })

        if (haveMinus) this.setState({ alert: Object.assign(this.state.alert, { minus: true }) })
        else if (this.state.alert.minus) this.setState({ alert: Object.assign(this.state.alert, { minus: false }) })

        if (haveMayus) this.setState({ alert: Object.assign(this.state.alert, { mayus: true }) })
        else if (this.state.alert.mayus) this.setState({ alert: Object.assign(this.state.alert, { mayus: false }) })

        if (isEqual) this.setState({ alert: Object.assign(this.state.alert, { equal: true }) })
        else if (this.state.alert.equal) this.setState({ alert: Object.assign(this.state.alert, { equal: false }) })

        this.setState({ canSend: ok })
    }
    render() {
        return (
            <div className="lowes_register">
                <Spinner isLoading={this.props.isLoading} />
                <div className="container container-login">
                    <div className="card card-container">
                        <img alt="" src={logo} />
                        {this.props.alertSendForm && <Alerts alert={this.props.alertSendForm.msg} status={this.props.alertSendForm.status} />}
                        {this.props.alertSendForm && this.props.alertSendForm.status === 200 ? (
                            <LocalizedLink routeId="ROUTE_HOME">
                                <Button>
                                    <Translation id="take_me_home" defaultMessage="Take me home" />
                                </Button>
                            </LocalizedLink>
                        ) : (
                            <form
                                className="form-signin"
                                onSubmit={e =>
                                    this.props.sendForm(
                                        e,
                                        this.emailInput.value,
                                        this.nameInput.value,
                                        this.lastNameInput.value,
                                        this.storeNumberInput.value,
                                        this.passwordInput.value
                                    )
                                }
                            >
                                <div className="group group_user">
                                    <label for="email">
                                        <Translation id="register" defaultMessage="Register" />
                                    </label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email"
                                        required
                                        onChange={() => this.checkForm()}
                                        ref={node => {
                                            this.emailInput = node
                                        }}
                                    />
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Name"
                                        required
                                        onChange={() => this.checkForm()}
                                        ref={node => {
                                            this.nameInput = node
                                        }}
                                    />
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Last name"
                                        required
                                        onChange={() => this.checkForm()}
                                        ref={node => {
                                            this.lastNameInput = node
                                        }}
                                    />
                                    <input
                                        className="form-control"
                                        type="text"
                                        pattern="[0-9]*"
                                        maxlength="4"
                                        placeholder="Lowe's store number"
                                        required
                                        onChange={() => this.checkForm()}
                                        ref={node => {
                                            this.storeNumberInput = node
                                        }}
                                    />
                                </div>
                                <div className="group group_password">
                                    <label for="password">
                                        <Translation id="password" defaultMessage="Password" />
                                    </label>
                                    <ul className={`list_requirements ${this.state.listRequirementsVisible ? '' : 'list_requirements--hidden'}`}>
                                        <li
                                            className={`list_requirements__item${this.state.alert.number !== undefined ? (this.state.alert.number ? '-success' : '-failure') : ''}`}
                                            data-cy="change_password__requirements-number"
                                        >
                                            <Translation id="password_not_number" defaultMessage="At least one numeral" />
                                        </li>
                                        <li
                                            className={`list_requirements__item${this.state.alert.minus !== undefined ? (this.state.alert.minus ? '-success' : '-failure') : ''}`}
                                            data-cy="change_password__requirements-low_case"
                                        >
                                            <Translation id="password_not_minus" defaultMessage="At least one lower case letter" />
                                        </li>
                                        <li
                                            className={`list_requirements__item${this.state.alert.mayus !== undefined ? (this.state.alert.mayus ? '-success' : '-failure') : ''}`}
                                            data-cy="change_password__requirements-upper_case"
                                        >
                                            <Translation id="password_not_mayus" defaultMessage="At least one upper case letter" />
                                        </li>
                                        <li
                                            className={`list_requirements__item${
                                                this.state.alert.contain !== undefined ? (this.state.alert.contain ? '-success' : '-failure') : ''
                                            }`}
                                            data-cy="change_password__requirements-minimum"
                                        >
                                            <Translation id="password_should_contain" defaultMessage="Minimum 8 characters" />
                                        </li>
                                        <li
                                            className={`list_requirements__item${this.state.alert.equal !== undefined ? (this.state.alert.equal ? '-success' : '-failure') : ''}`}
                                            data-cy="change_password__requirements-match"
                                        >
                                            <Translation id="password_not_equal" defaultMessage="Passwords do not match" />
                                        </li>
                                    </ul>
                                    <input
                                        className="form-control"
                                        type="password"
                                        placeholder="Password"
                                        onChange={() => this.checkForm()}
                                        onFocus={() => {
                                            this.setState({ listRequirementsVisible: true })
                                        }}
                                        onBlur={() => {
                                            this.setState({ listRequirementsVisible: false })
                                        }}
                                        ref={node => {
                                            this.passwordInput = node
                                        }}
                                    />
                                    <input
                                        className="form-control"
                                        type="password"
                                        placeholder="Repeat password"
                                        onChange={() => this.checkForm()}
                                        onFocus={() => {
                                            this.setState({ listRequirementsVisible: true })
                                        }}
                                        onBlur={() => {
                                            this.setState({ listRequirementsVisible: false })
                                        }}
                                        ref={node => {
                                            this.repeatPasswordInput = node
                                        }}
                                    />
                                </div>
                                <div className="group group_button">
                                    <button disabled={!this.state.canSend} className="btn btn-lg btn-primary btn-block btn-signin">
                                        <Translation id="create_account" defaultMessage="Create account" />
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LowesNewUserRegister)
)
