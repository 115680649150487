import { connect } from 'react-redux'
import { getProductsSimilarColors } from '../../store/products/reducers'
import { getIsLogged } from '../../store/login/reducers'
import Translation from "../global/Translation";
import React from "react";
import { fetchProductsSimilarColors } from "../../store/products/actions";
import { canViewMktools } from "../../store/centers/reducers";
import ProductsSlider from "./ProductsSlider";

const mapStateToProps = state => {
    return {
        products: getProductsSimilarColors(state),
        hide: !getIsLogged(state) || canViewMktools(state),
        title: <Translation id="similar_colors" defaultMessage="Similar colors" />,
        customClass: 'products-similar-colors',
        analyticsTag: 'similar_colors',
        minWidth: 215,
        gtmLabel: 'ec_carrusel_similar_colors'
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetch: () => {
            return dispatch(fetchProductsSimilarColors(props.productId))
        }
    }
}

const ProductsSimilarColorsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider)

export default ProductsSimilarColorsContainer
