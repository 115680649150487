import React from 'react'

import Spinner from '../../global/Spinner'
import ReactTable from "react-table";
import Translation from "../../global/Translation";
import DisplayLotId from "../../catalog/rectangleResult/displays/DisplayLotId";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {fetchMallLots} from "../../../store/landing/actions";
import {getIsLoadingLots, getMallLots} from "../../../store/landing/reducers";
import Select from "react-select";
import Button from "../../global/Button";

const DEFAULT_PAGE_SIZE = 32
let PAGE_INDEX = 0
let PAGE_SIZE = DEFAULT_PAGE_SIZE

class LotsModal extends React.Component {

    state = {
        search: '',
        selectedCenter: null,
        centersOptions: [],
        provisionalLots: {},
        selectedLots: {},
        selectedBundles: {},
        lotsAreFetched: false,
        expander: {}
    }

    componentDidMount() {
        if (this.props.centers) {
            this.props.centers.forEach(c => {
                // if (c == '1100') c = '1070'
                this.state.centersOptions.push({label: c, value: c})
            })
        } else if (this.props.dataItem) {
            this.props.fetchLots(
                [this.props.product.centerId],
                this.props.product.productId,
                this.props.dataItem.landing_common_id,
                this.props.dataItem.id
            ).then(() => {
                const bundles = {}
                const lots = {}

                this.props.lots.forEach((l, index) => {
                    this.state.expander[index] = !l.isSelected && l.selectedLotsCount > 0

                    if (l.bundleId) {
                        bundles[l.bundleId] = true
                        if (l.lots) {
                            l.lots.forEach(innerLot => lots[innerLot.lotId] = true)
                        }
                    } else {
                        lots[l.lotId] = true
                    }
                })

                this.setState({lotsAreFetched : true })

                this.props.product.bundles && this.props.product.bundles.forEach(b => {
                    if (bundles[b]) this.state.selectedBundles[b] = true
                })
                this.props.product.lots && this.props.product.lots.forEach(l => {
                    if (lots[l]) this.state.selectedLots[l] = true
                })
            })
        }
    }

    search = e => {
        this.setState({ search: e.target.value }, this.filter)
    }

    handleSelectCenter = center => {
        if (this.state.selectedCenter != center) {
            this.props.product.centerId = center
            this.setState({selectedCenter: center})
            this.props.fetchLots([center], this.props.product.productId, null, null).then(() => this.setState({lotsAreFetched : true }))
        }
    }

    addLots = () => {
        Object.keys(this.state.selectedBundles).forEach(b => this.props.selectedBundles[b] = true)
        Object.keys(this.state.selectedLots).forEach(l => this.props.selectedLots[l] = true)
    }

    updateGroup = () => {
        this.addLots()
        this.props.product.lots = Object.keys(this.state.selectedLots)
        this.props.product.bundles = Object.keys(this.state.selectedBundles)
        this.props.onClose()
    }

    addGroup = () => {
        this.addLots()
        const group = {...this.props.product,
            isGroup: true,
            lots: Object.keys(this.state.selectedLots),
            bundles: Object.keys(this.state.selectedBundles),
            outlet: this.props.lots[0].qualityOutlet
        }
        let isOutlet = group.outlet
        if (this.props.lots[0] && this.props.lots[0].lots && this.props.lots[0].lots[0]) isOutlet = !!this.props.lots[0].lots[0].qualityOutlet
        this.props.addGroup(group, isOutlet)
        this.props.onClose()
    }

    selectLot = (checked, lotId) => {
        if (checked) this.state.selectedLots[lotId] = true
        else delete this.state.selectedLots[lotId]
        this.forceUpdate()
    }

    selectBundle = (checked, bundleId, lots) => {
        if (checked) {
            this.state.selectedBundles[bundleId] = true
            lots.forEach(l => {
                if (!(l.lotId in this.props.selectedLots)) this.state.selectedLots[l.lotId] = true
            })
        } else {
            delete this.state.selectedBundles[bundleId]
            lots.forEach(l => delete this.state.selectedLots[l.lotId])
        }
        this.forceUpdate()
    }

    filter = () => {
        let lotsFiltered = this.state.lotsAreFetched && this.props.lots ? this.props.lots : []

        if (this.state.provisionalLots) {
            lotsFiltered = lotsFiltered.filter(row => !(row.lotId in this.state.provisionalLots))
        }

        if (this.state.search) {
            lotsFiltered = lotsFiltered.filter(row => {
                if (row.lotId) return row.lotId.includes(this.state.search)
                else if (row.bundleId) return row.bundleId.includes(this.state.search) || row.lots.some(lot => lot.lotId.includes(this.state.search))
                else return row
            })
        }

        return lotsFiltered
    }

    disableButton = () => {
        return !this.state.lotsAreFetched || Object.keys(this.state.selectedLots).length === 0
    }

    render() {

        let lots = this.filter()

        return (

            <section id="section-landing-lots">
                <Spinner isLoading={this.props.isLoading} />

                <div className="full-overlay" style={{ display: 'block'}} onClick={this.props.onClose} />

                <div id="modal-tables-quantity-container" className="tables-quantity container p-3" style={{ display: 'block', maxHeight: 'none'}}>
                    <div className="lots container">
                        <div className="row align-items-center">

                            {this.props.centers ? (
                                <React.Fragment>
                                    <div className="col-12 col-lg-4 mb-1 p-lg-0">
                                        <div className="search-bar search-bar--thin">
                                            <input type="text"
                                                   className="search-box"
                                                   placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                                   value={this.state.search}
                                                   onChange={this.search}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-4 mb-1 d-flex align-items-center">
                                        <Translation id={'center'} defaultMessage={'Center'}/>:
                                        <Select
                                            style={{width: '100%', textAlign: 'center'}}
                                            id="mall-lots-center-select"
                                            placeholder={this.props.intl.formatMessage({id:"select_center", defaultMessage:"Select center"})}
                                            options={this.state.centersOptions}
                                            value={this.state.selectedCenter}
                                            clearable={false}
                                            onChange={selectedOption => this.handleSelectCenter(selectedOption.value)}
                                        />
                                    </div>

                                    <div className="col-12 col-lg-4 mb-1">
                                        <Button
                                            size="medium"
                                            onClick={this.addGroup}
                                            disabled={this.disableButton()}
                                            gentle
                                        >
                                            <Translation id={'create_group'} defaultMessage={'Create group'}/>
                                        </Button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="row w-100 d-flex justify-content-end">
                                        <div className="col-6 col-lg-4 mb-1">
                                            <Button
                                                size="medium"
                                                onClick={this.updateGroup}
                                                disabled={this.disableButton()}
                                                gentle
                                            >
                                                <Translation id={'update_group'} defaultMessage={'Update group'}/>
                                            </Button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            <div className="lots-container col-12">

                                <ReactTable
                                    data={lots ? lots : []}
                                    loading={this.props.isLoading}
                                    loadingText={<Spinner isLoading={this.props.isLoading} />}
                                    columns={[
                                        {
                                            id: 'expander',
                                            expander: true,
                                            Expander: ({ isExpanded, original }) => {
                                                return original.lots ? (
                                                    <div className={isExpanded ? 'rt-expander -open' : 'rt-expander'} style={{ width: '20px', cursor: 'pointer' }}>
                                                        •
                                                    </div>
                                                ) : (
                                                    ''
                                                )
                                            }
                                        },
                                        {
                                            id: 'image',
                                            Header: '',
                                            Cell: ({ original }) => {
                                                return <img src={original.thumb} className="lot-thumb" alt="" style={{ width: '50px', cursor: 'auto' }} />
                                            },
                                            minWidth: 60
                                        },
                                        {
                                            id: 'quarryBlock',
                                            Header: <Translation id="quarryBlock" defaultMessage="Quarry Block" />,
                                            Cell: ({ original }) => {
                                                if (original.lots) {
                                                    return (
                                                        <span className="bundle-tag">
                                                            BUNDLE
                                                            <span className="count-container">
                                                                <span className="count"> ({original.lots.length} lots)</span>
                                                            </span>
                                                        </span>
                                                    )
                                                } else {
                                                    return <span>{ original.quarryBlock }</span>
                                                }
                                            },
                                            minWidth: 90
                                        },
                                        {
                                            id: 'bundleId',
                                            Header: 'Bundle ID',
                                            Cell: ({ original }) => {
                                                return <b>{original.bundleId}</b>
                                            },

                                            minWidth: 90
                                        },
                                        {
                                            id: 'lotId',
                                            Header: <Translation id="lotId" defaultMessage="Lot ID" />,
                                            Cell: ({ original }) => {
                                                if (original.lots) {
                                                    return ''
                                                } else {
                                                    return <DisplayLotId lotId={original.lotId}/>                                            }
                                            },

                                            minWidth: 90
                                        },
                                        {
                                            id: 'location',
                                            Header: <Translation id="location" defaultMessage="Location" />,
                                            accessor: 'location',
                                            minWidth: 80
                                        },
                                        {
                                            id: 'actions',
                                            Header: '',
                                            Cell: ({ original, column, rowInfo }) => {
                                                if (original.lots) {
                                                    return (
                                                        <input
                                                            type="checkbox"
                                                            onClick={(e) => {
                                                                original.isSelected = e.target.checked
                                                                original.lots.forEach(l => l.isSelected = e.target.checked)
                                                                this.selectBundle(e.target.checked, original.bundleId, original.lots)
                                                            }}
                                                            key={original.bundleId}
                                                            disabled={original.bundleId in this.props.selectedBundles && !(original.bundleId in this.state.selectedBundles)}
                                                            defaultChecked={original.isSelected || original.bundleId in this.state.selectedBundles || original.bundleId in this.props.selectedBundles}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <input
                                                            type="checkbox"
                                                            onClick={(e) => {
                                                                original.isSelected = e.target.checked
                                                                this.selectLot(e.target.checked, original.lotId)
                                                            }}
                                                            key={original.lotId}
                                                            disabled={original.lotId in this.props.selectedLots && !(original.lotId in this.state.selectedLots)}
                                                            defaultChecked={original.isSelected || original.lotId in this.state.selectedLots || original.lotId in this.props.selectedLots}
                                                        />
                                                    )
                                                }
                                            },
                                            maxWidth: 60
                                        }
                                    ]}
                                    subRowsKey="lots"
                                    defaultPageSize={DEFAULT_PAGE_SIZE}
                                    pageSizeOptions={[8, 16, 32, 64, 128]}
                                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                                    nextText={<Translation id="next" defaultMessage="Next" />}
                                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                    pageText={<Translation id="page" defaultMessage="Page" />}
                                    ofText={<Translation id="of" defaultMessage="Of" />}
                                    className="light lots_table"
                                    onPageChange={pageIndex => {
                                        PAGE_INDEX = pageIndex
                                        lots.slice(PAGE_INDEX * PAGE_SIZE, PAGE_INDEX * PAGE_SIZE + PAGE_SIZE)
                                        document.getElementsByClassName('lots_table')[0].getElementsByClassName('rt-tbody')[0].scrollTop = 0
                                    }}
                                    onPageSizeChange={(pageSize, pageIndex) => {
                                        PAGE_SIZE = pageSize
                                        PAGE_INDEX = pageIndex
                                        lots.slice(PAGE_INDEX * PAGE_SIZE, PAGE_INDEX * PAGE_SIZE + PAGE_SIZE)
                                        document.getElementsByClassName('lots_table')[0].getElementsByClassName('rt-tbody')[0].scrollTop = 0
                                    }}
                                    expanded={this.state.expander}
                                    onExpandedChange={newExpanded => this.setState({expander: newExpanded})}
                                />

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        lots: getMallLots(state),
        isLoading: getIsLoadingLots(state),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchLots: (centers, productId, landingCommonId, landingProductId) => dispatch(fetchMallLots(centers, productId, landingCommonId, landingProductId))
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LotsModal)
)
