import * as api from '../api'
import { getToken } from '../login/reducers'
import {
  getLotsImages,
  lastLotCenterLoaded,
  lastLotProductIdLoaded,
  isLastLotOutlet
} from './reducers'
import {byProductId} from "../products/reducers";
import {getLastSelectedProduct} from "../selected-objects/reducers";

export const fetchLot = (centerId, lotId, productId) => (dispatch, getState) => {
    dispatch({ type: 'EMPTY_LOT_DETAIL' })
    dispatch({ type: 'FETCH_LOT_REQUEST', centerId, lotId, productId })

    return api.fetchLot(centerId, lotId, productId, getToken(getState())).then(
        response => {
            dispatch({ type: 'FETCH_LOT_SUCCESS', response: response.data })
        },
        error => {
            dispatch({ type: 'FETCH_LOT_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}

export const fetchLots = (productId, quarryBlock = null, shade = null, quality = null, location = null, outlet = null, callback = null) => (dispatch, getState) => {

    let centerId = getState().centers.selectedCenter
    // const product = byProductId(getState(), productId)
    let product = getLastSelectedProduct(getState())
    const fromMall = product && product.fromMall

    if (!product) product = byProductId(getState(), productId)
    if (product) centerId = product.centerId

    if (!fromMall && lastLotCenterLoaded(getState()) == centerId && lastLotProductIdLoaded(getState()) == productId && isLastLotOutlet(getState()) == outlet) {
        if (callback) callback()
        return Promise.resolve()
    }
    dispatch({ type: 'FETCH_LOTS_REQUEST', productId, centerId, quarryBlock, shade, quality, location, outlet })


    return api.fetchLots(productId, centerId, quarryBlock, shade, quality, location, outlet, getToken(getState())).then(
        response => {
            let lotIds = []
            response.data.data.forEach(bundlelot => {
                bundlelot.lotId ? lotIds.push(bundlelot.lotId) : bundlelot.lots.forEach(lot => lotIds.push(lot.lotId))
            })
            dispatch({ type: 'FETCH_LOTS_SUCCESS', response: response.data, productId, centerId, outlet })
            const fetchImagesResp = dispatch(fetchLotsImages(lotIds))
            if (callback) {
                if (fetchImagesResp.then) fetchImagesResp.then(callback)
                else callback()
            }
        },
        error => {
            dispatch({ type: 'FETCH_LOTS_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}

export const fetchLotsImages = lotIds => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LOTS_IMAGES_REQUEST', lotIds })
    const lotIdsFeched = Object.keys(getLotsImages(getState()))
    lotIds = lotIds.filter(lot => !lotIdsFeched.includes(lot))
    if (lotIds.length === 0) return dispatch({ type: 'FETCH_LOTS_IMAGES_SUCCESS', response: {} })
    return api.fetchLotsImages(lotIds).then(
        response => {
            dispatch({ type: 'FETCH_LOTS_IMAGES_SUCCESS', response: response.data })
        },
        error => {
            dispatch({ type: 'FETCH_LOTS_IMAGES_FAILURE' })
            // api.errorHandler(dispatch, error.response)
        }
    )
}
