import * as api from '../api'
import { getToken } from '../login/reducers'
import {
    getCurrentPromotionId,
    getDiscountMaterials,
    getFetchedCenters,
    getFetchedCountries,
    getFetchedMarkets, getMaterialsSelectedData,
    getStep
} from './reducers'
import { getAvailableLands, getLanguage } from '../ui/reducers'
import { getSelectedCenter } from '../centers/reducers'
import {sendApproveCampaignDiscount, sendDenyCampaignDiscount} from "../api";

export const sendPromotionHeader = _data => (dispatch, getState) => {
    let data = { ..._data, promotionId: getCurrentPromotionId(getState()) }
    dispatch({
        type: 'SEND_PROMOTION_HEADER_REQUEST',
        name: data.name,
        startDate: data.startDate,
        endDate: data.endDate,
        commercial_name: data.commercial_name,
        description: data.description,
        isNewPromo: !!data.promotionId,
        promotionId: data.promotionId
    })
    return api
        .sendPromotionHeader(getToken(getState()), data)
        .then(response => {
            return dispatch({
                type: 'SEND_PROMOTION_HEADER_SUCCESS',
                status: response.status,
                promotionId: response.data,
                data,
                alert: 'Genearl Info saved'
            })
        })
        .catch(error => {
            dispatch({
                type: 'SEND_PROMOTION_HEADER_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
            return Promise.reject()
            return dispatch({
                type: 'SEND_PROMOTION_HEADER_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        })
}

export const sendTemplatesSelected = data => (dispatch, getState) => {
    data = { ...data, promotionId: getCurrentPromotionId(getState()) }
    dispatch({ type: 'SEND_TEMPLATES_SELECTED_REQUEST', data })
    return api
        .sendTemplatesSelected(getToken(getState()), data)
        .then(response => {
            return dispatch({ type: 'SEND_TEMPLATES_SELECTED_SUCCESS', status: response.status, data, step: getStep(getState()) + 1 })
        })
        .catch(error => {
            return dispatch({
                type: 'SEND_TEMPLATES_SELECTED_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        })
}
export const changeStep = step => (dispatch, getState) => {
    return dispatch({ type: 'PROMOTIONS_ADMIN_CHANGE_STEP', step: step })
}

export const updateTranslationObject = (object, data) => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_OBJECT_TRANSLATIONS', object: object, translations: data })
}
export const uploadPromotionFile = formData => (dispatch, getState) => {
    dispatch({ type: 'UPLOAD_FILE_REQUEST', formData: formData })
    return api
        .uploadPromotionFile(getToken(getState()), formData)
        .then(response => {
            return dispatch({ type: 'UPLOAD_FILE_SUCCESS', banner: response.data })
        })
        .catch(error => {
            return dispatch({
                type: 'UPLOAD_FILE_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        })
}

export const sendBanners = (bannerLanding, bannerHome, bannerNewsLetter, sideBannerNewsLetter, banner_title, banner_description) => (dispatch, getState) => {
    let data = {
        promotionId: getCurrentPromotionId(getState()),
        bannerLanding: bannerLanding.id,
        bannerHome: bannerHome.id,
        bannerNewsLetter: bannerNewsLetter.id,
        sideBannerNewsLetter: sideBannerNewsLetter.id,
        banner_title,
        banner_description
    }
    dispatch({
        type: 'SEND_BANNERS_REQUEST',
        bannerLanding,
        bannerHome,
        bannerNewsLetter,
        sideBannerNewsLetter,
        banner_title,
        banner_description
    })
    return api
        .sendBanners(getToken(getState()), data)
        .then(response => {
            return dispatch({
                type: 'SEND_BANNERS_SUCCESS',
                status: response.status,
                bannerLanding,
                bannerHome,
                bannerNewsLetter,
                sideBannerNewsLetter,
                banner_title,
                banner_description,
                step: getStep(getState()) + 1
            })
        })
        .catch(error => {
            return dispatch({
                type: 'SEND_BANNERS_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        })
}

export const getMarkets = () => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({ type: 'GET_MARKETS' })
    return api
        .promotionFetchMarkets(token)
        .then(response => {
            dispatch({ type: 'GET_MARKETS_SUCCESS', status: 200, fetchedMarkets: response.data })
            return Promise.resolve()
        })
        .catch(error => {
            dispatch({ type: 'GET_MARKETS_FAILURE', status: error.response.status, alert: error.response.data.err })
            return Promise.reject()
        })
}

export const getCountries = data => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({ type: 'GET_COUNTRIES', salesOrg: data.salesOrg })
    return api
        .promotionFetchCountries(token, data)
        .then(response => {
            dispatch({ type: 'GET_COUNTRIES_SUCCESS', status: 200, fetchedCountries: response.data })
            return Promise.resolve()
        })
        .catch(error => {
            dispatch({ type: 'GET_COUNTRIES_FAILURE', status: error.response.status, alert: error.response.data.err })
            return Promise.reject()
        })
}

export const getCenters = data => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({ type: 'GET_CENTERS', salesOrg: data.salesOrg })
    return api
        .promotionFetchCenters(token, data)
        .then(response => {
            dispatch({ type: 'GET_CENTERS_SUCCESS', status: 200, fetchedCenters: response.data })
            return Promise.resolve()
        })
        .catch(error => {
            dispatch({ type: 'GET_CENTERS_FAILURE', status: error.response.status, alert: error.response.data.err })
            return Promise.reject()
        })
}

export const sendPromotionArea = data => (dispatch, getState) => {
    data = { ...data, promotionId: getCurrentPromotionId(getState()) }
    dispatch({ type: 'SEND_PROMOTION_CENTERS_REQUEST', data })
    return api
        .sendPromotionArea(getToken(getState()), data)
        .then(response => {
            return dispatch({
                type: 'SEND_PROMOTION_CENTERS_SUCCESS',
                status: 200,
                alert: 'Promotion saved',
                data,
                step: 3
            })
        })
        .catch(error => {
            return dispatch({
                type: 'SEND_PROMOTION_CENTERS_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
        })
}

export const fetchCustomersOfArea = (market, countries, centers) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_CUSTOMERS_OF_AREA', market, countries, centers })
    return api
        .fetchCustomersOfArea(getToken(getState()), market, countries, centers)
        .then(response => {
            return dispatch({ type: 'FETCH_CUSTOMERS_OF_AREA_SUCCESS', customers: response.data })
        })
        .catch(error => {
            return dispatch({
                type: 'FETCH_CUSTOMERS_OF_AREA_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
        })
}
export const sendCustomersSelected = customers => (dispatch, getState) => {
    let promotionId = getCurrentPromotionId(getState())

    dispatch({ type: 'SEND_CUSTOMERS_SELECTED_REQUEST', customers, promotionId })
    return api
        .sendCustomersSelected(getToken(getState()), promotionId, customers)
        .then(response => {
            return dispatch({
                type: 'SEND_CUSTOMERS_SELECTED_SUCCESS',
                customers: customers,
                alert: 'Promotion saved',
                step: getStep(getState()) + 1
            })
        })
        .catch(error => {
            return dispatch({
                type: 'SEND_CUSTOMERS_SELECTED_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
        })
}

export const sendMaterialsSelected = (materials, materialsSelectedData) => (dispatch, getState) => {
    let promotionId = getCurrentPromotionId(getState())

    dispatch({ type: 'SEND_MATERIALS_SELECTED_REQUEST', materials, promotionId })
    let materialsWithOutletSelected = []
    if (materialsSelectedData && materialsSelectedData.length > 0) {
        materialsWithOutletSelected = materialsSelectedData.map(a => {
            return { productId: a.productId, isSellingOutlet: a.isSellingOutlet }
        })
        // console.log(materialsWithOutletSelected)
    }

    return api
        .sendMaterialsSelected(getToken(getState()), promotionId, materials, materialsWithOutletSelected)
        .then(response => {
            return dispatch({
                type: 'SEND_MATERIALS_SELECTED_SUCCESS',
                materials: materials,
                materialsSelectedData: materialsSelectedData,
                alert: 'Promotion saved'
            })
        })
        .catch(error => {
            return dispatch({
                type: 'SEND_MATERIALS_SELECTED_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
        })
}

export const requestMaterialsDiscounts = () => (dispatch, getState) => {
    const state = getState()
    const promotionId = getCurrentPromotionId(state)
    const materialsData = Object.values(getMaterialsSelectedData(state))
    const discountMaterials = getDiscountMaterials(state)
    const centerId = getSelectedCenter(state)
    const token = getToken(state)

    const language = getLanguage(state).toUpperCase()

    const materials = Object.values(discountMaterials).map(m => {
        const materialData = materialsData.find(md => md.productId == m.materialId)
        const lang = (language in materialData.translations) ? language : 'Z2'

        return {
            materialId : m.materialId,
            requestedDiscount: m.requestedDiscount,
            acceptedDiscount: m.acceptedDiscount,
            thumb: materialData.thumb,
            brand: materialData.brand,
            description: materialData.translations[lang].productName,
            rotation: materialData.translations[lang].indRotation,
            outlet: materialData.outletQuality
        }
    })

    dispatch({ type: 'REQUEST_MATERIALS_DISCOUNTS'})

    return api
        .sendMaterialsToRequestDiscount(token, centerId, promotionId, materials, language)
        .then(response => {
            const message = response.data.sent_mail ? 'REQUESTED_CAMPAIGN_DISCOUNT' : 'NOT_REQUESTED_CAMPAIGN_DISCOUNT'
            // const discountMaterials = {}
            // response.data.requested_discounts && response.data.requested_discounts.forEach(v => discountMaterials[v.materialId] = {
            //     materialId: v.materialId,
            //     requestedDiscount: v.requestedDiscount,
            //     acceptedDiscount: v.acceptedDiscount
            // })

            return dispatch({
                type: 'REQUEST_MATERIALS_DISCOUNTS_SUCCESS',
                discountMaterials: {...discountMaterials},
                message
            })
        })
        .catch(error => {
            return dispatch({
                type: 'REQUEST_MATERIALS_DISCOUNTS_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
        })
}


export const fetchPromotionsList = (page, itemsPerPage, filters) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PROMOTIONS_LIST_REQUEST' })
    return api
        .getPromotionsList(getToken(getState()), getLanguage(getState()), page, itemsPerPage, filters)
        .then(response => {
            return dispatch({ type: 'FETCH_PROMOTIONS_LIST_SUCCESS', status: response.status, promotions: response.data })
        })
        .catch(error => {
            return dispatch({
                type: 'FETCH_PROMOTIONS_LIST_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
        })
}
export const fetchPromotionToEdit = (promotionId, intl) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PROMOTION_TO_EDIT_REQUEST' })
    return api.getPromotionToEdit(getToken(getState()), promotionId).then(response => {
        let languages = getAvailableLands(getState())
            .filter(
                language =>
                    JSON.parse(response.data.commercial_name_data.text) &&
                    Object.keys(JSON.parse(response.data.commercial_name_data.text))
                        .filter(val => !val.includes('default'))
                        .includes(language.localecode)
            )
            .map(land => {
                return { value: land.localecode, label: land.name, name: land.langName, code: land.code }
            })

        return dispatch(getMarkets()).then(() => {
            let marketSelected = getFetchedMarkets(getState()).filter(market => market.name === response.data.market)[0]
            let salesOrgMarket = marketSelected && marketSelected.salesOrg
            if (salesOrgMarket) {
                return dispatch(getCountries({ salesOrg: salesOrgMarket })).then(() => {
                    let countries = Object.keys(getFetchedCountries(getState()))
                        .filter(country => response.data.countries.includes(country))
                        .map(country => {
                            return {
                                value: country,
                                label: intl.formatMessage({ id: 'country_' + country }),
                                salesOrg: getFetchedCountries(getState())[country]
                            }
                        })
                    var groupedSalesOrg = []
                    countries.forEach(country => {
                        groupedSalesOrg = groupedSalesOrg.concat(country.salesOrg)
                    })
                    return dispatch(getCenters({ salesOrg: groupedSalesOrg })).then(() => {
                        let centers = getFetchedCenters(getState())
                            .filter(center => response.data.centers.includes(center.id))
                            .map(center => {
                                return {
                                    value: center.id,
                                    label: center.id + ' ' + center.name,
                                    salesOrg: center.salesOrg
                                }
                            })

                        return dispatch({
                            type: 'FETCH_PROMOTION_TO_EDIT_SUCCESS',
                            status: response.status,
                            promotion: response.data,
                            languages,
                            countries,
                            centers
                        })
                    })
                })
            } else {
                return dispatch({
                    type: 'FETCH_PROMOTION_TO_EDIT_SUCCESS',
                    status: response.status,
                    promotion: response.data,
                    languages
                })
            }
        })
    })
    // .catch(error => {
    //     return dispatch({ type: 'FETCH_PROMOTION_TO_EDIT_FAILURE', status: error.response.status, alert: error.response.data.err })
    // })
}

export const fetchBanners = (pageSelected, search, type) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PROMOTIONS_BANNERS_REQUEST' })
    return api
        .getBanners(getToken(getState()), pageSelected, search, type)
        .then(response => {
            return dispatch({
                type: 'FETCH_PROMOTIONS_BANNERS_SUCCESS',
                status: response.status,
                bannersPaginatedResult: response.data
            })
        })
        .catch(error => {
            return dispatch({
                type: 'FETCH_PROMOTIONS_BANNERS_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
        })
}

export const fetchPromotionData = promotionId => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PROMOTION_DATA_REQUEST' })
    return api
        .getPromotionData(getToken(getState()), promotionId, getSelectedCenter(getState()), getLanguage(getState()))
        .then(response => {
            return dispatch({ type: 'FETCH_PROMOTION_DATA_SUCCESS', status: response.status, data: response.data })
        })
        .catch(error => {
            return dispatch({
                type: 'FETCH_PROMOTION_DATA_FAILURE',
                status: error.response.status,
                alert: error.response.data.err
            })
        })
}

export const sendPreviewPromotionEmail = (promotionId, email, userSelected) => (dispatch, getState) => {
    dispatch({ type: 'SEND_PREVIEW_PROMOTION_EMAIL_REQUEST' })
    return api
        .sendPreviewPromotionEmail(getToken(getState()), promotionId, email, userSelected, getSelectedCenter(getState()), getLanguage(getState()))
        .then(response => {
            return dispatch({
                type: 'SEND_PREVIEW_PROMOTION_EMAIL_SUCCESS',
                status: response.status,
                alert: response.data
            })
        })
        .catch(error => {
            return dispatch({ type: 'SEND_PREVIEW_PROMOTION_EMAIL_FAILURE', status: error.response.status })
        })
}

export const enablePromotion = promotionId => (dispatch, getState) => {
    dispatch({ type: 'SEND_ENABLE_PROMOTION_REQUEST', promotionId })
    return api
        .enablePromotion(getToken(getState()), promotionId)
        .then(() => {
            return dispatch({ type: 'SEND_ENABLE_PROMOTION_SUCCESS', promotionId })
        })
        .catch(error => {
            return dispatch({ type: 'SEND_ENABLE_PROMOTION_FAILURE', promotionId, error: error.response })
        })
}
export const disablePromotion = promotionId => (dispatch, getState) => {
    dispatch({ type: 'SEND_DISABLE_PROMOTION_REQUEST', promotionId })
    return api
        .disablePromotion(getToken(getState()), promotionId)
        .then(() => {
            return dispatch({ type: 'SEND_DISABLE_PROMOTION_SUCCESS', promotionId })
        })
        .catch(error => {
            return dispatch({ type: 'SEND_DISABLE_PROMOTION_FAILURE', promotionId })
        })
}

export const getPromotionByCenterId = centerId => (dispatch, getState) => {
    centerId = centerId ? centerId : getSelectedCenter(getState())
    dispatch({ type: 'FETCH_PROMOTION_ACTIVE_BY_CENTER_AND_USER_REQUEST', center: centerId })
    return api
        .getPromotionByCenterId(getToken(getState()), centerId)
        .then(response => {
            return dispatch({
                type: 'FETCH_PROMOTION_ACTIVE_BY_CENTER_AND_USER_SUCCESS',
                promotion: response.data,
                promotionId: response.data[centerId] ? response.data[centerId].id : null
            })
        })
        .catch(error => {
            return dispatch({ type: 'FETCH_PROMOTION_ACTIVE_BY_CENTER_AND_USER_FAILURE' })
        })
}
export const getAllProductOfTheLastQueryMultiCenterByUser = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_PRODUCTS_OF_THE_LAST_QUERY_MULTI_CENTER_REQUEST' })
    return api
        .getAllProductOfTheLastQueryMultiCenterByUser(getToken(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_PRODUCTS_OF_THE_LAST_QUERY_MULTI_CENTER_SUCCESS', products: response.data.products })
            return response.data.products
        })
        .catch(error => {
            dispatch({ type: 'FETCH_PRODUCTS_OF_THE_LAST_QUERY_MULTI_CENTER_FAILURE' })
            return []
        })
}

export const getDataToResolveDiscount = (promotionId, centers) => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)

    dispatch({ type: 'FETCH_DATA_TO_RESOLVE_DISCOUNT_REQUEST' })
    return api
        .getDataToResolveDiscount(token, promotionId, centers)
        .then(response => {
            return dispatch({
                type: 'FETCH_DATA_TO_RESOLVE_DISCOUNT_SUCCESS',
                resolveDiscountData: response.data
            })
        })
        .catch(error => {
            return dispatch({ type: 'FETCH_DATA_TO_RESOLVE_DISCOUNT_FAILURE' })
        })
}

export const fetchDiscountMaterialsData = (promotionId) => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)

    dispatch({ type: 'FETCH_DISCOUNT_MATERIALS_DATA_REQUEST' })
    return api
        .fetchDiscountMaterialsData(token, promotionId)
        .then(response => {
            return dispatch({
                type: 'FETCH_DISCOUNT_MATERIALS_DATA_SUCCESS',
                materialsData: response.data
            })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_DISCOUNT_MATERIALS_DATA_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}

export const fetchDiscountCustomersData = (promotionId) => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)

    dispatch({ type: 'FETCH_DISCOUNT_CUSTOMERS_DATA_REQUEST' })
    return api
        .fetchDiscountCustomersData(token, promotionId)
        .then(response => {
            return dispatch({
                type: 'FETCH_DISCOUNT_CUSTOMERS_DATA_SUCCESS',
                customersData: response.data
            })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_DISCOUNT_CUSTOMERS_DATA_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}

export const checkPromotionId = (promotionId, vistexId) => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)

    dispatch({ type: 'CHECK_PROMOTION_DISCOUNTS_REQUEST' })
    return api
        .checkPromotionDiscount(token, promotionId, vistexId)
        .then(response => {
            dispatch({type: 'CHECK_PROMOTION_DISCOUNTS_REQUEST_SUCCESS'})
            return {isValid: response.data.isValid}
        })
        .catch(error => {
            dispatch({ type: 'CHECK_PROMOTION_DISCOUNTS_REQUEST_FAILURE' })

            return {
                status: error.response.status,
                error: error.response.err
            }
        })
}

export const applyCampaignDiscount = (promotionId, vistex_ids, comments) => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)
    const lang = getLanguage(state)

    dispatch({ type: 'SEND_APPROVE_CAMPAIGN_DISCOUNTS_REQUEST' })
    return api
        .sendApproveCampaignDiscount(token, promotionId, vistex_ids, comments, lang)
        .then(response => {
            dispatch({type: 'SEND_APPROVE_CAMPAIGN_DISCOUNTS_SUCCESS'})
            return true
        })
        .catch(error => {
            dispatch({type: 'SEND_APPROVE_CAMPAIGN_DISCOUNTS_FAILURE'})
            return false
        })
}

export const denyCampaignDiscountStatus = (promotionId, reason) => (dispatch, getState) => {
    const state = getState()
    const token = getToken(state)
    const lang = getLanguage(state)

    dispatch({ type: 'SEND_DENY_CAMPAIGN_DISCOUNTS_REQUEST' })
    return api
        .sendDenyCampaignDiscount(token, promotionId, reason, lang)
        .then(response => {
            dispatch({type: 'SEND_DENY_CAMPAIGN_DISCOUNTS_SUCCESS'})
            return true
        })
        .catch(error => {
            dispatch({type: 'SEND_DENY_CAMPAIGN_DISCOUNTS_FAILURE'})
            return false
        })
}

export const clonePromotion = (promotionId) => (dispatch, getState) => {
    const token = getToken(getState())

    dispatch({ type: 'SEND_CLONE_PROMOTION_REQUEST' })

    return api.sendClonePromotion(token, promotionId).then(response => {
            dispatch({
                type: 'SEND_CLONE_PROMOTION_SUCCESS',
                status: response.data.status
            })
            dispatch({
                type: 'FLOATING_ALERTS_ADD_SUCCESS_MESSAGE',
                successMessage: 'THE_PROMOTION_HAVE_BEEN_CLONED_SUCCESSFULLY',
            })
        },
        error => {
            dispatch({
                type: 'SEND_CLONE_PROMOTION_FAILURE',
                status: error.response.status
            })
            dispatch({
                type: 'FLOATING_ALERTS_ADD_FAILURE_MESSAGE',
                failureMessage: 'AN_ERROR_HAS_OCCURRED_CLONING_THIS_CAMPAIGN'
            })
        }
    )
}
