import React from 'react'
import { injectIntl } from "react-intl";

const FilterTag = props => {

    return (
        <div className="filter__tag">
            <span className="filter__tag--text">
                <span>{props.label}:&nbsp;{props.intl.formatMessage({ id: props.value })}</span>
            </span>
            <span className="filter__tag--close">
                <button onClick={props.onClose}>
                X
                </button>
            </span>
        </div>
    )
}

export default injectIntl(FilterTag)