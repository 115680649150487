import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import { toggleMenu, showPersonalData } from '../../../store/ui/actions'
import { showMenuSelector, showPersonalDataSelector } from '../../../store/ui/reducers'

const mapDispatchToProps = dispatch => {
    return {
        onToggleMenu: active => {
            dispatch(toggleMenu(active))
        },
        onTogglePersonalData: active => {
            dispatch(showPersonalData(active))
        }
    }
}

const mapStateToProps = state => {
    return {
        showMenu: showMenuSelector(state),
        showPersonalData: showPersonalDataSelector(state)
    }
}

class GoBackButton extends React.Component {
    render() {
        return (
            <div className="go-back-profile">
                <i className="fal fa-chevron-left"></i>
                <span
                    className="label label-v2"
                    onClick={() => {
                        if (!this.props.showMenu) this.props.onToggleMenu(this.props.showMenu)
                        if (this.props.showMenu && this.props.showPersonalData) {
                            this.props.onToggleMenu(this.props.showMenu)
                        }
                        this.props.onTogglePersonalData(this.props.showPersonalData)
                    }}
                >
                    <Translation id="back_to_personal_area" defaultMessage="Back to personal area" />
                </span>
            </div>
        )
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(GoBackButton))
