import React from 'react'
import { capitalizeFirstLetter } from '../../../helpers'

export function addForwardSlash(...args) {
    // Filter out null or undefined values from the array
    const filteredArgs = args.filter(arg => arg !== null && arg !== undefined)
    
    // Concatenate the remaining values with ' / ' as the separator
    return filteredArgs && filteredArgs.join(' / ')
}


/**
 * @param {*} props 
 * @returns React slick next arrow
 */
export function SliderNextArrow(props) {
    const { style, onClick } = props
    return (
        <i
            className={'fal fa-chevron-right'}
            style={{ ...style, display: 'block', position: 'absolute', right: 0, top: '42%', fontSize: '26px', cursor: 'pointer', zIndex: '10', color: '#000000' }}
            onClick={onClick}
        />
    )
}

/**
 * @param {*} props 
 * @returns React slick prev arrow
 */
export function SliderPrevArrow(props) {
    const { style, onClick } = props
    return (
        <i
            className={'fal fa-chevron-left'}
            style={{ ...style, display: 'block', position: 'absolute', top: '42%', fontSize: '26px', cursor: 'pointer', zIndex: '10', color: '#000000' }}
            onClick={onClick}
        />
    )
}

/**
 * 
 * @param {*} props 
 * @returns Display the shipping address of the user 
 */
export function DisplayShippingAddress(props) {
    let { shippingAddress, shippingAddressId, shippingAddressesByUser } = props
    if (!shippingAddress) {
        shippingAddress =
            shippingAddressesByUser && shippingAddressId
                ? shippingAddressesByUser.filter(shippingAddress => {
                      return parseInt(shippingAddress.shippingAddressId) === parseInt(shippingAddressId)
                  })[0]
                : null
    }

    return shippingAddress ? (
        <span>
            {shippingAddress.customerName}&nbsp;{shippingAddress.address}&nbsp;{shippingAddress.zipCode}&nbsp;{shippingAddress.city}&nbsp;{shippingAddress.country}
        </span>
    ) : (
        '--'
    )
}

/**
 * Cut to size materials id
 */
export const CUT_TO_SIZE_MATERIALS = ['80457', '80461', '80451', '80458']

export function getConfigLineName(config) {
    const productName = config.product ? capitalizeFirstLetter(config.product) : ''
    const modelName = config.model ? capitalizeFirstLetter(config.model) : ''

    return `${modelName} ${productName}`;
}