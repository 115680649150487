import { connect } from 'react-redux'
import CartSummary from './CartSummary'
import { removeCartLine, changeCartLineQty, changeCartLineSegmentation } from '../../store/cart/actions'
import { totalSelector, qtySelector, getLines, getCalculatingLinesPrice, totalCoin, isMallCartForContainer, getGoalMin } from '../../store/cart/reducers'
import { getShippingMethod, getCoin, getIsIkeaCenter } from '../../store/centers/reducers'
import { getBusinesSegmentOptions, getUseOptions } from '../../store/segmentation/reducers'
import { openBundleContent, showSegmentationModal } from '../../store/ui/actions'
import { loadThisSegmentation } from '../../store/segmentation/actions'
import { canViewPricesPhase2 } from '../../store/products/reducers'
import { getLanguage } from '../../store/ui/reducers'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit
    return {
        lines: getLines(state),
        total: totalSelector(state),
        totalQty: qtySelector(state),
        segmentation: getBusinesSegmentOptions(state),
        shippingMethod: getShippingMethod(state),
        use: getUseOptions(state),
        coin: getCoin(state),
        isIkeaCenter: getIsIkeaCenter(state),
        calculatingLinesPrice: getCalculatingLinesPrice(state),
        totalCoin: totalCoin(state),
        canViewPricesPhase2: canViewPricesPhase2(state),
        lang: getLanguage(state),
        isMallCartForContainer: isMallCartForContainer(state),
        goalMin: getGoalMin(state),
        unit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDelete: lineId => {
            dispatch(removeCartLine(lineId))
        },
        changeCartLineQty: (lineId, qty) => {
            dispatch(changeCartLineQty(lineId, qty))
        },
        openBundleContent: product => {
            dispatch(openBundleContent(product))
        },
        loadThisSegmentation: seg => {
            dispatch(loadThisSegmentation(seg))
        },
        showSegmentationModal: (mode, callback) => {
            dispatch(showSegmentationModal(mode, callback))
        },
        changeCartLineSegmentation: lineId => {
            dispatch(changeCartLineSegmentation(lineId))
        }
    }
}

const CartSummaryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartSummary)

export default CartSummaryContainer
