import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { ElaborationOptionsMap } from './CutToSizeMaps'
import imageFurniture from '../../assets/img/cut-to-size/pre-order-banner.png'
import {connect} from "react-redux";
import {dispatchPushURL} from "../../store/ui/actions";
import history from "../global/history";
import Translation from "../global/Translation";
import Button from "../global/Button";
import {canViewPreorder} from "../../store/login/reducers";
import LocalizedLink from "../global/LocalizedLink";
class ElaborationDetails extends Component {

    render () {
        const {intl, modelImage, hasDetail, showDetail, currentModelOption} = this.props
        const id = this.props.id === 'PREORDER' ? 'BUDGET' : this.props.id
        const option = ElaborationOptionsMap[id]
        const title = intl.formatMessage(option.name)
        const image = modelImage ? (process.env.REACT_APP_API_HOST + modelImage[currentModelOption]) : option.image
        const optionModels = modelImage && modelImage.map((m, index) => {return {image:process.env.REACT_APP_API_HOST + m, index}}).filter(m => m.index !== currentModelOption)

        return (
            <div className="cut_to_size_elaboration_details">
                <div className="cut_to_size_elaboration_details_model_image" style={{ position: 'relative' }}>
                    <h2 className="questa-bold">{title}</h2>
                    <img src={image} alt={title} width="100%" />

                    { id !== 'BUDGET' &&
                        <div style={{position:"relative"}}>
                            <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} header={true} queryString={`?type=${this.props.hasPreOrder ? 'preorder' : 'budget'}`} params={{'outlet':''}}>
                            <img className="mt-2" src={imageFurniture} alt={title} width="100%"/>
                            <Button size="small"
                                    customClass="bt-cts-inverted my-3"
                                    style={{
                                        position:"absolute",
                                        top:'72%',
                                        left:'50%',
                                        borderColor:'white',
                                        transform:'translateX(-50%)'
                                    }}
                            >
                                <Translation id="request_for_preorder" defaultMessage="Request an order/offer" />
                            </Button>
                            </LocalizedLink>
                        </div>
                    }

                    {hasDetail && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%)',
                                borderRadius: '100%',
                                backgroundColor: '#D8D8D8',
                                opacity: '0.65',
                                width: '48px',
                                height: '48px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            onClick={showDetail}
                        >
                            <i className="fas fa-search-plus 2x" style={{ margin: 'auto', color: 'black', fontSize: '1.4em', fontWeight: '500' }} />
                        </div>
                    )}
                </div>
                {optionModels && optionModels.length > 0 && (
                    <div className="cut_to_size_elaboration_details_model_options mt-3">
                        {optionModels.map(option => {
                            const key = 'option-' + option.index

                            return (
                                <div className="cut_to_size_elaboration_details_model_option m-2 p-2" key={key}>
                                    <img
                                        src={option.image}
                                        alt={key}
                                        height="100px"
                                        onClick={() => {
                                            this.props.setCurrentModelOption(option.index)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )}
                {/* {modelInfo && (
                    <div className="cut_to_size_elaboration_details_model_info mt-4 ml-2">
                        {
                            modelInfo.map((v, index) => {
                                return (
                                    <div className="cut_to_size_elaboration_details_model_info_row" key={'model_info_' + index}>
                                        {`${v.label}: ${v.value}`}
                                    </div>
                                )
                            })
                        }                  
                    </div>
                )}

                {genericInfo && (
                    <div className="cut_to_size_elaboration_details_model_info mt-4 ml-2">
                        {
                            genericInfo.map((v, index) => {
                                return (
                                    <div className="cut_to_size_elaboration_details_model_info_row" key={'generic_info_' + index}>
                                        {`${v.label}: ${v.value}`}
                                    </div>
                                )
                            })
                        }                  
                    </div>
                )} */}

                {/* <p className="cts-detail-text">
                    { option.text }
                </p> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hasPreOrder: canViewPreorder(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        goToURL: (url) => {
            return dispatch(dispatchPushURL(url, 1))
        }
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ElaborationDetails)
)