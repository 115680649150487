import React from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import Button from '../../global/Button'
import Select from 'react-select'
import confirm from '../../global/confirm'

class ChooseArea extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            market: this.props.market,
            countries: this.props.countries,
            centers: this.props.centers,
            //Unsaved changes
            hasUnsavedChanges: {
                promotionMarketDirty: false,
                promotionCountriesDirty: false,
                promotionCentersDirty: false
            }
        }

        if (!this.props.centers || !(this.props.centers && this.props.centers.length)) {
            this.props.getMarkets().then(() => {
                let markets = this.getMarkets()
                if (markets.length === 1) {
                    this.handleMarketChange(markets[0])
                }
            })
        }
    }

    componentDidMount(){
        this.props.handleReference(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fetchedCenters !== null && this.props.fetchedCenters !== null && JSON.stringify(prevProps.fetchedCenters) !== JSON.stringify(this.props.fetchedCenters)) {
            let centers = this.state.centers.filter(center => {
                return this.props.fetchedCenters.filter(fetched => fetched.id === center.value).length > 0
            })
            this.setState({ centers: centers })
        }
    }

    getMarkets = () => {
        return this.props.fetchedMarkets
            .map(market => {
                return { value: market.name, label: market.name, salesOrg: market.salesOrg }
            })
            .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    }

    getCountries = () => {
        return Object.keys(this.props.fetchedCountries)
            .map(country => {
                return {
                    value: country,
                    label: this.props.intl.formatMessage({ id: 'country_' + country }),
                    salesOrg: this.props.fetchedCountries[country]
                }
            })
            .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    }

    getCenters = () => {
        return this.props.fetchedCenters
            .map(center => {
                return { value: center.id, label: center.id + ' ' + center.name, salesOrg: center.salesOrg }
            })
            .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
    }

    handleMarketChange = selectedOption => {
        if (selectedOption !== null) {
            this.setState(
                {
                    market: selectedOption.value,
                    countries: [],
                    centers: []
                },
                () => {
                    this.props.getCountries(selectedOption.salesOrg).then(() => {
                        let countries = this.getCountries()
                        if (countries.length === 1) {
                            this.handleCountriesChange([countries[0]])
                        }
                    })

                    // Handle unsaved changes for input market
                    this.handleMarketUnsavedChanges()
                }
            )
        } else {
            this.setState({ market: selectedOption })
        }
    }

    handleCountriesChange = selectedOption => {
        if (selectedOption !== null && Object.keys(selectedOption).length > 0) {
            let selected = selectedOption
            let salesOrgs = []
            selectedOption.forEach(country => {
                country.salesOrg.forEach(salesOrg => {
                    //For multiples salesorg at country
                    salesOrgs.push(salesOrg)
                })
            })
            this.setState({ countries: selected, centers: [] }, () => {
                //Call the update of unsaved changes state
                this.handleCountriesUnsavedChanges()
                    this.props.getCenters(salesOrgs).then(() => {
                        let centers = this.getCenters()
                        if (centers.length === 1) {
                            this.handleCentersChange([centers[0]])
                        }
                    })
                }
            )
        } else {
            //If empty select clear centers selection
            this.setState({ countries: selectedOption, centers: [] })
        }
    }

    handleCentersChange = selectedOption => {
        if (selectedOption !== null) {
            let selected = selectedOption
            this.setState({ centers: selected }, () => {
                this.handleCentersUnsavedChanges()
            })
        } else {
            this.setState({ centers: selectedOption })
        }
    }

    compareArrayOfObjectsByValue(oldObjects, newObjects) {
        let isEquivalent = oldObjects.length === newObjects.length
        if (isEquivalent) {
            isEquivalent = oldObjects.every(oldObject => newObjects.find(newObject => oldObject.value === newObject.value))
        }
        return isEquivalent
    }

    handleSubmit = e => {
        if (e) e.preventDefault()
        if (
            (this.props.market && this.props.market !== this.state.market) ||
            (this.props.countries.length && !this.compareArrayOfObjectsByValue(this.props.countries, this.state.countries)) ||
            (this.props.centers.length && !this.compareArrayOfObjectsByValue(this.props.centers, this.state.centers))
        ) {
            confirm(this.props.intl.formatMessage({ id: 'promotion_confirm_clean_customers_materials' }), {intl: this.props.intl}).then(
                confirm => {
                    this.props.sendCustomersSelected([])
                    this.props.sendMaterialsSelected([])
                    
                    //No more unsaved changes after send the form for step two
                    this.setState({hasUnsavedChanges: {
                        ...this.state.hasUnsavedChanges,
                        promotionMarketDirty: false,
                        promotionCountriesDirty: false,
                        promotionCentersDirty: false
                    }}, () => {
                        this.props.sendPromotionArea(this.state.market, this.state.countries, this.state.centers)
                        //Update global state after form submit
                        this.props.setUnsavedChangesStepTwo(this.state.hasUnsavedChanges)
                        //Make sure the change step is the last function
                        
                        //Only if event exists we use this change step. If not we use the one inside StepsBar component
                        if (e) {
                            this.props.changeStep(this.props.step + 1)
                        }
                    })
                    
                },
                cancel => {}
            )
        } else {
            //No more unsaved changes after send the form for step two
            this.setState({hasUnsavedChanges: {
                ...this.state.hasUnsavedChanges,
                promotionMarketDirty: false,
                promotionCountriesDirty: false,
                promotionCentersDirty: false
            }}, () => {
                this.props.sendPromotionArea(this.state.market, this.state.countries, this.state.centers)
                //Update global state after form submit
                this.props.setUnsavedChangesStepTwo(this.state.hasUnsavedChanges)
                //Make sure the change step is the last function

                //Only if event exists we use this change step. If not we use the one inside StepsBar component
                if (e) {
                    this.props.changeStep(this.props.step + 1)
                }
            })
        }
    }

    /**
     * Handle the state value markets unsaved changes.
     */
    handleMarketUnsavedChanges = () => {
        const stateMarket = this.state.market
        const propsMarket = this.props.market

        if (propsMarket !== stateMarket) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionMarketDirty: true} }, () => {
                this.props.setUnsavedChangesStepTwo(this.state.hasUnsavedChanges)
            })
        } else if (propsMarket === stateMarket) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionMarketDirty: false} }, () => {
                this.props.setUnsavedChangesStepTwo(this.state.hasUnsavedChanges)
            })
        }
    }

    /**
     * Handle the state value countries unsaved changes.
     */
    handleCountriesUnsavedChanges = () => {
        const stateCountries = this.state.countries
        const propsCountries = this.props.countries
        
        if ((propsCountries && propsCountries.length) !== (stateCountries && stateCountries.length)) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCountriesDirty: true} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepTwo(this.state.hasUnsavedChanges)
            })
        } else if ((propsCountries && propsCountries.length) === (stateCountries && stateCountries.length)) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCountriesDirty: false} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepTwo(this.state.hasUnsavedChanges)
            })
        }
    }

    /**
     * Handle the state value centers unsaved changes.
     */
    handleCentersUnsavedChanges = () => {
        const stateCenters = this.state.centers
        const propsCenters = this.props.centers

        if ((propsCenters && propsCenters.length) !== (stateCenters && stateCenters.length)) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCentersDirty: true} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepTwo(this.state.hasUnsavedChanges)
            })
        } else if ((propsCenters && propsCenters.length) === (stateCenters && stateCenters.length)) {
            this.setState({ hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionCentersDirty: false} }, () => {
                //Update global state
                this.props.setUnsavedChangesStepTwo(this.state.hasUnsavedChanges)
            })
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-lg-10">
                        <h3 style={{ fontWeight: '400' }}>
                            <span style={{ fontWeight: '300' }}>
                                <Translation id="step" defaultMessage={'Step'} /> {this.props.step}
                            </span>{' '}
                            <Translation id="promotion_line_market_target" defaultMessage="Market target" />
                        </h3>
                        <p>
                            <Translation
                                id="select_the_markets_countries_and_centers_to_apply_your_campaign"
                                defaultMessage="Select the markets, countries and centers to apply your campaign"
                            />
                        </p>
                    </div>
                </div>
                <form id="form_current_step" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group select_market">
                                <label htmlFor="select_market">
                                    <Translation id="promotion_markets" defaultMessage="Markets:" />
                                </label>
                                <Select
                                    required
                                    id="select_market"
                                    className="form-control"
                                    placeholder={this.props.intl.formatMessage({ id: 'promotion_select_market' })}
                                    value={this.state.market}
                                    onChange={selectedOption => this.handleMarketChange(selectedOption)}
                                    options={this.getMarkets()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group select_countries">
                                <label htmlFor="select_countries">
                                    <Translation id="promotion_countries" defaultMessage="Countries:" />
                                </label>
                                <Select
                                    required
                                    id="select_countries"
                                    multi={true}
                                    className="form-control"
                                    placeholder={this.props.intl.formatMessage({ id: 'promotion_select_countries' })}
                                    value={this.state.countries}
                                    onChange={selectedOption => this.handleCountriesChange(selectedOption)}
                                    options={this.getCountries()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group select_countries">
                                <label htmlFor="select_centers">
                                    <Translation id="promotion_centers" defaultMessage="Centers:" />
                                </label>
                                <Select
                                    required
                                    id="select_centers"
                                    multi={true}
                                    className="form-control"
                                    placeholder={this.props.intl.formatMessage({ id: 'promotion_select_centers' })}
                                    value={this.state.centers}
                                    onChange={selectedOption => this.handleCentersChange(selectedOption)}
                                    options={this.getCenters()}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default ChooseArea = withRouter(injectIntl(ChooseArea))