import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import logo from '../../assets/img/e.cosentino.svg'
// import logo from '../../assets/img/DKTN.svg'

import AlertsContainer from '../alerts/AlertsContainer'
import Spinner from '../global/Spinner'
import Modal from '../global/Modal'
import CosentinoSingleSignOn from './CosentinoSingleSignOn'
import Alerts from '../alerts/Alerts'
import { MsalContext } from '@azure/msal-react'
import { withMsal } from '@azure/msal-react'
import { PublicClientApplication, EventType, InteractionRequiredAuthError,BrowserAuthError } from '@azure/msal-browser'
import axios from 'axios'
import InfoBox from "../global/InfoBox";

class SignInForm extends React.Component {
    static contextType = MsalContext

    state = { error: null, showOldLogin:false, isInfoHidden: false }
    handleSubmit = e => {
        e.preventDefault()
        this.props.onLogin(this.usernameInput.value, this.passwordInput.value, this.props.callback, this.props.callbackCenter, this.props.intl, null, this.props.callbackSalesOrg)
    }

    handleSubmitAD = (ssoData) => {
        let accountsSortedByLastLogin = []
        if (this.props.msalContext.accounts && this.props.msalContext.accounts.length > 0){
            accountsSortedByLastLogin = this.props.msalContext.accounts.sort((a, b) => {
                //sort descending, last logged in on position 0
                return b.idTokenClaims.auth_time - a.idTokenClaims.auth_time
            })
        }
            
            this.setState({ lastLoginAccount: accountsSortedByLastLogin && accountsSortedByLastLogin[0] && accountsSortedByLastLogin[0].name })
        var request = {
            scopes: [process.env.REACT_APP_APP_ID + ' openid offline_access'],
            account: accountsSortedByLastLogin && accountsSortedByLastLogin[0],
            // grant_type: 'client_credentials',
            // client_id: 'f920199a-0f95-4bad-8256-0f60b16ed1ac',
            // client_secret: '00C6FKE13rRpT5CxP1.-w2j.-cWs~c1mh8',
            // resource: 'https://graph.microsoft.com'
        }
        // console.log('aqcuiring token',request)
        this.props.msalContext.instance
            .acquireTokenSilent(request)
            .then(tokenResponse => {
                // Do something with the tokenResponse
                // console.log('token ad')
                // console.log('tokenResponse.accessToken: ', tokenResponse.accessToken)

                axios
                    .get(process.env.REACT_APP_INSTANCE + process.env.REACT_APP_TENANT_NAME + '/' + process.env.REACT_APP_SIGN_IN_POLICY + '/openid/v2.0/userinfo', {
                        headers: { Authorization: `Bearer ${tokenResponse.accessToken}` }
                    })
                    .then(response => {
                        // console.log(response)
                        ssoData.type = 'AD'
                        ssoData.username = response.data.email
                        ssoData.azureToken = tokenResponse.accessToken
                        this.setState({ lastLoginAccount: response.data.email })
                        this.props.onLogin(ssoData.username, null, this.props.callback, this.props.callbackCenter, this.props.intl, ssoData, this.props.callbackSalesOrg)
                    })
            })
            .catch(async error => {
                // console.log('error')
                // console.log(error)
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return this.props.msalContext.instance.acquireTokenPopup(request)
                }
                if(error instanceof BrowserAuthError){
                    this.context.instance.loginRedirect()
                }
            })
            .catch(error => {
                // console.log('error')

                // handleError(error)
            })
    }
    componentDidMount = () => {
        const msalInstance = this.props.msalContext.instance
        const isAuthenticated = this.props.msalContext.accounts.length > 0
        // if (this.context.inProgress === 'none'){
        // console.log('llamando a loginAD desde didmount and account available')
        // console.log(document.referrer)
        if (
            (document.referrer.includes('we.cosentino.com') || document.referrer.includes('pro.cosentino.com') || document.referrer.includes('qas-cosentino')) &&
            !this.props.isLogged
        ) {
            this.handleSubmitAD({})
        }

        this.setState({...this.state, isInfoHidden: false});
         
        // } 
        // console.log('context info')
        // console.log(this.props.msalContext.instance.getAllAccounts())
        // msalInstance.addEventCallback(event => {
        //     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        //         const account = event.payload.account
        //         console.log(event.payload)
        //         console.log('llamando a loginAD desde mount addeventcallback')
        //         this.handleSubmitAD(account)
        //     }
        // })
        // if (this.props.msalContext.accounts && this.props.msalContext.accounts && this.props.msalContext.accounts[0]) {
        //     console.log('llamando a loginAD desde didmount and account available')
        //     this.handleSubmitAD(this.props.msalContext.accounts[0])
        // }
        // if(isAuthenticatedOnAD,handleSubmitAD)
    }
    // trickSSO = ssoData => {
    //     console.log(ssoData)
    //     ssoData.type = 'SSO'
    //     this.props.onLogin(ssoData.sapId, null, this.props.callback, this.props.callbackCenter, this.props.intl, ssoData)
    // }
    componentDidUpdate = prevProps => {
        if (prevProps.msalContext.accounts !== this.props.msalContext.accounts && this.props.msalContext.accounts && this.props.msalContext.accounts[0]) {
            // console.log('llamando a loginAD desde didupdate and account available')
            if (this.context.inProgress !== 'none' && !this.props.isLogged) {
                this.handleSubmitAD({})
            }
        }
        if(prevProps.isLogged !== this.props.isLogged){
            this.setState({...this.state, isInfoHidden: false})
        }
    }
    render() {
        const { isLogging } = this.props
        const spinner = isLogging || this.context.inProgress !== 'none' ? <Spinner isLoading={isLogging} /> : null
        const isAuthenticated = this.props.msalContext.accounts.length > 0
        const msalInstance = this.props.msalContext.instance

        // console.log('context')
        //console.log('this.props.msalContext: ', this.props.msalContext)
        return (
            <div className="form-signin">
                <form onSubmit={this.handleSubmit}>
                    <AlertsContainer values={{ lastLoginAccount: this.state.lastLoginAccount }} />
                    {this.state.error && <Alerts alert={this.state.error} status="404" />}
                    {spinner}
                    <div className="d-block d-lg-none">
                        <img alt="" id="profile-img" className="profile-img-card" src={logo} style={{ marginBottom: '30px' }} />
                    </div>
                    <div className="azure-login">
                        <h6 htmlFor="email" style={{ color: 'black' }}>
                            <Translation id="new_access" defaultMessage="New Access" />
                        </h6>

                        {this.props.alert == 'WRONG_CREDENTIALS_AZURE' ? (
                            <input
                                type="button"
                                className="bt bt-we bt-login loginbutton"
                                onClick={() => this.context.instance.loginRedirect({ prompt: 'login' })}
                                value={this.props.intl.formatMessage({ id: 'login_with_another_account' })}
                                style={{ width: '100%' }}
                            />
                        ) : (
                            <input
                                type="button"
                                className="bt bt-we bt-login loginbutton"
                                onClick={() => this.context.instance.loginRedirect()}
                                value={this.props.intl.formatMessage({ id: 'login_with_your_email' })}
                            />
                        )}

                        {/* <div className="text-center">
                            <Translation id="dont_have_a_registered_email_yet" defaultMessage="Dont have a registered email yet?" />
                            &nbsp;
                            <a href="https://we.cosentino.com/s/register?language=en_GB" target="_blank" style={{ color: '#007bff', textDecoration: 'underline !important' }}>
                                <Translation id="update_your_account_now" defaultMessage="Update your account now!" />
                            </a>
                        </div> */}
                    </div>

                    {process.env.NODE_ENV !== 'production' && (
                        <React.Fragment>

                            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.setState({ showOldLogin: !this.state.showOldLogin })}>
                                <Translation id="click_here_" defaultMessage="Click here!" />
                            </span>

                            <div className={`identifier-login ${this.state.showOldLogin ? '' : 'd-none'}`}>
                                <label htmlFor="email">
                                    <Translation id="identifier" defaultMessage="Identifier" />
                                </label>
                                <input
                                    type="text"
                                    id="inputEmail"
                                    className="form-control"
                                    placeholder={this.props.intl.formatMessage({ id: 'identifier' })}
                                    autoFocus
                                    ref={node => {
                                        this.usernameInput = node
                                    }}
                                />
                                <label htmlFor="email">
                                    <Translation id="password" defaultMessage="Password" />
                                </label>
                                <input
                                    type="password"
                                    id="inputPassword"
                                    className="form-control mb-3"
                                    placeholder={this.props.intl.formatMessage({ id: 'password' })}
                                    ref={node => {
                                        this.passwordInput = node
                                    }}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            this.handleSubmit(e)
                                        }
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </form>
            </div>
        )
    }
}
export default withMsal(injectIntl(SignInForm))
