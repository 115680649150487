import React from "react"
import customProductIcon from "../../../assets/img/logos/custom-product-icon.svg"

const CustomProductIndicator = () => {
    return (
        <div className="custom-product-indicator">
            <img src={customProductIcon} alt="Custom" />
        </div>
    )
}

export default CustomProductIndicator