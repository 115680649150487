import React from 'react'

import { connect } from 'react-redux'
import createClass from 'create-react-class'
import Select from 'react-select'

import PropTypes from 'prop-types'

const stringOrNode = PropTypes.oneOfType([PropTypes.string, PropTypes.node])

const productOption = createClass({
    propTypes: {
        children: PropTypes.node,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
        isFocused: PropTypes.bool,
        isSelected: PropTypes.bool,
        onFocus: PropTypes.func,
        onSelect: PropTypes.func,
        option: PropTypes.object.isRequired
    },
    handleMouseDown(event) {
        event.preventDefault()
        event.stopPropagation()
        this.props.onSelect(this.props.option, event)
    },
    handleMouseEnter(event) {
        this.props.onFocus(this.props.option, event)
    },
    handleMouseMove(event) {
        if (this.props.isFocused) return
        this.props.onFocus(this.props.option, event)
    },
    render() {
        let imageStyle = {
            borderRadius: 3,
            display: 'inline-block',
            marginRight: 10,
            position: 'relative',
            top: -2,
            verticalAlign: 'middle',
            height: '20px'
        }
        return (
            <div
                className={this.props.className}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
                title={this.props.option.title}
            >
                <img src={this.props.option.img} alt="" style={imageStyle} />

                {this.props.children}
            </div>
        )
    }
})
const productValue = createClass({
    propTypes: {
        children: PropTypes.node,
        placeholder: stringOrNode,
        value: PropTypes.object
    },
    render() {
        let imageStyle = {
            borderRadius: 3,
            display: 'inline-block',
            marginRight: 10,
            position: 'relative',
            top: -2,
            verticalAlign: 'middle',
            height: '20px'
        }
        return (
            <div className="Select-value" title={this.props.value.title}>
                <span className="Select-value-label">
                    <img src={this.props.value.img} alt="" style={imageStyle} />

                    {this.props.children}
                </span>
            </div>
        )
    }
})
const mapDispatchToProps = dispatch => {
    return {}
}
const mapStateToProps = dispatch => {
    return {}
}
const ColorSelector = ({ products, onChange, value }) => {
    return (
        <Select
            placeholder="Choose Product"
            options={products.map(product => ({
                label: product.name,
                value: product.productId,
                productId: product.productId,
                img: product.img,
                lowesCode: product.lowesCode
            }))}
            value={value}
            onChange={selectedOption => onChange(selectedOption)}
            optionComponent={productOption}
            valueComponent={productValue}
        />
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorSelector)
