import { connect } from 'react-redux'
import OfferStatusHistory from './OfferStatusHistory'
import { getOffers, getOrdersLoading, canEditOrders, canDeleteOrders } from '../../store/orders/reducers'
import { injectIntl } from 'react-intl'
import {acceptQuote, fetchOrders, rejectOrder, rejectQuote} from '../../store/orders/actions'
import { withRouter } from 'react-router-dom'
import { getUserType } from '../../store/login/reducers'
import {canOffer, getIsIkeaCenter} from '../../store/centers/reducers'
import { getSelectedShippingAddressForIkea } from '../../store/shipping-addresses/reducers'

const mapStateToProps = (state, { match }) => {
    return {
        orders: getOffers(state),
        type: match.params.open,
        key: match.params.open,
        isLoading: getOrdersLoading(state),
        userType: getUserType(state),
        canEditOrders: canEditOrders(state),
        canDeleteOrders: canDeleteOrders(state),
        isIkeaCenter: getIsIkeaCenter(state),
        selectedShippingAddressForIkea: getSelectedShippingAddressForIkea(state),
        canOffer: canOffer(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrders: (type, startDate, endDate) => {
            dispatch(fetchOrders(type, startDate, endDate))
        },
        rejectOrder: (reservationId, orderId) => {
            dispatch(rejectOrder(reservationId, orderId))
        },
        rejectQuote: (reservationId) => {
            dispatch(rejectQuote(reservationId))
        },
        acceptQuote: (reservationId, orderId) => {
            dispatch(acceptQuote(reservationId))
        },
    }
}

const OfferStatusHistoryContainer = withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(OfferStatusHistory)
    )
)

export default OfferStatusHistoryContainer
