import jwtDecode from 'jwt-decode'

const initState = {
    successMessage: null,
    failureMessage: null,
    values: null
}

const floatingAlerts = (state = initState, action) => {
    //please define error messages translations at floatingAlert component
    switch (action.type) {
        case 'SIMPLE_LOGIN_SUCCESS':
            const decoded = jwtDecode(action.token)
            return { ...state, successMessage: decoded.accountCreated ? 'account sucessfully created' : null, values: null, failureMessage: null }
        case 'CREATE_ACCOUNT_SUCCESS':
            return { ...state, successMessage: 'account sucessfully created', values: null, failureMessage: null }
        case 'CLOSE_FLOATING_ALERT':
            return { ...state, successMessage: null, failureMessage: null, values: null }
        case 'FLOATING_ALERTS_ADD_SUCCESS_MESSAGE':
            return { ...state, successMessage: action.successMessage, values: action.values, failureMessage: null }
        case 'FLOATING_ALERTS_ADD_FAILURE_MESSAGE':
            return { ...state, successMessage: null, values: action.values, failureMessage: action.failureMessage }
        case 'REJECT_ORDER_SUCCESS':
            return { ...state, successMessage: 'ORDER_REJECTED_SENT' }
        case 'REJECT_ORDER_FAILURE':
            return { ...state, failureMessage: action.statusText }
        case 'SEND_ACCEPT_QUOTE_FAILURE':
            return { ...state, failureMessage: action.statusText }
        case 'SEND_REJECT_QUOTE_FAILURE':
            return { ...state, failureMessage: action.statusText }
        case 'SAVE_ORDER_TO_EDIT_SUCCESS':
            return { ...state, successMessage: 'ORDER_EDITED' }
        case 'SAVE_ORDER_TO_EDIT_FAILURE':
            return { ...state, failureMessage: action.error }

        case 'GET_FILE_BY_URL_FAILURE':
            return { ...state, failureMessage: action.error }
        case 'ADD_CART_LINE_FAILURE':
        case 'CHANGE_CART_LINE_QTY_FAILED':
        case 'CUT_TO_SIZE_ASK_FOR_BUDGET_FAILURE':
            let values = {}
            if (action.payload.statusText === 'CART_TYPE_INCOMPATIBLE_EXCEPTION') {
                return { ...state }
            }
            if (action.payload.statusText === 'MAX_MONTH_QTY_PRODUCT_MKTOOLS_REACHED')
                values = {
                    monthQty: action.payload.values.monthQty,
                    limitMonth: action.payload.values.limitMonth,
                    toAdd:
                        action.payload.values.alreadyIntoCart > 0
                            ? '( ' + action.payload.values.alreadyIntoCart + ' + ' + action.payload.values.toAdd + ' )'
                            : '( ' + action.payload.values.toAdd + ' )'
                }
            if (action.payload.statusText === 'MAX_QTY_PRODUCT_MKTOOLS_REACHED')
                values = {
                    alreadyIntoCart: action.payload.values.alreadyIntoCart,
                    limitMax: action.payload.values.limitMax,
                    toAdd: action.payload.values.toAdd
                }
            if (action.payload.statusText === 'EXCEEDE_ORDER_MAXIMUM_LOWES')
                values = {
                    monthItems: action.payload.values.monthItems
                }
            if (action.payload.statusText === 'SEND_QUOTE_REQUEST') {
                values =  action.payload.values.cause
            }

            return { ...state, failureMessage: action.payload.statusText, values: values }

        case 'SAVE_COLORS_MKTOOLS_ATTR_SUCCESS':
            return { ...state, successMessage: 'SAVE_COLORS_MKTOOLS_ATTR_SUCCESS' }
        case 'SAVE_CCOLORS_MKTOOLS_ATTR_FAILURE':
            return { ...state, failureMessage: 'SAVE_CCOLORS_MKTOOLS_ATTR_FAILURE' }
        case 'DUPLICATED_COLOR_MKTOOLS_ATTR':
            return { ...state, failureMessage: 'DUPLICATED_COLOR_MKTOOLS_ATTR' }
        case 'SEND_PREVIEW_PROMOTION_EMAIL_FAILURE':
            return { ...state, failureMessage: 'PROMOTION_OR_EMAIL_NOT_FOUND' }
        case 'SEND_PREVIEW_PROMOTION_EMAIL_SUCCESS':
            return { ...state, successMessage: 'EMAIL_SENT' }

        //promotions
        case 'SEND_PROMOTION_HEADER_FAILURE':
        case 'SEND_TEMPLATES_SELECTED_FAILURE':
        case 'UPLOAD_FILE_FAILURE':
        case 'SEND_BANNERS_FAILURE':
        case 'SEND_PROMOTION_CENTERS_FAILURE':
        case 'SEND_CUSTOMERS_SELECTED_FAILURE':
        case 'SEND_MATERIALS_SELECTED_FAILURE':
        case 'RESUBSCRIBE_NEWSLETTER_FAILURE':
        case 'UNSUBSCRIBE_NEWSLETTER_FAILURE':
        case 'CREATE_ACCOUNT_FAILURE':
            return { ...state, failureMessage: 'SOMETHING_WENT_WRONG' }
        case 'SEND_ENABLE_PROMOTION_FAILURE':
            return { ...state, failureMessage: action.error }
        case 'SEND_PROMOTION_HEADER_SUCCESS':
            return { ...state, successMessage: 'SEND_PROMOTION_HEADER_SUCCESS' }
        case 'SEND_PROMOTION_CENTERS_SUCCESS':
            return { ...state, successMessage: 'SEND_PROMOTION_CENTERS_SUCCESS' }
        case 'SEND_CUSTOMERS_SELECTED_SUCCESS':
            return { ...state, successMessage: 'SEND_CUSTOMERS_SELECTED_SUCCESS' }
        case 'SEND_MATERIALS_SELECTED_SUCCESS':
            return { ...state, successMessage: 'SEND_MATERIALS_SELECTED_SUCCESS' }
        case 'SEND_TEMPLATES_SELECTED_SUCCESS':
            return { ...state, successMessage: 'SEND_TEMPLATES_SELECTED_SUCCESS' }
        case 'SEND_BANNERS_SUCCESS':
            return { ...state, successMessage: 'SEND_BANNERS_SUCCESS' }
        case 'ADD_EMAIL_CENTER_SUCCESS':
            return { ...state, successMessage: 'SUCCESS_EMAIL_CENTER' }
        case 'ADD_EMAIL_CENTER_FAILURE':
            return { ...state, failureMessage: action.statusText }
        case 'SEND_FEDDBACK_SUCCESS':
            return { ...state, successMessage: 'SUCCESS_SEND_FEDDBACK' }
        case 'SEND_FEDDBACK_FAILURE':
            return { ...state, failureMessage: action.statusText }
        case 'REQUEST_MATERIALS_DISCOUNTS_SUCCESS':
            return { ...state, successMessage: action.message }
        case 'CUT_TO_SIZE_CANCEL_PREORDER_FAILURE':
            return { ...state, failureMessage: action.message }
        case 'FETCH_CONFIGURABLE_BATHS_MODELS_FAILURE':
                return { ...state, failureMessage: action.message }
        default:
            return state
    }
}

export const getSuccessMessage = state => {
    return state.floatingAlerts.successMessage
}
export const getFailureMessage = state => {
    return state.floatingAlerts.failureMessage
}
export const getValues = state => {
    return state.floatingAlerts.values
}
export default floatingAlerts
