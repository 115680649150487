import React from 'react'

import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getSelectedCenter } from '../../../../store/centers/reducers'
import logoSilestone from '../../../../assets/img/logos/brands/logo-silestone.jpg'
import logoDekton from '../../../../assets/img/logos/brands/logo-dekton.jpg'
import logoDktn from '../../../../assets/img/logos/brands/logo-dktn.jpg'
import logoSensa from '../../../../assets/img/logos/brands/logo-sensa.jpg'

const mapStateToProps = state => {
    return {
        selectedCenter: getSelectedCenter(state)
    }
}
const logosBrands = {
    SILESTONE: logoSilestone,
    DEKTON: logoDekton,
    DKTN: logoDktn,
    SENSA: logoSensa
}
class DisplayBrandLogo extends React.Component {
    render() {
        const brand = this.props.selectedCenter === 7130 && this.props.brand === 'DEKTON' ? 'DKTN' : this.props.brand
        const logoBrand = logosBrands[brand]
        return <div>{logoBrand ? <img src={logoBrand} alt="logo" height="15" /> : <div className="product-brand">{brand.toLowerCase()}&reg;</div>}</div>
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(DisplayBrandLogo)
)
