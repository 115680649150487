import React from 'react'
import Translation from '../../global/Translation'

import iconoTemporalAcabado from '../../../assets/img/acabado.png'
import ProductDisplayName from '../../global/ProductDisplayName'
import LengthMeasureTranslation from '../../global/LengthMeasureTranslation'
import LengthMeasure from '../../global/LengthMeasure'

//Displays
import DisplayProductInfo from './displays/DisplayProductInfo'
import DisplayBrand from './displays/DisplayBrand'
import DisplayProductInfoTable from './displays/DisplayProductInfoTable'
import DisplayThickness from './displays/DisplayThickness'
import DisplayGroupDtails from './displays/DisplayGroupDtails'
import DisplayDetail from './displays/DisplayDetail'
import DisplayPrice from './displays/DisplayPrice'
import DisplayBrandLogo from './displays/DisplayBrandLogo'

export default class DetailsLandingDefault extends React.Component {
    state = { showHelp: false }
    getUnitBy() {
        const { product } = this.props
        if (product.unitsByLot) return product.unitsByLot
        else if (product.unitsByBox) return product.unitsByBox
        else if (!product.unitsByLot && !product.unitsByBox) return <LengthMeasure product={product} isSellingOutlet={this.props.isSellingOutlet ? 'X' : false}/>
    }

    render() {
        const { product, canViewThresholdMax } = this.props
        const available = product.type === 'TABLA' ? <Translation id="tables" defaultMessage="Slabs" /> : <Translation id="available" defaultMessage="Available" />
        let quantity
        if (product.type === 'TABLA') {
            quantity = (
                <div>
                    <LengthMeasureTranslation square={true} />
                </div>
            )
        } else {
            quantity = <Translation id="quantity" defaultMessage="Quantity" />
        }
        const watchingOutlet = this.props.outlet ? true : false
        let stock
        if (watchingOutlet) {
            stock = product.stock_outlet
        } else {
            if (product.threshold && product.threshold >= product.stock) {
                stock = ' < ' + product.threshold
            } else if (canViewThresholdMax && product.thresholdMax && product.thresholdMax <= product.stock) {
                stock = ' > ' + product.thresholdMax
            } else {
                stock = product.stock
            }
        }

        return (
            <DisplayProductInfo>
                <DisplayBrandLogo brand={product.brand} />
                <div className="product-color-name">
                    <ProductDisplayName product={product} />
                </div>
                <DisplayProductInfoTable
                    th={[{ value: available }, { datacy: 'product_rectangle_result__surface', value: quantity }, { value: this.props.intl.formatMessage({ id: 'material_id' }) }]}
                    td={[{ value: stock }, { datacy: 'product_rectangle_result__surface_value', value: this.getUnitBy() }, { value: product.productId }]}
                />
                <DisplayGroupDtails>
                    <DisplayThickness thickness={product.thickness} />
                    <DisplayDetail class="finish" icon={iconoTemporalAcabado} value={product.finish} />
                </DisplayGroupDtails>
                <DisplayGroupDtails>
                    <DisplayDetail class="subtypeName" value={product.subtypeName} />
                    <DisplayDetail class="format" value={product.format} />
                </DisplayGroupDtails>

                <DisplayGroupDtails>{<DisplayPrice product={this.props.product} outlet={this.props.outlet} />}</DisplayGroupDtails>
            </DisplayProductInfo>
        )
    }
}
