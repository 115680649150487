import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from './Button'
import Modal from './Modal'
import { connect } from 'react-redux'
import { getShowIkeaModalEmployeeId } from '../../store/ui/reducers'
import { getEmployeeId } from '../../store/big-account/reducers'
import { getIsIkeaCenter } from '../../store/centers/reducers'
import { fetchCart } from '../../store/cart/actions'
import Spinner from './Spinner'

const mapStateToProps = state => {
    return {
        showIkeaModalEmployeeId: getShowIkeaModalEmployeeId(state),
        employeeId: getEmployeeId(state),
        isIkeaCenter: getIsIkeaCenter(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setEmployeeId: employeeId => Promise.resolve(dispatch({ type: 'SET_EMPLOYEE_ID', employeeId: employeeId })),
        fetchCart: () => dispatch(fetchCart()),
        toggleShowIkeaModalEmployeeId: open => Promise.resolve(dispatch({ type: 'TOGGLE_SHOW_IKEA_MODAL_EMPLOYEE_ID', open: open }))
    }
}
class IkeaModalEmployeeId extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false, employeeId: props.employeeId }
        this.form = React.createRef()
    }
    setEmployeeId() {
        if ((!HTMLFormElement.prototype.reportValidity && /[0-9]{8}/.test(this.state.employeeId)) || this.form.current.reportValidity())
            this.props.setEmployeeId(this.state.employeeId).then(() => {
                this.setState({ isLoading: true })
                this.props
                    .fetchCart()
                    .then(() => {
                        this.setState({ isLoading: false })
                        this.props.toggleShowIkeaModalEmployeeId(false)
                    })
                    .catch(() => this.setState({ isLoading: false }))
            })
    }
    render() {
        const { isIkeaCenter, showIkeaModalEmployeeId, employeeId, toggleShowIkeaModalEmployeeId } = this.props

        if (!isIkeaCenter || !showIkeaModalEmployeeId) return null
        return (
            <div className="container">
                <Modal
                    title={<Translation id="please_complete_the_form" defaultMessage="Please complete the form" />}
                    footer={
                        <Button
                            disabled={!(this.state.employeeId && this.state.employeeId !== '') || this.state.isLoading}
                            onClick={() => this.setEmployeeId()}
                            inverted
                            size="medium"
                        >
                            <Translation id="accept" defaultMessage="Accept" />
                        </Button>
                    }
                    onClose={() => toggleShowIkeaModalEmployeeId()}
                >
                    <div className="container">
                        <Spinner isLoading={this.state.isLoading} />
                        <div className="col-12">
                            <div className="employee-msg">
                                <Translation id="please_add_the_employee_id_in_order" defaultMessage="Please add the employee id in order" />
                            </div>
                            <div className="form-group employee-id">
                                <form
                                    ref={this.form}
                                    onSubmit={e => {
                                        e.preventDefault()
                                        this.setEmployeeId()
                                    }}
                                >
                                    <label>
                                        <Translation id="employee_id" defaultMessage="Employee Id" />:
                                    </label>{' '}
                                    <input
                                        data-cy="ikea__input_employeeId-start"
                                        pattern="[0-9]{8}"
                                        title="8 digits (12345678)"
                                        placeholder="12345678"
                                        defaultValue={employeeId}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                this.setEmployeeId()
                                            }
                                        }}
                                        onChange={e => this.setState({ employeeId: e.target.value })}
                                        type="text"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(IkeaModalEmployeeId)
)
