import React from 'react'
import Translation from '../../global/Translation'
import { injectIntl } from 'react-intl'
import { getShowSaleConditions } from '../../../store/ui/reducers'
import { connect } from 'react-redux'
import { toggleSaleConditionsCheck } from '../../../store/checkout/actions'
import {getSalesOrg} from "../../../store/centers/reducers";
import { getLanguage } from '../../../store/ui/reducers'
import { getSaleConditionsCheck } from '../../../store/checkout/reducers'
import { getUsaCaPrTermsAndConditionsByLang } from '../../../helpers'

const mapStateToProps = state => {
    return {
        showSaleConditions: getShowSaleConditions(state),
        getSalesOrg: getSalesOrg(state),
        language: getLanguage(state),
        hasSaleConditionsCheck: getSaleConditionsCheck(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        toggleSaleConditionsCheck: checked => {
            dispatch(toggleSaleConditionsCheck(checked))
        }
    }
}

class CheckboxLinkSaleConditions extends React.Component {
    render() {
        if (!this.props.showSaleConditions) return null
        const usaSalesOrgs = ['2011', '2032', '2037'];

        if(usaSalesOrgs.includes(this.props.getSalesOrg)){
          return (
            <React.Fragment>
              <label className="CheckboxLinkSaleConditions" data-qas="checkout_steptwo-general-conditions-checkbox">
                <input name="sales_conditions_check" type="checkbox" onClick={input => this.props.toggleSaleConditionsCheck(input.target.checked)} />{' '}
                <span className='checkmark' />
                <Translation id="i_accept_the" defaultMessage="I accept the" />{' '}
                <a href={getUsaCaPrTermsAndConditionsByLang(this.props.language)} target="_blank" rel="nofollow noopener noreferrer">
                  <Translation id="general_conditions_of_sale" defaultMessage="General conditions of sale" />
                </a>{' '}
                <Translation id="of_cosentino" defaultMessage="of Cosentino" />
              </label>         
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment>
              <label className="CheckboxLinkSaleConditions" data-qas="checkout_steptwo-general-conditions-checkbox">
                  <input name="sales_conditions_check" type="checkbox" onClick={input => this.props.toggleSaleConditionsCheck(input.target.checked)} />{' '}
                  <span className='checkmark' />
                  <Translation id="i_accept_the" defaultMessage="I accept the" />{' '}
                  <a href="https://assetstools.cosentino.com/api/v1/bynder/doc/511A2D40-054A-41D5-89CE81CF6DB105D8/cgv.pdf" target="_blank" rel="nofollow noopener noreferrer">
                      <Translation id="general_conditions_of_sale" defaultMessage="General conditions of sale" />
                  </a>{' '}
                <Translation id="of_cosentino" defaultMessage="of Cosentino" />
              </label>
            </React.Fragment>
          )
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CheckboxLinkSaleConditions))
