import * as api from '../api'
import { getToken } from '../login/reducers'
import { getFiltersToApi } from './reducers'

export const closeDeliveryDetail = () => {
    return {
        type: 'CLOSE_DELIVERY_DETAIL'
    }
}

export const fetchDeliveryDetails = deliveryId => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_DELIVERY_DETAIL_REQUEST',
        deliveryId
    })

    return api
        .fetchDeliveryDetails(deliveryId, getToken(getState()))

        .then(response => {
            dispatch({
                type: 'FETCH_DELIVERY_DETAIL_SUCCESS',
                deliveryDetails: response.data
            })
        })
}

export const fetchKpis = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_KPIS_REQUEST' })

    return api.fetchKpis(getToken(getState()), getFiltersToApi(getState())).then(
        response => {
            dispatch({ type: 'FETCH_KPIS_SUCCESS', summary: response.data })
        },
        error => {
            dispatch({ type: 'FETCH_KPIS_FAILURE', payload: { status: error.response.status, statusText: error.response.statusText } })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const fetchOpenOrders = page => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_KPIS_OPEN_ORDERS_REQUEST'
    })

    return api.fetchKpisOpenOrders(getToken(getState()), page, getFiltersToApi(getState())).then(
        response => {
            dispatch({ type: 'FETCH_KPIS_OPEN_ORDERS_SUCCESS', response: response.data })
        },
        error => {
            dispatch({ type: 'FETCH_KPIS_OPEN_ORDERS_FAILURE', status: error.response.status, statusText: error.response.statusText })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const fetchBackOrders = page => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_KPIS_BACK_ORDERS_REQUEST'
    })
    return api.fetchKpisBackOrders(getToken(getState()), page, getFiltersToApi(getState())).then(
        response => {
            dispatch({ type: 'FETCH_KPIS_BACK_ORDERS_SUCCESS', response: response.data })
        },
        error => {
            dispatch({ type: 'FETCH_KPIS_BACK_ORDERS_FAILURE', status: error.response.status, statusText: error.response.statusText })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const setKpisFilters = (startDate, endDate, centers, customers, topColors) => (dispatch, getState) => {
    dispatch({ type: 'SET_KPIS_FILTERS', filters: { startDate, endDate, centers, customers, topColors } })
    dispatch(fetchKpis())
    dispatch(fetchOpenOrders())
    dispatch(fetchBackOrders())
}
