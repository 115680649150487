import React from 'react'

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { fetchOfferOverview } from '../../store/orders/actions'
import { getPackingList, getOrderDetailsLoading } from '../../store/orders/reducers'
import { getToken } from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import PackingListView from './PackingListView'

const mapStateToProps = state => {
    return {
        packingList: getPackingList(state),
        isLoading: getOrderDetailsLoading(state),
        token: getToken(state),
        lang: getLanguage(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchOfferOverview: reservationId => {
            dispatch(fetchOfferOverview(reservationId))
        }
    }
}

class OfferOverview extends React.Component {
    componentDidMount() {
        this.props.fetchOfferOverview(this.props.order)
    }

    render() {
        if (!this.props.packingList) return null
        return <PackingListView isOffer={true} {...this.props} />
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OfferOverview)
)
