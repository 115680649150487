import React from 'react'

import { injectIntl } from 'react-intl'

class DirectSlabAccessForm extends React.Component {
    handleSubmitByInvoice = e => {
        e.preventDefault()
        this.props.fetchDirectSlabAccess(null, this.invoiceIdInput.value)
    }
    handleSubmitByReservation = e => {
        e.preventDefault()
        this.props.fetchDirectSlabAccess(this.reservationIdInput.value, null)
    }
    render() {
        return (
            <div className="row forms-search-dsa">
                <div className="col-12 col-md-6">
                    <form onSubmit={this.handleSubmitByInvoice} className="form-inline">
                        <input
                            type="text"
                            id="invoiceIdInput"
                            className="form-control"
                            placeholder={this.props.intl.formatMessage({ id: 'invoice_id' })}
                            autoFocus
                            ref={node => {
                                this.invoiceIdInput = node
                            }}
                        />

                        <input type="submit" className="bt bt-inverted loginbutton" value={this.props.intl.formatMessage({ id: 'search' })} />
                    </form>
                </div>
                <div className="col-12 col-md-6">
                    <form onSubmit={this.handleSubmitByReservation} className="form-inline">
                        <input
                            type="text"
                            id="reservationIdInput"
                            className="form-control"
                            placeholder={this.props.intl.formatMessage({ id: 'reservation_id' })}
                            autoFocus
                            ref={node => {
                                this.reservationIdInput = node
                            }}
                        />

                        <input id="reservationIdSubmit" type="submit" className="bt bt-inverted loginbutton" value={this.props.intl.formatMessage({ id: 'search' })} />
                    </form>
                </div>
            </div>
        )
    }
}

export default injectIntl(DirectSlabAccessForm)
