import React from 'react'

import { injectIntl } from 'react-intl'

import Modal from './Modal'
import Button from './Button'
import { getShowInsertTranslation, getIdTranslation, getDefaultMessageTranslation, getLanguage } from '../../store/ui/reducers'
import { connect } from 'react-redux'

import { getTranslation, sendTranslation } from '../../store/ui/actions'
import Translation from './Translation'

const mapStateToProps = state => {
    return {
        showInsertTranslation: getShowInsertTranslation(state),
        id: getIdTranslation(state),
        defaultMessage: getDefaultMessageTranslation(state),
        language: getLanguage(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        closeModalTranslation: () => {
            dispatch({ type: 'CLOSE_MODAL_TRANSLATION' })
        },
        sendTranslation: (termId, translation) => {
            dispatch(sendTranslation(termId, translation)).then(() => dispatch({ type: 'CLOSE_MODAL_TRANSLATION' }))
        },
        getTranslation: () => dispatch(getTranslation())
    }
}
class ModalFormattedMessage extends React.Component {
    state = { showInsertTranslation: false, translations: {} }
    componentDidMount() {
        // this.props.getTranslation()
    }

    render() {
        return (
            this.props.showInsertTranslation && (
                <Modal
                    style={{ fontSize: '16px' }}
                    title={<Translation id="translationInsertTitle" defaultMessage="Not correctly translated?" />}
                    onClose={() => this.props.closeModalTranslation()}
                    footer={
                        <Button onClick={() => this.props.sendTranslation(this.props.id, this.inputTranslate.value)} inverted size="medium">
                            <Translation id="translate" defaultMessage="Translate" />
                        </Button>
                    }
                >
                    <div style={{ fontSize: '14px' }}>
                        <ul>
                            <li><Translation id="id" defaultMessage="ID" />: {this.props.id}</li>
                            <li><Translation id="default_text" defaultMessage="Default text" />: {this.props.defaultMessage}</li>
                            <li><Translation id="translate" defaultMessage="Translate" />: {this.props.intl.formatMessage({ id: this.props.id })}</li>
                        </ul>
                        <p>
                            <Translation
                                id="translationInsertBody"
                                defaultMessage="This translation not correspond to actual term or not fitting the display? update it please!."
                            />
                        </p>
                        <div className="form-group">
                            <small><Translation id="showing" defaultMessage="Showing" /></small>
                            <input ref={ref => (this.inputTranslate = ref)} type="email" className="form-control" aria-describedby="emailHelp" placeholder="translate" />
                        </div>
                    </div>
                </Modal>
            )
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ModalFormattedMessage)
)
