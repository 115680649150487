import { connect } from 'react-redux'

import { getSalesOrg, getIsLogged, getUserType } from '../../store/login/reducers'
import { fetchMarkets, fetchLandings, fetchCountries, fetchCenter, fetchLangs, fetchLandingsQuery, deleteLanding } from '../../store/landing/actions'

import LandingManager from './LandingManager'
import { loginRequired } from '../../store/login/actions'
import { push } from 'react-router-redux'
import { showToastNotification } from '../../store/toast-notifications/actions'
import { addLoader } from '../../store/loader/actions'
import { removeLoader } from '../../store/loader/actions'
import { dispatchPushURL } from '../../store/ui/actions'

const mapStateToProps = (state, { match }) => {
    return {
        isLogged: getIsLogged(state),
        salesOrg: getSalesOrg(state),
        markets: state.landing.markets,
        countries: state.landing.countries,
        centers: state.landing.centers,
        landings: state.landing.landings,
        landingId: match.params.id,
        userType: getUserType(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showToastNotification: (text = '', type = 'none', duration = 10000) => dispatch(showToastNotification(text, type, duration)),
        fetchMarketsDescriptions: () => dispatch(fetchMarkets()),
        fetchCountriesDescriptions: salesOrg => dispatch(fetchCountries(salesOrg)),
        fetchCentersDescriptions: salesOrg => dispatch(fetchCenter(salesOrg)),
        fetchLangs: () => dispatch(fetchLangs()),
        fetchLandings: () => dispatch(fetchLandings()),
        fetchLandingsQuery: query => dispatch(fetchLandingsQuery(query)),
        deleteLanding: (id, iso) => dispatch(deleteLanding(id, iso)),
        loginRequired: () => {
            dispatch(loginRequired())
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        addLoader: (id) => dispatch(addLoader(id)),
        removeLoader: (id) => dispatch(removeLoader(id))
    }
}

const LandingManagerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingManager)

export default LandingManagerContainer
