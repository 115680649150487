import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import 'react-toggle/style.css'
import Toggle from 'react-toggle'
import Select from 'react-select'
import Button from '../global/Button'
import Modal from '../global/Modal'
import ColorSelector from '../global/ColorSelector'
import LowesUsers from './LowesUsers'
import confirm from '../global/confirm'
import GridLayout from 'react-grid-layout'
import Spinner from '../global/Spinner'
import domtoimage from 'dom-to-image'
import LazyImage from '../global/LazyImage'
import GoBack from '../global/GoBack'
import InputNumber from '../global/InputNumber'

const confirmDelete = (templateId, deleteTemplate, intl) => {
    confirm(intl.formatMessage({ id: 'DELETE_TEMPLATE_LOWES_CONFIRM' }), {intl: intl}).then(
        confirm => {
            deleteTemplate(templateId)
        },
        cancel => {}
    )
}

const initState = {
    optionTemplateSelected: null,
    templateSelected: null,
    sampleSelected: null,
    selectedProduct: null,
    selectedId: null,
    showModalUsers: false,
    showModalNewTemplate: false,
    titleForNewTemplate: null,
    sampleType: null,
    editingTitle: false,
    wantDuplicate: false
}
class CatalogLowesAdmin extends React.Component {
    state = initState
    componentDidMount() {
        this.props.fetchTemplatesLowes()
        this.props.fetchProductsLowes()
        this.props.fetchBrochuresLowes()
        this.props.fetchUsersShopperLowes()
    }
    selectProduct = selectedOption => {
        selectedOption
            ? this.setState({
                  optionSelectedProduct: selectedOption,
                  sampleSelected: {
                      ...this.state.sampleSelected,
                      id: selectedOption.value,
                      productId: selectedOption.productId,
                      name: selectedOption.label,
                      img: selectedOption.img,
                      title: null
                  }
              })
            : this.setState({
                  optionSelectedProduct: null,
                  sampleSelected: { ...this.state.sampleSelected, id: null, name: null, img: null }
              })
    }

    selectTemplate(option) {
        option
            ? this.setState({
                  optionTemplateSelected: option.value,
                  templateSelected: { ...this.props.templates.find(template => template.id === option.value) }
              })
            : this.setState({ optionTemplateSelected: null, templateSelected: null })
    }

    changeTitleForSample(value) {
        this.setState({ sampleSelected: { ...this.state.sampleSelected, id: null, title: value, name: null, img: null } })
    }
    changeLowesCodeForSample(value) {
        this.setState({ sampleSelected: { ...this.state.sampleSelected, lowesCode: value } })
    }

    configNewSample(sample) {
        this.setState({
            optionSelectedProduct: null,
            templateSelected: {
                ...this.state.templateSelected,
                samples: this.state.templateSelected.samples.map(emptySample => {
                    if (sample.i === emptySample.i) {
                        return { ...sample, type: this.state.sampleType }
                        // {
                        //     ...sample,
                        //     subtypeName: '¿?',
                        //     finish: 'Polished',
                        //     thickness: '2cm'
                        // }
                    } else return emptySample
                })
            },
            sampleSelected: null
        })
    }
    changeTitleForNewTemplate(value) {
        this.setState({ titleForNewTemplate: value })
    }
    createNewTemplate() {
        let template = { ...this.props.templateSelected, name: this.state.titleForNewTemplate }
        this.props.createNewTemplate(template).then(() => {
            this.setState({ showModalNewTemplate: false })
            this.selectTemplate({ value: this.props.templates.length.toString() })
        })
    }
    saveTemplate() {
        let template
        this.state.templateSelected
            ? (template = {
                  name: this.state.titleForNewTemplate,
                  samples: this.state.templateSelected.samples,
                  stores: this.state.templateSelected.stores,
                  screenshot: this.state.templateSelected.screenshot
              })
            : (template = {
                  name: this.state.titleForNewTemplate,
                  samples: [],
                  stores: [],
                  screenshot: null
              })
        this.props.saveTemplate(template).then(() => {
            this.clearTemplateSelection()
            this.setState({ showModalNewTemplate: false })
        })
    }
    updateTemplate() {
        Promise.resolve(this.setState({ saving: true })).then(() => {
            this.createScreenShot()
                .then(() => {
                    this.props.updateTemplate(this.state.templateSelected)
                })
                .then(() => {
                    this.setState({ saving: false })
                })
        })
    }
    deleteTemplate() {
        confirmDelete(this.state.templateSelected.id, this.props.deleteTemplate, this.props.intl)
        this.clearTemplateSelection()
    }
    createElementToEdit(item) {
        return (
            <div
                key={item.i}
                className={`grid_container__item ${item.type === 'BROCHURE' ? 'grid_container__item--brochure flip_container flip_container--hover' : ''} ${
                    item.type === 'COLOR' ? 'flip_container flip_container--hover' : ''
                } ${item.type === 'TEXT' ? 'grid_container__item--title' : ''}`}
            >
                <div className={`item item--edit ${item.type === 'COLOR' || item.type === 'BROCHURE' ? 'flip' : ''}`}>
                    {item.img && (
                        <div className={`item__img ${item.type === 'COLOR' || item.type === 'BROCHURE' ? 'flip__front' : ''}`} style={{ backgroundImage: `url(${item.img})` }} />
                    )}

                    <div className={`item__info ${item.type === 'COLOR' || item.type === 'BROCHURE' ? 'flip__back' : ''}`}>
                        <div onClick={() => this.setState({ sampleSelected: item })}>
                            <span
                                className="remove"
                                style={{ position: 'absolute', right: '2px', top: '-2px', cursor: 'pointer' }}
                                onClick={event => this.onRemoveItem(item.i, event, this.props.intl)}
                            >
                                <i className="fal fa-times" />
                            </span>
                            <span className="edit" style={{ position: 'absolute', left: '2px', bottom: '2px', cursor: 'pointer' }}>
                                <i className="fal fa-pencil" />
                            </span>

                            {item.name && (
                                <div className="item__info__name">
                                    <span>{item.name}</span>
                                </div>
                            )}
                            {item.title && (
                                <div className="item__info__title">
                                    <span>{item.title}</span>
                                </div>
                            )}
                            {item.lowesCode && (
                                <div className="item__info__title">
                                    <span>{item.lowesCode}</span>
                                </div>
                            )}
                            {!item.title && item.img && (
                                <div className="item__info__subtitle">
                                    <span>
                                        {item.h} X {item.w}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    onLayoutChange(samples) {
        let newSamples = samples.map((sample, index) => {
            if (this.state.templateSelected.samples[index] !== undefined) return { ...this.state.templateSelected.samples[index], ...sample }
            else return sample
        })
        this.setState({ templateSelected: { ...this.state.templateSelected, samples: newSamples } })
    }
    getXoflastElement() {
        if (this.state.templateSelected.samples.length > 0) {
            return (
                this.state.templateSelected.samples[this.state.templateSelected.samples.length - 1].x +
                this.state.templateSelected.samples[this.state.templateSelected.samples.length - 1].w
            )
        } else return 0
    }
    addNewSample(w, h) {
        this.setState({
            templateSelected: {
                ...this.state.templateSelected,
                samples: this.state.templateSelected.samples.concat({
                    i:
                        this.state.templateSelected.samples !== null && this.state.templateSelected.samples.length > 0
                            ? (this.state.templateSelected.samples[this.state.templateSelected.samples.length - 1].i + 1).toString()
                            : '0',
                    x: 0,
                    y: Infinity,
                    w: w,
                    h: h,
                    minH: 2,
                    minW: 2
                })
            }
        })
    }
    onRemoveItem(i, e, intl) {
        e.stopPropagation()
        confirm(intl.formatMessage({ id: 'DELETE_ITEM_LOWES_CONFIRM' }), {intl: intl}).then(
            confirm => {
                let newSamples = this.state.templateSelected.samples.filter(item => {
                    return item.i !== i
                })
                this.setState({ templateSelected: { ...this.state.templateSelected, samples: newSamples } })
            },
            cancel => {}
        )
    }
    changeTitleOfTemplateSelected(value) {
        this.setState({ templateSelected: { ...this.state.templateSelected, name: value } })
    }
    toogleAvailableOfTemplateSelected() {
        this.setState({ templateSelected: { ...this.state.templateSelected, active: !this.state.templateSelected.active } }, () => {
            if (this.state.templateSelected.active) {
                this.props.enableTemplate(this.state.templateSelected.id)
            } else {
                this.props.disableTemplate(this.state.templateSelected.id)
            }
        })
    }
    toggleUsersTemplate(storeId) {
        let found =
            this.state.templateSelected.stores.filter(store => {
                return store === storeId
            }).length > 0
        if (found) {
            let newsStores = this.state.templateSelected.stores.filter(user => {
                return user !== storeId
            })
            this.setState({ templateSelected: { ...this.state.templateSelected, stores: newsStores } })
        } else {
            this.setState({ templateSelected: { ...this.state.templateSelected, stores: this.state.templateSelected.stores.concat(storeId) } })
        }
    }
    selectAllUsersShopper() {
        let users = this.props.usersShopper.map(user => user.id)
        this.setState({ templateSelected: { ...this.state.templateSelected, stores: users } })
    }
    unselectAllUsersShopper() {
        this.setState({ templateSelected: { ...this.state.templateSelected, stores: [] } })
    }
    createScreenShot() {
        return (
            document.querySelector('.react-grid-layout.grid_container') &&
            domtoimage.toJpeg(document.querySelector('.react-grid-layout.grid_container'), { quality: 0.8, bgcolor: '#e6e6e6' }).then(dataUrl => {
                var img = new Image()
                img.src = dataUrl
                this.setState({ templateSelected: { ...this.state.templateSelected, screenshot: dataUrl } })
            })
        )
    }
    clearTemplateSelection() {
        this.setState({ templateSelected: null, optionTemplateSelected: null })
    }
    createElement(item, step) {
        item = { ...item, step: step }
        return (
            <div
                key={item.i}
                className={`grid_container__item ${item.type === 'BROCHURE' ? 'grid_container__item--brochure flip_container flip_container--hover' : ''} ${
                    item.type === 'COLOR' ? 'flip_container flip_container--hover' : ''
                }${item.type === 'TEXT' ? 'grid_container__item--title' : ''}`}
                onClick={item.img && (() => this.insertItem(item))}
            >
                <div className={`item ${item.type === 'COLOR' || item.type === 'BROCHURE' ? 'flip' : ''}`}>
                    {item.img && (
                        <div
                            className={`item__img ${item.type === 'COLOR' || item.type === 'BROCHURE' ? 'flip__front' : ''}`}
                            style={{
                                backgroundImage: `url(${item.img})`
                            }}
                        />
                    )}

                    <div className={`item__info ${item.type === 'COLOR' || item.type === 'BROCHURE' ? 'flip__back' : ''}`}>
                        <div>
                            {item.name && (
                                <div className="item__info__name">
                                    <span>{item.name}</span>
                                </div>
                            )}
                            {item.title && (
                                <div className="item__info__title">
                                    <span>{item.title}</span>
                                </div>
                            )}
                            {item.lowesCode && (
                                <div className="item__info__title">
                                    <span>{item.lowesCode}</span>
                                </div>
                            )}
                            {item.img && (
                                <div className="item__info__subtitle">
                                    <span>
                                        {item.h} X {item.w}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    getCols(samples) {
        let maxXaddW = 0
        samples.forEach(sample => {
            if (sample.w + sample.x >= maxXaddW) maxXaddW = sample.w + sample.x
        })
        return maxXaddW
    }
    render() {
        let { templates } = this.props
        let { optionTemplateSelected, templateSelected } = this.state
        let templatesOptions =
            templates &&
            templates.map(template => {
                return { value: template.id, label: template.name }
            })
        return (
            <div>
                {(this.props.isLoading || this.state.saving) && <Spinner isLoading={this.props.isLoading || this.state.saving} />}
                <div className="catalog_lowes">
                    {/* <div className="catalog_lowes__sidbar">
                        <div className="row">
                            <div className="col-9">
                                <Select
                                    placeholder="Select a Template"
                                    value={optionTemplateSelected}
                                    onChange={value => {
                                        this.selectTemplate(value)
                                    }}
                                    options={templatesOptions}
                                />
                            </div>
                            <div className="col-3">
                                <Button customClass="menu__item" onClick={() => this.setState({ showModalNewTemplate: true })} size="medium">
                                    <Translation id="new" defaultMessage="New" />
                                </Button>
                            </div>
                        </div>
                    </div> */}
                    {!templateSelected && (
                        <div className="catalog_lowes__selection">
                            <div style={{ width: '100%' }}>
                                <h3><Translation id="templates_enabled" defaultMessage="Templates enabled" /></h3>
                            </div>
                            {templates &&
                                templates.map(template => {
                                    if (template.active)
                                        return (
                                            <div
                                                className="catalog_lowes__selection selection__item item"
                                                onClick={() => {
                                                    this.selectTemplate({ value: template.id })
                                                }}
                                            >
                                                <div className="item__screenshot">
                                                    {/* <div className="available">
                                                        {template.active ? <i className="fas fa-check-circle" style={{ color: 'green' }} /> : <i className="fas fa-times-circle" />}
                                                    </div> */}
                                                    <div className="available">
                                                        {template.samples.filter(sample => {
                                                            return !((sample.name !== null && sample.name !== undefined) || (sample.title !== null && sample.title !== undefined))
                                                        }).length > 0 ? (
                                                            <i className="fas fa-exclamation-triangle" />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    {template.screenshot && (
                                                        <LazyImage extraClass="screenshot" preview={template.screenshot} image={template.screenshot} type="div" />
                                                    )}

                                                    {/* {template.screenshot && <img width="250" height="250" src={template.screenshot} />} */}
                                                </div>
                                                <div className="item__text">{template.name}</div>
                                            </div>
                                        )
                                    else return null
                                })}
                            {!templateSelected && (
                                <div
                                    className="catalog_lowes__selection selection__item item"
                                    onClick={() => {
                                        this.setState({ showModalNewTemplate: true })
                                    }}
                                >
                                    <div className="item__screenshot">
                                        <div className="add_new">
                                            <i className="fas fa-plus-circle" />
                                        </div>
                                    </div>
                                    <div className="item__text">
                                        <Translation id="new_template" defaultMessage="New template" />
                                    </div>
                                </div>
                            )}
                            {templates.find(template => {
                                return template.active === 0
                            }) && (
                                <div style={{ width: '100%' }}>
                                    <h3><Translation id="templates_disabled" defaultMessage="Templates disabled" /></h3>
                                </div>
                            )}
                            {templates &&
                                templates.map(template => {
                                    if (!template.active)
                                        return (
                                            <div
                                                className="catalog_lowes__selection selection__item item"
                                                onClick={() => {
                                                    this.selectTemplate({ value: template.id })
                                                }}
                                            >
                                                <div className="item__screenshot">
                                                    {/* <div className="available">
                                                        {template.active ? <i className="fas fa-check-circle" style={{ color: 'green' }} /> : <i className="fas fa-times-circle" />}
                                                    </div> */}
                                                    {template.screenshot && (
                                                        <LazyImage extraClass="screenshot" preview={template.screenshot} image={template.screenshot} type="div" />
                                                    )}

                                                    {/* {template.screenshot && <img width="250" height="250" src={template.screenshot} />} */}
                                                </div>
                                                <div className="item__text">{template.name}</div>
                                            </div>
                                        )
                                    else return null
                                })}
                        </div>
                    )}
                    {templateSelected && (
                        <div className="catalog_lowes__content">
                            <div className="catalog_lowes__sidbar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px' }}>
                                    <div style={{ width: '100px' }}>
                                        <GoBack goBack={() => this.clearTemplateSelection()} />
                                    </div>
                                    {this.state.editingTitle ? (
                                        <div className="title_template">
                                            <input
                                                type="text"
                                                placeholder="text"
                                                value={this.state.templateSelected.name}
                                                onKeyPress={e => {
                                                    if (e.key === 'Enter') {
                                                        this.props.editTemplateName(this.state.templateSelected.id, this.state.templateSelected.name).then(() => {
                                                            this.setState({ editingTitle: false })
                                                        })
                                                    }
                                                }}
                                                onChange={input => this.changeTitleOfTemplateSelected(input.target.value)}
                                                style={{ width: '75%' }}
                                            />
                                            <Button
                                                onClick={() => {
                                                    this.props.editTemplateName(this.state.templateSelected.id, this.state.templateSelected.name).then(() => {
                                                        this.setState({ editingTitle: false })
                                                    })
                                                }}
                                            >
                                                {' '}
                                                <Translation id="save" defaultMessage="Save" />
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="title_template">
                                            <h2>
                                                {this.state.templateSelected.name}{' '}
                                                <sup>
                                                    <i
                                                        className="fal fa-pencil"
                                                        onClick={() => {
                                                            this.setState({ editingTitle: !this.state.editingTitle })
                                                        }}
                                                    />
                                                </sup>
                                            </h2>
                                        </div>
                                    )}

                                    <div style={{ width: '180px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                        <Toggle defaultChecked={this.state.templateSelected.active} onChange={() => this.toogleAvailableOfTemplateSelected()} />
                                        {this.state.templateSelected.active ? (
                                            <Translation id="available" defaultMessage="Available" />
                                        ) : (
                                            <Translation id="not_available" defaultMessage="Not available" />
                                        )}
                                    </div>
                                </div>
                                <div style={{ width: '1400px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '50%',
                                            padding: '10px 20px',
                                            background: 'cornsilk',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        <span><Translation id="height" defaultMessage="Height" /></span>
                                        <InputNumber
                                            value={this.state.height}
                                            change={qty => {
                                                this.setState({ height: parseFloat(qty, 10) })
                                            }}
                                            step={1}
                                        />

                                        <span><Translation id="width" defaultMessage="Width" /></span>
                                        <InputNumber
                                            value={this.state.width}
                                            change={qty => {
                                                this.setState({ width: parseFloat(qty, 10) })
                                            }}
                                            step={1}
                                        />
                                        <Button onClick={() => this.addNewSample(this.state.width, this.state.height)} disabled={!(this.state.width && this.state.height)}>
                                            <Translation id="add_custom" defaultMessage="Add custom" />
                                        </Button>
                                    </div>
                                    <Button onClick={() => this.addNewSample(4, 4)}><Translation id="add" defaultMessage="Add" /> 4 x 4 </Button>
                                    <Button onClick={() => this.addNewSample(8, 8)}><Translation id="add" defaultMessage="Add" /> 8 x 8</Button>
                                </div>
                            </div>
                            <div />
                            {!this.state.saving ? (
                                <GridLayout
                                    className="grid_container grid_container--editing"
                                    onLayoutChange={layout => this.onLayoutChange(layout)}
                                    layout={this.state.templateSelected.samples}
                                    cols={240}
                                    rowHeight={10}
                                    width={5000}
                                >
                                    {this.state.templateSelected.samples.map(item => this.createElementToEdit(item))}
                                </GridLayout>
                            ) : (
                                <GridLayout
                                    className="grid_container"
                                    useCSSTransforms={true}
                                    isDraggable={false}
                                    isResizable={false}
                                    layout={this.state.templateSelected.samples}
                                    cols={this.getCols(this.state.templateSelected.samples)}
                                    rowHeight={10}
                                    width={this.getCols(this.state.templateSelected.samples) * 20.84}
                                    style={{ width: `${this.getCols(this.state.templateSelected.samples) * 20.84}px` }}
                                >
                                    {this.state.templateSelected.samples.map(item => this.createElement(item, 1))}
                                </GridLayout>
                            )}
                            {templateSelected && (
                                <div className="catalog_lowes__sidbar menu">
                                    <Button customClass="menu__item" onClick={() => this.deleteTemplate()} size="medium">
                                        <Translation id="delete" defaultMessage="Delete" />
                                    </Button>
                                    <Button
                                        customClass="menu__item"
                                        onClick={() =>
                                            this.setState({
                                                showModalUsers: !this.state.showModalUsers
                                            })
                                        }
                                        size="medium"
                                        disabled={optionTemplateSelected ? false : true}
                                    >
                                        <Translation id="users" defaultMessage="Users" />
                                    </Button>
                                    <Button customClass="menu__item" inverted onClick={() => this.updateTemplate()} size="medium" disabled={optionTemplateSelected ? false : true}>
                                        <Translation id="save" defaultMessage="Save" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                    {this.state.showModalUsers && (
                        <LowesUsers
                            stores={this.state.templateSelected.stores}
                            onClose={() =>
                                this.setState({
                                    showModalUsers: false
                                })
                            }
                            onToggleUsersTemplate={storeId => this.toggleUsersTemplate(storeId)}
                            selectAllUsersShopper={() => this.selectAllUsersShopper()}
                            unselectAllUsersShopper={() => this.unselectAllUsersShopper()}
                        />
                    )}
                    {this.state.sampleSelected && (
                        <Modal
                            title={<Translation id="please_choose_option" defaultMessage="Please choose option" />}
                            onClose={() => this.setState({ sampleSelected: false })}
                            size="large"
                            footer={
                                <div className="modal-footer__buttons">
                                    <Button onClick={() => this.configNewSample(this.state.sampleSelected)} inverted size="medium">
                                        <Translation id="ok" defaultMessage="Ok" />
                                    </Button>
                                </div>
                            }
                        >
                            <div className="edit_sample">
                                <div className="edit_sample__type">
                                    <Button onClick={() => this.setState({ sampleType: 'COLOR' })} inverted={this.state.sampleType === 'COLOR'} size="medium">
                                        <Translation id="product" defaultMessage="Product" />
                                    </Button>
                                    <Button onClick={() => this.setState({ sampleType: 'TEXT' })} inverted={this.state.sampleType === 'TEXT'} size="medium">
                                        <Translation id="title" defaultMessage="Title" />
                                    </Button>
                                    {/* //REVIEW: Funcionalidad de brochures */}
                                    {/* <Button onClick={() => this.setState({ sampleType: 'BROCHURE' })} inverted={!this.state.sampleType === 'BROCHURE'} size="medium">
                                        <Translation id="brochures" defaultMessage="Brochures" />
                                    </Button> */}
                                </div>

                                {this.state.sampleType === 'COLOR' && (
                                    <React.Fragment>
                                        <ColorSelector
                                            products={this.props.products}
                                            value={this.state.sampleSelected ? this.state.sampleSelected.id : this.state.optionSelectedProduct}
                                            onChange={selectedOption => this.selectProduct(selectedOption)}
                                        />
                                        <input
                                            style={{ marginTop: '10px' }}
                                            id="inputId"
                                            placeholder="insert re-order number"
                                            value={this.state.sampleSelected.lowesCode}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') this.configNewSample(this.state.sampleSelected)
                                            }}
                                            onChange={e => {
                                                this.changeLowesCodeForSample(e.target.value)
                                            }}
                                            className="form-control"
                                        />
                                    </React.Fragment>
                                )}
                                {this.state.sampleType === 'TEXT' && (
                                    <React.Fragment>
                                        <input
                                            id="inputId"
                                            placeholder="Insert Text"
                                            value={this.state.sampleSelected.title}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') this.configNewSample(this.state.sampleSelected)
                                            }}
                                            onChange={e => {
                                                this.changeTitleForSample(e.target.value)
                                            }}
                                            className="form-control"
                                        />
                                    </React.Fragment>
                                )}
                                {this.state.sampleType === 'BROCHURE' && (
                                    <React.Fragment>
                                        <React.Fragment>
                                            <ColorSelector
                                                products={this.props.brochures}
                                                value={this.state.sampleSelected ? this.state.sampleSelected.id : this.state.optionSelectedProduct}
                                                onChange={selectedOption => this.selectProduct(selectedOption)}
                                            />
                                            <input
                                                style={{ marginTop: '10px' }}
                                                id="inputId"
                                                placeholder="Insert Code"
                                                value={this.state.sampleSelected.lowesCode}
                                                onKeyPress={e => {
                                                    if (e.key === 'Enter') this.configNewSample(this.state.sampleSelected)
                                                }}
                                                onChange={e => {
                                                    this.changeLowesCodeForSample(e.target.value)
                                                }}
                                                className="form-control"
                                            />
                                        </React.Fragment>
                                    </React.Fragment>
                                )}
                            </div>
                        </Modal>
                    )}
                    {this.state.showModalNewTemplate && (
                        <Modal
                            title={<Translation id="new_template" defaultMessage="New template" />}
                            onClose={() =>
                                this.setState({
                                    showModalNewTemplate: false
                                })
                            }
                            size="large"
                            footer={
                                <Button onClick={() => this.saveTemplate()} disabled={!this.state.titleForNewTemplate} inverted size="medium">
                                    <Translation id="new" defaultMessage="New" />
                                </Button>
                            }
                        >
                            <div className="modal_new_template">
                                <div className="group group__title">
                                    <input
                                        id="input_title__new_template"
                                        placeholder="Insert Title"
                                        value={this.state.titleForNewTemplate}
                                        onChange={e => {
                                            this.changeTitleForNewTemplate(e.target.value)
                                        }}
                                        className="form-control"
                                    />
                                    <Button
                                        onClick={() => {
                                            if (this.state.wantDuplicate) {
                                                this.clearTemplateSelection()
                                            }
                                            this.setState({ wantDuplicate: !this.state.wantDuplicate })
                                        }}
                                        inverted={this.state.wantDuplicate}
                                        inline
                                    >
                                        <Translation id="duplicate" defaultMessage="Duplicate" />
                                    </Button>
                                </div>
                                <div className="group group__duplicate">
                                    {this.state.wantDuplicate && (
                                        <Select
                                            placeholder="Select template (for duplicate)"
                                            value={optionTemplateSelected}
                                            onChange={value => {
                                                this.selectTemplate(value)
                                            }}
                                            options={templatesOptions}
                                            inline
                                        />
                                    )}
                                </div>
                            </div>
                        </Modal>
                    )}
                </div>
            </div>
        )
    }
}

export default injectIntl(CatalogLowesAdmin)
