import React from 'react'
import Translation from '../global/Translation'

import {FormattedHTMLMessage, injectIntl} from 'react-intl'

import Button from '../global/Button'
import { connect } from 'react-redux'
import warning from '../../assets/img/warning.jpg'
import { getShowAskForEmailModal } from '../../store/login/reducers'
import { setUserEmailAAD } from '../../store/profile-configuration/actions'
import Alerts from '../alerts/Alerts'
import { getErrorOnSetEmail } from '../../store/profile-configuration/reducers'

const mapStateToProps = state => {
    return {
        showAskForEmailModal: getShowAskForEmailModal(state),
        errorOnSetEmail: getErrorOnSetEmail(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserEmailAAD: email => {
            return dispatch(setUserEmailAAD(email))
        }
    }
}

class AskForEmailModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false, email: '', error: '' }
        this.form = React.createRef()
    }
    validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }
    setUserEmailAAD = input => {
        if (input.validity.valid && input.value !== '' && this.validateEmail(this.state.email)) {
            this.props
                .setUserEmailAAD(this.state.email)
                .then(() => {
                    // this.props.toggleModal(true) //moved to ui reducer
                })
                .catch(() => {})
        } else {
            this.setState({ error: 'MUST_BE_A_VALID_EMAIL' })
        }
    }
    render() {
        const { showAskForEmailModal } = this.props

        if (!showAskForEmailModal) return null
        return (
            <div>
                <div className="full-overlay" style={{ display: showAskForEmailModal ? 'block' : 'none' }} />
                <div id="modal-login-container" className="modal-login modal-ask-for-email" style={{ display: showAskForEmailModal ? 'block' : 'none' }}>
                    <div className="">
                        <div className="row no-gutters">
                            <div className="col-12 ">
                                <div className="logo-container" style={{ backgroundImage: `url(${warning})` }} />
                            </div>
                            <div className="col-12 card-container">
                                <header className="container-fluid">
                                    <div className="row">
                                        <div className="col-3 offset-9 my-auto text-right">
                                            {/* <div className="bt-close" onClick={() => {}}>
                                                <i className="fal fa-times" />
                                            </div> */}
                                        </div>
                                    </div>
                                </header>

                                <div className="form-signin">
                                    <div className="col-12">
                                        <div className="form-group employee-id" style={{ textAlign: 'center' }}>
                                            <form
                                                ref={this.form}
                                                onSubmit={e => {
                                                    e.preventDefault()
                                                    // this.setUserEmailAAD()
                                                }}
                                            >
                                                <h3>
                                                   <Translation
                                                        id="remember"
                                                        defaultMessage="REMEMBER!"
                                                    />
                                                    </h3>
                                                <label>
                                                    <FormattedHTMLMessage
                                                        id="remember_for_email_azure_active_directory_modal_text"
                                                        defaultMessage="In the following weeks you won´t be able to access <i><b>eCosentino</b></i> using your <b>SAP</b> id. Ensure you have an email account to access through our new and improved unified access"
                                                    />
                                                </label>{' '}
                                                {(this.state.error || this.props.errorOnSetEmail) && <Alerts alert={this.state.error || this.props.errorOnSetEmail} status="404" />}
                                                <div className="mt-2 form-group" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                    <input
                                                        className="form-control col-9"
                                                        placeholder={'example@email.com'}
                                                        defaultValue={this.state.email}
                                                        onKeyPress={e => {
                                                            if (e.key === 'Enter') {
                                                                this.setUserEmailAAD(this.inputEmail)
                                                            }
                                                        }}
                                                        ref={node => {
                                                            this.inputEmail = node
                                                        }}
                                                        onChange={e => this.setState({ email: e.target.value })}
                                                        type="email"
                                                    />
                                                    <Button
                                                        disabled={!(this.state.email && this.state.email !== '') || this.state.isLoading}
                                                        onClick={() => this.setUserEmailAAD(this.inputEmail)}
                                                        inverted
                                                        type="submit"
                                                        className="bt bt-inverted loginbutton form-control"
                                                    >
                                                        <Translation id="send" defaultMessage="Send" />
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AskForEmailModal)
)
