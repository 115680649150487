import React from 'react'

import { injectIntl } from 'react-intl'
import Modal from '../global/Modal'
import Button from '../global/Button'
import Translation from '../global/Translation'
import ReactPaginate from 'react-paginate'
import Spinner from '../global/Spinner'

class BannerPicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = { selectedId: this.props.selectedId, currentPage: 0, alert: '' }
        this.props.searchBanner(null, null, this.props.currentBannerEdit)
    }
    handlePageClick(page) {
        this.setState({ currentPage: page.selected })
        this.props.searchBanner(page.selected + 1, this.inputSearch.value, this.props.currentBannerEdit)
    }
    search() {
        this.props.searchBanner(null, this.inputSearch.value, this.props.currentBannerEdit).then(() => {
            this.setState({ currentPage: 0 })
        })
    }
    render() {
        return (
            <Modal
                customClass="banner-picker"
                title={<Translation id="select_campaign_banners" defaultMessage="Select campaign banners" />}
                size="large"
                onClose={this.props.onClose}
                footer={
                    <div className="row w-100">
                        <div className="col-12 d-flex justify-content-around">
                            <nav className="d-flex flex-grow-1 justify-content-center" aria-label="Page navigation example">
                                <ReactPaginate
                                    forcePage={this.state.currentPage}
                                    previousLabel={this.props.intl.formatMessage({ id: 'previous' })}
                                    nextLabel={this.props.intl.formatMessage({ id: 'next' })}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.props.bannersPaginatedResult.last_page}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={e => this.handlePageClick(e)}
                                    containerClassName={'pagination m-0'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                />
                            </nav>
                            <div>
                                <Button
                                    disabled={this.state.alert}
                                    onClick={() => {
                                        const found = this.props.bannersPaginatedResult.data.find(img => img.id === this.state.selectedId)
                                        if (found) {
                                            this.props.setBanner(found)
                                        }
                                        this.props.onClose()
                                    }}
                                >
                                    <Translation id="accept" defaultMessage="Accept" />
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            >
                <div className="row">
                    {/* {this.state.selectedId && 'seleccionada '+this.props.currentBannerEdit+' la '+this.state.selectedId} */}

                    <div className="col-12">
                        <div className="input-group mb-3">
                            <input
                                ref={node => (this.inputSearch = node)}
                                type="text"
                                className="form-control"
                                placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') this.search()
                                }}
                            />
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={() => this.search()}>
                                    <i className="fas fa-search" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {this.props.isLoading && (
                            <div className="wrapper_spinner">
                                <Spinner isLoading={this.props.isLoading} relative />
                            </div>
                        )}
                        <div className="row">
                            {this.state.alert && (
                                <div className="col-12">
                                    <div className="alert alert-danger d-flex justify-content-between my-2" role="alert">
                                        <span>{this.state.alert}</span>
                                    </div>
                                </div>
                            )}
                            {this.props.bannersPaginatedResult.data.map(img => {
                                return (
                                    <div
                                        className={`col-12 col-sm-6 col-md-4 my-2`}
                                        onClick={() => {
                                            this.setState({ alert: '' })
                                            let hiddenImage = document.getElementById(img.id)
                                            if (
                                                (this.props.currentBannerEdit === 'home' || this.props.currentBannerEdit === 'side-letter') &&
                                                (hiddenImage.height !== this.props.bannerSizes[this.props.currentBannerEdit].height ||
                                                    hiddenImage.width !== this.props.bannerSizes[this.props.currentBannerEdit].width)
                                            ) {
                                                //banner home and banner portfolio must be exact dimensions
                                                this.setState({
                                                    alert:
                                                        this.props.intl.formatMessage({
                                                            id: 'error_dimensions',
                                                            defaultMessage: 'Error dimensions'
                                                        }) +
                                                        ' width: ' +
                                                        this.props.bannerSizes[this.props.currentBannerEdit].width +
                                                        ', height: ' +
                                                        this.props.bannerSizes[this.props.currentBannerEdit].height
                                                })
                                            } else if (
                                                (this.props.currentBannerEdit === 'landing' || this.props.currentBannerEdit === 'letter') &&
                                                (hiddenImage.height > 800 || hiddenImage.width !== this.props.bannerSizes[this.props.currentBannerEdit].width)
                                            ) {
                                                //banner landing and newsletter only checks width and up to 800 height
                                                this.setState({
                                                    alert:
                                                        this.props.intl.formatMessage({
                                                            id: 'error_dimensions',
                                                            defaultMessage: 'Error dimensions'
                                                        }) +
                                                        ' width: ' +
                                                        this.props.bannerSizes[this.props.currentBannerEdit].width +
                                                        ', max-height: ' +
                                                        800
                                                })
                                            }
                                            this.setState({ selectedId: img.id })
                                        }}
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    >
                                        <img src={img.url} alt={img.name} className={`img-thumbnail ${this.state.selectedId === img.id ? 'img-thumbnail--selected' : ''}`} />
                                        {/* this hidden image is for validate the real width and height of the image */}
                                        <img src={img.url} id={img.id} alt={img.name} className={`d-none`} />
                                        <span style={{ wordBreak: 'break-word' }}>
                                            {img.name}{' '}
                                            <small>
                                                {`(${img.width}*${img.height})`}
                                                {/*({document.getElementById(img.id) && document.getElementById(img.id).width} *{' '}*/}
                                                {/*{document.getElementById(img.id) && document.getElementById(img.id).height})*/}
                                            </small>
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default injectIntl(BannerPicker)
