export const initState = {
    crossSelling: [],
    crossSellingCheckout: [],
    cartCrossSelling: {},
    showCrossSellingModal: false,
    boughtTogetherProducts: null,
    cartSimilarProducts: null,
    cartComplementaryProducts: null

}

const crossSelling = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCT_CROSSELLING_SUCCESS':
            return Object.assign({}, state, { crossSelling: action.crossSelling })
        case 'FETCH_ADD_TO_CART_CROSSELLING_SUCCESS':
            return Object.assign({}, state, { cartCrossSelling: action.crossSelling })
        case 'FETCH_PRODUCT_ALTERNATIVES_SUCCESS':
            return Object.assign({}, state, { alternatives: action.alternatives })
        case 'SHOW_CROSSELLLING_MODAL':
            return Object.assign({}, state, { showCrossSellingModal: true })
        case 'HIDE_CROSSELLLING_MODAL':
            return Object.assign({}, state, { showCrossSellingModal: false })
        case 'FETCH_PRODUCT_CROSSELLING_CHECKOUT_SUCCESS':
            return Object.assign({}, state, { crossSellingCheckout: action.crossSelling })
        case 'FETCH_BOUGHT_TOGETHER_SUCCESS':
            return { ...state, boughtTogetherProducts: action.boughtTogether }
        case 'FETCH_CART_SIMILAR_PRODUCTS_SUCCESS':
            return { ...state, cartSimilarProducts: action.products }
        case 'FETCH_CART_COMPLEMENTARY_PRODUCTS_SUCCESS':
            return { ...state, cartComplementaryProducts: action.products }
        default:
            return state
    }
}

export const getCurrentCartCrossSelling = state => {
    return state.crossSelling.cartCrossSelling
}
export const getCurrentCrossSelling = state => {
    return state.crossSelling.crossSelling
}
export const getCurrentCrossSellingCheckout = state => {
    return state.crossSelling.crossSellingCheckout
}
export const getCurrentAlternatives = state => {
    return state.crossSelling.alternatives
}
export const showCrossSellingModal = state => {
    return state.crossSelling.showCrossSellingModal
}
export const getBoughtTogetherProducts = state => {
    return state.crossSelling.boughtTogetherProducts
}
export const getCartSimilarProducts = state => {
    return state.crossSelling.cartSimilarProducts
}
export const getCartComplementaryProducts = state => {
    return state.crossSelling.cartComplementaryProducts
}
export default crossSelling
