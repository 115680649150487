export const initState = {
    selectedProduct: null,
    currentRouteId: null
}

const selectedObjects = (state = initState, action) => {
    switch (action.type) {
        case 'PRODUCT_CLICK':
        case 'SET_LAST_SELECTED_PRODUCT':
            return {...state, selectedProduct: action.product}
        case 'SET_LAST_ROUTE_ID':
            return {...state, currentRouteId: action.routeId}
        default:
            return state
    }
}

export const getLastSelectedProduct = state => state.selectedObjects.selectedProduct

export const getLastRouteId = state => state.selectedObjects.currentRouteId


export default selectedObjects