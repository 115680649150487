import React from 'react'
import dangerIcon from '../../assets/img/icons/danger-icon.svg';

class InfoBox extends React.Component {
  logo;
  boxStyle;

  componentDidMount() {
    switch (this.props.alertType){
      case 'danger':
        this.logo = dangerIcon;
        this.boxStyle = 'bg-danger';
        break;
      default:
        this.logo = dangerIcon;
        this.boxStyle = 'bg-danger';
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={'alert-box ' + this.boxStyle}>

          <div className='alert-icon'>
            <img alt="" src={this.logo} />
          </div>
          <div className='alert-text'>
            {this.props.children}
          </div>
          <div className='alert-close' style={{cursor: "pointer"}} onClick={this.props.onClose}>
            <i className='fal fa-times'/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default InfoBox
