import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import { connect } from 'react-redux'

import logo from '../../assets/img/e.cosentino.svg'
import CosentinoSingleSignOn from './CosentinoSingleSignOn'
import { getIsLogging, showRedirectSSOModal, getIsLogged, getSapId } from '../../store/login/reducers'
import { login, logout } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'
import Button from '../global/Button'
import Spinner from '../global/Spinner'
import { push } from 'react-router-redux'
import Alerts from '../alerts/Alerts'
import { dispatchPushURL } from '../../store/ui/actions'

const mapStateToProps = state => {
    return {
        show: showRedirectSSOModal(state),
        isLogging: getIsLogging(state),
        isLogged: getIsLogged(state),
        sapId: getSapId(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (username, password, callback, callbackCenter, intl, ssoData, callbackSalesOrg) => {
            dispatch(login(username, password, callback, callbackCenter, intl, ssoData, callbackSalesOrg))
        },
        onClose: () => {
            dispatch(ui.closeLoginModal())
        },
        onShowUpRedirect: () => {
            dispatch(ui.showRedirectSSOModal())
        },
        logout: () => {
            dispatch(logout())
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        }
    }
}
class RedirectSSO extends React.Component {
    state = { error: null }

    componentDidMount() {
        this.props.onShowUpRedirect()
    }
    handleSubmitSSO = ssoData => {
        ssoData.type = 'SSO'

        if (this.props.isLogged && this.props.sapId) this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_HOME' }))
        else if (this.props.isLogged)
            this.props.logout().then(() => {
                this.props.onLogin(ssoData.sapId, null, this.props.callback, this.props.callbackCenter, this.props.intl, ssoData, this.props.callbackSalesOrg)
            })
        else this.props.onLogin(ssoData.sapId, null, this.props.callback, this.props.callbackCenter, this.props.intl, ssoData, this.props.callbackSalesOrg)
    }
    render() {
        const { show, onClose, isLogging } = this.props
        if (this.props.isLogged && this.props.sapId) this.props.redirect(this.props.intl.formatMessage({ id: 'ROUTE_HOME' }))

        return (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} onClick={onClose} />

                <div id="modal-login-container" className="modal-login sso" style={{ display: show ? 'block' : 'none' }}>
                    {isLogging && <Spinner isLoading={isLogging} />}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 logo-container">
                                <header className="container-fluid">
                                    <div className="row">
                                        <div className="col-3 offset-9 my-auto text-right">
                                            <div className="bt-close" onClick={onClose}>
                                                <i className="fal fa-times" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12 col-lg-6 offset-lg-3 text-center ">
                                            <img alt="" className="profile-img-card" src={logo} />

                                            {this.state.error && <Alerts alert={this.state.error} status="404" />}

                                            <CosentinoSingleSignOn
                                                onSuccess={data => this.handleSubmitSSO(data)}
                                                onError={data =>
                                                    this.setState({ error: data.error === 'Insufficient permissions' ? 'INSUFFICIENT_PERMISSIONS' : 'SOMETHING_WENT_WRONG' })
                                                }
                                            >
                                                <Button inverted>
                                                    <Translation id="continue_with_my_salesforce_account" defaultMessage="Continue with my Salesforce account" />
                                                </Button>
                                            </CosentinoSingleSignOn>
                                        </div>
                                    </div>
                                </header>
                                <footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RedirectSSO)
)
