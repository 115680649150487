import React from 'react'
import Translation from '../global/Translation'

import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Button from '../global/Button'
import Modal from '../global/Modal'
import Alerts from '../alerts/Alerts'

class AssignCustomer extends React.Component {
    constructor(props) {
        super(props)
        this.state = { selectedCustomer: null, deliveryDate: moment().add(1, 'days') }
    }
    handleChange = selectedCustomer => {
        this.setState({ selectedCustomer: selectedCustomer })
    }
    handleChangeDate = date => {
        this.setState({
            deliveryDate: date
        })
    }
    handleSubmit = () => {
        if (!this.state.selectedCustomer) {
            this.setState({ alert: 'CUSTOMER_REQUIRED' })
            return
        }
        const deliveryDate = moment(this.state.deliveryDate).format('YYYY/MM/DD')
        this.props.assignCustomer(this.state.selectedCustomer.value, this.props.order, deliveryDate, this.poInput.value, this.commentInput.value)
    }
    render() {
        if (this.props.customers === undefined) {
            return null
        }
        return (
            <Modal
                title={
                    <span>
                        <Translation id="reservation_id" defaultMessage="Reservation ID" /> {this.props.order}{' '}
                        <small>
                            <Translation id="assign_customer" defaultMessage="Assign customer" />
                        </small>
                    </span>
                }
                footer={
                    <Button inline size="small" icon="chevron-circle-right" onClick={() => this.handleSubmit()}>
                        <Translation id="select_shipping_address" defaultMessage="Select shipping address" />
                    </Button>
                }
                onClose={this.props.onClose}
            >
                {this.state.alert && <Alerts alert={this.state.alert} status="404" />}

                <form>
                    <div className="form-group">
                        <Select
                            placeholder={<Translation id="select_customer" defaultMessage="Select customer" />}
                            options={this.props.customers.map(option => ({
                                label: option.customerName + ' , ' + option.address + ' , ' + option.zipCode + ' , ' + option.city + ' , ' + option.country,
                                value: option.shippingAddressId
                            }))}
                            value={this.state.selectedCustomer}
                            onChange={selectedCustomer => this.handleChange(selectedCustomer)}
                            className="select-customer"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input-date">
                            <Translation id="required_delivery_date" defaultMessage="Required delivery date" />
                        </label>
                        <DatePicker
                            selected={this.state.deliveryDate}
                            onChange={this.handleChangeDate}
                            minDate={moment().add(1, 'days')}
                            className="form-control"
                            autoComplete="off"
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input-po">
                            <Translation id="insert_po" defaultMessage="Insert your PO#" />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="input-po"
                            ref={input => {
                                this.poInput = input
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="input-comments">
                            <Translation id="comment" defaultMessage="Comments" />
                        </label>
                        <textarea
                            className="form-control"
                            id="input-comments"
                            rows="3"
                            ref={input => {
                                this.commentInput = input
                            }}
                        />
                    </div>
                </form>
            </Modal>
        )
    }
}
export default AssignCustomer
