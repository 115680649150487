import React from 'react'
import configBathIndicator3DIcon from '../../../../assets/img/logos/icon-config-bath-3d.svg'
import Translation from '../../../global/Translation'

const ConfigBathIndicator = () => {
    return (
        <div className='config-bath-indicator' >
            <div className="config-bath-indicator__img">
                <img src={configBathIndicator3DIcon} alt="3D Customized" />
            </div>
            <div className="config-bath-indicator__text">
                <Translation id="customized" defaultMessage="Customized" />
            </div>
        </div>
    )
}

export default ConfigBathIndicator;
