import React from 'react'
import Translation from '../global/Translation'

import CartLineContainer from '../cart-line/CartLineContainer'
import Spinner from '../global/Spinner'
import MallIncentivesContainer from '../incentives/MallIncentivesContainer'
import getNumberFormat from "../getNumberFormat";

const CartSummary = ({
    lines,
    totalQty,
    total,
    onDelete,
    changeCartLineQty,
    openBundleContent,
    segmentation,
    shippingMethod,
    use,
    segmentationV3,
    coin,
    isIkeaCenter,
    calculatingLinesPrice,
    totalCoin,
    canViewPricesPhase2,
    lang,
    isMallCartForContainer,
    goalMin,
    unit,
    type,
    showAll=true,
    isCosentinoGlobal,
    howManyShouldShow = 5
}) => {
    if (totalQty <= 0) {
        return (
            <div>
                <h4 className="cart-heading heading">
                    <Translation id="your_cart" defaultMessage="Your Cart" />
                </h4>
                <div className="total cart-empty container-fluid">
                    <div className="row">
                        <div className="label">
                            <Translation id="your_cart_is_empty" defaultMessage="Your cart is empty" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    let numberFormat = getNumberFormat(lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

    if(totalCoin === 'JPY') {
        numberFormat = getNumberFormat(lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
    }

    const aProducts = []
    const aQuantities = []

    let _lines = lines

    if (isCosentinoGlobal !== undefined){
        _lines = isCosentinoGlobal ?
            lines.filter((line) => {
                return line.extra && line.extra.isCosentinoGlobal
            }) :
            lines.filter((line) => {
                return !line.extra || !line.extra.isCosentinoGlobal
            })
    }

    _lines = showAll ? _lines : _lines.slice(0, howManyShouldShow)

    let totalThickness = 0;

    _lines.forEach(line => {
        aProducts.push(line.productId)
        aQuantities.push(line.qty)
        totalThickness += line.product && line.product.thickness ? parseFloat(line.product.thickness) * line.qty : 0
    })

    const products = aProducts.join(',')
    const quantities = aQuantities.join(',')

    return (
        type && type === 'checkout_v2_summary' ? (
            <React.Fragment>
                {_lines.map(line => line && <CartLineContainer line={line} key={line.lineId} typeLine={type} type='checkout' onlyView={line.extra && line.extra.itsFree} />)}
            </React.Fragment>
        ) : (
            <div className="checkout-table">
                {isMallCartForContainer && <MallIncentivesContainer />}
                <h4 className="cart-heading heading">
                    <Translation id="your_cart" defaultMessage="Your Cart" />
                </h4>
                <div className="container">
                    <div className="row headers">
                        <div className="col" />
                        <div className="col" />
                    </div>

                    <div className="row">
                        <div className="col-12 products item-list">
                            {_lines.map((line, index) => line && <CartLineContainer positionLine={index} line={line} key={line.lineId} typeLine={type} type='checkout' onlyView={line.extra && line.extra.itsFree} />)}
                        </div>
                    </div>
                </div>
                {!isIkeaCenter && (
                    <div className="total container-fluid">
                        <div className="row">
                            <div className="col-3 my-auto">
                                <div className="label">
                                    <Translation id="total_items" defaultMessage="Total" />
                                </div>
                            </div>
                            <div className="col-2  my-auto">
                                <div className="quantity">{totalQty}</div>
                            </div>
                            {canViewPricesPhase2 && (
                                <React.Fragment>
                                    <div className="col-2 col-md-2 my-auto text-right">
                                        <div className="label">
                                            <Translation id="total_import" defaultMessage="Total" />
                                        </div>
                                    </div>

                                    <div className="col-4 my-auto">
                                        <div className="quantity">
                                            {calculatingLinesPrice ? (
                                                <Spinner isLoading={true} relative small></Spinner>
                                            ) : !total || (total && total == 0) ? (
                                                <Translation id="not_available" defaultMessage="Not available" />
                                            ) : (
                                                total && (
                                                    <React.Fragment>
                                                        {numberFormat.format(total)} {totalCoin}
                                                    </React.Fragment>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    )
}

export default CartSummary
