import { createBrowserHistory }  from "history";

const history = createBrowserHistory();

const actionsMap = new Map()

history.listen((location, action) => {
    actionsMap.forEach(callback => callback(location, action))
})


export const addHistoryAction = (key, callback) => actionsMap.set(key, callback)
export const removeHistoryAction = (key) => actionsMap.delete(key)
export default history