import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { fetchRecurringOrderDetails, add2CartRecurringOrder } from '../../store/orders/actions'
import { getOrderDetailsLoading, getRecurringOrderDetails, getOrdersLoadingAdd, getselectedRecurringOrder } from '../../store/orders/reducers'
import { getToken } from '../../store/login/reducers'
import { getLanguage } from '../../store/ui/reducers'
import Button from '../global/Button'
import Modal from '../global/Modal'
import Spinner from '../global/Spinner'
import { getBusinesSegmentOptions, getUseOptions } from '../../store/segmentation/reducers'
import moment from 'moment'
import CartLineContainer from '../cart-line/CartLineContainer'

const mapStateToProps = state => {
    return {
        recurringOrderDetails: getRecurringOrderDetails(state),
        isLoading: getOrderDetailsLoading(state),
        isLoadingAdd: getOrdersLoadingAdd(state),
        token: getToken(state),
        lang: getLanguage(state),
        segmentation: getBusinesSegmentOptions(state),
        use: getUseOptions(state),
        selectedRecurringOrder: getselectedRecurringOrder(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchRecurringOrderDetails: reservationId => {
            dispatch(fetchRecurringOrderDetails(reservationId))
        },
        add2CartRecurringOrder: num => {
            dispatch(add2CartRecurringOrder(num))
        }
    }
}

class RecurringOrderDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.props.fetchRecurringOrderDetails(this.props.selectedRecurringOrder)
    }

    add2CartRecurringOrder = id => {
        this.props.add2CartRecurringOrder(id)
    }

    render() {
        let { recurringOrderDetails, onClose, isLoadingAdd, isLoading } = this.props

        if (!recurringOrderDetails.lines) return null
        return (
            <Modal
                title={
                    <span>
                        <Translation id="recurring_orders" defaultMessage="Recurring Orders" />{' '}
                        <small>
                            <Translation id="order_date" />: {moment(recurringOrderDetails.createdAt + '+00:00').calendar()}
                        </small>
                    </span>
                }
                footer={''}
                onClose={onClose}
                size="large"
                customClass="modal-recurring-orders"
            >
                {(isLoadingAdd || isLoading) && <Spinner isLoading={isLoadingAdd || isLoading} />}
                <div className="recurring-orders-details-scroll  products item-list">
                    {recurringOrderDetails.lines.map((line,index) => {
                        line.brand = line.product.brand
                        line.businessSegment = line.segmentation.businessSegment
                        line.channel = line.segmentation.channel
                        line.displayName = line.product.productName
                        line.productId = line.product.productId
                        line.subchannel = line.segmentation.subchannel
                        line.thumb = line.product.thumb
                        line.typology = line.segmentation.typology
                        line.use = line.segmentation.use
                        line.lineId = index +1
                        return (
                            <div>
                                <CartLineContainer line={line} type="lowes_order_details" />
                                <hr />
                            </div>
                        )
                    })}
                </div>
                <hr />
                {!this.props.isLoading && (
                    <div className="row">
                        <div className="col-8 offset-2 col-lg-4 offset-lg-8 txt-centered">
                            {isLoadingAdd ? (
                                <Button inline icon="shopping-cart" disabled>
                                    <Translation id="add_to_cart" />
                                </Button>
                            ) : (
                                <Button
                                    inline
                                    icon="shopping-cart"
                                    onClick={() => {
                                        this.add2CartRecurringOrder(recurringOrderDetails.id)
                                    }}
                                >
                                    <Translation id="add_to_cart" />
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </Modal>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RecurringOrderDetails)
)
