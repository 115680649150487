import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'
import { getCurrentSegmentation, getSelectedUse, getUseOptions } from '../../store/segmentation/reducers'
import { selectUse, toggleSegmentation } from '../../store/segmentation/actions'
const mapStateToProps = state => {
    return {
        show: getCurrentSegmentation(state) === 'USE',
        selected: getSelectedUse(state),
        options: getUseOptions(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('USE'))
        },
        onSelect: value => {
            dispatch(selectUse(value))
        }
    }
}
const Use = ({ show, selected, onToggle, onSelect, options }) => {
    if (!options || Object.entries(options).length === 0) {
        return ''
    }

    const selectedValue = selected ? <span>{selected.name}</span> : null
    return (
        <div>
            <div onClick={onToggle} className="option">
                <Translation id="use" defaultMessage="Use" /> : {selectedValue}
                <i className={show ? 'fa fa-caret-down' : 'fa fa-caret-right'} aria-hidden="true" />
                <br />
            </div>

            <div style={{ display: show ? 'block' : 'none' }}>
                <div className="row segment-selections">
                    {options.map(option => (
                        <span
                            key={option.id}
                            onClick={() => {
                                onSelect(option)
                            }}
                        >
                            {option.name}
                        </span>
                    ))}
                </div>
            </div>
            <hr />
        </div>
    )
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Use)
