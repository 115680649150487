import en from "./locales/en.json";
import es from "./locales/es.json";
import de from "./locales/de.json";
import da from "./locales/da.json";
import enUs from "./locales/en-us.json";
import fi from "./locales/fi.json";
import frCa from "./locales/fr-ca.json";
import enCa from "./locales/en-ca.json";
import fr from "./locales/fr.json";
import he from "./locales/he.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import nl from "./locales/nl.json";
import no from "./locales/no.json";
import pl from "./locales/pl.json";
import pt from "./locales/pt.json";
import sv from "./locales/sv.json";
import tr from "./locales/tr.json";

export const messages = {
    en,
    es,
    de,
    da,
    'en-US': enUs,
    fi,
    'fr-CA': frCa,
    'en-CA': enCa,
    fr,
    he,
    it,
    ja,
    nl,
    no,
    pl,
    pt,
    sv,
    tr
}