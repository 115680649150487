import React from "react";
import Translation from "../../global/Translation";
import ReactTooltip from "react-tooltip";
import {injectIntl} from "react-intl";

class DiscountStateLabel extends React.Component {


    render() {

        const {discount_request_status, discount_comments, id} = this.props.campaign

        let status = 'PROMO_DISCOUNT_' + discount_request_status
        let statusMsg
        let iconClass
        const style = {padding: '4px 6px', borderRadius: '2px', fontSize: '12px', fontWeight: 500, lineHeight: '14px'}
        const textStyle ={flexGrow:1}
        const iconStyle = {flexGrow:1}

        switch (discount_request_status) {
            case 'REQUESTED':
                statusMsg = 'DISCOUNT_REQUESTED'
                iconClass = "far fa-history"
                style.backgroundColor = 'yellow'
                style.color = 'black'
                break
            case 'APPROVED':
                statusMsg = 'APPROVED'
                iconClass = "far fa-check-circle"
                style.backgroundColor = 'green'
                style.color = 'black'
                break
            case 'DENIED':
                statusMsg = 'DENIED'
                iconClass = "fas fa-times"
                style.backgroundColor = 'red'
                style.color = 'white'
                break
            case 'PART_APRVED':
                statusMsg = 'PART_APRVED'
                iconClass = "fas fa-times"
                style.backgroundColor = 'green'
                style.color = 'black'
                break
            case 'NONE':
            default:
                statusMsg = 'NONE'
                style.backgroundColor = 'grey'
                style.color = 'white'
                break
        }

        return (
            <div className="d-flex justify-content-between align-items-center" style={style}>
                <div>
                    {
                        this.props.intl.formatMessage({id:statusMsg})
                    }
                    &nbsp;
                    {iconClass && (<i className={iconClass} style={iconStyle}/>)}
                </div>
                {
                    discount_comments && (
                        <span data-tip data-for={'discount_comments_' + id}>
                            <i
                                className="fas fa-info-circle " data-toggle="tooltip" data-placement="top"
                                style={{color:'blue', textShadow: '1px 1px white', fontSize:'14px', marginTop:'2px'}}
                            />

                            <ReactTooltip id={'discount_comments_' + id} bg-coloreffect="solid" place="bottom">
                                {discount_comments}
                            </ReactTooltip>
                        </span>
                    )
                }
            </div>
        )

    }

}

export default injectIntl(DiscountStateLabel)