import React from 'react'

import { connect } from 'react-redux'
import {getImageBackground, getImageThumb, isOtherMask} from '../../store/products/actions'
import { canViewImageByLot } from '../../store/centers/actions'
import { getHQCenterId, getIKEACenterId, getIsMkToolsCenter, getSelectedCenter, getIsIkeaCenter } from '../../store/centers/reducers'
import { getLanguage } from '../../store/ui/reducers'
import { getDeviatedDescriptions, canViewPricesPhase3 } from '../../store/products/reducers'
import { withRouter } from 'react-router-dom'
import noImage from '../../assets/img/icons/no-image.svg'
import { ElaborationOptionsMapById } from '../cut-to-size/CutToSizeMaps'

const mapStateToProps = (state, { match }) => {
    return {
        isMktoolsCenter: getIsMkToolsCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        locale: getLanguage(state),
        deviatedDescritions: getDeviatedDescriptions(state),
        centerId: getSelectedCenter(state),
        outlet: match.params.outlet,
        canViewPricesPhase3: canViewPricesPhase3(state),
        HQCenterId: getHQCenterId(),
        IkeaCenterId: getIKEACenterId()
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getImageThumb: product => dispatch(getImageThumb(product)),
        isOtherMask: product => isOtherMask(product),
        canViewImageLot: location => dispatch(canViewImageByLot(location))
    }
}

class ProductImage extends React.Component {
    // constructor(props) {
    //     super(props)
    //     this.state = {}
    //     this.product = this.props.product
    // }
    
    render() {
        const {materialId, product} = this.props
        // let image = getImageThumb(this.product)

        if (!product) {

            if (materialId && ElaborationOptionsMapById[materialId]) return (
                <div className="product-image-container">
                     <div
                        className="product-image"
                        style={{
                            backgroundColor: 'white',
                            backgroundImage: 'url("' + ElaborationOptionsMapById[materialId].icon + '")',
                            backgroundSize: 'cover'
                        }}
                    ></div>
                </div>
            )
            else return (
                <div className="product-image-container" style={{ backgroundImage: 'url("' + noImage + '")',
                    backgroundColor: '#f6f6f6',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    border: '1px dashed #cccccc' 
                }}>
                    <div className="product-image"></div>
                </div>
            )
        }
        else if (product && !this.props.getImageThumb(product)){
            return (
                <div className="product-image-container" style={{ backgroundImage: 'url("' + noImage + '")',
                    backgroundColor: '#f6f6f6',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    border: '1px dashed #cccccc'
                }}>
                <div className="product-image"></div>
             </div>
            )
        }
        return (
            <div className={this.props.customClass && this.props.customClass !== '' ? this.props.customClass + ' product-image-container' : 'product-image-container'}>
                {(product.thumbMd || this.props.isIkeaCenter || this.props.isMktoolsCenter) && (
                    <div
                        className="product-image"
                        type={product.subtypeId == 'REVESTIMIENTO' ? 'TABLA' : product.type}
                        style={
                            this.props.getImageThumb(product) && {
                                backgroundColor: 'white',
                                backgroundImage: 'url("' + this.props.getImageThumb(product) + '")',
                                backgroundSize:this.props.getImageThumb(product).includes('product-adv') && product.type == 'FREGADERO' ?'180%':'',
                                backgroundPosition: this.props.getImageThumb(product).includes('product-adv')?'57% 41%':'',
                            }
                        }
                    >
                        {product.promotionFreeItem && this.props.canViewPricesPhase3 && (
                            <div
                                className="other_mask free-item"
                                type={product.promotionFreeItem.type}
                                style={{
                                    backgroundImage: 'url("' + product.promotionFreeItem.thumb + '")'
                                }}
                            />
                        )}

                        {this.props.isOtherMask(product) && (
                            <div
                                className="other_mask"
                                style={{
                                    backgroundImage: 'url("' + product.colorImg + '")'
                                }}
                            />
                        )}
                    </div>
                )}
                {this.props.centerId === this.props.HQCenterId &&
                    this.props.outlet === 'outlet' &&
                    this.props.deviatedDescritions &&
                    this.props.deviatedDescritions[product.productId] && (
                        <div className="product-image-container__text-info">
                            {this.props.deviatedDescritions[product.productId][this.props.locale]
                                ? this.props.deviatedDescritions[product.productId][this.props.locale]
                                : this.props.deviatedDescritions[product.productId]['default']}
                        </div>
                    )}
            </div>
        )
    }
}
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProductImage)
)
