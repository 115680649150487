import React from 'react'
import { Popup } from '@progress/kendo-react-popup'
import Button from '../../global/Button'
import LocalizedLink from '../../global/LocalizedLink'
import Translation from '../../global/Translation'
import ReactTooltip from 'react-tooltip'
import { ROUTE_LANDING_TEMPLATE } from '../../../i18n/locales/en-us.json'

export class ActionsCell extends React.Component {
    anchor = null

    constructor(props) {
        super(props)

        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        const linkToCopy =
            window.location.host +
            ROUTE_LANDING_TEMPLATE.replace(':preview?', '')
                //.replace(':iso?', props.dataItem['lang']['id'])
                .replace(':iso?/', '')
                .replace(':slug?', props.dataItem['slug'])
        this.state = {
            show: false,
            linkToCopy: linkToCopy
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ show: false })
        }
    }

    render() {
        return (
            <td style={{ textAlign: 'center' }}>
                <Button className="tablecustomButtonRound" onClick={this.onClick}>
                    <span className="k-icon k-i-more-vertical"></span>
                </Button>
                <Popup offset={this.offset} anchor={this.anchor} show={this.state.show} popupClass={'popup-content-landing-item'}>
                    <div ref={this.setWrapperRef}>
                        <LocalizedLink
                            routeId="ROUTE_LANDING_MANAGER_DETAIL"
                            params={{ id: this.props.dataItem['common_id'], iso: this.props.dataItem['lang']['id'], outlet: '' }}
                            queryString=""
                            className="bt noselect bt-gentle bt-size-medium"
                        >
                            <Translation id="edit" defaultMessage="Edit" />
                        </LocalizedLink>
                        <LocalizedLink
                            routeId="ROUTE_LANDING_TEMPLATE"
                            params={{ slug: this.props.dataItem['slug'], iso: this.props.dataItem['lang']['id'], outlet: '', preview: 'preview' }}
                            queryString=""
                            className="bt noselect bt-gentle bt-size-medium"
                        >
                            <Translation id="preview" defaultMessage="Preview" />
                        </LocalizedLink>
                        <Button
                            className="bt noselect bt-gentle bt-size-medium"
                            onClick={() => {
                                navigator.clipboard.writeText(this.state.linkToCopy).then(() => {
                                    this.setState({ copied: true }, () =>
                                        setTimeout(() => {
                                            this.setState({ copied: false })
                                        }, 2000)
                                    )
                                })
                            }}
                            data-tip={this.state.linkToCopy}
                        >
                            <Translation id="copy_link" defaultMessage="Copy link" />
                            <ReactTooltip bg-coloreffect="solid" place="top" getContent={() => `${this.state.copied ? 'copied! \n' : ''} ${this.state.linkToCopy}`} />
                        </Button>
                        <Button
                            className="bt noselect bt-gentle bt-size-medium"
                            onClick={e => {
                                this.props.onDelete(this.props.dataItem['common_id'], this.props.dataItem['lang']['id'])
                                this.setState({ show: false })
                            }}
                        >
                            <Translation id="delete" defaultMessage="Delete" />
                        </Button>
                    </div>
                </Popup>
            </td>
        )
    }

    onClick = e => {
        this.anchor = e.target
        this.setState({ show: !this.state.show })
    }
}
