import React from "react";
import Translation from "./Translation";

class Countdown extends React.Component {

    promoDate = null
    state = {now: new Date().getTime()}
    interval = null

    componentDidMount() {
        if (!this.props.hide) {
            this.interval = setInterval(this.updateTime, 1)
            const time = new Date(this.props.endDate)
            // time.setDate(time.getDate() + 7)
            this.promoDate = time.getTime()
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    updateTime = () => this.setState({now: new Date().getTime()})

    checkTime = (i) => {
        if (i < 10) {i = "0" + i}  // add zero in front of numbers < 10
        return i;
    }

    render() {

        
        let dateFuture = new Date(this.props.endDate);
        let dateNow = new Date();

        let seconds = Math.floor((dateFuture - (dateNow)) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60); 
        
        if(days < 10) days = "0"+days
        if(minutes < 10) minutes = "0"+minutes
        if(hours < 10) hours = "0"+hours
        if(seconds < 10) seconds = "0"+seconds


        return  (
            <div className="countdown">                
                <div className="countdown-time">
                    <span>{days}</span>
                    <span><Translation id="days" defaultMessage="days" /></span>
                </div>
                <div className="countdown-time">
                    <span>{hours}</span>
                    <span><Translation id="hours" defaultMessage="hours" /></span>
                </div>
                <div className="countdown-time">
                    <span>{minutes}</span>
                    <span><Translation id="minutes" defaultMessage="minutes" /></span>
                </div>
                <div className="countdown-time">
                    <span>{seconds}</span>
                    <span><Translation id="seconds" defaultMessage="seconds" /></span>
                </div>
            </div>
        )
    }
}

export default Countdown

