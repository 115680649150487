import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Modal from '../../global/Modal'
import { capitalizeFirstLetter, getCmOrInches, getMeasureFromMetric } from '../../../helpers'
import LocalizedLink from '../../global/LocalizedLink'
import { getConfigLineName } from '../../orders/new-order-history/Utils'
import Translation from '../../global/Translation'
import { handlePlanPdfDownload } from '../utils'

const mapStateToProps = state => {
    return {
        measureFromMetric: (measure, decimals) => getMeasureFromMetric(measure, decimals, state),
        units: getCmOrInches(state)
    }
}

const IN_DECIMALS = 2

const ModalConfigurationDetails = props => {
    const { showBtnEdit, showBtnDownload, config, onClose, intl, refcode, measureFromMetric, units } = props

    const getModalTitle = () => {
        return (
            <React.Fragment>
                <Translation id="customization_details" defaultMessage="Customization details" /> {getConfigLineName(config)}{' '}
                <Translation id="customized" defaultMessage="Custom" />
            </React.Fragment>
        )
    }

    const getPlanPdfUrl = () => {
        return config && config.planDocumentUrl ? config.planDocumentUrl : null
    }

    const getPreparedUrlForDownload = () => {
        if (getPlanPdfUrl()) {
            return process.env.REACT_APP_API_HOST + '/helper/view-pdf-url/?url=' + encodeURIComponent(getPlanPdfUrl())
        }

        return null
    }

    const onDownloadClick = () => {
        const model = config && config.model && config.model.toLowerCase().replace(' ', '_')
        const referenceCode = refcode ? refcode : config && config.refcode

        handlePlanPdfDownload(getPreparedUrlForDownload(), model, referenceCode)
    }

    return (
        <Modal title={getModalTitle()} showFooter={false} customClass={'modal--configurable-baths'} onClose={onClose}>
            <div className="model--body-container">
                <div className="modal--body-content">
                    {config && config.material && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="color" defaultMessage="Color" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                <span>{config.material.colorName ? capitalizeFirstLetter(config.material.colorName) + ' - ' : ''}</span>
                                <span>{config.material.finish ? intl.formatMessage({ id: config.material.finish }) : ''}</span>&nbsp;
                                <span className="material-brand">{config.material.brand ? capitalizeFirstLetter(config.material.brand) + ' ® ' : ''}</span>
                            </div>
                        </div>
                    )}

                    {/* DATA FOR WASHBASINS */}

                    {config && config.legs && config.legs.position && config.legs.position.length > 0 && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="edge" defaultMessage="Edge" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                {config.legs.position.map((item, index) => (
                                    <div key={index}>
                                        {item.left && (
                                            <div>
                                                <Translation id="position" defaultMessage="Position" />: {item.left}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {config && config.countertop && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="countertop" defaultMessage="Countertop" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                {config.countertop.length && (
                                    <div>
                                        <Translation id="length" defaultMessage="Length" />
                                        :&nbsp;{measureFromMetric(config.countertop.length, IN_DECIMALS) + ' ' + units}
                                    </div>
                                )}
                                {config.countertop.width && (
                                    <div>
                                        <Translation id="width" defaultMessage="Width" />
                                        :&nbsp;{measureFromMetric(config.countertop.width, IN_DECIMALS) + ' ' + units}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {config && config.basins && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="basins_and_taps" defaultMessage="Basins and taps" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                <div>
                                    <Translation id="pieces" defaultMessage="Pieces" />
                                    :&nbsp;{config.basins.items && config.basins.items.length}
                                </div>
                                <div>
                                    <Translation id="position" defaultMessage="Position" />:
                                </div>
                                {config.basins.centered && (
                                    <div>
                                        <Translation id="centered_on_the_countertop" defaultMessage="Centered on the countertop" />
                                    </div>
                                )}
                                {config.basins.left && (
                                    <div>
                                        {measureFromMetric(config.basins.left, IN_DECIMALS)}&nbsp;{units}{' '}
                                        <Translation id="from_the_left_edge_of_the_countertop" defaultMessage="from the left edge of the countertop" />
                                    </div>
                                )}
                                {config.basins.bottom && (
                                    <div>
                                        {measureFromMetric(config.basins.bottom, IN_DECIMALS)}&nbsp;{units}{' '}
                                        <Translation id="from_the_lower_edge_of_the_countertop" defaultMessage="from the lower edge of the countertop" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {config && config.towelRails && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="towel_rails" defaultMessage="Towel rails" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                <div>
                                    <Translation id="pieces" defaultMessage="Pieces" />
                                    :&nbsp;{config.towelRails.items && config.towelRails.items.length}
                                </div>

                                {config.towelRails.items &&
                                    config.towelRails.items.map((item, index) => (
                                        <div key={index}>
                                            {item.length && (
                                                <div>
                                                    <Translation id="size" defaultMessage="Size" />
                                                    :&nbsp;{measureFromMetric(item.length, IN_DECIMALS)}&nbsp;{units}{' '}
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                <div className="py-1">
                                    <span className="font-weight-normal">
                                        <Translation id="positions" defaultMessage="Positions" />:
                                    </span>
                                </div>
                                <div>
                                    {config.towelRails.centered ? (
                                        <Translation id="centered" defaultMessage="Centered" />
                                    ) : (
                                        <Translation id="no_centered" defaultMessage="No centered" />
                                    )}
                                </div>
                                {config.towelRails.left && (
                                    <div>
                                        {measureFromMetric(config.towelRails.left, IN_DECIMALS)}&nbsp;{units}{' '}
                                        <Translation id="from_the_left_edge_of_the_countertop" defaultMessage="from the left edge of the countertop" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {config && config.soapHolders && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="soap_holders" defaultMessage="Soap holders" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                {config.soapHolders.bottom && (
                                    <div>
                                        {measureFromMetric(config.soapHolders.bottom, IN_DECIMALS)}&nbsp;{units}{' '}
                                        <Translation id="from_the_lower_of_the_countertop" defaultMessage="from the lower of the countertop" />
                                    </div>
                                )}
                                <div className="py-1">
                                    <span className="font-weight-normal">
                                        <Translation id="positions" defaultMessage="Positions" />:
                                    </span>
                                </div>
                                {config.soapHolders.items &&
                                    config.soapHolders.items.map((item, index) => (
                                        <div key={index}>
                                            {item.left && (
                                                <div>
                                                    {measureFromMetric(item.left, IN_DECIMALS)}&nbsp;{units}{' '}
                                                    <Translation id="from_the_left_edge_of_the_countertop" defaultMessage="from the left edge of the countertop" />
                                                </div>
                                            )}
                                            {item.diameter && (
                                                <div>
                                                    <Translation id="diameter" defaultMessage="Diameter" />
                                                    :&nbsp;{measureFromMetric(item.diameter, IN_DECIMALS)}&nbsp;{units}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}

                    {config && config.tissueSlots && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="tissue_slots" defaultMessage="Tissue slots" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                <div className="py-1">
                                    <span className="font-weight-normal">
                                        <Translation id="positions" defaultMessage="Positions" />:
                                    </span>
                                </div>
                                {config.tissueSlots.items &&
                                    config.tissueSlots.items.map((item, index) => (
                                        <div key={index}>
                                            {item.left && (
                                                <div>
                                                    {measureFromMetric(item.left, IN_DECIMALS)}&nbsp;{units}{' '}
                                                    <Translation id="from_the_left_edge_of_the_countertop" defaultMessage="from the left edge of the countertop" />
                                                </div>
                                            )}
                                            {item.bottom && (
                                                <div>
                                                    {measureFromMetric(item.bottom, IN_DECIMALS)}&nbsp;{units}{' '}
                                                    <Translation id="from_the_lower_edge_of_the_countertop" defaultMessage="from the lower edge of the countertop" />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}

                    {config && config.tapDrills && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="tap_drills" defaultMessage="Tap drills" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                <div>
                                    <Translation id="quantity" defaultMessage="Quantity" />
                                    :&nbsp;{config.tapDrills.items && config.tapDrills.items.length}
                                </div>
                                <div className="py-1">
                                    <span className="font-weight-normal">
                                        <Translation id="positions" defaultMessage="Positions" />:
                                    </span>
                                </div>
                                {config.tapDrills.items &&
                                    config.tapDrills.items.map((item, index) => (
                                        <div key={index}>
                                            {item.left && (
                                                <div>
                                                    {measureFromMetric(item.left, IN_DECIMALS)}&nbsp;{units} <Translation id="from_left_edge" defaultMessage="from left edge" />
                                                </div>
                                            )}
                                            {item.top && (
                                                <div>
                                                    {measureFromMetric(item.top, IN_DECIMALS)}&nbsp;{units} <Translation id="from_top_edge" defaultMessage="from top edge" />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}

                    {/* DATA FOR SHOWERTRAYS */}

                    {config && config.showertray && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="showertray" defaultMessage="Showertray" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                {config.showertray.length && (
                                    <div>
                                        <Translation id="length" defaultMessage="Length" />
                                        :&nbsp;{measureFromMetric(config.showertray.length, IN_DECIMALS) + ' ' + units}
                                    </div>
                                )}
                                {config.showertray.width && (
                                    <div>
                                        <Translation id="width" defaultMessage="Width" />
                                        :&nbsp;{measureFromMetric(config.showertray.width, IN_DECIMALS) + ' ' + units}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {config && config.drainDrill && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="drain_drill" defaultMessage="Drain drill" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                {config.drainDrill.diameter && (
                                    <div>
                                        <Translation id="diameter" defaultMessage="Diameter" />
                                        :&nbsp;{measureFromMetric(config.drainDrill.diameter, IN_DECIMALS)}&nbsp;{units}
                                    </div>
                                )}
                                <div className="py-1">
                                    <span className="font-weight-normal">
                                        <Translation id="positions" defaultMessage="Positions" />
                                    </span>
                                    :
                                </div>
                                {config.drainDrill.top && (
                                    <div>
                                        {measureFromMetric(config.drainDrill.top, IN_DECIMALS)}&nbsp;{units} <Translation id="from_top_edge" defaultMessage="from top edge" />
                                    </div>
                                )}
                                {config.drainDrill.left && (
                                    <div>
                                        {measureFromMetric(config.drainDrill.left, IN_DECIMALS)}&nbsp;{units} <Translation id="from_left_edge" defaultMessage="from left edge" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {config && config.straightCutOut && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="straight_cut_out" defaultMessage="Straight cut out" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                {config.straightCutOut.size && (
                                    <div>
                                        <Translation id="size" defaultMessage="Size" />
                                        :&nbsp;{measureFromMetric(config.straightCutOut.size, IN_DECIMALS)}
                                    </div>
                                )}
                                {config.straightCutOut.items && config.straightCutOut.items.length > 0 && (
                                    config.straightCutOut.items.map((item, index) => (
                                        <div key={index}>
                                            {item.vertex && (
                                                <div className="py-1">
                                                    <span className="font-weight-normal">
                                                        <Translation id="vertex" defaultMessage="Vertex" />:&nbsp;
                                                        {item.vertex}
                                                    </span>
                                                </div>
                                            )}
                                            {item.lengthDistance && (
                                                <div>
                                                    <Translation id="length" defaultMessage="Length" />:&nbsp;
                                                    {measureFromMetric(item.lengthDistance, IN_DECIMALS) + ' ' + units}
                                                </div>
                                            )}
                                            {item.widthDistance && (
                                                <div>
                                                    <Translation id="width" defaultMessage="Width" />:&nbsp;
                                                    {measureFromMetric(item.widthDistance, IN_DECIMALS) + ' ' + units}
                                                </div>
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    )}

                    {config && config.diagonalCut && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="diagonal_cut" defaultMessage="Diagonal cut" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                <div>
                                    <Translation id="size" defaultMessage="Size" />
                                    :&nbsp;{measureFromMetric(config.diagonalCut.size, IN_DECIMALS)}
                                </div>
                                {config.diagonalCut.items && config.diagonalCut.items.length > 0 && (
                                    config.diagonalCut.items.map((item, index) => (
                                        <div key={index}>
                                            {item.vertex && (
                                                <div className="py-1">
                                                    <span className="font-weight-normal">
                                                        <Translation id="vertex" defaultMessage="Vertex" />:&nbsp;
                                                        {item.vertex}
                                                    </span>
                                                </div>
                                            )}
                                            {item.lengthDistance && (
                                                <div>
                                                    <Translation id="length" defaultMessage="Length" />:&nbsp;
                                                    {measureFromMetric(item.lengthDistance, IN_DECIMALS) + ' ' + units}
                                                </div>
                                            )}
                                            {item.widthDistance && (
                                                <div>
                                                    <Translation id="width" defaultMessage="Width" />:&nbsp;
                                                    {measureFromMetric(item.widthDistance, IN_DECIMALS) + ' ' + units}
                                                </div>
                                            )}
                                            {item.diagonalLength && (
                                                <div>
                                                    <Translation id="diagonal_length" defaultMessage="Diagonal length" />:&nbsp;
                                                    {measureFromMetric(item.diagonalLength, IN_DECIMALS) + ' ' + units}
                                                </div>
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    )}


                    {config && config.showerFrame && config.showerFrame.items && config.showerFrame.items.length > 0 && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="shower_frame" defaultMessage="Shower frame" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                {config.showerFrame.size && (
                                    <div>
                                        <Translation id="size" defaultMessage="Size" />:&nbsp;
                                        {measureFromMetric(config.showerFrame.size, IN_DECIMALS)}
                                    </div>
                                )}
                                <span className="font-weight-normal">
                                    <Translation id="sides" defaultMessage="Sides" />:
                                </span>
                                {config.showerFrame.items.map((item, index) => (
                                    <div key={index}>
                                        <div>{item.side}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {config && config.screenSlot && config.screenSlot.items && config.screenSlot.items.length > 0 && (
                        <div className="body-content__item">
                            <div>
                                <h4>
                                    <Translation id="screen_slot" defaultMessage="Screen slot" />:
                                </h4>
                            </div>
                            <div className="body-content__item--desc">
                                {config.screenSlot.items.map((item, index) => (
                                    <div key={index}>
                                        <div>
                                            {item.position && (
                                                <div>
                                                    <span className="font-weight-normal">
                                                        <Translation id="position" defaultMessage="Position" />:&nbsp;
                                                        {item.position}
                                                    </span>
                                                </div>
                                            )}
                                            {item.side && (
                                                <div>
                                                    <Translation id="side" defaultMessage="Side" />:&nbsp;
                                                    {item.side}
                                                </div>
                                            )}
                                            {item.length && (
                                                <div>
                                                    <Translation id="length" defaultMessage="Length" />:&nbsp;
                                                    {measureFromMetric(item.length, IN_DECIMALS)}&nbsp;{units}
                                                </div>
                                            )}
                                            {item.width && (
                                                <div>
                                                    <Translation id="width" defaultMessage="Width" />:&nbsp;
                                                    {measureFromMetric(item.width, IN_DECIMALS)}&nbsp;{units}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {showBtnDownload && getPlanPdfUrl() && (
                    <div className="body-action--download">
                        <i class="fas fa-download" />
                        <button onClick={onDownloadClick}>
                            <Translation id="download_map" defaultMessage="Download map" />
                        </button>
                    </div>
                )}
            </div>

            {showBtnEdit && (
                <div className="modal--body-footer">
                    <div className="body-footer--edit">
                        <LocalizedLink routeId="ROUTE_CONFIG_CUT_TO_SIZE" params={{ type: config.product, model: config.model.toLowerCase().replace(' ', '_'), refcode: refcode }}>
                            <button>
                                <Translation id="edit_configuration" defaultMessage="Edit configuration" />
                                <i class="fas fa-pen"></i>
                            </button>
                        </LocalizedLink>
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default injectIntl(connect(mapStateToProps, null)(ModalConfigurationDetails))
