import * as api from '../api'
import { getToken } from '../login/reducers'
import { getLanguage } from '../ui/reducers'
import { getSelectedCenter } from '../centers/reducers'
import {
    getSelectedBusinesSegment,
    getSelectedChannel,
    getSelectedSubchannel,
    getSelectedTypology,
    getSelectedUse,
    getSelectedKB,
    getSelectedBuilder,
    getPO,
    getPreferredSegmentationByName,
    getSelectedSalesForceId,
    getSelectedProjectId,
    getSelectedSubchannelProgram,
    getSelectedOriginalChannel
} from './reducers'

export const submittSegmentationModal = callback => (dispatch, getState) => {
    dispatch({ type: 'CLOSE_SEGMENTATION_MODAL' })
    dispatch({ type: 'SUBMIT_SEGMENTATION_SUCCESS' })
    dispatch(callback)
}
export const CLEAR_SEGMENTATION = 'CLEAR_SEGMENTATION'
export const clearSegmentation = () => {
    return {
        type: CLEAR_SEGMENTATION
    }
}
export const TOGGLE_SEGMENTATION = 'TOGGLE_SEGMENTATION'
export const toggleSegmentation = seg => {
    return {
        type: TOGGLE_SEGMENTATION,
        seg
    }
}
export const SELECT_BUSINESS_SEGMENT = 'SELECT_BUSINESS_SEGMENT'
export const selectBusinessSegment = (value, name) => {
    return {
        type: SELECT_BUSINESS_SEGMENT,
        value,
        name
    }
}
export const SELECT_CHANNEL = 'SELECT_CHANNEL'
export const selectChannel = (value, name) => {
    return {
        type: SELECT_CHANNEL,
        value,
        name
    }
}
export const SELECT_SUBCHANNEL = 'SELECT_SUBCHANNEL'
export const selectSubChannel = (value, name) => {
    return {
        type: SELECT_SUBCHANNEL,
        value,
        name
    }
}
export const SELECT_SALESFORCEID = 'SELECT_SALESFORCEID'
export const selectSalesForceId = (value, name, subchannelProgram, businessSegment, subchannelId) => {
    return { type: SELECT_SALESFORCEID, value, name, subchannelProgram, businessSegment, subchannelId }
}
export const SELECT_PROJECTID = 'SELECT_PROJECTID'
export const selectProjectId = (value, name, subchannelProgram, businessSegment, subchannelId) => {
    return { type: SELECT_PROJECTID, value, name, subchannelProgram, businessSegment, subchannelId }
}
export const selectProjectIdV4 = (value, name, subchannelProgram, businessSegment, subchannelId, salesForceId, salesForceName, originalChannel, originalChannelName, nexusOriginalChannel) => {
    return { type: SELECT_PROJECTID, value, name, subchannelProgram, businessSegment, subchannelId, salesForceId, salesForceName, originalChannel, originalChannelName, nexusOriginalChannel }
}
export const SELECT_USE = 'SELECT_USE'
export const selectUse = (value, name) => {
    return {
        type: SELECT_USE,
        value: value,
        name: name
    }
}
export const SELECT_TYPOLOGY = 'SELECT_TYPOLOGY'
export const selectTypology = (value, name) => {
    return {
        type: SELECT_TYPOLOGY,
        value: value,
        name: name
    }
}
export const SELECT_KB = 'SELECT_KB'
export const selectKB = option => {
    return {
        type: SELECT_KB,
        value: option.value,
        name: option.label
    }
}
export const SELECT_BUILDER = 'SELECT_BUILDER'
export const selectBuilder = value => {
    return {
        type: SELECT_BUILDER,
        value
    }
}
export const SET_PO = 'SET_PO'
export const setPO = value => {
    return {
        type: SET_PO,
        value
    }
}
export const FETCH_SEGMENTATION_REQUEST = 'FETCH_SEGMENTATION_REQUEST'
export const FETCH_SEGMENTATION_SUCCESS = 'FETCH_SEGMENTATION_SUCCESS'
export const FETCH_SEGMENTATION_FAILURE = 'FETCH_SEGMENTATION_FAILURE'
export const fetchSegmentation = () => (dispatch, getState) => {
    dispatch({
        type: FETCH_SEGMENTATION_REQUEST,
        selectedCenter: getSelectedCenter(getState()),
        token: getToken(getState()),
        locale: getLanguage(getState())
    })
    return api
        .fetchSegmentation(getSelectedCenter(getState()), getToken(getState()), getLanguage(getState()))
        .then(response => {
            dispatch({ type: FETCH_SEGMENTATION_SUCCESS, segmentation: response.data, segmentationV3: response.data.segmentationV3 })
        })
        .catch(error => {
            dispatch({ type: FETCH_SEGMENTATION_FAILURE })
            api.errorHandler(dispatch, error.response)
        })
}
export const deletePreferredSegmentation = currentFavourite => (dispatch, getState) => {
    dispatch({ type: 'DELETE_PREFERRED_SEGMENTATION_REQUEST', currentFavourite: currentFavourite })
    return api
        .deleteSegmentationPreferred(currentFavourite, getToken(getState()), getSelectedCenter(getState()))
        .then(response => {
            dispatch({ type: 'DELETE_PREFERRED_SEGMENTATION_SUCCESS', currentFavourite: currentFavourite })
            dispatch(fetchSegmentation())
        })
        .catch(error => {
            dispatch({ type: 'DELETE_PREFERRED_SEGMENTATION_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const loadPreferredSegmentation = currentFavourite => (dispatch, getState) => {
    dispatch({ type: 'SET_CURRENT_FAVOURITE', currentFavourite: currentFavourite })
    dispatch({ type: 'LOAD_SEGMENTATION_PREFERED_SUCCESS', segmentation: getPreferredSegmentationByName(getState(), currentFavourite) })
}
export const loadThisSegmentation = segmentation => (dispatch, getState) => {
    dispatch({
        type: 'LOAD_SEGMENTATION_PREFERED_SUCCESS',
        segmentation: segmentation
    })
}
export const saveSegmentationPreferred = currentFavourite => (dispatch, getState) => {
    dispatch({ type: 'SAVE_CURRENT_FAVOURITE_REQUEST', currentFavourite: currentFavourite })
    const state = getState()
    const originalChannel = getSelectedOriginalChannel(state)
    const kb = getSelectedKB(state)
    const builder = getSelectedBuilder(state)
    const channel = getSelectedChannel(state)



    let segmentationData = {
        segmentation: {
            name: currentFavourite,
            businessSegment: getSelectedBusinesSegment(state),
            channel: originalChannel ? originalChannel :channel,
            subchannel: getSelectedSubchannel(state),
            salesforceId: getSelectedSalesForceId(state),
            projectId:getSelectedProjectId(state),
            subchannelProgram: getSelectedSubchannelProgram(state),
            typology: getSelectedTypology(state),
            use: getSelectedUse(state),
            kbId: kb ? kb.id : null,
            kbName: kb ? kb.name : null,
            builderId: builder ? builder.id : null,
            builderName: builder ? builder.name : null,
            purchaseOrders: null
        }
    }

    return api
        .saveSegmentationPreferred(
            getToken(state),
            getSelectedCenter(state),
            segmentationData
        )
        .then(response => {
            dispatch({ type: 'SAVE_CURRENT_FAVOURITE_SUCCESS', segmentation: response.data })
            dispatch(fetchSegmentation())
        })
        .catch(error => {
            dispatch({ type: 'SAVE_CURRENT_FAVOURITE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
