import React, { useEffect, useState } from 'react'
import FilterTag from "./FilterTag";
import Translation from "../Translation";

const FilterTags = props => {

    function getTags(filters) {
        const _tags = {}

        Object.entries(filters).forEach(([k, f]) => Object.entries(f.filters).forEach(([_k, _f]) => {
            if (_k !== 'empty' && !!_f.isSelected) {
                _tags[_k] = {label:f.label, value: _f.label, parentId: k}
            }
        }))

        return _tags
    }

    const [tags, setTags] = useState({})

    useEffect(() => {
        setTags(getTags(props.filters))
    }, [props.filters])


    const onClose = (id, parentId) => {
        const newTags = Object.assign({}, tags)
        delete newTags[id]
        setTags(newTags)
        const filters = Object.assign({}, props.filters)
        filters[parentId].filters[id].isSelected = false
        if (filters[parentId].filters[id].defaultLabel) filters[parentId].filters[id].label = filters[parentId].filters[id].defaultLabel
        if (filters[parentId].filters[id].defaultValue) {
            filters[parentId].filters[id].mapper.output = Object.assign({}, filters[parentId].filters[id].defaultValue)
            filters[parentId].filters[id].mapper.prevOutput = Object.assign({}, filters[parentId].filters[id].defaultValue)
        }

       //console.log('TAGAS ' , filters[parentId].filters[id])

        if (!!filters[parentId].filters.empty) {
            const fromParentTags = Object.values(newTags).filter(v => v.parentId === parentId)
            if (fromParentTags.length === 0) filters[parentId].filters.empty.isSelected = true
        }

        if (!newTags[parentId])
        props.onChange(filters)
    }

    return Object.keys(tags).length !== 0 ? (
            <div className="filters__tag">
                {
                    Object.entries(tags).map(([id, value]) => {
                        return <FilterTag id={id} label={value.label} value={value.value} onClose={() => onClose(id, value.parentId)}/>
                    })
                }
                <span className="filters__tag--clear-all"
                      onClick={props.onClean}
                >
                    <Translation id='clean_filters' defaultMessage='Clean filters'/>
                </span>
            </div>
    ) : null
}

export default FilterTags