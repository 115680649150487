import React from 'react'
import ConfigurableCutToSize from "../components/cut-to-size/configurable-baths/ConfigurableCutToSize";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {addCartLineCutToSize, changeCartLineCutToSize} from "../store/cart/actions";
import { setIsConfigurableBathEditScreen } from '../store/ui/actions';
import {getCmOrInches, hasImperialUnits} from "../helpers";
import { getLocalizedUrl } from '../store/ui/reducers';
import { addConfigurableInProgress, addConfigurablePlanAcceptanceChecked, addConfigurableModifiedList } from '../store/cut-to-size/actions';
import { getConfigurableInProgress, getConfigurablePlanAcceptanceList, getConfigurableDrawingList } from '../store/cut-to-size/reducers'
import { getLines } from '../store/cart/reducers'
import { getUserName, getPriceListAtSelectedCenter } from '../store/login/reducers';
import { getSelectedCenter,getSalesOrg } from '../store/centers/reducers';

const ConfigCutToSizeScreen = props => <ConfigurableCutToSize {...props} />

const mapDispatchToProps = (dispatch, ownprops) => {
    return {
        addCartLine: (qty, cts) => dispatch(addCartLineCutToSize(qty, cts)),
        setIsConfigurableBathEditScreen: isOrderHistoryScreen => {
            dispatch(setIsConfigurableBathEditScreen(isOrderHistoryScreen))
        },
        addConfigurableInProgress: (config) => dispatch(addConfigurableInProgress(config)),
        addConfigurablePlanAcceptanceChecked: (refcode, checked) => dispatch(addConfigurablePlanAcceptanceChecked(refcode, checked)),
        changeCartLineCutToSize: (lineId, cutToSize) => dispatch(changeCartLineCutToSize(lineId, cutToSize)),
        addConfigurableModifiedList: (refcode, isModified) => dispatch(addConfigurableModifiedList(refcode, isModified))
    }
}

const mapStateToProps = (state, ownprops) => {
    return {
        locale: hasImperialUnits(state) ? 'en-US' : 'es-ES',
        model: ownprops.match.params.model,
        refcode: ownprops.match.params.refcode,
        type: ownprops.match.params.type,
        units: getCmOrInches(state),
        localizedUrl: routeId => getLocalizedUrl(state, ownprops.intl, routeId),
        configurableInProgress: getConfigurableInProgress(state),
        configurablePlanAcceptanceList: getConfigurablePlanAcceptanceList(state),
        checkoutLines: getLines(state),
        configurableDrawingList: getConfigurableDrawingList(state),
        username: getUserName(state),
        salesOrg: getSalesOrg(state),
        selectedCenter: getSelectedCenter(state),
        priceListAtSelectedCenter: getPriceListAtSelectedCenter(state)
    }
}

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ConfigCutToSizeScreen)
)
