import React from 'react'

const Modal = props => {
    const { customStyle, title, subtitle, children, footer, onClose, size, customClass, showCloseButton = true, showBody = true, showFooter = true, customId='general-modal-comp', gtmLabel } = props

    let dialogClasses = ''

    switch (size) {
        case 'small':
            dialogClasses += ' modal-sm'
            break
        case 'large':
            dialogClasses += ' modal-lg'
            break
        case 'xl':
            dialogClasses += ' modal-xl'
            break
        default:
            dialogClasses += ' modal-lg'
    }

    if (customClass) {
        dialogClasses += ' ' + customClass
    }

    return (
        <div id={customId} gtm-label={gtmLabel}>
            <div className="full-overlay" onClick={onClose} />
            <div className={'modal modal-new-version-experience fade show ' + customClass} tabIndex="-1" role="dialog" data-backdrop="static" style={{ display: 'block' }}>
                <div className={'modal-dialog' + dialogClasses} data-cy="global__modal_dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title" data-cy="modal__title">
                                    {title}
                                </h5>
                                {subtitle && <span>{subtitle}</span>}
                            </div>
                            {showCloseButton && (
                                <button type="button" className="close" aria-label="Close" onClick={onClose}>
                                    <i className="fal fa-times" />
                                </button>
                            )}
                        </div>

                        {showBody && (
                            <div className="modal-body" style={customStyle}>
                                {children}
                            </div>
                        )}
                        {showFooter && <div className="modal-footer">{footer}</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
