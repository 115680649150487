import React from 'react'

import SegmentationV3 from './SegmentationV3'
import Segmentation from './Segmentation'
import SegmentationConfiguration from "./SegmentationConfiguration";
class SegmentationSelector extends React.Component {

    render() {
        const isInConfiguration = !!window.location.pathname.match(/segmentation/gi)

        return this.props.segmentationV3 ?
            isInConfiguration ?
                <SegmentationConfiguration {...this.props} />
                :
                <SegmentationV3 {...this.props} />
            : <Segmentation {...this.props} />
    }
}

export default SegmentationSelector
