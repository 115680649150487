import { connect } from 'react-redux'
import { getIsLogged } from '../../store/login/reducers'
import { canViewMktools } from '../../store/centers/reducers'
import Translation from "../global/Translation";
import React from "react";
import Countdown from "../global/Countdown";
import RectangleResultContainer from '../catalog/rectangleResult/RectangleResultContainer'
import Slider from 'react-slick/lib/slider'
import { getUserLandings } from '../../store/landing/reducers'
import LocalizedLink from '../global/LocalizedLink'
import {fetchProductsLandingsByCenters} from "../../store/landing/actions";
import {getMallProduct} from "../landing-templates/utils";
import ProductContext, {MallProductContext} from "../catalog/rectangleResult/contexts/ProductContext";

const mapStateToProps = state => {
    //const products = getProductsForYou(state)/*getLastProductBoughts(state)*/

    return {
        //products: products && products.map(el => el.product),
        hide: !getIsLogged(state) || canViewMktools(state),
        userLandings: getUserLandings(state),
        productsLanding: state.landing.productsLanding,
        productsLots: state.landing.productsLots,
        gtmLabel: 'ec_carrusel_promotion_mall'
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProductsLandingsByCenters: (landingId, centers, products, portfolioMode) => {
            return dispatch(fetchProductsLandingsByCenters(landingId, centers, products, portfolioMode))
        },
    }
}



function SampleNextArrow(props) {
    const { style, onClick } = props
    return (
        <i
            className={'fal fa-chevron-right'}
            style={{ ...style, display: 'block', position: 'absolute', right: '-16px', top: '30%', fontSize: '30px', cursor: 'pointer', zIndex: '10' }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { style, onClick } = props
    return (
        <i
            className={'fal fa-chevron-left'}
            style={{ ...style, display: 'block', position: 'absolute', left: '-14px', top: '30%', fontSize: '30px', cursor: 'pointer', zIndex: '10' }}
            onClick={onClick}
        />
    )
}

class MallPreviewContainer extends React.Component {
    state = {
        slideIndex: 0,
        landings: [],
        lastFetchedLanding: -1
    }

    resize = () => {        
        // let slices = 4
        // if(window.innerWidth < 1430) {
        //     slices = 3
        // }
        // if (window.innerWidth < 1010) {
        //     slices = 2
        // }
        // if (window.innerWidth < 768) {
        //     slices = 1
        // }
        const slidesToShow =  this.getSlidesToShow()
        this.setState({ slidesToShow })
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.lastFetchedLanding === -1) this.setState({lastFetchedLanding: 0})

        if (this.props.userLandings.length > 0
            && this.state.lastFetchedLanding < this.props.userLandings.length
            && this.state.lastFetchedLanding !== prevState.lastFetchedLanding
        ) {
            let today = new Date()
            const landing = this.props.userLandings[this.state.lastFetchedLanding]
            if(new Date(landing.end_at) > today) {
                this.props.fetchProductsLandingsByCenters(landing.common_id, landing.centers, landing.products, landing.portfolio_mode)
            } else {
                this.setState({
                    lastFetchedLanding: this.state.lastFetchedLanding + 1
                })
            }
        }

        if (this.props.productsLanding !== prevProps.productsLanding ) {
            const landing = this.props.userLandings[this.state.lastFetchedLanding]

            if (landing) {
                landing.products = landing.products.map(product => {
                    // return product.data
                    return getMallProduct(product, this.props.productsLanding, this.props.productsLots)
                })
                this.state.landings.push(landing)
                this.setState({
                    lastFetchedLanding: this.state.lastFetchedLanding + 1
                }, this.resize)
            }
        }
    }


    getSlidesToShow = () => {
        const maxSlidesToShow = 10
        const minGap = 12
        const minWidth = 250
        const minCardWidth = this.props.minWidth ? this.props.minWidth : minWidth
        const el = document.getElementById('mall-promo-slides')
        if (!el) return 1
        let maxSlides = Math.floor(el.offsetWidth / minCardWidth) //250 Card width
        const gap = (el.offsetWidth - minCardWidth * maxSlides) / (1 + maxSlides)
        if (gap < minGap) --maxSlides
        const slidesCount = this.props.productsLanding.length > maxSlides ? maxSlides : this.props.productsLanding.length
        return Math.min(maxSlidesToShow, slidesCount)
    }

    render() {
        const settings = {
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            dots: true,
            speed: 300,
            slidesToShow: this.state.slidesToShow,
            slidesToScroll: 1,
            infinite: false,
            variableWidth: false
        }
        //if (this.props.hide || !this.props.products || !this.props.products[0]) return <React.Fragment>hola</React.Fragment>
        //console.log({ productNormal: this.props.products[0], productMall: this.props.userLandings[0].products[0]});
        
        return(
            this.state.landings.length > 0 &&(
                this.state.landings.map(landing => {
                    let today = new Date()                    
                    if(new Date(landing.end_at) > today) 
                        return(

                        <section id="section-products-slider">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="container" gtm-label={this.props.gtmLabel}>
                                        <div className="general-results-list">
                                            <div className="results-list mall-preview-slider">
                                                <div style={{ padding: '16px 8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="mall-promo-card">
                                                    <h2>
                                                        <span>{landing.title}</span>
                                                    </h2>
                                                    <div className="timer-promo">
                                                        <span><Translation id="time_remaining" defaultMessage="Promotion time remaining" /></span>
                                                        <Countdown endDate={landing.end_at ? landing.end_at : "2025-02-15"} />
                                                    </div>
                                                </div>
                                                <div id="mall-promo-slides" >
                                                    <Slider ref={s => (this.slider = s)} {...settings}>
                                                        {landing.products.map((product, index) => product && (
                                                            <div className="slider-cards" key={`pindex-${index}`}>
                                                                <ProductContext.Provider value={MallProductContext}>
                                                                    <RectangleResultContainer
                                                                        key={index}
                                                                        product={product}
                                                                        mallPromotionSection={true}
                                                                        list='promotion_products'
                                                                        showImg={true}
                                                                    />
                                                                </ProductContext.Provider>
                                                            </div>
                                                        )
                                                        )}
                                                    </Slider>
                                                </div>
                                                <div className={'mall-promotion-container'}>
                                                    <LocalizedLink routeId={"ROUTE_LANDING_TEMPLATE"} params={{ slug: landing ? landing['slug'] : "test", outlet: '', preview: '', iso: '' }} queryString={""} gtmLabel="ec_button_view_promotion_mall">
                                                        <button className={"mall-promotion-btn"}>
                                                            <Translation id="see_products_mall" defaultMessage="See all products of the Mall " />
                                                        </button>
                                                    </LocalizedLink>
                                                </div>
                                            </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        </section>
                    )
                })
                
            )
        
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MallPreviewContainer)

