import * as api from '../api'
import { getToken } from '../login/reducers'
import { getSelectedCenter } from '../centers/reducers'

export const fetchProfileConfiguration = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PROFILE_CONFIGURATION_REQUEST'
    })
    return api
        .fetchProfileConfiguration(getToken(getState()), getSelectedCenter(getState()))

        .then(
            response => {
                response.data.email = response.data.email === '' ? [] : response.data.email.split(';')
                dispatch({ type: 'FETCH_PROFILE_CONFIGURATION_SUCCESS', profileConfiguration: response.data })
                // if (response.data.locale) {
                //     dispatch({ type: 'SET_LANGUAGE', locale: response.data.locale })
                //     dispatch(push(`/${response.data.locale === 'en-US' ? '' : response.data.locale}`))
                // }
            },
            error => {
                dispatch({ type: 'FETCH_PROFILE_CONFIGURATION_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const saveProfileConfiguration = profileConfigData => (dispatch, getState) => {
    dispatch({
        type: 'SAVE_PROFILE_CONFIGURATION_REQUEST',
        profileConfigData
    })

    return api.saveProfileConfiguration(getToken(getState()), profileConfigData, getSelectedCenter(getState())).then(
        () => {
            dispatch({
                type: 'SAVE_PROFILE_CONFIGURATION_SUCCESS',
                alert: 'YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY'
            })
            dispatch(fetchProfileConfiguration())
        },
        error => {
            dispatch({
                type: 'SAVE_PROFILE_CONFIGURATION_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        }
    )
}

export const setUserEmailAAD = email => (dispatch, getState) => {
    dispatch({
        type: 'SET_USER_EMAIL_AAD_REQUEST',
        email
    })

    return api.setUserEmailAAD(getToken(getState()), email)
        .then(
            response => {
                dispatch({
                    type: 'SET_USER_EMAIL_AAD_SUCCESS',
                    alert: 'YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY'
                })
            })
        .catch(error => {
                  dispatch({ type: 'SET_USER_EMAIL_AAD_FAILURE', alert: error.response.data.err, status: error.response.status,statusText: error.response.data.err })

            })
}
export const changePassword = password => (dispatch, getState) => {
    dispatch({
        type: 'CHANGE_PASSWORD_REQUEST'
    })
    return api.changePassword(getToken(getState()), password).then(
        () => {
            dispatch({
                type: 'CHANGE_PASSWORD_SUCCESS',
                alert: 'YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY'
            })
            dispatch(fetchProfileConfiguration())
        },
        error => {
            dispatch({ type: 'CHANGE_PASSWORD_FAILURE', alert: error.response.data.err, status: error.response.status })
        }
    )
}
export const fetchPromotionConfiguration = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PROMOTION_CONFIGURATION_REQUEST'
    })
    return api.fetchPromotionConfiguration(getToken(getState())).then(
        response => {
            response.data.email = !response.data.email || response.data.email === '' ? [] : response.data.email.split(';')
            dispatch({ type: 'FETCH_PROMOTION_CONFIGURATION_SUCCESS', promotionConfiguration: response.data })
            // if (response.data.locale) {
            //     dispatch({ type: 'SET_LANGUAGE', locale: response.data.locale })
            //     dispatch(push(`/${response.data.locale === 'en-US' ? '' : response.data.locale}`))
            // }
        },
        error => {
            dispatch({ type: 'FETCH_PROMOTION_CONFIGURATION_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const fetchWhatsappConfiguration = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_WHATSAPP_CONFIGURATION_REQUEST'
    })
    return api.fetchWhatsappConfiguration(getToken(getState())).then(
        response => {
            dispatch({ type: 'FETCH_WHATSAPP_CONFIGURATION_SUCCESS', whatsappConfiguration: response.data })
        },
        error => {
            dispatch({ type: 'FETCH_WHATSAPP_CONFIGURATION_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const savePromotionConfiguration = profileConfigPromotion => (dispatch, getState) => {
    dispatch({
        type: 'SAVE_PROMOTION_CONFIGURATION_REQUEST',
        profileConfigPromotion
    })

    return api.savePromotionConfiguration(getToken(getState()), profileConfigPromotion).then(
        () => {
            dispatch({
                type: 'SAVE_PROMOTION_CONFIGURATION_SUCCESS',
                alert: 'YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY'
            })
            if (profileConfigPromotion.acceptPromotionNotifications) {
                dispatch({
                    type: 'OPEN_THANK_YOU_SUBSCRIBE_MODAL'
                })
            }
            //dispatch(fetchPromotionConfiguration())
        },
        error => {
            dispatch({
                type: 'SAVE_PROMOTION_CONFIGURATION_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
        }
    )
}
export const unsubscribeNewsletter = tokenOnlyLogin => (dispatch, getState) => {
    dispatch({
        type: 'UNSUBSCRIBE_NEWSLETTER_REQUEST',
        tokenOnlyLogin
    })

    return api.unsubscribeNewsletter(tokenOnlyLogin).then(
        () => {
            dispatch({
                type: 'UNSUBSCRIBE_NEWSLETTER_SUCCESS',
                alert: 'YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY'
            })
            return Promise.resolve()
        },
        error => {
            dispatch({
                type: 'UNSUBSCRIBE_NEWSLETTER_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
            return Promise.reject()
        }
    )
}
export const resubscribeNewsletter = tokenOnlyLogin => (dispatch, getState) => {
    dispatch({
        type: 'RESUBSCRIBE_NEWSLETTER_REQUEST',
        tokenOnlyLogin
    })

    return api.resubscribeNewsletter(tokenOnlyLogin).then(
        () => {
            dispatch({
                type: 'RESUBSCRIBE_NEWSLETTER_SUCCESS',
                alert: 'YOUR_PREFERENCES_HAVE_BEEN_SAVED_SUCCESSFULLY'
            })
            return Promise.resolve()
        },
        error => {
            dispatch({
                type: 'RESUBSCRIBE_NEWSLETTER_FAILURE',
                alert: error.response.data.err,
                status: error.response.status
            })
            return Promise.reject()
        }
    )
}
