import brochureSensa from '../assets/img/lowes/brochureSensa.jpg'
import brochureSilestone from '../assets/img/lowes/brochureSilestone.jpg'

//lowes
export const productsLowes2 = [
    {
        productId: '223252',
        name: 'ALPINA WHITE 08',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/6B/detalle/6B-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/6B/detalle/6B-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'codigo1'
    },
    {
        productId: '231004',
        name: 'ALTAIR15',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/VA1/detalle/VA1-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/VA1/detalle/VA1-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'F255187'
    },
    {
        productId: '231990',
        name: 'ARIEL',
        thumb: 'https://assetscolors.cosentino.com/get/sap-ARI/1/slab.jpg?w=100&h=100&fit=crop&auto=compress,format',
        img: 'https://assetscolors.cosentino.com/get/sap-ARI/1/slab.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '258644',
        name: 'BLANCO CITY',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/9Y/detalle/9Y-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/9Y/detalle/9Y-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,75X0,7',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '228682',
        name: 'MAPLE14',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/M14/detalle/M14-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/M14/detalle/M14-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '232555',
        name: 'BLANCO ORION',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/BON/detalle/BON-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/BON/detalle/BON-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    // {
    //   "productId": "231062",
    //   "name": "NO TIENE",
    //   "thumb": "https://assetstools.cosentino.com/api/v1/bynder/product/nocolor/colorsil/colorsil.jpg?trim=color&w=140&h=140&auto=compress",
    //   "img": "https://assetstools.cosentino.com/api/v1/bynder/product/nocolor/colorsil/colorsil.jpg?trim=color&w=500&h=300&auto=compress",
    //   "brand": "SILESTONE",
    //   "format": "NOTIENE"
    // , },
    {
        productId: '230844',
        name: 'CORAL CLAY',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/BCR/detalle/BCR-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/BCR/detalle/BCR-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '230447',
        name: 'GREY EXPO',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/GE/detalle/GE-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/GE/detalle/GE-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '223290',
        name: 'LAGOON',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/VLN/detalle/VLN-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/VLN/detalle/VLN-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '228686',
        name: 'LUNA14 JUM',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/LU1/detalle/LU1-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/LU1/detalle/LU1-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'ECO',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '231063',
        name: 'LUSSO',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/LSS/detalle/LSS-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/LSS/detalle/LSS-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    // {
    //   "productId": "223277",
    //   "name": "LYRA",
    //   "thumb": "https://assetstools.cosentino.com/api/v1/bynder/product/VLM/integrity-due-l/integrity-due-l.jpg?trim=color&w=140&h=140&auto=compress",
    //   "img": "https://assetstools.cosentino.com/api/v1/bynder/product/VLM/integrity-due-l/integrity-due-l.jpg?trim=color&w=500&h=300&auto=compress",
    //   "brand": "SILESTONE",
    //   "format": "9,8X4,7X0,3"
    // , },
    {
        productId: '229556',
        name: 'MOUNTAIN MIST 12',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/MOM/detalle/MOM-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/MOM/detalle/MOM-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '234965',
        name: 'ROYAL REEF',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/SL3/detalle/SL3-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/SL3/detalle/SL3-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '229553',
        name: 'SIENNA RIDGE 12',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/MOS/detalle/MOS-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/MOS/detalle/MOS-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '223246',
        name: 'STELLAR NIGHT',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/ES/detalle/ES-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/ES/detalle/ES-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '229559',
        name: 'STELLAR BLANCO13',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/BS4/detalle/BS4-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/BS4/detalle/BS4-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    // {
    //   "productId": "224658",
    //   "name": "VORTIUM",
    //   "thumb": "https://assetstools.cosentino.com/api/v1/bynder/product/SMV/integrity-due-l/integrity-due-l.jpg?trim=color&w=140&h=140&auto=compress",
    //   "img": "https://assetstools.cosentino.com/api/v1/bynder/product/SMV/integrity-due-l/integrity-due-l.jpg?trim=color&w=500&h=300&auto=compress",
    //   "brand": "SILESTONE",
    //   "format": "9,8X4,7X0,3"
    // , },
    {
        productId: '226380',
        name: 'WHITE DIAMOND',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/E7/detalle/E7-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/E7/detalle/E7-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'ECO',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    {
        productId: '212986',
        name: 'WHITE ZEUS Z',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/BZ/detalle/BZ-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/BZ/detalle/BZ-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3',
        lowesCode: 'tarariquetevi'
    },
    //Estos van  ser bornichures
    // {
    //     productId: '230711',
    //     name: 'CYGNUS15',
    //     thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/VC5/detalle/VC5-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
    //     img: 'https://assetstools.cosentino.com/api/v1/bynder/color/VC5/detalle/VC5-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
    //     brand: 'SILESTONE',
    //     format: '9,8X4,7X0,3'
    // },
    // {
    //     productId: '226941',
    //     name: 'FOREST SNOW',
    //     thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/EFS/detalle/EFS-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
    //     img: 'https://assetstools.cosentino.com/api/v1/bynder/color/EFS/detalle/EFS-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
    //     brand: 'ECO',
    //     format: '9,8X4,7X0,3'
    // },
    {
        productId: '223263',
        name: 'TIGRIS SAND',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/6T/detalle/6T-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/6T/detalle/6T-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3'
    },
    {
        productId: '228683',
        name: 'WHITE STORM14',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/WS1/detalle/WS1-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/WS1/detalle/WS1-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        brand: 'SILESTONE',
        format: '9,8X4,7X0,3'
    }
]

export const brochuresLowes = [
    {
        productId: '907218',
        name: 'Brochure Sensa',
        thumb: brochureSensa,
        img: brochureSensa,
        brand: 'Sensa'
    },
    {
        productId: '905252',
        name: 'Brochure Silestone',
        thumb: brochureSilestone,
        img: brochureSilestone,
        brand: 'Silestone'
    }
]

//cutToSize
export const furnituresCutToSize = [
    {
        name: 'furniture 1 (con color)',
        id: 'id1',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        furniture: {
            color: {
                label: 'IROK',
                value: 'DOK',
                thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
                img: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
                product: {
                    id: 'DOK',
                    name: 'IROK',
                    format: '320X144',
                    subtypeId: 'ESTANDAR',
                    img: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
                    imgFull: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-detail.jpg?fit=crop&auto=compress,format',
                    thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
                    thumbMd: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=220&h=160&fit=crop&auto=compress,format',
                    thumbMdPreview: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=22&h=16&fit=crop&auto=compress,format',
                    finish: ['GC2', 'MAL', 'AND'],
                    thickness: ['0.8', '1.2', '2.0']
                }
            },
            width: 100,
            height: 100,
            thickness: '2.0',
            finish: 'MAL'
        }
    },
    {
        name: 'furniture 2 (sin color)',
        id: 'id2',
        thumb: '',
        img: '',
        furniture: {
            color: null,
            width: 150,
            height: 100,
            thickness: '2.0',
            finish: 'MAL'
        }
    },
    {
        name: 'furniture 3 (incompleto)',
        id: 'id3',
        thumb: '',
        img: '',
        furniture: {
            color: null,
            width: 500,
            // height: 100,
            thickness: '2.0',
            finish: 'AND'
        }
    }
]
export const samplesCutToSize = [
    {
        name: 'ejemplo 1 (con color)',
        id: 'id1',
        thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
        img: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
        sample: {
            color: {
                label: 'IROK',
                value: 'DOK',
                thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
                img: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
                product: {
                    id: 'DOK',
                    name: 'IROK',
                    format: '320X144',
                    subtypeId: 'ESTANDAR',
                    img: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-detail.jpg?w=500&h=300&fit=crop&auto=compress,format',
                    imgFull: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-detail.jpg?fit=crop&auto=compress,format',
                    thumb: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=140&h=140&fit=crop&auto=compress,format',
                    thumbMd: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=220&h=160&fit=crop&auto=compress,format',
                    thumbMdPreview: 'https://assetstools.cosentino.com/api/v1/bynder/color/DOK/detalle/DOK-thumb.jpg?w=22&h=16&fit=crop&auto=compress,format',
                    finish: ['GC2', 'MAL', 'AND'],
                    thickness: ['0.8', '1.2', '2.0']
                }
            },
            width: 100,
            height: 100,
            thickness: '2.0',
            finish: 'GC2'
        }
    },
    {
        name: 'ejemplo2 (sin color)',
        id: 'id2',
        thumb: '',
        img: '',
        sample: {
            color: null,
            width: 150,
            height: 100,
            thickness: '2.0',
            finish: 'MAL'
        }
    },
    {
        name: 'ejemplo3 (incompleto)',
        id: 'id3',
        thumb: '',
        img: '',
        sample: {
            color: null,
            width: 500,
            // height: 100,
            thickness: '2.0',
            finish: 'AND'
        }
    }
]

//mktools
export const productWithOptionsByMktools = ['906543', '907369', '907635']
export const listBrandsMktoolsAttr = ['SILESTONE', 'DEKTON', 'PREXURY', 'SCALEA', 'SENSA', 'EARTHIC', 'SILESTONEXM']
export const colorsMktoolsAttr2 = {
    SILESTONE: ['Color Silestone'],
    DEKTON: ['Color Dekton'],
    PREXURY: ['Color Prexury'],
    SCALEA: ['Color Scalea'],
    SENSA: ['Color Sensa']
}
export const colorsMktoolsAttr = {
    SENSA: [
        'Abyss',
        'Amarillo Santa Cecilia',
        'Amazonas',
        'Amelia Ridge',
        'Amelia Ridge',
        'Avalanche',
        'Baltic Brown',
        'Baltica Cream',
        'Beige Butterfly',
        'Beige Ipanema',
        'Bianco Antico',
        'Black beauty',
        'Black Galaxy',
        'Blanco Gabrielle',
        'Blanco Leblon',
        'Blat',
        'Blue Pearl',
        'Boira',
        'Brown Persa',
        'Caledonia',
        'Caroline Summer',
        'Colonial white',
        'Cosmic Grey',
        'Cosmos Brown',
        'Crema Pearl',
        'Crescent Veil',
        'Crisol',
        'Dakota Mahagony',
        'Dawn Mist',
        'Delicatus Ice',
        'El Dorado',
        'Emerald Pearl',
        'Floresta Green',
        'Gabon',
        'Giallio Latina',
        'Giallo Latina',
        'Giallo Ornamental',
        'Giallo Vicenza',
        'Glacial Blue',
        'Golden King',
        'Graphite Grey',
        'Highland Frost',
        'Iberian Sunset',
        'Ice Blue',
        'Indian Black',
        'Jatoba Red',
        'Juparana Cathedral',
        'Juparana Fantasy',
        'Key West Gold',
        'Lapidus',
        'Latina',
        'Luna Pearl',
        'Madison Spring',
        'Majestic White',
        'Manglar',
        'Maroon Bay',
        'Moak black',
        'Namib',
        'New Smoke',
        'New Zealand',
        'Nilo',
        'Orinoco',
        'Outono',
        'Red Terrain',
        'San Benedito',
        'Sant Angelo',
        'Santa Cecilia',
        'Savannah',
        'Silver Silk',
        'Star Beach',
        'Star Galaxy',
        'Tahoe',
        'Taj Mahal',
        'Tanami',
        'Tangier',
        'Tea',
        'Thailand',
        'Thailand',
        'Tropical Brown',
        'Tuscany Brown',
        'Tuscany',
        'Ubatuba',
        'Vena Oro',
        'Verde Aquarius',
        'Verde Butterfly',
        'Waterfall',
        'White Macaubas'
    ],
    SCALEA: [
        'Absolute Cream',
        'Alaskan',
        'Amarillo Indalo',
        'Amarillo Ornametal',
        'Amazonix',
        'Antique White',
        'Arabescato',
        'Athiri White',
        'Avalanche Marble',
        'Azul Bahia',
        'Azul Celeste',
        'Azul Macauba ',
        'Bamboo',
        'Bambu ',
        'Beige Butterfly',
        'Beige Ipanema',
        'Black Beauty ',
        'Black Marinace',
        'Black Pearl',
        'Black Sky',
        'Blanco Acropolis',
        'Blanco Alaskan',
        'Blanco Cachoeiro',
        'Blanco Cintilante',
        'Blanco Classico',
        'Blanco Cristal',
        'Blanco Desiree',
        'Blanco Iberico',
        'Blanco Macael',
        'Blanco Romano',
        'Blanco Tranco',
        'Blanquino',
        'Blue Flower',
        'Bordeaux',
        'Bordeaux River',
        'Cafe Emperador',
        'Calacatta ',
        'Caliza Alba',
        'Caliza Blanca',
        'Caliza Capri',
        'Caliza Sahel',
        'Capuccino',
        'Caramelo br',
        'Carioca Gold',
        'Chateau Blanc',
        'Coffe Brown',
        'Copacabana',
        'Copenhagen',
        'Copper Sunset',
        'Cosmic Black',
        'Cosmic Brown',
        'Crema Bordeaux',
        'Crema Julia',
        'Crema Marfil',
        'Crema-Bordeaux-New',
        'Crystalo',
        'Daino Reale',
        'Dark Emperador',
        'Delicatus',
        'Denia Cream',
        'El Gran Dorado',
        'Emerald Quartzite',
        'Estatuario ',
        'Everest',
        'Everest Marble',
        'Exodus White',
        'Four Seasons',
        'Fusion',
        'Giallo Antico',
        'Giallo Fiorito',
        'Giallo Santa Cruz',
        'Gold Bariloche',
        'Gold Macaubas',
        'Golden Beach',
        'Golden Fantasy',
        'Golden Marinace',
        'Golden Rustic',
        'Golden Thunder',
        'Gran Perla',
        'Gran Valle',
        'Granada Hill',
        'Green Emerald',
        'Gris Adria',
        'Gris Azulado Rústicas',
        'Gris Macael',
        'Gris Monaco',
        'Gris Pulpis',
        'Gris Viola',
        'Grisal',
        'Iberian White',
        'Icy Mist',
        'Indian Impala',
        'Javea Cream',
        'Juaparama Gold',
        'Juparana Colombo',
        'Juparana Dream',
        'Kozmus',
        'Labrador Claro',
        'Lapidus Gold',
        'Madura Gold',
        'Magma Gold',
        'Magnetic Blue',
        'Maracuya',
        'Maroon Bay',
        'Marpa Rusticas',
        'Marron Cohiba',
        'Marron Emperador',
        'Marron Tabaco',
        'Marron Tupi',
        'Mascarello',
        'Matrix',
        'Moleanos',
        'Montana',
        'Multicolor Rojo',
        'Negro Cosmos',
        'Negro Eclipse',
        'Negro Estrella',
        'Negro Marquina',
        'Negro Sudafrica',
        'Negro Zimbawe',
        'New Gris Viola',
        'New Venetian Gold',
        'Onix Blanco ',
        'Orinoco',
        'Ornamental',
        'Ornametal Light',
        'Oro Brasil',
        'Oro Brazil',
        'Oyster',
        'Paradiso Bash',
        'Parga',
        'Peacock Green',
        'Perlado',
        'Persa',
        'Pink Sand Exoticas',
        'Pinta Verde',
        'Pizarra Gris',
        'Pizarra Verde',
        'Portofino',
        'Prahada Gold',
        'Preto Rio Preto',
        'Red Dragon',
        'Red Hollywood',
        'Rhodium',
        'Rojo Alicante',
        'Rojo Barón Exóticas',
        'Rojo Cehejin',
        'Rojo Hollywood Exóticas',
        'Romanix',
        'Rosa Porriño',
        'Rosa Portugues',
        'Ruby Fusion',
        'San Gabriel',
        'San Vicente',
        'Santa Cecilia light',
        'Santa Helena',
        'Serpeggiante',
        'Shangrila',
        'Shivakashi',
        'Sienna Bodeaux',
        'Soapstone',
        'Soapstone Barroca',
        'Soapstone Fantasy',
        'Soapstone Julia',
        'Soapstone Smoke',
        'Spring Breeze',
        'Star Galaxy',
        'Star White',
        'Steel Grey',
        'Sunflower',
        'Super White',
        'Taj White',
        'Tan Brown',
        'Taupe',
        'Travertino Abassi',
        'Travertino Nazarí',
        'Travertino Oro',
        'Travertino Rania',
        'Travertino Rojo',
        'Travertino Romano',
        'Tropical Fashion',
        'Typhoon Bordeaux',
        'Venato Carrara',
        'Verde Bamboo Exóticas',
        'Verde Esmeralda Exóticas',
        'Verde Guaco',
        'Verde Plata Rústicas',
        'Via Lattea',
        'Victoria Dream',
        'Viena White',
        'Viscount White',
        'Vogue',
        'White Bariloche',
        'White Ice',
        'White Macaubas',
        'White Splendor',
        'Wild Sea',
        'Yellow Rive'
    ],
    PREXURY: [
        'Amethyst',
        'Angel Jasper',
        'Classic Quartz',
        'Labradorite',
        'Petrified Wood',
        'Red Jasper',
        'Retro Petrified Wood',
        'Retro Tiger Iron',
        'Rose Quartz',
        'Smoky Quartz',
        'Wild Agate'
    ],
    DEKTON: [
        'Aldem',
        'Ananke',
        'Arga',
        'Aura 15',
        'Aura',
        'Baltic',
        'Bento',
        'Bergen',
        'Blanc Concrete',
        'Blaze',
        'Borea',
        'Bromo',
        'Danae',
        'Domoos',
        'Edora',
        'Entzo',
        'Feroe',
        'Fiord',
        'Fossil',
        'Gada',
        'Galema',
        'Glacier',
        'Halo',
        'Irok',
        'Kadum',
        'Kairos',
        'Kelya',
        'Keon',
        'Keranium',
        'Kira Grey',
        'Kira',
        'Korso',
        'Korus',
        'Kreta',
        'Laos',
        'Lumina',
        'Lunar',
        'Makai',
        'Manhattan',
        'Milar',
        'Natura',
        'Nayla',
        'Nilium',
        'Olimpo',
        'Opera',
        'Orix',
        'Portum',
        'Qatar',
        'Radium',
        'Sarey',
        'Sirius',
        'Sirocco',
        'Sogne',
        'Soke',
        'Spectra',
        'Splendor',
        'Sterling',
        'Strato',
        'Taga',
        'Trilium',
        'Tundra',
        'Valterra',
        'Vegha',
        'Ventus',
        'Vera',
        'Vienna',
        'Zenith'
    ],
    SILESTONE: [
        'Absolute Green',
        'Acqua Fraccaroli',
        'African Red',
        'Albedo',
        'Alpina White',
        'Altair',
        'Aluminio Nube',
        'Amarillo Arena',
        'Amarillo Dream',
        'Amarillo Gea',
        'Amarillo Monsul',
        'Amarillo Palmira',
        'Antique Gold',
        'Aquatint',
        'Arctic',
        'Arden Blue',
        'Ariel',
        'Atlantis',
        'Azul Enjoy',
        'Azul Ugarit',
        'Bamboo',
        'Beige Daphne',
        'Bering',
        'Bianco Calacatta',
        'Bianco Rivers',
        'Black Canyon',
        'Blanco Capri',
        'Blanco City',
        'Blanco Dune',
        'Blanco Maple',
        'Blanco Matrix',
        'Blanco Norte',
        'Blanco Orion',
        'Blanco Stellar',
        'Blanco Zeus Extreme',
        'Brazilian Brown',
        'Brooklyn',
        'Brooklyn',
        'Calypso',
        'Caramel Rhine',
        'Carbono',
        'Cemento Spa',
        'Charcoal Soapstone',
        'Chrome',
        'Classic White',
        'Copper Mist',
        'Coral Clay',
        'Cream Stone',
        'Crema Minerva',
        'Crema Stellar',
        'Crema Urban',
        'Cygnus',
        'Daria',
        'Desert Silver',
        'Diana Pearl',
        'Dinux',
        'Doradus',
        'Dreis',
        'E. Bianco Calacatta',
        'E. Calacatta Classic',
        'E. Calacatta Gold',
        'E. Desert Silver',
        'E. Emperador',
        'E. Emperador',
        'E. Marfil',
        'E. Marfil',
        'E. Marquina',
        'E. Noir',
        'E. Serena',
        'E. Statuario',
        'Ebony Pearl',
        'Eros Stellar',
        'Forest Snow',
        'Gedatsu',
        'Giallo Nova',
        'Giallo Quarry',
        'Grey Moss',
        'Gris Amazon',
        'Gris Expo',
        'Haiku',
        'Halley',
        'Helix',
        'Hudson',
        'Iconic Black',
        'Iconic White',
        'Iron Bark',
        'Iron Ore',
        'Istmo',
        'Ivory Coast',
        'Kensho',
        'Kimbler Mist',
        'Kimbler',
        'Koan',
        'Kona Beige',
        'Lagoon',
        'Luna',
        'Lusso',
        'Lyra',
        'Magenta Energy',
        'Mahogany',
        'Maple Orna',
        'Marengo',
        'Marina Stellar',
        'Marrón Júpiter',
        'Mediterranean',
        'Merope',
        'Miami White 17',
        'Montblanc',
        'Moonstone',
        'Mountain Mist',
        'Naranja Cool',
        'Negro Anubis',
        'Negro Stellar',
        'Negro Tao',
        'Negro Tebas',
        'Niebla',
        'Night Mist',
        'Nile',
        'Noka',
        'Nuit Bleue',
        'Nymbus',
        'Ocean Jasper',
        'Ocean Storm',
        'Olive Green',
        'Olivia',
        'Orange Fuego',
        'Pacific',
        'Pearl Jasmine',
        'Phoenix',
        'Pietra',
        'Polar Cap',
        'Pulsar',
        'Quasar',
        'Rain Forest',
        'Red Pine',
        'Riverbed',
        'Rojo Eros',
        'Rojo Vital',
        'Rosa Grey',
        'Rosso Monza',
        'Rougui',
        'Royal Reef',
        'Santa Fe Brown',
        'Satori',
        'Seleno',
        'Sienna Ridge',
        'Sierra Madre',
        'Silken Pearl',
        'Silver Lake',
        'Siridium',
        'Snowy Ibiza',
        'Sonora Gold',
        'Starlight',
        'Steel',
        'Stellar Grey',
        'Stellar Rose',
        'Stellar Sun',
        'Terra',
        'Tigris Sand',
        'Toffee',
        'Tritium',
        'Unsui',
        'Urban Frost',
        'Verde Fun',
        'Verde Ugarit',
        'Vortium',
        'White Arabesque',
        'White Diamond',
        'White Platinum',
        'White Storm',
        'Yukon',
        'Zirconium',
        'Zirix',
        'Zynite'
    ]
}
