import React from 'react'
import Translation from '../global/Translation'

import ReactTable from 'react-table'
import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import AssignCustomer from './AssignCustomer'
import confirm from '../global/confirm'
import Spinner from '../global/Spinner'
import OrderDetailsContainer from '../orders/OrderDetailsContainer'

const confirmReject = (reservationId, rejectReservation, intl) => {
    confirm(intl.formatMessage({ id: 'REJECT_RESERVATION_CONFIRM' }), {intl: intl}).then(
        confirm => {
            rejectReservation(reservationId)
        },
        cancel => {}
    )
}
class Reservations extends React.Component {
    constructor(props) {
        super(props)
        this.state = { selectedOrder: null, showAssignCustomer: false, showOrderDetails: false }
    }
    assignCustomer = (shippingAddressId, reservation, date, po, comments) => {
        this.props.assignCustomer(shippingAddressId, reservation, date, po, comments)
        this.setState({ selectedOrder: null, showAssignCustomer: false })
    }
    showAssignCustomer = selectedOrder => {
        this.setState({ selectedOrder: selectedOrder, showAssignCustomer: true })
    }

    closeAssignCustomer = () => {
        this.setState({ selectedOrder: null, showAssignCustomer: false })
    }
    componentDidMount() {
        this.props.fetchReservations(this.props.unassigned)
        this.props.fetchCustomers()
    }
    showOrderDetails = selectedOrder => {
        this.setState({ selectedOrder: selectedOrder, showOrderDetails: true })
    }
    closeOrderDetails = () => {
        this.setState({ selectedOrder: null, showOrderDetails: false })
    }
    render() {
        return (
            <section id="section-unassigned-orders">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>
                                {this.props.unassigned ? (
                                    <Translation id="unassigned_reservations" defaultMessage="Unassigned reservations" />
                                ) : (
                                    <Translation id="reservations" defaultMessage="Reservations" />
                                )}
                            </h2>
                            <ReactTable
                                data={this.props.reservations}
                                columns={[
                                    { Header: <Translation id="order_date" defaultMessage="Order date" />, accessor: 'createdAt' },
                                    { Header: <Translation id="reservation_id" defaultMessage="Reservation ID" />, accessor: 'reservationId' },
                                    {
                                        Header: <Translation id="order_id" defaultMessage="Order ID" />,
                                        Cell: ({ original }) => {
                                            return (
                                                <span onClick={() => this.showOrderDetails(original.reservationId)} style={{ color: 'blue' }}>
                                                    {original.orderId}
                                                </span>
                                            )
                                        }
                                    },
                                    { Header: <Translation id="customer_id" defaultMessage="Customer id" />, accessor: 'customerId' },
                                    { Header: <Translation id="status" defaultMessage="Status" />, accessor: 'status' },
                                    { Header: <Translation id="status_description" defaultMessage="Status description" />, accessor: 'statusDescription' },
                                    {
                                        Header: <Translation id="assign" defaultMessage="Assign" />,
                                        id: 'assign',
                                        show: this.props.unassigned === 'unassigned',
                                        minWidth: 200,
                                        Cell: ({ original }) => {
                                            return (
                                                <div>
                                                    <Button inline size="small" icon="hand-point-up" onClick={() => this.showAssignCustomer(original.reservationId)}>
                                                        <Translation id="assign" defaultMessage="Assign" />
                                                    </Button>{' '}
                                                    <Button
                                                        inline
                                                        size="small"
                                                        icon="ban"
                                                        onClick={event => confirmReject(original.reservationId, this.props.rejectReservation, this.props.intl)}
                                                    >
                                                        <Translation id="reject" defaultMessage="Reject" />
                                                    </Button>
                                                </div>
                                            )
                                        },
                                        className: 'text-center',
                                        headerClassName: 'text-center'
                                    }
                                ]}
                                defaultSorted={[{ id: 'reservationId', desc: true }]}
                                defaultPageSize={8}
                                pageSizeOptions={[8, 16, 32, 64, 128]}
                                previousText={<Translation id="previous" defaultMessage="Previous" />}
                                nextText={<Translation id="next" defaultMessage="Next" />}
                                loading={this.props.isLoading}
                                loadingText={<Spinner isLoading={this.props.isLoading} />}
                                noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                                pageText={<Translation id="page" defaultMessage="Page" />}
                                ofText={<Translation id="of" defaultMessage="Of" />}
                            />
                            {this.state.showAssignCustomer && this.props.customers && (
                                <AssignCustomer
                                    customers={this.props.customers}
                                    order={this.state.selectedOrder}
                                    onClose={() => this.closeAssignCustomer()}
                                    assignCustomer={(shippingAddressId, reservation, date, po, comments) => this.assignCustomer(shippingAddressId, reservation, date, po, comments)}
                                />
                            )}
                            {this.state.showOrderDetails && <OrderDetailsContainer order={this.state.selectedOrder} onClose={() => this.closeOrderDetails()} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default injectIntl(Reservations)
