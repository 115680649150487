import React from 'react'

import { connect } from 'react-redux'
import { fetchKpis } from '../../../../store/kpis/actions'
import { getSummary } from '../../../../store/kpis/reducers'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import iconCart from '../../../../assets/img/icons/cart-thin.svg'
import iconCaution from '../../../../assets/img/icons/caution.svg'
import KpiTabButton from './KpiTabButton'
import moment from 'moment'

const mapStateToProps = (state, { match }) => {
    return {
        filter: match.params.filter,
        summary: getSummary(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchKpis: (startDate, endDate, customers, centers, topColors) => {
            dispatch(fetchKpis(startDate, endDate, customers, centers, topColors))
        }
    }
}
class KpiHeader extends React.Component {
    componentDidMount() {
        this.props.fetchKpis(moment().startOf('year'), moment(), null, null, null)
    }
    render() {
        const { summary, filter } = this.props
        return (
            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <div className="row">
                        <div className="col-12" />
                    </div>
                    <div className="row">
                        <div className="container" />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="kpis-resume">
                                <div className="row">
                                    <div className="col">
                                        <KpiTabButton
                                            link={this.props.intl.formatMessage({ id: 'ROUTE_KPI_DETAIL' }).replace(':filter', 'open-orders')}
                                            icon={iconCart}
                                            active={filter === 'open-orders'}
                                            number={summary ? summary.openOrders.count : null}
                                        />
                                    </div>
                                    <div className="col">
                                        <KpiTabButton
                                            link={this.props.intl.formatMessage({ id: 'ROUTE_KPI_DETAIL' }).replace(':filter', 'back-orders')}
                                            icon={iconCaution}
                                            active={filter === 'back-orders'}
                                            number={summary ? summary.backOrders.count : null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(KpiHeader))
)
