import React from 'react'
import { ReactComponent as IconCosentinoGlobalExtended } from '../../../assets/img/icons/cosentino-global-icon-c.svg'
import Translation from '../../global/Translation'

const CosentinoGlobalIndicatorExtended = () => {
    return (
        <div className="cosentino-global-indicator-extended">
            <div className="cosentino-global-icon">
                <IconCosentinoGlobalExtended />
            </div>
            <div className="cosentino-global-text">
                <div><Translation id="cosentino_global" defaultMessage="Cosentino Global" /></div>
            </div>
        </div>
    )
}

export default CosentinoGlobalIndicatorExtended
