import React from 'react'

import { injectIntl } from 'react-intl'

import Translation from '../../../global/Translation'

class DisplayDiscontinued extends React.Component {
    render() {
        if (this.props.discontinued)
            return (
                <div className="tooltip">
                    <div className="discontinued-indicator">
                        <Translation id="discontinued" defaultMessage="Discontinued" />
                        {' - '}
                        {this.props.intl.formatMessage({ id: this.props.discontinued + '_SHORT' })}
                    </div>
                    <p className="tooltiptext tooltip-discontinued">{this.props.intl.formatMessage({ id: this.props.discontinued + '_LONG' })}</p>
                </div>
            )
        else return null
    }
}
export default injectIntl(DisplayDiscontinued)
