import React from 'react'
import Translation from '../global/Translation'

import { connect } from 'react-redux'

import { getCurrentSegmentation, getSelectedBusinesSegment, getBusinesSegmentOptions } from '../../store/segmentation/reducers'
import { toggleSegmentation, selectBusinessSegment } from '../../store/segmentation/actions'
const mapStateToProps = state => {
    return {
        show: getCurrentSegmentation(state) === 'BUSINESS_SEGMENT',
        selected: getSelectedBusinesSegment(state),
        options: getBusinesSegmentOptions(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(toggleSegmentation('BUSINESS_SEGMENT'))
        },
        onSelect: (value, name) => {
            dispatch(selectBusinessSegment(value, name))
        }
    }
}
const BusinessSegment = ({ show, selected, onToggle, onSelect, options }) => {
    if (options === undefined || (Object.keys(options).length === 1 && selected === 'NOT')) {
        return ''
    }
    if (Object.keys(options).length === 1 && selected === null) {
        Object.entries(options).map(option => onSelect(option[0], option[1].name))
    }
    const selectedValue = selected ? <Translation id={selected} /> : null
    return (
        <div>
            <div onClick={() => onToggle()} className="option">
                <Translation id="business_segment" defaultMessage="Business segment" /> : {selectedValue}
                <i className={show ? 'fa fa-caret-down' : 'fa fa-caret-right'} aria-hidden="true" />
                <br />
            </div>

            <div style={{ display: show ? 'block' : 'none' }}>
                <div className="row segment-selections">
                    {Object.entries(options).map(option => (
                        <span key={option[0]} onClick={() => onSelect(option[0], option[1].name)}>
                            <Translation id={option[0]} />
                        </span>
                    ))}
                </div>
            </div>
            <hr />
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessSegment)
