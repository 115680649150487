import { connect } from 'react-redux'
import { showCheckoutModalSelector, getReservationCode, getReservationCutToSizeCode } from '../../store/cart/reducers'
import * as ui from '../../store/ui/actions'
import OrderFinished from './OrderFinished'
import { getIsIkeaCenter, getIsLowesCenter } from '../../store/centers/reducers'
import { getHaveOffer } from '../../store/checkout/reducers'
import {injectIntl} from "react-intl";

const mapStateToProps = state => {
    return {
        show: showCheckoutModalSelector(state),
        reservationCode: getReservationCode(state),
        reservationCutToSizeCode: getReservationCutToSizeCode(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isLowesCenter: getIsLowesCenter(state),
        haveOffer: getHaveOffer(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeCheckoutModal())
        },
        rememberCenter: isIkeaCenter => {
            if (isIkeaCenter) dispatch({ type: 'REMEMBER_CENTER' })
        }
    }
}

const OrderFinishedContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderFinished)

export default injectIntl(OrderFinishedContainer)
