import React from 'react'
import Joyride, { STATUS } from 'react-joyride'
import * as CustomStyles from '../styles/CustomStyles'
import { connect } from 'react-redux'
import { submitWalkthrough } from '../../../store/ui/actions'
import { getHasWalkthrough } from '../../../store/login/reducers'
import { fetchHasWalkthrough } from '../../../store/login/actions'
import Translation from '../../global/Translation'
import { ReactComponent as IconTruck } from '../../../assets/img/icons/icon-catalog-shipping-truck.svg'
import { ReactComponent as IconWarning } from '../../../assets/img/icons/icon-catalog-warning.svg'
import { getWalkthroughWatched } from '../../../store/ui/reducers'

const mapStateToProps = (state) => {
    return {
        hasWalkthrough: getHasWalkthrough(state, 'OUTLET_PN_FROM_OTHER_CENTERS'),
        isWalkthroughWatched: getWalkthroughWatched(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitWalkthrough: (type, noShowMore) => dispatch(submitWalkthrough(type, noShowMore)),
        refreshData: () => dispatch(fetchHasWalkthrough()),
        onWatchedWalkthrough: () => dispatch({ type: 'WALTHROUGH_HAS_BEEN_WATCHED' })
    }
}

class OutletPnFromOtherCentersWalkthrough extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: 'OUTLET_PN_FROM_OTHER_CENTERS'
        }
    }

    /**
     *  Body content for step one
     */
    stepOneContent = () => {
        return (
            <div className="walkthrough__tooltip__content">
                <div className="walkthrough__tooltip__content--heading">
                    <h2><Translation id="walkthrough_buy_directly_from_cosentino_global_by_clicking_on_this_button" defaultMessage="Buy directly from Cosentino Global by clicking on this button." /></h2>
                </div>
                <div className="walkthrough__tooltip__content--info">
                    <span>
                        <div className="walkthrough__tooltip__content__pn-outlet">
                            <div className="beggining"><span><Translation id="walkthrough_keep_in_mind_that" defaultMessage="Keep in mind that" />:</span></div>
                            <div className="walkthrough__tooltip__content__pn-outlet--body">
                                <div className="first-block">
                                    <div><IconTruck /></div>
                                    <div><span><Translation id="walkthrough_these_products_are_in_our_cosentino_global_delivery_may_be_longer" defaultMessage="These products are in our Cosentino Global Center. Delivery times may be longer than the delivery times of your usual Center." /></span></div>
                                </div>
                                <div className="second-block">
                                    <div><IconWarning /></div>
                                    <div><span><Translation id="walkthrough_limitations_on_natural_stone_products" defaultMessage="Limitations on Natural Stone products" />:</span>
                                        <ul>
                                            <li><Translation id="the_total_width_of_all_the_natural_stone_slabs_you_buy_from_HQ" defaultMessage="The TOTAL thickness of all the natural stone slabs you buy from HQ must be at least: 12 cm. Example: 4 slabs of 3 cm = 12 cm."/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        )
    }

    /**
     * steps
     */
    steps = {
        steps: [
            {
                content: this.stepOneContent(),
                locale: {
                    close: <span aria-label="close" onClick={() => this.handleNoShowMoreOnClick()} style={{ fontSize: '14px' }}><Translation id="do_not_show_again" defaultMessage="Do not show again" /></span>
                },
                placement: 'auto',
                disableBeacon: true,
                target: '#pn_outlet_hq_button'
            }
        ]
    }

    /**
     * Handle on click to submit the walkthrough data
     */
    handleNoShowMoreOnClick = () => {
        return this.props.onSubmitWalkthrough(this.state.type, true).then(() => this.props.refreshData())
    }

    /**
     * Callback function to manipulate the walkthrough logic
     */
    handleJoyrideCallback = data => {
        const { status } = data

        // If the walkthrough is finished (watched) let's update the global state by dispaching an action
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            this.props.onWatchedWalkthrough()
        }
    }

    render() {
        const { steps } = this.steps
        const { hasWalkthrough, isWalkthroughWatched } = this.props

        return hasWalkthrough && hasWalkthrough.shouldShow && !isWalkthroughWatched ? (
            <React.Fragment>
                <Joyride
                    callback={this.handleJoyrideCallback}
                    steps={steps}
                    styles={{
                        tooltip: CustomStyles.TooltipStyles,
                        tooltipContent: CustomStyles.TooltipContentStyles,
                        options: CustomStyles.OptionStyles,
                        buttonBack: CustomStyles.ButtonBackStyles,
                        tooltipFooter: CustomStyles.TooltipFooterStyles,
                        spotlight: CustomStyles.SpotlightSyles
                    }}
                    disableScrolling
                />
            </React.Fragment>
        ) : null
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutletPnFromOtherCentersWalkthrough)