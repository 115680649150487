import React from 'react'
import Slider from 'react-slick'
import { v4 as uuidv4 } from 'uuid'
import RectangleResultContainer from '../catalog/rectangleResult/RectangleResultContainer'
import {connect} from "react-redux";
import {
    getIsLogged
} from "../../store/login/reducers";
import { withRouter } from 'react-router'

function SampleNextArrow(props) {
    const { style, onClick } = props
    return (
        <i
            className={'fal fa-chevron-right'}
            style={{ ...style, display: 'block', position: 'absolute', right: '-24px', top: '40%', fontSize: '30px', cursor: 'pointer', zIndex: '10' }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props) {
    const { style, onClick } = props
    return (
        <i
            className={'fal fa-chevron-left'}
            style={{ ...style, display: 'block', position: 'absolute', left: '-24px', top: '40%', fontSize: '30px', cursor: 'pointer', zIndex: '10' }}
            onClick={onClick}
        />
    )
}


class ProductsSlider extends React.Component {
    state = {slidesToShow: 1, slideIndex: 0, hasObserver: false, shouldSendMetrics: true}
    id = uuidv4()

    componentDidMount() {
        if(this.props.isLogged){
            this.props.fetch()
        }
        if (!this.props.hide && this.props.products) {
            this.resize()
        }
        window.addEventListener("resize", this.resize.bind(this));
    }

    resize() {
        if (this.props.products) {
            this.setState({slidesToShow: this.getSlidesToShow()});
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentCenter && this.props.currentCenter != prevProps.currentCenter) {
            this.props.fetch()
            return
        }

        if (!this.props.hide && this.props.products && this.props.products[0]) {
            const currentFirstProductId = this.props.products[0].product.productId
            const prevFirstProductId = prevProps.products && prevProps.products[0] && prevProps.products[0].product && prevProps.products[0].product.productId

            if (currentFirstProductId !== prevFirstProductId) {
                this.slider.slickGoTo(0)
            }

            const prevProductsLength = prevProps.products ? prevProps.products.length : 0
            if (this.props.products.length !== prevProductsLength) {
                this.resize()
            }
        }

        // Update the state shouldSendMetrics everytime pathname is different
        if (this.props.location.key !== prevProps.location.key) {
            this.setState({ shouldSendMetrics: true })
        }

        !this.state.hasObserver && this.createObserver()
    }

    getSlidesToShow = () => {
        const maxSlidesToShow = 15
        const minGap = 12
        const minWidth = 235
        const minCardWidth = this.props.minWidth ? this.props.minWidth : minWidth
        const el = document.getElementById(this.id)
        if (!el) return 1
        let maxSlides = Math.floor(el.offsetWidth / minCardWidth) //250 Card width
        const gap = (el.offsetWidth - minCardWidth * maxSlides) / (1 + maxSlides)
        if (gap < minGap) --maxSlides
        const slidesCount = this.props.products.length > maxSlides ? maxSlides : this.props.products.length
        // console.log('SLIDER : ' , this.props.title.props.defaultMessage)
        // console.log('SLIDERs COUNT : ', slidesCount)
        // console.log('SLIDERs width : ', el.offsetWidth)
        // console.log('SLIDERs card width : ', minCardWidth)
        // console.log('SLIDERs gap : ', gap)
        return Math.min(maxSlidesToShow, slidesCount)
    }

    /**
     * Creates a new observer instance and handles the callback funcion
     */
    createObserver = () => {
        let options = {
            root: null, // Means browser viewport reference
            rootMargin: "0px", // Means 100% of the viewport
            threshold: 1 // Means 100% of the element
        }

        const el = document.getElementById(this.id)
        const observer = new IntersectionObserver(this.handleIntersect, options)

        if (el) {
            this.setState({ hasObserver: true })
            observer.observe(el)
        }
    }

    /**
     * Callback function for executing events when observe isIntersecting is true
     * @param {object} entries 
     */
    handleIntersect = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && this.state.shouldSendMetrics) {
                if (this.props.analyticsTag) {
                    //console.log('SLIDER SEND METRICS ' , this.props.products);
                    this.props.sendMetrics(this.props.products, this.props.analyticsTag)
                    this.setState({ shouldSendMetrics: false })
                }
            }
        })
    }

    render() {
        if (this.props.hide) return <React.Fragment></React.Fragment>
        const settings = {
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            dots: true,
            speed: 300,
            slidesToShow: this.state.slidesToShow,
            slidesToScroll: 1,
            infinite: false,
            variableWidth: false
        }

        if (!this.props.products) {
            return null
        }

        const productsObject = {}

        this.props.products.forEach((product, index) => {
            try {
            } catch (e) {
                console.log('PROCUT', product)
            }
            product.product.index = index
            product.product.showOutlet = !!product.outlet
            product.product.quantity = product.quantity
            product.product.crossType = !!product.fromRecommendation ? 'dinamico' : 'estatico'
            productsObject[index] = product.product
        });

        const productsArray = Object.values(productsObject).sort((a, b) => a.index - b.index)
        const slideClass = this.props.customClass ? this.props.customClass : "results-list"

        return (
            this.props.products.length > 0 && (
                <section id="section-products-slider">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="container" gtm-label={this.props.gtmLabel}>                            
                                <div className={`general-results-list ${slideClass}`}>
                                    {this.props.title &&
                                        <h2>
                                            {this.props.title}
                                        </h2>
                                    }
                                    <div id={this.id}>
                                        <Slider ref={s => (this.slider = s)} {...settings}>
                                            {productsArray.map((product, index) => product && (
                                                    <div className="slider-cards" key={`pindex-${index}`}>
                                                        <RectangleResultContainer
                                                            outlet={product.showOutlet}
                                                            product={product}
                                                            key={product.productId}
                                                            index={index}
                                                            list={this.props.analyticsTag}
                                                            sectionFlag={this.props.sectionFlag}
                                                            crossType={product.crossType}

                                                        />
                                                    </div>
                                                )
                                            )}
                                        </Slider>
                                    </div>
                                </div>                                                               
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            )
        )

    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendMetrics: (products, tag) => dispatch({type: 'SHOW_CROSS_SELLING_SLIDER', products, tag})
    }
}

const mapStateToProps = state => {
    return {
        isLogged: getIsLogged(state),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSlider))