import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import { connect } from 'react-redux'

class CookiePolicy extends React.Component {
    componentDidMount() {
        // const script = document.createElement('script')
        // script.src = 'https://consent.cookiebot.com/f7a14091-0ea6-4e3b-bb15-248d40629e42/cd.js'
        // script.setAttribute('data-culture', this.props.intl.locale === 'es' ? 'ES' : 'EN')
        // script.async = true
        // document.getElementById('CookieDeclarationTable').appendChild(script)
    }
    render() {
        const englishContent = (
            <div class="entry-content">
                <h2>
                    <FormattedMessage id="cookies_policy" defaultMessage="Cookies policy" />
                </h2>
                <p>
                    This website uses its own cookies and third-party cookies to measure and analyse our users’ web browsing. Cookies are files that install into the system that is
                    accessing our site and serve the purposes outlined on this page. In addition to the cookies strictly required for web browsing, such as session cookies, this
                    website uses the following cookies and tracking files:
                </p>
                <ul>
                    <li>
                        Google Analytics provided by Google, Inc., with head office in 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, USA. Google stores cookies
                        to create statistics on the website’s visits traffic and volume in order to improve visits and the website’s positioning. The data obtained will be handled
                        by Google in conformity with the Privacy Policy available at https://www.google.es. By using this website you agree to have your personal data processed by
                        Google.
                    </li>
                    <li>
                        Social media https://www.facebook.com/, https://twitter.com, http://www.youtube.com/, http://www.pinterest.com or http://www.linkedin.com : Each one of
                        these websites uses its own cookies to allow you to use buttons such as Like and Share.
                    </li>
                    <li>
                        Pardot sets exclusive cookies for tracking purposes and sets third-party cookies for redundancy. The use of exclusive cookies and Third parties is a norm in
                        the marketing automation sector. Pardot cookies do not store personally identifiable information, only one Unique identifier. Pardot sets exclusive cookies
                        on its tracking domains and Pardot domains. Pardot uses third-party cookies on pages https and when your account does not have a tracking subdomain
                        configuration.
                    </li>
                    <li>Logrocket cookies are used to collect statistics on the use of the Platform and identify users and their requests.</li>
                </ul>
                <p>
                    Data obtained will be processed by Google and other indicated entities in conformity with their respective Privacy Policy found at the above-mentioned links.
                    Therefore, the exercise of any corresponding right must be communicated directly to the heads of each respective website.
                </p>
                <p>
                    Cookies will not be installed into your computer until you accept their use in the Pop Up tab which will appear upon your first visit to the Website or after 30
                    seconds. By accepting, the USER agrees to the use of cookies and tracking files with IP addresses that allow our company to gather statistical data concerning:
                    the number of pages visited; language; social media platform where our content is shared; the city and region from which the IP is accessing our site; the
                    number of new users; frequency and recurrence of visits; the session time; and lastly, the browser and system terminal where our website is accessed from.{' '}
                </p>
                <p>
                    The USER can stop cookies from installing onto their system or erase installed cookies via their browser settings and through the use of specific applications.
                    However, THE COMPANY is not held responsible if stopping such cookies inhibits the proper functioning of the website. The data obtained is completely anonymous
                    and under no circumstance can it be linked back or identified to a specific user.
                </p>
                <p>
                    In the event that the USER wishes to delete cookies after they have been stored in his or her computer, requiring their consent, they can do so via their
                    browser tools available for this purpose. You can access additional information on this matter by using the following links:
                </p>
                <p>
                    Firefox: <a href="https://support.mozilla.org/en-US/">https://support.mozilla.org/en-US/</a>
                    <br />
                    Internet Explorer: <a href="https://support.microsoft.com/en-gb">https://support.microsoft.com/en-gb</a>
                    <br />
                    Safari: <a href="https://support.apple.com/en-gb/HT201265">https://support.apple.com/en-gb/HT201265</a>
                    <br />
                    Opera:{' '}
                    <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData">https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData</a>
                </p>
                <p>Please note that some elements of this website could cease to work properly if the USER does not accept cookies or later disagrees to their use.</p>
            </div>
        )

        const spanishContent = (
            <div class="entry-content">
                <h2>
                    <FormattedMessage id="cookies_policy" defaultMessage="Cookies policy" />
                </h2>
                <p>
                    Esta página web utiliza cookies propias y de terceros para medir y analizar la navegación de nuestros Usuarios. Las cookies son unos archivos que se instalan en
                    el equipo desde el que accede a nuestra web con las finalidades que se describen en esta página. Además de aquellas cookies estrictamente necesarias para la
                    navegación web, como por ejemplo cookies de sesión, este sitio web utiliza las siguientes cookies y archivos de seguimiento.
                </p>

                <ul>
                    <li>
                        Google Analytics servicio prestado por Google, Inc., cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados
                        Unidos. Google almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web con el fin de mejorar las visitas y el
                        posicionamiento de la web. Los datos obtenidos serán tratados por Google conforme la Política de privacidad accesible en https://www.google.es. Por lo que
                        al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted por Google.
                    </li>
                    <li>
                        Redes sociales https://www.facebook.com/, https://twitter.com, http://www.youtube.com/, http://www.pinterest.com o http: //www.linkedin. com: cada uno de
                        estos sitios web usan sus propias cookies para permitirle usar botones como Me gusta y Compartir.
                    </li>
                    <li>
                        Pardot establece cookies exclusivas para fines de seguimiento y configura cookies de terceros para la redundancia. El uso de cookies exclusivas y de
                        terceros es una norma en el sector de la automatización de marketing. Las cookies de Pardot no almacenan información personal de identificación, solo un
                        identificador único. Pardot establece cookies exclusivas en sus dominios rastreadores y dominios de Pardot. Pardot utiliza cookies de terceros en páginas
                        https y cuando su cuenta no dispone de una configuración de subdominio rastreador.
                    </li>
                    <li>Las cookies de Logrocket se utilizan para recopilar estadísticas sobre el uso de la Plataforma e identificar a los usuarios y sus solicitudes.</li>
                </ul>
                <p>
                    Los datos obtenidos serán tratados por Google y otras entidades indicadas conforme se indica en sus respectivas Políticas de Privacidad que se pueden conocer
                    desde los enlaces indicados. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente a los responsables de estos
                    sitios web.
                </p>
                <p>
                    Las cookies no serán instaladas en su ordenador hasta que acepte su uso en el Aviso (Pop Up) que se mostrará en el primer acceso del USUARIO a la Web o pasados
                    30 segundos. Al aceptar, el USUARIO consiente el uso de cookies y archivos de seguimiento de direcciones IP cuyo uso permite a nuestra empresa a recoger datos a
                    efectos estadísticos como: el número de páginas visitadas, el idioma, red social en la que se publican nuestras noticias, la ciudad o región a la que está
                    asignada la dirección IP desde la que accedes, el número de nuevos Usuarios, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador o
                    terminal desde el que se realiza la visita.{' '}
                </p>
                <p>
                    En todo caso, el USUARIO podrá impedir la instalación de cookies o borrar las que ya se hubiesen instalado, a través de la configuración de su navegador, así
                    como a través del uso de aplicaciones específicas. Sin embargo, COSENTINO no se responsabiliza de que la desactivación de los mismos impida el buen
                    funcionamiento de la página. La información obtenida es totalmente anónima, y en ningún caso puede ser asociada a un Usuario concreto e identificado.{' '}
                </p>
                <p>
                    En caso de que el USUARIO desee con posterioridad eliminar las cookies que se hayan almacenado en su ordenador y que necesariamente precisen de su
                    consentimiento, podrá hacerlo usando las herramientas que le proporcione su navegador a dichos efectos. En éste sentido, podrá encontrar información adicional a
                    través de los siguientes enlaces:{' '}
                </p>
                <ul className="p-0" style={{ listStyle: 'none' }}>
                    <li>
                        Firefox:{' '}
                        <a href="http://support.mozilla.org/es/home" target="_blanc">
                            http://support.mozilla.org/es/home
                        </a>
                    </li>
                    <li>
                        Internet Explorer:{' '}
                        <a href="http://support.microsoft.com/ph/807/es-es" target="_blanc">
                            http://support.microsoft.com/ph/807/es-es
                        </a>
                    </li>
                    <li>
                        Safari:{' '}
                        <a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES" target="_blanc">
                            http://support.apple.com/kb/HT1677?viewlocale=es_ES
                        </a>
                    </li>
                    <li>
                        Opera:{' '}
                        <a href="http://help.opera.com/Windows/8.54/es-ES/index.html" target="_blanc">
                            http://help.opera.com/Windows/8.54/es-ES/index.html
                        </a>
                    </li>
                </ul>
                <p>Se recuerda que, si el USUARIO no acepta las cookies o las rechaza posteriormente, las funcionalidades del sitio web podrán verse mermadas. </p>
            </div>
        )

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1 my-4">
                        <div id="CookieDeclarationWrapper">{this.props.intl.locale === 'es' ? spanishContent : englishContent}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        null,
        null
    )(CookiePolicy)
)
