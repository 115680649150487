import React from "react";
import Button from "../../global/Button";
import Translation from "../../global/Translation";
import {denyCampaignDiscountStatus} from "../../../store/promotions/actions";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

const MIN_CHAR_ALLOWED = 10;
class DenyDiscount extends React.Component {

    state={rejectReason:''}

    sendDenyDiscounts = () => {
        this.props.denyCampaignDiscountStatus(this.props.campaign.id, this.state.rejectReason)
            .then(r => r ? this.props.showResponseModal() : this.props.showAlert())
    }

    render() {
        return (
            <div className="row px-4 pb-2 h-100">
                <div className="col-12 h-100 d-flex flex-column justify-content-between" style={{}}>
                    <div className="mt-4 p-4 h-100" style={{fontSize:'14px'}}>
                        <Translation id="rejection_reason" defaultMessage="Enter the rejection reason for discounts for this promotion."/>
                        <textarea
                            value={this.state.rejectReason}
                            rows="4"
                            maxLength="512"
                            style={{marginTop:'8px', width:'100%', border:'3px solid grey'}}
                            onChange={e => this.setState({rejectReason: e.target.value})}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button className="approve_deny_button"
                                onClick={this.sendDenyDiscounts}
                                disabled={this.state.rejectReason.length < MIN_CHAR_ALLOWED}
                        >
                            <Translation id="deny_discounts" defaultMessage="Deny discounts"/>
                        </Button>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
        denyCampaignDiscountStatus: (promotionId, rejectReason) => dispatch(denyCampaignDiscountStatus(promotionId, rejectReason))
    }
}


export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DenyDiscount)
)
