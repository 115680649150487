import { connect } from 'react-redux'
import Weights from "./Weights";
import {
    getCoin,
    getIsMkToolsCenter, getMktoolsBudget,
    getMktoolsBudgetWasted,
    getSelectedCenter,
    getShippingMethodWithoutWeights, isLatinaVitoria, getShippingMethod
} from '../../store/centers/reducers'
import { getBudgetPlusTotal, getFullUnits } from '../../store/cart/reducers'
import {getProfileConfiguration} from "../../store/profile-configuration/reducers";
import {getLanguage, getLanguageDir} from "../../store/ui/reducers";
import getNumberFormat from "../getNumberFormat";
import { injectIntl } from 'react-intl'

const mapStateToProps = state => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        unit,
        isMktoolCenter: getIsMkToolsCenter(state),
        shippingMethod: getShippingMethodWithoutWeights(state),
        languageDir: getLanguageDir(state),
        numberFormat: getNumberFormat(language),
        fullUnits: getFullUnits(state),
        selectedCenter: getSelectedCenter(state),
        spentBudget: getMktoolsBudgetWasted(state),
        newSpentBudgetWithCartContents: getBudgetPlusTotal(state),
        maxBudget: getMktoolsBudget(state),
        coin: getCoin(state),
        isLatinaVitoria: isLatinaVitoria(state)
    }
}

const WeightsContainer = injectIntl(connect(mapStateToProps, null)(Weights))

export default WeightsContainer
