import React from 'react'
import Translation from '../global/Translation'

import {injectIntl} from 'react-intl'

import Button from '../global/Button'
import { connect } from 'react-redux'
import warning from '../../assets/img/warning.jpg'
import { getAdvertisements, showAdvertisements } from '../../store/advertisement/reducers'
import { fetchAdvertisements } from '../../store/advertisement/actions'


const mapStateToProps = state => {
    return {               
        advertisements: getAdvertisements(state),
        showAdvertisements: showAdvertisements(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAdvertisements: () => dispatch(fetchAdvertisements()),
        hideAdvertisements: () => dispatch({type: 'HIDE_ADVERTISEMENTS'})
    }
}

class AdvertisementModal extends React.Component {
    
    render() {
        const { showAdvertisements } = this.props

        if (!showAdvertisements) return null
        return (
            <div className="advertisement">
                <div className="full-overlay" style={{ display: showAdvertisements ? 'block' : 'none' }} />
                <div id="modal-advertisement" className="modal-advertisement-container" style={{ display: showAdvertisements ? 'block' : 'none' }}>
                    <div className="">
                        <div className="row no-gutters">
                            <div className="col-12 ">
                                <div className="logo-container" style={{ backgroundImage: `url(${warning})` }} />
                            </div>
                            <div className="col-12 card-container">

                                <div className="form-signin">
                                    <div className="col-12">
                                        <div className="form-group employee-id" style={{ textAlign: 'center' }}>
                                            <h3 className="my-4">
                                                <div dangerouslySetInnerHTML={{__html: this.props.advertisements.message}}></div>
                                            </h3>

                                            <Button
                                                onClick={this.props.hideAdvertisements}
                                                inverted
                                                className="bt bt-inverted loginbutton form-control"
                                            >
                                                <Translation id="keep_buying" defaultMessage="Keep buying" />
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AdvertisementModal)
)
