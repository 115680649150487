// Note: This is not used maybe?

import React from 'react'

import { connect } from 'react-redux'
import { getUserType, getImpersonatedBy } from '../store/login/reducers'
import { getIsLowesCenter, getIsMkToolsCenter, getIsIkeaCenter } from '../store/centers/reducers'
import CartSummaryContainer from '../components/checkout/CartSummaryContainer';
import Translation from '../components/global/Translation'
import LocalizedLink from '../components/global/LocalizedLink'
import Button from '../components/global/Button'
import IncentivesContainer from '../components/incentives/IncentivesContainer';
const mapStateToProps = state => {
    return {
        userType: getUserType(state),
        impersonatedBy: getImpersonatedBy(state),
        isLowesCenter: getIsLowesCenter(state),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isIkeaCenter: getIsIkeaCenter(state)
    }
}

function canViewIncentives (props) {
        if (props.isIkeaCenter) return false
        else if (props.isMktoolsCenter && props.userType === 'CUSTOMER') return false
        else return true
}
const CartSummaryScreen = props => (
    <div>
        <div className="container-heading">
            <div className="container">
                <div className="row checkout-heading-row">
                    <div className="col-12">
                        <h2 className="heading checkout-heading">
                            <Translation id="checkout" defaultMessage="Checkout" />
                        </h2>
                    </div>
                </div>
            </div>
        </div>

        <div className="container checkout">
            <div className="row">
                <div className="col-12">
                    {canViewIncentives(props) && <IncentivesContainer />}

                    <CartSummaryContainer {...props} />
                </div>
            </div>
        </div>
        <footer className="container -fluid" style={{paddingTop:'20px'}}>
            <div className="row">
                <div className="col-12 col-md-3">
                    <LocalizedLink routeId="ROUTE_CHECKOUT">
                        <Button inverted datacy="modal_minicarrito__go_checkout">
                            <Translation id="checkout" defaultMessage="Checkout" />
                        </Button>
                    </LocalizedLink>
                </div>
            </div>
        </footer>
    </div>
)
    

export default connect(
    mapStateToProps,
    null
)(CartSummaryScreen)
