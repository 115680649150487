import { submitWhatsappOptinModal, noShowMoreWhatsappModal } from './../../store/ui/actions'
import { submitWhatsappOptin } from './../../store/api'
import { showingWhatsappModal } from './../../store/ui/reducers'
import { getSapId, getUserName, getEmailUsedToLogin, getName, getSalesOrg } from './../../store/login/reducers'
import React from 'react'
import Translation from '../global/Translation'
import axios from 'axios'
import { injectIntl } from 'react-intl'
import whatsappEcosentinoLogo from '../../assets/img/logos/brands/whatsapp-ecosentino-logo.svg'
import { showingPhonePromotionsModal } from '../../store/ui/reducers'
import {
    getPromotionConfigurationPhones,
    getPromotionConfigurationAcceptPromotionNotifications,
    getPromotionConfigurationAcceptPromotionPolicies,
    getPromotionConfigurationPreviousPreferences
} from '../../store/profile-configuration/reducers'
import * as ui from '../../store/ui/actions'
import { connect } from 'react-redux'
import Button from '../global/Button'
import LocalizedLink from '../global/LocalizedLink'
import Alerts from '../alerts/Alerts'
import { isMobile, isBrowser } from 'react-device-detect'
const mapStateToProps = state => {
    return {
        show: showingWhatsappModal(state),
        // show: true,
        userName: getUserName(state),
        name: getName(state),
        email: getEmailUsedToLogin(state),
        userSalesOrg: getSalesOrg(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(ui.closeWhatsappModal())
        },
        submitWhatsappOptinModal: (phone, email, acceptPolicies, onClose) => {
            return dispatch(ui.submitWhatsappOptinModal(phone, email, acceptPolicies, onClose))
        },
        dontShowMore: () => {
            dispatch(noShowMoreWhatsappModal())
            dispatch(ui.closeWhatsappModal())
        }
    }
}

class WhatsappOptin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    desktopSubmit = () => {
        const endpointsBySalesOrg = {
            '2000': 'https://messages.landbot.io/wa/W-2341-X538NFLM6LRXV5M8/opt_in',
            '2001': 'https://messages.landbot.io/wa/W-1211-MALKZOSEK5MFNYX2/opt_in',
            '2014': 'https://messages.landbot.io/wa/W-1211-MALKZOSEK5MFNYX2/opt_in'
        }

        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
        axios.defaults.headers.common['Content-Type'] = 'application/json'

        let token = 'dHJ1c3RlZF9jbGllbnRlX3BpOnNlY3JldA'
        const phone = this.state.phone.replace('+', '')
        this.props.submitWhatsappOptinModal(phone, this.props.email, true, this.props.onClose)
        axios
            .post(endpointsBySalesOrg[this.props.userSalesOrg] + '?phone=' + phone + '&name=' + this.props.name + '&email=' + this.props.email + '&sapId=' + this.props.userName, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(
                response => {
                    if (response.data.error) {
                        this.setState({ error: response.data.error.code })
                    } else {
                        console.log('sent messages.landbot > todo correcto')
                    }
                },
                error => {
                    // this.setState({ error: error.error.code })
                }
            )
    }

    /**
     * Show a different placeholder message depending on user sales org
     * 2000: holland, 2001: germany, 2014: austria
     * @type {object}
     */
    placeholderMap = {
        '2000': '+31xxxxxxxxx',
        '2001': '+49xxxxxxxxx',
        '2014': '+43xxxxxxxxx'
    }

    render() {
        const { show, onClose, userSalesOrg } = this.props
        return show ? (
            <div>
                <div className="full-overlay" style={{ display: show ? 'block' : 'none' }} />
                <div
                    id="modal-login-container"
                    className="modal-login whatsapp-optin "
                    style={{ display: show ? 'block' : 'none', top: '15%', bottom: 'auto', maxWidth: '680px' }}
                    gtm-label="ec_whatsapp_optin_modal"
                >
                    <div className="">
                        <div className="row no-gutters">
                            <div className="col-12 logo-container">
                                <header className="container-fluid" style={{ position: 'absolute', zIndex: '5', color: 'white' }}>
                                    <div className="row">
                                        <div className="col-3 offset-9 my-auto text-right">
                                            <div className="bt-close" onClick={onClose} style={{ color: 'black' }}>
                                                <i className="fal fa-times" style={{ fontSize: '24px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <img alt="" id="profile-img" className="profile-img-card" src={whatsappEcosentinoLogo} />
                            </div>
                            <div className="col-12 card-container" style={{ background: 'white' }}>
                                <div className="">
                                    <div className="col-12">
                                        <header className="container-fluid">
                                            <h3 style={{ textAlign: 'center' }}>
                                                <Translation id="join_our_whatsapp" defaultMessage="Join our Whatsapp channel of eCosentino" />
                                            </h3>
                                            <label className="d-lg-block d-none">
                                                <Translation
                                                    id="add_your_phone_number_to_receive_information_whatsapp"
                                                    defaultMessage="Add your phone number to receive information and exclusive access to our eCosentino promotional campaigns via Whatsapp."
                                                />
                                            </label>
                                            <label className="d-block d-lg-none text-center">
                                                <Translation
                                                    id="click_on_subscribe_to_receive_information_whatsapp"
                                                    defaultMessage="Click on subscribe to receive information and exclusive access to our eCosentino promotional campaigns via Whatsapp."
                                                />
                                            </label>

                                            <Button
                                                // onClick={() => this.desktopSubmit(this.inputPhone)}
                                                inverted
                                                type="submit"
                                                className="bt bt-inverted loginbutton form-control d-lg-none d-block mt-4"
                                                gtmLabel="ec_mobile_whatsapp_optin"
                                                onClick={() => this.props.submitWhatsappOptinModal(this.state.phone, this.props.email, true)}
                                            >
                                                <a
                                                    href="http://wa.me/34648849972?text=Whatsapp+von+Cosentino+abonnieren"
                                                    target="_blank"
                                                    onClick={() => this.props.submitWhatsappOptinModal(this.state.phone, this.props.email, true)}
                                                >
                                                    <Translation id="send" defaultMessage="Send" />
                                                </a>
                                            </Button>
                                            {this.state.error && <Alerts alert={this.state.error} status="404" />}
                                        </header>

                                        <div className="form-group employee-id d-lg-block d-none">
                                            <div className="container">
                                                <div className="row" style={{ textAlign: 'left' }}>
                                                    <div className="col-12">
                                                        <strong>
                                                            <label>
                                                                <Translation id="phone_number" defaultMessage="Phone number"></Translation>*
                                                            </label>
                                                        </strong>
                                                        <div className="form-group" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <input
                                                                className="form-control col-9"
                                                                placeholder={this.placeholderMap[userSalesOrg]}
                                                                defaultValue={this.state.phone}
                                                                ref={node => {
                                                                    this.inputPhone = node
                                                                }}
                                                                onChange={e => this.setState({ phone: e.target.value })}
                                                                style={{ marginRight: '16px' }}
                                                                type="phone"
                                                                onKeyPress={e => {
                                                                    const value = e.target.value + e.key
                                                                    if (!/^[\+]?[0-9]*$/.test(value)) e.preventDefault()
                                                                    // if (!/[+0-9]/.test(e.key)) e.preventDefault()
                                                                }}
                                                            />
                                                            <Button
                                                                disabled={!(this.state.phone && this.state.phone !== '') || this.state.isLoading || !this.state.acceptPolicies}
                                                                onClick={() => this.desktopSubmit(this.inputPhone)}
                                                                inverted
                                                                type="submit"
                                                                gtmLabel="ec_desktop_whatsapp_optin"
                                                                className="bt bt-inverted loginbutton form-control"
                                                            >
                                                                <Translation id="send" defaultMessage="Send" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <p>
                                                            <small>
                                                                <Translation
                                                                    id="whatsapp_legal"
                                                                    defaultMessage="Your data will be processed by COSENTINO GLOBAL, S.L.U. in order to inform you about our advantages, promotions, events
                                                            and other products. You may exercise your rights of access, rectification, cancellation, opposition, restriction and
                                                            portability or revoke your consent by writing to Ctra. Baza a Huércal - Overa, Km. 59 - 04860 Cantoria (Almería), or by
                                                            e-mail to gdpr@cosentino.com, stating your identity and the nature of your request. For more information about the
                                                            treatment of your data, please refer to the Privacy Policy. By joining our WhatsApp channel, you consent to the
                                                            processing of your personal data"
                                                                />
                                                            </small>
                                                        </p>
                                                        <label className="CheckboxLinkSaleConditions">
                                                            <input
                                                                name="accept_policies_check"
                                                                type="checkbox"
                                                                onClick={() => this.setState({ acceptPolicies: !this.state.acceptPolicies })}
                                                                defaultChecked={this.state.acceptPolicies}
                                                            />{' '}
                                                            <Translation id="i_accept_the" defaultMessage="I accept the" />{' '}
                                                            <LocalizedLink routeId="ROUTE_PRIVACY" target={'_blank'}>
                                                                <b>
                                                                    <Translation id="privacy_policy" defaultMessage="Privacy policy" />
                                                                </b>
                                                            </LocalizedLink>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 text-right mt-4">
                                            <span onClick={() => this.props.dontShowMore()} className="dont_show_more" style={{ cursor: 'pointer' }}>
                                                <small>
                                                    <Translation id="dont_show_more" defaultMessage="Don't show more." />
                                                </small>
                                            </span>
                                        </div>
                                    </div>

                                    <footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WhatsappOptin))
