import Translation from "../global/Translation";
import React from "react";
import {injectIntl} from "react-intl";

class IntegerInputNumber extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.valueDefault ? props.valueDefault : props.min,
            min: props.min ? props.min : 0,
            max: props.max ? props.max : 999
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.valueDefault ? this.props.valueDefault : this.props.min,
            min: this.props.min ? this.props.min : 0,
            max: this.props.max ? this.props.max : 999
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.max !== prevProps.max || this.props.min !== prevProps.min){
            this.setState({
                ...this.state,
                min: this.props.min ? this.props.min : 0,
                max: this.props.max ? this.props.max : 999
            })
        }
    }

    upNumber = () => {
        if(!this.isDisabled() && this.state.value < this.state.max){
            let value = this.state.value
            if (value === '') value = this.state.min
            else value += 1
            this.setState({value})
            if (typeof this.props.onChangeValue === "function") this.props.onChangeValue(value)
        }
    }

    downNumber = () => {
        if(!this.isDisabled() && this.state.value > this.state.min){
            let value = this.state.value
            if (value === '') value = this.state.min
            else value -= 1
            this.setState({value})
            if (typeof this.props.onChangeValue === "function") this.props.onChangeValue(value)
        }
    }

    handleChangeValue = (e) => {
        let value = e.target.value === '' ? '' : parseInt(e.target.value)
        if (value === '') {
            this.setState({value:''})
            if (typeof this.props.onChangeValue === "function") this.props.onChangeValue(this.props.min)
        } else if(!isNaN(value)) {
            value = Math.max(this.state.min, value)
            value = Math.min(this.state.max, value)
            this.setState({value})
            if (typeof this.props.onChangeValue === "function") this.props.onChangeValue(value)
        }
    }

    handleBlur = () => {
        let value = this.state.value
        if (value === '') this.setState({value: this.state.min })
    }

    isDisabled = () => this.props.disabled ? true : (this.state.max <= this.state.min)

    render(){
        const disabled = this.isDisabled()

        return (
            <div className="integer__input__number__container">
            <div className="integer__input__number">
                <div className="integer__input__number--down">
                    <button 
                        onClick={() => this.downNumber()}
                        disabled={disabled}
                    >
                        <i className="far fa-minus" />
                    </button>
                </div>
                <div className="integer__input__number--number">
                    <input
                        maxLength="3"
                        value={this.state.value}
                        onChange={this.handleChangeValue}
                        onBlur={this.handleBlur}
                        disabled={disabled}
                    />
                </div>
                <div className="integer__input__number--up">
                    <button 
                        onClick={() => this.upNumber()}
                        disabled={disabled}
                    >
                        <i className="far fa-plus" />
                    </button>
                </div>

                {this.props.max && (
                    <div className="integer__input__number--max">
                        <span>
                            <Translation id="of" defaultMessage="of" /> {this.props.max}
                        </span>
                    </div>
                )}
            </div>
        </div>
        )
    }
}
export default injectIntl(IntegerInputNumber)