import React from 'react'
import moment from 'moment'
import DisplayOutletIndicator from '../displays/DisplayOutletIndicator'
import DisplaySalesIndicator from '../displays/DisplaySalesIndicator'
import DisplayDiscontinued from '../displays/DisplayDiscontinued'
import DisplayPromoIndicator from '../displays/DisplayPromoIndicator'
import DisplayNewIndicator from '../displays/DisplayNewIndicator'
import DisplayBlackIndicator from '../displays/DisplayBlackIndicator'
import DisplayUpcomingIndicator from '../displays/DisplayUpcomingIndicator'

const ProductIndicators = (props) => {

    const getIndicators = () => {
        const indicators = props.indicators ? props.indicators : []
        if (props.canDisplayDiscontinued) indicators.push(
            <React.Fragment>
                <DisplayDiscontinued discontinued={props.product.discontinued} /> <div style={{ width: '100%' }} />
            </React.Fragment>
        )
        if (props.outlet) indicators.push(
            <DisplayOutletIndicator
                outletQuality={props.product.outletQuality}
                outletDeviated={props.isAdmin || props.impersonatedBy ? props.product.outletDeviated : null}
            />
        )
        if (props.isSale) indicators.push(<DisplaySalesIndicator/>)
        if (props.productIsInPromo) indicators.push(<DisplayPromoIndicator/>)

        return indicators
    }

    const getSphereIndicators = () => {
        const sphereIndicators = props.sphereIndicators ? props.sphereIndicators : []
        const todayMinusOneYear = new moment().subtract(365, 'days')
        const todayPlus15Days = new moment().add(15, 'days')
        const today = new moment()
        const creationDate = moment(props.product.creationDate)
        const showNewIndicator = creationDate > todayMinusOneYear && creationDate < today
        const showUpcomingIndicator = creationDate > today && creationDate < todayPlus15Days

        if (showNewIndicator) sphereIndicators.push(<DisplayNewIndicator />)
        if (showUpcomingIndicator) sphereIndicators.push(<DisplayUpcomingIndicator />)

        if (props.canViewPricesPhase3 && props.tate && props.state.promotions && props.state.promotions.length) {
            if (props.state.promotions[0].dealType === 'A') {
                sphereIndicators.push(<DisplayBlackIndicator value={props.intl.formatMessage({ id: 'new_price' })} productDiscounts={props.productDiscounts} />)
            }
            else if (props.state.promotions[0].dealType === 'B') {
                sphereIndicators.push(<DisplayBlackIndicator value={`-${props.state.promotions[0].value}%`} productDiscounts={props.productDiscounts} />)
            }
        }

        return sphereIndicators
    }


    if (props.product) {
        return (
            <React.Fragment>
                <div className="indicators">
                    {
                        getIndicators().map(i => i)
                    }
                </div>
                <div className="spheres-indicator">
                    {
                        getSphereIndicators().map(i => i)
                    }
                </div>
            </React.Fragment>
        )
    } else return <React.Fragment/>
}

export default ProductIndicators
