import React from 'react'
import Lightbox from 'react-image-lightbox'
import Translation from "../../global/Translation";
import DisplayOutletIndicator from "../../catalog/rectangleResult/displays/DisplayOutletIndicator";
import ProductDisplayName from '../../global/ProductDisplayName';

class MallImageViewer extends React.Component {

    componentDidMount() {
        document.body.style.overflow = 'hidden'
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }

    showValidImage() {
        if(this.props.product.detailImage && this.props.product.detailImage !== 'undefined') return this.props.product.detailImage.replace('?w=500', '')
        return this.props.product.imgLarge.replace('?w=2000', '')
    }

    render() {
        return (
            <section id="section-lots-viewer">
                <div className="carousel">
                    <div>
                        <Lightbox
                            mainSrc={this.showValidImage()}
                            onCloseRequest={this.props.onClose}
                            wrapperClassName={'carousel-custom'}
                            imagePadding={60}
                            imageTitle={
                                <div className="lot-viewer-title">

                                    <div style={{display:'flex'}}>
                                        <div className="mall-image-viewer">
                                            <ProductDisplayName product={this.props.product} />
                                        </div>

                                        {this.props.product.outlet && this.props.product.outlet.qualityOutlet && (
                                        <div className="m-auto" style={{width:'fit-content'}}>
                                            <DisplayOutletIndicator outletQuality={this.props.product.outlet.qualityOutlet} />
                                        </div>
                                        )}
                                    </div>

                                    <div style={{display:'flex'}}>
                                        <div className="bt">
                                            <a href={process.env.REACT_APP_API_HOST + '/helper/download-url/?url=' + this.showValidImage()} download="image.png">
                                                <i className="fal fa-download mr-1" />
                                                <Translation id="download" defaultMessage="Download" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }
                            imageCaption={[]}
                            toolbarButtons={[]}
                        />
                    </div>
                </div>
            </section>
        )
    }
}

export default (MallImageViewer)
