import { connect } from 'react-redux'
import ProfileLowesConfiguration from './ProfileLowesConfiguration'
import { fetchProfileConfiguration } from '../../store/profile-configuration/actions'
import { getLowesStoreData } from '../../store/login/reducers'

const mapStateToProps = state => {
    return {
        lowesStoreData: getLowesStoreData(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchProfileConfiguration: () => {
            dispatch(fetchProfileConfiguration())
        }
    }
}

const ProfileLowesConfigurationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileLowesConfiguration)

export default ProfileLowesConfigurationContainer
