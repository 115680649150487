import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
    getIsLogged,
    getToken,
    getUserType,
    getIsAdmin,
} from '../../../store/login/reducers'
import { withRouter } from 'react-router-dom'
import { getLanguage } from '../../../store/ui/reducers'
import MaterialsList from './MaterialsList'
const mapStateToProps = (state, { match }) => {
    return {
        token: getToken(state),
        isLogged: getIsLogged(state),
        isAdmin: getIsAdmin(state),
        userType: getUserType(state),
        language: getLanguage(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {

    }
}
const MaterialsListContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MaterialsList)
)

export default injectIntl(MaterialsListContainer)
