import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import ReactTooltip from 'react-tooltip'
import copyToClipboardIcon from '../../../assets/img/components/copy-to-clipboard.svg'

const SmallBannerRocketV2 = props => {
    const { intl, market, isOnlyUsaSalesOrg } = props

    // Data for Card with 35 percent off
    const _35percentOffData = {
        percentAmountTitle: intl.formatMessage({ id: 'MSRP_and_150_day_terms', defaultMessage: 'MSRP and 150-day terms.' }),
        bodyText: intl.formatMessage({ id: 'when_you_purchase_a_minimum_of_30_silestone_and_or_dekton_slabs', defaultMessage: 'When you purchase a minimum of 30 Silestone® and/or Dekton® slabs.' }),
        smallText: intl.formatMessage({ id: 'total_discount', defaultMessage: 'Total discount' }),
    }

    //Data for Card with 5 and 10 percent off 
    const commonPercentOffData = {
        bodyText: intl.formatMessage({ id: 'for_any_quantity_of_silestone_and_dekton_slabs', defaultMessage: 'For any quantity of Silestone® and Dekton® slabs.' }),
        smallText: intl.formatMessage({ id: 'additional_discount', defaultMessage: 'Additional discount' }),
    }

    return (
        <div className="small-banner-rocket-promo-v2">
            <div className="banner-title-content">
                <h3>
                    <Translation id="rocket_promo_choose_your_discount" defaultMessage="Rock[et] Promo: Choose Your Discount!" />
                </h3>
            </div>
            <div className="banner-card-content">
                <Card
                    percentAmount={35}
                    percentAmountTitle={_35percentOffData.percentAmountTitle}
                    promotionCode={isOnlyUsaSalesOrg ? '100000479' : '100000452'}
                    bodyText={_35percentOffData.bodyText}
                    smallText={_35percentOffData.smallText}
                    intl={intl}
                />
                {market === 'USACA10' ? (
                    <Card
                        percentAmount={10}
                        promotionCode={'100000446'}
                        bodyText={commonPercentOffData.bodyText}
                        smallText={commonPercentOffData.smallText}
                        intl={intl}
                    />
                ) : market === 'USACA5' ? (
                    <Card
                        percentAmount={5}
                        promotionCode={'100000431'}
                        bodyText={commonPercentOffData.bodyText}
                        smallText={commonPercentOffData.smallText}
                        intl={intl}
                    />
                ) : null}
            </div>
        </div>
    )
}

const Card = props => {
    const { percentAmount, percentAmountTitle, promotionCode, bodyText, smallText, intl } = props
    const [promotionCopied, setPromotionCopied] = useState(false)

    const copyPromotionCodeOnClick = e => {
        e.preventDefault()
        navigator.clipboard.writeText(promotionCode).then(() => {
            setPromotionCopied(true)
            ReactTooltip.hide()
            ReactTooltip.show()
            setTimeout(() => {
                setPromotionCopied(false)
                ReactTooltip.hide()
            }, 3000)
        })
    }

    return (
        <div className="rocket-promo-card">
            <div>
                <div>
                    <h5>
                        {percentAmount}%* <Translation id="off" defaultMessage="off" />. {percentAmountTitle}
                    </h5>
                </div>
                <div className="enter-promotion-code">
                    <span>
                        <Translation id="enter" defaultMessage="Enter" />: {promotionCode}
                        <button
                            onClick={copyPromotionCodeOnClick}
                            data-tip={promotionCopied ? intl.formatMessage({ id: 'copied', defaultMessage: 'Copied' }) : intl.formatMessage({ id: 'copy_to_clipboard', defaultMessage: 'Copy to clipboard' })}
                            className="copy-to-clipboard__btn"
                        >
                            <img src={copyToClipboardIcon} alt="copy to clipboard" />
                        </button>
                        <ReactTooltip
                            effect="solid"
                            place="top"
                        />
                    </span>
                </div>
            </div>
            {bodyText && (
                <div>
                    <p>{bodyText}</p>
                </div>
            )}

            {smallText && (
                <div>
                    <p className="mt-1">
                        <small>* {smallText}</small>
                    </p>
                </div>
            )}
        </div>
    )
}

export default injectIntl(SmallBannerRocketV2)
