import { connect } from 'react-redux'
import MktProductsMax from './MktProductsMax'
import { injectIntl } from 'react-intl'
import { fetchProductsMktToMax, saveProductsMktToMax, getImageBackground } from '../../store/products/actions'
import { getProductsMktToMax } from '../../store/products/reducers'
import { getSelectedCenter } from '../../store/centers/reducers'

const mapStateToProps = state => {
    return {
        products: getProductsMktToMax(state),
        centerId: getSelectedCenter(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProductsMktToMax: () => dispatch(fetchProductsMktToMax()),
        saveProductsMktToMax: products => dispatch(saveProductsMktToMax(products)),
        getImageBackground: product => dispatch(getImageBackground(product)),
        showAlertFailure: textFailure => dispatch({ type: 'FLOATING_ALERTS_ADD_FAILURE_MESSAGE', failureMessage: textFailure })
    }
}

const MktProductsMaxContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MktProductsMax)

export default injectIntl(MktProductsMaxContainer)
