import * as api from '../api'
import {onlyChangeCenter} from '../centers/actions'

export const fetchUsersToImpersonate = () => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_USERS_TO_IMPERSONATE_REQUEST',
        token
    })

    return api
        .fetchUsersToImpersonate(token)
        .then(response => {
            dispatch({ type: 'FETCH_USERS_TO_IMPERSONATE_SUCCESS', users: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_USERS_TO_IMPERSONATE_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}

export const fetchUsersToImpersonateByCenter = (center) => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_USERS_TO_IMPERSONATE_BY_CENTER_REQUEST',
        token
    })

    return api
        .fetchUsersToImpersonateByCenter(center, token)
        .then(response => {
            dispatch(onlyChangeCenter(center))
            dispatch({ type: 'FETCH_USERS_TO_IMPERSONATE_BY_CENTER_SUCCESS', users: response.data })
        })
        .catch(error => {
            dispatch({ type: 'FETCH_USERS_TO_IMPERSONATE_BY_CENTER_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
