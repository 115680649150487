import * as api from '../api'
import { getLanguage } from '../ui/reducers'
import { getSelectedCenter } from '../centers/reducers'
import { fetchCart } from '../cart/actions'
import { getEmployeeId } from '../big-account/reducers'
import { getSelectedShippingAddressForIkea } from '../shipping-addresses/reducers'
import { getToken } from '../login/reducers'
import { getWeightUnit } from '../profile-configuration/reducers'
import {
    getOrderCenter,
    getOrderCenterHasMinWeight,
    getOrderCountryOfSend,
    getOrderCurrentWeight,
    getOrderGoalMax,
    getOrderGoalMin,
    getOrderHasDestination,
    getOrderLenghtBundlesCart,
    getOrderShippingAddress,
    getOrderShippingMethod
} from './reducers'
import { add2cartRecurringOrdersQty } from '../api'
import FileDownload from "js-file-download";

export const fetchOrders = (open, startDate, endDate, filterTypeDate = null, status = null, shippingType = null, supplierCenter = null, search = null) => (dispatch, getState) => {
    const token = getState().login.token
    switch (open) {
        case undefined:
            dispatch({
                type: 'FETCH_ORDERS_REQUEST',
                token,
                open
            })

            return api
                .fetchOrders(token, open, getLanguage(getState()), getSelectedCenter(getState()), startDate, endDate, filterTypeDate, status, shippingType, supplierCenter, search)
                .then(
                    response => {
                        dispatch({ type: 'FETCH_ORDERS_SUCCESS', orders: response.data.data, ordersCurrentPage: 1, ordersLastPage: response.data.last_page })
                    },
                    error => {
                        dispatch({ type: 'FETCH_ORDERS_FAILURE' })
                        // api.errorHandler(dispatch, error.response)
                    }
                )
        //REVIEW: unused??
        case 'ikea':
            dispatch({
                type: 'FETCH_ORDERS_REQUEST',
                token,
                open
            })

            return api.fetchOrders(token, null, getLanguage(getState()), getSelectedCenter(getState()), startDate, endDate, filterTypeDate, status, search, supplierCenter).then(
                response => {
                    dispatch({ type: 'FETCH_ORDERS_SUCCESS', orders: response.data, ordersCurrentPage: 1, ordersLastPage: response.data.last_page })
                },
                error => {
                    dispatch({ type: 'FETCH_ORDERS_FAILURE' })
                    // api.errorHandler(dispatch, error.response)
                }
            )
        case 'open':
            dispatch({
                type: 'FETCH_OPEN_ORDERS_REQUEST',
                token
            })

            return api.fetchOrders(token, open, getLanguage(getState()), getSelectedCenter(getState()), startDate, endDate, filterTypeDate).then(
                response => {
                    dispatch({
                        type: 'FETCH_OPEN_ORDERS_SUCCESS',
                        openOrders: response.data
                    })
                },
                error => {
                    dispatch({ type: 'FETCH_OPEN_ORDERS_FAILURE' })
                    api.errorHandler(dispatch, error.response)
                }
            )
        case 'transfer':
            dispatch({
                type: 'FETCH_TRANSFER_ORDERS_REQUEST',
                token
            })

            return api.fetchTransferOrders(token, getSelectedCenter(getState())).then(
                response => {
                    dispatch({
                        type: 'FETCH_TRANSFER_ORDERS_SUCCESS',
                        transferOrders: response.data
                    })
                },
                error => {
                    dispatch({ type: 'FETCH_TRANSFER_ORDERS_FAILURE' })
                    api.errorHandler(dispatch, error.response)
                }
            )
        case 'offers':
            dispatch({
                type: 'FETCH_OFFERS_REQUEST',
                token
            })

            return api.fetchOffers(token, open, getLanguage(getState()), getSelectedCenter(getState()), startDate, endDate).then(
                response => {
                    dispatch({
                        type: 'FETCH_OFFERS_SUCCESS',
                        offers: response.data
                    })
                },
                error => {
                    dispatch({ type: 'FETCH_OFFERS_FAILURE' })
                    api.errorHandler(dispatch, error.response)
                }
            )
        default:
            break
    }
}
export const fetchOrdersPaginate = (open, filterTypeDate, startDate, endDate, status = null, shippingType = null, supplierCenter = null, search = null, page) => (
    dispatch,
    getState
) => {
    const token = getState().login.token

    dispatch({
        type: 'FETCH_ORDERS_PAGINATE_REQUEST',
        page,
        token
    })

    return api
        .fetchOrdersPaginate(
            token,
            open,
            getLanguage(getState()),
            getSelectedCenter(getState()),
            filterTypeDate,
            startDate,
            endDate,
            status,
            shippingType,
            supplierCenter,
            search,
            page
        )
        .then(
            response => {
                dispatch({
                    type: 'FETCH_ORDERS_PAGINATE_SUCCESS',
                    orders: response.data.data,
                    ordersCurrentPage: response.data.current_page,
                    ordersLastPage: response.data.last_page
                })
            },
            error => {
                dispatch({ type: 'FETCH_ORDERS_PAGINATE_FAILURE' })
                // api.errorHandler(dispatch, error.response)
            }
        )
}
export const fetchOrderToEdit = reservationId => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_ORDER_TO_EDIT_REQUEST',
        token
    })

    return api.fetchOrderToEdit(token, reservationId, getLanguage(getState())).then(
        response => {
            dispatch({
                type: 'FETCH_ORDER_TO_EDIT_SUCCESS',
                orderToEdit: response.data.lines,
                reservationId
            })
        },
        error => {
            dispatch({ type: 'FETCH_ORDER_TO_EDIT_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const saveOrderToEdit = (order, orderId, reservationId) => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'SAVE_ORDER_TO_EDIT_REQUEST',
        order,
        orderId,
        reservationId,
        token
    })

    return api.saveOrderToEdit(token, order, orderId, reservationId, getLanguage(getState())).then(
        response => {
            dispatch({ type: 'SAVE_ORDER_TO_EDIT_SUCCESS', orderToEdit: order })
        },
        error => {
            dispatch({
                type: 'SAVE_ORDER_TO_EDIT_FAILURE',
                error: error.response.data.err
            })
        }
    )
}
export const clearOrderDetails = () => (dispatch, getState) => {
    dispatch({
        type: 'CLEAR_ORDER_DETAILS'
    })
}
export const fetchOrderDetails = reservationId => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_ORDER_DETAILS_REQUEST',
        token
    })

    return api.fetchOrderDetails(token, reservationId, getLanguage(getState())).then(
        response => {
            dispatch({
                type: 'FETCH_ORDER_DETAILS_SUCCESS',
                orderDetailsCart: response.data.cart,
                order: response.data.order,
                orderDetailsHead: response.data.reservation,
                openDocDetails: response.data.openDocDetails,
                invoices: response.data.invoices
            })
            return Promise.resolve()
        },
        error => {
            dispatch({ type: 'FETCH_ORDER_DETAILS_FAILURE', response: error.response })
            return Promise.reject(error.response)
            // api.errorHandler(dispatch, error.response)
        }
    )
}
export const fetchPackingList = reservationId => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_PACKING_LIST_REQUEST',
        token
    })

    return api.fetchPackingList(token, reservationId, getLanguage(getState())).then(
        response => {
            dispatch({
                type: 'FETCH_PACKING_LIST_SUCCESS',
                packingList: response.data.packingList
            })
        },
        error => {
            dispatch({ type: 'FETCH_PACKING_LIST_FAILURE', response: error.response })
            // api.errorHandler(dispatch, error.response)
        }
    )
}
export const fetchOfferOverview = reservationId => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_OFFER_OVERVIEW_REQUEST',
        token
    })

    return api.fetchOfferOverview(token, reservationId, getLanguage(getState())).then(
        response => {
            dispatch({
                type: 'FETCH_OFFER_OVERVIEW_SUCCESS',
                packingList: response.data
            })
        },
        error => {
            dispatch({ type: 'FETCH_OFFER_OVERVIEW_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const rejectOrder = (reservationId, orderId) => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'REJECT_ORDER_REQUEST',
        token,
        reservationId,
        orderId
    })

    return api.rejectOrder(token, reservationId, orderId, getLanguage(getState())).then(
        response => {
            dispatch({ type: 'REJECT_ORDER_SUCCESS', orderDetails: response.data, reservationId })
        },
        error => {
            dispatch({
                type: 'REJECT_ORDER_FAILURE',
                statusText: error.response.data.err
            })
        }
    )
}
export const fetchRecurringOrders = (dateFrom = null, dateTo = null, search = null, page = null) => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_RECURRING_ORDERS_REQUEST'
    })

    return api.fetchRecurringOrders(token, getSelectedCenter(getState()), dateFrom, dateTo, search, page).then(
        response => {
            dispatch({
                type: 'FETCH_RECURRING_ORDERS_SUCCESS',
                recurringOrders: response.data
            })
        },
        error => {
            dispatch({ type: 'FETCH_RECURRING_ORDERS_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const fetchRecurringOrderDetails = id => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_RECURRING_ORDERS_DETAILS_REQUEST'
    })

    return api.fetchRecurringOrdersDetails(token, getSelectedCenter(getState()), id).then(
        response => {
            dispatch({
                type: 'FETCH_RECURRING_ORDERS_DETAILS_SUCCESS',
                recurringOrders: response.data
            })
        },
        error => {
            dispatch({ type: 'FETCH_RECURRING_ORDERS_DETAILS_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const toggleModalRecurringOrderDetails = selectedRecurringOrder => (dispatch, getState) => {
    selectedRecurringOrder
        ? dispatch({
              type: 'OPEN_RECURRING_ORDERS_DETAILS',
              selectedRecurringOrder: selectedRecurringOrder
          })
        : dispatch({ type: 'CLOSE_RECURRING_ORDERS_DETAILS' })
}
export const add2CartRecurringOrder = id => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'ADD2CART_RECURRING_ORDERS_DETAILS_REQUEST'
    })

    return api
        .add2cartRecurringOrders(token, getSelectedCenter(getState()), id, getSelectedShippingAddressForIkea(getState()), getEmployeeId(getState()), getLanguage(getState()))
        .then(
            response => {
                dispatch({ type: 'ADD2CART_RECURRING_ORDERS_DETAILS_SUCCESS' })
                dispatch(fetchCart(getSelectedCenter(getState())))
                dispatch({ type: 'CLOSE_RECURRING_ORDERS_DETAILS' })
            },
            error => {
                dispatch({ type: 'ADD2CART_RECURRING_ORDERS_DETAILS_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const add2CartRecurringOrderQty = value => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'ADD2CART_RECURRING_ORDERS_DETAILS_REQUEST'
    })

    return api
        .add2cartRecurringOrdersQty(token, getSelectedCenter(getState()), value, getSelectedShippingAddressForIkea(getState()), getEmployeeId(getState()), getLanguage(getState()))
        .then(
            response => {
                dispatch({ type: 'ADD2CART_RECURRING_ORDERS_DETAILS_SUCCESS' })
                dispatch(fetchCart(getSelectedCenter(getState())))
                dispatch({ type: 'CLOSE_RECURRING_ORDERS_DETAILS' })
            },
            error => {
                dispatch({ type: 'ADD2CART_RECURRING_ORDERS_DETAILS_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const getFileByUrl = (url, name, extension) => (dispatch, getState) => {
    dispatch({
        type: 'GET_FILE_BY_URL_REQUEST'
    })

    const FileDownload = require('js-file-download');
    const token = getToken(getState())

    return api
        .getFileByUrl(url, token)
        .then(response => {
            if (response.data instanceof Blob) {
                let filename

                if (name) {
                    filename = name + '.' + extension
                }
                else if (response.headers && response.headers['content-disposition'] && response.headers['content-disposition'].includes('attachment')) {
                    const regex = /"([^"]*)"/;
                    const attachment = regex.exec(response.headers['content-disposition']);
                    if (attachment[1]) filename = attachment[1]
                }

                FileDownload(response.data, filename);
                dispatch({ type: 'GET_FILE_BY_URL_SUCCESS' })
            } else {
                dispatch({
                    type: 'GET_FILE_BY_URL_FAILURE',
                    error: 'No response data'
                })
            }
        })
        .catch(error => {
            dispatch({
                type: 'GET_FILE_BY_URL_FAILURE',
                error: error.response.data.err
            })
        })
}

export const fetchAllLowesOrders = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_ALL_LOWES_ORDERS_REQUEST' })

    return api
        .fetchAllLowesOrders(getToken(getState()))
        .then(response => {
            dispatch({
                type: 'FETCH_ALL_LOWES_ORDERS_SUCCESS',
                lowesOrders: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: 'FETCH_ALL_LOWES_ORDERS_FAILURE',
                error: error.response.data.err
            })
        })
}
export const fetchAllLowesLinesOrders = (page, pageSize, query, startDate, endDate) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_ALL_LOWES_LINES_ORDERS_REQUEST',
        page,
        pageSize,
        query,
        startDate,
        endDate
    })

    return api
        .fetchAllLowesLinesOrders(getToken(getState()), page, pageSize, query, startDate, endDate)
        .then(response => {
            dispatch({
                type: 'FETCH_ALL_LOWES_LINES_ORDERS_SUCCESS',
                lowesLinesOrders: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: 'FETCH_ALL_LOWES_LINES_ORDERS_FAILURE',
                error: error.response.data.err
            })
        })
}

export const fetchLowesOrderDetails = reservationId => (dispatch, getState) => {
    dispatch({
        type: 'GET_LOWES_ORDER_DETAILS_REQUEST',
        reservationId: reservationId
    })

    return api
        .fetchLowesOrderDetails(reservationId, getToken(getState()))
        .then(response => {
            dispatch({
                type: 'GET_LOWES_ORDER_DETAILS_SUCCESS',
                details: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: 'GET_LOWES_ORDER_DETAILS_FAILURE',
                error: error.response.data.err
            })
        })
}

export const toggleModalOrderLineDetail = orderLine => (dispatch, getState) => {
    orderLine
        ? dispatch({
              type: 'SHOW_ORDER_LINE_DETAIL',
              orderLine: orderLine
          })
        : dispatch({ type: 'CLOSE_ORDER_LINE_DETAIL' })
}
export const closeOrderLineModal = () => {
    return { type: 'CLOSE_ORDER_LINE_DETAIL' }
}

export const changeOrderInEdition = orderInEdition => {
    return { type: 'CHANGE_ORDER_IN_EDITION', orderInEdition }
}

export const canEditOrderBundleCatalog = () => (dispatch, getState) => {
    if (getOrderShippingAddress(getState()) && getOrderCenter(getState()) === 7500)
        return (
            getOrderHasDestination(getState()) &&
            getOrderCurrentWeight(getState(), getWeightUnit(getState())) >= getOrderGoalMin(getState(), getWeightUnit(getState())) &&
            canEditOrderBundleLimit()
        )
    else return false
}

export const canEditOrderBundleLimit = () => (dispatch, getState) => {
    let conditionForMaxBundle = getOrderShippingAddress(getState()) && getOrderShippingMethod(getState()) === 'CONTAINER' && getOrderCenter(getState()) === 7500

    if (conditionForMaxBundle) {
        let bundleLimit = getOrderCountryOfSend(getState()) === 'IN' ? 6 : 7
        return getOrderLenghtBundlesCart(getState()) === bundleLimit
    }
    return true
}

export const canEditOrderMinMax = () => (dispatch, getState) => {
    const weightUnit = getWeightUnit(getState())
    const currentWeight = getOrderCurrentWeight(getState(), getWeightUnit(getState()))
    const goalMin = getOrderGoalMin(getState(), weightUnit)
    const goalMax = getOrderGoalMax(getState(), weightUnit)

    return (
        (currentWeight >= goalMin && currentWeight <= goalMax) ||
        (getOrderShippingMethod(getState()) === 'SINGLE_SLAB' && currentWeight <= goalMax) ||
        getOrderCenterHasMinWeight(getState()) ||
        getOrderShippingMethod(getState()) === 'MKTOOLS' ||
        getOrderShippingMethod(getState()) === 'LOWES_CUSTOMER_SAMPLES' ||
        dispatch(canEditOrderBundleCatalog())
    )
}

export const acceptQuote = (offer) => (dispatch, getState) => {
    const token = getToken(getState())
    dispatch({type: 'SEND_ACCEPT_QUOTE_REQUEST'})

    api.acceptQuote(token, offer.reservationId).then(
        response => dispatch({type: 'SEND_ACCEPT_QUOTE_SUCCESS', offer}),
        error => dispatch({
            type: 'SEND_ACCEPT_QUOTE_FAILURE',
            statusText: error.response.data.err
        })
    );
}

export const rejectQuote = (offer) => (dispatch, getState) => {
    const token = getToken(getState())
    dispatch({type: 'SEND_REJECT_QUOTE_REQUEST'})

    api.rejectQuote(token, offer.reservationId).then(
        response => dispatch({type: 'SEND_REJECT_QUOTE_SUCCESS', offer}),
        error => dispatch({
            type: 'SEND_REJECT_QUOTE_FAILURE',
            statusText: error.response.data.err
        })
    );
}

export const fetchPackagingImages = (orderId) => (dispatch, getState) => {
    const token = getToken(getState())
    dispatch({type: 'FETCH_PACKAGING_IMAGES_REQUEST'})

    api.fetchPackagingImages(token, orderId).then(
        response => dispatch({type: 'FETCH_PACKAGING_IMAGES_SUCCESS', packagingImages: response.data}),
        error => dispatch({
            type: 'FETCH_PACKAGING_IMAGES_FAILURE',
            statusText: error.response.data.err
        })
    )
}

