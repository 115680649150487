import debounce  from 'lodash/debounce'
import { fetchPreOrdersCts, fetchPreOrdersCtsPaginate } from './../../store/cut-to-size/actions'
import React from 'react'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import Button from '../global/Button'
import { injectIntl } from 'react-intl'
import OrderTransfersStatus from './OrderTransfersStatus'
import moment from 'moment'
import Timeline from './Timeline'
import { getOrderStatusMap } from '../../store/orders/reducers'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

class PreOrdersCtsFilters extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startDate: moment().add(-90, 'days'),
            endDate: moment(),
            filterProject: null,
            filterTypeOrder: null,
            showDetails: false,
            search:''
        }
    }

    componentDidMount() {}
    changeTypeOrder = e => {
        this.setState({ filterTypeOrder: e.value }, () => this.fetchOrders())
    }

    changeStartDate = date => {
        this.setState({ startDate: date })
    }

    changeEndDate = date => {
        this.setState({ endDate: date }, () => this.fetchOrders())
    }

    changeTypeProject = e => {
        this.setState({ filterProject: e.value }, () => this.fetchOrders())
    }
    fetchOrders = () => {
        this.props.fetchPreOrdersCtsPaginate(this.state.startDate, this.state.endDate, this.state.filterProject, this.state.filterTypeOrder, this.state.search, 1)
    }
    searchDebounce = debounce(() => {
        if ((this.state.search && this.state.search.length > 2) || (!this.state.search || this.state.search.length === 0)) {
            this.fetchOrders()
        }
    }, 500)

    render() {
        const inputProjectType = (
            <Select
                options={
                    this.props.projectList &&
                    this.props.projectList.length > 0 &&
                    this.props.projectList.map(item => {
                        return {
                            label: this.props.intl.formatMessage({ id: item.project }),
                            value: item.project
                        }
                    })
                }
                value={this.state.filterProject}
                clearable={false}
                onChange={this.changeTypeProject}
                className={`${this.props.isNewVersion ? 'customDropdown' : 'Select--sm form-control form-control-sm flex-grow-1'}`}
            />
        )
        const inputDateFrom = (
            <DatePicker
                selected={this.state.startDate}
                selectsStart={true}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                maxDate={moment().add(12, 'month')}
                onChange={selectedOption => this.changeStartDate(selectedOption, 'startDate')}
                onFocus={() => this.setState({ endDate: null })}
                autoComplete="off"
                onKeyDown={e => e.preventDefault()}
                showMonthDropdown
                showYearDropdown
                isClearable={true}
                className={`${this.props.isNewVersion ? 'form-control form-control-sm date customDatePicker' : 'form-control form-control-sm date'}`}
            />
        )
        const inputDateTo = (
            <DatePicker
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={this.state.startDate}
                maxDate={moment(this.state.startDate).add(12, 'month')}
                onChange={selectedOption => this.changeEndDate(selectedOption, 'endDate')}
                autoComplete="off"
                onKeyDown={e => e.preventDefault()}
                showMonthDropdown
                showYearDropdown
                isClearable={true}
                disabled={!this.state.startDate}
                className={`${this.props.isNewVersion ? 'form-control form-control-sm date customDatePicker' : 'form-control form-control-sm date'}`}
            />
        )
        const inputStatus = (
            <Select
                options={['REQUESTED', 'TECHNICAL_REVIEW', 'CSTMR_APPROVAL', 'ORDER_CREATED', 'CANCELLED'].map(item => {
                    return {
                        label: this.props.intl.formatMessage({ id: item }),
                        value: item
                    }
                })}
                value={this.state.filterTypeOrder}
                clearable={false}
                onChange={this.changeTypeOrder}
                className={`${this.props.isNewVersion ? 'customDropdown' : 'Select--sm form-control form-control-sm flex-grow-1'}`}
            />
        )
        const inputSearch = (
            <input
                type="text"
                data-qa="query"
                placeholder={this.props.intl.formatMessage({
                    id: 'search'
                })}
                value={this.state.search}
                onChange={e => this.setState({ search: e.target.value })}
                onKeyUp={this.searchDebounce}
                onPaste={this.searchDebounce}
                className={`${this.props.isNewVersion ? 'form-control' : 'search-box form-control form-control-sm'}`}
            />
        )

        return (
            <React.Fragment>
            {!this.props.isNewVersion ? (
                <div className="row order_filters">
                    {this.props.type !== 'transfer' && (
                        <React.Fragment>
                            <div
                                className={`col-12 col-lg-10  order-2 order-sm-1   group_date_picker justify-content-between d-sm-block my-1 ${this.state.showFilter ? '' : 'd-none'}`}
                            >
                                <div className="row">
                                    <div className={'col-12 col-lg-3'}>
                                        <div className="name">
                                            <Translation id="project_type" defaultMessage="Project type" />
                                        </div>
                                        {inputProjectType}
                                    </div>

                                    <div className="group_date_picker__item col-6 col-lg-3">
                                        <div className="name">
                                            <Translation id="from" defaultMessage="From" /> <i className="fal fa-chevron-down" />
                                        </div>                                     
                                        {inputDateFrom}
                                    </div>

                                    <div className="group_date_picker__item col-6 col-lg-3">
                                        <div className="name">
                                            <Translation id="to" defaultMessage="To" /> <i className="fal fa-chevron-down" />
                                        </div>
                                        {inputDateTo}
                                    </div>

                                    <div className={'col-12 col-lg-3'}>
                                        <div className="name">
                                            <Translation id="status" defaultMessage="Status" />
                                        </div>                                     
                                        {inputStatus}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-2  order-1 order-sm-4 order-lg-2 my-1">
                                <div className={'d-sm-flex align-items-end'} style={{ height: '100%' }}>
                                    <div className="search-bar search-bar--thin w-100" style={{ minWidth: 'fit-content' }}>        
                                        {inputSearch}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            ) : (
                <div>
                    {this.props.type !== 'transfer' && (
                        <React.Fragment>
                        <div className="row preorder__cts__filter">
                            <div className="col-12 p-0">
                                <div className="preorder__cts__filter--inputs">
                                    <div className="col-12 col-md-6 d-flex p-0 preorder__cts__filter--column1">
                                        <div className="preorder__cts--project">                                      
                                            <label className="font-bold"><Translation id="filter_by" defaultMessage="Filter by" /></label>
                                            <div>
                                                {inputProjectType}
                                            </div>
                                        </div>
                                        <div className="preorder__cts--datefrom">
                                            <label className="font-bold"><Translation id="from" defaultMessage="From" /></label>
                                            <div>
                                                {inputDateFrom}
                                            </div>                            
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 d-flex p-0 preorder__cts__filter--column2">
                                        <div className="preorder__cts--dateto">
                                            <label className="font-bold"><Translation id="to" defaultMessage="To" /></label>
                                            <div>
                                                {inputDateTo}
                                            </div>
                                        </div>
                                        <div className="preorder__cts--status">
                                            <label className="font-bold"><Translation id="status" defaultMessage="Status" /></label>
                                            <div>
                                                {inputStatus}
                                            </div>                                     
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0">
                                <div className="preorder__cts--search">        
                                    {inputSearch}
                                    <i class="fa fa-search" aria-hidden="true" />
                                </div>                           
                            </div>
                        </div>
                        </React.Fragment>
                    )}
                </div>
            )}
            </React.Fragment>
        )
    }
}

export default injectIntl(PreOrdersCtsFilters)
