import React from 'react'
import Translation from '../global/Translation'

import ReactTable from 'react-table'
import { defineMessages, injectIntl } from 'react-intl'

import Button from '../global/Button'
import Modal from '../global/Modal'
import { connect } from 'react-redux'
import Spinner from '../global/Spinner'
import DatePicker from 'react-datepicker'

import { fetchAllLowesOrders, fetchLowesOrderDetails, fetchAllLowesLinesOrders } from '../../store/orders/actions'
import { getLowesOrders, getLowesOrderDetails, getOrdersLoading, getLowesLinesOrders } from '../../store/orders/reducers'
import { getToken, getIsLowesAdmin } from '../../store/login/reducers'
import { getImageBackground } from '../../store/products/actions'
import debounce from 'lodash/debounce'
import moment from 'moment'
import noImage from '../../assets/img/icons/no-image.svg'

defineMessages({
    SEARCH_BY_ID_OR_NAME: {
        id: 'search_by_id_or_name',
        description: 'search_by_id_or_name',
        defaultMessage: 'Search by Id or Name'
    },
    SEARCH_BY_NAME: {
        id: 'search_by_name',
        description: 'search_by_name',
        defaultMessage: 'Search by Name'
    }
})
const mapStateToProps = state => {
    return {
        lowesOrders: getLowesOrders(state),
        lowesOrderDetails: getLowesOrderDetails(state),
        lowesLinesOrders: getLowesLinesOrders(state),
        isLoading: getOrdersLoading(state),
        token: getToken(state),
        isLowesAdmin: getIsLowesAdmin(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchAllLowesOrders: () => {
            dispatch(fetchAllLowesOrders())
        },
        fetchAllLowesLinesOrders: (page, pageSize, query, startDate, endDate) => dispatch(fetchAllLowesLinesOrders(page, pageSize, query, startDate, endDate)),
        fetchLowesOrderDetails: reservationId => dispatch(fetchLowesOrderDetails(reservationId)),
        clearLowesOrderDetails: () => dispatch({ type: 'CLEAR_LOWES_ORDER_DETAILS' }),
        getImageBackground: product => dispatch(getImageBackground(product))
    }
}
class LowesReports extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            startDate: null,
            endDate: null,
            page: 0,
            selectedCSVFormat: 'xls'
        }
    }
    componentDidMount() {
        this.input.addEventListener('keydown', this.debounceInput)
        // this.props.fetchAllLowesLinesOrders()
    }
    showOrderDetails(reservationId) {
        this.props.fetchLowesOrderDetails(reservationId)
    }
    hiddenorderDetails() {
        this.props.clearLowesOrderDetails()
    }
    changeStartDate = date => {
        this.setState({ startDate: date })
    }

    changeEndDate = date => {
        this.setState({ endDate: date }, () => this.fetchAllLowesLinesOrders(0, this.state.pageSize))
    }

    debounceInput = debounce(e => {
        this.fetchAllLowesLinesOrders(0, this.state.pageSize)
    }, 250)
    fetchAllLowesLinesOrders = (page, pageSize) => {
        this.setState({ page: page, pageSize: pageSize }, () => this.props.fetchAllLowesLinesOrders(page + 1, pageSize, this.input.value, this.state.startDate, this.state.endDate))
    }
    downloadCsv = () => {
        if (!this.inputEmail.value) {
            return
        }

        this.setState({ emailPromptOpen: false })

        const email = this.inputEmail.value

        // const header = fields.map(field => {
        //     return intl.formatMessage({ id: `${field}` })
        // })

        // const url = `${process.env.REACT_APP_API_HOST}/lowes/orders/csv?&to=${encodeURIComponent(email)}&format=${this.state.selectedCSVFormat}&authToken=${this.props.token}`
        const url = `${process.env.REACT_APP_API_HOST}/lowes/orders/csv?&to=${encodeURIComponent(email)}&format=${this.state.selectedCSVFormat}`
        fetch(url, {
            headers: {Authorization: `Bearer ${this.props.token}`}
        })
    }
    getColumnsLineOrders() {
        let img = {
            id: 'img',
            Header: <Translation id="image" defaultMessage="Image" />,
            accessor: 'img',
            maxWidth: 160,
            Cell: ({ original }) => {
                if(original.product !== null && original.product !== undefined) {
                    const imageBackground = this.props.getImageBackground(original.product)
                        return (
                            <React.Fragment>
                                <img src={imageBackground} alt="product-img" width="75" height="50" />
                            </React.Fragment>
                        )
                } else
                return (
                    <React.Fragment>
                        <img src={noImage} alt="temporal-img" width="75" height="50" />
                    </React.Fragment>
                )
            }
        }
        let createdAt = {
            id: 'createdAt',
            Header: <Translation id="date" defaultMessage="Date" />,
            accessor: 'createdAt'
        }
        let orderId = {
            id: 'orderId',
            Header: <Translation id="order_hashtag" defaultMessage="Order #" />,

            accessor: 'orderId'
        }

        let storeId = {
            id: 'storeId',
            Header: <Translation id="store_hashtag" defaultMessage="Store #" />,
            accessor: 'storeId',
            filterable: false
        }
        let storeName = {
            id: 'storeName',
            Header: <Translation id="store_name" defaultMessage="Store Name" />,
            accessor: 'storeName'
        }

        let displayName = {
            id: 'displayName',
            Header: <Translation id="description" defaultMessage="Description" />,
            accessor: 'displayName'
        }
        let brand = {
            id: 'brand',
            Header: <Translation id="brand" defaultMessage="Brand" />,
            accessor: 'brand'
        }
        let colorId = {
            id: 'colorId',
            Header: <Translation id="color" defaultMessage="Color" />,
            accessor: 'colorId'
        }
        let cutSize = {
            id: 'cutSize',
            Header: <Translation id="cut_size" defaultMessage="Cut Size" />,
            accessor: 'cutSize'
        }
        let pogType = {
            id: 'pogType',
            Header: <Translation id="pog_type" defaultMessage="Pog Type" />,
            accessor: 'pogType'
        }
        let lowesReorderId = {
            id: 'lowesReorderId',
            Header: <Translation id="lowes_reorder" defaultMessage="Lowe's Reorder" />,
            accessor: 'lowesReorderId'
        }
        let productId = {
            id: 'productId',
            Header: <Translation id="sap_item_id" defaultMessage="Sap Item Id" />,
            accessor: 'productId'
        }
        let uom = {
            id: 'uom',
            Header: <Translation id="uom" defaultMessage="UOM" />,
            accessor: 'uom'
        }
        let qty = {
            id: 'qty',
            Header: <Translation id="quantity" defaultMessage="Quantity" />,
            accessor: 'qty'
        }
        // let address = {
        //     id: 'address',
        //     Header: <Translation id="address" defaultMessage="Address" />,
        //     accessor: 'address'
        // }

        // let city = {
        //     id: 'city',
        //     Header: <Translation id="city" defaultMessage="City" />,
        //     accessor: 'city'
        // }
        // let state = {
        //     id: 'state',
        //     Header: <Translation id="state" defaultMessage="State" />,
        //     accessor: 'state'
        // }
        // let zip = {
        //     id: 'zip',
        //     Header: <Translation id="zipcode" defaultMessage="Zip Code" />,
        //     accessor: 'zip'
        // }
        let name = {
            id: 'name',
            Header: <Translation id="name" defaultMessage="Name" />,
            accessor: 'name'
        }
        let title = {
            id: 'title',
            Header: <Translation id="title" defaultMessage="Title" />,
            accessor: 'title'
        }
        let email = {
            id: 'email',
            Header: <Translation id="email" defaultMessage="Email" />,
            accessor: 'email'
        }
        let shipDate = {
            id: 'shipDate',
            Header: <Translation id="ship_date" defaultMessage="Ship Date" />,
            accessor: 'shipDate'
        }
        let status = {
            id: 'status',
            Header: <Translation id="status" defaultMessage="Status" />,
            accessor: 'status'
        }
        let shippingStatus = {
            id: 'shippingStatus',
            Header: <Translation id="shippingStatus" defaultMessage="Shipping status" />,
            Cell: ({ original }) => {
                return (
                    <div>
                        {original.status === 'Shipped' ? original.status : original.shippingStatus}
                        {original.tscDetailsLine && (
                            <ul style={{ paddingLeft: '0px' }}>
                                {original.tscDetailsLine.backOrderQuantity !== original.qty && (
                                    <ul>
                                        {original.tscDetailsLine.quantity > 0 && (
                                            <li>
                                                <span>
                                                    <Translation id="tracking" defaultMessage="Tracking" />{' '}
                                                    {parseInt(original.tscDetailsLine.quantity, 10) === parseInt(original.qty, 10) ? (
                                                        <React.Fragment>{original.relatedShipments[0] && original.relatedShipments[0].trackingNumber}</React.Fragment>
                                                    ) : (
                                                        original.tscDetailsLine.quantity > 0 &&
                                                        original.relatedShipments.map(shipment => {
                                                            return shipment.trackingNumber + ' '
                                                        })
                                                    )}{' '}
                                                    - {original.tscDetailsLine.quantity} / {original.qty}
                                                </span>
                                            </li>
                                        )}
                                        {original.tscDetailsLine.backOrderQuantity > 0 && (
                                            <li>
                                                <span>
                                                    {original.status === 'Shipped' ? 'Tracking' : 'Pending ' + original.tscDetailsLine.backOrderQuantity}{' '}
                                                    {original.tscDetailsLine.backOrderQuantity > 0 &&
                                                        original.relatedShipments.map(shipment => {
                                                            return shipment.trackingNumber + ' '
                                                        })}{' '}
                                                    - {original.tscDetailsLine.backOrderQuantity} / {original.qty}
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </ul>
                        )}
                    </div>
                )
            }
        }
        let shippingCarrier = {
            id: 'shippingCarrier',
            Header: <Translation id="shipping_carrier" defaultMessage="Shipping Carrier" />,
            accessor: 'shippingCarrier'
        }
        let trackingId = {
            id: 'trackingId',
            Header: <Translation id="tracking" defaultMessage="Tracking" />,
            accessor: 'trackingId'
        }

        if (this.props.isLowesAdmin)
            return [
                createdAt,
                orderId,
                storeId,
                storeName,
                qty,
                displayName,
                brand,
                colorId,
                cutSize,
                pogType,
                lowesReorderId,
                productId,
                uom,
                name,
                title,
                email,
                shipDate,
                status,
                shippingStatus,
                shippingCarrier,
                trackingId
            ]
        return [
            createdAt,
            orderId,
            img,
            qty,
            displayName,
            brand,
            colorId,
            cutSize,
            pogType,
            lowesReorderId,
            uom,
            name,
            title,
            shipDate,
            status,
            shippingStatus,
            shippingCarrier,
            trackingId
        ]
    }

    render() {
        if (!this.props.lowesLinesOrders) return <Spinner isLoading={true} />

        return (
            <div className="container container--lowes">
                <div className="row" style={{ paddingBottom: '15px' }}>
                    <div className="col-12 order_header">
                        <span>
                            <Translation id="for_assistance_please_email" defaultMessage="For assistance please email" /> <a href="mailto:lowescustomercare@cosentino.com">lowescustomercare@cosentino.com</a>
                        </span>
                    </div>
                    <div className="col-12  order_header">
                        <div className="group_date_picker col-12 col-lg-4" style={{ marginRight: '0px' }}>
                            {this.props.type !== 'transfer' && (
                                <React.Fragment>
                                    <div className="group_date_picker__item">
                                        <div className="name">
                                            <Translation id="from" defaultMessage="From" /> <i className="fal fa-chevron-down" />
                                        </div>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            selectsStart={true}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            maxDate={moment()}
                                            onChange={selectedOption => this.changeStartDate(selectedOption, 'startDate')}
                                            onFocus={() => this.setState({ endDate: null })}
                                            autoComplete="off"
                                            onKeyDown={e => e.preventDefault()}
                                            showMonthDropdown
                                            showYearDropdown
                                            className="form-control form-control-sm date"
                                        />
                                    </div>
                                    <div className="group_date_picker__item">
                                        <div className="name">
                                            <Translation id="to" defaultMessage="To" /> <i className="fal fa-chevron-down" />
                                        </div>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            selectsEnd
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            minDate={this.state.startDate}
                                            onChange={selectedOption => this.changeEndDate(selectedOption, 'endDate')}
                                            autoComplete="off"
                                            onKeyDown={e => e.preventDefault()}
                                            showMonthDropdown
                                            showYearDropdown
                                            className="form-control form-control-sm date"
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="search-bar search-bar--thin col-10 col-lg-6" style={{ marginRight: '0px' }}>
                            <input
                                type="text"
                                className="search-box"
                                placeholder={this.props.intl.formatMessage({ id: 'search_by_id_or_name' })}
                                ref={node => {
                                    this.input = node
                                }}
                            />
                        </div>
                        <div className="col-1" style={{ marginRight: '0px' }}>
                            {/* <a href={process.env.REACT_APP_API_HOST + `/lowes/orders/csv?authToken=${this.props.token}`} data-cy="impersonate__link_download_users_list"> */}
                            <Button
                                icon="times"
                                customClass="only-icon bt--thin"
                                onClick={() => {
                                    // this.startDate.value = null
                                    // this.endDate.value = null
                                    this.input.value = null
                                    return this.setState({ startDate: null, endDate: null, query: null }, () => this.fetchAllLowesLinesOrders(0, this.state.pageSize))
                                }}
                            ></Button>
                            {/* </a> */}
                        </div>

                        <div className="col-6 col-lg-1">
                            {/* <a href={process.env.REACT_APP_API_HOST + `/lowes/orders/csv?authToken=${this.props.token}`} data-cy="impersonate__link_download_users_list"> */}
                            <Button
                                icon="download"
                                onClick={() => this.setState({ emailPromptOpen: true })}
                                customClass="only-icon bt--thin"
                                data-cy="impersonate__link_download_users_list"
                            />
                            {/* </a> */}
                        </div>
                    </div>
                </div>
                <ReactTable
                    data={this.props.lowesLinesOrders.data}
                    loading={this.props.isLoading}
                    loadingText={<Spinner isLoading={this.props.isLoading} />}
                    className="-highlight scroll react-table--lowes-orders mb-5"
                    columns={this.getColumnsLineOrders()}
                    defaultPageSize={20}
                    sortable={false}
                    // pageSizeOptions={[6, 8, 16, 32, 64, 128]}
                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                    nextText={<Translation id="next" defaultMessage="Next" />}
                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                    pageText={<Translation id="page" defaultMessage="Page" />}
                    ofText={<Translation id="of" defaultMessage="Of" />}
                    manual
                    page={this.state.page}
                    onPageChange={page => this.setState({ page })}
                    pages={this.props.lowesLinesOrders.last_page}
                    onFetchData={(state, instance) => {
                        this.fetchAllLowesLinesOrders(state.page, state.pageSize)
                        this.setState({ loading: true })
                    }}
                />

                {this.state.emailPromptOpen && (
                    <Modal
                        title={<Translation id="emailPromptModalTitle" defaultMessage="We are preparing your listing" />}
                        onClose={() => this.setState({ emailPromptOpen: false })}
                        footer={
                            <Button onClick={() => this.downloadCsv()} inverted size="medium">
                                <Translation id="send" defaultMessage="Send" />
                            </Button>
                        }
                    >
                        <p>
                            <Translation
                                id="emailPromptModalBody"
                                defaultMessage="This process may take several minutes. Please, give us your email so we can send it once it is ready."
                            />
                        </p>
                        <div className="container">
                            <Translation id="please_specify_the_desired_format" defaultMessage="Please specify the desired format" />

                            <div className="col-12" style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <span>
                                    <form>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                value="xls"
                                                checked={this.state.selectedCSVFormat === 'xls'}
                                                onChange={e =>
                                                    this.setState({
                                                        selectedCSVFormat: e.target.value
                                                    })
                                                }
                                            />
                                            <label class="form-check-label">
                                                <Translation id="csv_optimized_for_excel" defaultMessage="csv optimized for excel"></Translation>
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                value="csv"
                                                checked={this.state.selectedCSVFormat === 'csv'}
                                                onChange={e =>
                                                    this.setState({
                                                        selectedCSVFormat: e.target.value
                                                    })
                                                }
                                            />
                                            <label class="form-check-label">csv</label>
                                        </div>
                                    </form>
                                </span>
                            </div>
                        </div>
                        <div className="form-group">
                            <b>
                                <small><Translation id="email" defaultMessage="Email" /></small>
                            </b>
                            <input ref={ref => (this.inputEmail = ref)} type="email" className="form-control" aria-describedby="emailHelp" placeholder="Email" />
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LowesReports)
)
