import React from 'react'

const CustomFilter = props => {

    if (props.onChange && props.children.props.mapper) {
        let _onChange = {};
        if (props.children.props.mapper.onChange) {
            Object.entries(props.children.props.mapper.onChange).forEach(([k, fn]) => {
                _onChange[k] = (value, key) => {
                    if (value !== undefined && key) {
                        const _value = (value && value.value) ? value.value : value
                        props.children.props.mapper.output[key] = _value
                        fn(value)
                        props.onChange(props.children.props.mapper.output)
                    }
                }
            })
        }

        props.children.props.mapper.onChange = _onChange
    }

    return (
        <React.Fragment>
            { props.children.props.children }
        </React.Fragment>
    )
}

export default CustomFilter