import React from 'react'
import Translation from '../global/Translation'
import { injectIntl } from 'react-intl'
import $ from "jquery"

import Modal from '../global/Modal'
import { Editor, EditorTools } from '@progress/kendo-react-editor'
import Button from '../global/Button'
import Alerts from '../alerts/Alerts'

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock
} = EditorTools

class I18nPromotionModal extends React.Component {
    constructor(props) {
        super(props)

        if (!this.props.translations || (this.props.translations && !this.props.languages.find(lang => lang.value === this.props.translations.default))) {
            if (!!this.props.languages.length) {
                this.state = {
                    translations: { default: this.props.languages[0].value },
                    showEmptyAlert: false,
                    charLimitErrors: []
                }
            }
        } else {
            this.state = {
                translations: this.props.translations,
                showEmptyAlert: false,
                charLimitErrors: []
            }
        }
    }


    handleChange(event) {
        if (event.target.type === 'radio') {
            let value = undefined
            if (event.target.checked) {
                value = event.target.id.split('_').length > 1 ? event.target.id.split('_')[1] : ''
            }
            this.setState(prevState => ({
                translations: { ...prevState.translations, default: value }
            }))

            console.log('> ' , value.replace(/<[^>]*>/g,'').trim())
        } else {
            let value = event.html
            let key = event.target.props.id
            this.setState(prevState => ({
                translations: { ...prevState.translations, [key]: value }
            }))

            console.log('> ' , value.replace(/<[^>]*>/g,'').trim())
        }
    }

    handleSubmit(event) {

        const charLimitErrors = []
        let defaultEmpty = false

        this.props.languages.forEach(l => {
            const translation = this.state.translations[l.value]
            if (translation === undefined) return
            const sanitizedText = translation.replace(/<[^>]*>/g,'').trim()

            if (l.value === this.state.translations.default && sanitizedText.length === 0) {
                defaultEmpty = true
            }

            if (this.props.limit && sanitizedText.length > this.props.limit ){
                charLimitErrors.push(l.value)
            }
        })
        //
        // // let defaultText = this.state.translations.default && this.state.translations[this.state.translations.default]
        // // defaultText = defaultText && defaultText.replace(/<[^>]*>/g,'').trim()
        // // const validText = defaultText && defaultText.length > 0
        this.setState({showEmptyAlert: defaultEmpty})
        this.setState({charLimitErrors: charLimitErrors})

        if (!defaultEmpty && charLimitErrors.length === 0) {
            this.props.onSaveForm(this.props.type, this.state.translations)
        }
    }

    valueDefault = () => {
        return this.state.translations['default'] ? this.state.translations['default'] : null
    }

    getBannerTitle = lang => {
        if (this.props.type === 'banner_title') return this.state.translations[lang.value]
        else if (this.props.type === 'banner_description') return this.props.allTranslations.banner_title[lang.value]
        return ''
    }

    getBannerDescription = lang => {
        if (this.props.type === 'banner_title') return this.props.allTranslations.banner_description[lang.value]
        else if (this.props.type === 'banner_description') return this.state.translations[lang.value]
        return ''
    }
    render() {
        return (
            <Modal
                title={this.props.intl.formatMessage({ id: this.props.title })}
                onClose={this.props.onCloseModal}
                size="large"
                customClass="i18n-promos-modal"
                footer={
                    <div>
                        <Button onClick={e => this.handleSubmit(e)} inverted size="medium" form="i18n-promos-form">
                            <Translation id="save" />
                        </Button>
                        {this.state && this.state.showEmptyAlert && (<Alerts alert={'DONT_ALLOWED_EMPTY_TEXT'} status="warning" />)}
                        {this.state && this.state.charLimitErrors.length > 0 && (<Alerts alert={'EXCEEDED_CHARS_LIMIT'}
                                                                                         values={{langs:this.state.charLimitErrors.join(', ')}}
                                                                                         status="warning" />)}
                    </div>
                }
            >
                <div>
                    {this.props.description && (
                        <div>
                            {this.props.description}
                            <hr style={{ marginLeft: '-1rem', marginRight: '-1rem' }} />
                        </div>
                    )}
                    <form id="i18n-promos-form" onSubmit={e => this.handleSubmit(e)}>
                        <div className="row">
                            <div className="col-3">
                                <label>
                                    <Translation id="promotion_i18n_default" defaultMessage="Default" />
                                </label>
                            </div>
                            <div className="col offset-3">
                                <label>
                                    <Translation id="promotion_i18n_translations" defaultMessage="Translations" />
                                </label>
                            </div>
                        </div>
                        <div>
                            {this.props.languages.map(lang => {
                                return (
                                    <div id={'group_' + lang.value} className="row promotion-i18n-row">
                                        <div className="col-1">
                                            <input
                                                id={`default_${lang.value}`}
                                                type="radio"
                                                checked={this.valueDefault() === lang.value}
                                                required={this.valueDefault() === undefined || this.valueDefault() === null}
                                                onChange={e => this.handleChange(e)}
                                            />
                                        </div>
                                        <div className="col-1">
                                            <span className={`flag-icon flag-icon-${lang.code} mx-1`} />
                                        </div>
                                        <div className="col-2">
                                            <label>{lang.label}</label>
                                        </div>
                                        <div className="col-8">
                                            <Editor
                                                onChange={e => this.handleChange(e)}
                                                id={lang.value}
                                                value={this.state.translations[lang.value] ? this.state.translations[lang.value] : ''}
                                                tools={[
                                                    Bold,
                                                    Italic,
                                                    Underline,
                                                    Strikethrough,
                                                    Subscript,
                                                    Superscript,
                                                    AlignLeft,
                                                    AlignCenter,
                                                    AlignRight,
                                                    AlignJustify,
                                                    Indent,
                                                    Outdent,
                                                    OrderedList,
                                                    UnorderedList,
                                                    Undo,
                                                    Redo,
                                                    FormatBlock
                                                ]}
                                                contentStyle={{ height: 160 }}
                                            />
                                        </div>
                                        {this.props.bannerPreview && (
                                            <div className="col-12 my-4">

                                                <div className="promotion-home-banner">
                                                    <div className="promotion-home-banner-text" style={{backgroundImage: 'url(' + this.props.bannerPreview.url + ')'}}>
                                                        <div dangerouslySetInnerHTML={{ __html: this.getBannerTitle(lang) }} />
                                                        <div dangerouslySetInnerHTML={{ __html: this.getBannerDescription(lang) }} />
                                                    </div>
                                                    <div className="promotion-home-banner-image">
                                                        <img src={this.props.bannerPreview.url} alt="Promo Banner" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}
export default injectIntl(I18nPromotionModal)
