import React from "react";
import ReactTable from "react-table";
import Translation from "../../global/Translation";
import {injectIntl} from "react-intl";
import ReactTablePaginationCount from './ReactTablePaginationCount';
import Button from "../../global/Button";
import { fetchDiscountCustomersData } from "../../../store/promotions/actions";
import { getToken } from "../../../store/login/reducers";
import axios from "axios";
import FormatCSVModal from "../../global/FormatCSVModal";
import {connect} from "react-redux";

class CustomersTable extends React.Component {

    state = { showCSVFormat: false }

    componentDidMount() {
        this.props.fetchDiscountCustomersData(this.props.campaign.id)
    }

    closeFormatCSVModal(format) {
        this.setState({ showCSVFormat: false })

        const FileDownload = require('js-file-download');
        axios.get(process.env.REACT_APP_API_HOST + `/promotions/get-discount-customers-data/${this.props.campaign.id}/csv?format=${format}`,
            {headers: 
                {Authorization: `Bearer ${this.props.token}`},
                'Content-Type': 'text/plain',
                responseType: 'blob'
            })
        .then((response) => {
            FileDownload(response.data, `customers_discount.${format}`);
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex align-items-center justify-content-end">
                    <Button inline size="small" icon="download" onClick={() => this.setState({ showCSVFormat: true })}>
                        <Translation id="download_excel" defaultMessage="Download Excel" />
                    </Button>
                </div>
                {this.state.showCSVFormat && (<FormatCSVModal onClose={(format) => this.closeFormatCSVModal(format)} token={() => this.props.token} />)}
                <ReactTable
                    className={'light'}
                    data={this.props.customers}
                    columns={[
                        {
                            id: 'id',
                            Header: <Translation id="id" defaultMessage="ID" />,
                            Cell: ({original}) => {return original.login}
                        },
                        {
                            id: 'name',
                            Header: <Translation id="name" defaultMessage="Name" />,
                            Cell: ({original}) => {return original.name}
                        },
                        {
                            id: 'market',
                            Header: <Translation id="market" defaultMessage="Market" />,
                            Cell: () => {return this.props.campaign.market}
                        },
                        {
                            id: 'country',
                            Header: <Translation id="country" defaultMessage="Country" />,
                            Cell: ({original}) => {
                                const country_id = 'country_' + original.country
                                return (this.props.intl.formatMessage({id:country_id}))
                            }
                        },
                        {
                            id: 'center',
                            Header: <Translation id="center" defaultMessage="Center" />,
                            Cell: ({original}) => {return original.center.id + ' - ' + original.center.name}
                        },
                    ]}
                    defaultPageSize={4}
                    pageSizeOptions={[4, 8, 10, 25, 50, 100]}
                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                    nextText={<Translation id="next" defaultMessage="Next" />}
                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                    pageText={<Translation id="page" defaultMessage="Page" />}
                    ofText={<Translation id="of" defaultMessage="Of" />}
                    PaginationComponent={ReactTablePaginationCount}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDiscountCustomersData: (promotionId) => dispatch(fetchDiscountCustomersData(promotionId))
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CustomersTable)
)
