import React from 'react'
import Translation from '../../global/Translation'

import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { injectIntl, FormattedMessage } from 'react-intl'

import Alerts from '../../alerts/Alerts'
import Button from '../../global/Button'
import confirm from '../../global/confirm'
import DatePicker from 'react-datepicker'
import LocalizedLink from '../../global/LocalizedLink'
import moment from 'moment'
import OrderFinishedContainer from '../../order-finished/OrderFinishedContainer'
import Select from 'react-select'
import { RadioButton } from '@progress/kendo-react-inputs'

class CheckoutFormIkeaV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectBoolean: true,
            validate: {
                installationDataName: false,
                installationDataAddress: false,
                installationDataTown: false,
                installationDataProvince: false,
                installationDataZipCode: false,
                installationDataPhone: false
            }
        }
    }

    componentDidMount() {
        this.props.resetCheckout().then(() => {
            this.props.setDeliveryDate(moment().add(1, 'days'))
            this.props.fetchShippingAddresses().then(() => {
                let shippingAddress = this.props.shippingAddresses.filter(shippingAddres => {
                    return shippingAddres.shippingAddressId === this.props.ikeaSelectedShippingAddress
                })[0]
                this.props.setSelectedShippingAddress(shippingAddress)
                this.props.setShippingAddressesEmails(this.props.profileConfiguration.shippingAddressesEmails)
                this.props.setEmployeeId(this.props.employeeId)
                this.props.setStoreId(this.props.storeId)
                this.props.setSelectedShippingAddressId(shippingAddress.shippingAddressId)
                this.props.setSelectedShippingCenterId(shippingAddress.centerId)
                this.props.setInstallationDataName('')
                this.props.setInstallationDataAddress('')
                this.props.setInstallationDataTown('')
                this.props.setInstallationDataProvince('')
                this.props.setInstallationDataZipCode('')
                this.props.setInstallationDataPhone('')
                this.props.setComments('')
            })
        })
        this.props.onLoadCheckout()
    }

    confirmSubmitCheckout = () => {
        if(this.canSendCheckout()){
            let shippingAddress = this.props.shippingAddresses.find(item => item.shippingAddressId === this.props.ikeaSelectedShippingAddress)
            confirm(
                <div className="confirm-checkout">
                    <div className="confirm-checkout_title">
                        <FormattedMessage id="please_confirm_the_order" defaultMessage="Please confirm the order" />:
                    </div>
                    <div className="confirm-checkout_sub-title">
                        <FormattedMessage id="ikea_store_store_name" defaultMessage="IKEA store: {storeName}" values={{ storeName: shippingAddress.customerName }} />
                    </div>
                    <div className="confirm-checkout_text">
                        <FormattedMessage
                            id="shipping_address_ikea"
                            defaultMessage="Shipping address: {shippingAddress}"
                            values={{ shippingAddress: shippingAddress.address + ', ' + shippingAddress.city }}
                        />
                    </div>
                </div>
            ).then(
                confirm => {
                    this.submitCheckout()
                },
                cancel => {
                    this.props.goCatalog(this.props.intl)
                }
            )
        }
    }
    submitCheckout = () => {
        let realComments =
            'Name: ' +
            this.props.installationDataName +
            ' // ' +
            'Address: ' +
            this.props.installationDataAddress +
            ' // ' +
            'Town: ' +
            this.props.installationDataTown +
            ' // ' +
            'Province: ' +
            this.props.installationDataProvince +
            ' // ' +
            'Zip Code: ' +
            this.props.installationDataZipCode +
            ' // ' +
            'Phone: ' +
            this.props.installationDataPhone +
            ' // ' +
            'Comments: ' +
            this.props.comments
        this.props.setComments(realComments)

        this.props.submitCheckout()
    }

    canSendIkea = () => {
        if(!this.props.installationDataName){
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataName: true}
            }))
        }else{
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataName: false}
            }))
        }
        if(!this.props.installationDataAddress){
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataAddress: true}
            }))
        }else{
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataAddress: false}
            }))
        }
        if(!this.props.installationDataTown){
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataTown: true}
            }))
        }else{
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataTown: false}
            }))
        }
        if(!this.props.installationDataProvince){
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataProvince: true}
            }))
        }else{
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataProvince: false}
            }))
        }
        if(!this.props.installationDataZipCode){
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataZipCode: true}
            }))
        }else{
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataZipCode: false}
            }))
        }
        if(!this.props.installationDataPhone){
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataPhone: true}
            }))
        }else{
            this.setState(prevState => ({
                validate: {...prevState.validate, installationDataPhone: false}
            }))
        }
        return (
            this.props.employeeId &&
            this.props.employeeId !== '' &&
            (this.props.ikeaTransportArea || this.props.userName !== '0000121085')
        )
    }
    canSendInstallationData = () => {
        let validateName = this.props.installationDataName && this.props.installationDataName !== ''
        let validateAddress = this.props.installationDataAddress && this.props.installationDataAddress !== ''
        let validateTown = this.props.installationDataTown && this.props.installationDataTown !== ''
        let validateProvince = this.props.installationDataProvince && this.props.installationDataProvince !== ''
        let validateZipCode = this.props.installationDataZipCode && this.props.installationDataZipCode !== ''
        let validatePhone = this.props.installationDataPhone && this.props.installationDataPhone !== ''

        return validateName && validateAddress && validateTown && validateProvince && validateZipCode && validatePhone
    }
    canSendCheckout = () => {
        const { totalQty } = this.props
        return this.canSendIkea() && this.canSendInstallationData() && !this.props.isSending && totalQty > 0
    }
    changeIkeaShippingAddress = value => {
        let shippingAddress = this.props.shippingAddresses.filter(shippingAddres => {
            return shippingAddres.shippingAddressId === value
        })[0]
        this.props.setSelectedShippingAddress(shippingAddress)
        this.props.changeIkeaShippingAddress(value)
    }
    getOptionsIkea = () => {
        let ikeaOptions = [
            { value: 'Y001', label: 'Canarias (Tenerife / G.Canaria)' },
            { value: 'Y002', label: 'Palma de Mallorca' },
            { value: 'Y003', label: 'Outside Palma de Mallorca' },
            { value: 'Y004', label: 'Menorca / Ibiza' }
        ]
        return ikeaOptions
    }
    getOptionsCountryOfSend = (selectedCenterData, filteredShippingAddressesForUsers) => {
        if (this.props.userType === 'CUSTOMER' && filteredShippingAddressesForUsers)
            return filteredShippingAddressesForUsers.map(shippingAddress => ({
                value: shippingAddress.shippingAddressId,
                label:
                    shippingAddress.centerName +
                    ' ' +
                    shippingAddress.customerName +
                    ' ' +
                    shippingAddress.address +
                    ' ' +
                    shippingAddress.zipCode +
                    ' ' +
                    shippingAddress.city +
                    ' ' +
                    shippingAddress.country
            }))
        else return []
    }

    render() {
        const { shippingAddresses, shippingAddressesCenters, shippingMethod } = this.props
        const {selectBoolean} = this.state

        //allowedShippingTypes for users
        let filteredShippingAddressesForUsers = shippingAddresses
            ? shippingAddresses.filter(shippingAddress => {
                  return shippingAddress.allowedShippingTypes.includes(shippingMethod)
              })
            : []

        let selectedCenterData = shippingAddressesCenters
            ? shippingAddressesCenters.find(center => {
                  return center.id === this.props.selectedShippingCenterId
              })
            : null
        const sendText = <Translation id="checkout" defaultMessage="Checkout" />

        // const submit = this.canSendCheckout() ? (
        //     <Button className='bt bt-inverted next w-100' inverted datacy="checkout__send_checkout" onClick={() => this.confirmSubmitCheckout()}>
        //         {sendText}
        //     </Button>
        // ) : (
        //     <Button className='bt bt-inverted next w-100' inverted disabled>
        //         {sendText}
        //     </Button>
        // )
        const submit = <Button className='bt bt-inverted next w-100' inverted datacy="checkout__send_checkout" onClick={() => this.confirmSubmitCheckout()}>
                {sendText}
            </Button>
        if (this.props.isLoadingShippingAddress) return <Translation id="loading_shipping_addresses" defaultMessage="Loading Shipping Addresses" />

        return (
            <div className="checkout-form--ikea checkout-address-box">
                <h1 className='shipping_information'>
                    <Translation id='shipping_information' defaultMessage='Shipping information' />
                </h1>
                <hr/>
                <form>
                    <Alerts alert={this.props.alert} status={this.props.status} />

                    <RadioButton onClick={() => this.setState({selectBoolean: true})} label={this.props.intl.formatMessage({ id: 'my_default_addresses' })} name="my_addresses" value="1" checked={selectBoolean} />
                    {selectBoolean && (
                        <div className="form-group select">
                        {/*<label htmlFor="input-date">*/}
                        {/*    {this.props.shippingMethod === 'CONTAINER' ? (*/}
                        {/*        <Translation id="shipping_address_for_container" defaultMessage="Final Destination" />*/}
                        {/*    ) : (*/}
                        {/*        <React.Fragment>*/}
                        {/*            <Translation id="shipping_address" defaultMessage="Shipping Address" /> <span style={{ color: 'red' }}>*</span>*/}
                        {/*        </React.Fragment>*/}
                        {/*    )}*/}
                        {/*</label>*/}
                        <Select
                            id="checkout__select_shipping_address-ikea"
                            className="form-control"
                            disabled
                            placeholder={`${this.props.intl.formatMessage({ id: 'select_shipping_address' })}...`}
                            value={this.props.ikeaSelectedShippingAddress}
                            onChange={e => {
                                e && this.changeIkeaShippingAddress(e.value)
                            }}
                            options={this.getOptionsCountryOfSend(selectedCenterData, filteredShippingAddressesForUsers)}
                            clearable={false}
                        />

                        {this.props.shippingAddressesEmails && !this.props.shippingAddressesEmails[this.props.ikeaSelectedShippingAddress] && (
                            <div className="tip red-tip">
                                <LocalizedLink routeId={'ROUTE_PROFILE'} params={{ filter: 'configuration', open: '' }}>
                                    <Translation
                                        id="this_IKEA_store_doesnt_have_email_please_configure_it"
                                        defaultMessage="This IKEA store does not have an email address, please add it on your personal area"
                                    />
                                </LocalizedLink>
                            </div>
                        )}
                    </div>
                    )}

                    {/*<div className="form-group select_date">*/}
                    {/*    <label htmlFor="input-date">*/}
                    {/*        <Translation id="required_delivery_date" defaultMessage="Required delivery date" />*/}
                    {/*    </label>*/}
                    {/*    <DatePicker*/}
                    {/*        selected={this.props.deliveryDate}*/}
                    {/*        onChange={this.props.setDeliveryDate}*/}
                    {/*        minDate={moment().add(1, 'days')}*/}
                    {/*        className="form-control"*/}
                    {/*        autoComplete="off"*/}
                    {/*        onKeyDown={e => e.preventDefault()}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="form-group">
                        {/*<label htmlFor="input-employee-id">*/}
                        {/*    <Translation id="insert_employee_id" defaultMessage="Insert your employee ID" /> <span style={{ color: 'red' }}>*</span>*/}
                        {/*</label>*/}
                        <input
                            id="input-employee-id"
                            className="form-control is-disabled"
                            disabled
                            value={this.props.employeeId}
                            type="hidden"
                            maxLength="8"
                            onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) e.preventDefault()
                            }}
                        />
                    </div>

                    {this.props.userName === '0000121085' && (
                        <div className="form-group">
                            <label htmlFor="input-date">
                                <Translation id="transport_area" defaultMessage="Transport Area" /> <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Select
                                className="form-control"
                                placeholder={`${this.props.intl.formatMessage({ id: 'select_transport_area' })}...`}
                                value={this.props.ikeaTransportArea}
                                onChange={e => {
                                    e && this.props.setIkeaTransportArea(e.value)
                                }}
                                options={this.getOptionsIkea()}
                                clearable={false}
                            />
                        </div>
                    )}
                    <h1 className='shipping_information mt-3'>
                        <Translation id="installation_data" defaultMessage="Installation Data" />
                    </h1>
                    <hr/>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="input-installation-data-name-id">
                                    <Translation id="first_name_last_name" defaultMessage="First name and last name" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="name"
                                    required
                                    id="input-installation-data-name-id"
                                    className="form-control"
                                    value={this.props.installationDataName}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setInstallationDataName(e.target.value)
                                    }}
                                />
                                {this.state.validate['installationDataName'] && (
                                    <span className='alert_empty'><Translation id='alert_input_empty' defaultMessage='This field is required' /></span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="input-installation-data-address-id">
                                    <Translation id="address" defaultMessage="Address" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="address"
                                    required
                                    id="input-installation-data-address-id"
                                    className="form-control"
                                    value={this.props.installationDataAddress}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setInstallationDataAddress(e.target.value)
                                    }}
                                />
                                {this.state.validate['installationDataAddress'] && (
                                    <span className='alert_empty'><Translation id='alert_input_empty' defaultMessage='This field is required' /></span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="input-installation-data-town-id">
                                    <Translation id="town" defaultMessage="Town" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="town"
                                    required
                                    id="input-installation-data-town-id"
                                    className="form-control"
                                    value={this.props.installationDataTown}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setInstallationDataTown(e.target.value)
                                    }}
                                />
                                {this.state.validate['installationDataTown'] && (
                                    <span className='alert_empty'><Translation id='alert_input_empty' defaultMessage='This field is required' /></span>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="input-installation-data-province-id">
                                    <Translation id="province" defaultMessage="Province" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="province"
                                    required
                                    id="input-installation-data-province-id"
                                    className="form-control"
                                    value={this.props.installationDataProvince}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setInstallationDataProvince(e.target.value)
                                    }}
                                />
                                {this.state.validate['installationDataProvince'] && (
                                    <span className='alert_empty'><Translation id='alert_input_empty' defaultMessage='This field is required' /></span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="input-installation-data-zip-code-id">
                                    <Translation id="zipcode" defaultMessage="Zip Code" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="zip-code"
                                    required
                                    id="input-installation-data-zip-code-id"
                                    className="form-control"
                                    value={this.props.installationDataZipCode}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setInstallationDataZipCode(e.target.value)
                                    }}
                                />
                                {this.state.validate['installationDataZipCode'] && (
                                    <span className='alert_empty'><Translation id='alert_input_empty' defaultMessage='This field is required' /></span>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="input-installation-data-phone-id">
                                    <Translation id="phone" defaultMessage="Phone" /> <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="phone"
                                    required
                                    id="input-installation-data-phone-id"
                                    className="form-control"
                                    value={this.props.installationDataPhone}
                                    type="text"
                                    onChange={e => {
                                        e && this.props.setInstallationDataPhone(e.target.value)
                                    }}
                                />
                                {this.state.validate['installationDataPhone'] && (
                                    <span className='alert_empty'><Translation id='alert_input_empty' defaultMessage='This field is required' /></span>
                                )}
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className="form-group">
                        <label htmlFor="input-po">
                            <Translation id="insert_po" defaultMessage="Insert your PO#" />
                        </label>
                        <input
                            data-cy="checkout__input_po"
                            type="text"
                            className="form-control"
                            id="input-po"
                            onChange={e => {
                                e && this.props.setPo(e.target.value)
                            }}
                        />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="input-comments">
                                    <Translation id="comment" defaultMessage="Comments" />
                                </label>
                                <textarea
                                    data-cy="checkout__textarea_comment"
                                    className="form-control"
                                    // value={this.props.comments} //prevent show merge the values of the installation data
                                    id="input-comments"
                                    rows="3"
                                    maxlength="2500"
                                    onChange={e => {
                                        e && this.props.setComments(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div className="row align-items-baseline">
                    <div className="col-12" style={{ paddingLeft: '35px', paddingBottom: '10px' }} />
                    {/*
                        <div className="col-12">
                            <div className="d-flex justify-content-end">{submit}</div>
                        </div>*/
                    }
                </div>
                {this.props.reservationCode && <OrderFinishedContainer />}
            </div>
        )
    }
}

export default CheckoutFormIkeaV2
