import React from "react"
import Translation from "../global/Translation"

/** @type {object} */
export const rejectionCodeMap = {
    '400': <Translation id="400_rejection_code" defaultMessage="Good discontinued" />,
    '401': <Translation id="401_rejection_code" defaultMessage="Bad thickness" />,
    '402': <Translation id="402_rejection_code" defaultMessage="Fissure" />,
    '403': <Translation id="403_rejection_code" defaultMessage="Holes / pores" />,
    '404': <Translation id="404_rejection_code" defaultMessage="Oxide" />,
    '405': <Translation id="405_rejection_code" defaultMessage="Excess resin" />,
    '406': <Translation id="406_rejection_code" defaultMessage="Grated" />,
    '407': <Translation id="407_rejection_code" defaultMessage="Bad shade / stains" />,
    '408': <Translation id="408_rejection_code" defaultMessage="Half board (leftover)" />,
}

/**
 * Get the rejection code of each bundle.
 * @param {object} lot 
 * @param {array} bundlesRejectionCodes 
 * @returns {(string|null)} rejectionCode
 */
export const getBundleRejectionCode = (lot, bundlesRejectionCodes) => {
    let rejectionCode = null
    if (lot && lot.bundleId && bundlesRejectionCodes && bundlesRejectionCodes.length > 0) {
        rejectionCode = bundlesRejectionCodes.filter((bundle) => bundle.bundleId === lot.bundleId)
        return rejectionCode[0].rejectionCode
    }
    return rejectionCode
}