import React from 'react'
import PropTypes from 'prop-types';

import { injectIntl} from 'react-intl'
import { getTranslateMode } from '../../store/ui/reducers'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        translateMode: getTranslateMode(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        openModalTranslation: (idTranslation, defaultMessageTranslation) => {
            dispatch({ type: 'OPEN_MODAL_TRANSLATION', idTranslation, defaultMessageTranslation })
        }
    }
}

class Translation extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                {this.props.translateMode && (
                    <i
                        className="fal fa-language"
                        style={{ margin: '5px', cursor: 'pointer', color: 'black', textShadow: '0px 0px 2px white' }}
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.props.openModalTranslation(this.props.id, this.props.defaultMessage)
                        }}
                    />
                )}
                {/*<FormattedHTMLMessage id={this.props.id} defaultMessage={this.props.defaultMessage} values={this.props.values}/>*/}
                {this.props.intl.formatMessage({ id: this.props.id, defaultMessage: this.props.defaultMessage }, this.props.values ? this.props.values : {})}
            </React.Fragment>
        )
    }
}

Translation.propTypes = {
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Translation)
)
