import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Route, withRouter} from "react-router-dom";
import {getLocalizedUrl} from "./store/ui/reducers";
import Error404Screen from "./screens/Error404Screen";
import {getLastSelectedProduct} from "./store/selected-objects/reducers";
import {getIsIkeaCenter, getIsMkToolsCenter} from "./store/centers/reducers";
import {getCommonParams} from "./store/login/reducers";
import {byProductId} from "./store/products/reducers";



const PageViewRoute = (props) => {

    const [pageType, setPageType] = useState('')
    const [pageSection, setPageSection] = useState('')
    const [pageSubsection, setPageSubsection] = useState('')

    const getUrlType = () => {
        const regex = /[?]type\[0]=([^&]*)/
        const type = regex.exec(props.location.search)
        return type && type[1].toLowerCase()
    }

    const getCtsUrlType = () => {
        const regex = /[?]type=([^&]*)/
        const type = regex.exec(props.location.search)
        return type && type[1].toLowerCase()
    }

    const getUrlBrand = () => {
        const regex = /[&|?]brand\[0]=([^&]*)/
        const type = regex.exec(props.location.search)
        return type && type[1].toLowerCase()
    }

    const getUrlSubtype = () => {
        const regex = /&subtype\[0]=([^&]*)/
        const type = regex.exec(props.location.search)
        return type && type[1].toLowerCase()
    }

    const getUrlSection = () => {
        const regex = /\/([a-z0-9_-]*[\/]?)$/g
        const type = regex.exec(props.location.pathname)
        return type && type[1].toLowerCase()
    }
    const getUrlAreaPersonalSection = () => {
        if (props.location.pathname.includes('view-order')) return 'view-order'
        if (props.location.pathname.includes('view-shipping')) return 'view-shipping'
        if (props.location.pathname.includes('view-order')) return 'view-order'

        return getUrlSection()
    }

    const getWebHeading = () => {
        const regex = /webHeading\[0]=([^&]*)/
        const type = regex.exec(props.location.search)
        return type && type[1].toLowerCase()
    }

    const urlHasMoreThanOneType = (times=2) => {
        const regex = RegExp(`([?&]type\\[[0-9]].*){${times},}`)
        return regex.test(props.location.search)
    }



    const setPageViewEvent = () => {
        const {localizedUrl, path, location, selectedMaterial, commonParams, isLogged} = props

        if (isLogged && !commonParams.userId) return

        switch (path) {
            case localizedUrl('ROUTE_HOME'):
                setPageType('home')
                setPageSection('home')
                setPageSubsection('home')
                break

            case localizedUrl('ROUTE_CATALOG'):
                setPageType('listado_de_producto')
                setPageSections('listado_de_producto')
                break

            case localizedUrl('ROUTE_CUT_TO_SIZE'):
                if (location.search) {
                    setPageType('detalle_de_producto')
                    let ctsSection = getCtsUrlType()
                    if (ctsSection === 'preorder') ctsSection = 'pre_order'
                    if (ctsSection === 'budget') ctsSection = 'presupuesto`'
                    setPageSubsection(ctsSection)
                }
                else {
                    setPageType('listado_de_producto')
                    setPageSubsection('productos_a_medida')
                }
                setPageSection('productos_a_medida')
                break

            case localizedUrl('ROUTE_LOTS'):
                setPageType('listado_de_producto')
                setPageSection('lotes')
                setPageSubsection('lotes')
                break

            case localizedUrl('ROUTE_LOT_IMAGE_VIEWER'):
                setPageType('detalle_de_producto')
                setPageSection('lotes')
                setPageSubsection('lotes')
                break

            case localizedUrl('ROUTE_PRODUCT_DETAIL'):
                if (selectedMaterial) {
                    if (selectedMaterial.fromMall) {
                        setPageType('detalle_de_producto')
                        setPageSection('landing')
                        setPageSubsection('mall_days')
                    } else {
                        setPageSections('detalle_de_producto')
                    }
                }
                break

            case localizedUrl('ROUTE_PROFILE'):
                setPageType('area_personal')
                setPageSection('area_personal')
                setPageSections('area_personal')
                break

            case localizedUrl('ROUTE_COOKIES'):
                setPageType('informativa')
                setPageSection('politica_cookies')
                setPageSubsection('politica_cookies')
                break

            case localizedUrl('ROUTE_PRIVACY'):
                setPageType('informativa')
                setPageSection('politica_privacidad')
                setPageSubsection('politica_privacidad')
                break

            case localizedUrl('ROUTE_LANDING_TEMPLATE'):
                setPageType('listado_de_producto')
                setPageSection('landing')
                setPageSubsection('mall_days')
                break

            case localizedUrl('ROUTE_PROMOTION_LANDING'):
                setPageType('listado_de_producto')
                setPageSection('landing')
                setPageSubsection('campañas_locales')
                break

            case localizedUrl('ROUTE_CHECKOUT'):
                setPageType('proceso_de_compra')
                setPageSection('proceso_de_compra')
                setPageSections('carrito')
                break



            default:
                // setPageType('S/E')
        }
    }

    const setPageSections = (pageType) => {
        switch (pageType) {
            case  'home':
                break

            case 'listado_de_producto': {
                let section = getUrlSection()
                let type = getUrlType()
                let brand = getUrlBrand()
                let subtype = getUrlSubtype()

                if (props.isMktools) {
                    setPageSection('mk_tools')
                    const subSect = getWebHeading() === 'sh' ? 'showroom' : 'mk_tools'
                    setPageSubsection(subSect)
                } else if (props.isIkea) {
                    setPageSection('ikea')
                    setPageSubsection('ikea')
                } else if (section === 'outlet') {
                    if (!brand) brand = 'outlet'
                    setPageSection('outlet')
                    setPageSubsection(brand)
                } else if (type === 'ppcc') {
                    if (!subtype) subtype = 'ppcc'
                    setPageSection('ppcc')
                    setPageSubsection(subtype)
                } else {
                    const hasMoreThanOneType = urlHasMoreThanOneType()
                    if (type === 'fregadero' || type === 'baños' || type === 'ba%c3%91os' || hasMoreThanOneType) {
                        setPageSection('productos_estandar')
                        if (urlHasMoreThanOneType(3)) setPageSubsection('productos_estandar')
                        else if (type === 'fregadero') setPageSubsection('fregadero')
                        else if (subtype === 'soleria') setPageSubsection('soleria_aplacado')
                        else if (subtype === 'shower%20tray') setPageSubsection('platos_ducha')
                        else if (subtype === 'washbasin') setPageSubsection('lavabos')
                        else setPageSubsection('productos_estandar')
                    } else {
                        if (!brand) brand = type
                        setPageSection(type)
                        setPageSubsection(brand)
                    }
                }
                break
            }

            case 'detalle_de_producto': {
                let section = ''
                let subsection = ''

                if (props.selectedMaterial.type === 'PPCC') {
                    section = 'ppcc'
                    switch (props.selectedMaterial.subtypeId) {
                        case 'COLORSIL':
                        case 'SOLUMASTIK':
                        case 'COLORDEK':
                        case 'MASTIDEK':
                            subsection = props.selectedMaterial.subtypeId
                            break
                        case 'ABRASIVO':
                            subsection = 'abrasive'
                            break
                        default:
                            subsection = 'others_ppcc'
                    }
                } else if (props.selectedMaterial.subtypeId === 'SOLERIA' || props.selectedMaterial.subtypeId === 'OPTIMMA') {
                    section = 'productos_estandar'
                    subsection = 'soleria_aplacado'
                } else if (props.selectedMaterial.type === 'FREGADERO') {
                    section = 'productos_estandar'
                    subsection = 'fregadero'
                } else if (props.selectedMaterial.subtypeId === 'LAVABO') {
                    section = 'productos_estandar'
                    subsection = 'lavabos'
                } else if (props.selectedMaterial.subtypeId === 'PLATO DUCHA') {
                    section = 'productos_estandar'
                    subsection = 'platos_ducha'
                } else  if (getUrlSection() === 'outlet') {
                    section = 'outlet'
                    subsection = props.selectedMaterial.brand
                } else if (props.selectedMaterial.type === 'TABLA') {
                    section = 'tablas'
                    subsection = props.selectedMaterial.brand
                }

                setPageType('detalle_de_producto')
                setPageSection(section.toLowerCase())
                setPageSubsection(subsection.toLowerCase())
                break
            }

            case 'area_personal': {
                let section = getUrlAreaPersonalSection()

                switch (section) {
                    case 'configuration':
                        setPageSubsection('configuracion')
                        break
                    /* case 'orders':
                        setPageType('listado_de_pedido_v1')
                        setPageSection('area_personal_v1')
                        setPageSubsection('historial_pedidos_v1')
                        break */
                    /* case 'view-order':
                        setPageType('detalle_de_pedido_v1')
                        setPageSection('area_personal_v1')
                        setPageSubsection('historial_pedidos_v1')
                        break */
                    case 'orders':
                        setPageType('listado_de_pedido')
                        setPageSubsection('historial_pedidos')
                        break
                    case 'view-order':
                        setPageType('detalle_de_pedido')
                        setPageSubsection('historial_pedidos')
                        break
                    case 'shipping-history':
                        setPageType('listado_de_envios')
                        setPageSubsection('historial_envios')
                        break
                    case 'view-shipping':
                        setPageType('detalle_de_envios')
                        setPageSubsection('historial_envios')
                        break
                    case 'pre-orders-cts':
                        setPageSubsection('pre_order_cts')
                        break
                    case 'recurring-orders':
                        setPageSubsection('volver_a_comprar')
                        break
                    case 'segmentation':
                        setPageSubsection('segmentacion')
                        break
                    case 'products-favourite':
                        setPageSubsection('productos_favoritos')
                        break
                    default:
                        setPageType(null)
                        setPageSection(null)
                        setPageSubsection(null)
                }
                break
            }

            case 'carrito':
                let section = getUrlSection()
                switch (section) {
                    case 'address':
                        setPageSubsection('direccion_envio')
                        break
                    case 'thankyou':
                        setPageSubsection('confirmacion_compra')
                        break
                    default:
                        setPageSubsection('carrito')
                }

            default:
                pageType = 'S/E'
        }
    }

    useEffect(() => {
        if (props.localizedUrl('ROUTE_PRODUCT_DETAIL') && !pageSection && !pageSubsection && props.selectedMaterial) {
            setPageSections('detalle_de_producto')
        }
    }, [props.selectedMaterial && props.selectedMaterial.productId])

    useEffect(() => {

        if (pageType && pageSection && pageSubsection) {
            // console.log('PAEGE VIEW pageType >', pageType)
            // console.log('PAEGE VIEW pageSection >', pageSection)
            // console.log('PAEGE VIEW pageSubsection >', pageSubsection)
            // console.log('PAEGE VIEW ****************************************')

            props.sendAnalytics(pageType, pageSection, pageSubsection)
        }
    }, [
        pageType,
        pageSection,
        pageSubsection,
        props.commonParams.userId,
        props.commonParams.idCenter,
        props.commonParams.idioma
    ])

    useEffect(() => {
        if (props.registerAnalytics) {
            setPageViewEvent()
        }
    }, [])

    useEffect(() => {
        if (props.registerAnalytics) {
            setPageViewEvent()
        }
    }, [
        props.location.pathname,
        props.location.search,
        props.commonParams.userId,
        props.commonParams.idCenter,
        props.commonParams.idioma,
        props.isLogged
    ])

    return props.showErrorScreen ?
        <Error404Screen />
        :
        <Route {...props} render={_props => <props.component {..._props} /> } />

    return <Error404Screen />
}

const getSku = (pathname) => {
    const regex = /\/([0-9]*)(\/?|(\/.*))?$/g
    const type = regex.exec(pathname)
    return type && type[1].toLowerCase()
}

const mapStateToProps = (state, props) => {
    const lastSelectedMaterial = getLastSelectedProduct(state)

    return {
        localizedUrl: (routeId) => getLocalizedUrl(state, props.intl, routeId),
        selectedMaterial: lastSelectedMaterial ? lastSelectedMaterial : byProductId(state, getSku(props.location.pathname)),
        isMktools: getIsMkToolsCenter(state),
        isIkea: getIsIkeaCenter(state),
        commonParams: getCommonParams(state),
        isLogged: state.login.isLogged
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendAnalytics: (pageType, section, subsection) => dispatch({type: 'CHANGE_ANALYTICS_URL', pageType, section, subsection})
    }
}

export default withRouter(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(PageViewRoute)
    )
)