import React from 'react'
import cosentinoGlobal from '../../../assets/img/logos/HQ-icon.svg'

const CosentinoGlobalIndicator = () => {
    return (
        <div className='cosentino-global-indicator' >
            <img src={cosentinoGlobal} alt="Cosentino Global" />
        </div>
    )
}

export default CosentinoGlobalIndicator;
