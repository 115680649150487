import * as api from '../api'
import { getLanguage } from '../ui/reducers'
import { getCenterData, getIsEndiCenter, getSelectedCenter } from '../centers/reducers'
import { getToken } from '../login/reducers'
import { getShippingAddresses, getFetchedOn, getShippingAddressFilteredToSelectByCenter } from './reducers'
import { fetchCart } from '../cart/actions'
import { setSelectedShippingAddressEcosentinoKey } from '../checkout/actions'
import { getSelectedShippingAddress } from '../checkout/reducers'

export const fetchShippingAddresses = () => (dispatch, getState) => {
    if (getShippingAddresses(getState()) && getFetchedOn(getState()) === getSelectedCenter(getState())) return Promise.resolve() //AVOID REFECTH SHIPPING ADDRES WHILE STILL ON SAME CENTER
    let token = getToken(getState())
    dispatch({ type: 'FETCH_SHIPPING_ADDRESSES_REQUEST', token })

    return api
        .fetchShippingAddresses(token, getSelectedCenter(getState()), getLanguage(getState()))
        .then(response => {
            dispatch({ type: 'FETCH_SHIPPING_ADDRESSES_SUCCESS', data: response.data, fetchedOn: getSelectedCenter(getState()) })
            if (getIsEndiCenter(getState())) {
                let shippingAddressFilteredToSelectByCenter = getShippingAddressFilteredToSelectByCenter(getState())
                if (shippingAddressFilteredToSelectByCenter.length === 1) {
                    dispatch(changeEndiShippingAddress(shippingAddressFilteredToSelectByCenter[0].value))
                } else {
                    dispatch({ type: 'REMEMBER_SELECT_SHIPPING_ADDRESS_ENDI' })
                }
            }
        })
        .catch(error => {
            dispatch({ type: 'FETCH_SHIPPING_ADDRESSES_FAILURE' })
            api.errorHandler(dispatch, error.response)
        })
}
export const changeIkeaShippingAddress = shippingAddressId => (dispatch, getState) => {
    dispatch({
        type: 'CHANGE_IKEA_SHIPPING_ADDRESS',
        shippingAddressId: shippingAddressId
    })
    dispatch(fetchCart())
    dispatch({
        type: 'CLEAR_FETCHED_ORDERS'
    })
}

export const changeEndiShippingAddress = shippingAddressEcosentinoKey => (dispatch, getState) => {
    let shippingAddress = getShippingAddresses(getState()).find(shippingAddress => {
        return shippingAddress.ecosentinoKey === shippingAddressEcosentinoKey
    })
    
    let shippingTypes = getCenterData(getState()).shippingTypes
            let isAllowed = false
            let allowedShippingTypesForThisAddress = shippingTypes.filter(shippingType => {
                return (shippingAddress.allowedShippingTypes.includes(shippingType))
            })
    let endiSelectedShippingType = allowedShippingTypesForThisAddress ? allowedShippingTypesForThisAddress[0] : null
    dispatch({ type: 'CHANGE_ENDI_SHIPPING_ADDRESS', shippingAddressEcosentinoKey, endiSelectedShippingType })
    dispatch({ type: 'CHANGE_SHIPPING_METHOD', shippingMethod: endiSelectedShippingType })
}
