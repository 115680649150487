import * as api from '../api'
import { getLanguage } from '../ui/reducers'

export const fetchOrderDeliveriesPaginate = (status, startDate, endDate, search, type, page) => (dispatch, getState) => {
    const token = getState().login.token
    const locale = getLanguage(getState())
    dispatch({type: 'FETCH_ORDER_DELIVERIES_PAGINATE_REQUEST'})
    
    return api.fetchOrderDeliveriesPaginate(token, status, startDate, endDate, search, locale, type, page).then(
        response => dispatch({
            type: 'FETCH_ORDER_DELIVERIES_PAGINATE_SUCCESS',
            deliveries: response.data.data.data,
            deliveriesCurrentPage: response.data.data.current_page,
            deliveriesLastPage: response.data.data.last_page
        }),
        error => dispatch({
            type: 'FETCH_ORDER_DELIVERIES_PAGINATE_FAILURE',
            statusText: error.response.data.err
        })
    )
}
