import React from 'react'

const TipModal = props => {
    const { title, children, onClose, customClass, showBody = true, color = 'white', borderColor = '#bccbd2' } = props

    let dialogClasses = ''

    if (customClass) {
        dialogClasses += ' ' + customClass
    }

    return (
        <div className="tip-modal fade show" tabIndex="-1" role="dialog" data-backdrop="static">
            
            <svg height="16" width="32" className="tip-modal-arrow">
                <polygon points="0,16 16,0 32,16" style={{fill:color}} />
                {/* <polyline points="0,16 16,0 32,16" style={{fill:'none',strokeWidth:1,stroke:borderColor}} /> */}
            </svg>

            <div className={'tip-modal-dialog' + dialogClasses} role="document" 
                style={{background:color,/*border:`1px solid ${borderColor}`*/}}
            >
                <div className="tip-modal-content">  
                    <i className="fas fa-times tip-modal-close" onClick={onClose} aria-label="Close"/>  
                    <div className="tip-modal-header">{title}</div>
                    <div className="tip-modal-header-separator"/>
                    {showBody && <div className="tip-modal-body">{children}</div>}
                </div>
            </div>

            <svg height="16" width="32" className="tip-modal-arrow">
                <polygon points="0,16 16,0 32,16" style={{fill:color}} />
                {/* <polyline points="0,16 16,0 32,16" style={{fill:'none',strokeWidth:1,stroke:borderColor}} /> */}
            </svg>
            
        </div>
    )
}

export default TipModal
