import { connect } from 'react-redux'

import { getSalesOrg } from '../../store/login/reducers'

import Promotions from './Promotions'

const mapStateToProps = (state, { match }) => {
    return {
        salesOrg: getSalesOrg(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

const PromotionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Promotions)

export default PromotionsContainer
