import {
    getHQCenterId,
    getHUBHoustonCenterId,
    getHUBNorfolkCenterId,
    getHUBSydneyCenterId,
    getIKEACenterId,
    getLatinaDepot,
    getLatinaVitoriaCenterId,
    getNaturalStoneSpainCenterId, getSelectedCenter, getIsIkeaCenter } from '../centers/reducers'
import { getImpersonatedBy, getUserName } from '../login/reducers'
import { createSelector } from 'reselect'
import { getKgPoundRatio } from '../cart/reducers'

export const initState = {
    ordersCurrentPage: 1,
    ordersLastPage: 1,
    orders: [],
    openOrders: [],
    transferOrders: [],
    orderDetails: [],
    orderDetailsHead: null,
    order: null,
    openDocDetails: [],
    packingList: null,
    recurringOrders: [],
    recurringOrderDetails: [],
    selectedRecurringOrder: null,
    showRecurringOrderDetails: false,
    isLoading: false,
    isLoadingDetails: false,
    isLoadingPackingList: false,
    lowesOrders: [],
    lowesLinesOrders: [],
    lowesOrderDetails: null,
    isOrderToEditLoading: false,
    orderToEdit: [],
    orderInEdition: [],
    invoices: [],
    showModal: false,
    activeOrderLine: null,
    error: null,
    fetchingPackagingImages: false,
    packagingImages: null
}
const orders = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_OFFERS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_OFFERS_SUCCESS':
            return Object.assign({}, state, {
                offers: action.offers,
                isLoading: false
            })
        case 'FETCH_OFFERS_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_ORDERS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_ORDERS_SUCCESS':
            return Object.assign({}, state, {
                orders: action.orders,
                ordersCurrentPage: action.ordersCurrentPage,
                ordersLastPage: action.ordersLastPage,
                isLoading: false
            })
        case 'FETCH_ORDERS_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'CLEAR_FETCHED_ORDERS':
            return Object.assign({}, state, {
                orders: [],
                ordersCurrentPage: null,
                ordersLastPage: null,
                isLoading: false
            })
        case 'FETCH_ORDERS_PAGINATE_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_ORDERS_PAGINATE_SUCCESS':
            return Object.assign({}, state, {
                orders: state.orders.concat(action.orders),
                ordersCurrentPage: action.ordersCurrentPage,
                ordersLastPage: action.ordersLastPage,
                isLoading: false
            })
        case 'FETCH_ORDERS_PAGINATE_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_OPEN_ORDERS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_OPEN_ORDERS_SUCCESS':
            return Object.assign({}, state, {
                openOrders: action.openOrders,
                isLoading: false
            })
        case 'FETCH_TRANSFER_ORDERS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_TRANSFER_ORDERS_SUCCESS':
            return Object.assign({}, state, {
                transferOrders: action.transferOrders,
                isLoading: false
            })
        case 'FETCH_TRANSFER_ORDERS_FAILURE':
        case 'FETCH_OPEN_ORDERS_FAILURE':
        case 'FETCH_ORDER_DETAILS_REQUEST':
            return Object.assign({}, state, {
                isLoadingDetails: true
            })
        case 'FETCH_ORDER_DETAILS_FAILURE':
            return Object.assign({}, state, { isLoadingDetails: false, error: action.response })
        case 'FETCH_ORDER_DETAILS_SUCCESS':
            return Object.assign({}, state, {
                orderDetailsHead: action.orderDetailsHead,
                openDocDetails: action.openDocDetails,
                order: action.order,
                invoices: action.invoices,
                isLoadingDetails: false
            })
        case 'CLEAR_ORDER_DETAILS':
            return Object.assign({}, state, {
                packingList: null,
                orderDetailsHead: null
            })
        case 'FETCH_OFFER_OVERVIEW_REQUEST':
        case 'FETCH_PACKING_LIST_REQUEST':
            return Object.assign({}, state, {
                isLoadingPackingList: true,
                packingList: null
            })
        case 'FETCH_OFFER_OVERVIEW_FAILURE':
        case 'FETCH_PACKING_LIST_FAILURE':
            return Object.assign({}, state, { isLoadingPackingList: false, error: action.response })
        case 'FETCH_OFFER_OVERVIEW_SUCCESS':
        case 'FETCH_PACKING_LIST_SUCCESS':
            return Object.assign({}, state, {
                isLoadingPackingList: false,
                packingList: action.packingList
            })
        case 'FETCH_RECURRING_ORDERS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_RECURRING_ORDERS_SUCCESS':
            return Object.assign({}, state, {
                recurringOrders: action.recurringOrders,
                isLoading: false
            })
        case 'FETCH_RECURRING_ORDERS_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_RECURRING_ORDERS_DETAILS_REQUEST':
            return Object.assign({}, state, { isLoadingDetails: true })
        case 'FETCH_RECURRING_ORDERS_DETAILS_SUCCESS':
            return Object.assign({}, state, {
                recurringOrderDetails: action.recurringOrders,
                isLoadingDetails: false
            })
        case 'FETCH_RECURRING_ORDERS_DETAILS_FAILURE':
            return Object.assign({}, state, { isLoadingDetails: false })
        case 'ADD2CART_RECURRING_ORDERS_DETAILS_REQUEST':
            return Object.assign({}, state, { isLoadingAdd: true })
        case 'ADD2CART_RECURRING_ORDERS_DETAILS_SUCCESS':
            return Object.assign({}, state, { isLoadingAdd: false })
        case 'ADD2CART_RECURRING_ORDERS_DETAILS_FAILURE':
            return Object.assign({}, state, { isLoadingAdd: false })
        case 'OPEN_RECURRING_ORDERS_DETAILS':
            return Object.assign({}, state, {
                showRecurringOrderDetails: true,
                selectedRecurringOrder: action.selectedRecurringOrder
            })
        case 'CLOSE_RECURRING_ORDERS_DETAILS':
            return Object.assign({}, state, { showRecurringOrderDetails: false })
        case 'SAVE_ORDER_TO_EDIT_REQUEST':
        case 'FETCH_ORDER_TO_EDIT_REQUEST':
            return Object.assign({}, state, { isOrderToEditLoading: true })
        case 'SAVE_ORDER_TO_EDIT_SUCCESS':
        case 'FETCH_ORDER_TO_EDIT_SUCCESS':
            return Object.assign({}, state, {
                orderToEdit: action.orderToEdit,
                orderInEdition: action.orderToEdit,
                isOrderToEditLoading: false
            })
        case 'CHANGE_ORDER_IN_EDITION':
            return Object.assign({}, state, {
                orderInEdition: action.orderInEdition
            })
        case 'SAVE_ORDER_TO_EDIT_FAILURE':
        case 'FETCH_ORDER_TO_EDIT_FAILURE':
            return Object.assign({}, state, { isOrderToEditLoading: false })

        case 'FETCH_ALL_LOWES_ORDERS_REQUEST':
            return { ...state, isLoading: true }
        case 'FETCH_ALL_LOWES_ORDERS_SUCCESS':
            return { ...state, isLoading: false, lowesOrders: action.lowesOrders }
        case 'FETCH_ALL_LOWES_ORDERS_FAILURE':
            return { ...state, isLoading: false }
        case 'FETCH_ALL_LOWES_LINES_ORDERS_REQUEST':
            return { ...state, isLoading: true }
        case 'FETCH_ALL_LOWES_LINES_ORDERS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                lowesLinesOrders: action.lowesLinesOrders
            }
        case 'FETCH_ALL_LOWES_LINES_ORDERS_FAILURE':
            return { ...state, isLoading: false }

        case 'GET_LOWES_ORDER_DETAILS_REQUEST':
            return { ...state, isloading: true }
        case 'GET_LOWES_ORDER_DETAILS_SUCCESS':
            return { ...state, isloading: false, lowesOrderDetails: action.details }
        case 'GET_LOWES_ORDER_DETAILS_FAILURE':
            return { ...state, isloading: false }
        case 'CLEAR_LOWES_ORDER_DETAILS':
            return { ...state, isloading: false, lowesOrderDetails: null }

        case 'SIMPLE_IMPERSONATE_SUCCESS':
        case 'LOGOUT':
            return Object.assign({}, state, initState)
        case 'SHOW_ORDER_LINE_DETAIL':
            return Object.assign({}, state, { showModal: true, activeOrderLine: action.orderLine })
        case 'CLOSE_ORDER_LINE_DETAIL':
            return Object.assign({}, state, { showModal: false })

        case 'SEND_ACCEPT_QUOTE_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'SEND_ACCEPT_QUOTE_SUCCESS':
            action.offer.statusText = 'PENDING_ACCEPT_OFFER'
        case 'SEND_ACCEPT_QUOTE_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'SEND_REJECT_QUOTE_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'SEND_REJECT_QUOTE_SUCCESS':
            action.offer.statusText = 'PENDING_CANCEL_OFFER'
        case 'SEND_REJECT_QUOTE_FAILURE':
            return Object.assign({}, state, { isLoading: false })
        case 'FETCH_PACKAGING_IMAGES_REQUEST':
            return Object.assign({}, state, { fetchingPackagingImages: true, packagingImages: null })
        case 'FETCH_PACKAGING_IMAGES_SUCCESS':
            return Object.assign({}, state, { fetchingPackagingImages: false, packagingImages: action.packagingImages })
        case 'FETCH_PACKAGING_IMAGES_FAILURE':
            return Object.assign({}, state, { fetchingPackagingImages: false, packagingImages: null })

        default:
            return state
    }
}
export const byOrderId = (state, orderId) => {
    let found = state.orders.orders.find(order => {
        return order.orderId === orderId
    })

    return found
}
export const getOrders = state => {
    return state.orders.orders
}
export const getOffers = state => {
    return state.orders.offers
}
export const getOpenOrders = state => {
    return state.orders.openOrders
}
export const getTransferOrders = state => {
    return state.orders.transferOrders
}
export const getPackingList = state => {
    return state.orders.packingList
}

export const getOpenDocDetails = state => {
    return state.orders.openDocDetails
}
export const getOrder = state => {
    return state.orders.order
}
export const getInvoices = state => state.orders.invoices
export const getOrderDetailsHead = state => {
    return state.orders.orderDetailsHead
}
export const getRecurringOrder = state => {
    return state.orders.recurringOrders
}
export const getRecurringOrderDetails = state => {
    return state.orders.recurringOrderDetails
}
export const getOrdersLoading = state => {
    return state.orders.isLoading
}
export const getOrderToEditLoading = state => {
    return state.orders.isOrderToEditLoading
}
export const getOrderDetailsLoading = state => {
    return state.orders.isLoadingDetails
}
export const getPackingListLoading = state => {
    return state.orders.isLoadingPackingList
}
export const getShowRecurringOrderDetails = state => {
    return state.orders.showRecurringOrderDetails
}
export const getselectedRecurringOrder = state => {
    return state.orders.selectedRecurringOrder
}
export const getOrderToEdit = state => {
    return state.orders.orderToEdit
}
export const getOrdersLoadingAdd = state => {
    return state.orders.isLoadingAdd
}
export const canEditOrders = state => {
    let centers = ['YVETTEL', 'MPAKOS', 'VALERIYAK', 'DQUILES']
    let users = ['410606', '0000410606']
    if (centers.includes(getImpersonatedBy(state)) || users.includes(getUserName(state))) return true
    return false
}
export const canEditOrder = (state, order) => {
    const orderStatusMap = getOrderStatusMap(state)

    if (order != null) {
        if (order.deliveries && order.deliveries.length === 0) {
            if (order.statusText) {
                return orderStatusMap[order.statusText] ? orderStatusMap[order.statusText].editable : false
            }
        }
    }

    return false
}
export const canDeleteOrder = (state, order) => {
    return canEditOrder(state, order)
}
export const getStatusMap = state => {
    return {
        //RESERVATIONSTATUS
        PENDING_CONFIRMATION: { status: 1, textToShow: 'pendiente_de_crear', editable: true },
        CONFIRMED: { status: 3, textToShow: 'aprobado', editable: true },
        PENDING_ORDER_CREATION: { status: 1, textToShow: 'pendiente_de_crear', editable: true },
        ORDER_CREATED: { status: 2, textToShow: 'pedido_creado', editable: true },
        EXTERNAL_CUSTOMER: { status: 1, textToShow: 'pendiente_de_crear', editable: true }, //PENDING
        ERROR_CREATING_ORDER: { status: 8, textToShow: 'error_creating_order', editable: false },
        PENDING_CANCELLATION: { status: 2, textToShow: 'PENDING_CANCELLATION', editable: false }, //PENDING
        CANCELLED: { status: 20, textToShow: 'reserva_cancelada', editable: false },
        QUOTE_REJECTED: { status: 20, textToShow: 'quote_rejected', editable: false },
        QUOTE_EXPIRED: { status: 20, textToShow: 'QUOTE_EXPIRED', editable: false },
        ORDER_CANCELLED: { status: 3, textToShow: 'reserva_cancelada', editable: false },
        ORDER_MODIFIED: { status: 1, textToShow: 'pendiente_de_crear', editable: true }, //PENDING
        WILSAM_RESERVATION: { status: 1, textToShow: 'pendiente_de_crear', editable: true },
        USA_SHOPS: { status: 1, textToShow: 'pendiente_de_crear', editable: true }, //PENDING
        PENDING_CHANGES: { status: 9, textToShow: 'cambios pendientes', editable: false },
        RESERVATION: { status: 1, textToShow: 'pendiente_de_crear', editable: true }, //PENDING
        OFFER_CREATED: { status: 9, textToShow: 'oferta_creada' },
        PENDING_ACCEPT_OFFER: { status: 9, textToShow: 'PENDING_ACCEPT_OFFER', editable: false },//PENDING
        PENDING_CANCEL_OFFER: { status: 9, textToShow: 'PENDING_CANCEL_OFFER', editable: false },//PENDING
        ERROR_PROCESSING_OFFER: { status: 8, textToShow: 'error_procesando_oferta' },
        OFFER_PENDING_CREATE: { status: 1, textToShow: 'pendiente_de_crear', editable: true },
        //ORDERSTATUS
        CREATED: { status: 1, textToShow: 'order_status_created', editable: true },
        APPROVED: { status: 3, textToShow: 'order_status_approved', editable: true },
        ORDER_PREPARATION: { status: 3, textToShow: 'order_in_preparation', editable: true },
        //PARTIALLY_DELIVERED: { status: 4, textToShow: 'salida_de_mercancías_realizada', editable: false },
        TOTALLY_DELIVERED: { status: 4, textToShow: 'salida_de_mercancías_realizada', editable: false },
        PARTIALLY_INVOICED: { status: 1, textToShow: 'pendiente_de_crear', editable: true }, //PENDING
        TOTALLY_INVOICED: { status: 6, textToShow: 'facturado_finalizado', editable: false }
    }
}
export const getOrderStatusMap = state => {
    const timelineStatus = {
        RESERVATION_CREATED: { textToShow: 'reservation_status_created', editable: false }, //Can't update without order_id
        RESERVATION_CANCELLED: { textToShow: 'reserva_cancelada', editable: false },
        CREATED: { textToShow: 'order_status_created', editable: true },
        APPROVED: { textToShow: 'order_status_approved', editable: true },
        PREPARATION: { textToShow: 'order_status_preparation', editable: true },
        SHIPPED: { textToShow: 'order_status_shipped', editable: true },
        PICKUP: { textToShow: 'order_status_pickup', editable: true },
        DELIVERED: { textToShow: 'order_status_delivered', editable: true },
        INVOICED: { textToShow: 'order_status_invoiced', editable: true },
        PENDING_CANCELLATION: { textToShow: 'order_pending_cancellation', editable: false },
        PENDING_CHANGES: { textToShow: 'order_status_pending_changes', editable: false },
        CANCELLED: { textToShow: 'reserva_cancelada', editable: false },
        ORDER_CANCELLED: { textToShow: 'order_status_cancelled', editable: false },
        ERROR: { textToShow: 'order_status_error', editable: false },
        IN_PROCESS: { textToShow: 'order_status_preparation', editable: true },
        PARTIALLY_DELIVERED: { textToShow: 'order_status_partially_delivered', editable: true },
        PENDING: { textToShow: 'order_status_approved', editable: false },
    }

    // Esto lo mantenemos para que no falte ningún texto
    return Object.assign(getStatusMap(state), timelineStatus)
}

export const getDeliveryStatusMap = state => {
    return {
        DELIVERY_CREATED: { textToShow: 'planned_transportation', editable: false },
        IN_TRANSIT: { textToShow: 'order_status_shipped', editable: false },
        DELIVERED: { textToShow: 'entregado', editable: false },
    }
}
export const canDeleteOrders = state => {
    if (getIsIkeaCenter(state)) return true
    return false
}
export const getLowesOrders = state => {
    return state.orders.lowesOrders
}
export const getLowesLinesOrders = state => {
    return state.orders.lowesLinesOrders
}
export const getLowesOrderDetails = state => {
    return state.orders.lowesOrderDetails
}
export const getOrdersCurrentPage = state => {
    return state.orders.ordersCurrentPage
}
export const getOrdersLastPage = state => {
    return state.orders.ordersLastPage
}
export const showModalLineDetail = state => {
    return state.orders.showModal
}
export const getActiveOrderLine = state => {
    return state.orders.activeOrderLine
}
export const getOrderInEdition = state => {
    return state.orders.orderInEdition
}
const linesSelector = state => {
    return state.orders.orderInEdition.filter(line => !line.rejected)
}
export const orderWeightSelector = createSelector(
    linesSelector,
    lines => lines.reduce((total, line) => total + (line.product ? line.product.weight : 0) * line.quantity, 0)
)
export const getOrderShippingMethod = state => {
    return state.orders.order.shippingType
}
export const getOrderCenter = state => {
    return parseInt(state.orders.order.centerId)
}
export const getOrderCenterData = state => {
    let found = state.centers.centers.find(center => {
        return center.centerId === getOrderCenter(state)
    })

    return found
}
export const getOrderCenterHasMinWeight = state => {
    if (!getOrderCenterData(state)) return null
    return getOrderCenterData(state).hasMinWeight
}
export const getOrderShippingAddressId = state => {
    return getOrder(state).shippingAddressId
}
export const getOrderShippingAddress = state => {
    return state.shippingAddresses.shippingAddresses.shippingAddresses.find(shippingAddress => shippingAddress.shippingAddressId === getOrderShippingAddressId(state))
}
export const getOrderCountryOfSend = state => {
    return getOrderShippingAddress(state).country
}
export const getOrderHasDestination = state => {
    return getOrderShippingMethod(state) === 'CONTAINER'
}
export const getOrderCurrentWeight = (state, unit) => {
    return unit === 'KG' ? orderWeightSelector(state) : Math.floor(orderWeightSelector(state) * getKgPoundRatio())
}
export const getOrderGoalMin = (state, unit) => {
    let goalMin = getOrderShippingMethod(state) === 'CONTAINER' ? 19500 : 12000
    const orderCenter = getOrderCenter(state)
    switch (orderCenter) {
        case getLatinaVitoriaCenterId():
        case getNaturalStoneSpainCenterId():
            if (getCartTypes(state).every(type => ['BUNDLE'].includes(type))) {
                if (getCartBrands(state).every(brand => ['SENSA', 'SCALEA'].includes(brand))) {
                    goalMin = 15000
                }
            }
            break
        case getHQCenterId(): // HQ
            goalMin = 12000
            break
        case getHUBSydneyCenterId(): // HUB Sydney
            goalMin = 19000
            break
        case getHUBHoustonCenterId(): // HUBs de USA
        case getHUBNorfolkCenterId():
            goalMin = 21500
            break
        case getLatinaDepot(): // Centro 7507 de Brasil
            goalMin = 17000
            break
        default:
            goalMin = getOrderShippingMethod(state) === 'CONTAINER' ? 19500 : 12000
    }
    const goalMinAcordingUnit = unit === 'KG' ? goalMin : Math.floor(goalMin * getKgPoundRatio())
    return goalMinAcordingUnit
}
export const getOrderGoalMax = (state, unit) => {
    let goalMax = getOrderShippingMethod(state) === 'CONTAINER' ? 20200 : 24000

    switch (getOrderCenter(state)) {
        case getLatinaVitoriaCenterId():
            goalMax = getOrderShippingMethod(state) === 'CONTAINER' ? 27000 : 24000
            break
        case getHQCenterId(): // HQ
            goalMax = 24000
            break
        case getHUBSydneyCenterId(): // HUB Sydney
            goalMax = 35000
            break
        case getHUBHoustonCenterId(): // HUBs de USA
        case getHUBNorfolkCenterId():
            goalMax = 23000
            break
        case getLatinaDepot(): // Centro 7507 de Brasil
            goalMax = 39000
            break
        default:
            goalMax = getOrderShippingMethod(state) === 'CONTAINER' ? 20200 : 24000
    }
    const goalMaxAcordingUnit = unit === 'KG' ? goalMax : Math.floor(goalMax * getKgPoundRatio())

    return goalMaxAcordingUnit
}
export const getCartTypes = state => {
    let types = []
    linesSelector(state).forEach(line => {
        if (line.bundleId !== null) {
            if (!types.includes('BUNDLE')) types.push('BUNDLE')
        } else if (line.lotId !== null) {
            if (!types.includes('LOT')) types.push('LOT')
        } else if (line.productId === 'CUT_TO_SIZE') {
            if (!types.includes('CUT_TO_SIZE')) types.push('CUT_TO_SIZE')
        } else {
            if (!types.includes('NORMAL')) types.push('NORMAL')
        }
    })
    return types
}
export const getCartBrands = state => {
    let brands = []
    linesSelector(state).forEach(line => {
        if (!brands.includes(line.brand)) {
            brands.push(line.brand)
        }
    })
    return brands
}
export const getOrderLenghtBundlesCart = state => {
    let numOfRealBundles = linesSelector(state).filter(line => {
        return line.bundleId !== null
    }).length
    let numOfCatalogBundles = 0
    if (getOrderShippingMethod(state) !== 'SINGLE_SLAB') {
        linesSelector(state).forEach(line => {
            if (line.bundleId === null && line.lotId === null && line.product.minQty[getOrderShippingMethod(state)] !== 1)
                //dont count if step is 1
                numOfCatalogBundles = numOfCatalogBundles + line.qty / line.product.minQty[getOrderShippingMethod(state)]
        })
    }

    return numOfRealBundles + numOfCatalogBundles
}
export const getError = state => {
    return state.orders.error
}

export const getPackagingImages = state => state.orders.packagingImages
export const isFetchingPackagingImages = state => state.orders.fetchingPackagingImages

export default orders
