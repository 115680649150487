import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import Button from '../global/Button'
import { connect } from 'react-redux'
import { getShowBigAccountModal } from '../../store/ui/reducers'
import { getEmployeeId } from '../../store/big-account/reducers'
import { fetchCart } from '../../store/cart/actions'
import logo from '../../assets/img/e.cosentino.svg'
import { setUserEmailAAD } from '../../store/profile-configuration/actions'

const mapStateToProps = state => {
    return {
        showBigAccountModal: getShowBigAccountModal(state),
        employeeId: getEmployeeId(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setEmployeeId: employeeId => Promise.resolve(dispatch({ type: 'SET_EMPLOYEE_ID', employeeId: employeeId })),
        fetchCart: () => dispatch(fetchCart()),
        toggleShowIkeaModalEmployeeId: open => Promise.resolve(dispatch({ type: 'TOGGLE_SHOW_BIG_ACCOUNT_MODAL', open: open })),
        setUserEmailAAD: email => {
            return dispatch(setUserEmailAAD(email))
        },
    }
}
class IkeaModalEmployeeId extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false, employeeId: props.employeeId }
        this.form = React.createRef()
    }
    setEmployeeId() {
        if ((!HTMLFormElement.prototype.reportValidity && /[0-9]{8}/.test(this.state.employeeId)) || this.form.current.reportValidity())
            this.props.setEmployeeId(this.state.employeeId).then(() => {
                this.props.setUserEmailAAD(this.state.employeeId)
                this.setState({ isLoading: true })
                this.props
                    .fetchCart()
                    .then(() => {
                        this.setState({ isLoading: false })
                        this.props.toggleShowIkeaModalEmployeeId(false)
                    })
                    .catch(() => this.setState({ isLoading: false }))
            })
    }
    render() {
        const { showBigAccountModal, employeeId } = this.props

        if (!showBigAccountModal) return null
        return (
            <div>
                <div className="full-overlay" style={{ display: showBigAccountModal ? 'block' : 'none' }} />
                <div id="modal-login-container" className="modal-login" style={{ display: showBigAccountModal ? 'block' : 'none' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-5 logo-container d-none d-lg-block">
                                <img alt="" id="profile-img" className="profile-img-card" src={logo} />
                            </div>
                            <div className="col-12 col-lg-7 card-container">
                                <header className="container-fluid">
                                    <div className="row">
                                        <div className="col-3 offset-9 my-auto text-right">
                                            {/* <div className="bt-close" onClick={() => {}}>
                                                <i className="fal fa-times" />
                                            </div> */}
                                        </div>
                                    </div>
                                </header>

                                <div className="form-signin">
                                    <div className="col-12">
                                        <div className="form-group employee-id">
                                            <form
                                                ref={this.form}
                                                onSubmit={e => {
                                                    e.preventDefault()
                                                    this.setEmployeeId()
                                                }}
                                            >
                                                <label>
                                                    <Translation
                                                        id="your_account_has_the_multicart_feature_active_so_please_enter_your_email_address"
                                                        defaultMessage="Your account has the multicart feature active so please enter your email address"
                                                    />
                                                </label>{' '}
                                                <input
                                                    data-cy="ikea__input_employeeId-start"
                                                    className="form-control"
                                                    defaultValue={employeeId}
                                                    onKeyPress={e => {
                                                        if (e.key === 'Enter') {
                                                            this.setEmployeeId()
                                                        }
                                                    }}
                                                    onChange={e => this.setState({ employeeId: e.target.value })}
                                                    type="email"
                                                />
                                                <Button
                                                    disabled={!(this.state.employeeId && this.state.employeeId !== '') || this.state.isLoading}
                                                    onClick={() => this.setEmployeeId()}
                                                    inverted
                                                    size="medium"
                                                    type="submit"
                                                    className="bt bt-inverted loginbutton"
                                                >
                                                    <Translation id="login" defaultMessage="Login"></Translation>
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                    <footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(IkeaModalEmployeeId)
)
