import { injectIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import FilterButton from "./FilterButton";
import CustomFilter from "./CustomFilter";

const FiltersRow = props => {

    const [ selections, setSelections ] = useState({})

    useEffect(() => {
        setSelections(Object.entries(props.filters.filters).reduce((p, [k, v]) => ({ ...p, [k]: v.isSelected }), {}))
    }, [props.filters.filters])

    const select = (key, value, getIsSelected) => {
        const _selections = {}
        _selections[key] = getIsSelected ? getIsSelected(value) : !!value
        Object.entries(selections).forEach(([_key, _value]) => {
            if (_key !== key) _selections[_key] = !!props.multiselect ? selections[_key] : false
        })

        setSelections(_selections)
        props.onSelect(_selections)
    }

    return (
        <React.Fragment>
            <div className="filter__label__title">
                <h5>
                    {props.label}
                </h5>
            </div>
            <div className="filter__row__content">
                <div className="filter__row__content--track-list">
                    {
                        Object.entries(props.filters.filters).map(([key, f]) => (f.custom ?
                            <CustomFilter
                                onChange={value => {
                                    select(key, value, f.getIsSelected)
                                }}
                            >
                                {f.custom(f.mapper, props.filters)}
                            </CustomFilter>
                                :
                            <FilterButton label={f.label}
                                        toggle={props.multiselect}
                                        key={key}
                                        onSelect={value => select(key, value)}
                                        isSelected={selections[key]}
                            />
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default FiltersRow


