import React from 'react'
import Translation from '../global/Translation'

import { injectIntl } from 'react-intl'

import logo from '../../assets/img/e.cosentino.svg'

import AlertsContainer from '../alerts/AlertsContainer'
import Spinner from '../global/Spinner'
class CreateAccountForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = { forgotPassword: null }
    }
    handleSubmit = e => {
        e.preventDefault()
        const containZero = this.usernameInput.value.slice(0, 4)
        if (containZero === '0000') {
            this.props.onCreateAccount(this.usernameInput.value, this.emailInput.value)
        } else {
            this.props.onCreateAccount('0000' + this.usernameInput.value, this.emailInput.value)
        }
    }
    render() {
        const { isLogging } = this.props

        const spinner = isLogging ? <Spinner isLoading={isLogging} /> : null

        return (
            <div className="form-signin">
                <form onSubmit={this.handleSubmit}>
                    <AlertsContainer />
                    {spinner}
                    <img alt="" id="profile-img" className="d-block d-lg-none profile-img-card" src={logo} style={{ marginBottom: '30px' }} />
                    <label htmlFor="email">
                        <Translation id="ask_cosentino_center_for_id" defaultMessage="Ask your Cosentino Center for your unique ID #" />
                    </label>
                    <input
                        type="text"
                        id="inputEmail"
                        className="form-control"
                        placeholder={this.props.intl.formatMessage({ id: 'identifier' })}
                        ref={node => {
                            this.usernameInput = node
                        }}
                    />
                    <label htmlFor="email">
                        <Translation id="Add_more_than_one_email" defaultMessage="Add more than one email separating them by ';'" />
                    </label>
                    <input
                        type="text"
                        id="inputEmail"
                        className="form-control mb-3"
                        placeholder="Email"
                        ref={node => {
                            this.emailInput = node
                        }}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                this.handleSubmit(e)
                            }
                        }}
                    />
                    <div id="remember" className="checkbox" />
                    <input type="submit" className="bt bt-inverted button" value={this.props.intl.formatMessage({ id: 'create_account' })} />
{/* 
                    <div className="row links">
                        <div className="col-12 col-md-7 offset-md-5 forgot-password">
                            <span onClick={this.props.showLogin}>
                                <Translation id="login" defaultMessage="Login" />
                            </span>
                        </div>
                    </div> */}
                </form>
            </div>
        )
    }
}
export default injectIntl(CreateAccountForm)
