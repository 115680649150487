import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getLanguage } from '../../../../store/ui/reducers'
import { getReplaceDotWithComma } from '../../../../helpers'

class DisplayThickness extends React.Component {
    render() {
        const { locale, thickness, string } = this.props
        if (thickness && string) {
            return <span>{this.props.replaceDotWithComma(thickness, locale)} cm</span>
        }
        else if (thickness) {
                return (
                    <div className="product_details thickness">
                        <div className="icon">
                            <div style={{ height: `${thickness}px`,overflow: 'auto',minHeight: '1px' }} />
                        </div>
                        <div className="text">{this.props.replaceDotWithComma(thickness, locale)} cm</div>
                    </div>
                )
        } else return null
    }
}

const mapStateToProps = state => {
    return {
        locale: getLanguage(state),
        replaceDotWithComma: (element, locale) => getReplaceDotWithComma(element, locale, state)
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(DisplayThickness)
)
