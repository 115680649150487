import React from 'react'
import Translation from '../global/Translation'

import { Player } from 'video-react'
import video from '../../assets/video/outlet-offpacksmart.mp4'
import videoEs from '../../assets/video/outlet-digital-esp.mp4'
import videoEn from '../../assets/video/outlet-digital-en.mp4'
import SimpleModal from '../global/SimpleModal'

const initState = { showModalVideoOffpack: false, showModalVideoOutletDigital: false }
class OutletInfoText extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...initState, maxWidhtVideo: window.innerHeight * 1.3375 - 150 }
        window.addEventListener('resize', this.calculateMaxWidthVideo)
    }
    calculateMaxWidthVideo = () => {
        this.setState({ maxWidhtVideo: window.innerHeight * 1.3375 - 150 })
    }
    render() {
        return (
            <p>
                <h4>
                    <Translation id="you_can_consult_the_information_in_the_following_links" defaultMessage="You can consult the information in the following links!" />
                </h4>
                <ul>
                    <li>
                        <Translation id="any_doubts__find_what_you_need_in_offpack_smart" defaultMessage="Any doubts? Find what you need in Offpack Smart" />.{' '}
                        <span className="text_link" onClick={() => this.setState({ showModalVideoOffpack: true })}>
                            <Translation id="discover_it_" defaultMessage="Discover it!" />
                        </span>
                    </li>
                    <li>
                        <Translation id="do_you_want_to_check_how_the_platform_works_" defaultMessage="Do you want to check how the platform works?" />.{' '}
                        <span className="text_link" onClick={() => this.setState({ showModalVideoOutletDigital: true })}>
                            <Translation id="click_here_" defaultMessage="Click here!" />
                        </span>
                    </li>
                </ul>
                {this.state.showModalVideoOffpack && (
                    <SimpleModal title={'Video'} onClose={() => this.setState({ showModalVideoOffpack: false })} size="large" footer={null}>
                        <div style={{ margin: '0 auto', width: '100%', maxWidth: this.state.maxWidhtVideo + 'px' }}>
                            <Player>
                                <source src={video} />
                            </Player>
                        </div>
                    </SimpleModal>
                )}
                {this.state.showModalVideoOutletDigital && (
                    <SimpleModal title={'Video'} onClose={() => this.setState({ showModalVideoOutletDigital: false })} size="large" footer={null}>
                        <div style={{ margin: '0 auto', width: '100%', maxWidth: this.state.maxWidhtVideo + 'px' }}>
                            <Player>
                                <source src={this.props.language === 'es' ? videoEs : videoEn} />
                            </Player>
                        </div>
                    </SimpleModal>
                )}
            </p>
        )
    }
}
export default OutletInfoText
