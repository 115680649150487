import React from 'react'
import Translation from '../global/Translation'

import ReactTable from 'react-table'
import { defineMessages, injectIntl } from 'react-intl'

import Button from '../global/Button'
import Modal from '../global/Modal'
import { connect } from 'react-redux'
import Spinner from '../global/Spinner'
import { getUsersShopper, getIsLoading, getTemplates } from '../../store/lowes/reducers'
import { fetchUsersShopperLowes, fetchTemplatesLowes, updateStoreLowes } from '../../store/lowes/actions'

defineMessages({
    SEARCH_BY_ID_OR_NAME: {
        id: 'search_by_id_or_name',
        description: 'search_by_id_or_name',
        defaultMessage: 'Search by Id or Name'
    },
    SEARCH_BY_NAME: {
        id: 'search_by_name',
        description: 'search_by_name',
        defaultMessage: 'Search by Name'
    }
})
const mapStateToProps = state => {
    return {
        usersShopper: getUsersShopper(state),
        isLoading: getIsLoading(state),
        templates: getTemplates(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchUsersShopperLowes: () => {
            dispatch(fetchUsersShopperLowes())
        },
        fetchTemplatesLowes: () => {
            dispatch(fetchTemplatesLowes())
        },
        updateStoreLowes: (storeId, templatesId) => {
            return dispatch(updateStoreLowes(storeId, templatesId))
        }
    }
}
class LowesUser extends React.Component {
    state = {
        searchUsers: null,
        searchTemplates: null
    }
    componentDidMount() {
        this.props.fetchUsersShopperLowes()
        this.props.fetchTemplatesLowes()
    }
    searchUsers(value) {
        this.setState({ searchUsers: value })
    }
    searchTemplates(value) {
        this.setState({ searchTemplates: value })
    }
    //poner nombre correcto (cambia el título de los samples sin producto)
    toggleTemplateStore = templateId => {
        let found =
            this.state.templatesId.filter(store => {
                return store === templateId
            }).length > 0
        if (found) {
            let newstemplatesId = this.state.templatesId.filter(template => {
                return template !== templateId
            })
            this.setState({ templatesId: newstemplatesId })
        } else {
            this.setState({ templatesId: this.state.templatesId.concat(templateId) })
        }
    }
    updateStoreLowes = () => {
        this.props.updateStoreLowes(this.state.storeSelected, this.state.templatesId).then(() => {
            this.setState({ storeSelected: undefined })
            this.props.fetchUsersShopperLowes()
        })
    }
    render() {
        let usersFiltered = null
        if (this.state.searchUsers) {
            usersFiltered = this.props.usersShopper
            usersFiltered = usersFiltered.filter(user => {
                return (
                    user.name.toUpperCase().includes(this.state.searchUsers.toUpperCase()) ||
                    user.address.toUpperCase().includes(this.state.searchUsers.toUpperCase()) ||
                    user.city.toUpperCase().includes(this.state.searchUsers.toUpperCase()) ||
                    user.state.toUpperCase().includes(this.state.searchUsers.toUpperCase())
                )
            })
        }
        let usersShopper = usersFiltered !== null ? usersFiltered : this.props.usersShopper

        let templatesFiltered = null
        if (this.state.searchTemplates) {
            templatesFiltered = this.props.templates
            templatesFiltered = templatesFiltered.filter(template => {
                return template.name.toUpperCase().includes(this.state.searchTemplates.toUpperCase())
            })
        }
        let templates = templatesFiltered !== null ? templatesFiltered : this.props.templates

        return (
            <div className="container container--lowes">
                {this.state.storeSelected && (
                    <Modal
                        title={<Translation id="plans" defaultMessage="Plans" />}
                        onClose={() => this.setState({ storeSelected: undefined })}
                        size="large"
                        footer={
                            <Button onClick={() => this.updateStoreLowes()} inverted size="medium">
                                <Translation id="save" defaultMessage="Save" />
                            </Button>
                        }
                    >
                        <div className="group_buttons">
                            <div className="search-bar search-bar--thin">
                                <input
                                    type="text"
                                    className="search-box"
                                    placeholder={this.props.intl.formatMessage({ id: 'search_by_name' })}
                                    value={this.state.searchTemplates}
                                    onChange={e => {
                                        this.searchTemplates(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <ReactTable
                            data={templates}
                            loading={this.props.isLoading}
                            loadingText={<Spinner isLoading={this.props.isLoading} />}
                            className="-highlight scroll mb-5"
                            columns={[
                                {
                                    id: 'id',
                                    Header: <Translation id="id" defaultMessage="id" />,
                                    accessor: 'id',
                                    maxWidth: 60
                                },
                                {
                                    id: 'name',
                                    Header: <Translation id="name" defaultMessage="Name" />,
                                    accessor: 'name'
                                },
                                {
                                    id: 'checkBox',
                                    show: this.props.templates !== undefined,
                                    Cell: ({ original }) => {
                                        return (
                                            <input
                                                type="checkbox"
                                                onChange={() => {
                                                    this.toggleTemplateStore(original.id)
                                                }}
                                                checked={this.state.templatesId.includes(original.id)}
                                            />
                                        )
                                    },
                                    maxWidth: 60
                                }
                            ]}
                            defaultPageSize={8}
                            pageSizeOptions={[8, 16, 32, 64, 128]}
                            previousText={<Translation id="previous" defaultMessage="Previous" />}
                            nextText={<Translation id="next" defaultMessage="Next" />}
                            noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                            pageText={<Translation id="page" defaultMessage="Page" />}
                            ofText={<Translation id="of" defaultMessage="Of" />}
                            getTrProps={(state, rowInfo) => {
                                let cursor = rowInfo ? 'pointer' : 'default'

                                return {
                                    onClick: () => {
                                        this.toggleTemplateStore(rowInfo.original.id)
                                    },
                                    style: { cursor: cursor }
                                }
                            }}
                        />
                    </Modal>
                )}
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="offset-1 col-10">
                        <div className="search-bar search-bar--thin">
                            <input
                                type="text"
                                className="search-box"
                                placeholder={this.props.intl.formatMessage({ id: 'search_by_id_or_name' })}
                                value={this.state.searchUsers}
                                onChange={e => {
                                    this.searchUsers(e.target.value)
                                }}
                            />
                        </div>
                        {this.props.stores && (
                            <div className="group_button_select_all">
                                <Button onClick={this.props.unselectAllUsersShopper}>Unselect All</Button>
                                <Button onClick={this.props.selectAllUsersShopper}>Select All</Button>
                            </div>
                        )}
                    </div>
                </div>
                <ReactTable
                    data={usersShopper}
                    loading={this.props.isLoading}
                    loadingText={<Spinner isLoading={this.props.isLoading} />}
                    className="-highlight scroll mb-5"
                    columns={[
                        {
                            id: 'name',
                            Header: <Translation id="store_name" defaultMessage="Store Name" />,
                            accessor: 'name'
                        },
                        {
                            id: 'store_hashtag',
                            Header: <Translation id="store_hashtag" defaultMessage="Store #" />,
                            accessor: 'store_hashtag'
                        },
                        {
                            id: 'phone',
                            Header: <Translation id="phone" defaultMessage="Phone" />,
                            accessor: 'phone',
                            Cell: ({ original }) => {
                                if (original.phone === 'SIN_TELEFONO') return <Translation id="no_phone" defaultMessage="No phone" />
                                else return original.phone
                            }
                        },
                        {
                            id: 'address',
                            Header: <Translation id="address" defaultMessage="Address" />,
                            accessor: 'address'
                        },
                        {
                            id: 'city',
                            Header: <Translation id="city" defaultMessage="City" />,
                            accessor: 'city'
                        },
                        {
                            id: 'state',
                            Header: <Translation id="state" defaultMessage="State" />,
                            accessor: 'state'
                        },
                        {
                            id: 'zip',
                            Header: <Translation id="zip" defaultMessage="Zip" />,
                            accessor: 'zip'
                        },
                        {
                            id: 'pog',
                            Header: <Translation id="pog_type" defaultMessage="Pog Type" />,
                            accessor: 'pog'
                        },
                        {
                            id: 'checkBox',
                            show: this.props.stores !== undefined,
                            Cell: ({ original }) => {
                                return (
                                    <input
                                        type="checkbox"
                                        onChange={() => {
                                            this.props.onToggleUsersTemplate(original.id)
                                        }}
                                        checked={this.props.stores.includes(original.id)}
                                    />
                                )
                            },
                            maxWidth: 60
                        }
                    ]}
                    defaultPageSize={8}
                    pageSizeOptions={[8, 16, 32, 64, 128]}
                    previousText={<Translation id="previous" defaultMessage="Previous" />}
                    nextText={<Translation id="next" defaultMessage="Next" />}
                    noDataText={<Translation id="no_data" defaultMessage="No data available" />}
                    pageText={<Translation id="page" defaultMessage="Page" />}
                    ofText={<Translation id="of" defaultMessage="Of" />}
                    getTrProps={(state, rowInfo) => {
                        let cursor = rowInfo ? 'pointer' : 'default'

                        return {
                            onClick: () => {
                                this.props.stores !== undefined
                                    ? this.props.onToggleUsersTemplate(rowInfo.original.id)
                                    : this.setState({ storeSelected: rowInfo.original.id, templatesId: rowInfo.original.plans })
                            },
                            style: { cursor: cursor }
                        }
                    }}
                />
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LowesUser)
)
