const initState = {
    advertisements: null,
    showAdvertisements: false
}
const advertisements = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ADVERTISEMENTS_SUCCESS':
            return Object.assign({}, state, { advertisements: action.advertisements, showAdvertisements: action.advertisements !== null })

        case 'REMOVE_ADVERTISEMENTS':
                return Object.assign({}, state, { advertisements: null, showAdvertisements: false})

        case 'HIDE_ADVERTISEMENTS':
            return Object.assign({}, state, { showAdvertisements: false})
        
        default:
            return state
    }
}

export const getAdvertisements = state => {
    return state.advertisements.advertisements
}

export const showAdvertisements = state => {
    return state.advertisements.showAdvertisements
}

export default advertisements