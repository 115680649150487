import { connect } from 'react-redux'
import LoginModal from './LoginModal'
import {
    getCallbackCenter,
    getIsLogging,
    getLoginCallback,
    mandatoryChangePassword,
    showModalSelector,
    getIsLogged,
    getAlert,
    getCallbackSalesOrg
} from '../../store/login/reducers'
import { createAccount, login, mandatoryChangePasswordRequest, recoverPassword } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'
import { getEmployeeId } from '../../store/big-account/reducers'

const mapStateToProps = state => {
    return {
        show: showModalSelector(state),
        alert: getAlert(state),
        showBigAccount: true,
        callback: getLoginCallback(state),
        callbackCenter: getCallbackCenter(state),
        callbackSalesOrg: getCallbackSalesOrg(state),
        isLogging: getIsLogging(state),
        employeeId: getEmployeeId(state),
        mandatoryChangePassword: mandatoryChangePassword(state),
        isLogged: getIsLogged(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (username, password, callback, callbackCenter, intl, ssoData, callbackSalesOrg) => {
            dispatch(login(username, password, callback, callbackCenter, intl, ssoData, callbackSalesOrg))
        },
        onRecoverPassword: (username, email) => {
            dispatch(recoverPassword(username, email))
        },
        onCreateAccount: (username, email) => {
            dispatch(createAccount(username, email))
        },
        onMandatoryChangePassword: (newPassword, newPasswordRepeat) => {
            dispatch(mandatoryChangePasswordRequest(newPassword, newPasswordRepeat))
        },
        onClose: () => {
            dispatch(ui.closeLoginModal())
        }
    }
}

const LoginModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal)

export default LoginModalContainer
