import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'
import { getLocaleWithft2 } from '../../store/products/reducers'

const mapStateToProps = state => {
    return { userPreferences: getProfileConfiguration(state), localeWithft2: getLocaleWithft2(state) }
    // return { userPreferences: 'PL', localeWithft2: getLocaleWithft2(state) }
}

export const formatValue = value => {
    if (typeof value == 'number') {
        const strValue = value.toString()
        const intDecParts = strValue.split('.')
        const decimalPart = intDecParts.length > 1 && intDecParts[1]
        if (decimalPart) {
            const decSize = Math.min(2, decimalPart.length)
            return value.toFixed(decSize)
        }
    }

    return value
}

const CalculateMeasure = ({ cm, ft, withUnit = false, userPreferences, intl, localeWithft2 }) => {
    let conversionFactor = 0.393701
    if (cm !== undefined) {
        const userLengthPrefer = userPreferences.lengthUnit === 'M2' ? cm : conversionFactor * cm
        const userUnitPrefer = userPreferences.lengthUnit === 'M2' ? 'cm' : 'inch'
        const lengthDefault = localeWithft2.includes(intl.locale) ? conversionFactor * cm : cm
        const unitDefault = localeWithft2.includes(intl.locale) ? 'inch' : 'cm'
        const value = formatValue(userPreferences.lengthUnit ? userLengthPrefer : lengthDefault)
        const unit = userPreferences.lengthUnit ? userUnitPrefer : unitDefault
        return withUnit ? value + ' ' + unit : value
    } else if (ft !== undefined) {
        const userLengthPrefer = userPreferences.lengthUnit === 'M2' ? ft / conversionFactor : ft
        const userUnitPrefer = userPreferences.lengthUnit === 'M2' ? 'cm' : 'inch'
        const lengthDefault = localeWithft2.includes(intl.locale) ? ft : ft / conversionFactor
        const unitDefault = localeWithft2.includes(intl.locale) ? 'inch' : 'cm'
        const value = formatValue(userPreferences.lengthUnit ? userLengthPrefer : lengthDefault)
        const unit = userPreferences.lengthUnit ? userUnitPrefer : unitDefault
        return withUnit ? value + ' ' + unit : value
    } else {
        const userUnitPrefer = userPreferences.lengthUnit === 'M2' ? 'cm' : 'inch'
        const unitDefault = localeWithft2.includes(intl.locale) ? 'inch' : 'cm'
        const unit = userPreferences.lengthUnit ? userUnitPrefer : unitDefault
        return unit
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(CalculateMeasure)
)
